import { Record, String, Array, Static, Boolean, Literal, Null, Union, Number, Optional } from 'runtypes';
import { IHttpPromise, IHttpResponse, IPromise } from 'angular';
import { toInteger } from 'lodash-es';
import { draftHttpService, DraftHttpService } from './http-services/draft.httpservice';
import { ProjectHttpService, projectHttpService, CreateProjectResponse } from './http-services/project.httpservice';
import { ManageHttpService, manageHttpService } from './http-services/manage.httpservice';
import { profilingHttpService, ProfilingHttpService } from './http-services/profiling.httpservice';
import { TargetGroupHttpService, targetGroupHttpService } from './http-services/target-group.httpservice';
import { PricingHttpService, pricingHttpService } from './http-services/pricing.httpservice';
import { FeasibilityRequest } from './http-services/feasibility-request.model';
import { urlService } from './http-services/url.service';
import {
  HttpsStatusKey,
  ProductMode,
  ProfilingScope,
  QuotaPresetSaveOutcome,
  RegionTypeKey,
  TargetGender,
} from './enums';
import { CensusResponse, CensusResponseDeprecated } from './http-services/census-response.model';
import { SupplyHttpService, supplyHttpService } from './http-services/supply.httpservice';
import { Constants } from '../constants';
import { asJsonScalarString, isUndefinedOrEmpty } from '../helpers';
import { AboveZero, GuidString, Iso8601UtcString, MinusOne, NonEmptyString, NonNegative } from '../custom-runtypes';
import { FeasibilityResponse, InfieldFeasibilityResponse } from './http-services/feasibility.response';
import { projectListHttpService, ProjectListHttpService } from './http-services/project-list.httpservice';
import { $q, $http } from '../ngimport';
import { TemplateHttpService, templateHttpService } from './http-services/template.httpservice';
import { surveyMetadataHttpService, SurveyMetadataHttpService } from './http-services/survey-metadata.httpservice';
import { cancelingHttpService, PotentiallyCanceledResult } from './http-services/canceling.httpservice';
import { ProjectSearchHttpService, projectSearchHttpService } from './http-services/project-search.httpService';
import { UpsertQuotaGroupPresetRequest } from './dialogs/SaveQuotaPresetDialog';

export interface FeasibilityHttpService {
  getFeasibility(request: FeasibilityRequest, timeoutPromise: IPromise<any>): IHttpPromise<FeasibilityResponse>;
  getInfieldFeasibility(projectId: number, targetGroupId: number): IHttpPromise<InfieldFeasibilityResponse>;
  calculateSuggestedNumberOfInvites({
    wantedNumberOfCompletes,
    actualNumberOfCompletes,
  }: {
    wantedNumberOfCompletes: number;
    actualNumberOfCompletes: number;
  }): IPromise<number>;
}

export interface CensusHttpService {
  getCensusDeprecated(countryId: number, minAge: number, maxAge: number): IHttpPromise<CensusResponseDeprecated>;
  getCensus(countryId: number, minAge: number, maxAge: number): IHttpPromise<CensusResponse>;
  clearCountryCensusCacheForCompany(countryId: number): IHttpPromise<void>;
}

export interface QuotaPresetHttpService {
  upsertPreset(newPreset: UpsertQuotaGroupPresetRequest): IHttpPromise<QuotaPresetSaveResponse>;
  deletePreset(presetId: number): IHttpPromise<void>;
  getQuotaPresets(request: GetQuotaPresetsRequest): IHttpPromise<QuotaGroupPresetResponse>;
}

export const HttpsStatusResponse = Union(
  Literal(HttpsStatusKey.Unknown),
  Literal(HttpsStatusKey.InvalidCertificate),
  Literal(HttpsStatusKey.Unsupported),
  Literal(HttpsStatusKey.Valid),
  Literal(HttpsStatusKey.ValidNotUsed)
);
export type HttpsStatusResponse = Static<typeof HttpsStatusResponse>;

export interface ValidationHttpService {
  getLinkHttpsStatus(url: string): IHttpPromise<HttpsStatusResponse>;
}

export const QuotaPresetSaveResponse = Record({
  status: Union(
    Literal(QuotaPresetSaveOutcome.Success),
    Literal(QuotaPresetSaveOutcome.NameExists),
    Literal(QuotaPresetSaveOutcome.CensusNonProfilingPresetExists),
    Literal(QuotaPresetSaveOutcome.CensusProfilingPresetExists),
    Literal(QuotaPresetSaveOutcome.UnknownError)
  ),
  id: Number.Or(Null),
});

export type QuotaPresetSaveResponse = Static<typeof QuotaPresetSaveResponse>;

export const GetQuotaPresetsRequest = Record({
  countryId: AboveZero,
  targetingTypes: Array(String),
});

export type GetQuotaPresetsRequest = Static<typeof GetQuotaPresetsRequest>;

export const PresetProfilingVariable = Record({
  id: Number,
  questionId: Number,
  scope: Union(Literal(ProfilingScope.Global), Literal(ProfilingScope.PanelSpecific)),
  panelId: Number.Or(Null),
});

export type PresetProfilingVariable = Static<typeof PresetProfilingVariable>;

export const QuotaPreset = Record({
  ordinal: Number,
  regions: Array(
    Record({
      id: Number,
      regionTypeId: Number,
    })
  ),
  variables: Array(PresetProfilingVariable),
  ageSpan: Record({ from: Number, to: Number }).Or(Null),
  gender: Union(Literal(TargetGender.Male), Literal(TargetGender.Female)).Or(Null),
  ratio: Number,
});

export const QuotaGroupPreset = Record({
  companyId: Number.Or(Null),
  countryId: Number,
  id: Number,
  interlocked: Boolean,
  name: String,
  presetType: Union(Literal('custom'), Literal('census')),
  quotas: Array(QuotaPreset),
  targetingTypes: Array(String),
  description: String.Or(Null),
  source: String.Or(Null),
});

export type QuotaGroupPreset = Static<typeof QuotaGroupPreset>;

export const QuotaGroupPresetResponse = Array(QuotaGroupPreset);
export type QuotaGroupPresetResponse = Static<typeof QuotaGroupPresetResponse>;

export type QuotaPreset = Static<typeof QuotaPreset>;

export const GetQuotaPresetsResponse = Array(QuotaPreset);

export type GetQuotaPresetsResponse = Static<typeof GetQuotaPresetsResponse>;

export const RegionResponse = Array(
  Record({
    id: AboveZero,
    mapId: NonNegative,
    name: NonEmptyString,
  })
);
export type RegionResponse = Static<typeof RegionResponse>;

export const RegionTypeResponse = Array(
  Record({
    id: AboveZero.Or(MinusOne), // -1 means `Postal codes`
    name: NonEmptyString,
    description: NonEmptyString,
    displayNotice: String.Or(Null),
    type: Union(Literal(RegionTypeKey.Generic), Literal(RegionTypeKey.Main), Literal(RegionTypeKey.PostalCodes)),
  })
);
export type RegionTypeResponse = Static<typeof RegionTypeResponse>;

export const SearchableRegion = Record({
  id: AboveZero,
  name: NonEmptyString,
  regionTypeDescription: NonEmptyString,
  regionTypeId: AboveZero,
  regionTypeName: String,
  countryId: AboveZero,
});
export type SearchableRegion = Static<typeof SearchableRegion>;

export const RegionSearchResult = Record({
  pagingToken: String,
  hits: Array(SearchableRegion),
});

export type RegionSearchResult = Static<typeof RegionSearchResult>;

export interface RegionHttpService {
  getRegions(countryId: number, regionTypeId: number): IHttpPromise<RegionResponse>;
  getRegionTypes(countryId: number): IHttpPromise<RegionTypeResponse>;
  getRegionSearchResults(
    countryId: number,
    searchString: string
  ): IPromise<PotentiallyCanceledResult<RegionSearchResult>>;
}

export const CountryIr = Record({
  min: NonNegative,
  max: NonNegative,
});
export type CountryIr = Static<typeof CountryIr>;

export const CountryLoi = Record({
  min: NonNegative,
  max: NonNegative,
});
export type CountryLoi = Static<typeof CountryLoi>;

export const Country = Record({
  id: AboveZero,
  name: String,
  minimumAge: NonNegative,
  ir: CountryIr,
  loi: CountryLoi,
  hasRegions: Boolean,
  hasAvailablePanels: Boolean,
});
export type Country = Static<typeof Country>;

export const CountriesResponse = Array(Country);
export type CountriesResponse = Static<typeof CountriesResponse>;

export interface CountryHttpService {
  getCountries(): IHttpPromise<CountriesResponse>;
}

export interface ConfigHttpService {
  getStartupConfig(): IHttpPromise<StartupConfig>;
}

export const DetailedFeature = Record({
  id: NonEmptyString,
  name: NonEmptyString,
  isFleeting: Boolean,
  enabled: Boolean,
  isTurnedOn: Boolean,
  notEnabledMessage: String,
  description: Optional(String),
});
export type DetailedFeature = Static<typeof DetailedFeature>;

export const DetailedFeaturesResponse = Array(DetailedFeature);
export type DetailedFeaturesResponse = Static<typeof DetailedFeaturesResponse>;

export const CacheKeysResponse = Array(NonEmptyString);
export type CacheKeysResponse = Static<typeof CacheKeysResponse>;

export const ProjectSearchResult = Record({
  id: AboveZero,
  name: NonEmptyString,
  projectId: AboveZero,
});
export type ProjectSearchResult = Static<typeof ProjectSearchResult>;

export const ProjectSearchResultResponse = Array(ProjectSearchResult);
export type ProjectSearchResultResponse = Static<typeof ProjectSearchResultResponse>;

export interface CompanyHttpService {
  getCompanyContacts(query: string): IPromise<CompanyContactResponse>;
  getProjectParametersDefinition(): IPromise<CustomParametersDefinitionResponse>;
  getTargetGroupParametersDefinition(): IPromise<CustomParametersDefinitionResponse>;
}

export const CompanyContact = Record({
  contactName: NonEmptyString,
  contactEmail: NonEmptyString,
});
export type CompanyContact = Static<typeof CompanyContact>;

export const CompanyContactResponse = Array(CompanyContact);
export type CompanyContactResponse = Static<typeof CompanyContactResponse>;

export const CustomOptionParameterValue = Record({
  value: Number,
  displayText: NonEmptyString,
  default: Optional(Boolean),
});
export type CustomOptionParameterValue = Static<typeof CustomOptionParameterValue>;

export const CustomOptionParameterDefinition = Record({
  key: NonEmptyString,
  displayText: NonEmptyString,
  values: Array(CustomOptionParameterValue),
});
export type CustomOptionParameterDefinition = Static<typeof CustomOptionParameterDefinition>;

export const CustomTextParameterDefinition = Record({
  key: NonEmptyString,
  displayText: NonEmptyString,
  defaultValue: String,
});
export type CustomTextParameterDefinition = Static<typeof CustomTextParameterDefinition>;

export const CustomParametersDefinitionResponse = Array(
  CustomOptionParameterDefinition.Or(CustomTextParameterDefinition)
);
export type CustomParametersDefinitionResponse = Static<typeof CustomParametersDefinitionResponse>;

export const RecreateProjectRequest = Record({
  workingDocumentId: GuidString,
  product: Union(Literal(ProductMode.Access), Literal(ProductMode.AccessPro), Literal(ProductMode.ManagedServices)),
});
export type RecreateProjectRequest = Static<typeof RecreateProjectRequest>;

export interface FeatureHttpService {
  getAllFeatures(): IPromise<IHttpResponse<DetailedFeaturesResponse>>;
  enableFeatures(featureIds: string[]): IHttpPromise<DetailedFeaturesResponse>;
  clearCacheFor(application: string): IHttpPromise<any>;
  clearCacheSection(sectionName: string): IHttpPromise<{}>;
  clearCacheItem(key: string): IHttpPromise<any>;
  getCacheKeys(): IHttpPromise<CacheKeysResponse>;
  searchProjects(projectIdQuery: number): IHttpPromise<ProjectSearchResultResponse>;
  exportProject(id: number): IHttpPromise<string>;
  importProject(projectData: string): IHttpPromise<CreateProjectResponse>;
  recreateProject(projectId: number, reacreateRequest: RecreateProjectRequest): IHttpPromise<CreateProjectResponse>;
}

export const ProjectDescriptionResponse = Record({
  description: String,
  history: Array(Record({ userName: String, date: Iso8601UtcString })),
});

export type ProjectDescriptionResponse = Static<typeof ProjectDescriptionResponse>;

export const ProjectGroupResponse = Record({
  id: GuidString,
  companyId: AboveZero,
  name: NonEmptyString,
  description: String,
  createdDate: Iso8601UtcString,
  createdBy: AboveZero,
});

export type ProjectGroupResponse = Static<typeof ProjectGroupResponse>;

export const ProjectGroupsResponse = Array(ProjectGroupResponse);

export type ProjectGroupsResponse = Static<typeof ProjectGroupsResponse>;

export class Api {
  get surveyMetadata(): SurveyMetadataHttpService {
    return surveyMetadataHttpService;
  }
  get draft(): DraftHttpService {
    return draftHttpService;
  }
  get template(): TemplateHttpService {
    return templateHttpService;
  }
  get project(): ProjectHttpService {
    return projectHttpService;
  }
  get projectList(): ProjectListHttpService {
    return projectListHttpService;
  }
  get manage(): ManageHttpService {
    return manageHttpService;
  }
  get profiling(): ProfilingHttpService {
    return profilingHttpService;
  }
  get targetGroup(): TargetGroupHttpService {
    return targetGroupHttpService;
  }
  get pricing(): PricingHttpService {
    return pricingHttpService;
  }
  get supply(): SupplyHttpService {
    return supplyHttpService;
  }

  get search(): ProjectSearchHttpService {
    return projectSearchHttpService;
  }

  get validation(): ValidationHttpService {
    return {
      getLinkHttpsStatus: (urlToValidate) =>
        $http.validatingPost<HttpsStatusResponse>(HttpsStatusResponse, urlService.postHttpsStatusValidation(), {
          url: urlToValidate,
        }),
    };
  }

  get quotaPresets(): QuotaPresetHttpService {
    return {
      upsertPreset: (newPreset: UpsertQuotaGroupPresetRequest) =>
        $http.validatingPost<QuotaPresetSaveResponse>(
          QuotaPresetSaveResponse,
          urlService.upsertQuotaPreset(),
          newPreset
        ),
      deletePreset: (presetId: number) => $http.delete(urlService.deleteQuotaPreset(presetId)),
      getQuotaPresets: (request: GetQuotaPresetsRequest) =>
        $http.validatingPost<QuotaGroupPresetResponse>(QuotaGroupPresetResponse, urlService.getQuotaPresets(), request),
    };
  }

  get census(): CensusHttpService {
    return {
      getCensusDeprecated: (countryId, minAge, maxAge) =>
        $http.validatingGet<CensusResponseDeprecated>(
          CensusResponseDeprecated,
          urlService.getCensusDeprecated(countryId, minAge, maxAge),
          {
            cache: true,
          }
        ),
      getCensus: (countryId, minAge, maxAge) =>
        $http.validatingGet<CensusResponse>(CensusResponse, urlService.getCensus(countryId, minAge, maxAge)),
      clearCountryCensusCacheForCompany: (countryId) =>
        $http.delete(urlService.clearCountryCensusCache(countryId, false)),
    };
  }

  get country(): CountryHttpService {
    return {
      getCountries: () => $http.validatingGet(CountriesResponse, urlService.getCountries(), { cache: true }),
    };
  }

  get config(): ConfigHttpService {
    return {
      getStartupConfig: () => $http.get(Constants.startupConfigUrl),
    };
  }

  get region(): RegionHttpService {
    return {
      getRegions: (countryId, regionTypeId) =>
        $http.validatingGet(RegionResponse, urlService.getRegions(countryId, regionTypeId), { cache: true }),
      getRegionTypes: (countryId) =>
        $http.validatingGet(RegionTypeResponse, urlService.getRegionTypes(countryId), { cache: true }),
      getRegionSearchResults: (countryId, searchString) =>
        cancelingHttpService.get(urlService.getRegionSearchResults(countryId, searchString)),
    };
  }

  get feature(): FeatureHttpService {
    return {
      getAllFeatures: () => $http.validatingGet(DetailedFeaturesResponse, urlService.getAllFeatures()),
      enableFeatures: (featureIds) =>
        $http.validatingPost(DetailedFeaturesResponse, urlService.enableFeatures(), featureIds),
      clearCacheFor: (application) => $http.post(urlService.clearCache(application), null),
      clearCacheSection: (name) => $http.post(urlService.clearCacheSection(), asJsonScalarString(name)),
      clearCacheItem: (key) => $http.post(urlService.clearCacheItem(), asJsonScalarString(key)),
      getCacheKeys: () => $http.validatingGet(CacheKeysResponse, urlService.getCacheKeys()),
      searchProjects: (projectIdQuery) =>
        $http.validatingGet(ProjectSearchResultResponse, urlService.searchProjects(projectIdQuery)),
      exportProject: (id) => $http.get(urlService.exportProject(id)),
      importProject: (projectData) =>
        $http.validatingPost(CreateProjectResponse, urlService.importProject(), projectData),
      recreateProject: (projectId: number, recreateRequest: RecreateProjectRequest) =>
        $http.validatingPost(CreateProjectResponse, urlService.recreateProject(projectId), recreateRequest),
    };
  }

  get feasibility(): FeasibilityHttpService {
    return {
      getFeasibility: (request, timeoutPromise) =>
        $http.validatingPost(FeasibilityResponse, urlService.feasibility(), request, { timeout: timeoutPromise }),

      getInfieldFeasibility: (projectId, targetGroupId) =>
        $http.validatingGet(InfieldFeasibilityResponse, urlService.infieldFeasibility(projectId, targetGroupId)),

      calculateSuggestedNumberOfInvites: ({
        wantedNumberOfCompletes,
        actualNumberOfCompletes,
      }: {
        wantedNumberOfCompletes: number;
        actualNumberOfCompletes: number;
      }) => {
        const remaining = wantedNumberOfCompletes - actualNumberOfCompletes;
        if (remaining < 1) return $q.when(0);

        return $q.when(Math.max(toInteger(4 * remaining), 0));
      },
    };
  }

  get company(): CompanyHttpService {
    return {
      getCompanyContacts: (query: string): IPromise<CompanyContactResponse> => {
        const emptyResponse: CompanyContact[] = [];
        if (isUndefinedOrEmpty(query)) return $q.resolve(emptyResponse);

        const encodedQuery = encodeURIComponent(query.trim());
        return $http
          .validatingGet(CompanyContactResponse, urlService.contactNames(encodedQuery))
          .then((res) => res.data);
      },
      getProjectParametersDefinition: () => {
        const url = urlService.projectParametersDefinition();
        return $http.validatingGet(CustomParametersDefinitionResponse, url).then((res) => res.data);
      },
      getTargetGroupParametersDefinition: () => {
        const url = urlService.targetGroupParametersDefinition();
        return $http.validatingGet(CustomParametersDefinitionResponse, url).then((res) => res.data);
      },
    };
  }
}

export const api = new Api();
