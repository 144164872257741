import { fromJson, toJson, copy as ngCopy } from 'angular';
import { map, findIndex } from 'lodash-es';
import { TargetGroupValidationResult, ProjectTemplateTargetGroup, LinksData } from '../http-services/draft.httpservice';
import { targetGroupNamer } from './target-group-namer';
import { TargetGroupSorter } from './target-group-sorter';
import {
  TargetGroupModel,
  ProjectTemplateSource,
  createTargetGroupModel,
  createTargetGroupValidationResult,
} from '../models/target-group.model';
import { TargetGroupPersistentModelFactory } from './target-group-persistent-model.factory';

import { featureFlipper } from '../feature-flipper';
import { db } from '../db';
import { session } from '../session';
import { StorageFactory } from '../storage.factory';
import { RuntimeSettings } from '../../runtime-settings';
import { ExistingTargetGroupSummary } from '../http-services/existing-project.models';
import { getQuotaGroupNamesFromReused, getSectionsUsingIgnoreCompletesFromReused } from './reuse-target-group-helper';
import { PanelistPoolType, SupplySource } from '../enums';
import { createSupplyModel } from '../models/supply.model';

export interface StorageDraft {
  id: number;
  name: string;
}

export interface StorageTemplate {
  id: number;
  name: string;
}

export class TargetGroupRepository {
  private draftKey = 'draft';
  private templateKey = 'targetGroupTemplate';
  private storage: Storage;
  private readonly targetGroupSorter: TargetGroupSorter;

  constructor() {
    this.storage = StorageFactory.getStorage();
    this.targetGroupSorter = new TargetGroupSorter();
  }

  init() {
    this.targetGroupSorter.loadWith(this.retrievePersistentSortOrder());
  }

  getTargetGroupSorter(): TargetGroupSorter {
    return this.targetGroupSorter;
  }

  findDeprecationById(targetGroupId: number): TargetGroupValidationResult {
    const localStorageTg = this.storage.getItem(this.createTargetGroupDeprecationKey(targetGroupId));
    return localStorageTg === null ? null : (fromJson(localStorageTg) as TargetGroupValidationResult);
  }

  async createDefault(
    idOffset = 0,
    existingTargetGroups: ExistingTargetGroupSummary[] = []
  ): Promise<TargetGroupModel> {
    const currentNames = await this.findAllTargetGroupNames();
    const targetGroupNames = currentNames.concat(existingTargetGroups.map((tg) => tg.name));

    const id = this.createIdForNewTg(idOffset);
    const name = targetGroupNamer.updateName(`Target Group ${id}`, targetGroupNames);
    const persistentModel = createTargetGroupModel(id, name);

    await this.addToStore(persistentModel, createTargetGroupValidationResult());
    return this.findById(persistentModel.id);
  }

  async updateLinksData(targetGroupId: number, linksData: LinksData) {
    const targetGroup = await this.findById(targetGroupId);
    targetGroup.linksData = linksData;
    await this.save(targetGroup);
  }

  async updateSupplySource(targetGroupId: number, supplySource: SupplySource) {
    const targetGroup = await this.findById(targetGroupId);
    targetGroup.panels = { ...targetGroup.panels, supplySource };
    return this.save(targetGroup);
  }

  async save(targetGroupModel: TargetGroupModel): Promise<void> {
    await this.savePersistentModelToDb(targetGroupModel);
  }

  getStorageDraft(): StorageDraft {
    return fromJson(this.storage.getItem(this.draftKey));
  }

  saveStorageDraft(draftToSave: StorageDraft): void {
    this.storage.setItem(this.draftKey, this.createStorageDraft(draftToSave));
  }

  getStorageTemplate(): StorageTemplate {
    return fromJson(this.storage.getItem(this.templateKey));
  }

  saveDeprecation(id: number, deprecationModel: TargetGroupValidationResult): void {
    this.storage.setItem(this.createTargetGroupDeprecationKey(id), toJson(deprecationModel));
  }

  async remove(targetGroupId: number): Promise<void> {
    const tg = await this.getPersistedModelFromDb(targetGroupId);
    await db.targetGroups.where('key').equals(`${session.uuid}:${tg.id}`).delete();
    this.targetGroupSorter.remove(targetGroupId);
    this.storage.removeItem(`deprecation_${targetGroupId.toString()}`);
    this.saveSortOrder();
  }

  async removeAll(): Promise<void> {
    const targetGroupsIds = this.retrievePersistentSortOrder();
    for (const id of targetGroupsIds) {
      this.storage.removeItem(`deprecation_${id.toString()}`);
    }
    await db.targetGroups.where('suuid').equals(session.uuid).delete();
    this.storage.removeItem('ordering');
    this.storage.removeItem('draft');
    this.targetGroupSorter.clear();
  }

  async createFromJSON(
    reusedTargetGroup: ProjectTemplateTargetGroup,
    projectTemplateSource: ProjectTemplateSource
  ): Promise<TargetGroupModel> {
    const sectionsUsingIgnoreCompletes = getSectionsUsingIgnoreCompletesFromReused(reusedTargetGroup);
    const quotaGroupNames = featureFlipper.isEnabled('nameQuotaGroups')
      ? getQuotaGroupNamesFromReused(reusedTargetGroup)
      : undefined;

    const persistentModel = TargetGroupPersistentModelFactory.createFromJSON(
      reusedTargetGroup,
      featureFlipper,
      sectionsUsingIgnoreCompletes,
      quotaGroupNames
    );

    persistentModel.projectTemplateSource = projectTemplateSource;
    persistentModel.id = this.createIdForNewTg();
    persistentModel.name = targetGroupNamer.updateName(persistentModel.name, await this.findAllTargetGroupNames());
    await this.addToStore(persistentModel, reusedTargetGroup.validation);
    return this.findById(persistentModel.id);
  }

  async findById(targetGroupId: number): Promise<TargetGroupModel> {
    const persistedModel = await this.getPersistedModelFromDb(targetGroupId);
    persistedModel.deprecation = this.findDeprecationById(persistedModel.id);

    if (RuntimeSettings.runtime.environment === 'Development') {
      Object.freeze(persistedModel.basicSettings);
      Object.freeze(persistedModel.regions);
      Object.freeze(persistedModel.panels);
      Object.freeze(persistedModel.profiling);
      Object.freeze(persistedModel.quotas);
      Object.freeze(persistedModel.excludeProjects);
      Object.freeze(persistedModel.incentives);
    }

    return persistedModel;
  }

  async findAll(): Promise<TargetGroupModel[]> {
    const targetGroupsIds = this.retrievePersistentSortOrder();
    return Promise.all(map(targetGroupsIds, (id) => this.findById(id)));
  }

  async getNextActiveTargetGroup(id: number) {
    const orderedTargetGroups = await this.findAll();
    const previousIndex = findIndex(orderedTargetGroups, (tg) => tg.id === id) - 1;
    const nextActiveIndex = previousIndex < 0 ? 1 : previousIndex;
    return orderedTargetGroups[nextActiveIndex];
  }

  findAllDeprecations(): TargetGroupValidationResult[] {
    const targetGroupsIds = this.retrievePersistentSortOrder();
    return map(targetGroupsIds, (id) => this.findDeprecationById(id));
  }

  async duplicate(targetGroup: TargetGroupModel, copyUrls: boolean, removePool = false): Promise<TargetGroupModel> {
    const persistentModel = ngCopy(targetGroup);

    if (!copyUrls) {
      persistentModel.linksData = {
        liveLinkTemplate: '',
        testLinkTemplate: '',
        screenoutInformation: '',
      };
    }

    const poolType = persistentModel.panels.panelistPool.type;

    if (removePool) {
      if (poolType === PanelistPoolType.Inclusive) persistentModel.panels = createSupplyModel();
      else if (poolType === PanelistPoolType.Exclusive)
        persistentModel.panels = {
          ...persistentModel.panels,
          panelistPool: createSupplyModel().panelistPool,
        };
    }

    persistentModel.id = this.createIdForNewTg();
    persistentModel.name = targetGroupNamer.updateName(persistentModel.name, await this.findAllTargetGroupNames());

    const deprecation = this.findDeprecationById(targetGroup.id);
    if (deprecation !== null) {
      this.saveDeprecation(persistentModel.id, deprecation);
    }

    await this.save(persistentModel);
    this.targetGroupSorter.insert(persistentModel.id, targetGroup.id);
    this.saveSortOrder();

    return persistentModel;
  }

  findFirstTargetGroupId(): number {
    return this.targetGroupSorter.sortOrder[0] || 1;
  }

  private async addToStore(
    persistentModel: TargetGroupModel,
    deprecationModel: TargetGroupValidationResult
  ): Promise<void> {
    await this.savePersistentModelToDb(persistentModel);
    this.targetGroupSorter.add(persistentModel.id);
    this.saveSortOrder();
    this.saveDeprecation(persistentModel.id, deprecationModel);
  }

  private createTargetGroupDeprecationKey(targetGroupId: number): string {
    return `deprecation_${targetGroupId}`;
  }

  private createStorageDraft(storageDraft: StorageDraft): string {
    return toJson({ id: storageDraft.id, name: storageDraft.name });
  }

  private saveSortOrder(): void {
    this.storage.setItem('ordering', toJson(this.targetGroupSorter.sortOrder));
  }

  private retrievePersistentSortOrder(): number[] {
    return fromJson(this.storage.getItem('ordering')) || [];
  }

  private async findAllTargetGroupNames(): Promise<string[]> {
    const sortOrder = await this.retrievePersistentSortOrder();
    const targetGroups = await db.targetGroups
      .where('suuid')
      .equals(session.uuid)
      .and((tg) => sortOrder.includes(tg.id));

    const targetGroupNames: string[] = [];
    await targetGroups.each((row) => targetGroupNames.push(row.targetGroup.name));
    return targetGroupNames;
  }

  private createIdForNewTg(offset = 0): number {
    return this.targetGroupSorter.findHighestNumber(offset) + 1;
  }

  private savePersistentModelToDb(tg: TargetGroupModel): Promise<string> {
    return db.transaction('rw', db.targetGroups, () => {
      return db.targetGroups.put({
        key: `${session.uuid}:${tg.id}`,
        suuid: session.uuid,
        id: tg.id,
        targetGroup: tg,
      });
    });
  }

  private async getPersistedModelFromDb(id: number): Promise<TargetGroupModel> {
    if (id === undefined) {
      throw new Error('TargetGroup id cannot be undefined');
    }

    const tableItem = await db.targetGroups.where('key').equals(`${session.uuid}:${id}`).first();
    if (!tableItem) {
      throw new Error(`No target group with id '${id}' was found.`);
    }
    return tableItem ? tableItem.targetGroup : null;
  }
}

export const targetGroupRepository = new TargetGroupRepository();
