import { IPromise } from 'angular';
import { map, isEmpty, uniq, includes } from 'lodash-es';
import {
  ActiveProfilingModel,
  createActiveProfilingModel,
  ProfilingCategory,
  SelectedVariables,
} from '../../models/active-profiling.model';
import { ProfilingCategoryDetailsResponse } from '../../../../common/http-services/profiling.httpservice';
import { api, QuotaGroupPreset } from '../../../../common/api';
import { errorLogger } from '../../../../common/error-logger';
import { mutators } from './profiling.mutators';
import { $q } from '../../../../ngimport';
import { logProduce } from '../../../../common/immer-wrapper';
import { profilingSearchService } from '../../../profiling/profiling-search.service';

export class ProfilingSubactions {
  createCategoriesPromise(
    state: ActiveProfilingModel,
    countryId: number,
    panelId?: number
  ): IPromise<ActiveProfilingModel> {
    if (countryId <= 0) {
      return $q.when().then(() => {
        return logProduce(state, () => {
          return createActiveProfilingModel();
        });
      });
    }

    const param = {
      countryId,
      panelId,
    };

    if (state.panelSpecificProfiling && panelId === undefined) {
      errorLogger.warning('No panelId set for panel specific profiling');
    }

    return api.profiling.getCategories(param).then((res) => {
      const newCategories = res.data.map((c) => ({
        ...c,
        questions: [],
      }));

      return logProduce(state, mutators.setCategories(newCategories));
    });
  }

  // only used on restore ( refresh, change tg ..)
  createCategoriesWithVariablesPromise(
    state: ActiveProfilingModel,
    countryId: number,
    selectedPanelId: number
  ): IPromise<ActiveProfilingModel> {
    const categoryIds = this.getSelectedCategoryIds(state);

    if (!isEmpty(state.activeCategory) && !includes(categoryIds, state.activeCategory.id)) {
      categoryIds.push(state.activeCategory.id);
    }

    if (categoryIds.length <= 0) return $q.when(state);

    const param = state.panelSpecificProfiling ? { countryId, panelId: selectedPanelId } : { countryId };

    return api.profiling.getCategoriesByIds(param, categoryIds).then((res) => {
      return this.populateQuestionsForAllSelectedCategories(state, res.data);
    });
  }

  setActiveCategoryWithQuestionsAsync(
    state: ActiveProfilingModel,
    categoryId: number,
    countryId: number,
    panelId?: number
  ): IPromise<ActiveProfilingModel> {
    const selectedCategory = this.findCategory(state, categoryId);
    const param = state.panelSpecificProfiling ? { countryId, panelId } : { countryId };
    return api.profiling
      .getCategoryDetails(param, selectedCategory.id)
      .then((res) => res.data)
      .then((category) => {
        return logProduce(state, (draft) => {
          mutators.setActiveCategory(category)(draft);
          mutators.setCategoryQuestions(category)(draft);
          mutators.setCategoryVariablesIsSelected(category, state.selectedVariables)(draft);
        });
      });
  }

  searchQuestionsAndVariablesAsync(
    state: ActiveProfilingModel,
    countryId: number,
    searchString: string,
    categoryId?: number,
    panelId?: number
  ): IPromise<ActiveProfilingModel> {
    const param = state.panelSpecificProfiling ? { countryId, panelId } : { countryId };
    console.log(param); // todo- include support
    return profilingSearchService.throttledSearch(countryId, searchString, categoryId).then((searchResults) => {
      return logProduce(state, (draft) => {
        mutators.setSearchResult(searchResults)(draft);

        searchResults.results.forEach((category) => {
          mutators.setCategoryQuestions(category)(draft);
          mutators.setCategoryVariablesIsSelected(category, state.selectedVariables)(draft);
        });
      });
    });
  }

  clearSearchResults(state: ActiveProfilingModel): ActiveProfilingModel {
    return logProduce(state, mutators.setSearchResult(null));
  }

  removeActiveCategory(state: ActiveProfilingModel): ActiveProfilingModel {
    return logProduce(state, mutators.clearActiveCategory());
  }

  variableSelectionChanged(
    state: ActiveProfilingModel,
    variableIds: number[],
    categoryId: number,
    toggle?: boolean
  ): ActiveProfilingModel {
    return logProduce(state, (draft) => {
      variableIds.forEach((variableId) => mutators.toggleVariable(variableId, categoryId, toggle)(draft));
      mutators.setSelectedVariables(this.getSelectedVariables(draft))(draft);
      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }

  selectPresetProfiling(
    state: ActiveProfilingModel,
    quotaPreset: QuotaGroupPreset,
    categoryId: number
  ): ActiveProfilingModel {
    const presetQuestionIds = uniq(quotaPreset.quotas.flatMap((q) => q.variables.map((v) => v.questionId)));
    if (presetQuestionIds.length !== 1) {
      throw Error('interlocked profiling quotas not supported');
    }
    const presetQuestionId = presetQuestionIds[0];
    const presetVariableIds = quotaPreset.quotas.flatMap((q) => q.variables.map((v) => v.id));

    const allQuestionVariables = state.categories
      .find((cat) => cat.id === categoryId)
      .questions.find((q) => q.id === presetQuestionId)
      .variables.map((v) => v.id);

    return logProduce(state, (draft) => {
      allQuestionVariables.forEach((variableId) =>
        mutators.toggleVariable(variableId, categoryId, presetVariableIds.includes(variableId))(draft)
      );
      mutators.setSelectedVariables(this.getSelectedVariables(draft))(draft);
      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }

  selectCensusProfiling(
    state: ActiveProfilingModel,
    categoryId: number,
    questionId: number,
    variableIds: number[]
  ): ActiveProfilingModel {
    return logProduce(state, (draft) => {
      mutators.deselectAllVariablesForQuestion(categoryId, questionId)(draft);
      variableIds.forEach((variableId) => mutators.toggleVariable(variableId, categoryId, true)(draft));
      mutators.setSelectedVariables(this.getSelectedVariables(draft))(draft);
      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }

  removeVariableSelectionFromActiveCategory(state: ActiveProfilingModel): ActiveProfilingModel {
    return logProduce(state, (draft) => {
      mutators.deselectAllVariables(state.activeCategory.id)(draft);
      mutators.setSelectedVariables(this.getSelectedVariables(draft))(draft);
      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }

  clearAllCategories(state: ActiveProfilingModel): ActiveProfilingModel {
    return logProduce(state, (draft) => {
      for (const category of draft.categories) {
        mutators.deselectAllVariables(category.id)(draft);
      }
      mutators.setSelectedVariables(this.getSelectedVariables(draft))(draft);
      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }

  handlePanelProfilingAsync<T extends [ActiveProfilingModel, boolean]>(
    state: ActiveProfilingModel,
    countryId: number,
    showTogglePanelProfiling: boolean,
    resetPanelSpecificProfiling?: boolean
  ): IPromise<T> {
    const hasDisabledShowTogglePanelProfiling = state.panelSpecificProfiling && !showTogglePanelProfiling;
    if (hasDisabledShowTogglePanelProfiling || resetPanelSpecificProfiling) {
      const newState = logProduce(state, mutators.setPanelSpecificProfiling(false));
      return this.createCategoriesPromise(newState, countryId).then(
        (newProfilingState) => [newProfilingState, true] as T
      );
    }

    return $q.when([state, false] as T);
  }

  togglePanelProfilingAsync(
    state: ActiveProfilingModel,
    countryId: number,
    panelId: number | undefined,
    withPanelProfiling: boolean
  ) {
    const newState = logProduce(state, mutators.setPanelSpecificProfiling(withPanelProfiling));
    return this.createCategoriesPromise(newState, countryId, panelId);
  }

  private findCategory(state: ActiveProfilingModel, categoryId: number): ProfilingCategory {
    return state.categories.find((c) => c.id === categoryId);
  }

  private getSelectedVariables(state: ActiveProfilingModel): SelectedVariables {
    const result: SelectedVariables = {};

    for (const category of state.categories) {
      if (category.questions === undefined) continue;
      for (const question of category.questions) {
        for (const v of question.variables) {
          if (v.isSelected) {
            result[v.id] = {
              categoryId: category.id,
            };
          }
        }
      }
    }

    if (state.searchResult) {
      for (const category of state.searchResult.results) {
        if (category.questions === undefined) continue;
        for (const question of category.questions) {
          for (const v of question.variables) {
            if (v.isSelected) {
              result[v.id] = {
                categoryId: category.id,
              };
            }
          }
        }
      }
    }

    return result;
  }

  private getSelectedCategoryIds(state: ActiveProfilingModel): number[] {
    const catIds = map(state.selectedVariables, (v) => v.categoryId);
    return uniq(catIds);
  }

  private populateQuestionsForAllSelectedCategories(
    state: ActiveProfilingModel,
    categories: ProfilingCategoryDetailsResponse[]
  ): ActiveProfilingModel {
    return logProduce(state, (draft) => {
      for (const category of categories) {
        if (category.id === state.activeCategory.id) {
          mutators.setCategoryName(category.name)(draft);
        }

        mutators.setCategoryQuestions(category)(draft);
        mutators.setCategoryVariablesIsSelected(category, state.selectedVariables)(draft);
      }

      mutators.setDetailedSelection(draft.categories)(draft);
    });
  }
}

export const profilingSubactions = new ProfilingSubactions();
