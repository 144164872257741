import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';

const directiveName = 'onEnterPressed';

export function onEnterPressed(): IDirective {
  return {
    restrict: 'A',
    link: (scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      elem.bind('keydown', (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
          scope.$apply(() => {
            scope.$eval(attrs[directiveName]);
          });
        }
      });
    },
  };
}

export const ngOnEnterPressedDirective = {
  [directiveName]: onEnterPressed,
};
