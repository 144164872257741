import 'console-polyfill';
import { module as ngModule } from 'angular';
import ngSanitize from 'angular-sanitize';
import ngAnimate from 'angular-animate';
import ngMessages from 'angular-messages';
import { trace, Category } from '@uirouter/angularjs';
import Dexie from 'dexie';
import dayjs from 'dayjs';
import { keys } from 'lodash-es';
import { appInsightsSafeUserId, silenceRejection } from './helpers';
import { extendErrorHandler } from './error-handler-extensions';
import { common } from './common/common.module';
import { welcome } from './welcome/welcome.module';
import { projectList } from './project-list/project-list.module';
import { states } from './app.states';
import { interceptors } from './http-interceptors';
import { db } from './common/db';
import { session } from './common/session';
import { panelistPoolStorageService } from './common/panelist-pool-storage.service';
import {
  $stateProvider,
  $compileProvider,
  $httpProvider,
  $location,
  $urlServiceProvider,
  $transitions,
} from './ngimport';
import { targetGroupRepository } from './common/target-group/target-group.repository';
import { ngAppComponent } from './app.component';
import { ngClearManagedServicesModeDialogComponent } from './clear-managed-services-mode-dialog.component';
import { ngCheckForUpdatesComponent } from './check-for-updates.component';
import { ngFancyCacheKeyComponent } from './dev-tools/fancy-cache-key.component';
import { ngDeveloperToolsDialogComponent } from './dev-tools/developer-tools-dialog.component';
import { ngOpenDeveloperToolsButtonComponent } from './dev-tools/open-developer-tools-button.component';
import { ngListCacheDialogComponent } from './dev-tools/list-cache-dialog.component';
import { ngWarningDialogComponent } from './dev-tools/warning-dialog.component';
import { ngExportProjectDialogComponent } from './dev-tools/export-project-dialog.component';
import { ngReloadApplicationDialogComponent } from './reload-application-dialog.component';
import { ngNavigationComponent } from './navigation.component';
import { RuntimeSettings } from './runtime-settings';

import { stateTransitionService } from './common/state-transition.service';
import { targetGroupAccordionService } from './target-groups/target-group-accordion.service';
import { feasibilityService } from './target-groups/feasibility.service';
import { ngImportProjectDialogComponent } from './dev-tools/import-project-dialog.component';
import { regionSearchService } from './target-groups/regions/region-search.service';
import { profilingSearchService } from './target-groups/profiling/profiling-search.service';
import { analytics } from './common/analytics';
import { ngRecreateProjectDialogComponent } from './dev-tools/recreate-project-dialog.component';
import { projectSearchService } from './dashboard/search/project-search.service';

if (__PROCESS__.ENV !== 'production') {
  if (window.sessionStorage.getItem('globalDebugOptions:enableTransitionTracing') === 'true') {
    // enable ui-router transition tracing
    trace.enable(Category.TRANSITION);
  }
}

export const app = ngModule('access', [ngSanitize, ngAnimate, ngMessages, common.name, welcome.name, projectList.name]);

app.config(() => {
  $compileProvider.debugInfoEnabled(__PROCESS__.ENV !== 'production');
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);

  $httpProvider.useApplyAsync(true);
  $httpProvider.defaults.withCredentials = true;
  $httpProvider.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };
  for (const interceptorName of keys(interceptors)) {
    $httpProvider.interceptors.push(interceptorName);
  }

  extendErrorHandler();

  for (const state of states) {
    $stateProvider.state(state);
  }

  $urlServiceProvider.rules.otherwise('/');
});

app.run(() => {
  $transitions.onStart({}, (trans) => {
    const stateToName = trans.$to().name;
    stateTransitionService.transitionStart();

    analytics.appInsights?.setAuthenticatedUserContext(appInsightsSafeUserId(RuntimeSettings.userData.name));
    analytics.appInsights?.startTrackPage(stateToName);

    (trans.promise as any).finally(() => {
      analytics.appInsights?.stopTrackPage(stateToName, $location.absUrl());
      return stateTransitionService.transitionEnd(trans);
    });
  });

  $transitions.onStart(
    {},
    (trans) => {
      const { redirectTo } = trans.$to();
      if (redirectTo) {
        stateTransitionService.transitionStart();

        (trans.promise as any)
          .catch(() => silenceRejection)
          .finally(() => {
            return stateTransitionService.transitionEnd(trans);
          });
      }
    },
    { priority: 1 }
  );

  function clearOldSessionData(): void {
    db.transaction('rw', [db.sessionInfo, db.existingProject, db.targetGroups, db.feasibilityResponses], () => {
      const incompatible = db.sessionInfo.where('buildNumber').below(RuntimeSettings.buildNumber);
      incompatible.each((s) => {
        const sessionTargetGroupsInDb = db.targetGroups.where('suuid').equals(s.uuid);
        return Dexie.Promise.all([db.existingProject.delete(s.uuid), sessionTargetGroupsInDb.delete()]);
      });
      const old = db.sessionInfo.filter((s) => dayjs().diff(dayjs(s.createdAt), 'minute') > 1);
      old.each((s) => {
        const sessionTargetGroupsInDb = db.targetGroups.where('suuid').equals(s.uuid);
        return Dexie.Promise.all([db.existingProject.delete(s.uuid), sessionTargetGroupsInDb.delete()]);
      });
      const oldFeasibilityData = db.feasibilityResponses.where('date').below(dayjs().subtract(3, 'days').unix());

      return Dexie.Promise.all([
        incompatible.delete().then((count) => {
          if (count > 0) console.debug(`deleted ${count} sessions from a previous build`);
        }),
        old.delete().then((count) => {
          if (count > 0) console.debug(`deleted ${count} sessions older than 7 days`);
        }),
        oldFeasibilityData.delete().then((count) => {
          if (count > 0) console.debug(`deleted ${count} feasibility responses older than 3 days`);
        }),
      ]);
    });

    // pretty sure this won't do anything. if this doesn't fix the expired pool bug, remove this call.
    panelistPoolStorageService.clear();
  }

  session.onCreated.subscribe(() => {
    console.debug(`new session created: ${session.uuid}`);
    clearOldSessionData();
  });

  session.init().then(() => {
    initServices();
  });
});

function initServices(): void {
  targetGroupRepository.init();
  targetGroupAccordionService.init();
  feasibilityService.init();
  regionSearchService.init();
  profilingSearchService.init();
  projectSearchService.init();
}

app.factory(interceptors);

app.component(ngAppComponent);
app.component(ngClearManagedServicesModeDialogComponent);
app.component(ngCheckForUpdatesComponent);
app.component(ngDeveloperToolsDialogComponent);
app.component(ngFancyCacheKeyComponent);
app.component(ngOpenDeveloperToolsButtonComponent);
app.component(ngListCacheDialogComponent);
app.component(ngWarningDialogComponent);
app.component(ngExportProjectDialogComponent);
app.component(ngImportProjectDialogComponent);
app.component(ngRecreateProjectDialogComponent);
app.component(ngReloadApplicationDialogComponent);
app.component(ngNavigationComponent);
