import React, { useState } from 'react';
import { ui } from 'angular';
import { react2angular } from 'react2angular';
import { ModalCloseButton } from '../../../common/dialogs/ModalCloseButton';
import { PanelistPoolSearch } from './PanelistPoolSearch';
import { useForceUpdate } from '../../../common/custom-hooks';
import { activeTargetGroupStore } from '../../active-target-group/store/active-target-group.store';
import { targetGroupChannel } from '../../channels/target-group-channel';
import { useChannel } from '../../../common/channels/channel';
import { panelistPoolHelper } from '../../panelist-pool-helper';
import { supplyStateStore } from '../../active-target-group/store/supply-state.store';
import { AvailabilityResultResponse, supplyHttpService } from '../../../common/http-services/supply.httpservice';
import { modalService } from '../../../common/modal.service';
import { targetGroupRepository } from '../../../common/target-group/target-group.repository';
import { InfoDialogSettingsFactory } from '../../../common/dialogs/info-dialog-settings.factory';
import { panelistPoolApiInputString } from '../../exclude-projects/ExcludePanelistPool';

import './TargetFromPanelistPoolApiDialog.scss';

export const TargetFromPanelistPoolApiDialog: React.FC<{ modalInstance: ui.bootstrap.IModalInstanceService }> = ({
  modalInstance,
}) => {
  const [selectionSaved, setSelectionSaved] = useState(
    supplyStateStore.model?.supply?.panelistPool?.input === panelistPoolApiInputString
  );
  const [isValidating, setIsValidating] = useState(false);
  const [availabilityResult, setAvailabilityResult] = useState<AvailabilityResultResponse>(
    supplyStateStore.model?.supply?.panelistPool?.availabilityResult ?? undefined
  );
  const forceUpdate = useForceUpdate();

  useChannel(targetGroupChannel.model.supplyState.panelistAvailabilityUpdated, () => {
    setIsValidating(false);
  });

  useChannel(targetGroupChannel.model.supply.updated, forceUpdate);

  const saveSelected = (selectedPoolId: string) => {
    validateIsPoolInUse(selectedPoolId, activeTargetGroupStore.model.identity.id).then((poolIsInUse) => {
      if (poolIsInUse) return;

      setSelectionSaved(true);
      setIsValidating(true);

      const { countryId } = activeTargetGroupStore.model.basicSettings;

      supplyHttpService
        .checkPanelistAvailability({ countryId, poolId: selectedPoolId, fromPanelistPoolApi: true })
        .then(({ data }) => {
          setAvailabilityResult(data);
          supplyStateStore.supply.saveFromPanelistPoolApi(data, selectedPoolId, countryId);
        });
    });
  };

  const availablePanelists = panelistPoolHelper.getAvailablePanelistCount(availabilityResult);

  const unavailablePanelists = availabilityResult?.unavailablePanelists?.panelistCount ?? 0;

  const removeSelection = () => {
    setSelectionSaved(false);
    supplyStateStore.supply.discardPanelistPool();
  };

  return (
    <div className="target-from-panelist-pool-api-dialog">
      <ModalCloseButton onClose={modalInstance.dismiss} />
      <div className="modal-container">
        <div className="header-wrapper">
          <h1 className="modal-header">Target Panelist Pool</h1>
        </div>
        {!selectionSaved && (
          <div>
            <PanelistPoolSearch onSave={saveSelected} />
          </div>
        )}
        {selectionSaved && (
          <div>
            {isValidating && (
              <div className="spinner-container text-center">
                <div className="spinner-text">Validating available panelists...</div>
                <i className="fas fa-spinner fa-spin" aria-hidden="true" />
              </div>
            )}
            {!isValidating && (
              <>
                <div className="text-center pool-availability">
                  Out of {availablePanelists + unavailablePanelists} panelists, {availablePanelists} are available and
                  will be targeted.
                </div>
                <div className="modal-controls">
                  <button className="ui-btn default-btn" onClick={removeSelection}>
                    Back to pool search
                  </button>
                  <button className="ui-btn primary-btn" onClick={(_) => modalInstance.close(true)}>
                    OK
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const selector = 'targetFromPanelistPoolApiDialog';

export class TargetFromPanelistPoolApiDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'target-from-panelist-pool-api-dialog',
      component: selector,
      keyboard: false,
      resolve: {},
    };
  }
}

export const ngTargetFromPanelistPoolApiDialogComponent = {
  [selector]: react2angular(TargetFromPanelistPoolApiDialog, ['modalInstance']),
};

export async function validateIsPoolInUse(poolId: string, activeTgId: number) {
  const tgWithSameApiPool = (await targetGroupRepository.findAll()).find(
    (tg) => tg.id !== activeTgId && tg.panels.panelistPool.selectedPoolId === poolId
  );

  if (tgWithSameApiPool) {
    modalService.openSmall(
      InfoDialogSettingsFactory.create({
        title: 'Unable to use selected pool',
        text: 'The pool you are trying to select is already in use by another Target Group in the current project.',
      })
    );

    return true;
  }

  return false;
}
