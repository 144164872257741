import { IPromise } from 'angular';
import { remove } from 'lodash';
import { monitorChannel } from '../manage/monitor/monitor-channel';
import { Listener } from './channels/channel';
import {
  ExistingTargetGroup,
  ExistingProject,
  ExistingProjectBase,
  ExistingTargetGroupSummary,
} from './http-services/existing-project.models';
import { api } from './api';

export class ExistingProjectLookupService extends Listener {
  private existingProject: ExistingProject;
  private existingTargetGroups: ExistingTargetGroup[];

  constructor() {
    super();
    this.existingTargetGroups = [];
    monitorChannel.project.loaded.listen(this.storeInMemory, this);
  }

  storeInMemory(newProject: ExistingProject): void {
    this.existingProject = newProject;
  }

  getTargetGroup(id: number, allowCache = true): IPromise<ExistingTargetGroup> {
    let existingTargetGroup: ExistingTargetGroup;
    if (this.existingTargetGroups) {
      existingTargetGroup = this.existingTargetGroups.find((tg) => tg.id === id);
    }

    if (!existingTargetGroup) {
      return api.targetGroup.getTargetGroup(this.project.id, id, allowCache).then((res) => {
        this.storeTargetGroupInMemory(res.data);
        return res.data;
      });
    }

    return Promise.resolve(existingTargetGroup);
  }

  storeTargetGroupInMemory(targetGroup: ExistingTargetGroup): void {
    this.existingTargetGroups = this.existingTargetGroups
      .filter((tg) => tg.id !== targetGroup.id)
      .concat([targetGroup]);
  }

  removeTargetGroupFromInMemory(targetGroupId: number) {
    remove(this.existingTargetGroups, (tg) => tg.id === targetGroupId);
  }

  get targetGroups(): ExistingTargetGroup[] {
    return this.existingTargetGroups;
  }

  get targetGroupSummaries(): ExistingTargetGroupSummary[] {
    return this.existingProject.targetGroups;
  }

  get project(): ExistingProjectBase {
    return this.existingProject;
  }
}

export const existingProjectLookupService = new ExistingProjectLookupService();
