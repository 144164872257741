import { useEffect, useState } from 'react';
import { inlineHelpService } from './inline-help.service';

export const useInlineHelp = (id: string) => {
  const [showHelp, setShowHelp] = useState(inlineHelpService.visibleFor[id] || false);
  useEffect(() => {
    const binding = inlineHelpService.onChange.add((source, state) => {
      if (source === id) setShowHelp(state);
    });

    return () => {
      binding.detach();
    };
  }, [id]);

  return showHelp;
};

type Props = {
  id: string;
  children: React.ReactNode;
};

export const InlineHelp: React.FC<Props> = ({ id, children }) => useInlineHelp(id) && <>{children}</>;
