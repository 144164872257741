import { fromJson, toJson } from 'angular';
import { isEmpty } from 'lodash-es';
import { StorageFactory } from './storage.factory';

export interface SaveStateSettings {
  isDirty: boolean;
}

export class SaveStateService {
  settings: SaveStateSettings = SaveStateService.defaultSettings();

  private storage: Storage;
  private storageKey = 'saveState';

  static defaultSettings(): SaveStateSettings {
    return { isDirty: false };
  }

  constructor() {
    this.storage = StorageFactory.getStorage();
  }

  setDirty(hasBeenModified: boolean): void {
    this.settings.isDirty = hasBeenModified;
    this.storage.setItem(this.storageKey, toJson(this.settings));
  }

  init(): void {
    if (isEmpty(this.storage.getItem(this.storageKey))) {
      this.settings = SaveStateService.defaultSettings();
      this.storage.setItem(this.storageKey, toJson(this.settings));
    } else {
      this.settings = fromJson(this.storage.getItem(this.storageKey));
    }
  }
}

export const saveStateService = new SaveStateService();
