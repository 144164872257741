import { PanelistPoolSource, PanelistPoolType, SupplySource } from '../enums';
import {
  ActiveSupplyModel,
  CreatePanelistPoolResult,
  SupplyGroupCustomCpiSettings,
} from '../../target-groups/active-target-group/models/active-supply.model';
import { ActiveSupplyMixSource } from '../../target-groups/supply/supply-mix/supply-mix-state.service';
import { Constants } from '../../constants';
import { AvailablePanelistGroups } from '../http-services/supply.httpservice';

export interface PanelistPool {
  readonly source: PanelistPoolSource;
  readonly input: PanelistEntries;
  readonly rootPoolId: string;
  readonly selectedPoolId: string;
  readonly panelistCount: number;
  readonly panelIds: number[];
  readonly selectedGroup: keyof AvailablePanelistGroups | '';
  readonly hasAvailablePanelists: boolean;
  readonly type: PanelistPoolType;
  readonly containsUrl: boolean;
  readonly sourceUrl: string | null;
  readonly createPoolResult?: CreatePanelistPoolResult;
}

export interface SupplyGroup {
  readonly id: number;
  readonly name: string;
  readonly panelIds: number[];
  readonly percentage: number;
  readonly wantedCompletes: number;
  readonly wantedStarts?: number | undefined;
  readonly source: ActiveSupplyMixSource;
  readonly customCpi?: number;
  readonly useCustomCpi: boolean;
  readonly ccpiSettings?: SupplyGroupCustomCpiSettings;
}

export interface SupplyMix {
  readonly supplyGroups: SupplyGroup[];
  readonly ignoreCompletes: boolean;
}

export interface SupplyModel {
  readonly ownPanelCurrency?: string; // not saved in DB; figured out on backend for load draft and reuse
  readonly selectedPrivatePricingRateCard?: string; // not saved in DB; figured out on backend for load draft and reuse
  readonly supplySource: SupplySource;
  readonly selectedIds: number[];
  readonly adHocSupplier: any;
  readonly supplyMix: SupplyMix;
  readonly panelistPool: PanelistPool;
  readonly customCpi?: number;
  readonly useCustomCpi: boolean;
}

export function createSupplyModelFrom(supply: ActiveSupplyModel): SupplyModel {
  return {
    ownPanelCurrency: supply.ownPanelCurrency,
    selectedPrivatePricingRateCard: supply.selectedPrivatePricingRateCard,
    supplySource: supply.supplySource,
    selectedIds: supply.selectedIds,
    adHocSupplier: supply.adHoc,
    supplyMix: supply.supplyMix !== undefined ? supply.supplyMix : { supplyGroups: [], ignoreCompletes: false },
    panelistPool: {
      source: supply.panelistPool.source,
      input: !supply.panelistPool || !supply.panelistPool.input ? '' : supply.panelistPool.input,
      rootPoolId: supply.panelistPool.rootPoolId,
      selectedPoolId: supply.panelistPool.selectedPoolId,
      panelistCount: 0,
      panelIds: supply.panelistPool.panelIds,
      selectedGroup: supply.panelistPool.selectedGroup,
      hasAvailablePanelists: supply.panelistPool.hasAvailablePanelists,
      type: supply.panelistPool.type,
      containsUrl: supply.panelistPool.containsUrl,
      sourceUrl: supply.panelistPool.sourceUrl,
      createPoolResult: supply.panelistPool.createPoolResult,
    },
    customCpi: supply.customCpi,
    useCustomCpi: supply.useCustomCpi,
  };
}

export function createSupplyModel(): SupplyModel {
  return {
    ownPanelCurrency: undefined,
    selectedPrivatePricingRateCard: undefined,
    supplySource: SupplySource.SystemSelected,
    selectedIds: [],
    adHocSupplier: {},
    supplyMix: { supplyGroups: [], ignoreCompletes: false },
    useCustomCpi: false,
    panelistPool: {
      source: null,
      input: '',
      rootPoolId: Constants.emptyGuid,
      selectedPoolId: Constants.emptyGuid,
      panelistCount: 0,
      panelIds: [],
      selectedGroup: '',
      hasAvailablePanelists: true,
      type: PanelistPoolType.None,
      containsUrl: false,
      sourceUrl: null,
      createPoolResult: null,
    },
  };
}

// a string resulting from newline-joining a string array whose entries are either in the form of 'id' or 'id,url'
// string.join('\n', [
//   '1',
//   '2',
//   '3',
// ])
// or, when using the Unique Links feature
// string.join('\n', [
//   '1,http://url',
//   '2,http://url',
//   '3,http://url',
// ])
export type PanelistEntries = string;
