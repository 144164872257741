import { isEmpty } from 'lodash-es';
import { ActiveRegionsModel } from '../../target-groups/active-target-group/models/active-regions.model';

export interface RegionsModel {
  readonly selectedIds: number[];
  readonly regionTypeId: number;
  readonly postalCodes: any[];
  readonly mainRegionsAutomaticallySelected: boolean;
}

export function createRegionsModelFromActive(model: ActiveRegionsModel): RegionsModel {
  return {
    selectedIds: model.selectedIds,
    regionTypeId: !isEmpty(model.selectedRegionType) ? model.selectedRegionType.id : undefined,
    postalCodes: model.postalCodes,
    mainRegionsAutomaticallySelected: model.mainRegionsAutomaticallySelected,
  };
}

export function createRegionsModel(): RegionsModel {
  return {
    selectedIds: [],
    regionTypeId: undefined,
    postalCodes: [],
    mainRegionsAutomaticallySelected: false,
  };
}
