import { IDirective } from 'angular';
import { every } from 'lodash-es';
import { featureFlipper } from '../feature-flipper';

const directiveName = 'featureOnAll';

export function featureOnAll(): IDirective {
  return {
    link: (_scope, element, attrs) => {
      const featureIds = attrs[directiveName].split(/[,;|]/);

      if (!every(featureIds, (f) => featureFlipper.isEnabled(f))) {
        element.remove();
      }
    },
  };
}

export const ngFeatureOnAllDirective = {
  [directiveName]: featureOnAll,
};
