import { IPromise } from 'angular';
import { includes, find, isEmpty } from 'lodash-es';
import { SupplyGroup } from '../../../../common/models/supply.model';
import { PanelistPoolSource, SupplySource, PanelistPoolType } from '../../../../common/enums';
import {
  ActiveSupplyModel,
  ActiveSupplyGroup,
  PanelSources,
  EligiblePanelSourceKeys,
  Panel,
  createActiveSupplyModel,
  ActivePanelistPool,
} from '../../models/active-supply.model';
import { countryService } from '../../../country.service';
import { projectSettingsService } from '../../../../common/project-settings.service';
import { userService } from '../../../../common/user.service';
import { keys, isEmptyGuid } from '../../../../helpers';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { api } from '../../../../common/api';
import {
  AvailabilityResultResponse,
  AvailablePanelistGroups,
  CreatePanelistPoolResponse,
} from '../../../../common/http-services/supply.httpservice';

import { targetGroupValidator } from '../../../target-group-validator';
import { panelistPoolStorageService } from '../../../../common/panelist-pool-storage.service';
import { $q } from '../../../../ngimport';
import { ActiveBasicSettingsModel } from '../../models/active-basic-settings.model';
import { mutators } from './supply.mutators';
import { TargetGroupConstants } from '../../../target-group.constants';
import { feasibilityResults } from '../../../feasibility-results';
import {
  getSelectedRateCard,
  getActiveSupplySource,
  getPanelSourcesWithSelectedPanels,
  getSupplySourceFromPanelistPool,
  isAllowedToSelectPanel,
} from '../../../supply/supply-helper.functions';
import { logProduce } from '../../../../common/immer-wrapper';
import { SupplyGroupUpdate } from '../../../supply/supply-mix/supply-mix-state.service';
import { supplyStateStore } from '../supply-state.store';
import { featureFlipper } from '../../../../common/feature-flipper';
import { Constants } from '../../../../constants';
import { externalIdSources } from '../../../supply/panelist-pool/supply-panelist-pool-helper.functions';
import { panelistPoolApiInputString } from '../../../exclude-projects/ExcludePanelistPool';

export class SupplySubactions {
  createSupplySourcesPromise(
    state: ActiveSupplyModel,
    basicSettings: ActiveBasicSettingsModel
  ): IPromise<ActiveSupplyModel> {
    return this.shouldAskForSupply(basicSettings)
      ? this.getSupplyTypesForCountry(
          state,
          basicSettings.countryId,
          basicSettings.estimatedIncidenceRate,
          basicSettings.estimatedLengthOfInterview,
          basicSettings.useFixedLoi
        )
      : $q.when().then(() => {
          return logProduce<ActiveSupplyModel>(state, () => {
            return createActiveSupplyModel();
          });
        });
  }

  setSupplySource(
    state: ActiveSupplyModel,
    supplySource: SupplySource,
    dontDeletePanelistPool = false
  ): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.selectSupplySource(supplySource)(draft);

      if (state.panelistPool.type !== PanelistPoolType.Exclusive && !dontDeletePanelistPool) {
        // TODO: should this side effect really be inside the `produce`? move to SupplyActions.setSupplySource?
        panelistPoolStorageService.delete(state.panelistPool.sourceUrl);
        const isConnectedUser = userService.isConnectUser();
        mutators.resetPanelistPool(isConnectedUser, supplySource)(draft);
      }

      mutators.setOwnPanelCurrency()(draft);
      mutators.setSelectedPrivatePricingRateCard(
        getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds)
      )(draft);
    });
  }

  setIgnoreCompletesForSupplyMix(state: ActiveSupplyModel, ignoreCompletes: boolean): ActiveSupplyModel {
    return logProduce(state, mutators.setIgnoreCompletesForSupplyMix(ignoreCompletes));
  }

  setCustomCpi(state: ActiveSupplyModel, customCpi: number): ActiveSupplyModel {
    return logProduce(state, mutators.setCustomCpi(customCpi));
  }

  clearCustomCpi(state: ActiveSupplyModel): ActiveSupplyModel {
    return logProduce(state, mutators.clearCustomCpi());
  }

  setPanelistPoolSupplySource(state: ActiveSupplyModel): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.setPanelistPoolSupplySource()(draft);
      mutators.setSelectedPrivatePricingRateCard(
        getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds)
      )(draft);
    });
  }

  togglePanelSource(state: ActiveSupplyModel, panelId: number, source: SupplySource): ActiveSupplyModel {
    return this.internalTogglePanel(state, panelId, source);
  }

  togglePanel(state: ActiveSupplyModel, panelId: number): ActiveSupplyModel {
    return this.internalTogglePanel(state, panelId, getActiveSupplySource(state) as EligiblePanelSourceKeys);
  }

  setPanelsSelection(state: ActiveSupplyModel, panelIds: number[], selected: boolean): ActiveSupplyModel {
    return this.internalSelectPanels(
      state,
      panelIds,
      selected,
      getActiveSupplySource(state) as EligiblePanelSourceKeys
    );
  }

  setIncludeLockedPanelsFromFeasibility(
    state: ActiveSupplyModel,
    targetGroupId: number,
    numberOfCompletes: number
  ): ActiveSupplyModel {
    const feasibilityMatch = find(feasibilityResults.getFeasibilities(), (result) => {
      return result.targetGroupId === targetGroupId;
    });

    return logProduce(state, (draft) => {
      draft.includeLockedPanels =
        feasibilityMatch.feasibility.defaultFeasibility.wantedNumberOfCompletes < numberOfCompletes;
    });
  }

  selectAllAvailableFromSupplyType(state: ActiveSupplyModel): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.selectAllAvailableFromSupplyType(getActiveSupplySource(state))(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });
  }

  deselectAllFromSupplyType(state: ActiveSupplyModel): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.deselectAllFromSupplyType(getActiveSupplySource(state))(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });
  }

  createAdHocSuppliersAndLanguagesPromise(state: ActiveSupplyModel): IPromise<ActiveSupplyModel> {
    const newSupplyState = logProduce(state, mutators.setAdHocSupplySource());

    return this.createSuppliersPromise(newSupplyState).then((newState) => {
      return this.createLanguagesPromise(newState);
    });
  }

  loadAdHocSuppliersAsync(state: ActiveSupplyModel): IPromise<ActiveSupplyModel> {
    return this.createSuppliersPromise(state);
  }

  setLanguage(state: ActiveSupplyModel, languageId: number): ActiveSupplyModel {
    return logProduce(state, mutators.setAdHocLanguage(languageId));
  }

  toggleAdHoc(state: ActiveSupplyModel, supplierId: number): ActiveSupplyModel {
    return logProduce(state, mutators.toggleAdhoc(supplierId));
  }

  importExternalPanelistPool(
    state: ActiveSupplyModel,
    url: string,
    panelistPoolType: PanelistPoolType,
    countryId: number
  ): IPromise<ActiveSupplyModel> {
    return panelistPoolStorageService.getFromUrl(url).then((response) => {
      const newState = logProduce(state, mutators.setExternalPanelistPool(url, response?.poolId, panelistPoolType));
      return this.createPanelistPoolOutputPromise(newState, countryId, response?.poolId);
    });
  }

  importInternalPanelistPool(
    state: ActiveSupplyModel,
    panelistEntries: string,
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    containsUrl: boolean,
    countryId: number
  ): IPromise<ActiveSupplyModel> {
    return panelistPoolStorageService.getFromPanelistEntries(panelistEntries).then((response) => {
      const newState = logProduce(
        state,
        mutators.setInternalPanelistPool(poolSource, panelistEntries, panelistPoolType, response, containsUrl)
      );
      return this.createPanelistPoolOutputPromise(newState, countryId, response?.poolId);
    });
  }

  importFromPanelistPoolApi(
    state: ActiveSupplyModel,
    panelistPoolId: string,
    panelistPoolType: PanelistPoolType,
    countryId: number
  ): IPromise<ActiveSupplyModel> {
    const newState = logProduce(
      state,
      mutators.setFromPanelistPoolApi(PanelistPoolSource.AccessProject, panelistPoolId, panelistPoolType)
    );
    return this.createPanelistPoolOutputPromise(newState, countryId, panelistPoolId, true);
  }

  createPoolFromPanelistsEntries(
    state: ActiveSupplyModel,
    panelistEntries: string,
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    containsUrl: boolean
  ): IPromise<ActiveSupplyModel> {
    return panelistPoolStorageService.getFromPanelistEntries(panelistEntries).then((response) => {
      return logProduce(
        state,
        mutators.setInternalPanelistPool(poolSource, panelistEntries, panelistPoolType, response, containsUrl)
      );
    });
  }

  importInternalPanelistPoolFromExternalIds(
    state: ActiveSupplyModel,
    lookupJobId: string
  ): IPromise<ActiveSupplyModel> {
    return api.supply
      .createPanelistPoolFromExternalIds(lookupJobId)
      .then((response) => {
        return logProduce(state, mutators.setInternalPanelistPoolExternalIds(response.data));
      })
      .catch((error) => {
        targetGroupChannel.model.panelistPool.error.dispatch(error);
        return logProduce(state, (_draft) => {
          $q.when(state);
        });
      });
  }

  saveInternalPanelistPoolForExternalSource(
    state: ActiveSupplyModel,
    poolSource: PanelistPoolSource,
    createPoolResponse: CreatePanelistPoolResponse,
    panelistEntries: string
  ): ActiveSupplyModel {
    return logProduce(
      state,
      mutators.setInternalPanelistPool(
        poolSource,
        panelistEntries,
        PanelistPoolType.Inclusive,
        createPoolResponse,
        false
      )
    );
  }

  saveInternalPanelistPoolForInternalIds(
    state: ActiveSupplyModel,
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    panelistEntries: string,
    createPoolResponse: CreatePanelistPoolResponse,
    containsUrl: boolean
  ): ActiveSupplyModel {
    return logProduce(
      state,
      mutators.setInternalPanelistPool(poolSource, panelistEntries, panelistPoolType, createPoolResponse, containsUrl)
    );
  }

  loadExistingPanelistPool(
    state: ActiveSupplyModel,
    rootPoolId: string,
    selectedGroup: keyof AvailablePanelistGroups | '',
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    countryId: number
  ): IPromise<ActiveSupplyModel> {
    const newState = logProduce(
      state,
      mutators.setInternalPanelistPoolForConnectPool(poolSource, '', panelistPoolType, rootPoolId, selectedGroup, false)
    );
    return this.createPanelistPoolOutputPromise(newState, countryId, rootPoolId);
  }

  loadExternalIdsPanelistPool(
    state: ActiveSupplyModel,
    rootPoolId: string,
    selectedGroup: keyof AvailablePanelistGroups | '',
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    countryId: number
  ): IPromise<ActiveSupplyModel> {
    const newState = logProduce(
      state,
      mutators.setPanelistPoolForExternalIdsSource(
        poolSource,
        Constants.externalIdsInputToken,
        panelistPoolType,
        rootPoolId,
        selectedGroup,
        false
      )
    );
    return this.createPanelistPoolOutputPromise(newState, countryId, rootPoolId);
  }

  selectPanelistPool(
    state: ActiveSupplyModel,
    availabilityGroup: keyof AvailablePanelistGroups,
    poolId: string,
    panelIds: number[]
  ): ActiveSupplyModel {
    return logProduce(state, mutators.setSelectedPanelistPool(poolId, panelIds, availabilityGroup));
  }

  discardExclusionsPanelistPool(state: ActiveSupplyModel): ActiveSupplyModel {
    const isConnectedUser = userService.isConnectUser();
    if (!isEmpty(state.panelistPool.sourceUrl)) {
      panelistPoolStorageService.delete(state.panelistPool.sourceUrl);
    } else if (!isEmpty(state.panelistPool.input)) {
      panelistPoolStorageService.deletePanelistEntries(state.panelistPool.input);
    }
    return logProduce(state, (draft) => {
      mutators.resetPanelistPool(isConnectedUser)(draft);
    });
  }

  discardPanelistPool(state: ActiveSupplyModel): ActiveSupplyModel {
    const isConnectedUser = userService.isConnectUser();
    if (!isEmpty(state.panelistPool.sourceUrl)) {
      panelistPoolStorageService.delete(state.panelistPool.sourceUrl);
    } else if (!isEmpty(state.panelistPool.input)) {
      panelistPoolStorageService.deletePanelistEntries(state.panelistPool.input);
    }
    return logProduce(state, (draft) => {
      mutators.selectSupplySource(getSupplySourceFromPanelistPool(draft.panelistPool))(draft);
      mutators.resetPanelistPool(isConnectedUser)(draft);
    });
  }

  addSupplyMixGroup(
    state: ActiveSupplyModel,
    supplyGroup: ActiveSupplyGroup,
    panelSpecificProfiling: boolean,
    numberOfCompletes: number,
    numberOfStarts: number | undefined
  ): ActiveSupplyModel {
    const newState = logProduce(state, (draft) => {
      mutators.addSupplyMixGroup(supplyGroup)(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });

    return this.validateModel(newState, panelSpecificProfiling, numberOfCompletes, numberOfStarts);
  }

  removeSupplyMixGroup(
    state: ActiveSupplyModel,
    supplyGroupId: number,
    numberOfCompletes: number,
    panelSpecificProfiling: boolean,
    numberOfStarts: number | undefined
  ): ActiveSupplyModel {
    const newState = logProduce(state, (draft) => {
      mutators.removeSupplyMixGroup(supplyGroupId, numberOfCompletes)(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });

    return this.validateModel(newState, panelSpecificProfiling, numberOfCompletes, numberOfStarts);
  }

  updateSupplyMixGroups(state: ActiveSupplyModel, supplyGroupUpdates: SupplyGroupUpdate[]): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      supplyGroupUpdates.forEach((supplyGroupUpdate) => mutators.updateSupplyMixGroup(supplyGroupUpdate)(draft));
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });
  }

  // TODO: what to do with these functions that reads from activeTargetGroupStore.model directly?
  getNextSupplyMixGroupId(): number {
    const highestId = Math.max(...supplyStateStore.model.supply.supplyMix.supplyGroups.map((g) => g.id));
    if (highestId === -Infinity) return 1;
    return highestId + 1;
  }

  // TODO: what to do with these functions that reads from activeTargetGroupStore.model directly?
  isUniqueSupplyGroupName(supplyGroup: SupplyGroup | SupplyGroupUpdate): boolean {
    const groups = supplyStateStore.model.supply.supplyMix.supplyGroups.filter((group) => group.id !== supplyGroup.id);
    const existingSupplyGroup = find(groups, { name: supplyGroup.name.trim() });
    return existingSupplyGroup === undefined;
  }

  setSupplyGroupPercentage(
    state: ActiveSupplyModel,
    supplyGroupId: number,
    percentage: number,
    totalWantedCompletes: number,
    panelSpecificProfiling: boolean,
    totalWantedStarts: number | undefined
  ): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.setSupplyGroupPercentage(
        supplyGroupId,
        percentage,
        Math.floor((percentage / 100) * totalWantedCompletes),
        totalWantedStarts !== undefined ? Math.floor((percentage / 100) * totalWantedStarts) : undefined
      )(draft);

      if (draft.supplyMix.supplyGroups.reduce((acc, g) => acc + g.percentage, 0) === 100) {
        mutators.rescaleSupplyGroupsWantedCompletes(totalWantedCompletes)(draft);
        if (totalWantedStarts !== undefined) {
          mutators.rescaleSupplyGroupWantedStarts(totalWantedStarts)(draft);
        }
      }
      const validation = targetGroupValidator.validateSupply(
        draft,
        panelSpecificProfiling,
        totalWantedCompletes,
        totalWantedStarts
      );
      mutators.setValidationResult(validation)(draft);
    });
  }

  setSupplyGroupCustomCpi(state: ActiveSupplyModel, supplyGroupId: number, newCcpi: number): ActiveSupplyModel {
    return logProduce(state, mutators.setSupplyGroupCustomCpi(supplyGroupId, newCcpi));
  }

  rescaleSupplyGroupWantedCompletes(state: ActiveSupplyModel, wantedCompletes: number): ActiveSupplyModel {
    return logProduce(state, mutators.rescaleSupplyGroupsWantedCompletes(wantedCompletes));
  }

  rescaleSupplyGroupWantedStarts(state: ActiveSupplyModel, wantedStarts: number): ActiveSupplyModel {
    return logProduce(state, mutators.rescaleSupplyGroupWantedStarts(wantedStarts));
  }

  validateModel(
    state: ActiveSupplyModel,
    panelSpecificProfiling: boolean,
    numberOfCompletes: number,
    numberOfStarts: number | undefined
  ): ActiveSupplyModel {
    const validation = targetGroupValidator.validateSupply(
      state,
      panelSpecificProfiling,
      numberOfCompletes,
      numberOfStarts
    );
    return logProduce(state, mutators.setValidationResult(validation));
  }

  toggleUseCustomCpi(state: ActiveSupplyModel): ActiveSupplyModel {
    return logProduce(state, (draft) => {
      mutators.toggleUseCustomCpi()(draft);
    });
  }

  //TODO: I added poolId as parameter here since we have it in all places this is called from. Also made it private
  createPanelistPoolOutputPromise(
    state: ActiveSupplyModel,
    countryId: number,
    poolId: string,
    fromPanelistPoolApi = false
  ): IPromise<ActiveSupplyModel> {
    const data = {
      countryId,
      poolId,
      fromPanelistPoolApi,
    };
    return api.supply
      .checkPanelistAvailability(data)
      .then((response) => {
        return logProduce(state, (draft) => {
          mutators.setPanelistPoolAvailability(response.data)(draft);

          if (draft.panelistPool.type === PanelistPoolType.Exclusive) {
            mutators.selectRootPool(draft.panelistPool)(draft);
            draft.panelistPool.source = PanelistPoolSource.AccessProject; // TODO: Mutator?
          }
        });
      })
      .then((state) => {
        if (
          state.panelistPool.type === PanelistPoolType.Inclusive ||
          state.panelistPool.type === PanelistPoolType.Exclusive
        ) {
          targetGroupChannel.model.supplyState.panelistAvailabilityUpdated.dispatch(); // TODO: Should this be here?
        }
        return state;
      })
      .catch((error) => {
        console.log(error);
        targetGroupChannel.model.panelistPool.error.dispatch(error);
        return logProduce(state, (_draft) => {
          $q.when(state);
        });
      });
  }

  savePanelistPoolAvailability(state: ActiveSupplyModel, availabilityResult: AvailabilityResultResponse) {
    return logProduce(state, (draft) => {
      mutators.setPanelistPoolAvailability(availabilityResult)(draft);

      if (draft.panelistPool.type === PanelistPoolType.Exclusive) {
        mutators.selectRootPool(draft.panelistPool)(draft);
        draft.panelistPool.source = PanelistPoolSource.AccessProject; // TODO: Mutator?
      }
    });
  }

  private shouldAskForSupply(basicSettings: ActiveBasicSettingsModel): boolean {
    const countryId = basicSettings.countryId > 0 ? basicSettings.countryId : undefined;

    const validation = basicSettings.validationResult; // TODO: do I need to actually do a new Validation or just take the existing one?

    return (
      countryId !== undefined &&
      validation.estimatedIncidenceRate.isValid &&
      validation.estimatedLengthOfInterview.isValid
    );
  }

  // This is called get but definitely sets stuff on the state.
  private getSupplyTypesForCountry(
    state: ActiveSupplyModel,
    countryId: number,
    estimatedIncidenceRate: string | number,
    estimatedLengthOfInterview: string | number,
    useFixedLoi: boolean
  ): IPromise<ActiveSupplyModel> {
    return countryService.countryHasAvailablePanels(countryId)
      ? this.callSupplyPanelSources(state, countryId, estimatedIncidenceRate, estimatedLengthOfInterview, useFixedLoi)
      : this.createAdHocSuppliersAndLanguagesPromise(state);
  }

  private callSupplyPanelSources(
    state: ActiveSupplyModel,
    countryId: number,
    estimatedIncidenceRate: string | number,
    estimatedLengthOfInterview: string | number,
    useFixedLoi: boolean
  ): IPromise<ActiveSupplyModel> {
    const postData = {
      ir: estimatedIncidenceRate,
      loi: estimatedLengthOfInterview,
      categoryIds: projectSettingsService.settings.categoryIds,
      filterOnPii: projectSettingsService.settings.filterOnPii,
      filterOnWebcam: projectSettingsService.settings.requireWebcam,
    };

    return api.supply
      .getSupply(countryId, postData)
      .then((response) => {
        const panelSources = {} as PanelSources;
        for (const key of keys(response.data)) {
          panelSources[key] = response.data[key].map(
            (panel): Panel => ({
              ...panel,
              supportsCustomCpi:
                panel.supportsCustomCpi &&
                (key !== SupplySource.CintPanels || featureFlipper.isEnabled('customCpiOpenExchange')),
              panelistCount: 0,
              feasibleCompletes: null,
            })
          );
        }

        const newState = logProduce(state, mutators.setPanelSources(panelSources));
        return this.setSupplySourceOnLoad(newState, useFixedLoi);
      })
      .then((newState) => {
        if (this.isSupplySourceWithPanels(newState.supplySource)) {
          newState = logProduce(newState, (draft) => {
            mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(
              draft
            );
          });
        }
        const { panelistPool } = newState;
        const isExternalIdsPool =
          includes(externalIdSources, panelistPool.source) && panelistPool.input === Constants.externalIdsInputToken;

        if (this.hasPanelistPoolId(panelistPool) && isExternalIdsPool) {
          return this.loadExternalIdsPanelistPool(
            newState,
            panelistPool.rootPoolId,
            panelistPool.selectedGroup,
            panelistPool.type,
            panelistPool.source,
            countryId
          );
        }
        if (this.hasPanelistPoolInput(panelistPool)) {
          if (panelistPool.input === panelistPoolApiInputString)
            return this.importFromPanelistPoolApi(newState, panelistPool.rootPoolId, panelistPool.type, countryId);

          return this.importInternalPanelistPool(
            newState,
            panelistPool.input,
            panelistPool.type,
            panelistPool.source,
            panelistPool.containsUrl,
            countryId
          );
        }
        if (this.hasPanelistPoolUrl(panelistPool)) {
          return this.importExternalPanelistPool(newState, panelistPool.sourceUrl, panelistPool.type, countryId);
        }

        if (this.hasPanelistPoolId(panelistPool)) {
          return this.loadExistingPanelistPool(
            newState,
            panelistPool.rootPoolId,
            panelistPool.selectedGroup,
            panelistPool.type,
            panelistPool.source,
            countryId
          );
        }
        return newState;
      })
      .then((newState) => {
        return this.hasAdHocSupplier(newState)
          ? this.createAdHocSuppliersAndLanguagesPromise(newState)
          : $q.when(newState);
      });
  }

  private determineSupplySourceOnLoad(state: ActiveSupplyModel): SupplySource {
    const nonPanelSources = [SupplySource.AdHoc, SupplySource.PanelistPool];
    if (includes(nonPanelSources, state.supplySource)) return state.supplySource;

    const selectedSource = state.supplySource;

    if (!isEmpty(selectedSource)) {
      if (this.isSupplySourceWithPanels(selectedSource)) {
        if (selectedSource === SupplySource.UnspecifiedPanels) {
          return this.determinePanelSupplySource(getPanelSourcesWithSelectedPanels(state));
        }
        return selectedSource;
      }
    }
    return SupplySource.SystemSelected;
  }

  private setSupplySourceOnLoad(state: ActiveSupplyModel, useFixedLoi: boolean): ActiveSupplyModel {
    if (state.supplySource === SupplySource.AdHoc) return state;

    const supplySource = this.determineSupplySourceOnLoad(state);

    // TODO: Make only one mutator for all this maybe?
    return logProduce(state, (draft) => {
      draft.supplySource = supplySource;

      if (useFixedLoi) {
        mutators.resetLoiDisqualifiedPanels()(draft);
      }

      if (this.isSupplySourceWithPanels(supplySource)) {
        if (!isEmpty(state.selectedIds)) {
          mutators.selectNonDisqualifiedPanels()(draft);
        }
      }
    });
  }

  private isSupplySourceWithPanels(supplySource: SupplySource): boolean {
    return (
      includes(TargetGroupConstants.eligiblePanelSources, supplySource) ||
      supplySource === SupplySource.UnspecifiedPanels ||
      supplySource === SupplySource.SupplyMix ||
      supplySource === SupplySource.PanelistPool
    );
  }

  private determinePanelSupplySource(selectedPanelSources: { [key in EligiblePanelSourceKeys]: Panel[] }):
    | EligiblePanelSourceKeys
    | SupplySource.SupplyMix {
    const selectedPanelGroups = keys(selectedPanelSources).filter((source) => selectedPanelSources[source].length > 0);

    if (selectedPanelGroups.length > 1) return SupplySource.SupplyMix;
    return selectedPanelGroups[0];
  }

  private createSuppliersPromise(state: ActiveSupplyModel): IPromise<ActiveSupplyModel> {
    return api.supply.getAdHocSuppliers().then((response) => {
      return logProduce(state, mutators.setAdhocSuppliers(response.data));
    });
  }

  private createLanguagesPromise(state: ActiveSupplyModel): IPromise<ActiveSupplyModel> {
    return api.supply.getAdHocSupplierLanguages().then((response) => {
      return logProduce(state, mutators.setAdhocLanguages(response.data));
    });
  }

  private hasPanelistPoolInput(panelistPool: ActivePanelistPool): boolean {
    return !isEmpty(panelistPool.input);
  }

  private hasPanelistPoolUrl(panelistPool: ActivePanelistPool): any {
    return !isEmpty(panelistPool.sourceUrl);
  }

  private hasPanelistPoolId(panelistPool: ActivePanelistPool): any {
    return !isEmptyGuid(panelistPool.rootPoolId);
  }

  private hasAdHocSupplier(state: ActiveSupplyModel): boolean {
    return getActiveSupplySource(state) === SupplySource.AdHoc;
  }

  private internalTogglePanel(state: ActiveSupplyModel, panelId: number, source: SupplySource): ActiveSupplyModel {
    const activeSupplySource = source as EligiblePanelSourceKeys;
    const sourcePanels = state.panelSources[activeSupplySource];
    const panel = find(sourcePanels, (p) => p.id === panelId);
    if (!isAllowedToSelectPanel(panel)) return state;

    return logProduce(state, (draft) => {
      mutators.togglePanel(panelId, activeSupplySource)(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });
  }

  private internalSelectPanels(
    state: ActiveSupplyModel,
    panelIds: number[],
    selected: boolean,
    source: SupplySource
  ): ActiveSupplyModel {
    const activeSupplySource = source as EligiblePanelSourceKeys;
    const sourcePanels = state.panelSources[activeSupplySource];
    const selectablePanelIds = sourcePanels
      .filter((p) => includes(panelIds, p.id) && isAllowedToSelectPanel(p))
      .map((p) => p.id);

    return logProduce(state, (draft) => {
      mutators.setPanelSelection(selectablePanelIds, selected)(draft);
      mutators.updatePanelsState(getSelectedRateCard(draft.supplySource, draft.panelSources, draft.selectedIds))(draft);
    });
  }
}

export const supplySubactions = new SupplySubactions();
