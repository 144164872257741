import './template-filters.component.scss';
import { map, includes } from 'lodash-es';
import { ListenerComponent } from '../../common/channels/channel';
import { MenuListItem } from '../../common/controls/options-menu/options-menu.component';
import { api } from '../../common/api';
import { countryService } from '../../target-groups/country.service';
import { TemplatesListType } from '../../common/enums';
import { TemplatesListItem } from './templates-list.component';
import { html } from '../../helpers';

const selector = 'templateFiltersComponent';

const template = html`
  <div class="template-filters-component">
    <input
      type="text"
      ng-model="$ctrl.model.templateName"
      ng-change="$ctrl.filterChanged()"
      class="name-filter"
      placeholder="Filter by name"
    />
    <div class="select-container country-select">
      <ui-select class="ui-select ui-select-bootstrap" ng-model="$ctrl.model.country" ng-change="$ctrl.filterChanged()">
        <ui-select-match class="ui-select-match" placeholder="Filter by country"
          >{{$select.selected.name}}</ui-select-match
        >
        <ui-select-choices
          class="ui-select-choices"
          repeat="country in $ctrl.countries | filter: {name: $select.search} track by country.id"
          ><span ng-bind-html="country.name | highlight: $select.search"></span>
        </ui-select-choices>
      </ui-select>
    </div>
    <div class="select-container owner-select">
      <ui-select
        class="ui-select ui-select-bootstrap"
        ng-model="$ctrl.model.onlyMyTemplates"
        ng-change="$ctrl.filterChanged()"
        search-enabled="false"
      >
        <ui-select-match class="ui-select-match">{{$select.selected.name}}</ui-select-match>
        <ui-select-choices
          class="ui-select-choices"
          repeat="item.value as item in [{ name: 'All templates', value: false }, { name: 'My templates', value: true }] track by item.value"
        >
          <span ng-bind-html="item.name"></span>
        </ui-select-choices>
      </ui-select>
    </div>
    <div class="clear-btn ui-btn full-btn default-btn" ng-click="$ctrl.clearFilter()">Clear</div>
  </div>
`;

interface Bindings {
  filterTemplates: '&' | ((_: { countryId: number; onlyMyTemplates: boolean; templateName: string }) => void);
}

const bindings: Bindings = {
  filterTemplates: '&',
};

export class TemplateFiltersComponent extends ListenerComponent implements Bindings {
  isLoading: boolean;
  templates: TemplatesListItem[] = [];
  filteredTemplates: TemplatesListItem[];
  userOwnsAllTemplates: boolean;
  templatesListType: TemplatesListType;
  onMenuOptionClicked: (_: { option: MenuListItem; item: TemplatesListItem }) => void;
  filterTemplates: (_: { countryId: number; onlyMyTemplates: boolean; templateName: string }) => void;

  model: {
    country?: {
      id: number;
      name: string;
    };
    templateName?: string;
    onlyMyTemplates: boolean;
  };

  countries: {
    id: number;
    name: string;
  }[];

  $onInit(): void {
    this.model = { country: null, templateName: null, onlyMyTemplates: false };
    this.getTemplatesCountries();
    this.filterChanged();
  }

  clearFilter(): void {
    this.model.country = undefined;
    this.model.templateName = undefined;
    this.model.onlyMyTemplates = false;
    this.filterTemplates({ countryId: undefined, onlyMyTemplates: false, templateName: undefined });
  }

  filterChanged(): void {
    const countryId = this.model.country ? this.model.country.id : undefined;
    const templateName = this.model.templateName ? this.model.templateName : undefined;
    const { onlyMyTemplates } = this.model;
    this.filterTemplates({ countryId, onlyMyTemplates, templateName });
  }

  private getTemplatesCountries(): void {
    if (!this.countries) {
      api.template.getTemplateCountries().then((res) => {
        this.countries = map(countryService.countries, (c) => {
          return {
            id: c.id,
            name: c.name,
          };
        }).filter((c) => includes(res.data, c.id));
      });
    }
  }
}

export const ngTemplateFiltersComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TemplateFiltersComponent,
  },
};
