import './project-redirect-links.control.scss';
import { react2angular } from 'react2angular';
import { LabeledInput } from './LabeledInput';

interface Props {
  earlyScreenoutLink: string;
  quotaFullLink: string;
  completeLink: string;
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const ProjectRedirectLinks: React.FC<Props> = ({ earlyScreenoutLink, quotaFullLink, completeLink }) => {
  return (
    <div className="project-redirect-links">
      <LabeledInput
        verticalOrientation
        type="text"
        name="quotaFull"
        value={quotaFullLink}
        title={quotaFullLink}
        label="Quota full"
        maxLength={255}
        readonly
        buttonVisible={navigator.clipboard?.writeText !== undefined}
        buttonClick={() => copyToClipboard(quotaFullLink)}
        buttonIcon="fa fa-clipboard"
        buttonFeedback={{
          iconClicked: 'fa fa-clipboard-check',
          iconClickedText: 'Copied',
        }}
        buttonTitle="Copy to clipboard"
      />

      <LabeledInput
        verticalOrientation
        type="text"
        name="earlyScreenOut"
        value={earlyScreenoutLink}
        title={earlyScreenoutLink}
        label="Screen out"
        maxLength={255}
        readonly
        buttonVisible={navigator.clipboard?.writeText !== undefined}
        buttonClick={() => copyToClipboard(earlyScreenoutLink)}
        buttonIcon="fa fa-clipboard"
        buttonFeedback={{
          iconClicked: 'fa fa-clipboard-check',
          iconClickedText: 'Copied',
        }}
        buttonTitle="Copy to clipboard"
      />

      <LabeledInput
        verticalOrientation
        type="text"
        name="complete"
        value={completeLink}
        title={completeLink}
        label="Complete"
        maxLength={255}
        readonly
        buttonVisible={navigator.clipboard?.writeText !== undefined}
        buttonClick={() => copyToClipboard(completeLink)}
        buttonIcon="fa fa-clipboard"
        buttonFeedback={{
          iconClicked: 'fa fa-clipboard-check',
          iconClickedText: 'Copied',
        }}
        buttonTitle="Copy to clipboard"
      />
    </div>
  );
};

export const ngProjectRedirectLinks = {
  projectRedirectLinks: react2angular(ProjectRedirectLinks, ['quotaFullLink', 'earlyScreenoutLink', 'completeLink']),
};
