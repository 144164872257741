import './modal-close-button.component.scss';
import { html } from '../../helpers';

const selector = 'modalCloseButtonComponent';

const template = html`
  <div class="modal-close-button-component">
    <div class="modal-close-button-container">
      <div
        class="sticky-close-button"
        ng-class="{'disabled-close-button': $ctrl.disabled}"
        ng-click="$ctrl.closeAction()"
      >
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>
`;

interface Bindings {
  closeAction: '&' | (() => void);
  disabled: '<' | boolean;
}

const bindings: Bindings = {
  closeAction: '&',
  disabled: '<',
};

export class ModalCloseButtonComponent implements Bindings {
  static componentName = selector;
  closeAction: () => void;
  disabled: boolean;
}

export const ngModalCloseButtonComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ModalCloseButtonComponent,
  },
};
