import { IHttpInterceptor, IRequestConfig } from 'angular';
import { endsWith } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { Constants } from './constants';
import { RuntimeSettings } from './runtime-settings';
import { $window, $q } from './ngimport';
import { Product } from './common/enums';
import { session } from './common/session';
import { currentWorkService } from './common/current-work.service';
import { analytics } from './common/analytics';

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
}

function redirectInterceptor(): IHttpInterceptor {
  return {
    response: (response) => {
      if (response.status === StatusCode.Unauthorized) {
        $window.location.assign(RuntimeSettings.externalUrls.login);
      }

      return response || $q.when(response);
    },
    responseError: (rejection) => {
      if (rejection && rejection.status === StatusCode.Unauthorized) {
        $window.location.assign(RuntimeSettings.externalUrls.login);
      }

      return $q.reject(rejection);
    },
  };
}

function forbiddenInterceptor(): IHttpInterceptor {
  return {
    response: (response) => {
      if (response.status === StatusCode.Forbidden) {
        $window.location.assign(Constants.accessDeniedPageUrl);
      }

      return response || $q.when(response);
    },
    responseError: (rejection) => {
      if (rejection && rejection.status === StatusCode.Forbidden) {
        $window.location.assign(Constants.accessDeniedPageUrl);
      }

      return $q.reject(rejection);
    },
  };
}

function unauthorizedInterceptor(): IHttpInterceptor {
  return {
    response: (response) => {
      if (response.status === StatusCode.Unauthorized) {
        $window.location.assign(`${RuntimeSettings.apiUrl}/login`);
      }

      return response || $q.when(response);
    },
    responseError: (rejection) => {
      if (rejection && rejection.status === StatusCode.Unauthorized) {
        $window.location.assign(`${RuntimeSettings.apiUrl}/login`);
      }

      return $q.reject(rejection);
    },
  };
}

function productHeaderName(product: Product) {
  if (product === Product.Access) return 'access';
  if (product === Product.AccessPro) return 'accessPro';
  if (product === Product.ManagedServices) return 'ManagedServices';

  throw new Error('Invalid product');
}

function productModeInterceptor(): IHttpInterceptor {
  return {
    request: (request) => {
      request.headers[RuntimeSettings.productMode.headerName] = productHeaderName(session.product);

      return request || $q.when(request);
    },
  };
}

function usageTrackingInterceptor(): IHttpInterceptor {
  return {
    request: (request) => {
      request.headers[RuntimeSettings.usageTracking.setupSessionIdHeaderName] = currentWorkService.projectSetupId;
      return request || $q.when(request);
    },
  };
}

function errorReportingInterceptor(): IHttpInterceptor {
  return {
    request: (request) => {
      (request as any).timestamp = new Date().getTime();
      return request || $q.when(request);
    },
    requestError: (rejection) => {
      const cfg = rejection.config;
      const startTime = cfg.timestamp;
      if (shouldLog(rejection.status, cfg.url)) {
        log(cfg, false, rejection.status, startTime);
      }
      return $q.reject(rejection);
    },
    response: (response) => {
      const cfg = response.config;
      const startTime = (cfg as any).timestamp;
      if (shouldLog(response.status, cfg.url)) {
        log(cfg, true, response.status, startTime);
      }
      return response || $q.when(response);
    },
    responseError: (rejection) => {
      const cfg = rejection.config;
      const startTime = cfg.timestamp;
      if (shouldLog(rejection.status, cfg.url)) {
        log(cfg, false, rejection.status, startTime);
      }
      return $q.reject(rejection);
    },
  };
}

function frontendVersionInterceptor(): IHttpInterceptor {
  return {
    request: (request) => {
      request.headers['Cint-Access-Frontend-Version'] = RuntimeSettings.buildNumber;

      return request || $q.when(request);
    },
  };
}

function correlationIdInterceptor(): IHttpInterceptor {
  return {
    request: (request) => {
      request.headers['X-Correlation-Id'] = uuid();
      return request || $q.when(request);
    },
  };
}

export const interceptors = {
  redirectInterceptor,
  forbiddenInterceptor,
  unauthorizedInterceptor,
  errorReportingInterceptor,
  productModeInterceptor,
  usageTrackingInterceptor,
  frontendVersionInterceptor,
  correlationIdInterceptor,
};

function shouldLog(status: number, url: string): boolean {
  if (status === -1) return false;
  if (endsWith('.html', url)) return false;
  return true;
}

function log(cfg: IRequestConfig, success: boolean, status: number, startTime: any): void {
  analytics.appInsights?.trackDependencyData({
    id: cfg.url,
    name: cfg.url,
    success,
    duration: new Date().getTime() - startTime,
    responseCode: status,
  });
}
