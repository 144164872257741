import Dexie from 'dexie';
import { ExistingProject } from './http-services/existing-project.models';
import { ProjectPrice } from './pricing/price-models';
import { TargetGroupModel } from './models/target-group.model';

export interface SessionInfoTable {
  uuid: string;
  buildNumber: number;
  createdAt: string;
}

export interface ExistingProjectTable {
  key: string;
  project: ExistingProject;
  price: ProjectPrice;
}

export interface TargetGroupTable {
  key: string;
  suuid: string;
  id: number;
  targetGroup: TargetGroupModel;
}

export interface FeasibilityResponseTable {
  workingDocumentId: string;
  date: number;
  feasibilityResponsePairs: string[][];
}

export class Db extends Dexie {
  sessionInfo: Dexie.Table<SessionInfoTable, string>;
  existingProject: Dexie.Table<ExistingProjectTable, string>;
  targetGroups: Dexie.Table<TargetGroupTable, string>;
  migratedTgs: Dexie.Table<TargetGroupTable, string>;
  feasibilityResponses: Dexie.Table<FeasibilityResponseTable, string>;

  constructor() {
    super('AppDatabase');
    this.defineVersions();
    this.sessionInfo = this.table('sessionInfo');
    this.existingProject = this.table('existingProject');
    this.targetGroups = this.table('targetGroups');
    this.migratedTgs = this.table('migratedTgs');
    this.feasibilityResponses = this.table('feasibilityResponses');
  }

  private defineVersions() {
    this.version(1).stores({
      sessionInfo: 'uuid',
      existingProjectWithPrice: 'key',
    });
    this.version(2).stores({
      sessionInfo: 'uuid',
      existingProject: 'key',
      existingProjectWithPrice: null,
    });
    this.version(3).stores({
      sessionInfo: 'uuid,buildNumber',
      existingProject: 'key',
    });
    this.version(4).stores({
      sessionInfo: 'uuid,buildNumber',
      existingProject: 'key',
    });
    this.version(5).stores({
      sessionInfo: 'uuid,buildNumber',
      existingProject: 'key',
      targetGroups: '[key+id]',
    });
    this.version(6)
      .stores({
        sessionInfo: 'uuid,buildNumber',
        existingProject: 'key',
        migratedTgs: 'key,suuid',
        targetGroups: '[key+id]',
      })
      .upgrade(async (tx) => {
        const tgs = await tx.table('targetGroups').toArray();
        const migratedTgs: TargetGroupTable[] = tgs.map((v) => ({ ...v, key: `${v.key}:${v.id}`, suuid: v.key }));
        return tx.table('migratedTgs').bulkAdd(migratedTgs);
      });
    this.version(7).stores({
      sessionInfo: 'uuid,buildNumber',
      existingProject: 'key',
      migratedTgs: 'key,suuid',
      targetGroups: null,
    });
    this.version(8)
      .stores({
        sessionInfo: 'uuid,buildNumber',
        existingProject: 'key',
        migratedTgs: 'key,suuid',
        targetGroups: 'key,suuid',
      })
      .upgrade(async (tx) => {
        const tgs = await tx.table('migratedTgs').toArray();
        return tx.table('targetGroups').bulkAdd(tgs);
      });
    this.version(9).stores({
      sessionInfo: 'uuid,buildNumber',
      existingProject: 'key',
      migratedTgs: 'key,suuid',
      targetGroups: 'key,suuid',
      feasibilityResponses: '&workingDocumentId,date',
    });
  }
}

export const db = new Db();
