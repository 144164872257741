import { IHttpPromise, IRequestShortcutConfig } from 'angular';
import { Array, Boolean, Dictionary, Null, Number, Record, Static, String, Union, Literal, Optional } from 'runtypes';
import { isEmpty } from 'lodash-es';
import { Currency } from './pricing.httpservice';
import { ProjectTemplateSource } from '../models/target-group.model';
import { ExcludedStatusWithTimeline } from './existing-project.models';
import { WeightingStrategy } from '../models/quotas.model';
import { Bucket, QuotaWithBuckets } from '../../target-groups/quotas/quota-bucket-builder';
import { ActiveSupplyMixSource } from '../../target-groups/supply/supply-mix/supply-mix-state.service';
import { PanelistPoolType, BackgroundDataJobStatus, PanelistPoolSource, SupplySource } from '../enums';
import { AvailablePanelistGroups } from './supply.httpservice';
import { urlService } from './url.service';
import { FeasibilityOptions } from '../global-feasibility-options';
import { ProjectSettings } from '../project-settings.service';
import { AboveZero, GuidString, HttpUrl, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { ProjectTemplateTargetGroup } from './draft.httpservice';
import { ExistingProjectResponse, ProjectLiveDataResponse } from './existing-project.responses';
import { $http } from '../../ngimport';
import { AutoDeliveryStatusResponse } from './target-group.httpservice';

import { globalDebugOptions } from '../global-debug-options';
import { ProjectDescriptionResponse, ProjectGroupResponse, ProjectGroupsResponse } from '../api';
import { EndPageSecurityType } from '../../target-groups/project-order/ProjectOrderLinksSetup';
import { MoneyResponse } from './project-price.response';
import { Money } from '../pricing/price-models';

export const TargetGroupAutoDeliveryStatus = Record({
  targetGroupId: AboveZero,
  status: AutoDeliveryStatusResponse,
});

export type TargetGroupAutoDeliveryStatusResponse = Static<typeof TargetGroupAutoDeliveryStatus>;

export const AutoDeliveryStatuses = Array(TargetGroupAutoDeliveryStatus);

export type AutoDeliveryStatusesResponse = Static<typeof AutoDeliveryStatuses>;

export const RedirectLinks = Record({
  quotaFull: HttpUrl,
  earlyScreenout: HttpUrl,
  complete: HttpUrl,
});
export type RedirectLinks = Static<typeof RedirectLinks>;

export const RedirectLinksResponse = Record({
  token: GuidString,
  generic: RedirectLinks,
  specific: RedirectLinks,
});
export type RedirectLinksResponse = Static<typeof RedirectLinksResponse>;

export const ProjectSettingsResponse = Record({
  categoryIds: Array(AboveZero),
  filterOnPii: Boolean,
  checkGeoIp: Boolean,
  useDeduplication: Boolean,
  suitableForMobile: Boolean,
  suitableForTablet: Boolean,
  suitableForDesktop: Boolean,
  requireWebcam: Boolean,
  displayCurrency: Currency.Or(Null),
});
export type ProjectSettingsResponse = Static<typeof ProjectSettingsResponse>;

export const TargetGroupPriceItem = Record({
  id: AboveZero,
  completes: Record({
    wanted: Number,
    maximumFromLockedPanels: Number,
  }),
  suggestedNumberOfInvites: Number,
  cpi: MoneyResponse,
  price: MoneyResponse,
  isUsingCustomCpi: Boolean,
  includesInternalPrice: Boolean,
});
export type TargetGroupPriceItem = Static<typeof TargetGroupPriceItem>;

export const PrepareProjectResponse = Record({
  workingDocumentId: NonEmptyString,
  totalNumberOfCompletes: Number,
  totalNumberOfStarts: Optional(Number.Or(Null)),
  totalPrice: MoneyResponse,
  volumeDiscount: MoneyResponse,
  priceWithoutDiscount: MoneyResponse,
  priceItems: Array(TargetGroupPriceItem),
  feasibilityResponsePairs: Array(Array(String)),
});
export type PrepareProjectResponse = Static<typeof PrepareProjectResponse>;

export const ProjectHistory = Record({
  rejected: Boolean,
  current: NonEmptyString,
  projectEvents: Array(
    Record({
      name: NonEmptyString,
      completed: Boolean,
    })
  ),
});

export type ProjectHistory = Static<typeof ProjectHistory>;

export type ChangeDetailsTable = Static<typeof ChangeDetailsTable>;
export const ChangeDetailsTable = Record({
  columnHeaders: Array(String).Or(Null),
  rows: Array(
    Record({
      header: NonEmptyString,
      values: Array(String.Or(Null)),
    })
  ).Or(Null),
  isEmpty: Boolean,
});

export type ProjectChangeEvent = Static<typeof ProjectChangeEvent>;

export const ProjectChangeEvent = Record({
  targetGroup: Record({
    name: NonEmptyString.Or(Null),
    id: AboveZero,
  }).Or(Null),
  quotaName: NonEmptyString.Or(Null),
  occuredAt: Iso8601UtcString,
  eventName: NonEmptyString,
  userName: NonEmptyString,
  eventData: Optional(String),
  details: ChangeDetailsTable,
});

export const ProjectChangeEventResponse = Array(ProjectChangeEvent);

export type ProjectChangeEventResponse = Static<typeof ProjectChangeEventResponse>;

export const CreateProjectResponse = Record({
  projectId: AboveZero,
});
export type CreateProjectResponse = Static<typeof CreateProjectResponse>;

export const ValidateAddTargetGroupsResponse = Boolean;
export type ValidateAddTargetGroupsResponse = Static<typeof ValidateAddTargetGroupsResponse>;

export const AddTargetGroupsResponse = Record({
  projectId: AboveZero,
});
export type AddTargetGroupsResponse = Static<typeof AddTargetGroupsResponse>;

export const ReusableProject = Record({
  targetGroups: Array(ProjectTemplateTargetGroup),
  projectSettings: ProjectSettingsResponse,
});
export type ReusableProject = Static<typeof ReusableProject>;

export const ReplaceableTargetGroups = Record({
  targetGroups: Array(ProjectTemplateTargetGroup),
  projectSettings: ProjectSettingsResponse,
});
export type ReplaceableProject = Static<typeof ReplaceableTargetGroups>;

export const ProjectCategoryResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  onlyShowForManagedServices: Boolean,
});
export type ProjectCategoryResponse = Static<typeof ProjectCategoryResponse>;

export const ProjectCategoriesResponse = Array(ProjectCategoryResponse);
export type ProjectCategoriesResponse = Static<typeof ProjectCategoriesResponse>;

export interface CreateProjectRequest {
  projectName: string;
  token: string;
  endPageSecurityType: EndPageSecurityType;
  poNumber: string;
  jobNumber: string;
  contactName: string;
  contactEmail: string;
  additionalEmails: string[];
  contactPhone: string;
  targetGroupSurveyLinksItems: TargetGroupSurveyLinksItems[];
  workingDocumentId: string;
  replacesProjectId?: number;
  billingEntityId?: number;
  feasibilityResponsePairs: string[][];
}

export interface AddTargetGroupsRequest {
  targetGroupSurveyLinksItems: TargetGroupSurveyLinksItems[];
  workingDocumentId: string;
}

export interface TargetGroupSurveyLinksItems {
  id: number;
  name: string;
  testLinks: string;
  screenoutInformation: string;
  linkTemplate: string;
  uploadedLinks: number;
  createWithoutLinks: boolean;
}

export interface ProjectUpdateModel {
  contactName: string;
  contactEmail: string;
  additionalEmails: string[];
  purchaseOrderNumber: string;
  jobNumber: string;
  checkGeoIp: boolean;
  useDeduplication: boolean;
  suitableForMobile: boolean;
  suitableForTablet: boolean;
  suitableForDesktop: boolean;
  requireWebcam: boolean;
  endPageSecurityType: EndPageSecurityType;
  displayCurrency: Currency;
}

export interface PrepareProjectPanelistPool {
  rootPoolId: string;
  selectedPoolId: string;
  panelIds: number[];
  selectedGroup: keyof AvailablePanelistGroups | '';
  importedPanelistIds: string;
  source: PanelistPoolSource;
  type: PanelistPoolType;
  sourceUrl: string;
}

export interface PrepareProjectSupplyMix {
  supplyGroups: {
    id: number;
    name: string;
    panelIds: number[];
    percentage: number;
    wantedCompletes: number;
    wantedStarts?: number;
    source: ActiveSupplyMixSource;
    customCpi?: number;
  }[];
}
export interface PrepareProjectSupply {
  selectedPanels: { id: number }[];
  adHocSupplier: any;
  panelistPool: PrepareProjectPanelistPool;
  supplyMix: PrepareProjectSupplyMix;
  customCpi?: number;
  useCustomCpi: boolean;
}
export interface PrepareProjectTargetGroup {
  id: number;
  replacesTargetGroupId?: number;
  replacesProjectId?: number;
  name: string;
  country: string;
  countryId: number;
  wantedNumberOfCompletes: number;
  wantedNumberOfStarts: number;
  startDate: string;
  estimatedIncidenceRate: number;
  estimatedLengthOfInterview: number;
  gender: string;
  minAge: number;
  maxAge: number;
  numberOfDaysInField: number;
  useFixedLoi: boolean;
  surveyMetadata: { studyTypes: string[] | undefined };
  panels: PrepareProjectSupply;
  quotas: {
    quotas: QuotaWithBuckets[];
    buckets: Bucket[];
    weightingStrategy: WeightingStrategy;
  };
  regions: {
    regionTypeId: string | number;
    regionList: { regionId: number }[];
    mainRegionsAutomaticallySelected: boolean;
  };
  profiling: {
    panelSpecificProfiling: boolean;
    selectedVariables: { [id: number]: { categoryId: number } };
  };
  excludeProjects: {
    projects: { id: number; respondentStatusTimelines: ExcludedStatusWithTimeline[] }[];
    respondentStatusTimelines: ExcludedStatusWithTimeline[];
  };
  incentives?: { fixedIncentiveAmount: number };
  ownsAllPanels: boolean;
  ownPanelCurrency?: string;
  requestMode: { includeLockedPanels: boolean };
  projectTemplateSource: ProjectTemplateSource;
  panelistPoolContainsUrl: boolean;
  supplySource: SupplySource;
}

export const ProjectDataTargetGroupResponse = Record({
  targetGroupId: AboveZero,
  buckets: Array(
    Record({
      bucketId: AboveZero,
      wantedCompletes: NonNegative,
    })
  ),
});
export type ProjectDataTargetGroupResponse = Static<typeof ProjectDataTargetGroupResponse>;

export const ProjectDataResponse = Record({
  projectId: NonNegative,
  originalTargetGroupBuckets: Array(ProjectDataTargetGroupResponse).Or(Null),
});
export type ProjectDataResponse = Static<typeof ProjectDataResponse>;

export const ProjectSamplingProgressResponse = Record({
  targetGroups: Array(
    Record({
      targetGroupId: AboveZero,
      percentageSampled: NonNegative,
      quotas: Array(AboveZero),
    })
  ),
});
export type ProjectSamplingProgressResponse = Static<typeof ProjectSamplingProgressResponse>;

export const CustomParametersItems = Dictionary(Number.Or(String).Or(Null), 'string');
export type CustomParametersItems = Static<typeof CustomParametersItems>;

export const CustomParametersResponse = Record({
  project: CustomParametersItems.Or(Null),
  targetGroups: Dictionary(CustomParametersItems, 'number').Or(Null),
});
export type CustomParametersResponse = Static<typeof CustomParametersResponse>;

export const PanelistDataReportStatus = Record({
  jobStatus: Union(
    Literal(BackgroundDataJobStatus.Completed),
    Literal(BackgroundDataJobStatus.Failed),
    Literal(BackgroundDataJobStatus.Queued),
    Literal(BackgroundDataJobStatus.Started)
  ),
  createdAt: String.Or(Null),
});

export type AsyncReportStatus = Static<typeof PanelistDataReportStatus>;

export type InactivityThreshold = Static<typeof InactivityThreshold>;

export const InactivityThreshold = Record({
  inactivityThreshold: Number,
});

export type AutoCloseDate = Static<typeof AutoCloseDate>;

export const AutoCloseDate = Record({
  autoCloseDate: Iso8601UtcString,
});

export interface MinimumSpendResponse {
  isValid: boolean;
  minimumSpendInProvidedCurrency: string;
}

export class ProjectHttpService {
  cancelers: any;

  constructor() {
    this.cancelers = {
      closedProjectStatistics: undefined,
    };
  }

  createProject(request: CreateProjectRequest): IHttpPromise<CreateProjectResponse> {
    return $http.validatingPost(CreateProjectResponse, urlService.createProject(), request);
  }

  updateProject(
    id: number,
    updateModel: { [P in keyof ProjectUpdateModel]: { value: any } }
  ): IHttpPromise<ExistingProjectResponse> {
    return $http.validatingPost(ExistingProjectResponse, urlService.updateProject(id), updateModel);
  }

  addTargetGroups(id: number, request: AddTargetGroupsRequest): IHttpPromise<AddTargetGroupsResponse> {
    return $http.validatingPost(AddTargetGroupsResponse, urlService.addTargetGroups(id), request);
  }

  prepareProject(
    projectSettings: ProjectSettings,
    targetGroups: PrepareProjectTargetGroup[],
    feasibilityOptions: FeasibilityOptions,
    existingProjectId?: number
  ): IHttpPromise<PrepareProjectResponse> {
    const payload = {
      projectSettings,
      targetGroups,
      feasibilityOptions: feasibilityOptions || { useFakeFeasibility: false },
    };
    if (globalDebugOptions.options.disableCompressRequests) {
      return $http.validatingPost(PrepareProjectResponse, urlService.prepareProject(existingProjectId), payload);
    }
    const data = {
      payload,
      compressRequest: true,
      decompressResponse: false,
    };
    return $http.validatingPostGzip(
      PrepareProjectResponse,
      urlService.prepareProjectCompressed(existingProjectId),
      data
    );
  }

  getProjectSpecificRedirectLinks(projectId?: number): IHttpPromise<RedirectLinksResponse> {
    if (isEmpty(projectId)) {
      return $http.validatingGet(RedirectLinksResponse, urlService.generateRedirectLinks());
    }
    return $http.validatingGet(RedirectLinksResponse, urlService.getRedirectLinksForProject(projectId));
  }

  getProject(projectId: number, config?: IRequestShortcutConfig): IHttpPromise<ExistingProjectResponse> {
    const configuration = config || { params: { allowCache: false } };

    return $http.validatingGet(ExistingProjectResponse, urlService.getProject(projectId), configuration);
  }

  reuseProject(projectId: number, idsToCopyLinks?: number[], targetGroups?: number[]): IHttpPromise<ReusableProject> {
    return $http.validatingPost(ReusableProject, urlService.reuseProject(projectId), {
      targetGroupsToCopyUrlsFor: idsToCopyLinks,
      targetGroupsToReuse: targetGroups,
    });
  }

  replaceProject(projectId: number, targetGroupIds: number[]): IHttpPromise<ReusableProject> {
    return $http.validatingPost(ReplaceableTargetGroups, urlService.replaceProject(projectId), {
      targetGroupIds,
    });
  }

  duplicateTargetGroup(
    projectId: number,
    targetGroupId: number,
    copyUrls: boolean
  ): IHttpPromise<ProjectTemplateTargetGroup> {
    return $http.validatingGet(
      ProjectTemplateTargetGroup,
      urlService.duplicateTargetGroup(projectId, targetGroupId, copyUrls)
    );
  }

  getPanelistDataReportFile(projectId: number): IHttpPromise<string> {
    return $http.get(urlService.basicProjectPanelistDataReport(projectId));
  }

  getExtendedPanelistDataReportFile(projectId: number): IHttpPromise<string> {
    return $http.get(urlService.extendedProjectPanelistDataReport(projectId), { responseType: 'blob' });
  }

  getBasicPanelistsDataReportStatus(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.get(urlService.getDataReportStatus(projectId));
  }

  getExtendedPanelistsDataReportStatus(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.get(urlService.getExtendedDataReportStatus(projectId));
  }

  generateBasicPanelistsDataReport(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.post(urlService.basicProjectPanelistDataReport(projectId), {});
  }

  generateExtendedPanelistsDataReport(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.post(urlService.extendedProjectPanelistDataReport(projectId), {});
  }

  getProjectLifecycleHistory(projectId: number): IHttpPromise<ProjectHistory> {
    return $http.validatingGet(ProjectHistory, urlService.getProjectLifecycleHistory(projectId));
  }

  getProjectHistory(projectId: number): IHttpPromise<ProjectChangeEventResponse> {
    return $http.validatingGet(ProjectChangeEventResponse, urlService.getProjectHistoryFull(projectId));
  }

  validateAddTargetGroups(
    projectId: number,
    existingTargetGroupIds: number[]
  ): IHttpPromise<ValidateAddTargetGroupsResponse> {
    return $http.validatingPost(
      ValidateAddTargetGroupsResponse,
      urlService.validateAddTargetGroups(projectId),
      existingTargetGroupIds
    );
  }

  getProjectCategories(cache = false): IHttpPromise<ProjectCategoriesResponse> {
    return $http.validatingGet(ProjectCategoriesResponse, urlService.getProjectCategories(), { cache });
  }

  getSamplingProgress(projectId: number): IHttpPromise<ProjectSamplingProgressResponse> {
    return $http.validatingGet(ProjectSamplingProgressResponse, urlService.getProjectSamplingProgress(projectId));
  }

  getProjectData(projectId: number): IHttpPromise<ProjectDataResponse> {
    return $http.validatingGet(ProjectDataResponse, urlService.getProjectData(projectId));
  }

  getProjectLiveData(projectId: number): IHttpPromise<ProjectLiveDataResponse> {
    return $http.validatingGet(ProjectLiveDataResponse, urlService.getProjectLiveData(projectId));
  }

  getProjectParameters(projectId: number): IHttpPromise<CustomParametersResponse> {
    return $http.validatingGet(CustomParametersResponse, urlService.getProjectCustomParameters(projectId));
  }

  getProjectAutoDelivery(projectId: number): IHttpPromise<AutoDeliveryStatusesResponse> {
    return $http.validatingGet(AutoDeliveryStatuses, urlService.getAutoDeliveryStatuses(projectId));
  }

  updateProjectParameters(projectId: number, data: string): IHttpPromise<void> {
    return $http.post(urlService.updateProjectCustomParameters(projectId), data);
  }

  getProjectProgressReportFile(projectId: number): IHttpPromise<string> {
    return $http.get(urlService.getProjectProgressReport(projectId));
  }

  getProjectProgressReportStatus(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.get(urlService.projectProgressReportStatus(projectId));
  }

  getProjectProgressReport(projectId: number): IHttpPromise<string> {
    return $http.get(urlService.projectProgressReportStatus(projectId));
  }

  generateProjectProgressReport(projectId: number): IHttpPromise<AsyncReportStatus> {
    return $http.post(urlService.projectProgressReport(projectId), {});
  }

  getProjectDescription(projectId: number): IHttpPromise<ProjectDescriptionResponse> {
    return $http.validatingGet(ProjectDescriptionResponse, urlService.getSetProjectDescription(projectId));
  }

  setProjectDescription(projectId: number, description: string): IHttpPromise<ProjectDescriptionResponse> {
    return $http.post(urlService.getSetProjectDescription(projectId), JSON.stringify(description));
  }

  getProjectGroupsForProject(projectId: number): IHttpPromise<ProjectGroupsResponse> {
    return $http.validatingGet(ProjectGroupsResponse, urlService.getSetProjectGroupsForProject(projectId));
  }

  setProjectGroupsForProject(projectId: number, projectGroupIds: string[]): IHttpPromise<ProjectGroupsResponse> {
    return $http.put(urlService.getSetProjectGroupsForProject(projectId), JSON.stringify(projectGroupIds));
  }

  getProjectGroupsForCompany(): IHttpPromise<ProjectGroupsResponse> {
    return $http.validatingGet(ProjectGroupsResponse, urlService.getProjectGroupsForCompany());
  }

  createProjectGroup(name: string): IHttpPromise<ProjectGroupResponse> {
    return $http.validatingPost(ProjectGroupResponse, urlService.createProjectGroup(), JSON.stringify(name));
  }

  deleteProjectGroup(id: string): IHttpPromise<void> {
    return $http.delete(urlService.deleteProjectGroup(id));
  }

  getProjectInactivityThreshold(projectId: number): IHttpPromise<InactivityThreshold> {
    return $http.validatingGet(InactivityThreshold, urlService.projectInactivityThreshold(projectId));
  }

  setProjectInactivityThreshold(projectId: number, request: InactivityThreshold): IHttpPromise<void> {
    return $http.put(urlService.projectInactivityThreshold(projectId), request);
  }

  getProjectAutoCloseDate(projectId: number): IHttpPromise<AutoCloseDate> {
    return $http.validatingGet(AutoCloseDate, urlService.getProjectAutoCloseDate(projectId));
  }

  approveAllTargetGroupsForLaunch(projectId: number) {
    return $http.post(urlService.approveAllTargetGroupsForLaunch(projectId), {});
  }

  calculateMinimumSpend(totalSpend: Money): IHttpPromise<MinimumSpendResponse> {
    return $http.post(urlService.calculateMinimumSpend(), totalSpend);
  }
}

export const projectHttpService = new ProjectHttpService();
