import { IPromise } from 'angular';
import { FileSaver } from '../ngimport';
import { modalService } from './modal.service';
import { api } from './api';
import { ErrorMessageDialogSettingsFactory } from './dialogs/error-message-dialog-settings.factory';
import { prependPreambleAsNeeded, generateFileName } from './file-download-helper.functions';

export enum ReportTypes {
  PanelistBackgroundData = 'respondent data',
  ExtendedPanelistBackgroundData = 'extended respondent data',
  ProjectProgress = 'project progress',
  DraftSnapshot = 'draft snapshot',
}

export class AsyncReportService {
  getReportStatus(id: number, reportType: ReportTypes) {
    let endpointCall;
    switch (reportType) {
      case ReportTypes.DraftSnapshot:
        endpointCall = api.draft.getDraftSnapshotReportStatus;
        break;
      case ReportTypes.ExtendedPanelistBackgroundData:
        endpointCall = api.project.getExtendedPanelistsDataReportStatus;
        break;
      case ReportTypes.PanelistBackgroundData:
        endpointCall = api.project.getBasicPanelistsDataReportStatus;
        break;
      case ReportTypes.ProjectProgress:
        endpointCall = api.project.getProjectProgressReportStatus;
        break;
      default:
        throw new Error(`invalid enum member ${reportType}`);
    }
    return endpointCall(id)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return null;
      });
  }

  generateReport(id: number, reportType: ReportTypes) {
    let endpointCall;
    switch (reportType) {
      case ReportTypes.DraftSnapshot:
        endpointCall = api.draft.generateDraftSnapshotReport;
        break;
      case ReportTypes.ExtendedPanelistBackgroundData:
        endpointCall = api.project.generateExtendedPanelistsDataReport;
        break;
      case ReportTypes.PanelistBackgroundData:
        endpointCall = api.project.generateBasicPanelistsDataReport;
        break;
      case ReportTypes.ProjectProgress:
        endpointCall = api.project.generateProjectProgressReport;
        break;
      default:
        throw new Error(`invalid enum member ${reportType}`);
    }
    return endpointCall(id).then((res) => res.data);
  }

  getReportDataFile(id: number, name: string, reportType: ReportTypes): IPromise<void> {
    let endpointCall;
    let fileFormat: 'csv' | 'json' = 'csv';
    switch (reportType) {
      case ReportTypes.DraftSnapshot:
        endpointCall = api.draft.getDraftSnapshotReportFile;
        break;
      case ReportTypes.ExtendedPanelistBackgroundData:
        endpointCall = api.project.getExtendedPanelistDataReportFile;
        fileFormat = 'json';
        break;
      case ReportTypes.PanelistBackgroundData:
        endpointCall = api.project.getPanelistDataReportFile;
        break;
      case ReportTypes.ProjectProgress:
        endpointCall = api.project.getProjectProgressReportFile;
        break;
      default:
        throw new Error(`invalid enum member ${reportType}`);
    }

    return endpointCall(id)
      .then((res) => {
        let data;
        if (fileFormat === 'csv') {
          data = new Blob([prependPreambleAsNeeded(res.data)], { type: 'attachment/csv;charset=utf8' });
        } else {
          data = new Blob([res.data], { type: 'application/json;charset=utf8' });
        }
        FileSaver.saveAs(data, generateFileName(name, reportType, fileFormat), false);
      })
      .catch(() => this.openErrorDialog())
      .finally(() => true);
  }

  private openErrorDialog(): void {
    modalService.openSmall(
      ErrorMessageDialogSettingsFactory.create({
        title: 'Download report',
        messages: [
          'Something went wrong while getting the data.',
          'The system has logged the error, try again at a later time.',
        ],
      })
    );
  }
}

export const asyncReportService = new AsyncReportService();
