import './filter-list.component.scss';
import { produce } from 'immer';
import { includes, isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { ListItemPill } from '../react/ListItemPill';
import { Filter } from './filter';

interface Props {
  filters: Filter[];
  onApply: (filters: Filter[]) => void;
}

interface FilterListState {
  title: string;
  // Normalized state to make it easier to work with ListItemPill
  pills: {
    label: string;
    selected: boolean;
  }[];
  exclusive?: boolean;
}

const projectGroupTitle = 'ProjectGroups';

export const FilterList: React.FC<Props> = ({ filters, onApply }) => {
  const [unsavedChanges, setUnsavedChanges] = useState<FilterListState[]>([]);
  const activeFilters = filters.map((x) => ({
    title: x.title,
    pills: x.available.map((a) => ({ label: a, selected: includes(x.selected, a) })),
    exclusive: x.exclusive,
  }));

  const resetChanges = () => setUnsavedChanges(activeFilters);

  useEffect(resetChanges, [filters]);

  const handleApply = () => {
    onApply(
      unsavedChanges.map((a) => ({
        title: a.title,
        available: a.pills.map((a) => a.label),
        selected: a.pills.filter((a) => a.selected).map((a) => a.label),
      }))
    );
  };

  const undoChanges = () => {
    resetChanges();
  };

  const toggle = (filter: FilterListState, pillLabel: string) => {
    setUnsavedChanges((state) =>
      produce(state, (arr) => {
        for (const item of arr) {
          if (item.title === filter.title) {
            for (const pill of item.pills) {
              if (pill.label === pillLabel) {
                pill.selected = !pill.selected;
              } else if (filter.exclusive) {
                pill.selected = false;
              }
            }
          }
        }
      })
    );
  };

  const mapFilterTitleForUi = (title: string) => {
    return title === projectGroupTitle ? 'Project Groups' : title;
  };

  return (
    <div className="filter-list-component" data-testid="filter-list-component">
      <div className="settings-popover">
        {unsavedChanges
          .filter(({ pills }) => pills.length > 0)
          .map((filter) => (
            <div key={filter.title} className="filter-list">
              <h2>{mapFilterTitleForUi(filter.title)}</h2>
              <ul className="pill-list">
                {filter.pills.map(({ label, selected }) => (
                  <ListItemPill key={label} text={label} isSelected={selected} onClick={(_) => toggle(filter, label)} />
                ))}
              </ul>
            </div>
          ))}
      </div>
      <hr />
      <div className="filter-controls">
        <button
          className="ui-btn default-btn control-buttons"
          disabled={isEqual(activeFilters, unsavedChanges)}
          onClick={undoChanges}
        >
          Undo Changes
        </button>
        <button
          className="ui-btn primary-btn control-buttons"
          onClick={handleApply}
          data-testid="filter-list-apply-button"
        >
          Apply
        </button>
      </div>
    </div>
  );
};
