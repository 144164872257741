import './warning-dialog.component.scss';
import { ui } from 'angular';
import { html } from '../helpers';

const selector = 'warningDialogComponent';

const template = html`
  <div class="warning-dialog-component">
    <modal-close-button-component close-action="$ctrl.cancel()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Warning</h1>
      <div class="warning"><i class="fas fa-exclamation-triangle" ng-repeat="i in [0,1,2,3,4,5,6]"></i></div>
      <p>You're about to toggle a feature in production. Are you sure you want to do it?</p>
      <div class="warning">
        <i class="fas fa-exclamation-triangle" ng-repeat="i in [0,1,2,3,4,5,6]"></i>
      </div>
      <div class="modal-controls">
        <button type="button" class="ui-btn default-btn" ng-click="$ctrl.accept()">Yes</button>
        <button type="button" class="ui-btn primary-btn" ng-click="$ctrl.cancel()">Cancel</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class WarningDialogComponent implements Bindings {
  static componentName = selector;
  modalInstance: ui.bootstrap.IModalInstanceService;

  accept(): void {
    this.modalInstance.close();
  }

  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }
}

export const ngWarningDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: WarningDialogComponent,
  },
};
