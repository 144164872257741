import { IPromise } from 'angular';
import { $q, $timeout } from '../ngimport';

export type FunctionRetuningPromise = (...args: any[]) => IPromise<any>;

export type DebouncePromise = (func: FunctionRetuningPromise, wait: number) => FunctionRetuningPromise;

const debouncePromiseName = 'debouncePromise';

function debouncePromiseProvider<T extends (...args: any[]) => IPromise<any>>() {
  // eslint-disable-next-line func-names
  return function (this: Function, func: T, wait: number) {
    let timeout: any;

    // eslint-disable-next-line func-names
    return function (this: Function) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;

      return $q((resolve: Function) => {
        $timeout.cancel(timeout);
        timeout = $timeout(() => {
          resolve(func.apply(context, Array.from(args)));
        }, wait);
      });
    };
  };
}

export function registerDebouncePromise($provide: ng.auto.IProvideService): void {
  $provide.factory(debouncePromiseName, debouncePromiseProvider);
}
