import { ui } from 'angular';
import { ErrorMessageDialogComponent } from './error-message-dialog.component';

export interface ErrorMessageDialogResolve {
  messages: string[];
  title?: string;
}

export class ErrorMessageDialogSettingsFactory {
  static create(resolve: ErrorMessageDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'edit-invitation-email-error-dialog',
      component: ErrorMessageDialogComponent.componentName,
      resolve: {
        messages: () => resolve.messages,
        title: () => resolve.title,
      },
    };
  }
}
