import { TargetGroupModel } from '../common/models/target-group.model';
import { FeasibilityResponse } from '../common/http-services/feasibility.response';

export interface TargetGroupFeasibility {
  targetGroupId: number;
  definition: TargetGroupModel;
  feasibility: FeasibilityResponse;
}

export class FeasibilityResults {
  private list: TargetGroupFeasibility[];

  constructor() {
    this.list = [];
  }

  setFeasibilities(feasibilities: TargetGroupFeasibility[]) {
    this.list = feasibilities;
  }

  getFeasibilities(): TargetGroupFeasibility[] {
    return this.list;
  }

  reset() {
    this.list = [];
  }
}

export const feasibilityResults = new FeasibilityResults();
