import { SupplyGroupUpdate } from '../../../supply/supply-mix/supply-mix-state.service';
import { ActiveSupplyModel } from '../../models/active-supply.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { supplySubactions } from './supply.subactions';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { incentivesSubactions } from '../incentives/incentives.subactions';
import { SupplySource, PanelistPoolType, PanelistPoolSource } from '../../../../common/enums';
import { panelistPoolStorageService } from '../../../../common/panelist-pool-storage.service';
import { profilingSubactions } from '../profiling/profiling.subactions';
import { quotasSubactions } from '../quotas/quotas.subactions';
import { WeightingStrategy } from '../../../../common/models/quotas.model';
import { SupplyStateModel } from '../../models/supply-state.model';
import { countryRestrictionsDisabledService } from '../../../../common/country-restrictions-disabled.service';
import { basicSettingsSubactions } from '../basic-settings/basic-settings.subactions';
import { activeTargetGroupSubactions } from '../../active-target-group.subactions';
import { analytics } from '../../../../common/analytics';
import { stateDebugService } from '../../../../common/state-debug.service';

type SupplyUpdateFunc = (state: ActiveTargetGroupModel, commit: CommitFuncs, done: () => void) => void;

export const commitSupply = () => (supplyState: ActiveSupplyModel) => {
  activeTargetGroupStore.model.supply = supplyState;
  targetGroupChannel.model.supply.updated.dispatch();
};

export class SupplyActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  importInternalPanelistPool(
    panelistEntries: string,
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    containsUrl = false
  ) {
    this.update((state, commit, done) => {
      panelistPoolStorageService.deletePanelistEntries(panelistEntries);
      supplySubactions
        .importInternalPanelistPool(
          state.supply,
          panelistEntries,
          panelistPoolType,
          poolSource,
          containsUrl,
          state.basicSettings.countryId
        )
        .then((newState) => {
          commit.supply(newState);
          done();
        });
    });
  }

  importFromPanelistPoolApi(panelistPoolId: string, panelistPoolType: PanelistPoolType) {
    this.update((state, commit, done) => {
      supplySubactions
        .importFromPanelistPoolApi(state.supply, panelistPoolId, panelistPoolType, state.basicSettings.countryId)
        .then((newState) => {
          commit.supply(newState);
          done();
        });
    });
  }

  importExternalPanelistPool(url: string, panelistPoolType: PanelistPoolType) {
    this.update((state, commit, done) => {
      panelistPoolStorageService.delete(url);
      supplySubactions
        .importExternalPanelistPool(state.supply, url, panelistPoolType, state.basicSettings.countryId)
        .then((newState) => {
          commit.supply(newState);
          done();
        });
    });
  }

  updateCustomCpi(newCustomCpi: number): void {
    this.update((state, commit, done) => {
      analytics.newProject.pricing.setCustomCpi(state.supply.supplySource);
      const newState = supplySubactions.setCustomCpi(state.supply, newCustomCpi);
      commit.supply(newState);
      done();
    });
  }

  updateCustomCpiForSupplyGroups(supplyGroupUpdates: SupplyGroupUpdate[]): void {
    this.update((state, commit, done) => {
      const newState = supplySubactions.updateSupplyMixGroups(state.supply, supplyGroupUpdates);
      commit.supply(newState);
      done();
    });
  }

  clearCustomCpi(): void {
    this.update((state, commit, done) => {
      const newState = supplySubactions.clearCustomCpi(state.supply);
      commit.supply(newState);
      done();
    });
  }

  setSupplySource(supplySource: SupplySource) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setSupplySource(state.supply, supplySource);
      commit.supply(newSupplyState);

      let newIncentivesState = incentivesSubactions.useDefaultIncentive(state.incentives);
      newIncentivesState = this.rootStore.handleFixedIncentive(newIncentivesState, state.supply.ownPanelCurrency);
      commit.incentives(newIncentivesState);

      const newBasicSettingsState = basicSettingsSubactions.validateBasicSettings(state.basicSettings, () =>
        countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(state.supply)
      );
      commit.basicSettings(newBasicSettingsState);

      if (supplySource === SupplySource.AdHoc) {
        let newQuotasState = quotasSubactions.setWeightingStrategy(state.quotas, WeightingStrategy.EvenDistribution);
        newQuotasState = quotasSubactions.toggleOffPanelDistributionForAllQuotas(
          newQuotasState,
          state.basicSettings.numberOfCompletes
        );
        commit.quotas(newQuotasState);

        supplySubactions.createAdHocSuppliersAndLanguagesPromise(state.supply).then((newSupplyStateWithAdHoc) => {
          commit.supply(newSupplyStateWithAdHoc);
          done();
        });
      } else if (supplySource === SupplySource.SystemSelected || supplySource === SupplySource.PanelistPool) {
        // discard
        this.updateProfiling(state, commit, done);
      } else {
        done();
      }
    });
  }

  togglePanel(panelId: number) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.togglePanel(state.supply, panelId);
      commit.supply(newSupplyState);

      this.updateProfiling(state, commit, done);
    });
  }

  deselectAll() {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.deselectAllFromSupplyType(state.supply);
      commit.supply(newSupplyState);

      const newIncentivesState = incentivesSubactions.useDefaultIncentive(state.incentives);
      commit.incentives(newIncentivesState);

      this.updateProfiling(state, commit, done);
    });
  }

  toggleAdHoc(adHocSupplierId: number) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.toggleAdHoc(state.supply, adHocSupplierId);
      commit.supply(newState);
      done();
    });
  }

  persistSupplyState(supplyStateModel: SupplyStateModel) {
    this.update(async (state, commit, done) => {
      const { basicSettings, incentives, supply, profiling, quotas, surveyMetadata } =
        await activeTargetGroupSubactions.persistSupplyState(supplyStateModel, state);
      commit.supply(supply);
      commit.basicSettings(basicSettings);
      commit.incentives(incentives);
      commit.profiling(profiling);
      commit.quotas(quotas);
      commit.surveyMetadata(surveyMetadata);
      this.rootStore.managePanes();
      done();
    });
  }

  discardExclusionsPanelistPool() {
    this.update((state, commit, done) => {
      const newState = supplySubactions.discardExclusionsPanelistPool(state.supply);
      commit.supply(newState);
      done();
    });
  }

  toggleUseCustomCpi() {
    this.update((state, commit, done) => {
      const newState = supplySubactions.toggleUseCustomCpi(state.supply);
      commit.supply(newState);
      done();
    });
  }

  private updateProfiling(
    state: ActiveTargetGroupModel,
    commit: CommitFuncs,
    done: Function,
    resetPanelSpecificProfiling?: boolean
  ) {
    profilingSubactions
      .handlePanelProfilingAsync(
        state.profiling,
        state.basicSettings.countryId,
        this.rootStore.showTogglePanelProfiling,
        resetPanelSpecificProfiling
      )
      .then(([newProfilingState, hasUpdatedProfiling]) => {
        commit.profiling(newProfilingState);
        if (hasUpdatedProfiling) {
          const newQuotasState = quotasSubactions.removeAllProfilingQuotaSections(state.quotas);
          commit.quotas(newQuotasState);
        }

        const newIncentivesState = this.rootStore.handleFixedIncentive(state.incentives, state.supply.ownPanelCurrency);
        commit.incentives(newIncentivesState);

        done();
      });
  }

  private update(updateFunc: SupplyUpdateFunc, preventSignalUpdate?: boolean) {
    stateDebugService.logIfUnsafeUpdate('supply');
    if (!preventSignalUpdate) {
      this.rootStore.signalUpdateStarted();
    }

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, () => this.rootStore.publishUpdate());
  }
}
