import { ui } from 'angular';
import {
  RenameTargetGroupDialogComponent,
  RenameTargetGroupDialogResolve,
} from './rename-target-group-dialog.component';
import {
  DeleteTargetGroupDialogComponent,
  DeleteTargetGroupDialogResolve,
} from './delete-target-group-dialog.component';
import { SaveDraftDialogComponent, SaveDraftDialogResolve } from './save-draft-dialog.component';
import { SaveTemplateDialogComponent, SaveTemplateDialogResolve } from './save-template-dialog.component';
import {
  SaveTemplateInvalidSettingsDialogComponent,
  SaveTemplateInvalidSettingsDialogResolve,
} from './save-template-invalid-settings-dialog.component';
import { LoadTemplateDialogComponent } from './load-template-dialog.component';

export class RenameTargetGroupDialogSettingsFactory {
  static create(resolve: RenameTargetGroupDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'rename-target-group-dialog',
      component: RenameTargetGroupDialogComponent.componentName,
      resolve: {
        targetGroupId: () => resolve.targetGroupId,
        existingTargetGroupNames: () => resolve.existingTargetGroupNames,
        editingTemplate: () => resolve.editingTemplate,
      },
    };
  }
}

export class DeleteTargetGroupDialogSettingsFactory {
  static create(resolve: DeleteTargetGroupDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'delete-target-group-dialog',
      component: DeleteTargetGroupDialogComponent.componentName,
      resolve: {
        targetGroupName: () => resolve.targetGroupName,
      },
    };
  }
}

export class SaveDraftDialogSettingsFactory {
  static create(resolve: SaveDraftDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'save-draft-dialog',
      component: SaveDraftDialogComponent.componentName,
      resolve: {
        newDraft: () => resolve.newDraft,
        panelistPoolContainsUrl: () => resolve.panelistPoolContainsUrl,
      },
    };
  }
}

export class SaveTemplateDialogSettingsFactory {
  static create(resolve: SaveTemplateDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'save-template-dialog',
      component: SaveTemplateDialogComponent.componentName,
      resolve: {
        newTemplate: () => resolve.newTemplate,
        panelistPoolContainsUrl: () => resolve.panelistPoolContainsUrl,
      },
    };
  }
}

export class SaveTemplateInvalidSettingsDialogSettingsFactory {
  static create(resolve: SaveTemplateInvalidSettingsDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'save-template-invalid-settings-dialog',
      component: SaveTemplateInvalidSettingsDialogComponent.componentName,
      resolve: {
        type: () => resolve.type,
        reason: () => resolve.reason,
      },
    };
  }
}

export interface LoadTemplateDialogResolve {
  loadToExistingProject: boolean;
}

export class LoadTemplateDialogSettingsFactory {
  static create(resolve?: LoadTemplateDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'load-template-dialog',
      component: LoadTemplateDialogComponent.componentName,
      resolve: {
        loadToExistingProject: resolve ? resolve.loadToExistingProject : false,
      },
    };
  }
}
