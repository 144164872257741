import { IAttributes, IAugmentedJQuery, INgModelController, IScope } from 'angular';
import { DirectiveUsingNgModelController } from './directive.types';

const directiveName = 'validLinkTemplate';

export function validLinkTemplate(): DirectiveUsingNgModelController {
  return {
    require: 'ngModel',
    link: (_scope: IScope, _element: IAugmentedJQuery, _attrs: IAttributes, ctrl: INgModelController) => {
      const isValidId = (modelValue: any, viewValue: any) => {
        if (ctrl.$isEmpty(modelValue)) return true;

        return viewValue.indexOf('[ID]') > -1;
      };

      const isValidProtocol = (modelValue: any, viewValue: any) => {
        if (ctrl.$isEmpty(modelValue)) return true;

        return /https?:\/\//.test(viewValue);
      };

      ctrl.$validators.invalidId = (modelValue, viewValue) => {
        return isValidId(modelValue, viewValue);
      };

      ctrl.$validators.invalidProtocol = (modelValue, viewValue) => {
        return isValidProtocol(modelValue, viewValue);
      };

      ctrl.$validators.invalidLink = (modelValue, viewValue) => {
        if (!isValidId(modelValue, viewValue) || !isValidProtocol(modelValue, viewValue)) return true;

        if (ctrl.$isEmpty(modelValue)) return true;

        return /^https?:\/\/[^ "]+\[ID\][^ "]*$/.test(viewValue);
      };
    },
  };
}

export const ngValidLinkTemplateDirective = {
  [directiveName]: validLinkTemplate,
};
