import {
  map,
  isEqual,
  intersection,
  uniq,
  isEmpty,
  difference,
  groupBy,
  uniqBy,
  filter,
  pickBy,
  flatMap,
  find,
  without,
} from 'lodash-es';
import { toJson } from 'angular';
import { sha1 } from 'object-hash';
import { QuotaOptions, TargetGender } from '../../common/enums';
import { cartesianProductOf, keys } from '../../helpers';
import {
  ExtendedQuota,
  Quota,
  QuotaKeySupply,
  QuotaKeyProfiling,
  QuotaGroupNames,
} from '../active-target-group/models/active-quotas.model';
import { DetailedSelectionItem } from '../active-target-group/models/active-profiling.model';
import { Region } from '../active-target-group/models/active-regions.model';
import { AgeSpan } from '../active-target-group/models/active-target-group.model';
import { getProfilingQuotaName, getRegionQuotaName } from './quotas-helper.functions';

export interface Bucket {
  readonly id: number;
  readonly key: BucketKey;
  readonly name: string;
  wantedCompletes?: number;
}

export interface BucketKey {
  readonly gender: TargetGender;
  readonly ageSpan: AgeSpan;
  readonly region: number[];
  readonly supply: { panels: readonly number[] };
  readonly profiling: readonly number[];
}

export interface QuotaWithBuckets {
  readonly name: string;
  readonly matchId: number;
  readonly wantedCompletes: number;
  readonly wantedStarts?: number;
  readonly buckets: readonly number[];
  readonly keys: readonly string[];
  readonly quotaOption: QuotaOptions;
  readonly customCpi?: number;
}

export type QuotaType = 'gender' | 'ageSpan' | 'region' | 'profiling' | 'custom';

export interface QuotasAndBuckets {
  readonly buckets: readonly Bucket[];
  readonly quotas: readonly QuotaWithBuckets[];
}

interface GenerateNameParameters {
  gender: TargetGender;
  ageSpan: AgeSpan;
  region: number[] | null;
  supply?: { panels: readonly number[]; name: string };
  profiling: readonly number[];
  selectedRegions: readonly Region[];
  selectedProfiling: readonly DetailedSelectionItem[];
  quotaGroupNames?: QuotaGroupNames;
}

export type UniqueProfiling = {
  readonly questionId: number;
  readonly variables: readonly number[];
};

export class QuotaBucketBuilder {
  generate(
    quotas: readonly ExtendedQuota[],
    selectedRegions: readonly Region[],
    selectedProfiling: readonly DetailedSelectionItem[],
    quotaGroupNames?: QuotaGroupNames
  ): QuotasAndBuckets {
    const buckets = [] as Bucket[];
    const allGenders = this.getUniqueGenders(quotas);
    const allAgeSpans = this.getUniqueAgeSpans(quotas);
    const allRegions = this.getUniqueRegions(quotas);
    const allProfiling = this.getUniqueProfiling(quotas);
    const allSupply = this.getUniqueSupply(quotas);
    let count = 1;
    for (const gender of allGenders) {
      for (const ageSpan of allAgeSpans) {
        for (const region of allRegions) {
          for (const supply of allSupply) {
            for (const profiling of allProfiling) {
              if (gender || ageSpan || region || supply || profiling) {
                const key = {
                  gender,
                  ageSpan,
                  region: region === null ? [] : region,
                  supply: supply === null ? { panels: [] as number[] } : { panels: supply.panels },
                  profiling: profiling === null ? [] : flatMap(profiling, (p) => p.variables),
                };

                buckets.push({
                  id: count,
                  name: this.addName({
                    ...key,
                    supply:
                      supply === null
                        ? { panels: [] as number[], name: '' }
                        : { panels: supply.panels, name: supply.name },
                    selectedRegions,
                    selectedProfiling,
                    quotaGroupNames,
                  }),
                  key,
                });
                count++;
              }
            }
          }
        }
      }
    }

    return {
      buckets,
      quotas: this.getQuotasWithBuckets(quotas, buckets, selectedRegions, selectedProfiling, quotaGroupNames),
    };
  }

  private getQuotasWithBuckets(
    quotas: readonly ExtendedQuota[],
    buckets: readonly Bucket[],
    selectedRegions: readonly Region[],
    selectedProfiling: readonly DetailedSelectionItem[],
    quotaGroupNames?: QuotaGroupNames
  ): readonly QuotaWithBuckets[] {
    return map(quotas, (quota) => {
      const { key } = quota;
      return {
        name: this.addName({
          ...key,
          profiling: key.profiling.map((p) => p.variableId),
          selectedRegions,
          selectedProfiling,
          quotaGroupNames,
        }),
        matchId: quota.matchId,
        wantedCompletes: quota.completes,
        wantedStarts: quota.starts,
        buckets: this.getBuckets(quota, buckets),
        keys: quota.keys,
        quotaOption: quota.option,
        customCpi: quota.customCpi,
      };
    });
  }

  private addName({
    gender,
    ageSpan,
    region,
    supply,
    profiling,
    selectedRegions,
    selectedProfiling,
    quotaGroupNames,
  }: GenerateNameParameters): string {
    const parts: string[] = [];
    if (gender) {
      parts.push(gender === TargetGender.Male ? '[Male]' : '[Female]');
    }

    if (ageSpan) {
      parts.push(`[${ageSpan.from}-${ageSpan.to}]`);
    }

    if (region && region.length) {
      const groupName = quotaGroupNames?.region[JSON.stringify(region)];
      if (groupName) {
        parts.push(`[${groupName}]`);
      } else {
        const name = getRegionQuotaName(region, selectedRegions);
        parts.push(name);
      }
    }

    if (supply && supply.panels.length) {
      parts.push(`[${supply.name}]`);
    }

    if (profiling && profiling.length) {
      const profilingVariableKeys =
        quotaGroupNames?.profiling !== undefined &&
        keys(quotaGroupNames.profiling)
          .filter((k) => {
            return (JSON.parse(k) as number[]).every((id) => profiling.includes(id));
          })
          .filter((key) => quotaGroupNames.profiling[key]);
      if (profilingVariableKeys.length) {
        const usedVariableIds: number[] = [];
        for (const key of profilingVariableKeys) {
          const groupName = quotaGroupNames?.profiling[key];
          if (groupName) {
            (JSON.parse(key) as number[]).forEach((id) => usedVariableIds.push(id));
            parts.push(`[${groupName}]`);
          }
        }
        const remainingVariables = without(profiling, ...usedVariableIds);
        if (remainingVariables.length) {
          const name = getProfilingQuotaName(remainingVariables, selectedProfiling);
          parts.push(name);
        }
      } else {
        const name = getProfilingQuotaName(profiling, selectedProfiling);
        parts.push(name);
      }
    }

    const bucketName = parts.join(':');
    if (bucketName.length > 1000) {
      const newName = this.getUniqueTruncatedBucketName(bucketName);
      return newName;
    }
    return bucketName;
  }

  private getUniqueTruncatedBucketName(originalName: string): string {
    const nonInclusiveEnd = 1000;
    const suffixLength = 9; // "...]xxxxx" where x is character in hash
    const nonInclusiveNameEnd = nonInclusiveEnd - suffixLength;
    const first = originalName.slice(0, nonInclusiveNameEnd);
    const hash = sha1(originalName.slice(nonInclusiveNameEnd)).slice(0, 5);
    return `${first}...]${hash}`;
  }

  private getBuckets(quota: Quota, buckets: readonly Bucket[]): readonly number[] {
    const bucketMap = {
      gender: (bucket: Bucket) => bucket.key.gender === quota.key.gender,

      ageSpan: (bucket: Bucket) => isEqual(bucket.key.ageSpan, quota.key.ageSpan),

      region: (bucket: Bucket) => isEqual([...bucket.key.region].sort(), [...quota.key.region].sort()),

      supply: (bucket: Bucket) => isEqual(bucket.key.supply.panels, quota.key.supply.panels),

      profiling: (bucket: Bucket) => this.quotaProfilingIsSubsetOfBucketProfiling(quota, bucket),

      custom: (bucket: Bucket) => {
        const genderEqual = !quota.key.gender || bucket.key.gender === quota.key.gender;
        const ageEqual = !quota.key.ageSpan || isEqual(bucket.key.ageSpan, quota.key.ageSpan);
        const regionEqual =
          !quota.key.region.length || isEqual([...bucket.key.region].sort(), [...quota.key.region].sort());
        const supplyEqual =
          !quota.key.supply || isEqual([...bucket.key.supply.panels].sort(), [...quota.key.supply.panels].sort());
        const profilingEqual =
          !quota.key.profiling.length || this.quotaProfilingIsSubsetOfBucketProfiling(quota, bucket);

        return genderEqual && ageEqual && regionEqual && supplyEqual && profilingEqual;
      },
    };

    const quotaType = this.getQuotaType(quota);
    const bucketsOnQuota = filter(buckets, bucketMap[quotaType]);
    return bucketsOnQuota.map((b) => b.id);
  }

  private quotaProfilingIsSubsetOfBucketProfiling(quota: Quota, bucket: Bucket): boolean {
    const quotaProfiling = quota.key.profiling.map((p) => p.variableId).sort();
    return isEqual(intersection(quotaProfiling, [...bucket.key.profiling].sort()), quotaProfiling);
  }

  private getQuotaType(quota: Quota): QuotaType {
    const types = ['gender', 'ageSpan', 'region', 'supply', 'profiling'];
    const nonEmptyKeys = keys(pickBy(quota.key, (v) => !isEmpty(v)));
    const possibleTypes = intersection(types, nonEmptyKeys);
    const plausibleType = possibleTypes.length === 1 ? possibleTypes[0] : 'custom';
    let actualType: QuotaType;
    if (plausibleType === 'profiling') {
      actualType = uniqBy(quota.key.profiling, (p) => p.questionId).length > 1 ? 'custom' : 'profiling';
    } else {
      actualType = 'custom';
    }

    return actualType;
  }

  private getUniqueGenders(quotas: readonly ExtendedQuota[]): readonly TargetGender[] {
    const allGenders = uniq(
      quotas
        .filter((q) => q.key.gender === TargetGender.Male || q.key.gender === TargetGender.Female)
        .map((q) => q.key.gender)
    );

    if (isEmpty(allGenders)) return [null];

    return allGenders;
  }

  private getUniqueAgeSpans(quotas: readonly Quota[]): readonly AgeSpan[] {
    const allAgeSpans = uniqBy(
      quotas.filter((q) => q.key.ageSpan !== undefined),
      (q) => [q.key.ageSpan.from, q.key.ageSpan.to].join()
    ).map((q) => q.key.ageSpan);

    if (isEmpty(allAgeSpans)) return [null];

    return allAgeSpans;
  }

  private getUniqueRegions(quotas: readonly Quota[]): number[][] {
    const allRegions = uniqBy(
      quotas.filter((q) => q.key.region.length),
      (q) => [...q.key.region].sort().join(',')
    ).map((q) => q.key.region);

    if (isEmpty(allRegions)) return [null];

    return allRegions;
  }

  private getUniqueProfiling(quotas: readonly Quota[]): readonly UniqueProfiling[][] {
    const profilingQuotas = quotas.filter((q) => q.key.profiling.length);

    const noninterlockedQuotas = profilingQuotas.filter(
      (q) =>
        !q.key.gender &&
        !q.key.ageSpan &&
        !q.key.region.length &&
        uniqBy(q.key.profiling, (p) => p.questionId).length === 1
    );
    const noninterlockedQuestions = groupBy(
      noninterlockedQuotas.map((q) => q.key.profiling),
      (p) => p[0].questionId
    );

    const interlockedQuotas = difference(profilingQuotas, noninterlockedQuotas);
    const groupedInterlockedQuotas = interlockedQuotas.map((p) => groupBy(p.key.profiling, (q) => q.questionId));

    const interlockedQuestions: { [questionId: string]: QuotaKeyProfiling[] } = {};
    for (const profiling of groupedInterlockedQuotas) {
      for (const questionId of keys(profiling)) {
        const variables = profiling[questionId];
        if (!interlockedQuestions[questionId]) {
          interlockedQuestions[questionId] = [variables];
          continue;
        }

        if (!find(interlockedQuestions[questionId], (a) => isEqual(toJson(a), toJson(variables)))) {
          interlockedQuestions[questionId].push(variables);
        }
      }
    }

    const combinedQuestions = {
      ...noninterlockedQuestions,
      ...interlockedQuestions,
    };

    const uniqueQuestions = keys(combinedQuestions)
      .sort()
      .map((key) =>
        combinedQuestions[key].map((group) => ({
          questionId: group[0].questionId,
          variables: group.map((g) => g.variableId),
        }))
      );

    if (!uniqueQuestions.length) return [null];

    return cartesianProductOf(uniqueQuestions);
  }

  private getUniqueSupply(quotas: readonly Quota[]): readonly QuotaKeySupply[] {
    const allSupply = uniqBy(
      quotas.filter((q) => q.key.supply !== undefined),
      (q) => q.key.supply.panels.join(',')
    ).map((q) => q.key.supply);

    if (isEmpty(allSupply)) return [null];

    return allSupply;
  }
}

export const quotaBucketBuilder = new QuotaBucketBuilder();
