import { Channel, DataChannel } from '../../common/channels/channel';
import { UpfrontPrice } from '../price-quote.response';
import { ErrorModel } from '../validation-errors';
import { TargetGroupModel } from '../../common/models/target-group.model';
import { PaneName } from '../target-group-accordion.service';
import { TargetGroupActivatedData } from '../target-groups.component';
import { ActiveTargetGroupModel } from '../active-target-group/models/active-target-group.model';
import { FeasibilityResponse } from '../../common/http-services/feasibility.response';
import { ProfilingCategoryDepth } from '../profiling/profiling-depth.service';
import { CreatePanelistPoolResponse } from '../../common/http-services/supply.httpservice';
import { LinkSource, QuotaPresetLoadFailureReason } from '../../common/enums';
import { SurveyLinksData } from '../../common/models/survey-links.model';
import { SurveyMetadataListUpdate } from '../sidebar/target-group-list.service';
import { TargetGroupValidationResult } from '../../common/http-services/draft.httpservice';

export const targetGroupChannel = {
  activated: new DataChannel<TargetGroupActivatedData>(),
  navigatedAway: new Channel(),
  projectShow: new Channel(),
  currentWorkUpdated: new Channel(),
  feasibility: {
    runAll: new Channel(),
    runActive: new Channel(),
    invalid: new DataChannel<{ targetGroup: TargetGroupModel; validationResult: ErrorModel }>(),
    start: new DataChannel<{ targetGroup: TargetGroupModel; validationResult: ErrorModel }>(),
    done: new DataChannel<{ targetGroup: TargetGroupModel; feasibilityResponse: FeasibilityResponse }>(),
    error: new DataChannel<{ targetGroupId: number; reason: 'timeout' | 'error' }>(),
  },
  audienceFeasibility: {
    initializing: new Channel(),
    invalid: new DataChannel<{ targetGroup: TargetGroupModel; validationResult: ErrorModel }>(),
    start: new DataChannel<{ targetGroup: TargetGroupModel; validationResult: ErrorModel }>(),
    done: new DataChannel<{ targetGroup: TargetGroupModel; feasibilityResponse: FeasibilityResponse }>(),
    error: new DataChannel<{ targetGroupId: number; reason: 'timeout' | 'error' }>(),
  },
  depth: {
    validated: new DataChannel<boolean>(),
    updated: new DataChannel<ProfilingCategoryDepth>(),
    run: new DataChannel<number>(),
  },
  pricing: {
    start: new DataChannel<number[]>(),
    done: new DataChannel<UpfrontPrice>(),
    error: new Channel(),
    cancel: new Channel(),
  },
  targetGroupList: {
    updated: new Channel(),
    renamed: new Channel(),
  },
  existingTargetGroupList: {
    loaded: new Channel(),
  },
  model: {
    restoring: new Channel(),
    restored: new Channel(),
    failedToRestore: new Channel(),
    updating: new Channel(),
    updated: new DataChannel<{
      model: ActiveTargetGroupModel;
      withRequestFeasibility?: boolean;
    }>(),
    activePane: {
      updated: new DataChannel<PaneName>(),
    },
    basicSettings: {
      updated: new Channel(),
      countryChanging: new Channel(),
      loiOrIrChanging: new Channel(),
    },
    census: {
      updated: new Channel(),
    },
    regions: {
      updated: new Channel(),
      regionTypeChanging: new Channel(),
    },
    profiling: {
      updated: new Channel(),
      activeCategoryChanging: new Channel(),
      searchResultsUpdated: new Channel(),
    },
    supply: {
      updated: new Channel(),
      loadingAdHocSuppliers: new Channel(),
      removedLockedPanels: new Channel(),
      panelistAvailabilityUpdated: new Channel(),
    },
    surveyMetadata: {
      updated: new DataChannel<SurveyMetadataListUpdate>(),
    },
    supplyState: {
      updated: new Channel(),
      loadingAdHocSuppliers: new Channel(),
      removedLockedPanels: new Channel(),
      panelistAvailabilityUpdated: new Channel(),
    },
    quotas: {
      updated: new Channel(),
      updatedBucketsWithWantedCompletes: new Channel(),
      quotaPresetLoaded: new Channel(),
      quotaPresetFailed: new DataChannel<{ reason: QuotaPresetLoadFailureReason; payload?: any }>(),
    },
    excludeProjects: {
      updated: new Channel(),
    },
    incentives: {
      updated: new Channel(),
    },
    deprecation: {
      updated: new DataChannel<{ deprecationModel: TargetGroupValidationResult; hasAnyDeprecation: boolean }>(),
    },
    panelistPool: {
      error: new DataChannel<string>(),
      created: new DataChannel<CreatePanelistPoolResponse>(),
    },
  },

  linksData: {
    updated: new DataChannel<
      {
        id: number;
        source: LinkSource;
        explicitlyChosenNoLinks: boolean;
      } & SurveyLinksData
    >(),
  },
};
