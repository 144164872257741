import { isEmpty, isFinite, isInteger, isNumber, uniq } from 'lodash-es';
import { ErrorModel, ValidationErrors } from './validation-errors';
import { PanelistPool, SupplyMix, SupplyGroup } from '../common/models/supply.model';
import { ActivePanelistPool, SelectedAdHocSupplier } from './active-target-group/models/active-supply.model';
import { SupplySource } from '../common/enums';
import { isEmptyGuid } from '../helpers';

export function validatePanelistPool(panelistPool: PanelistPool | ActivePanelistPool): ErrorModel {
  if (!isEmpty(panelistPool.sourceUrl) && isEmptyGuid(panelistPool.rootPoolId))
    return ValidationErrors.panelistPoolError;

  const isValid = panelistPool.selectedGroup !== '';
  if (isValid) return ValidationErrors.noError;

  if (isEmpty(panelistPool.input)) {
    return ValidationErrors.hasNotCheckedPanelistAvailability;
  }

  return panelistPool.hasAvailablePanelists
    ? ValidationErrors.noPanelistGroupSelected
    : ValidationErrors.noAvailablePanelists;
}

export function validateAdHoc(adHocSupplier: SelectedAdHocSupplier): ErrorModel {
  const isValid = adHocSupplier !== undefined && adHocSupplier.id > 0 && adHocSupplier.languageId > 0;

  if (isValid) return ValidationErrors.noError;
  return ValidationErrors.adHocRequired;
}

export function validateSupplyMix(
  supplyMix: SupplyMix,
  wantedNumberOfCompletes: number,
  wantedNumberOfStarts: number | undefined
): ErrorModel {
  if (isEmpty(supplyMix.supplyGroups)) return ValidationErrors.supplyGroupsRequired;

  if (isPanelInMultipleSupplyGroups(supplyMix.supplyGroups)) {
    return ValidationErrors.supplyGroupsPanelIdConflict;
  }

  const sum = { percentage: 0, wantedCompletes: 0, wantedStarts: 0 };
  for (const g of supplyMix.supplyGroups) {
    if (isDisallowedMixSupplySource(g.source)) return ValidationErrors.supplyGroupsSourceInvalid;

    if (isEmpty(g.panelIds)) return ValidationErrors.supplyGroupsPanelIdsRequired;

    if (!isNumber(g.percentage) || !isFinite(g.percentage) || g.percentage < 0 || g.percentage > 100) {
      return ValidationErrors.supplyGroupsPercentageInvalid;
    }

    if (
      !isNumber(g.wantedCompletes) ||
      !isFinite(g.wantedCompletes) ||
      !isInteger(g.wantedCompletes) ||
      g.wantedCompletes < 0
    ) {
      return ValidationErrors.supplyGroupsWantedInvalid;
    }
    sum.percentage += g.percentage;
    sum.wantedCompletes += g.wantedCompletes;
    if (isNumber(wantedNumberOfStarts)) {
      sum.wantedStarts += g.wantedStarts;
    }
  }

  if (sum.percentage !== 100) return ValidationErrors.supplyGroupsPercentageSum;
  if (sum.wantedCompletes !== wantedNumberOfCompletes) return ValidationErrors.supplyGroupsWantedSum;
  if (isNumber(wantedNumberOfStarts) && sum.wantedStarts !== wantedNumberOfStarts) {
    return ValidationErrors.supplyGroupsWantedSum; // TODO QOS: do I need a special wanted sum validaiton error for starts?
  }

  if (supplyMix.supplyGroups.some((group) => group.percentage === 100)) return ValidationErrors.mixOfOneGroupOnly;

  return ValidationErrors.noError;
}

function isDisallowedMixSupplySource(source: SupplySource): boolean {
  switch (source) {
    case SupplySource.CintPanels:
      return false;
    case SupplySource.OwnPanels:
      return false;
    case SupplySource.PrivatePricing:
      return false;
    default:
      return true;
  }
}

function isPanelInMultipleSupplyGroups(groups: SupplyGroup[]): boolean {
  const panelIds = groups.reduce((acc, g) => acc.concat(g.panelIds), []);
  return uniq(panelIds).length < panelIds.length;
}
