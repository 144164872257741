import { ProfilingSearchResult } from '../../../common/http-services/profiling.httpservice';
import { ProfilingModel } from '../../../common/models/profiling.model';

export interface ProfilingCategory {
  readonly id: number;
  readonly name: string;
  readonly questions: ProfilingQuestion[];
}

export interface ProfilingQuestion {
  readonly id: number;
  readonly text: string;
  readonly depth?: number;
  readonly depthSelectedVariables?: number;
  readonly isMappedToGlobalQuestion: boolean | null;
  readonly variables: ProfilingVariable[];
  readonly isMatched?: boolean;
}

export interface ProfilingVariable {
  readonly id: number;
  readonly order: number;
  readonly name: string;
  readonly isMappedToGlobalVariable: boolean | null;
  readonly isSelected?: boolean;
  readonly isMatched?: boolean;
}

// TODO: this isn't "detailed" at all. It's less detailed...
export interface DetailedSelectionItem {
  readonly id: number;
  readonly name: string;
  readonly questions: DetailedSelectionItemQuestion[];
}

export interface DetailedSelectionItemQuestion {
  readonly id: number;
  readonly name: string;
  readonly variables: DetailedSelectionItemQuestionVariable[];
}

export interface DetailedSelectionItemQuestionVariable {
  readonly id: number;
  readonly name: string;
}

export interface SelectedVariables {
  [id: number]: {
    categoryId: number;
  };
}

export interface ActiveCategory {
  readonly id: number;
  readonly name: string;
}

export interface ActiveProfilingModel {
  readonly detailedSelection: DetailedSelectionItem[];
  readonly categories: ProfilingCategory[];
  readonly selectedVariables: SelectedVariables;
  readonly activeCategory: ActiveCategory;
  readonly panelSpecificProfiling: boolean;
  readonly searchResult: ProfilingSearchResult;
}

export function createActiveProfilingModel(): ActiveProfilingModel {
  return {
    detailedSelection: [],
    categories: [],
    selectedVariables: {},
    activeCategory: {} as ProfilingCategory,
    panelSpecificProfiling: false,
    searchResult: null,
  };
}

export function createActiveProfilingModelFrom(profiling: ProfilingModel): ActiveProfilingModel {
  return {
    detailedSelection: [],
    categories: [],
    selectedVariables: profiling.selectedVariables,
    activeCategory: profiling.categoryId > 0 ? { id: profiling.categoryId, name: '' } : ({} as ActiveCategory),
    panelSpecificProfiling: profiling.panelSpecificProfiling,
    searchResult: null,
  };
}
