import { ui } from 'angular';
import { map, filter, some, concat } from 'lodash-es';
import { projectService } from '../../project.service';
import { html } from '../../../helpers';
import { removeExtraSpaces } from '../../../common/filters';

const selector = 'renameTargetGroupDialogComponent';

const template = html`
  <div class="rename-target-group-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Rename {{$ctrl.targetGroupOrTemplate}}?</h1>
      <p>Rename this {{$ctrl.targetGroupOrTemplate}} to</p>
      <form class="push-down" ng-submit="$ctrl.ok()">
        <input
          data-testid="rename-tg-input"
          type="text"
          class="form-control"
          ng-model="$ctrl.targetGroup.newName"
          autofocus
          value=""
          maxlength="60"
        />
        <div class="validator-message" ng-show="$ctrl.errors.invalidName()">
          Enter a name for your {{$ctrl.targetGroupOrTemplate}}.
        </div>
        <div class="validator-message" ng-show="$ctrl.errors.nameNotUnique">
          It looks to me as if this target group already exists in the list. Please enter a unique name for the target
          group.
        </div>
      </form>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button
          class="ui-btn primary-btn"
          ng-disabled="$ctrl.errors.invalidName()"
          ng-click="$ctrl.ok()"
          data-testid="rename-tg-button"
        >
          Rename {{$ctrl.targetGroupOrTemplate}}
        </button>
      </div>
    </div>
  </div>
`;

export interface RenameTargetGroupDialogResolve {
  targetGroupId: number;
  existingTargetGroupNames: string[];
  editingTemplate?: boolean;
}

interface Bindings {
  resolve: '<' | RenameTargetGroupDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class RenameTargetGroupDialogComponent implements Bindings {
  static componentName = selector;

  resolve: RenameTargetGroupDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  existingTargetGroupNames: string[];
  targetGroup: {
    id: number;
    newName: string;
  };
  errors: {
    nameNotUnique: boolean;
    invalidName: () => boolean;
  };
  editingTemplate: boolean;

  get targetGroupOrTemplate(): string {
    return this.editingTemplate ? 'template' : 'target group';
  }

  $onInit() {
    this.editingTemplate = this.resolve.editingTemplate;
    projectService.findById(this.resolve.targetGroupId).then((tg) => {
      this.targetGroup = {
        id: this.resolve.targetGroupId,
        newName: tg.name,
      };
      this.existingTargetGroupNames = this.resolve.existingTargetGroupNames;
      this.errors = {
        nameNotUnique: false,
        invalidName: () => !(this.targetGroup.newName && this.targetGroup.newName.trim().length > 0),
      };
    });
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  ok(): void {
    if (this.errors.invalidName()) return;

    this.errors.nameNotUnique = false;

    if (!this.isUnique()) {
      this.errors.nameNotUnique = true;
      return;
    }

    this.targetGroup.newName = removeExtraSpaces(this.targetGroup.newName);
    this.modalInstance.close(this.targetGroup);
  }

  private isUnique(): boolean {
    const newTargetGroupNames = map(
      filter(projectService.targetGroups, (t) => t.id !== this.targetGroup.id),
      (tg) => tg.name
    );
    const allTargetGroupNames = concat(newTargetGroupNames, this.existingTargetGroupNames);
    return !some(
      allTargetGroupNames,
      (tg) => removeExtraSpaces(tg).toLowerCase() === removeExtraSpaces(this.targetGroup.newName).toLowerCase()
    );
  }
}

export const ngRenameTargetGroupDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: RenameTargetGroupDialogComponent,
  },
};
