import { IHttpPromise, IPromise } from 'angular';
import { Record, Array, Null, Boolean, Static, String, Optional } from 'runtypes';
import { urlService } from './url.service';
import { PotentiallyCanceledResult, cancelingHttpService } from './canceling.httpservice';
import { ProfilingDepthRequest } from '../../target-groups/profiling/profiling-depth-request.model';
import { ProjectSettings } from '../project-settings.service';
import { AboveZero, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { $http } from '../../ngimport';

export const VariableSearchResult = Record({
  id: AboveZero,
  order: NonNegative,
  name: NonEmptyString,
  highlightedName: String,
  isMappedToGlobalVariable: Boolean.Or(Null),
  isMatched: Boolean,
  isSelected: Optional(Boolean),
});
export type VariableSearchResult = Static<typeof VariableSearchResult>;

export const QuestionSearchResult = Record({
  id: AboveZero,
  text: NonEmptyString,
  highlightedText: String,
  isMappedToGlobalQuestion: Boolean.Or(Null),
  isMatched: Boolean,
  variables: Array(VariableSearchResult),
});
export type QuestionSearchResult = Static<typeof QuestionSearchResult>;

export const CategorySearchResult = Record({
  id: AboveZero,
  name: NonEmptyString,
  questions: Array(QuestionSearchResult),
});
export type CategorySearchResult = Static<typeof CategorySearchResult>;

export const ProfilingSearchResult = Record({
  results: Array(CategorySearchResult),
  paginationToken: String.Or(Null),
  pageSize: AboveZero,
  searchTerm: Optional(String),
});

export type ProfilingSearchResult = Static<typeof ProfilingSearchResult>;

export const ProfilingCategoryResponse = Array(
  Record({
    id: AboveZero,
    name: NonEmptyString,
  })
);
export type ProfilingCategoryResponse = Static<typeof ProfilingCategoryResponse>;

export const ProfilingVariableResponse = Record({
  id: AboveZero,
  order: NonNegative,
  name: NonEmptyString,
  isMappedToGlobalVariable: Boolean.Or(Null), // panel categories only
});
export type ProfilingVariableResponse = Static<typeof ProfilingVariableResponse>;

export const ProfilingQuestionResponse = Record({
  id: AboveZero,
  text: NonEmptyString,
  variables: Array(ProfilingVariableResponse),
  isMappedToGlobalQuestion: Boolean.Or(Null), // panel categories only
});
export type ProfilingQuestionResponse = Static<typeof ProfilingQuestionResponse>;

export const ProfilingCategoryDetailsResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  questions: Array(ProfilingQuestionResponse),
});
export type ProfilingCategoryDetailsResponse = Static<typeof ProfilingCategoryDetailsResponse>;

export const ProfilingCategoryDetailsByIdsResponse = Array(ProfilingCategoryDetailsResponse);
export type ProfilingCategoryDetailsByIdsResponse = Static<typeof ProfilingCategoryDetailsByIdsResponse>;

export const ProfilingQuestionDepthResponse = Record({
  id: AboveZero,
  depth: NonNegative,
  depthSelectedVariables: NonNegative,
});
export type ProfilingQuestionDepthResponse = Static<typeof ProfilingQuestionDepthResponse>;

export const ProfilingCategoryDepthResponse = Record({
  id: AboveZero,
  questions: Array(ProfilingQuestionDepthResponse),
});
export type ProfilingCategoryDepthResponse = Static<typeof ProfilingCategoryDepthResponse>;
export const ProfilingCategoryDepthBatchResponse = Array(ProfilingCategoryDepthResponse);
export type ProfilingCategoryDepthBatchResponse = Static<typeof ProfilingCategoryDepthBatchResponse>;

export interface CategoriesRequestParam {
  countryId: number;
  panelId?: number;
}

export class ProfilingHttpService {
  getCategories(item: CategoriesRequestParam): IHttpPromise<ProfilingCategoryResponse> {
    const url = item.panelId
      ? urlService.getCategoriesForPanel(item.countryId, item.panelId)
      : urlService.getCategoriesForCountry(item.countryId);

    return $http.validatingGet(ProfilingCategoryResponse, url);
  }

  getCategoriesByIds(
    item: CategoriesRequestParam,
    categoryIds: number[]
  ): IHttpPromise<ProfilingCategoryDetailsByIdsResponse> {
    const url = item.panelId
      ? urlService.getPanelCategoriesByIds(item.countryId, item.panelId)
      : urlService.getGlobalCategoriesByIds(item.countryId);

    return $http.validatingPost(ProfilingCategoryDetailsByIdsResponse, url, categoryIds);
  }

  searchCategoriesByIds(
    countryId: number,
    categoryId: number,
    searchString: string
  ): IHttpPromise<ProfilingSearchResult> {
    const url = urlService.searchCategories(countryId, categoryId, searchString);
    return $http.validatingGet(ProfilingSearchResult, url);
  }

  getCategoryDetails(item: CategoriesRequestParam, categoryId: number): IHttpPromise<ProfilingCategoryDetailsResponse> {
    const url = item.panelId
      ? urlService.getPanelCategory(item.countryId, item.panelId, categoryId)
      : urlService.getCountrySpecificCategory(item.countryId, categoryId);

    return $http.validatingGet(ProfilingCategoryDetailsResponse, url);
  }

  getCategoryDepth(
    categoryId: number,
    request: {
      targetGroup: ProfilingDepthRequest;
      projectSettings: ProjectSettings;
    }
  ): IPromise<PotentiallyCanceledResult<ProfilingCategoryDepthResponse>> {
    return cancelingHttpService.validatingPost(
      ProfilingCategoryDepthResponse,
      urlService.getCategoryDepth(categoryId),
      request
    );
  }

  getCategoryIdByQuestionId(countryId: number, questionId: number, isPanelQuestion?: boolean): IPromise<AboveZero> {
    return $http
      .validatingGet(AboveZero, urlService.categoryIdByQuestionId(countryId, questionId, isPanelQuestion))
      .then((res) => res.data);
  }
}

export const profilingHttpService = new ProfilingHttpService();
