import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { SurveyMetadataModel } from '../../../../common/models/survey-metadata.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { surveyMetadataSubactions } from './survey-metadata.subactions';
import { stateDebugService } from '../../../../common/state-debug.service';

type SurveyMetadataUpdateFunc = (
  state: ActiveTargetGroupModel,
  commit: CommitFuncs,
  done: (preventFeasibility: boolean) => void
) => void;

export const commitSurveyMetadata =
  () =>
  (surveyMetadataState: SurveyMetadataModel): void => {
    activeTargetGroupStore.model.surveyMetadata = surveyMetadataState;
    targetGroupChannel.model.surveyMetadata.updated.dispatch({
      targetGroupId: activeTargetGroupStore.model.identity.id,
      surveyMetadataTagsCount: surveyMetadataState.studyTypes.length,
    });
  };

export class SurveyMetadataActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  setSurveyTags(surveyTags: string[]) {
    this.update((state, commit, done) => {
      const newState = surveyMetadataSubactions.setSurveyTags(state.surveyMetadata, surveyTags);
      commit.surveyMetadata(newState);
      done(true);
    });
  }

  clearSurveyTags() {
    this.update((state, commit, done) => {
      const newState = surveyMetadataSubactions.clearSurveyTags(state.surveyMetadata);
      commit.surveyMetadata(newState);
      done(true);
    });
  }

  private update(updateFunc: SurveyMetadataUpdateFunc) {
    stateDebugService.logIfUnsafeUpdate('survey metadata');
    this.rootStore.signalUpdateStarted();

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, (preventFeasibility = false) =>
      this.rootStore.publishUpdate(preventFeasibility)
    );
  }
}
