export const datePickerOptions: mgcrea.ngStrap.datepicker.IDatepickerOptions = {
  dateFormat: 'd MMM yyyy',
  dateType: 'iso',
  autoclose: true,
  iconLeft: 'fas fa-chevron-left',
  iconRight: 'fas fa-chevron-right',
};

export const timePickerOptions: mgcrea.ngStrap.timepicker.ITimepickerOptions = {
  timeFormat: 'HH:mm',
  minuteStep: 1,
  iconUp: 'fas fa-chevron-up',
  iconDown: 'fas fa-chevron-down',
};
