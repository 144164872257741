import { Draft } from 'immer';
import { keys, find } from 'lodash-es';
import { ProfilingSearchResult } from '../../../../common/http-services/profiling.httpservice';
import {
  ActiveProfilingModel,
  ProfilingCategory,
  ProfilingVariable,
  ProfilingQuestion,
  ActiveCategory,
  SelectedVariables,
} from '../../models/active-profiling.model';

export const mutators = {
  setActiveCategory: (category: ProfilingCategory) => (state: Draft<ActiveProfilingModel>) => {
    state.activeCategory = {
      id: category.id,
      name: category.name,
    };
  },

  clearActiveCategory: () => (state: Draft<ActiveProfilingModel>) => {
    state.activeCategory = {} as ProfilingCategory;
  },

  setSearchResult: (searchResult: ProfilingSearchResult) => (state: Draft<ActiveProfilingModel>) => {
    state.searchResult = searchResult;
  },

  setCategoryQuestions: (category: ProfilingCategory) => (state: Draft<ActiveProfilingModel>) => {
    const cat = find(state.categories, (c) => c.id === category.id);
    if (cat === undefined) return;

    const mappedQuestions = category.questions.map(mapQuestion);

    if (!cat.questions?.length) {
      cat.questions = mappedQuestions;
      return;
    }

    mappedQuestions.forEach((mappedQuestion) => {
      if (cat.questions.every((question) => question.id !== mappedQuestion.id)) cat.questions.push(mappedQuestion);
    });
  },

  setCategoryName: (categoryName: string) => (state: Draft<ActiveProfilingModel>) => {
    state.activeCategory.name = categoryName;
  },

  setCategoryVariablesIsSelected:
    (category: ProfilingCategory, selectedIds: { [p: number]: { categoryId: number } }) =>
    (state: Draft<ActiveProfilingModel>) => {
      const currentCategory = state.categories.find((c) => c.id === category.id);
      if (currentCategory === undefined) return;

      for (const variableId of keys(selectedIds)) {
        const variable = findVariable(parseInt(variableId, 10), currentCategory);
        if (variable !== undefined) {
          variable.isSelected = true;
        }
      }
    },

  setSelectedVariables: (selectedVariables: SelectedVariables) => (state: Draft<ActiveProfilingModel>) => {
    state.selectedVariables = selectedVariables;
  },

  deselectAllVariables: (categoryId: number) => (state: Draft<ActiveProfilingModel>) => {
    const cat = state.categories.find((c) => c.id === categoryId);
    for (const q of cat.questions || []) {
      for (const v of q.variables) {
        v.isSelected = false;
      }
    }
  },

  deselectAllVariablesForQuestion: (categoryId: number, questionId: number) => (state: Draft<ActiveProfilingModel>) => {
    const cat = state.categories.find((c) => c.id === categoryId);
    const question = cat.questions.find((q) => q.id === questionId);
    for (const v of question.variables) {
      v.isSelected = false;
    }
  },

  setSelectedVariablesItem:
    (variableId: number, newValue: { categoryId: number }) => (state: Draft<ActiveProfilingModel>) => {
      state.selectedVariables[variableId] = newValue;
    },

  setDetailedSelection: (categories: ProfilingCategory[]) => (state: Draft<ActiveProfilingModel>) => {
    state.detailedSelection = categories
      .map((c) => ({
        id: c.id,
        name: c.name,
        questions: c.questions
          .map((q) => ({
            id: q.id,
            name: q.text,
            variables: q.variables
              .filter((v) => v.isSelected)
              .map((v) => ({
                id: v.id,
                name: v.name,
              })),
          }))
          .filter((q) => q.variables.length),
      }))
      .filter((c) => c.questions.length);
  },

  setPanelSpecificProfiling: (enabled: boolean) => (state: Draft<ActiveProfilingModel>) => {
    state.categories = [];
    state.activeCategory = {} as ActiveCategory;
    state.selectedVariables = {};
    state.detailedSelection = [];
    state.panelSpecificProfiling = enabled;
  },

  setCategories: (categories: ProfilingCategory[]) => (state: Draft<ActiveProfilingModel>) => {
    state.categories = categories;
  },

  toggleVariable:
    (variableId: number, categoryId: number, toggle?: boolean) => (state: Draft<ActiveProfilingModel>) => {
      const activeCategory = state.categories.find((c) => c.id === categoryId);

      const variable = findVariable(variableId, activeCategory);
      variable.isSelected = toggle === undefined ? !variable.isSelected : toggle;
    },
};

function mapQuestion(q: ProfilingQuestion): ProfilingQuestion {
  return {
    id: q.id,
    text: q.text,
    isMappedToGlobalQuestion: q.isMappedToGlobalQuestion,
    depth: 0,
    depthSelectedVariables: 0,
    variables: q.variables.map((v) => ({
      id: v.id,
      order: v.order,
      name: v.name,
      isMappedToGlobalVariable: v.isMappedToGlobalVariable,
      isSelected: v.isSelected,
    })),
  };
}

function findVariable(variableId: number, category: Draft<ProfilingCategory>): Draft<ProfilingVariable> {
  const [match] = category.questions.map((q) => q.variables.find((v) => v.id === variableId)).filter((v) => v);
  return match;
}
