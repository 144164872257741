import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'bootstrap/dist/css/bootstrap.css';
import 'ui-select/dist/select.css';
import './global.scss';
import 'angular-motion';
import { bootstrap, element, injector, IHttpResponse } from 'angular';
import { enablePatches } from 'immer';
import { app } from './app.module';
import { initApplicationInsightsAnalytics } from './common/analytics';
import { AppSettingsResponse } from './app-settings';
import { Constants } from './constants';
import { StatusCode } from './http-interceptors';
import { initRuntimeSetting } from './runtime-settings';
import { session } from './common/session';
import { userService } from './common/user.service';
import { featureFlipper } from './common/feature-flipper';

const inj = injector(['ng']);
const $http = inj.get('$http');
const $window = inj.get('$window');
const $q = inj.get('$q');

(async () => {
  const configResponse = await getStartupConfig();
  const apiUrl = await setAndReturnApiUrl(configResponse);
  const appSettingsResponse = await getAppSettings(apiUrl);
  if (appSettingsResponse === undefined) return;

  initAppSettings(appSettingsResponse);
  performBootstrap();
})();

async function getStartupConfig(): Promise<IHttpResponse<StartupConfig>> {
  return $http.get<StartupConfig>(Constants.startupConfigUrl);
}

async function setAndReturnApiUrl(res: IHttpResponse<StartupConfig>): Promise<string> {
  const { apiUrl, buildNumber } = res.data;
  app.constant('apiUrl', apiUrl);
  app.constant('buildNumber', buildNumber);

  initRuntimeSetting('apiUrl', apiUrl);
  initRuntimeSetting('buildNumber', buildNumber);

  return $q.when(apiUrl);
}

async function getAppSettings(apiUrl: string): Promise<IHttpResponse<AppSettingsResponse>> {
  try {
    const url = `${apiUrl}/services/settings/app-settings`;
    return await $http.get<AppSettingsResponse>(url, { withCredentials: true });
  } catch (err) {
    const res = err as IHttpResponse<unknown>;
    if (res.status === -1) {
      $window.location.assign(`${apiUrl}/logout`);
    } else if (res.status === StatusCode.Unauthorized) {
      $window.location.assign(`${apiUrl}/login`);
    } else if (res.status === StatusCode.Forbidden) {
      $window.location.assign(Constants.accessDeniedPageUrl);
    }
    return undefined;
  }
}

function initAppSettings(response: IHttpResponse<AppSettingsResponse>): void {
  const settings = AppSettingsResponse.check(response.data);

  initRuntimeSetting('runtime', settings.runtime);
  initRuntimeSetting('features', settings.features);
  initRuntimeSetting('userData', settings.user);
  initRuntimeSetting('externalUrls', settings.externalUrls);
  initRuntimeSetting('productMode', settings.productMode);
  initRuntimeSetting('usageTracking', settings.usageTracking);

  initAppInsights(settings);
  initZendesk(settings);
  initImmerPatches(settings);
}

function performBootstrap(): void {
  const doc = element(document);
  doc.ready(() => {
    bootstrap(doc, [app.name], { strictDi: __PROCESS__.ENV !== 'debug' });
  });
}

function initImmerPatches(settings: AppSettingsResponse): void {
  const requiresAdmin = settings.runtime.environment === 'Production' || settings.runtime.environment === 'Sandbox';
  if (requiresAdmin && !userService.isAdminUser()) return;
  enablePatches();
}

function initAppInsights(settings: AppSettingsResponse): void {
  if (settings.applicationInsightsKey === null) return;

  initApplicationInsightsAnalytics(settings.applicationInsightsKey);
}

function initZendesk(settings: AppSettingsResponse): void {
  const { zendeskSettings } = settings.user.settings;
  if (zendeskSettings.key === null) return;

  const product = session.product ? 'AccessPro' : 'Access';
  const showFullWidget = featureFlipper.isEnabled('zendeskWidget');
  const ticketForms = zendeskSettings.ticketFormIds.map((id: number) => ({ id }));
  $window.zESettings = {
    webWidget: {
      color: { launcher: '#0794C7', launcherText: '#ffffff', theme: '#0794C7' },
      offset: { horizontal: '10px', vertical: '19px' },
      contactOptions: {
        enabled: true,
        chatLabelOnline: { '*': 'Chat with us' },
        contactFormLabel: { '*': 'Submit a request' },
      },
      launcher: {
        label: { '*': 'Support' },
        chatLabel: { '*': 'Support' },
      },
      helpCenter: {
        suppress: true,
      },
      contactForm: {
        ticketForms,
        title: {
          '*': `If your question is not listed here,
                            please reach out to your CoE team for the internal
                            information on the requested question`,
        },
      },
    },
  };

  const zdWidget = document.createElement('script');
  zdWidget.type = 'text/javascript';
  zdWidget.innerHTML = `
        var runWidgetLogic = (function() {
            'use strict';
            var module = {};
            module.init = function(chat, contactForm) {
               configureWidget(chat, contactForm);
            };
            function configureWidget(chat, contactForm) {
               if (!chat) {
                   window.zESettings.chat.suppress = true;
               } else {
                   zopimConfig();
               }
               if (!contactForm) {
                   window.zESettings.contactForm = {};
                   window.zESettings.contactOptions = {
                       enabled: false
                   };
               }
            }
            function zopimConfig() {
               var timesToRun = 50;
               var waitForZopim = setInterval(function () {
                   if (timesToRun === 0) {
                       clearInterval(waitForZopim);
                   }
                   if (window.$zopim === undefined || window.$zopim.livechat === undefined) {
                       timesToRun--;
                       return;
                   }
                   $zopim(function() {
                        var zl = $zopim.livechat;
                        zl.setOnConnected(function() {
                            var department_status = zl.departments.getDepartment('Customer Success');
                            if (department_status.status == 'offline') zl.setStatus('offline'); // Set the account status to 'offline'
                            else {
                                zl.setLanguage('en');
                                zl.window.setTitle('${zendeskSettings.chatTitle}');
                                zl.addTags("${settings.user.company}");
                                zl.addTags("${product}");
                                zl.departments.filter(''); // Hide the Drop-down list option in the Pre-Chat Form to select the Department
                                zl.departments.setVisitorDepartment('Customer Success');
                            }
                        });
                    });
                    clearInterval(waitForZopim);
               }, 100);
            }
            return module;
        }());
        
        /**
        * Change the widget settings.
        * @params {boolean} chat - enable chat?
        * @params {boolean} contactForm - enable contact form?
        */
        runWidgetLogic.init(true, ${showFullWidget});
    `;

  const zd = document.createElement('script');
  zd.id = 'ze-snippet';
  zd.type = 'text/javascript';
  zd.async = true;
  zd.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskSettings.key}`;
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(zd, s);
  s.parentNode.insertBefore(zdWidget, s);
}
