import './fancy-cache-key.component.scss';
import { html } from '../helpers';

const selector = 'fancyCacheKeyComponent';

const template = html`
  <span class="fancy-cache-key-component">
    <span class="prefix">{{ $ctrl.prefix }}</span>
    &nbsp;&nbsp;&nbsp;
    <span class="suffix">{{ $ctrl.suffix }}</span>
  </span>
`;

interface Bindings {
  value: '<' | string;
}

const bindings: Bindings = {
  value: '<',
};

export class FancyCacheKeyComponent implements Bindings {
  static componentName = selector;

  value: string;
  prefix: string;
  suffix: string;

  $onInit() {
    const endOfPrefix = this.value.indexOf(']') + 1;
    this.prefix = this.value.substring(0, endOfPrefix);
    this.suffix = this.value.substring(endOfPrefix + 1, this.value.length);
  }
}

export const ngFancyCacheKeyComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: FancyCacheKeyComponent,
  },
};
