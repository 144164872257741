import { featureFlipper } from './feature-flipper';
import { ActiveSupplyModel } from '../target-groups/active-target-group/models/active-supply.model';
import { SupplyModel } from './models/supply.model';
import { SupplySource } from './enums';

export class CountryRestrictionsDisabledService {
  shouldOverrideIrAndLoiCountryRestrictions(supply: ActiveSupplyModel | SupplyModel) {
    if (!featureFlipper.isEnabled('nonRestrictedIrAndLoi')) return false;

    const supplyIsPrivatePricingOrOwnPanel =
      supply.supplySource === SupplySource.OwnPanels || supply.supplySource === SupplySource.PrivatePricing;

    let supplyIsMixWithPrivatePricingOrOwnPanels = supply.supplySource === SupplySource.SupplyMix;

    if (supply.supplySource === SupplySource.SupplyMix) {
      for (const g of supply.supplyMix.supplyGroups) {
        if (g.source !== SupplySource.PrivatePricing && g.source !== SupplySource.OwnPanels) {
          supplyIsMixWithPrivatePricingOrOwnPanels = false;
        }
      }
    }

    return supplyIsPrivatePricingOrOwnPanel || supplyIsMixWithPrivatePricingOrOwnPanels;
  }
}

export const countryRestrictionsDisabledService = new CountryRestrictionsDisabledService();
