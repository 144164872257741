import { IHttpPromise, IPromise } from 'angular';
import { api } from '../common/api';
import { projectListsChannel } from './project-lists-channel';
import { DataChannel, Listener } from '../common/channels/channel';
import {
  ProjectSearchHit,
  ProjectSearchResponse,
  TargetGroupListModel,
} from '../common/http-services/project-search.httpService';
import { SortProperties } from './ProjectLists';
import { ProjectUserFilter, SortField } from '../common/enums';
import { projectListsSettingsRepository } from './project-lists-filter-settings.repository';

type DataFetcher = (
  scope: ProjectUserFilter,
  desc: boolean,
  sortField: SortField,
  token: string
) => IHttpPromise<ProjectSearchResponse>;

export enum InitialLoadStatus {
  Uninitiated,
  InProgress,
  Completed,
}

export type ProjectListModel = {
  error: string;
  totalCount: number;
  projects: ProjectSearchHit[];
  paginationToken: string;
  sortSettings: SortProperties;
  isLoading: boolean;
  initialLoadStatus: InitialLoadStatus;
};

export class ProjectListService extends Listener {
  listModel: ProjectListModel = {
    isLoading: false,
    initialLoadStatus: InitialLoadStatus.Uninitiated,
    error: '',
    paginationToken: null,
    projects: [],
    sortSettings: {
      descending: true,
      sortField: SortField.ProjectActivity,
    },
    totalCount: null,
  };
  private loadDataFunction: DataFetcher;
  private channel: DataChannel<ProjectListModel>;

  constructor(loadDataFunction: DataFetcher, channel: DataChannel<ProjectListModel>, defaultSortField: SortField) {
    super();
    this.loadDataFunction = loadDataFunction;
    this.channel = channel;
    projectListsChannel.scopeChanged.listen((_) => {
      this.reloadFirstPage();
    }, this);
    this.listModel.sortSettings.sortField = defaultSortField;
  }

  async loadNextPage() {
    await this.loadData();
  }

  async changeSorting(newField: SortField) {
    const prev = this.listModel.sortSettings;
    this.listModel.sortSettings = {
      sortField: newField,
      descending: prev.sortField === newField ? !prev.descending : prev.descending,
    };
    await this.reloadFirstPage();
  }

  async reloadFirstPage() {
    await this.loadData(true);
  }

  private loadData(isFirstPage = false) {
    if (isFirstPage) {
      this.listModel.paginationToken = null;
      this.listModel.projects = [];
    }

    this.listModel.isLoading = true;
    this.listModel.error = '';

    if (this.listModel.initialLoadStatus === InitialLoadStatus.Uninitiated)
      this.listModel.initialLoadStatus = InitialLoadStatus.InProgress;

    this.dispatchListModelUpdate();

    this.fetchData(isFirstPage);
  }

  private async fetchData(isFirstPage: boolean) {
    const { scope } = projectListsSettingsRepository;
    const { descending, sortField } = this.listModel.sortSettings;
    try {
      const response = await this.loadDataFunction(scope, descending, sortField, this.listModel.paginationToken);

      this.listModel.totalCount = response.data.totalHitCount;
      this.listModel.projects = isFirstPage ? response.data.hits : [...this.listModel.projects, ...response.data.hits];
      this.listModel.paginationToken = response.data.paginationToken;
    } catch {
      this.listModel.error = 'Something went wrong when loading projects. Please try again or contact support.';
    }
    this.listModel.isLoading = false;
    this.listModel.initialLoadStatus = InitialLoadStatus.Completed;

    this.dispatchListModelUpdate();
  }

  private dispatchListModelUpdate() {
    this.channel.dispatch({
      error: this.listModel.error,
      paginationToken: this.listModel.paginationToken,
      projects: this.listModel.projects,
      sortSettings: this.listModel.sortSettings,
      totalCount: this.listModel.totalCount,
      isLoading: this.listModel.isLoading,
      initialLoadStatus: this.listModel.initialLoadStatus,
    });
  }
}

export class ProjectListsService {
  active: ProjectListService = new ProjectListService(
    api.search.getActiveProjects,
    projectListsChannel.activeProjectListUpdated,
    SortField.ProjectActivity
  );
  deviating: ProjectListService = new ProjectListService(
    api.search.getDeviatingProjects,
    projectListsChannel.deviatingProjectListUpdated,
    SortField.ProjectActivity
  );
  unclosed: ProjectListService = new ProjectListService(
    api.search.getUnclosedProjects,
    projectListsChannel.unclosedProjectListUpdated,
    SortField.ProjectActivity
  );
  recent: ProjectListService = new ProjectListService(
    api.search.getRecentProjects,
    projectListsChannel.recentProjectListUpdated,
    SortField.CreatedDate
  );
  group: ProjectListService = new ProjectListService(
    api.search.getGroupProjects,
    projectListsChannel.groupProjectListUpdated,
    SortField.CreatedDate
  );

  getDeviatingTargetGroups(projectId: number): IPromise<TargetGroupListModel[]> {
    return api.search.getTargetGroups(projectId, true).then((res) => res.data);
  }

  getAllTargetGroups(projectId: number): IPromise<TargetGroupListModel[]> {
    return api.search.getTargetGroups(projectId).then((res) => res.data);
  }

  loadAll() {
    this.active.reloadFirstPage();
    this.deviating.reloadFirstPage();
    this.recent.reloadFirstPage();
    this.unclosed.reloadFirstPage();
    this.group.reloadFirstPage();
  }
}

export const projectListsService = new ProjectListsService();
