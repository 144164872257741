import { IHttpPromise } from 'angular';
import { Array, Boolean, Null, Record, Static, String } from 'runtypes';
import { Iso8601UtcString, HighlightedString, NonNegative } from '../../custom-runtypes';
import { urlService } from './url.service';
import { $http } from '../../ngimport';

export const PanelistPoolSearchHit = Record({
  id: HighlightedString,
  name: HighlightedString,
  created: Iso8601UtcString,
  used: Boolean,
});
export type PanelistPoolSearchHit = Static<typeof PanelistPoolSearchHit>;

export const PanelistPoolSearchResponse = Record({
  paginationToken: String.Or(Null),
  hits: Array(PanelistPoolSearchHit),
  totalHitCount: NonNegative,
});
export type PanelistPoolSearchResponse = Static<typeof PanelistPoolSearchResponse>;

export class PanelistPoolSearchHttpService {
  getPanelistPools(paginationToken: string, searchString = ''): IHttpPromise<PanelistPoolSearchResponse> {
    return $http.validatingGet(PanelistPoolSearchResponse, urlService.getPanelistPools(paginationToken, searchString));
  }
}

export const panelistPoolSearchHttpService = new PanelistPoolSearchHttpService();
