import { IDirective } from 'angular';
import { globalDebugOptions } from '../global-debug-options';

const directiveName = 'onlyIfImmerPatchesEnabled';

export function onlyIfImmerPatchesEnabled(): IDirective {
  return {
    link: (_scope, element) => {
      if (globalDebugOptions.options.showImmerPatches) return;
      element.remove();
    },
  };
}

export const ngOnlyIfImmerPatchesEnabledDirective = {
  [directiveName]: onlyIfImmerPatchesEnabled,
};
