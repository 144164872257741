import { fromJson, toJson } from 'angular';
import { StorageFactory } from '../../storage.factory';

export interface PersistentSurveyLinks {
  template: string;
  testLinks: string;
  screenout: string;
  explicitlyChoseNoLinks: boolean;
}

export class PersistentSurveyLinksService {
  private storage: Storage;
  private storageKey = 'surveylinks';

  constructor() {
    this.storage = StorageFactory.getStorage();
  }

  persist(targetGroupId: number, links: PersistentSurveyLinks): void {
    const surveyLinks = this.load();
    surveyLinks[targetGroupId] = links;

    this.storage.setItem(this.storageKey, toJson(surveyLinks));
  }

  load(): { [p: string]: PersistentSurveyLinks } {
    return fromJson(this.storage.getItem(this.storageKey)) || {};
  }

  clear(): void {
    this.storage.removeItem(this.storageKey);
  }

  addLinksLater(targetGroupId: number): void {
    this.persist(targetGroupId, { template: '', testLinks: '', screenout: '', explicitlyChoseNoLinks: true });
  }
}

export const persistentSurveyLinksService = new PersistentSurveyLinksService();
