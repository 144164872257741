import { useCallback, useEffect, useRef, useState } from 'react';
import { every } from 'lodash-es';

export const useOutsideClick = (cb: () => void, ...ref: React.RefObject<any>[]) => {
  const isOutside = (event: MouseEvent, refs: React.RefObject<any>[]) => {
    return every(refs, (r) => r.current && !r.current.contains(event.target));
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOutside(event, ref)) {
        cb();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(() => null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
};

export const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_dummy, setDummy] = useState(0);
  return useCallback(() => {
    setDummy((prevDummyValue) => prevDummyValue + 1);
  }, []);
};
