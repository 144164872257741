const noErrorId = 'no-error';

export enum ErrorSeverity {
  none = 'none',
  warning = 'warning',
  error = 'error',
  fatal = 'fatal',
}

// TODO: whooops, here we have a class deep inside ActiveSupplyModel. Not good.
export class ErrorModel {
  constructor(
    public readonly errorId: string,
    public readonly severity: ErrorSeverity,
    public readonly data?: { [p: string]: any }
  ) {}

  withData(data: { [p: string]: any }): ErrorModel {
    return new ErrorModel(this.errorId, this.severity, data);
  }

  withSeverity(severity: ErrorSeverity): ErrorModel {
    return new ErrorModel(this.errorId, severity, this.data);
  }

  hasError(): boolean {
    return this.errorId !== noErrorId;
  }

  equals(otherError: ErrorModel): boolean {
    return this.errorId === otherError.errorId;
  }
}

export class ValidationErrors {
  static get noError() {
    return new ErrorModel(noErrorId, ErrorSeverity.none);
  }
  static get generic() {
    return new ErrorModel('generic', ErrorSeverity.error);
  }
  static get basicSettingsInput() {
    return new ErrorModel('basic-settings', ErrorSeverity.error);
  }
  static get basicSettingsCountryRequired() {
    return new ErrorModel('country-required', ErrorSeverity.error);
  }
  static get basicSettingsCompletesRequired() {
    return new ErrorModel('completes-required', ErrorSeverity.error);
  }
  static get basicSettingsStartsRequired() {
    return new ErrorModel('starts-required', ErrorSeverity.error);
  }
  static get basicSettingsCountryRestrictions() {
    return new ErrorModel('country-restrictions', ErrorSeverity.fatal);
  }
  static get basicSettingsFixedLoiRestrictions() {
    return new ErrorModel('fixed-loi-restrictions', ErrorSeverity.fatal);
  }
  static get regionsRequired() {
    return new ErrorModel('regions-required', ErrorSeverity.error);
  }
  static get regionsPostalCode() {
    return new ErrorModel('regions-postalCode', ErrorSeverity.error);
  }
  static get profilingRequired() {
    return new ErrorModel('profiling-required', ErrorSeverity.error);
  }
  static get hasNotCheckedPanelistAvailability() {
    return new ErrorModel('has-not-checked-panelist-availability', ErrorSeverity.error);
  }
  static get noPanelistGroupSelected() {
    return new ErrorModel('no-panelist-group-selected', ErrorSeverity.error);
  }
  static get noAvailablePanelists() {
    return new ErrorModel('no-available-panelists', ErrorSeverity.error);
  }
  static get panelistPoolError() {
    return new ErrorModel('panelist-pool-error', ErrorSeverity.error);
  }
  static get adHocRequired() {
    return new ErrorModel('ad-hoc-required', ErrorSeverity.error);
  }
  static get panelRequired() {
    return new ErrorModel('panel-required', ErrorSeverity.error);
  }
  static get panelsRequired() {
    return new ErrorModel('panels-required', ErrorSeverity.error);
  }
  static get supplyGroupsRequired() {
    return new ErrorModel('supply-groups-required', ErrorSeverity.error);
  }
  static get supplyGroupsPercentageInvalid() {
    return new ErrorModel('supply-groups-percentage-invalid', ErrorSeverity.error);
  }
  static get supplyGroupsWantedInvalid() {
    return new ErrorModel('supply-groups-wanted-invalid', ErrorSeverity.error);
  }
  static get supplyGroupsPercentageSum() {
    return new ErrorModel('supply-groups-percentage-sum', ErrorSeverity.error);
  }
  static get supplyGroupsWantedSum() {
    return new ErrorModel('supply-groups-wanted-sum', ErrorSeverity.error);
  }
  static get supplyGroupsSourceInvalid() {
    return new ErrorModel('supply-groups-source-invalid', ErrorSeverity.error);
  }
  static get supplyGroupsPanelIdsRequired() {
    return new ErrorModel('supply-groups-panelIds-required', ErrorSeverity.error);
  }
  static get supplyGroupsPanelIdConflict() {
    return new ErrorModel('supply-groups-panelId-conflict', ErrorSeverity.error);
  }
  static get tooManyBuckets() {
    return new ErrorModel('too-many-buckets-error', ErrorSeverity.fatal);
  }
  static get quotasHaveNoCompletes() {
    return new ErrorModel('quotas-have-no-completes-error', ErrorSeverity.error);
  }
  static get excludeProjectsRequired() {
    return new ErrorModel('exclude-project-required', ErrorSeverity.error);
  }
  static get fixedIncentiveAmountInvalid() {
    return new ErrorModel('fixed-incentive-amount-invalid', ErrorSeverity.error);
  }
  static get fixedIncentiveAmountStepInvalid() {
    return new ErrorModel('fixed-incentive-amount-step-invalid', ErrorSeverity.error);
  }
  static get fixedIncentiveCurrencyInvalid() {
    return new ErrorModel('fixed-incentive-currency-invalid', ErrorSeverity.error);
  }
  static get rimWeightingIsNotConvergent() {
    return new ErrorModel('rim-weighting-is-not-convergent', ErrorSeverity.fatal);
  }
  static get noPanelsForSampling() {
    return new ErrorModel('no-panels-for-sampling', ErrorSeverity.fatal);
  }
  static get mixOfOneGroupOnly() {
    return new ErrorModel('mix-of-one-group-only', ErrorSeverity.error);
  }
}

export class FeasibilityErrors {
  static get noError() {
    return new ErrorModel(noErrorId, ErrorSeverity.none);
  }
  static get timeout() {
    return new ErrorModel('feasibility-timeout', ErrorSeverity.error);
  }
  static get generic() {
    return new ErrorModel('feasibility-generic', ErrorSeverity.error);
  }
}
