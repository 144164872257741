import './target-group-quotas-table.component.scss';
import { IOnChangesObject, IScope } from 'angular';
import { ExistingTargetGroupQuota } from '../http-services/existing-project.models';
import { html } from '../../helpers';
import { QuotaStats, targetGroupHttpService, QuotaRespondedStats } from '../http-services/target-group.httpservice';
import { ListenerComponent } from '../channels/channel';
import { targetGroupInfieldChannel } from '../channels/target-group-infield-channel';

const selector = 'quotaStatsComponent';

const template = html`
  <div class="quota-stats-component">
    <div class="quota-info">
      <div class="basic-stats" feature-off="quotasOnStarts">
        <div class="quota-name" ng-click="$ctrl.showQuotaRespondedStats()">
          <i class="fas fa-chevron-right" ng-class="{'expanded': $ctrl.showQuotaStats}"></i>
          {{$ctrl.quota.name}}
        </div>
        <div title="Initial wanted number of completes">{{$ctrl.quota.wantedCompletes}}</div>
        <div title="Initial feasibility">{{$ctrl.quota.initialFeasible}}</div>
        <div title="Number of invited panelists">{{$ctrl.stats.invitedCount}}</div>
        <div title="Remaining number of completes">{{$ctrl.getRemainingCompletes()}}</div>
        <div title="Remaining feasibility">{{$ctrl.infieldFeasibility}}</div>
      </div>
      <div class="basic-stats" feature-on="quotasOnStarts">
        <div class="quota-name" ng-click="$ctrl.showQuotaRespondedStats()">
          <i class="fas fa-chevron-right" ng-class="{'expanded': $ctrl.showQuotaStats}"></i>
          {{$ctrl.quota.name}}
        </div>
        <div
          ng-if="$ctrl.targetGroupHasQuotasOnStart"
          title="Actual number of starts / Initial wanted number of starts"
        >
          {{$ctrl.quota.actualStarts}}<span class="unimportant">/{{$ctrl.quota.wantedStarts}}</span>
        </div>
        <div title="Collected number of completes / Initial wanted number of completes">
          {{$ctrl.quota.actualCompletes}}<span class="unimportant">/{{$ctrl.quota.wantedCompletes}}</span>
        </div>
        <div title="Number of invited panelists">{{$ctrl.stats.invitedCount}}</div>
        <div title="Initial feasibility">
          {{$ctrl.quota.initialFeasible}}</span>
        </div>
        <div title="Remaining feasibility">
          {{$ctrl.infieldFeasibility}}</span>
        </div>
      </div>

      <div class="responded-stats" ng-if="$ctrl.showQuotaStats">
        <spin-while-loading-fancy ng-if="$ctrl.isLoading"></spin-while-loading-fancy>
        <div class="stats-table" ng-if="!$ctrl.isLoading">
          <div class="responded-stats-header">
            <span>Total responded</span>
            <span>Collected</span>
            <span>Screened out</span>
            <span>Timed out</span>
            <span>Quota full</span>
          </div>
          <div>
            <span>{{$ctrl.respondedStats.responded}}</span>
            <span>{{$ctrl.respondedStats.completes}}</span>
            <span>{{$ctrl.respondedStats.screenouts}}</span>
            <span>{{$ctrl.respondedStats.timeouts}}</span>
            <span>{{$ctrl.respondedStats.quotaFulls}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
`;

interface Bindings {
  projectId: '<' | number;
  targetGroupId: '<' | number;
  quota: '<' | ExistingTargetGroupQuota;
  stats: '<' | QuotaStats;
  infieldFeasibility: '<' | number;
  targetGroupHasQuotasOnStart: '<' | boolean;
}

const bindings: Bindings = {
  projectId: '<',
  targetGroupId: '<',
  quota: '<',
  stats: '<',
  infieldFeasibility: '<',
  targetGroupHasQuotasOnStart: '<',
};

export class QuotaStatsComponent extends ListenerComponent implements Bindings {
  static componentName = selector;

  projectId: number;
  targetGroupId: number;
  quota: ExistingTargetGroupQuota;
  stats: QuotaStats;
  infieldFeasibility: number;
  respondedStats: QuotaRespondedStats;
  targetGroupHasQuotasOnStart: boolean;
  showQuotaStats = false;
  isLoading = false;

  constructor(private $scope: IScope) {
    'ngInject';

    super();
  }

  $onInit() {
    targetGroupInfieldChannel.refreshQuotaStats.listen(() => {
      if (this.respondedStats !== undefined) this.refresh();
    }, this);
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.quota) {
      this.quota = changes.quota.currentValue;
    }

    if (changes.infieldFeasibility) {
      this.infieldFeasibility = changes.infieldFeasibility.currentValue;
    }
  }

  getActualInvitedForQuota(): number {
    return this.stats.invitedCount;
  }

  getRespondedForQuota(): number {
    return this.stats.respondedCount;
  }

  showQuotaRespondedStats() {
    this.showQuotaStats = !this.showQuotaStats;
    if (this.showQuotaStats) {
      this.getQuotaRespondedStats();
    }
  }

  getRemainingCompletes(): number {
    return this.quota.wantedCompletes - this.quota.actualCompletes > -1
      ? this.quota.wantedCompletes - this.quota.actualCompletes
      : 0;
  }

  async getQuotaRespondedStats() {
    if (this.respondedStats === undefined || this.respondedStats.id === undefined) {
      this.isLoading = true;
      this.respondedStats = (
        await targetGroupHttpService.getQuotaRespondedStats(this.projectId, this.targetGroupId, this.quota.name, false)
      ).data;
      this.isLoading = false;
      this.$scope.$apply();
    }
  }

  refresh() {
    this.isLoading = true;
    this.respondedStats = undefined;
    this.getQuotaRespondedStats();
  }
}

export const ngQuotaStatsComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: QuotaStatsComponent,
  },
};
