import { IPromise, ui } from 'angular';
import { StorageTemplate } from '../../common/target-group/target-group.repository';
import { html, silenceRejection } from '../../helpers';
import { api } from '../../common/api';
import { templateChannel } from '../../common/channels/template-channel';
import { analytics } from '../../common/analytics';

const selector = 'deleteTemplateDialogComponent';

const template = html`
  <div class="delete-template-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Delete template?</h1>
      <p ng-show="$ctrl.isInStorage">
        Deleting the template will permanently delete the template and clear the template you are currently working
        with.
      </p>
      <p ng-hide="$ctrl.isInStorage" class="push-down">
        Deleting <em>{{$ctrl.templateName}}</em> will permanently delete the template.
      </p>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button class="ui-btn red-btn" ng-click="$ctrl.ok()">Delete</button>
      </div>
    </div>
  </div>
`;

interface Template {
  id: number;
  name: string;
}

export interface DeleteTemplateDialogResolve {
  template: {
    id: number;
    name: string;
  };
  savedTemplate: StorageTemplate;
}

interface Bindings {
  resolve: '<' | DeleteTemplateDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class DeleteTemplateDialogComponent implements Bindings, DeleteTemplateDialogResolve {
  static componentName = selector;

  resolve: DeleteTemplateDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  template: Template;
  savedTemplate: StorageTemplate;
  isInStorage: boolean;
  templateName: string;

  $onInit(): void {
    this.template = this.resolve.template;
    this.savedTemplate = this.resolve.savedTemplate;
    this.isInStorage = this.template && this.savedTemplate && this.template.id === this.savedTemplate.id;
    this.templateName = this.template.name;
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  ok(): IPromise<void> {
    return api.template.deleteTemplate(this.template.id).then(() => {
      analytics.template.delete();
      templateChannel.deleted.dispatch(this.isInStorage);
      this.modalInstance.close();
    }, silenceRejection);
  }
}

export const ngDeleteTemplateDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: DeleteTemplateDialogComponent,
  },
};
