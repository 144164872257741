import './project-list-item.component.scss';
import { html, Iso8601Date } from '../../helpers';
import { ProjectListItemStatus, Product, ProjectChannel, DraftChannel } from '../../common/enums';
import { MenuListItem, MenuOptionType } from '../../common/controls/options-menu/options-menu.component';
import { ListenerComponent } from '../../common/channels/channel';
import { projectListChannel } from '../../common/channels/project-list-channel';
import { analytics } from '../../common/analytics';

import { userService } from '../../common/user.service';
import { session } from '../../common/session';

import accessIcon from '../../../images/access-icon.png';

const selector = 'projectListItemComponent';

export interface ProjectListItem {
  name: string;
  startDate: Iso8601Date;
  endDate: Iso8601Date;
  id: number;
  status: ProjectListItemStatus;
  owner: string;
  url: string;
  menuOptions: MenuListItem[];
  channel: ProjectChannel | DraftChannel;
  wantedCompletes?: number;
  currentCompletes?: number;
}

const template = html`
  <div class="project-list-item font-paragraph" ng-class="{'loading-state': $ctrl.loadingFile}">
    <div class="project-list-item__main">
      <div class="project-list__column_id" data-testid="project-list-item-component-project-id">{{$ctrl.item.id}}</div>
      <a
        class="project-list-item__name project-list__column_project"
        ng-class="{'project-list-item__name_disabled': !$ctrl.canOpenProject}"
        ng-click="$ctrl.openProject()"
        href="{{$ctrl.item.url}}"
        title="{{$ctrl.item.name}}
Created by: {{$ctrl.item.owner}}"
      >
        <img
          ng-if="$ctrl.isAccessChannelItemAndProMode"
          ng-src="${accessIcon}"
          class="project-list-item__option-icon"
          title="Created in Access"
        />
        <i ng-if="$ctrl.isTrackChannel" class="fas fa-truck-monster channel-icon" title="Created in Tracker"></i>
        <i ng-if="$ctrl.isApiChannel" class="fas fa-network-wired channel-icon" title="Created through API"></i>
        <span data-testid="project-list-item-component-project-name">{{$ctrl.item.name}} </span>
      </a>
      <div class="project-list__column_completes hidden-tablet">
        <p ng-if="!$ctrl.isDraft">{{$ctrl.item.currentCompletes}} <span>/ {{$ctrl.item.wantedCompletes}}</span></p>
        <p ng-if="$ctrl.item.currentCompletes >= $ctrl.item.wantedCompletes" class="project-list__completed">
          <i class="fas fa-check-circle"></i> Completed
        </p>
      </div>
      <div class="project-list__column_date hidden-tablet">{{$ctrl.item.startDate | dateFormat}}</div>
      <div class="project-list__column_date hidden-tablet">{{$ctrl.item.endDate | dateFormat}}</div>
      <div class="project-list__column_control hidden visible-tablet" ng-click="$ctrl.toggleProjectInfo()">
        <div class="ui-btn icon-btn project-list-item__arrow rounded-arrow-hover-tracker">
          <span class="rounded-arrow" ng-class="{'rounded-arrow_up': $ctrl.isExpanded}"></span>
        </div>
      </div>
      <div
        class="project-list__column_control"
        ng-class="{'project-list__column_control_disabled': !$ctrl.canOpenProject}"
        title="{{$ctrl.loadingFileHoverText}}"
      >
        <options-menu-component options="$ctrl.item.menuOptions" on-option-click="$ctrl.onMenuOptionSelect(option)">
        </options-menu-component>
      </div>
    </div>
    <div class="project-list-item__info-pane" ng-class="{'project-list-item__info-pane_expanded': $ctrl.isExpanded}">
      <p class="project-list-item__info" ng-if="!$ctrl.isDraft">
        Completes: {{$ctrl.item.currentCompletes}} / {{$ctrl.item.wantedCompletes}}
      </p>
      <p class="project-list-item__info">Start date: {{$ctrl.item.startDate | dateFormat}}</p>
      <p class="project-list-item__info">End date: {{$ctrl.item.endDate | dateFormat}}</p>
    </div>
  </div>
`;

interface Bindings {
  item: '<' | ProjectListItem;
  onOptionClicked: '&' | ((_: { option: MenuListItem; item: ProjectListItem }) => void);
}

const bindings: Bindings = {
  item: '<',
  onOptionClicked: '&',
};

export class ProjectListItemComponent extends ListenerComponent implements Bindings {
  static componentName = selector;

  item: ProjectListItem;
  isExpanded = false;
  onOptionClicked: (_: { option: MenuListItem; item: ProjectListItem }) => void;
  isDraft: boolean;
  url: string;
  menuOptions: MenuListItem[];
  loadingFile: boolean;
  loadingFileHoverText: string;

  get isTrackChannel() {
    // As per discussion with Andrew, I am disabling the icons for Track and API for now.
    // return this.item.channel === ProjectChannel.Track;
    return false;
  }

  get isApiChannel() {
    // As per discussion with Andrew, I am disabling the icons for Track and API for now.
    // return this.item.channel === ProjectChannel.Api;
    return false;
  }

  get canOpenProject() {
    return !(userService.hasOutstandingBills && this.isDraft);
  }

  get isAccessChannelItemAndProMode() {
    return this.item.channel === ProjectChannel.Access && session.product === Product.AccessPro;
  }

  $onInit(): void {
    this.isDraft = this.item.status === ProjectListItemStatus.Draft;
    projectListChannel.paneExpanded.listen(this.checkIfShouldCollapse, this);
    projectListChannel.loadedFile.listen((id) => {
      if (id !== this.item.id) return;
      this.loadingFile = false;
      this.loadingFileHoverText = '';
    }, this);
  }

  toggleProjectInfo(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      projectListChannel.paneExpanded.dispatch(this.item.id);
    }
  }

  openProject(): void {
    if (!this.canOpenProject) return;
    if (this.isDraft) {
      analytics.projectList.draft.view();
    } else {
      analytics.projectList.view(this.item.status, this.item.startDate);
    }
  }

  onMenuOptionSelect(option: MenuListItem): void {
    if (option.type === MenuOptionType.DownloadPanelistData) {
      this.loadingFile = true;
      this.loadingFileHoverText = 'Loading Panelist background data';
      analytics.projectList.projectMenu.downloadData(this.item.status, this.item.endDate);
    }
    if (option.type === MenuOptionType.DownloadReport) {
      this.loadingFile = true;

      switch (this.item.status) {
        case ProjectListItemStatus.Draft:
          this.loadingFileHoverText = 'Loading Draft snapshot';
          break;
        default:
          this.loadingFileHoverText = 'Loading Project progress report';
          break;
      }
    }
    if (option.type === MenuOptionType.Monitor && this.canOpenProject) {
      analytics.projectList.projectMenu.monitor(this.item.status, this.item.startDate);
    }
    if (option.type === MenuOptionType.Delete) {
      analytics.projectList.projectMenu.draft.delete();
    }
    this.onOptionClicked({ option, item: this.item });
  }

  private checkIfShouldCollapse(projectId: number | 'none') {
    if (this.item.id === projectId) return;
    this.isExpanded = false;
  }
}

export const ngProjectListItemComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ProjectListItemComponent,
  },
};
