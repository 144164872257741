import { isEmpty, uniq, find, includes, head, without, union, some, cloneDeep } from 'lodash-es';
import {
  SupplyGroupCustomCpiSettings,
  PanelSources,
  ActiveSupplyModel,
  Panel,
  EligiblePanelSourceKeys,
  ActiveSupplyGroup,
} from '../active-target-group/models/active-supply.model';
import { PanelistPoolType, SupplySource } from '../../common/enums';

import { supplyMixStateService, ActiveSupplyMixSource, SupplyGroupUpdate } from './supply-mix/supply-mix-state.service';
import { isUndefinedOrNull } from '../../helpers';
import { currencyService } from '../currency.service';
import { activeTargetGroupStore } from '../active-target-group/store/active-target-group.store';
import { PanelistPool } from '../../common/models/supply.model';
import { userService } from '../../common/user.service';
import { featureFlipper } from '../../common/feature-flipper';

// TODO: We should be able to change this to not look at isSelected on panels but instead look at selectedIds so we can stop using isSelected?
export function getSelectedRateCard(
  supplySource: SupplySource,
  panelSources: PanelSources,
  selectedIds: number[]
): string | undefined {
  if (supplySource !== SupplySource.PrivatePricing) return undefined;
  const privatePricingPanels = panelSources[SupplySource.PrivatePricing];
  if (isEmpty(privatePricingPanels)) return undefined;

  const selected = privatePricingPanels.filter((p) => includes(selectedIds, p.id));
  if (isEmpty(selected)) return undefined;

  const hashes = uniq(selected.map((p) => p.privatePricingRateCardHash));
  return hashes.length === 1 ? hashes[0] : undefined;
}

export function getSourcePanels(state: ActiveSupplyModel): Panel[] {
  return state.panelSources[getActiveSupplySource(state) as EligiblePanelSourceKeys] || [];
}

export function getActiveSupplySource(state: ActiveSupplyModel): SupplySource {
  if (state.supplySource === SupplySource.SupplyMix) {
    return supplyMixStateService.activeSupplySource || SupplySource.SupplyMix;
  }
  if (state.supplySource === SupplySource.PanelistPool) {
    return getSupplySourceFromPanelistPool(state.panelistPool);
  }
  return state.supplySource;
}

export function getSupplySourceFromPanelistPool(panelistPool: PanelistPool): SupplySource {
  switch (panelistPool.selectedGroup) {
    case 'opinionHub':
      return SupplySource.CintPanels;
    case '':
      return SupplySource.PanelistPool;
    default:
      return panelistPool.selectedGroup as SupplySource;
  }
}

export function hasAnySupplyGroups(state: ActiveSupplyModel): boolean {
  if (isEmpty(state.supplyMix)) return false;
  return !isEmpty(state.supplyMix.supplyGroups);
}

export function shouldShowTogglePanelProfiling(state: ActiveSupplyModel): boolean {
  const activeSupplySource = getActiveSupplySource(state);

  if (state.selectedIds.length === 1) {
    const panel = find(getSourcePanels(state), (p) => p.id === head(state.selectedIds));
    if (panel === undefined) return false;

    if (featureFlipper.isEnabled('panelSpecificPushProfiling')) {
      return includes([SupplySource.OwnPanels, SupplySource.PrivatePricing], activeSupplySource);
    }
    return activeSupplySource === SupplySource.OwnPanels && !panel.isPush;
  }

  return false;
}

export function getPanelSourcesWithSelectedPanels(state: ActiveSupplyModel) {
  const containsPanels = (p: { id: number }) => includes(state.selectedIds, p.id);

  const sources = state.panelSources;
  return {
    cintPanels: sources.cintPanels.filter(containsPanels),
    ownPanels: sources.ownPanels.filter(containsPanels),
    privatePricing: sources.privatePricing.filter(containsPanels),
  };
}

export function supplyIsValidForFixedIncentive(state: ActiveSupplyModel): boolean {
  if (state.supplySource !== SupplySource.OwnPanels) return false;
  if (isUndefinedOrNull(state.ownPanelCurrency)) return false;
  const currency = currencyService.getCurrency(state.ownPanelCurrency);
  return currency.isValidForFixedIncentive;
}

export function supplyIsValidForSupplierMetadata(state: ActiveSupplyModel): boolean {
  if (state?.supplySource === SupplySource.PanelistPool && state?.panelistPool.type === PanelistPoolType.Inclusive)
    return state?.panelistPool.selectedGroup === 'ownPanels' || state?.panelistPool.selectedGroup === 'privatePricing';

  return state?.supplySource === SupplySource.PrivatePricing || state?.supplySource === SupplySource.OwnPanels;
}

export function getPanelNameBySourceAndId(source: ActiveSupplyMixSource, id: number): string {
  const { panelSources } = activeTargetGroupStore.model.supply;
  let selectedSource: Panel[];
  switch (source) {
    case SupplySource.CintPanels:
      selectedSource = panelSources.cintPanels || [];
      break;
    case SupplySource.OwnPanels:
      selectedSource = panelSources.ownPanels || [];
      break;
    case SupplySource.PrivatePricing:
      selectedSource = panelSources.privatePricing || [];
      break;
    default:
      selectedSource = [];
  }
  const panel = selectedSource.find((p) => p.id === id);
  return panel ? panel.name : '';
}

export function togglePanelForSupplyGroup(
  supplyGroup: ActiveSupplyGroup | SupplyGroupUpdate,
  panelId: number,
  panels: Panel[]
): ActiveSupplyGroup | SupplyGroupUpdate {
  const updatedSupplyGroup = cloneDeep(supplyGroup);
  const panelToToggle = panels.find((p) => p.id === panelId);
  if (!isAllowedToSelectPanel(panelToToggle)) {
    return updatedSupplyGroup;
  }
  const index = updatedSupplyGroup.panelIds.indexOf(panelId);
  if (index === -1) {
    updatedSupplyGroup.panelIds.push(panelId);
  } else {
    updatedSupplyGroup.panelIds.splice(index, 1);
  }
  return updatedSupplyGroup;
}

export function setSelectedIdsForSupplyGroup(
  supplyGroup: SupplyGroupUpdate,
  panelIds: number[],
  select: boolean,
  panels: Panel[]
) {
  const updatedSupplyGroup = cloneDeep(supplyGroup);
  if (!select) {
    updatedSupplyGroup.panelIds = without(updatedSupplyGroup.panelIds, ...panelIds);
    return updatedSupplyGroup;
  }
  const selectablePanelIds = panels
    .filter((p) => includes(panelIds, p.id) && isAllowedToSelectPanel(p))
    .map((p) => p.id);
  updatedSupplyGroup.panelIds = union(updatedSupplyGroup.panelIds, selectablePanelIds);
  return updatedSupplyGroup;
}

export function setUseCustomCpiForSupplyGroup(supplyGroup: SupplyGroupUpdate, useCcpi: boolean) {
  const updatedSupplyGroup = cloneDeep(supplyGroup);
  updatedSupplyGroup.useCustomCpi = useCcpi;
  return updatedSupplyGroup;
}

export function setCustomCpiForSupplyGroup(
  supplyGroup: SupplyGroupUpdate,
  customCpi: number,
  settings: SupplyGroupCustomCpiSettings
) {
  const updatedSupplyGroup = cloneDeep(supplyGroup);
  updatedSupplyGroup.customCpi = customCpi;
  updatedSupplyGroup.ccpiSettings = {
    minValue: settings.minValue,
    maxValue: settings.maxValue,
    currency: settings.currency,
  };
  return updatedSupplyGroup;
}

export function isAllowedToSelectPanel(panel: Panel): boolean {
  if (panel.isDisqualified) return false;
  if (panel.isLocked && !userService.allowLockedPanels) return false;
  return true;
}

export function disabledForEditInvitationEmail(
  panel: Panel,
  selectedIds: number[],
  availablePanelIds?: number[]
): boolean {
  const isSelected = includes(selectedIds, panel.id);
  const notIncludedInPanelistPool = some(availablePanelIds) && !includes(availablePanelIds, panel.id);
  return (selectedIds.length > 0 && !isSelected) || notIncludedInPanelistPool;
}

export function disabledForFixedIncentives(panel: Panel, ownPanelCurrency?: string): boolean {
  return ownPanelCurrency !== undefined && panel.currencyCode !== ownPanelCurrency;
}

export function disabledForPrivatePricing(panel: Panel, panels: Panel[], selectedPanelIds?: number[]): boolean {
  if (!some(selectedPanelIds)) return false;
  const selectedPanels = panels.filter((p) => selectedPanelIds.indexOf(p.id) > -1);
  const rateCardHashes = uniq(selectedPanels.map((p) => p.privatePricingRateCardHash));
  if (rateCardHashes.length !== 1) return true;
  return panel.privatePricingRateCardHash !== rateCardHashes[0];
}

export function filterCustomCpiPanels(panels: Panel[]): Panel[] {
  return panels.filter((p) => p.supportsCustomCpi);
}
