import { analytics } from './common/analytics';
import { $provide } from './ngimport';

export function extendErrorHandler() {
  $provide.decorator('$exceptionHandler', ($delegate: any) => {
    'ngInject';

    // eslint-disable-next-line func-names
    return function (exception: Error, cause: any): void {
      $delegate(exception, cause);
      analytics.trackException(exception);
    };
  });
}
