import { Record, Static, String, Boolean, Literal, Array, Union, Null, Number } from 'runtypes';

export const FeatureKey = Union(
  Union(
    Literal('msViaAccess'),
    Literal('nonRestrictedIrAndLoi'),
    Literal('downloadPanelistBackgroundDataAsJson'),
    Literal('editInvitationEmail'),
    Literal('fixedLoi'),
    Literal('editIncentive'),
    Literal('excludeExternalPanelistPool'),
    Literal('excludePanelistPool'),
    Literal('balancedSampling'),
    Literal('customParameters'),
    Literal('zendeskWidget'),
    Literal('panelSpecificPushProfiling'),
    Literal('panelSpecificBackgroundData'),
    Literal('preventLsrOverSampling'),
    Literal('showTrackProjectsForExclusion'),
    Literal('showApiChannelProjects'),
    Literal('viewBuckets'),
    Literal('customCpi'),
    Literal('customCpiOpenExchange'),
    Literal('supplierMetadata'),
    Literal('replacementTargetGroup')
  ),
  Union(
    Literal('disableAutodeliveryOptimizerSwitch'),
    Literal('externalIdsPool'),
    Literal('overrideSuspensionLimits'),
    Literal('useCreateProjectV2'),
    Literal('dashboard'),
    Literal('programmaticApiPools'),
    Literal('oxFeasibilityComparison'),
    Literal('statSocial'),
    Literal('internalPricing'),
    Literal('quotasOnStarts'),
    Literal('increasedBucketLimit'),
    Literal('useNewCensus'),
    Literal('customCpiMixSupply'),
    Literal('serverToServerEndLinks'),
    Literal('nameQuotaGroups'),
    Literal('feasibilityInfo'),
    Literal('autoCloseProject'),
    Literal('autoCloseInactivityThreshold'),
    Literal('projectChangeLog'),
    Literal('targetGroupListCaching'),
    Literal('displayCurrency'),
    Literal('billingEntity'),
    Literal('excludeEnablingOptimizer'),
    Literal('showOptimizerEnabled'),
    Literal('aiFeasibility'),
    Literal('aiFeasibilityEnabledForAll'),
    Literal('validateMinimumSpend'),
    Literal('ignoreValidateMinimumSpend')
  )
);

export type FeatureKey = Static<typeof FeatureKey>;

export const Environment = Union(
  Literal('None'),
  Literal('Production'),
  Literal('Beta'),
  Literal('Sandbox'),
  Literal('Development'),
  Literal('Test')
);

export type Environment = Static<typeof Environment>;

export const ExternalUrls = Record({
  login: String,
  connect: String,
  opinionHubUpdateProjectCloseUrl: String,
  account: String,
  products: String,
});
export type ExternalUrls = Static<typeof ExternalUrls>;

export const Runtime = Record({
  build: String,
  environment: Environment,
});
export type Runtime = Static<typeof Runtime>;

export const Role = Union(
  Literal('Access'),
  Literal('AccessPro'),
  Literal('Connect'),
  Literal('CpxImpersonationAdmin'),
  Literal('CpxCountryAdmin'),
  Literal('ProjectLead')
);
export type Role = Static<typeof Role>;

export const UserSettings = Record({
  isAdminUser: Boolean,
  showCompanyProjects: Boolean,
  allowLockedPanels: Boolean,
  hasMandatoryPurchaseOrder: Boolean,
  hasMandatoryJobNumber: Boolean,
  numberOfRequiredAdditionalEmails: Number,
  restrictedPanelUsage: Boolean,
  surveyTestingNeeded: Boolean,
  isWebcamRequired: Boolean,
  hasOutstandingBills: Boolean,
  allowPiiKeywordTagsOnUrls: Boolean,
  predefinedAdditionalContactEmails: Array(String).Or(Null),
  staleHours: Number,
  firstNotificationDaysForAutoClose: Number.Or(Null),
  displayCurrencyCode: String.Or(Null),
  zendeskSettings: Record({
    chatTitle: String.Or(Null),
    key: String.Or(Null),
    ticketFormIds: Array(Number).Or(Null),
  }),
  blockProjectCreation: Boolean,
});
export type UserSettings = Static<typeof UserSettings>;

export const User = Record({
  name: String,
  company: String,
  impersonatedAs: String,
  roles: Array(Role),
  settings: UserSettings,
});
export type User = Static<typeof User>;

export const ProductModeSettings = Record({
  headerName: String,
});
export type ProductModeSettings = Static<typeof ProductModeSettings>;

export const UsageTrackingSettings = Record({
  setupSessionIdHeaderName: String,
});
export type UsageTrackingSettings = Static<typeof UsageTrackingSettings>;

export const AppSettingsResponse = Record({
  runtime: Runtime,
  features: Array(FeatureKey),
  user: User,
  productMode: ProductModeSettings,
  usageTracking: UsageTrackingSettings,
  externalUrls: ExternalUrls,
  applicationInsightsKey: String.Or(Null),
});
export type AppSettingsResponse = Static<typeof AppSettingsResponse>;
