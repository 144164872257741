import './target-group-supply.component.scss';
import { IOnChangesObject, IScope } from 'angular';
import { flatMap, includes, some, uniq } from 'lodash-es';
import {
  ExistingTargetGroupQuota,
  ExistingTargetGroup,
  PanelNameWithTag,
} from '../http-services/existing-project.models';
import { QuotaGrouping, SupplySource, hasInclusivePanelistPool } from '../enums';
import { html } from '../../helpers';
import { FixedIncentive } from '../pricing/price-models';
import { Filter, FilterResult } from '../controls/filter/filter';

const selector = 'targetGroupSupplyComponent';

const template = html`
  <div class="target-group-supply-component">
    <div ng-switch="$ctrl.supplySource">
      <div ng-switch-when="adHoc">
        <h2>Ad hoc supplier</h2>
        <p>{{$ctrl.targetGroup.adHocSupplier.name}}</p>
      </div>
      <div ng-switch-when="panelistPool">
        <h2>Specific panelists</h2>
        <p>
          <span ng-if="$ctrl.targetGroup.supply.panelistPool.containsUrl">with links</span>
        </p>
      </div>
      <div ng-switch-when="systemSelected">
        <h2 class="push-down">System selected set of panels</h2>
      </div>
      <div ng-switch-when="supplyMix">
        <table class="details-table">
          <thead>
            <tr>
              <th>Supply selection</th>
              <th>Wanted</th>
              <th>Feasible</th>
            </tr>
          </thead>
          <tbody>
            <tr ng-repeat="supplyGroup in $ctrl.supplyGroupQuotas">
              <td>{{supplyGroup.name | trimBrackets}}</td>
              <td>{{supplyGroup.wantedCompletes}}</td>
              <td>{{supplyGroup.initialFeasible}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div ng-switch-when="unspecifiedPanels">
        <h2>Specific panels</h2>
        <div ng-if="$ctrl.panelsTotalAmount > 20">
          <h3>Search and filter in list to find a specific panel</h3>
          <panel-filter-component
            search="$ctrl.searchString"
            filters="$ctrl.filters"
            on-search="$ctrl.onSearch"
            on-filter="$ctrl.onFilter"
          />
        </div>
        <div class="push-down" ng-if="$ctrl.panels.length < $ctrl.panelsTotalAmount">
          {{$ctrl.panels.length}} of {{$ctrl.panelsTotalAmount}} panels displayed
        </div>
        <p class="panel-list">
          <label class="option-label" ng-repeat="panel in $ctrl.panels"
            ><span>{{panel.name}}</span> <span class="tag-label" ng-repeat="tag in panel.tags">{{tag}}</span></label
          >
        </p>
      </div>
    </div>
    <fieldset feature-on="editIncentive">
      <div ng-hide="$ctrl.fixedIncentive.amount" class="irrelevant push-down-more">No custom incentive was set up</div>
      <div ng-show="$ctrl.fixedIncentive.amount">
        <h2>Incentives</h2>
        <div>
          Custom incentive:
          <em>{{$ctrl.fixedIncentive.amount}} {{$ctrl.fixedIncentive.currency}} </em>
        </div>
      </div>
    </fieldset>
    <fieldset feature-on="fixedLoi">
      <div ng-hide="$ctrl.targetGroup.inFieldLengthOfInterviewOverride !== null" class="irrelevant push-down-more">
        No fixed LOI was set up
      </div>
      <div ng-show="$ctrl.targetGroup.inFieldLengthOfInterviewOverride !== null">
        <h2>Length of interview</h2>
        <label>Length of interview (LOI) override:</label>
        <em>{{$ctrl.targetGroup.inFieldLengthOfInterviewOverride | loiFormat}}</em>
      </div>
    </fieldset>
  </div>
`;

interface Bindings {
  targetGroup: '<' | ExistingTargetGroup;
  fixedIncentive: '<' | FixedIncentive;
}

const bindings: Bindings = {
  targetGroup: '<',
  fixedIncentive: '<',
};

export class TargetGroupSupplyComponent implements Bindings {
  static componentName = selector;
  targetGroup: ExistingTargetGroup;
  supplyGroupQuotas: ExistingTargetGroupQuota[];
  supplySource: SupplySource;
  panels: PanelNameWithTag[];
  panelsTotalAmount: number;
  fixedIncentive: FixedIncentive;

  searchString = '';
  filters: Filter[] = [{ title: 'Tags', available: [], selected: [] }];

  constructor(private $scope: IScope) {
    'ngInject';
  }

  onFilter = (changedFilters: FilterResult[]) => {
    this.filters = this.filters.map((t) => ({
      ...t,
      selected: changedFilters.find((a) => a.title === t.title).selected,
    }));

    this.reload();
    this.$scope.$apply();
  };

  onSearch = (search: string) => {
    this.searchString = search;
    this.reload();
    this.$scope.$apply();
  };

  $onChanges(changes: IOnChangesObject): void {
    if (changes.targetGroup) {
      this.targetGroup = changes.targetGroup.currentValue;
    }
    if (changes.fixedIncentive) {
      this.fixedIncentive = changes.fixedIncentive.currentValue;
    }

    this.reload();
  }

  getSupplySource(targetGroup: ExistingTargetGroup): SupplySource {
    if (targetGroup === undefined) return undefined;
    if (targetGroup?.adHocSupplier) return SupplySource.AdHoc;

    const supplyGroupQuotas = this.getSupplyGroupQuotas(targetGroup);
    if (supplyGroupQuotas.length > 0) {
      return SupplySource.SupplyMix;
    }

    const { supply } = targetGroup;
    if (hasInclusivePanelistPool(targetGroup)) {
      return SupplySource.PanelistPool;
    }

    if (!supply.selectedPanels || supply.selectedPanels.length === 0) {
      return SupplySource.SystemSelected;
    }

    return SupplySource.UnspecifiedPanels;
  }

  reload(): void {
    this.supplySource = this.getSupplySource(this.targetGroup);

    if (this.supplySource === SupplySource.SupplyMix) {
      this.supplyGroupQuotas = this.getSupplyGroupQuotas(this.targetGroup);
      return;
    }

    this.filters[0].available = uniq(flatMap(this.targetGroup.supply.selectedPanels, (a) => a.tags));

    if (this.supplySource === SupplySource.UnspecifiedPanels) {
      const tagFilters = this.filters.find((a) => a.title === 'Tags').selected;
      this.panelsTotalAmount = this.targetGroup.supply.selectedPanels.length;
      this.panels = this.targetGroup.supply.selectedPanels
        .filter((a) =>
          this.searchString ? a.name.toLocaleLowerCase().includes(this.searchString.toLowerCase()) : true
        )
        .filter((a) => (some(tagFilters) ? some(tagFilters, (f) => a.tags.includes(f)) : true))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  }

  private getSupplyGroupQuotas(targetGroup: ExistingTargetGroup): ExistingTargetGroupQuota[] {
    return targetGroup.quotas.quotas.filter((quota) => includes(quota.quotaGrouping, QuotaGrouping.Supply));
  }
}

export const ngTargetGroupSupplyComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TargetGroupSupplyComponent,
  },
};
