import { IDeferred, IPromise, IHttpPromise, IHttpResponse } from 'angular';
import { cloneDeep, fromPairs, isEmpty, reject } from 'lodash-es';
import hash from 'object-hash';
import { feasibilityResults } from './feasibility-results';
// import { targetGroupValidator } from './target-group-validator';
import { globalFeasibilityOptions } from '../common/global-feasibility-options';
import { TargetGroupConstants } from './target-group.constants';
import { ProjectSettings } from '../common/project-settings.service';
import { TargetGroupModel } from '../common/models/target-group.model';
import { CacheDuration, SupplySource, TargetGender, WeightingResult } from '../common/enums';
import { FeasibilityRequest } from '../common/http-services/feasibility-request.model';
import { targetGroupChannel } from './channels/target-group-channel';
import {
  FeasibilityResponse,
  Feasibility,
  InfieldFeasibilityResponse,
} from '../common/http-services/feasibility.response';
import { api } from '../common/api';
import { errorLogger } from '../common/error-logger';
import { $log, $q, debouncePromise } from '../ngimport';
import { Constants } from '../constants';
import { quotasSubactions } from './active-target-group/store/quotas/quotas.subactions';
import { activeTargetGroupStore } from './active-target-group/store/active-target-group.store';
import { createQuotasModelFromActive } from '../common/models/quotas.model';
import { isFeasibilityResponse } from '../helpers';
import { TargetGroupMapper } from '../common/target-group/target-group-mapper';
import { flattenQuotas } from './active-target-group/models/active-quotas.model';
import { UpfrontPrice } from './price-quote.response';
import { inMemoryCache } from '../common/in-memory-cache';

const feasibilityCachePrefix = 'feasibility';
const infieldFeasibilityCachePrefix = 'infieldFeasibility';

export function getActualFeasibility(feasibilityResponse: FeasibilityResponse): Feasibility {
  return feasibilityResponse.potentialFeasibility || feasibilityResponse.defaultFeasibility;
}

export class FeasibilityService {
  targetGroupFeasibilityIds: { [p: number]: number };
  cancelers: { [p: number]: IDeferred<unknown> };

  debouncedGetFeasibilityFunc: (
    tgm: TargetGroupModel,
    ps: ProjectSettings
  ) => IPromise<FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }>;

  constructor() {
    this.cancelers = {};
    this.targetGroupFeasibilityIds = {};
  }

  init(): void {
    this.debouncedGetFeasibilityFunc = debouncePromise(
      this.callAndLogFeasibility,
      TargetGroupConstants.feasibilityThrottleInterval
    );
  }

  debouncedGetFeasibility(
    targetGroup: TargetGroupModel,
    projectSettings: ProjectSettings
  ): IPromise<FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }> {
    return this.debouncedGetFeasibilityFunc(targetGroup, projectSettings);
  }

  getFeasibilityForTargetGroups(
    validTargetGroups: TargetGroupModel[],
    projectSettings: ProjectSettings,
    price: UpfrontPrice
  ): IPromise<FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }>[] {
    feasibilityResults.reset();

    const feasibilityPromises = [];
    for (const targetGroup of validTargetGroups) {
      const tgCpi = price.targetGroups.find((tg) => tg.id === targetGroup.id)?.cpi;
      const tgForFeasibilty = TargetGroupMapper.toTargetGroupForFeasibility(targetGroup, tgCpi);
      feasibilityPromises.push(
        this.callAndLogFeasibility(tgForFeasibilty, projectSettings).then((res) => {
          if (isFeasibilityResponse(res)) {
            targetGroupChannel.feasibility.done.dispatch({ targetGroup, feasibilityResponse: res });
          } else {
            targetGroupChannel.feasibility.error.dispatch({ targetGroupId: targetGroup.id, reason: res?.reason });
          }
          return res;
        })
      );
    }

    return feasibilityPromises;
  }

  incrementAndGetFeasibilityId(targetGroupId: number): number {
    let requestId = this.targetGroupFeasibilityIds[targetGroupId] || 0;
    requestId += 1;
    this.targetGroupFeasibilityIds[targetGroupId] = requestId;
    return requestId;
  }

  callAndLogFeasibility(
    targetGroup: TargetGroupModel,
    projectSettings: ProjectSettings
  ): IPromise<FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }> {
    if (targetGroup.panels.supplySource === SupplySource.AdHoc) {
      return this.getAdhocFeasibility(targetGroup).then((feasibility) => {
        this.updateFeasibilityResultList(targetGroup, feasibility);
        return feasibility;
      });
    }
    const requestId = this.incrementAndGetFeasibilityId(targetGroup.id);
    return this.callFeasibility(targetGroup, projectSettings)
      .then((res) => {
        if (this.feasibilityResponseIsOutdated(targetGroup.id, requestId)) {
          $log.warn('feasibility response is outdated');
          return undefined;
        }

        if (res.data.defaultFeasibility.noPanelsForSampling) {
          errorLogger.warningWithData('no panels for sampling!', [], {
            targetGroup,
            result: res.data,
          });
        }

        if (res.data.defaultFeasibility.weightingResult === WeightingResult.Failure) {
          errorLogger.warningWithData('nonconverging case (default feasibility)!', [], {
            targetGroup,
            result: res.data,
          });
        }

        if (
          res.data.potentialFeasibility &&
          res.data.potentialFeasibility.weightingResult === WeightingResult.Failure
        ) {
          errorLogger.warningWithData('nonconverging case (potential feasibility)!', [], {
            targetGroup,
            result: res.data,
          });
        }

        if (isEmpty(targetGroup.quotas.sectionsUsingPanelDistribution)) return res.data;

        return this.rescaleTargetGroupQuotaCompletesAndCallFeasibilityAgain(
          targetGroup,
          projectSettings,
          res.data
        ).then((response) => response.data);
      })
      .then((feasibility) => {
        this.updateFeasibilityResultList(targetGroup, feasibility);
        return feasibility;
      })
      .catch((res) => {
        // returns are a bit of a shit-show here
        if (this.requestWasCanceled(res)) return undefined;
        if (this.isError(res)) {
          $log.error(res);
          const reason = res.headers(Constants.httpTimeoutHeaderName) ? 'timeout' : 'error';
          return $q.resolve({ isInvalid: true, reason });
        }

        return undefined;
      });
  }

  async getInfieldFeasibility(
    projectId: number,
    targetGroupId: number
  ): Promise<IHttpResponse<InfieldFeasibilityResponse>> {
    return inMemoryCache.getOrAddAsync(
      `${infieldFeasibilityCachePrefix}_${[projectId]}_${targetGroupId}`,
      async () => api.feasibility.getInfieldFeasibility(projectId, targetGroupId),
      CacheDuration.Medium
    );
  }

  private getAdhocFeasibility(targetGroup: TargetGroupModel): IPromise<FeasibilityResponse> {
    return $q.resolve({
      defaultFeasibility: {
        targetGroupId: targetGroup.id,
        isFeasible: true,
        wantedNumberOfCompletes: targetGroup.basicSettings.numberOfCompletes,
        feasibleNumberOfCompletes: targetGroup.basicSettings.numberOfCompletes,
        suggestedNumberOfInvites: targetGroup.basicSettings.numberOfCompletes,
        userSelectedPanels: false,
        panelCompletes: [], // if using adhoc we have no panels
        quotas: targetGroup.quotas.quotas.map((q) => ({
          name: q.name,
          matchId: q.matchId,
          wanted: q.wantedCompletes,
          feasible: q.wantedCompletes,
          aiPushFeasibility: 0,
          pushFeasibility: 0,
          buckets: [...q.buckets],
          keys: [...q.keys],
        })),
        buckets: targetGroup.quotas.buckets.map((b) => ({
          id: b.id,
          bucketKey: {
            gender: b.key.gender === TargetGender.Female ? TargetGender.Female : TargetGender.Male,
            ageSpan: b.key.ageSpan,
            region: b.key.region,
            supply: { panels: [...b.key.supply.panels] },
            profiling: [...b.key.profiling],
          },
          feasible: null,
          aiPushFeasibility: null,
          pushFeasibility: 0,
          name: b.name,
          wantedCompletes: b.wantedCompletes,
          count: null,
        })),
        weightingResult: WeightingResult.Success,
        noPanelsForSampling: false,
        error: null,
      },
      potentialFeasibility: null,
      // TODO: rename to 'locked'
      hasAnyVisiblePanels: false,
    });
  }

  // BAIDU: quite hacky. The changes made to TargetGroupModel here (via ActiveTargetGroupStore) will later be saved
  //  down to TargetGroupRepository when ProjectService intercepts feasibility.done and processes the RIM weighting
  //  response on the buckets
  private rescaleTargetGroupQuotaCompletesAndCallFeasibilityAgain(
    targetGroup: TargetGroupModel,
    projectSettings: ProjectSettings,
    feasibilityResponse: FeasibilityResponse
  ) {
    let { model } = activeTargetGroupStore;
    const matchIds = fromPairs([...flattenQuotas(model.quotas)].map((x) => [x.hash, x.matchId]));

    const newState = quotasSubactions.rescaleCompletesAndStartsAccordingToPanelDistribution(
      model.quotas,
      targetGroup.quotas.sectionsUsingPanelDistribution,
      getActualFeasibility(feasibilityResponse),
      matchIds,
      model.basicSettings.numberOfCompletes,
      model.basicSettings.numberOfStarts
    );
    activeTargetGroupStore.commitFuncs.quotas(newState);
    activeTargetGroupStore.publishUpdate(true);

    model = activeTargetGroupStore.model;
    const { quotas, buckets } = activeTargetGroupStore.quotas.generatedBuckets;
    const { sectionsUsingPanelDistribution, sectionsUsingIgnoreCompletes } = activeTargetGroupStore.quotas;

    targetGroup.quotas = createQuotasModelFromActive(
      quotas,
      buckets,
      model.quotas.usePercentages,
      model.quotas.weightingStrategy,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes,
      model.quotas.quotaGroupNames
    );

    return this.callFeasibility(targetGroup, projectSettings);
  }

  private callFeasibility(
    targetGroup: TargetGroupModel,
    projectSettings: ProjectSettings
  ): IHttpPromise<FeasibilityResponse> {
    const request = new FeasibilityRequest(
      targetGroup.id,
      TargetGroupMapper.toTargetGroupForFeasibility(targetGroup, targetGroup.cpi), // AI Feasibility: Feels like this mapper is not needed here
      projectSettings,
      globalFeasibilityOptions
    );

    const requestForHash = cloneDeep(request);
    delete requestForHash.targetGroup.projectTemplateSource;

    const requestHash = hash(requestForHash, { algorithm: 'md5', encoding: 'base64' });

    return inMemoryCache.getOrAddAsync(
      `${feasibilityCachePrefix}_${requestHash}`,
      async () => {
        if (this.cancelers[targetGroup.id]) {
          this.cancelers[targetGroup.id].resolve();
        }
        this.cancelers[targetGroup.id] = $q.defer();

        return api.feasibility.getFeasibility(request, this.cancelers[targetGroup.id].promise);
      },
      CacheDuration.Medium
    );
  }

  private feasibilityResponseIsOutdated(targetGroupId: number, requestId: number): boolean {
    return this.targetGroupFeasibilityIds[targetGroupId] > requestId;
  }

  private updateFeasibilityResultList(requestedTg: TargetGroupModel, feasibilityResponse: FeasibilityResponse): void {
    let feasibilityList = feasibilityResults.getFeasibilities();
    feasibilityList = reject(feasibilityList, (feasibilityForTg) => feasibilityForTg.targetGroupId === requestedTg.id);
    feasibilityList.push({
      targetGroupId: requestedTg.id,
      definition: requestedTg,
      feasibility: feasibilityResponse,
    });
    feasibilityResults.setFeasibilities(feasibilityList);
  }

  private requestWasCanceled<T>(res: IHttpResponse<T>) {
    return res.status < 1;
  }

  private isError(res: IHttpResponse<unknown>): res is IHttpResponse<''> {
    return res.status === 400 || res.status === 500;
  }
}

export const feasibilityService = new FeasibilityService();
