import './immer-patches-devtools.component.scss';
import { Patch } from 'immer';
import { ListenerComponent } from '../common/channels/channel';
import { immerChannel } from '../target-groups/channels/immer-channel';
import { html } from '../helpers';

const selector = 'immerPatchesDevtoolsComponent';

const template = html`
  <div
    class="immer-patches-devtools-component"
    ng-class="{ 'collapsed': $ctrl.collapsed, 'half-screen': $ctrl.halfScreen }"
  >
    <div class="sticky-controls">
      <i
        class="clickable-icon"
        ng-click="$ctrl.toggleCollapsed()"
        ng-class="{ 'fa fa-chevron-right': !$ctrl.collapsed, 'fa fa-chevron-left': $ctrl.collapsed }"
      ></i>
      <i
        class="clickable-icon pull-right"
        ng-click="$ctrl.toggleHalfScreen()"
        ng-class="{ 'fa fa-expand': !$ctrl.halfScreen, 'fa fa-compress': $ctrl.halfScreen }"
      ></i>
      <i class="clickable-icon fa fa-ban pull-right" ng-click="$ctrl.clear()"></i>
      <span class="inverse-label">
        Inverse
        <on-off-switch-component
          class="inverse-switch"
          checked="$ctrl.inverse"
          switch-id="inverse-patches-on-off-switch"
          switch-type="onoff"
          on-toggle="$ctrl.inverseOptionChanged()"
        >
        </on-off-switch-component>
      </span>
    </div>
    <div class="patches-container">
      <immer-patches-item-component ng-hide="$ctrl.inverse" patches="$ctrl.patches"></immer-patches-item-component>
      <immer-patches-item-component
        ng-show="$ctrl.inverse"
        patches="$ctrl.inversePatches"
      ></immer-patches-item-component>
    </div>
  </div>
`;

export class ImmerPatchesDevtoolsComponent extends ListenerComponent {
  static componentName = selector;
  collapsed: boolean;
  halfScreen: boolean;
  inverse: boolean;
  patches: Patch[][];
  inversePatches: Patch[][];

  $onInit(): void {
    immerChannel.updated.listen(this.whenImmerPatchesUpdated, this);
    this.patches = [];
    this.inversePatches = [];
  }

  whenImmerPatchesUpdated(data: { patches: Patch[]; inversePatches: Patch[] }): void {
    this.patches.push(data.patches);
    this.inversePatches.push(data.inversePatches);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
    if (this.collapsed) this.halfScreen = false;
  }

  toggleHalfScreen(): void {
    this.halfScreen = !this.halfScreen;
  }

  clear(): void {
    this.patches = [];
    this.inversePatches = [];
  }

  getPathString(path: string[]): string {
    return path.join(' -> ');
  }

  inverseOptionChanged(): void {
    this.inverse = !this.inverse;
  }
}

export const ngImmerPatchesDevtoolsComponent = {
  [selector]: {
    template,
    controller: ImmerPatchesDevtoolsComponent,
  },
};
