import { IScope, IAugmentedJQuery, IAttributes, IDirective } from 'angular';
import { inlineHelpService, InlineHelpService } from './inline-help.service';
import { $animate } from '../../ngimport';

type DirectiveScope = IScope & { inlineHelpService?: InlineHelpService };

const directiveName = 'inlineHelp';

export function inlineHelpDirective(): IDirective {
  return {
    link: (scope: DirectiveScope, element: IAugmentedJQuery, attrs: IAttributes) => {
      scope.inlineHelpService = inlineHelpService;

      scope.$watch(
        () => scope.inlineHelpService.visibleFor,
        (visibleFor) => {
          const value = visibleFor ? visibleFor[attrs[directiveName]] : false;
          $animate[value ? 'removeClass' : 'addClass'](element, 'ng-hide'); // exactly like ng-show works
        },
        true
      );
    },
  };
}

export const ngInlineHelpDirective = {
  [directiveName]: inlineHelpDirective,
};
