import { isEmpty, map, compact, cloneDeep, findIndex } from 'lodash-es';
import { IHttpPromise } from 'angular';
import { userService } from '../common/user.service';
import { api } from '../common/api';
import { db } from '../common/db';
import { session } from '../common/session';
import { targetGroupChannel } from './channels/target-group-channel';
import { UpfrontPriceRequest, UpfrontTargetGroupPriceRequest } from './price-quote.request';
import { UpfrontPrice } from './price-quote.response';
import { TargetGroupsStateIntent } from '../target-groups-state-intent';
import { $q, $log, $stateParams } from '../ngimport';
import { TargetGroupModel } from '../common/models/target-group.model';
import { UpfrontPriceResponse } from '../common/http-services/pricing.httpservice';
import { projectSettingsService } from '../common/project-settings.service';
import { targetGroupValidator } from './target-group-validator';
import { TargetGroupMapper } from '../common/target-group/target-group-mapper';

export class CustomCpiSetting {
  targetGroupId: number;
  customCpi: number;
}
export class PriceQuoteService {
  async getPriceQuoteForAllValidTargetGroups(targetGroups: TargetGroupModel[]): Promise<UpfrontPrice | null> {
    const validTargetGroups = [];
    for (const targetGroup of targetGroups) {
      const validationResult = targetGroupValidator.validate(targetGroup);
      if (validationResult.hasError()) {
        targetGroupChannel.feasibility.invalid.dispatch({ targetGroup, validationResult });
        continue;
      }
      validTargetGroups.push(targetGroup);
    }
    const validTargetGroupsForPricing = validTargetGroups.map((tg) => TargetGroupMapper.toTargetGroupForPricing(tg));
    const request = this.createUpfrontPriceRequest(validTargetGroupsForPricing);
    if (!isEmpty(request.items)) {
      if ($stateParams.intent === TargetGroupsStateIntent.AddTargetGroups) {
        return db.existingProject.get(session.uuid).then((res) => {
          request.existingProjectId = res.project.id;
          return this.upfrontPrice(request);
        });
      }
      return this.upfrontPrice(request);
    }
    $q.when().then(() => {
      targetGroupChannel.pricing.cancel.dispatch();
    });
    return null;
  }

  async getPriceQuoteForSupplyState(
    persistedTargetGroups: TargetGroupModel[],
    updatedTargetGroup: TargetGroupModel
  ): Promise<UpfrontPrice | null> {
    const updatedTargetGroups = cloneDeep(persistedTargetGroups);
    updatedTargetGroups.splice(
      findIndex(updatedTargetGroups, (tg) => tg.id === updatedTargetGroup.id),
      1,
      updatedTargetGroup
    );
    const validTargetGroups = [];
    for (const targetGroup of updatedTargetGroups) {
      const validationResult = targetGroupValidator.validate(targetGroup);
      if (validationResult.hasError()) {
        targetGroupChannel.audienceFeasibility.invalid.dispatch({ targetGroup, validationResult });
        continue;
      }
      validTargetGroups.push(targetGroup);
    }
    const request = this.createUpfrontPriceRequest(validTargetGroups);
    if (!isEmpty(request.items)) {
      if ($stateParams.intent === TargetGroupsStateIntent.AddTargetGroups) {
        return db.existingProject.get(session.uuid).then((res) => {
          request.existingProjectId = res.project.id;
          return this.upfrontPriceForSupplyState(request);
        });
      }
      return this.upfrontPriceForSupplyState(request);
    }

    return null;
  }

  getPriceQuoteForCustomCpi(targetGroup: TargetGroupModel): IHttpPromise<UpfrontPriceResponse> {
    const request = new UpfrontTargetGroupPriceRequest(targetGroup);
    const { filterOnPii, requireWebcam, categoryIds } = projectSettingsService.settings;
    const displayCurrency = projectSettingsService.settings.displayCurrency || userService.displayCurrency;
    const priceRequest: UpfrontPriceRequest = {
      existingProjectId: null,
      items: [request],
      filterOnPii,
      requireWebcam,
      categoryIds,
      displayCurrency,
    };

    return api.pricing.getUpfrontPrice(priceRequest);
  }

  private async upfrontPrice(request: UpfrontPriceRequest): Promise<UpfrontPrice | null> {
    targetGroupChannel.pricing.start.dispatch([]);
    return api.pricing.getUpfrontPrice(request).then(
      (res) => {
        const price = new UpfrontPrice(res.data);
        targetGroupChannel.pricing.done.dispatch(price);
        return price;
      },
      (error) => {
        $log.error(error);
        targetGroupChannel.pricing.error.dispatch();
        return null;
      }
    );
  }

  private async upfrontPriceForSupplyState(request: UpfrontPriceRequest): Promise<UpfrontPrice | null> {
    try {
      return new UpfrontPrice((await api.pricing.getUpfrontPrice(request)).data);
    } catch (error) {
      $log.error(error);
      return null;
    }
  }

  private createUpfrontPriceRequest(targetGroups: TargetGroupModel[]): UpfrontPriceRequest {
    const filteredRequests = map(targetGroups, (tg) => {
      return new UpfrontTargetGroupPriceRequest(tg);
    });
    const { categoryIds, filterOnPii, requireWebcam } = projectSettingsService.settings;
    const displayCurrency = projectSettingsService.settings.displayCurrency || userService.displayCurrency;
    return {
      existingProjectId: null,
      items: compact(filteredRequests),
      categoryIds,
      filterOnPii,
      requireWebcam,
      displayCurrency,
    };
  }
}

export const priceQuoteService = new PriceQuoteService();
