import './DateSpanInput.scss';
import dayjs from 'dayjs';
import { todayISOString } from '../../helpers';

type Props = {
  fromDate: string;
  toDate: string;
  onDateChange: (from: string, to: string) => void;
  onClear: () => void;
};

const getMaxFromDateISOString = (toISOString: string) => {
  if (toISOString == null) return todayISOString;
  if (dayjs(todayISOString) > dayjs(toISOString)) {
    return toISOString;
  }
  return todayISOString;
};

export const DateSpanInput: React.FC<Props> = ({ fromDate, toDate, onDateChange, onClear }) => {
  const fromChanged = (from: string) => {
    onDateChange(from, toDate);
  };

  const toChanged = (to: string) => {
    onDateChange(fromDate, to);
  };

  return (
    <div className="date-span-input">
      <div className="date-inputs">
        <div className="control">
          <label htmlFor="from-date-input">From</label>
          <input
            id="from-date-input"
            className="ui-control"
            type="date"
            max={getMaxFromDateISOString(toDate)}
            onChange={(e) => fromChanged(e.target.value)}
            value={fromDate}
          />
        </div>

        <div className="control">
          <label htmlFor="to-date-input">To</label>
          <input
            id="to-date-input"
            className="ui-control"
            type="date"
            min={fromDate}
            max={todayISOString}
            onChange={(e) => toChanged(e.target.value)}
            value={toDate}
          />
        </div>
      </div>
      {(fromDate || toDate) && (
        <span role="button" className="clear-link" onClick={onClear} title="Clear dates">
          Clear
        </span>
      )}
    </div>
  );
};
