import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { last } from 'lodash-es';
import { filters } from '../../../common/filters';
import {
  panelistPoolSearchHttpService,
  PanelistPoolSearchResponse,
} from '../../../common/http-services/panelist-pool-search.httpService';
import { useDebounce } from '../../../helpers';

import './PanelistPoolSearch.scss';
import { SearchInput } from '../../../common/controls/search-input/SearchInput';

type PanelistPoolHit = {
  id: string;
  name: string;
  created: string;
  used: boolean;
};

type Props = {
  onSave: (poolId: string) => void;
};

const pageSize = 10;

export const PanelistPoolSearch: React.FC<Props> = ({ onSave }) => {
  const [panelistPools, setPanelistPools] = useState<PanelistPoolHit[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const [paginationTokens, setPaginationTokens] = useState<string[]>([]);
  const [totalHits, setTotalHits] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPanelistPool, setSelectedPanelistPool] = useState('');
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<[string, PanelistPoolSearchResponse]>();
  const currentSearchToken = useRef('');

  useEffect(() => {
    setIsFetching(true);
    setIsFetchingPage(true);
    panelistPoolSearchHttpService.getPanelistPools('').then(({ data }) => {
      setIsFetching(false);
      setIsFetchingPage(false);
      setPaginationTokens([data.paginationToken]);
      setPanelistPools(data.hits);
      setTotalHits(data.totalHitCount);
      setCurrentPage(1);
    });
  }, []);

  const loadNextPage = () => {
    setIsFetching(true);
    setIsFetchingPage(true);
    panelistPoolSearchHttpService.getPanelistPools(last(paginationTokens), searchString).then(({ data }) => {
      setIsFetching(false);
      setIsFetchingPage(false);
      setPaginationTokens((prevTokens) => [...prevTokens, data.paginationToken]);
      setPanelistPools(data.hits);
      setTotalHits(data.totalHitCount);
      setCurrentPage((prevPage) => prevPage + 1);
    });
  };

  const loadPreviousPage = () => {
    setIsFetching(true);
    setIsFetchingPage(true);
    panelistPoolSearchHttpService
      .getPanelistPools(paginationTokens[paginationTokens.length - 3], searchString)
      .then(({ data }) => {
        setIsFetching(false);
        setIsFetchingPage(false);
        setPaginationTokens((prevTokens) => [...prevTokens.slice(0, prevTokens.length - 2), data.paginationToken]);
        setPanelistPools(data.hits);
        setTotalHits(data.totalHitCount);
        setCurrentPage((prevPage) => prevPage - 1);
      });
  };

  const toggleSelection = (id: string, _used: boolean) => {
    // TODO: let's leave the below like this for testing, it will make it MUCH easier.
    // We just have to remember to set it back before release to prod...
    // if (used) return;
    setSelectedPanelistPool((prevId) => (prevId === id ? '' : id));
  };

  const debouncedSearch = useDebounce(
    (searchString: string) => {
      if (searchString.length === 1 || searchString.length === 2) return;

      setIsFetching(true);
      setIsFetchingPage(false);

      currentSearchToken.current = searchString;

      panelistPoolSearchHttpService.getPanelistPools('', searchString).then(({ data }) => {
        setSearchResults([searchString, data]);
      });
    },
    [],
    300
  );

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    debouncedSearch(searchString);
  };

  useEffect(() => {
    if (!searchResults) return;

    const [token, results] = searchResults;
    if (currentSearchToken.current === token) {
      setIsFetching(false);
      setIsFetchingPage(false);
      setPaginationTokens([results.paginationToken]);
      setPanelistPools(results.hits);
      setTotalHits(results.totalHitCount);
      setCurrentPage(1);
      setSearchString(token);
    }
  }, [searchResults]);

  return (
    <div className="panelist-pool-search">
      <SearchInput
        searchString={searchString}
        placeholder="Search for pool ID or pool name"
        onSearch={handleSearch}
        disabled={isFetchingPage}
      />
      {isFetching && (
        <div className="spinner-container text-center">
          <i className="fas fa-spinner fa-spin" aria-hidden="true" />
        </div>
      )}
      {!isFetching && totalHits > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="column-heading">ID</th>
              <th className="column-heading">Name</th>
              <th className="column-heading">Created</th>
            </tr>
          </thead>
          <tbody>
            {panelistPools.map((pool) => (
              <tr
                key={pool.id}
                onClick={() => {
                  toggleSelection(pool.id, pool.used);
                }}
                className={classNames({ 'selected-pool': selectedPanelistPool === pool.id, unavailable: pool.used })}
                title={
                  pool.used
                    ? 'This pool has already been used in another Target Group and is therefore not available for selection'
                    : undefined
                }
              >
                <td>{pool.id}</td>
                <td>{pool.name}</td>
                <td>{filters.dateFormat()(pool.created)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isFetching && totalHits < 1 && <div className="no-results text-center">No results.</div>}
      <div className="text-center">
        <ul className="pagination pagination-sm">
          <li className="pagination-prev">
            <a onClick={loadPreviousPage} className={isFetching || currentPage === 1 ? 'disabled' : undefined}>
              {'< Back'}
            </a>
          </li>
          <li className="pagination-page">
            <span>
              {isFetching ? '-' : currentPage} of {isFetching ? '-' : Math.ceil(totalHits / pageSize)}
            </span>
          </li>
          <li className="pagination-next">
            <a
              onClick={loadNextPage}
              className={isFetching || currentPage * pageSize >= totalHits ? 'disabled' : undefined}
            >
              {'Next >'}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <button
          type="button"
          className="ui-btn primary-btn pull-right"
          onClick={() => {
            onSave(selectedPanelistPool);
          }}
          disabled={!selectedPanelistPool}
        >
          Use selected panelist pool
        </button>
      </div>
    </div>
  );
};
