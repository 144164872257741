import React from 'react';
import { compact, sortBy, last } from 'lodash-es';
import { SamplingItemStatus, SamplingItem } from './monitor.component';
import { pluralize } from '../../common/filters';

export enum SamplingStatusSeverity {
  Success = 'success',
  Pending = 'default',
  Warning = 'warning',
  Error = 'error',
  Fatal = 'fatal',
}

export const severityFromStatusMap: { [status in SamplingItemStatus]: SamplingStatusSeverity } = {
  success: SamplingStatusSeverity.Success,

  waiting: SamplingStatusSeverity.Pending,
  processing: SamplingStatusSeverity.Pending,

  successWithRespondentShortage: SamplingStatusSeverity.Warning, // N/B!

  processingFailed: SamplingStatusSeverity.Error,
  insufficientLinks: SamplingStatusSeverity.Error,
  failedToAssignLinks: SamplingStatusSeverity.Error,

  successWithLinkShortage: SamplingStatusSeverity.Fatal, // N/B!

  removed: undefined,
};

export const priorityFromStatusMap: { [status in SamplingItemStatus]: number } = {
  successWithLinkShortage: 8,
  failedToAssignLinks: 7,
  insufficientLinks: 6,
  processingFailed: 5,
  successWithRespondentShortage: 4,
  processing: 3,
  waiting: 2,
  success: 1,
  removed: 0,
};

export const headerFromStatusMap: { [status in SamplingItemStatus]: string } = {
  success: 'Sampling complete',

  waiting: 'Waiting for sampling',
  processing: 'Preparing for sampling',

  successWithRespondentShortage: 'Limited direct invites',

  processingFailed: 'Sampling error',
  failedToAssignLinks: 'Sampling error',
  insufficientLinks: 'Link shortage',
  successWithLinkShortage: 'Link shortage',
  removed: undefined,
};

export const messageFromStatusMap = (
  status: SamplingItemStatus,
  estimated: number,
  wanted: number
): string | React.ReactElement => {
  switch (status) {
    case 'success':
      return `Invites have been sent to achieve circa ${pluralize(estimated, 'complete')}.`;
    case 'waiting':
      return 'Survey invites are waiting to be processed.';
    case 'processing':
      return 'Booking the respondents to invite.';
    case 'successWithRespondentShortage':
      return (
        <>
          <div>
            <a
              href="https://cintaccess.zendesk.com/hc/en-us/articles/360039239812-How-to-sample-in-Access-Pro"
              target="_blank"
              rel="noopener noreferrer"
              track-kb-link="monitor/respondent-shortage"
            >
              Click here to learn what to do about limited direct invites.
            </a>
          </div>
          Invites will be sent out to accomplish {estimated}/{pluralize(wanted, 'complete')}.
        </>
      );
    case 'processingFailed':
      return 'Failed to process sample. Try again later or contact support if the problem persists.';
    case 'failedToAssignLinks':
      return 'Failed to assign links. Try again later or contact support if the problem persists.';
    case 'insufficientLinks':
      return <div style={{ color: '#d65951' }}>Please upload more unique survey links to the target group.</div>;
    case 'successWithLinkShortage':
      return (
        <>
          <div style={{ color: '#d65951' }}>Please upload more unique survey links to the target group.</div>
          The latest sample is estimated to collect {estimated} out of {pluralize(wanted, 'complete')}.
        </>
      );
    default:
      return '';
  }
};

export const defaultSamplingItem: SamplingItem = {
  id: -1,
  status: undefined,
  quantity: 0,
  estimatedCompletes: 0,
  estimatedStarts: 0,
};

export function determineDominantSamplingItemStatus(samplingItems: SamplingItem[]): SamplingItem {
  return last(sortBy(compact(samplingItems), (item) => priorityFromStatusMap[item.status]));
}
