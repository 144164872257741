import { IPromise } from 'angular';
import { api } from '../../common/api';
import { debouncePromise } from '../../ngimport';
import { ProjectUserFilter, ProjectChannel, ProjectStatus, SortField, DeviationFilter } from '../../common/enums';
import { TargetGroupConstants } from '../../target-groups/target-group.constants';
import { ProjectSearchResponse } from '../../common/http-services/project-search.httpService';

export class ProjectSearchService {
  throttledSearch: (
    channels: ProjectChannel[],
    scope: ProjectUserFilter,
    projectStatuses: ProjectStatus[],
    countryIds: number[],
    deviationFilters: DeviationFilter[],
    projectGroups: string[],
    searchString: string,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ) => IPromise<ProjectSearchResponse>;

  init(): void {
    this.throttledSearch = debouncePromise(this.search, TargetGroupConstants.searchThrottleInterval);
  }

  private search(
    channels: ProjectChannel[],
    scope: ProjectUserFilter,
    projectStatuses: ProjectStatus[],
    countryIds: number[],
    deviationFilters: DeviationFilter[],
    projectGroups: string[],
    searchString: string,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IPromise<ProjectSearchResponse> {
    searchString = encodeURIComponent(searchString?.toLowerCase());
    return api.search
      .searchProjects(
        channels,
        scope,
        projectStatuses,
        countryIds,
        deviationFilters,
        projectGroups,
        searchString,
        descending,
        sortField,
        paginationToken
      )
      .then((response) => {
        return response.data;
      });
  }
}

export const projectSearchService = new ProjectSearchService();
