import { flatMap, isEmpty } from 'lodash-es';
import { IPromise } from 'angular';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { ActiveBasicSettingsModel } from '../../models/active-basic-settings.model';
import { ActiveSupplyModel } from '../../models/active-supply.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import {
  BasicSettingsValidationResult,
  basicSettingsValidator,
} from '../../../basic-settings/basic-settings-validator';
import { countryRestrictionsDisabledService } from '../../../../common/country-restrictions-disabled.service';
import { basicSettingsSubactions } from './basic-settings.subactions';
import { censusSubactions } from '../census/census.subactions';
import { supplySubactions } from '../supply/supply.subactions';
import { Iso8601Date } from '../../../../helpers';
import { $q } from '../../../../ngimport';
import { SupplySource, TargetGender } from '../../../../common/enums';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { activeTargetGroupSubactions } from '../../active-target-group.subactions';
import { quotasSubactions } from '../quotas/quotas.subactions';
import { countryService } from '../../../country.service';
import { stateDebugService } from '../../../../common/state-debug.service';
import { profilingSubactions } from '../profiling/profiling.subactions';
import { getQuotaSections } from '../../../quotas/quotas-helper.functions';

type BasicSettingsUpdateFunc = (state: ActiveTargetGroupModel, commit: CommitFuncs, done: () => void) => void;

export const commitBasicSettings =
  (overrideIrAndLoiRestrictionsFunc: () => boolean) =>
  (basicSettingsState: ActiveBasicSettingsModel): BasicSettingsValidationResult => {
    const newState = basicSettingsSubactions.validateBasicSettings(
      basicSettingsState,
      overrideIrAndLoiRestrictionsFunc
    );
    activeTargetGroupStore.model.basicSettings = newState;
    targetGroupChannel.model.basicSettings.updated.dispatch();
    return newState.validationResult;
  };

export interface AudienceSettings {
  ir: number | undefined;
  loi: number | undefined;
  useFixedLoi: boolean | undefined;
  fixedIncentive: number | undefined;
  supplySource: SupplySource;
  selectedIds: number[];
}

export class BasicSettingsActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  setCountry(country: { id: number; name: string }) {
    this.update((state, commit, done) => {
      const overrideIrAndLoiRestrictions = countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(
        this.rootStore.model.supply
      );

      const restrictions = countryService.getRestrictions(country.id, overrideIrAndLoiRestrictions);

      const newState = basicSettingsSubactions.countryChanged(state.basicSettings, country, restrictions);
      const validation = commit.basicSettings(newState);
      if (validation.countryId.isValid) {
        targetGroupChannel.model.basicSettings.countryChanging.dispatch();
        const profilingState = profilingSubactions.clearSearchResults(state.profiling);
        commit.profiling(profilingState);
        targetGroupChannel.model.profiling.searchResultsUpdated.dispatch();
      }
      this.countryChangedAsync(state, commit, done);
    });
  }

  setNumberOfCompletes(numberOfCompletes: number) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.completesChanged(state.basicSettings, numberOfCompletes);

      const validation = commit.basicSettings(newState);
      if (!validation.numberOfCompletes.isValid) {
        done();
        return;
      }

      if (activeTargetGroupStore.quotas.hasQuotas()) {
        const newQuotasState = quotasSubactions.rescaleQuotaCompletes(
          state.quotas,
          state.basicSettings.numberOfCompletes,
          state.regions?.selectedIds.map((id) => [id])
        );
        commit.quotas(newQuotasState);
      }

      const hasSupplyGroups = !isEmpty(state.supply.supplyMix.supplyGroups);
      const shouldIncludeLockedPanels =
        state.supply.supplySource === SupplySource.SystemSelected && state.supply.includeLockedPanels;

      let newSupplyState = state.supply;
      if (hasSupplyGroups) {
        newSupplyState = supplySubactions.rescaleSupplyGroupWantedCompletes(newSupplyState, numberOfCompletes);
      }
      if (shouldIncludeLockedPanels) {
        newSupplyState = supplySubactions.setIncludeLockedPanelsFromFeasibility(
          newSupplyState,
          state.identity.id,
          newState.numberOfCompletes
        );
      }
      if (hasSupplyGroups || shouldIncludeLockedPanels) {
        commit.supply(newSupplyState);
      }

      done();
    });
  }

  setUseStarts(useStarts: boolean) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.useStartsChanged(state.basicSettings, useStarts);

      const validation = commit.basicSettings(newState);
      if (!validation.numberOfStarts.isValid) {
        done();
        return;
      }

      const hasQuotas = activeTargetGroupStore.quotas.hasQuotas();
      if (!useStarts && hasQuotas) {
        const quotasState = quotasSubactions.clearAllIgnoreCompletes(state.quotas);
        commit.quotas(quotasState);

        const hasSupplyGroups = !isEmpty(state.supply.supplyMix.supplyGroups);
        if (hasSupplyGroups) {
          const supplyState = supplySubactions.setIgnoreCompletesForSupplyMix(state.supply, false);
          commit.supply(supplyState);
        }
      }

      done();
    });
  }

  setNumberOfStarts(numberOfStarts: number) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.startsChanged(state.basicSettings, numberOfStarts);

      const validation = commit.basicSettings(newState);
      if (!validation.numberOfStarts.isValid) {
        done();
        return;
      }

      if (activeTargetGroupStore.quotas.hasQuotas()) {
        const quotas = flatMap(getQuotaSections(state.quotas), (s) => s.items);
        let newQuotasState = state.quotas;

        if (quotas.every((q) => q.starts === undefined)) {
          newQuotasState = quotasSubactions.initQuotaStarts(state.quotas);
        }

        newQuotasState = quotasSubactions.rescaleQuotaStarts(
          newQuotasState,
          state.basicSettings.numberOfStarts,
          state.regions?.selectedIds.map((id) => [id])
        );
        commit.quotas(newQuotasState);
      }

      const hasSupplyGroups = !isEmpty(state.supply.supplyMix.supplyGroups);

      let newSupplyState = state.supply;
      if (hasSupplyGroups) {
        newSupplyState = supplySubactions.rescaleSupplyGroupWantedStarts(newSupplyState, numberOfStarts);
        commit.supply(newSupplyState);
      }

      done();
    });
  }

  setGender(gender: TargetGender) {
    this.update((state, commit, done) => {
      const newBasicSettingsState = basicSettingsSubactions.genderChanged(state.basicSettings, gender);
      commit.basicSettings(newBasicSettingsState);
      this.rootStore.handleRegionsIfInterlockedWith('gender');
      let newQuotasState = quotasSubactions.clearGenderQuotas(state.quotas);
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'gender');
      commit.quotas(newQuotasState);
      done();
    });
  }

  setAgeSpan(ageSpan: { minAge: number; maxAge: number }) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.ageChanged(state.basicSettings, ageSpan);
      const validation = commit.basicSettings(newState);

      if (validation.minAge.isValid && validation.maxAge.isValid) {
        censusSubactions
          .createCensusPromise(
            state.census,
            state.basicSettings.countryId,
            state.basicSettings.minAge,
            state.basicSettings.maxAge
          )
          .then((newCensusState) => {
            commit.census(newCensusState);
            this.rootStore.handleRegionsIfInterlockedWith('ageSpan');
            let newQuotasState = quotasSubactions.clearAgeQuotas(state.quotas);
            newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'ageSpan');
            commit.quotas(newQuotasState);
            done();
          });
      } else {
        done();
      }
    });
  }

  setNumberOfDaysInField(numberOfDays: number) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.numberOfDaysInFieldChanged(state.basicSettings, numberOfDays);
      commit.basicSettings(newState);
      done();
    });
  }

  setIr(ir: number) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.irChanged(state.basicSettings, ir);
      commit.basicSettings(newState);
      this.updateLoiOrIrAsync(state.supply).then((newSupplyState) => {
        commit.supply(newSupplyState);
        commit.basicSettings(newState); // need to perform new bs validation after supply commit
        done();
      });
    });
  }

  setLoi(loi: number) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.loiChanged(state.basicSettings, loi);
      commit.basicSettings(newState);
      this.updateLoiOrIrAsync(state.supply).then((newSupplyState) => {
        commit.supply(newSupplyState);
        commit.basicSettings(newState); // need to perform new bs validation after supply commit
        done();
      });
    });
  }

  setStartDate(startDate: Iso8601Date) {
    this.update((state, commit, done) => {
      const newState = basicSettingsSubactions.startDateChanged(state.basicSettings, startDate);
      commit.basicSettings(newState);
      done();
    });
  }

  setUseFixedLoi(useFixedLoi: boolean) {
    this.update((state, commit, done) => {
      const newBasicSettingsState = basicSettingsSubactions.setUseFixedLoiChanged(state.basicSettings, useFixedLoi);
      const validation = commit.basicSettings(newBasicSettingsState);
      if (useFixedLoi && state.supply.supplySource !== SupplySource.OwnPanels && this.isValidForFixedLoi(validation)) {
        const newSupplyState = supplySubactions.setSupplySource(state.supply, SupplySource.OwnPanels);
        commit.supply(newSupplyState);
      }
      done();
    });
  }

  isValid(state: ActiveBasicSettingsModel, bsValidationResult?: BasicSettingsValidationResult): boolean {
    bsValidationResult = bsValidationResult || state.validationResult;
    return basicSettingsValidator.isValid(bsValidationResult, state.useStarts);
  }

  private countryChangedAsync(state: ActiveTargetGroupModel, commit: CommitFuncs, done: () => void) {
    const newState = activeTargetGroupSubactions.partialReset(state);
    commit.excludeProjects(newState.excludeProjects);
    commit.incentives(newState.incentives);
    return ActiveTargetGroupStore.httpCallsForCountry(newState, commit).then(
      () => {
        this.rootStore.managePanes();
        const newQuotasState = quotasSubactions.removeAllQuotas(newState.quotas, []);
        commit.quotas(newQuotasState);
        done();
      },
      (errors) => {
        console.log(errors);
      }
    );
  }

  private update(updateFunc: BasicSettingsUpdateFunc): void {
    stateDebugService.logIfUnsafeUpdate('basic settings');
    this.rootStore.signalUpdateStarted();

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, () => this.managePanesAndPublishUpdate());
  }

  private isValidForFixedLoi(validation: BasicSettingsValidationResult): boolean {
    return (
      validation.countryId.isValid &&
      validation.estimatedIncidenceRate.isValid &&
      validation.estimatedLengthOfInterview.isValid
    );
  }

  private updateLoiOrIrAsync(state: ActiveSupplyModel): IPromise<ActiveSupplyModel> {
    if (!this.isValid(this.rootStore.model.basicSettings)) {
      return $q.when(state);
    }

    targetGroupChannel.model.basicSettings.loiOrIrChanging.dispatch();
    return supplySubactions.createSupplySourcesPromise(state, this.rootStore.model.basicSettings);
  }

  private managePanesAndPublishUpdate() {
    this.rootStore.managePanes();
    this.rootStore.publishUpdate();
  }
}
