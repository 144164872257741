import { ui } from 'angular';
import { AddProjectGroupDialogComponentName } from './AddProjectGroupDialog';

export class AddProjectGroupDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'select-project-groups-dialog',
      component: AddProjectGroupDialogComponentName,
      resolve: {},
    };
  }
}
