import { logProduce } from '../../../../common/immer-wrapper';
import { SurveyMetadataModel } from '../../../../common/models/survey-metadata.model';
import { mutators } from './survey-metadata.mutators';

export class SurveyMetadataSubactions {
  setSurveyTags(state: SurveyMetadataModel, surveyTags: string[]): SurveyMetadataModel {
    return logProduce(state, mutators.setSurveyTags(surveyTags));
  }

  clearSurveyTags(state: SurveyMetadataModel): SurveyMetadataModel {
    return logProduce(state, mutators.clearSurveyTags());
  }
}

export const surveyMetadataSubactions = new SurveyMetadataSubactions();
