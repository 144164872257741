import './ChangeLogFilters.scss';
import { useState } from 'react';
import { isEmpty, uniq } from 'lodash-es';
import dayjs from 'dayjs';
import { DateSpanInput } from '../controls/DateSpanInput';
import { Filter, FilterResult } from '../controls/filter/filter';
import { SearchAndFilter } from '../controls/filter/SearchAndFilter';
import { todayISOString } from '../../helpers';
import { ProjectChangeEvent, ProjectChangeEventResponse } from '../http-services/project.httpservice';
import { convertEventTypeToText } from './project-change-log-helper';

type Props = {
  changeLog: ProjectChangeEventResponse;
  onHandleChanges: (result: ProjectChangeEventResponse) => void;
};

type PopoverFilters = {
  userFilter: Filter;
  subjectFilter: Filter;
};

enum Subjects {
  Project = 'Project',
  TargetGroup = 'Target group',
}

const availableSubjectFilters = [Subjects.Project, Subjects.TargetGroup];
const userFilterTitle = 'User';
const subjectFilterTitle = 'Subject';

const getInitialPopoverFilters = (changeLog: ProjectChangeEventResponse): PopoverFilters => ({
  userFilter: { title: userFilterTitle, available: uniq(changeLog.map((i) => i.userName)), selected: [] },
  subjectFilter: { title: subjectFilterTitle, available: availableSubjectFilters, selected: [] },
});

const filterUser = (i: ProjectChangeEvent, selectedUsers: string[]) =>
  isEmpty(selectedUsers) || selectedUsers.includes(i.userName);

const filterDate = (i: ProjectChangeEvent, from: string, to: string) => {
  const occurred = dayjs(i.occuredAt);
  const fromDateMatch = isEmpty(from) || dayjs(from).startOf('day') <= occurred;
  const toDateMatch = isEmpty(to) || dayjs(to).endOf('day') >= occurred;
  return fromDateMatch && toDateMatch;
};

const filterSubject = (i: ProjectChangeEvent, selectedSubjects: string[]) => {
  if (isEmpty(selectedSubjects)) return true;
  if (selectedSubjects.length === 1) {
    if (selectedSubjects[0] === Subjects.Project) {
      return i.targetGroup == null;
    }
    if (selectedSubjects[0] === Subjects.TargetGroup) {
      return i.targetGroup !== null;
    }
  }
  return selectedSubjects.length === availableSubjectFilters.length;
};

const filterKeyword = (i: ProjectChangeEvent, search: string) => {
  const eventName = convertEventTypeToText(i.eventName, i.targetGroup?.name, i.quotaName).toLowerCase();
  const searchName = search.toLowerCase();
  const eventNameMatch = eventName.indexOf(searchName) > -1;
  const targetGroupNameMatch = i.targetGroup?.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
  const targetGroupIdMatch = i.targetGroup?.id.toString() === search;
  const userNameMatch = i.userName.toLowerCase().indexOf(search.toLowerCase()) > -1;
  const quotaNameMatch = i.quotaName?.toLowerCase().indexOf(search.toLowerCase()) > -1;
  return eventNameMatch || targetGroupNameMatch || targetGroupIdMatch || userNameMatch || quotaNameMatch;
};
interface FilterValues {
  searchKeyword: string;
  fromDate: string;
  toDate: string;
  users: string[];
  subjects: string[];
}

export const ChangeLogFilters: React.FC<Props> = ({ changeLog, onHandleChanges }) => {
  const [filters, setFilters] = useState<PopoverFilters>(getInitialPopoverFilters(changeLog));
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>(todayISOString);

  const onHandleFilter = (result: FilterResult[]) => {
    const users = result.find((i) => i.title === userFilterTitle).selected;
    const subjects = result.find((i) => i.title === subjectFilterTitle).selected;
    setFilters((prev) => {
      return {
        ...prev,
        userFilter: { ...prev.userFilter, selected: users },
        subjectFilter: { ...prev.subjectFilter, selected: subjects },
      };
    });

    applyFilters({ users, subjects });
  };

  const onHandleSearch = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
    applyFilters({ searchKeyword });
  };

  const onHandleDateChange = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    applyFilters({ fromDate, toDate });
  };

  const onHandleClearDates = () => {
    setFromDate('');
    setToDate('');
    applyFilters({ fromDate: '', toDate: '' });
  };

  const applyFilters = (update: Partial<FilterValues>) => {
    const active = {
      searchKeyword,
      fromDate,
      toDate,
      users: filters.userFilter.selected,
      subjects: filters.subjectFilter.selected,
    };
    const newFilters = { ...active, ...update };
    const filteredEvents = changeLog.filter(
      (i) =>
        filterUser(i, newFilters.users) &&
        filterDate(i, newFilters.fromDate, newFilters.toDate) &&
        filterKeyword(i, newFilters.searchKeyword) &&
        filterSubject(i, newFilters.subjects)
    );
    onHandleChanges(filteredEvents);
  };

  return (
    <div className="change-log-filters">
      <DateSpanInput
        fromDate={fromDate}
        toDate={toDate}
        onDateChange={onHandleDateChange}
        onClear={onHandleClearDates}
      />
      <div className="search-filters">
        <SearchAndFilter
          filters={[filters.subjectFilter, filters.userFilter]}
          onFilter={onHandleFilter}
          onSearch={onHandleSearch}
          search=""
          searchPlaceholder="Search by keyword"
        />
      </div>
    </div>
  );
};
