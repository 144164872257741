import dayjs from 'dayjs';
import { AutoDeliveryStrategy } from '../../../common/enums';
import { AutoDeliveryValidationData, TargetGroupValidationData } from './auto-delivery.component';

export enum AutoDeliveryError {
  None,
  DeliveryPeriodLessThan72HourSpreadError,
  HasOngoingSamplingError,
  EndsInLessThan24HoursFromNowSpreadError,
  EndDateHasPassedError,
  EndDateHasPassedSamplingNoLongerOngoingError,
}

export function validateAutoDelivery(validationData: AutoDeliveryValidationData): [boolean, AutoDeliveryError] {
  if (validationData.hasOngoingSampling) {
    return [false, AutoDeliveryError.HasOngoingSamplingError];
  }

  if (validationData.targetGroup.isAutoDeliveryEnabled) {
    if (!targetGroupEndDateIsInTheFuture(validationData.targetGroup)) {
      return [false, AutoDeliveryError.EndDateHasPassedSamplingNoLongerOngoingError];
    }
    return [true, AutoDeliveryError.None];
  }

  if (canCollectCompletes(validationData)) {
    if (
      validationData.selectedStrategy === AutoDeliveryStrategy.SpreadCompletesOverTime &&
      !targetGroupFieldPeriodHasAtLeast72HoursRemaining(validationData.targetGroup)
    ) {
      return [false, AutoDeliveryError.DeliveryPeriodLessThan72HourSpreadError];
    }
    return [true, AutoDeliveryError.None];
  }

  if (!targetGroupEndDateIsInTheFuture(validationData.targetGroup)) {
    return [false, AutoDeliveryError.EndDateHasPassedError];
  }

  if (!targetGroupEndDateIsAtLeast24HoursFromNow(validationData.targetGroup)) {
    if (validationData.selectedStrategy === AutoDeliveryStrategy.Asap) {
      return [false, AutoDeliveryError.EndsInLessThan24HoursFromNowSpreadError];
    }

    if (validationData.selectedStrategy === AutoDeliveryStrategy.SpreadCompletesOverTime) {
      return [false, AutoDeliveryError.DeliveryPeriodLessThan72HourSpreadError];
    }
  }

  return [true, AutoDeliveryError.None];
}

export function canCollectCompletes(validationData: AutoDeliveryValidationData): boolean {
  if (validationData.hasOngoingSampling) return false;
  if (!targetGroupEndDateIsInTheFuture(validationData.targetGroup)) return false;
  if (!targetGroupEndDateIsAtLeast24HoursFromNow(validationData.targetGroup)) return false;
  if (validationData.inProgress) return false;
  if (validationData.targetGroup.isAutoDeliveryEnabled) return false;
  if (validationData.hasOutstandingBills) return false;
  return true;
}

export function canCollectCompletesWithSpread(validationData: AutoDeliveryValidationData): boolean {
  return (
    canCollectCompletes(validationData) &&
    validationData.selectedStrategy === AutoDeliveryStrategy.SpreadCompletesOverTime &&
    targetGroupFieldPeriodHasAtLeast72HoursRemaining(validationData.targetGroup)
  );
}

export function targetGroupEndDateIsInTheFuture(targetGroup: TargetGroupValidationData): boolean {
  return dayjs(targetGroup.endDate).isAfter(dayjs());
}

export function targetGroupEndDateIsAtLeast24HoursFromNow(targetGroup: TargetGroupValidationData): boolean {
  return dayjs(targetGroup.endDate).diff(dayjs(), 'hour') >= 24;
}

export function targetGroupFieldPeriodHasAtLeast72HoursRemaining(targetGroup: TargetGroupValidationData): boolean {
  return (
    dayjs(targetGroup.endDate).diff(dayjs(), 'hour') >= 72 &&
    dayjs(targetGroup.endDate).diff(dayjs(targetGroup.startDate), 'hour') >= 72
  );
}
