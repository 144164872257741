import { useEffect, useRef, useState } from 'react';
import { CustomPopover } from './CustomPopover';

interface Props {
  onClick: () => void;
  disabled: boolean;
  className: string;
  title: string;
  icon: string;
  iconClicked: string;
  iconClickedText: string;
}

const popoverStyle: React.CSSProperties = {
  position: 'relative',
  fontSize: '12px',
  padding: '5px',
};

const iconStyle: React.CSSProperties = {
  fontSize: '14px',
  margin: 0,
};

export const FeedbackButton: React.FC<Props> = ({
  onClick,
  className,
  title,
  disabled,
  icon,
  iconClicked,
  iconClickedText,
}) => {
  const [currentButtonIcon, setCurrentButtonIcon] = useState(icon);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => (timeoutRef.current ? clearTimeout(timeoutRef.current) : undefined);
  }, []);

  const click = () => {
    onClick();

    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }
    setPopoverVisible(true);
    setCurrentButtonIcon(iconClicked);
    timeoutRef.current = setTimeout(() => {
      setCurrentButtonIcon(icon);
      setPopoverVisible(false);
    }, 1000);
  };

  return (
    <CustomPopover
      visible={popoverVisible}
      placement="right"
      content={<div style={popoverStyle}>{iconClickedText}</div>}
    >
      <button disabled={disabled} className={className} type="button" onClick={(_) => click()} title={title}>
        <i className={currentButtonIcon} style={iconStyle} />
      </button>
    </CustomPopover>
  );
};
