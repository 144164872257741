import { ui } from 'angular';
import { ErrorMessageDialogResolve } from './error-message-dialog-settings.factory';
import { html } from '../../helpers';

const selector = 'errorMessageDialogComponent';

const template = html`
  <div class="error-message-dialog-component">
    <modal-close-button-component close-action="$ctrl.close()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">{{ $ctrl.title }}</h1>
      <p ng-repeat="message in $ctrl.messages track by $index" class="push-down">{{message}}</p>
      <div class="modal-controls">
        <button class="ui-btn primary-btn" ng-click="$ctrl.close()">Ok</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  resolve: '<' | ErrorMessageDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class ErrorMessageDialogComponent implements Bindings {
  static componentName = selector;

  title: string;
  messages: string[];
  resolve: ErrorMessageDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;

  $onInit() {
    this.messages = this.resolve.messages;
    this.title = this.resolve.title ?? 'Error';
  }

  close() {
    this.modalInstance.close();
  }
}

export const ngErrorMessageDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ErrorMessageDialogComponent,
  },
};
