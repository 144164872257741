import { IDirective } from 'angular';
import { userService } from '../user.service';
import { RuntimeSettings } from '../../runtime-settings';

const directiveName = 'adminOnlyInProduction';

export function adminOnlyInProduction(): IDirective {
  return {
    link: (_scope, element) => {
      if (userService.isAdminUser()) return;
      if (RuntimeSettings.runtime.environment === 'Production' || RuntimeSettings.runtime.environment === 'Sandbox') {
        element.remove();
      }
    },
  };
}

export const ngAdminOnlyInProductionDirective = {
  [directiveName]: adminOnlyInProduction,
};
