import { values } from 'lodash-es';
import { projectService } from './project.service';
import { TargetGroupsStateIntent } from '../target-groups-state-intent';
import { $stateParams } from '../ngimport';

interface IsLoaded {
  isLoaded: boolean;
}

interface Components {
  basicSettings: IsLoaded;
  regions: IsLoaded;
  profiling: IsLoaded;
  quotas: IsLoaded;
  audience: IsLoaded;
  excludeProjects: IsLoaded;
  targetGroupsList: IsLoaded;
  projectService: IsLoaded;
}

export class TargetGroupsLoadingGuard {
  components: Components;

  constructor() {
    this.initComponents();
  }

  loaded(componentName: keyof Components) {
    this.components[componentName].isLoaded = true;

    if (values(this.components).every((x) => x.isLoaded === true)) {
      projectService.show($stateParams.intent === TargetGroupsStateIntent.AddTargetGroups);
    }
  }

  reset() {
    this.initComponents();
  }

  private initComponents(): void {
    this.components = {
      basicSettings: { isLoaded: false },
      regions: { isLoaded: false },
      profiling: { isLoaded: false },
      quotas: { isLoaded: false },
      audience: { isLoaded: false },
      excludeProjects: { isLoaded: false },
      targetGroupsList: { isLoaded: false },
      projectService: { isLoaded: false },
    };
  }
}

export const targetGroupsLoadingGuard = new TargetGroupsLoadingGuard();
