import { ui } from 'angular';
import { html } from '../../../helpers';

const selector = 'saveTemplateInvalidSettingsDialogComponent';

const template = html`
  <div class="save-template-invalid-settings-dialog-component">
    <div class="modal-container">
      <h1 class="modal-header">{{$ctrl.type === 'update' ? 'Update' : 'Save'}} template error</h1>
      <p class="push-down" ng-show="$ctrl.reason === 'invalid-basic-settings'">
        Please make sure that target group has correct values for basic settings before trying to {{$ctrl.type ===
        'update' ? 'update' : 'save'}} the template.
      </p>
      <p class="push-down" ng-show="$ctrl.reason === 'existing-template-name'">
        Another template with the same name already exists. Please choose a different name.
      </p>
      <div class="modal-controls">
        <button class="ui-btn primary-btn" ng-click="$ctrl.close()">OK</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  resolve: '<' | SaveTemplateInvalidSettingsDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class SaveTemplateInvalidSettingsDialogComponent implements Bindings {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;
  resolve: SaveTemplateInvalidSettingsDialogResolve;
  type: 'update' | 'save';
  reason: 'invalid-basic-settings' | 'existing-template-name';

  $onInit(): void {
    this.type = this.resolve.type;
    this.reason = this.resolve.reason;
  }

  close(): void {
    this.modalInstance.close();
  }
}

export class SaveTemplateInvalidSettingsDialogResolve {
  type: 'update' | 'save';
  reason: 'invalid-basic-settings' | 'existing-template-name';
}

export const ngSaveTemplateInvalidSettingsDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: SaveTemplateInvalidSettingsDialogComponent,
  },
};
