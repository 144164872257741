import { IAttributes, IAugmentedJQuery, IScope, INgModelController } from 'angular';
import { DirectiveUsingNgModelController } from './directive.types';

const directiveName = 'greaterThanZeroInteger';

export function greaterThanZeroInteger(): DirectiveUsingNgModelController {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (_scope: IScope, _element: IAugmentedJQuery, _attributes: IAttributes, ctrl: INgModelController) => {
      ctrl.$parsers.push((value) => {
        if (ctrl.$isEmpty(value)) return undefined;

        let num = Number(value);
        if (!Number.isNaN(num) && num > 0) {
          num = Number(num.toFixed(0));
          ctrl.$viewValue = num;
          ctrl.$render();
          return num;
        }
        ctrl.$viewValue = null;
        ctrl.$render();
        return undefined;
      });
      ctrl.$formatters.unshift((value) => {
        if (ctrl.$isEmpty(value)) return undefined;
        const num = Number(value);
        if (Number.isNaN(num)) return undefined;
        return num.toFixed(0);
      });
    },
  };
}

export const ngGreaterThanZeroIntegerDirective = {
  [directiveName]: greaterThanZeroInteger,
};
