import { Draft } from 'immer';
import { Iso8601Date } from '../../../../helpers';
import { TargetGender } from '../../../../common/enums';
import { ActiveBasicSettingsModel } from '../../models/active-basic-settings.model';
import { CountryRestrictions } from '../../../country.service';

export const mutators = {
  setCountry:
    (country: { id: number; name: string }, restrictions: CountryRestrictions | null) =>
    (state: Draft<ActiveBasicSettingsModel>) => {
      state.countryId = country.id;
      state.countryName = country.name;
      state.minAge = Math.max(restrictions.minimumAge, state.minAge);
    },

  setGender: (gender: TargetGender) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.gender = gender;
  },

  setAge: (age: { minAge: number; maxAge: number }) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.minAge = age.minAge;
    state.maxAge = age.maxAge;
  },

  setCompletes: (completes: number) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.numberOfCompletes = completes;
  },

  setStarts: (starts: number) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.numberOfStarts = starts;
  },
  setUseStarts: (useStarts: boolean) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.useStarts = useStarts;
  },

  setIr: (ir: number) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.estimatedIncidenceRate = ir;
  },

  setLoi: (loi: number) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.estimatedLengthOfInterview = loi;
  },

  setUseFixedLoi: (useFixedLoi: boolean) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.useFixedLoi = useFixedLoi;
  },

  setStartDate: (startDate: Iso8601Date | string) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.startDate = startDate;
  },

  setNumberOfDaysInField: (numberOfDays: number) => (state: Draft<ActiveBasicSettingsModel>) => {
    state.numberOfDaysInField = numberOfDays;
  },
};
