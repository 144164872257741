import { ui } from 'angular';
import { react2angular } from 'react2angular';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { ModalCloseButton } from '../../common/dialogs/ModalCloseButton';
import { api, ProjectGroupsResponse } from '../../common/api';
import { UiProjectGroup } from './ProjectGroupSettings';

import './SelectProjectGroupsDialog.scss';
import { modalService } from '../../common/modal.service';
import { AddProjectGroupDialogSettingsFactory } from './add-project-group-dialog-settings.factory';
import { silenceRejection } from '../../helpers';
import { SearchInput } from '../../common/controls/search-input/SearchInput';

interface Props {
  resolve: SelectProjectGroupsDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
}

const SelectProjectGroupsDialog: React.FC<Props> = ({ resolve: { projectId }, modalInstance }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectGroups, setProjectGroups] = useState<UiProjectGroup[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filteredProjectGroups, setFilteredProjectGroups] = useState<UiProjectGroup[]>([]);
  const [searchString, setSearchString] = useState('');

  const handleSave = async () => {
    await api.project.setProjectGroupsForProject(projectId, selectedIds);
    modalInstance.close();
  };

  const toggleProjectGroupSelection = (id: string) => {
    if (selectedIds.includes(id)) setSelectedIds((prevValue) => prevValue.filter((pgid) => pgid !== id));
    else setSelectedIds((prevValue) => [...prevValue, id]);
  };

  const fetchApiResponse = useCallback(async () => {
    setIsLoading(true);

    const companyPgs = await api.project.getProjectGroupsForCompany();
    const projectPgs = await api.project.getProjectGroupsForProject(projectId);

    processApiResponse(companyPgs.data, projectPgs.data);
  }, [projectId]);

  const processApiResponse = (companyPgs: ProjectGroupsResponse, projectPgs: ProjectGroupsResponse) => {
    setIsLoading(false);
    setProjectGroups(companyPgs);
    setSelectedIds(projectPgs.map((pg) => pg.id));
  };

  const openAddProjectGroupDialog = () => {
    modalService
      .openMedium(AddProjectGroupDialogSettingsFactory.create())
      .result.then((_) => {
        fetchApiResponse();
      })
      .catch(silenceRejection);
  };

  const filterProjectGroups = (searchStr: string, groups: UiProjectGroup[]) => {
    if (!searchStr) setFilteredProjectGroups(cloneDeep(groups));
    const lowStr = searchStr.toLowerCase();
    const filtered = groups
      .filter((g) => g.name.toLowerCase().includes(lowStr))
      .sort((a, b) => a.name.toLowerCase().indexOf(lowStr) - b.name.toLowerCase().indexOf(lowStr));
    setFilteredProjectGroups(cloneDeep(filtered));
  };

  const onSearch = (str: string) => {
    setSearchString(str);
  };

  useEffect(() => {
    filterProjectGroups(searchString, projectGroups);
  }, [searchString, projectGroups]);

  useEffect(() => {
    fetchApiResponse();
  }, [fetchApiResponse]);

  return (
    <div className="modal-container select-project-groups-dialog">
      <ModalCloseButton onClose={modalInstance.dismiss} />

      <h1 className="modal-header" id="select-project-groups-header">
        Select Project Groups to associate with the project
      </h1>

      {isLoading && (
        <div className="loading-spinner">
          <i className="fas fa-spinner fa-spin" />
        </div>
      )}

      {!isLoading && (
        <>
          {(projectGroups?.length || 0) > 5 && (
            <SearchInput placeholder="Search groups" onSearch={onSearch} searchString={searchString} />
          )}
          {!projectGroups?.length && <p>Your company has no Project Groups at the moment.</p>}
          {!!projectGroups?.length && !filteredProjectGroups?.length && <p>No project groups found.</p>}
          {!!filteredProjectGroups?.length &&
            filteredProjectGroups.map((pg) => (
              <div
                key={pg.id}
                className={classNames('project-group-pill', { unselected: !selectedIds.includes(pg.id) })}
                onClick={(_) => toggleProjectGroupSelection(pg.id)}
              >
                {pg.name}
              </div>
            ))}
        </>
      )}

      <div className="btn-row">
        <button className="ui-btn primary-btn" onClick={openAddProjectGroupDialog}>
          Create new Project Group
        </button>

        <div className="btn-row-right">
          <button className="ui-btn default-btn" onClick={modalInstance.dismiss}>
            Cancel
          </button>
          <button className="ui-btn primary-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export const SelectProjectGroupsDialogComponentName = 'selectProjectGroupsDialog';
export const ngSelectProjectGroupsDialogComponent = {
  selectProjectGroupsDialog: react2angular(SelectProjectGroupsDialog, ['resolve', 'modalInstance']),
};

export interface SelectProjectGroupsDialogResolve {
  projectId: number;
}
