import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { toJson } from 'angular';
import { isEmpty } from 'lodash';
import { ActiveSupplyMixSource } from '../target-groups/supply/supply-mix/supply-mix-state.service';
import { AutoDeliveryStrategy, PanelistPoolSource, ProjectListItemStatus, ProjectStatus, SupplySource } from './enums';
import { TargetGroupsStateIntent } from '../target-groups-state-intent';
import { appInsightsSafeUserId, Iso8601Date } from '../helpers';
import { userService } from './user.service';
import { featureFlipper } from './feature-flipper';

export function initApplicationInsightsAnalytics(applicationInsightsKey: string) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: applicationInsightsKey,
      disableAjaxTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.setAuthenticatedUserContext(appInsightsSafeUserId(userService.name));
  appInsights.trackPageView();
  analytics.appInsights = appInsights;
}

export class Analytics {
  appInsights: ApplicationInsights;

  currentWork = {
    merge: (intent: TargetGroupsStateIntent): void => {
      this.trackEventWithSource('current-work', 'merge', 'current-work-dialog', { intent });
    },
    discard: (intent: TargetGroupsStateIntent): void => {
      this.trackEventWithSource('current-work', 'discard', 'current-work-dialog', { intent });
    },
    cancel: (intent: TargetGroupsStateIntent): void => {
      this.trackEventWithSource('current-work', 'cancel', 'current-work-dialog', { intent });
    },
  };
  draft = {
    save: (): void => {
      this.trackEventWithSource('draft', 'save', 'save-draft-dialog');
    },
    downloadSnapshot: (id: number) => this.trackEventWithSource('draft-snapshot', 'download', 'tg-sidebar', { id }),
  };

  template = {
    load: (): void => {
      this.trackEventWithSource('tg-template', 'load', 'load-template-dialog');
    },
    loadIncompatible: (): void => {
      this.trackEventWithSource('tg-template', 'load-incompatible', 'load-template-dialog');
    },
    cancelLoadIncompatible: (): void => {
      this.trackEventWithSource('tg-template', 'cancel-load-incompatible', 'load-template-dialog');
    },
    createProjectBasedOnTemplate: (): void => {
      this.trackEventWithSource('tg-template', 'create-project-based-on-tempalte', 'template-list-menu');
    },
    save: (): void => {
      this.trackEventWithSource('tg-template', 'save', 'save-template-dialog');
    },
    edit: (): void => {
      this.trackEventWithSource('tg-template', 'edit', 'template-list-menu');
    },
    delete: (): void => {
      this.trackEventWithSource('tg-template', 'delete', 'delete-template-dialog');
    },
    showHistory: (): void => {
      this.trackEventWithSource('tg-template', 'show-history', 'template-list-menu');
    },
    update: (): void => {
      this.trackEventWithSource('tg-template', 'update', 'tg-sidebar');
    },
  };

  navigation = {
    learn: () => {
      this.trackEventWithSource('help', 'learn', 'global-navigation');
    },
    products: () => {
      this.trackEventWithSource('account', 'list-products', 'user-menu');
    },
    account: () => {
      this.trackEventWithSource('account', 'user-account', 'user-menu');
    },
    logOut: () => {
      this.trackEventWithSource('global-navigation', 'log-out', 'user-menu');
    },
    msViaAccess: (isEnabled: boolean) => {
      this.trackEventWithSource('ms-mode', `${isEnabled ? 'enable' : 'disable'}`, 'user-menu');
    },
  };

  projectList = {
    toggleTargetGroups: () => {
      this.trackEventWithSource('project-lists', 'expand-deviating-tg-list', 'project-list-item');
    },
    legacy: (): void => {
      this.trackEventWithSource('project-lists', 'view-legacy', 'dashboard');
    },
    new: (): void => {
      this.trackEventWithSource('project-lists', 'view-new', 'legacy-list');
    },
    openTab: (tabType: 'active' | 'closed' | 'drafts' | 'templates'): void => {
      this.trackEventWithSource('project-lists', `open-${tabType}-tab`, 'legacy-list');
    },
    filter: {
      select: (filterType: string): void => {
        this.trackEventWithSource('project-lists', `filter-${filterType}`, 'legacy-list');
      },
      apply: (tab: 'active' | 'drafts' | 'closed') => {
        this.trackEventWithSource('project-lists', `filter-button-${tab}`, 'legacy-list');
      },
      clear: () => {
        this.trackEventWithSource('project-lists', 'clear-button', 'legacy-list');
      },
    },
    sort: (sortType: string): void => {
      this.trackEventWithSource('project-lists', `sort-${sortType}`, 'legacy-list');
    },
    view: (projectStatus: ProjectListItemStatus, projectStartDate: string) =>
      this.trackEventWithSource('project-lists', 'view-project', 'legacy-list', {
        projectStatus,
        projectStartDate,
      }),
    draft: {
      view: () => this.trackEventWithSource('project-lists', 'view-draft', 'legacy-list'),
    },
    projectMenu: {
      view: (projectStatus: string, projectStartDate: string) =>
        this.trackEventWithSource('project-lists', 'view-project', 'project-menu', { projectStatus, projectStartDate }),
      monitor: (projectStatus: string, projectStartDate: string) =>
        this.trackEventWithSource('management-and-monitoring', 'open', 'project-menu', {
          projectStatus,
          projectStartDate,
        }),
      downloadData: (projectStatus: string, endDate: Iso8601Date) =>
        this.trackEventWithSource('respondent-data-report', 'download', 'project-menu', {
          projectStatus,
          endDate,
        }),
      downloadDataExtended: (projectStatus: string, endDate: Iso8601Date) =>
        this.trackEventWithSource(
          featureFlipper.name('downloadPanelistBackgroundDataAsJson'),
          'download',
          'project-menu',
          {
            projectStatus,
            endDate,
          }
        ),
      downloadProgressReport: (projectStatus: string, projectStartDate: string) =>
        this.trackEventWithSource('progress-report', ' download', 'project-menu', {
          projectStatus,
          projectStartDate,
        }),

      draft: {
        view: () => this.trackEventWithSource('draft', 'open', 'project-menu'),
        delete: () => this.trackEventWithSource('draft', 'delete', 'project-menu'),
        downloadSnapshot: (id: number) =>
          this.trackEventWithSource('draft-snapshot', 'download', 'project-menu', { id }),
      },
    },
  };

  newProject = {
    changePane: (name: string): void => {
      const nameSegments = name.split('-');
      for (let index = 0; index < nameSegments.length; index++) {
        const value = nameSegments[index];
        nameSegments[index] = value.charAt(0).toUpperCase() + value.slice(1);
      }
      const prettyName = nameSegments.join('');
      this.trackEventWithSource('project-setup', 'change-pane', prettyName);
    },
    continue: (name: string): void => {
      const nameSegments = name.split('-');
      for (let index = 0; index < nameSegments.length; index++) {
        const value = nameSegments[index];
        nameSegments[index] = value.charAt(0).toUpperCase() + value.slice(1);
      }
      const prettyName = nameSegments.join('');
      this.trackEventWithSource('project-setup', 'change-pane', 'continue-button', { continueTo: prettyName });
    },
    projectSettings: {
      selectSuitableForTablet: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-suitable-for-tablet`);
      },
      selectSuitableForMobile: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-suitable-for-mobile`);
      },
      selectSuitableForDesktop: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-suitable-for-desktop`);
      },
      selectRequiresWebcam: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-requires-webcam`);
      },
      selectRestrictGeo: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-restrict-geograpically`);
      },
      selectPreventDuplicates: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-prevent-duplicates`);
      },
      selectCollectsPII: (toggled: boolean): void => {
        this.trackEvent('project-settings', `${toggled ? 'yes' : 'no'}-collects-pii`);
      },
    },
    quotas: {
      removeInterlocked: (): void => {
        this.trackEvent('quotas', 'remove-interlocked');
      },
      toggleRimWeighting: (toggled: boolean): void => {
        this.trackEvent('quotas', `${toggled ? 'yes' : 'no'}-rim-weighting`);
      },
      toggleUsePercentages: (toggled: boolean): void => {
        this.trackEvent('quotas', `${toggled ? 'yes' : 'no'}-use-percentages`);
      },
      toggleSetupQuotasInPercentage: (toggled: boolean): void => {
        this.trackEvent('quotas', `${toggled ? 'yes' : 'no'}-quotas-in-percentage`);
      },
      toggleUsePanelDistribution: (toggled: boolean, quotaName: string): void => {
        this.trackEvent('quotas', `${toggled ? 'yes' : 'no'}-panel-distribution`, { quotaName });
      },
      groupQuotasIcon: (): void => {
        this.trackEvent('quotas', 'group-quotas-icon');
      },
      groupSelectedQuotas: (): void => {
        this.trackEvent('quotas', 'group-selected-quotas');
      },
      interlockQuotasIcon: (): void => {
        this.trackEvent('quotas', 'interlock-quotas-icon');
      },
      interlockQuotasButton: (): void => {
        this.trackEvent('quotas', 'interlock-quotas-button');
      },
      interlockSelectedQuotas: (): void => {
        this.trackEvent('quotas', 'interlock-selected-quotas');
      },
      useNoneDistribution: (questionName: string): void => {
        this.trackEvent('quotas', 'none-distribution', { questionName });
      },
      useCensusDistribution: (questionName: string): void => {
        this.trackEvent('quotas', 'census-distribution', { questionName });
      },
      useCustomDistribution: (questionName: string): void => {
        this.trackEvent('quotas', 'custom-distribution', { questionName });
      },
    },
    supply: {
      useSupplyMix: (): void => {
        this.trackEvent('supply-mix', 'use');
      },
      discardSupplyMix: (): void => {
        this.trackEvent('supply-mix', 'discard');
      },
      addSupplyGroup: (): void => {
        this.trackEvent('supply-mix', 'add');
      },
      editSupplyGroup: (): void => {
        this.trackEvent('supply-mix', 'edit');
      },
      deleteSupplyGroup: (): void => {
        this.trackEvent('supply-mix', 'delete');
      },
      setActiveSupplyGroupSource: (supplySource: ActiveSupplyMixSource): void => {
        this.trackEvent('supply-mix', 'set-active-source', { supplySource });
      },
      panelInfo: (): void => {
        this.trackEvent('audience', 'panel-information-icon');
      },
      filterOnTags: (tags: string[]): void => {
        this.trackEvent('audience', 'filter-on-tags', { tags });
      },
      specificPanelists: {
        openWizard: () => {
          this.trackEvent('specific-panelists', 'open-wizard');
        },
        selectFiles: (source: PanelistPoolSource, filesCount: number, uniqueRowsCount: number) => {
          this.trackEvent('specific-panelists', 'select-files', {
            source,
            filesCount,
            uniqueRowsCount,
          });
        },
        save: (source: PanelistPoolSource) => {
          this.trackEvent('specific-panelists', 'save', { source });
        },
        retryAvailability: (source: PanelistPoolSource) => {
          this.trackEvent('specific-panelists', 'retry-availability', { source });
        },
        reset: (source: PanelistPoolSource) => {
          this.trackEvent('specific-panelists', 'reset', { source });
        },
      },
    },
    profiling: {
      calculateProfilingDepth: (categoryName: string, categoryId: number): void => {
        this.trackEvent('profiling', 'calculate-depth', { categoryId, categoryName });
      },
      selectAllVariablesInQuestion: (
        toggled: boolean,
        categoryName: string,
        questionId: number,
        panelSpecificProfiling: boolean
      ): void => {
        const type = panelSpecificProfiling ? 'panel-specific' : 'global';
        this.trackEvent('profiling', `${toggled ? 'select' : 'deselect'}-all-variables-for-profiling-question`, {
          type,
          categoryName,
          questionId,
        });
      },
    },
    pricing: {
      useCustomCpi: (enable: boolean): void => {
        this.trackEventWithSource('custom-cpi', 'toggle', 'audience-pane', { state: enable ? 'enabled' : 'disabled' });
      },
      setCustomCpi: (supplySource: SupplySource): void => {
        this.trackEvent('custom-cpi', 'set', { supplySource });
      },
    },
    run: (tgCount: number): void => {
      this.trackEvent('project-setup', 'run');
      for (let i = 0; i < tgCount; i++) {
        this.trackEvent('project', 'run-target-group');
      }
    },
    request: (): void => {
      this.trackEvent('project-setup', 'request');
    },
    initiate: (source: string): void => {
      this.trackEventWithSource('project-setup', 'initiate-new', source);
    },
    create: (): void => {
      this.trackEvent('project-setup', 'create');
    },
    cancelCreate: (): void => {
      this.trackEvent('project-setup', 'cancel');
    },
    cancelSetup: (intent: string): void => {
      this.trackEvent('project-setup', 'cancel-setup', { intent });
    },
    downloadBucketsCsv: (): void => {
      this.trackEvent('project', 'download-buckets-csv');
    },
  };

  replaceTargetGroups = {
    startReplacement: (): void => {
      this.trackEvent('replace-target-groups', `start-replacement-project`);
    },
    orderReplacement: (): void => {
      this.trackEvent('replace-target-groups', 'order-replacement-project');
    },
  };

  projectOverview = {
    monitor: (projectStatus: ProjectStatus): void => {
      this.trackEventWithSource('management-and-monitoring', 'open', 'project-overview', {
        projectStatus,
      });
    },
  };

  manageProject = {
    goToOverview: (projectStatus: ProjectStatus): void => {
      this.trackEventWithSource('management-and-monitoring', 'to-overview', 'monitor', { projectStatus });
    },
    reuseTargetGroups: (projectStatus: ProjectStatus, copyUrls: boolean): void => {
      this.trackEventWithSource('project-setup', 'reuse', 'project-overview', { projectStatus, copyUrls });
    },
    addNewTargetGroup: (): void => {
      this.trackEventWithSource('project-setup', 'add-new-target-group', 'project-overview');
    },
    addTargetGroupFromTemplate: (): void => {
      this.trackEvent('project-setup', 'add-target-group-from-template');
    },
    duplicateTargetGroup: (copyUrls: boolean): void => {
      this.trackEvent('project-setup', 'duplicate-tg', { copyUrls });
    },
    duplicateTargetGroupInField: (copyUrls: boolean): void => {
      this.trackEvent('project-setup', 'duplicate-tg-infield', { copyUrls });
    },
    deleteTargetGroup: (): void => {
      this.trackEvent('project-setup', 'delete-tg');
    },
    downloadPanelistsBackgroundData: (projectId: number, projectStatus: ProjectStatus): void => {
      this.trackEventWithSource('respondent-data-report', 'download', 'overview', { projectId, projectStatus });
    },
    downloadPanelistsBackgroundDataJson: (projectId: number, projectStatus: ProjectStatus): void => {
      this.trackEventWithSource(featureFlipper.name('downloadPanelistBackgroundDataAsJson'), 'download', 'overview', {
        projectStatus,
        projectId,
      });
    },
    downloadProgressReport: (projectId: number, projectStatus: ProjectStatus, projectStartDate: string): void => {
      this.trackEventWithSource('progress-report', 'download', 'overview', {
        projectId,
        projectStatus,
        projectStartDate,
      });
    },
    editInvitationEmail: {
      cancel: (): void => {
        this.trackEvent('manage-project', 'edit-invitation-email-cancel');
      },
      preview: (): void => {
        this.trackEvent('manage-project', 'edit-invitation-email-preview');
      },
      startEdit: (): void => {
        this.trackEvent('manage-project', 'edit-invitation-email-start');
      },
      save: (): void => {
        this.trackEvent('manage-project', 'edit-invitation-email-save');
      },
    },
    fixedLoi: {
      edit: (): void => {
        this.trackEvent('manage-project', 'fixed-loi-edit');
      },
      cancel: (): void => {
        this.trackEvent('manage-project', 'fixed-loi-cancel');
      },
      save: (): void => {
        this.trackEvent('manage-project', 'fixed-loi-save');
      },
    },
    incentives: {
      edit: (): void => {
        this.trackEvent('manage-project', 'incentives-edit');
      },
      cancel: (): void => {
        this.trackEvent('manage-project', 'incentives-cancel');
      },
      save: (): void => {
        this.trackEvent('manage-project', 'incentives-save');
      },
    },
    update: (): void => {
      this.trackEvent('project', 'update');
    },
    cancelUpdate: (): void => {
      this.trackEvent('project', 'update-cancel');
    },
    relaxBuckets: (percent: number): void => {
      this.trackEvent('manage-project', 'relax-buckets', { percent });
    },
    downloadBucketsCsv: (): void => {
      this.trackEvent('manage-project', 'download-buckets-csv');
    },
  };

  suspensionLimits = {
    override: (): void => {
      this.trackEvent('suspension-limits', 'override', { area: 'monitor' });
    },
    openDialog: (canOverride: boolean): void => {
      this.trackEvent('suspension-limits', 'open-dialog', { area: 'monitor', canOverride });
    },
  };

  sampling = {
    sampleForQuota: (quotaName: string): void => {
      this.trackEvent('sampling', 'quota', { quotaName });
    },
    sampleForTargetGroup: (): void => {
      this.trackEvent('sampling', 'target-group');
    },
  };

  autodelivery = {
    start: (strategy: AutoDeliveryStrategy): void => {
      this.trackEvent('autodelivery', strategy === AutoDeliveryStrategy.Asap ? 'asap-start' : 'spread-start');
    },
    stop: (strategy: AutoDeliveryStrategy): void => {
      this.trackEvent('autodelivery', strategy === AutoDeliveryStrategy.Asap ? 'asap-stop' : 'spread-stop');
    },
  };

  customParameters = {
    project: {
      edit: (projectId: number): void => {
        this.trackEvent('custom-parameters', 'project-edit', { projectId });
      },
      save: (projectId: number): void => {
        this.trackEvent('custom-parameters', 'project-save', { projectId });
      },
      cancel: (projectId: number): void => {
        this.trackEvent('custom-parameters', 'project-cancel', { projectId });
      },
      resetToDefaults: (projectId: number): void => {
        this.trackEvent('custom-parameters', 'project-reset-to-defaults', { projectId });
      },
    },
    targetGroup: {
      edit: (targetGroupId: number): void => {
        this.trackEvent('custom-parameters', 'target-group-edit', { targetGroupId });
      },
      save: (targetGroupId: number): void => {
        this.trackEvent('custom-parameters', 'target-group-save', { targetGroupId });
      },
      cancel: (targetGroupId: number): void => {
        this.trackEvent('custom-parameters', 'target-group-cancel', { targetGroupId });
      },
      resetToDefaults: (targetGroupId: number): void => {
        this.trackEvent('custom-parameters', 'target-group-reset-to-defaults', { targetGroupId });
      },
    },
  };

  help = {
    toggleHelp: (source: string, onOrOff: boolean): void => {
      this.trackEventWithSource('help', 'inline-help', source, { state: onOrOff ? 'on' : 'off' });
    },
    visitKnowledgeBase: (source: string): void => {
      this.trackEventWithSource('help', 'knowledge-base', source);
    },
  };

  transition(stateToName: string): void {
    this.trackEvent('state-transition', stateToName);
  }

  trackException(exception: Error) {
    this.appInsights?.trackException({ exception } as IExceptionTelemetry);
  }

  private trackEvent(feature: string, action: string, properties: Record<string, any> = {}): void {
    this.trackEventWithSource(feature, action, undefined, properties);
  }
  /// Feature = The overall feature
  /// Action = The action that was triggered
  /// Source = View, or component from where the action was triggered. provides context
  private trackEventWithSource(
    feature: string,
    action: string,
    source: string,
    properties: Record<string, any> = {}
  ): void {
    const eventName = `${feature} | ${action}`;
    if (!isEmpty(source)) properties.source = source;

    if (__PROCESS__.ENV === 'development') {
      console.log(`Analytics event: ${eventName} (${toJson(properties)})`);
    }
    if (this.appInsights !== undefined) {
      this.appInsights.trackEvent({ name: eventName } as IEventTelemetry, { feature, action, ...properties });
    }
  }
}

export const analytics = new Analytics();
