import './app.component.scss';
import { userService } from './common/user.service';
import { stateTransitionService } from './common/state-transition.service';
import { RuntimeSettings } from './runtime-settings';
import { html } from './helpers';
import { $state } from './ngimport';

const selector = 'appComponent';

const template = html`
  <div class="app-component" data-testid="app-component">
    <check-for-updates-component ng-if="$ctrl.checkForUpdates"></check-for-updates-component>
    <div class="impersonation-bar" ng-if="$ctrl.userService.isImpersonatingOtherUser()">
      You are now impersonating <span>{{$ctrl.userService.impersonatedAs}}</span>
    </div>
    <navigation-component></navigation-component>
    <div class="unpaid-bills" ng-show="$ctrl.userService.hasOutstandingBills">
      <div class="unpaid-bills__icon"></div>
      <div class="unpaid-bills__text">
        Due to outstanding invoices, you are not able to use all Access / Access&nbsp;Pro functionality. Please contact
        <a href="mailto:accountsreceivable@cint.com" title="Send an email to accountsreceivable@cint.com"
          >accountsreceivable@cint.com</a
        >.
      </div>
    </div>
    <div class="wrapper" ng-class="{ 'monitor': $ctrl.isMonitorView }">
      <immer-patches-devtools-component only-if-immer-patches-enabled></immer-patches-devtools-component>
      <div class="clearfix"></div>
      <div>
        <div class="spinner-overlay" ng-if="$ctrl.stateTransitionService.isStateTransitioning">
          <div class="spinner-container text-center">
            <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        </div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="blur-svg">
          <defs>
            <filter id="blur-filter">
              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
            </filter>
          </defs>
        </svg>
        <div ui-view="main" ng-class="{ 'blur': $ctrl.stateTransitionService.isStateTransitioning }"></div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
`;

export class AppComponent {
  static componentName = selector;

  checkForUpdates: boolean;

  get stateTransitionService() {
    return stateTransitionService;
  }
  get userService() {
    return userService;
  }

  get isMonitorView() {
    return $state.includes('manage.monitor');
  }

  $onInit(): void {
    this.checkForUpdates = RuntimeSettings.runtime.environment !== 'Development';
  }
}

export const ngAppComponent = {
  [selector]: {
    template,
    controller: AppComponent,
  },
};
