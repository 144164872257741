import './save-template-dialog.component.scss';
import { ui, IPromise } from 'angular';
import { Transition } from '@uirouter/angularjs';
import { cloneDeep } from 'lodash-es';
import { projectService } from '../../project.service';
import { TargetGroupModel } from '../../../common/models/target-group.model';
import { api } from '../../../common/api';
import { modalService } from '../../../common/modal.service';
import { onEnter, onExit } from '../../../common/dialogs/dialog.controller';
import { isRuntypeError } from '../../../common/runtime-type-validating-http-provider.decorator';
import { analytics } from '../../../common/analytics';
import { $log } from '../../../ngimport';
import { TemplateInfoResponse } from '../../../common/http-services/template.httpservice';
import { SaveTemplateDialogSettingsFactory } from './target-groups-sidebar-dialog-settings.factories';
import { PanelistPoolType, TemplateChannel } from '../../../common/enums';
import { projectSettingsService } from '../../../common/project-settings.service';
import { html } from '../../../helpers';
import { panelistPoolApiInputString } from '../../exclude-projects/ExcludePanelistPool';
import { getUserConfirmationForPool } from './save-draft-dialog.component';
import { createSupplyModel } from '../../../common/models/supply.model';

const selector = 'saveTemplateDialogComponent';

const template = html`
  <div class="save-template-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Save template</h1>
      <p>The target group will be renamed.</p>
      <form class="push-down" ng-submit="$ctrl.ok()">
        <input type="text" class="form-control" ng-model="$ctrl.template.name" autofocus value="" maxlength="100" />
        <div class="validator-message" ng-show="$ctrl.errors.invalidName()">
          Enter a name for your target group template.
        </div>
        <div class="validator-message" ng-show="$ctrl.errors.nameNotUnique">
          Please choose a unique name for your target group template.
        </div>
        <div class="validator-message" ng-show="$ctrl.errors.saveFailed">Unable to save. Please try again later.</div>
        <div class="warning-message" ng-if="$ctrl.panelistPoolContainsUrl">
          Please note that target groups using included panelists with
          <em>unique links</em> will not have the panelists/links saved in templates
        </div>
      </form>
      <div class="modal-controls">
        <button ng-hide="$ctrl.errors.saveFailed" class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button
          ng-hide="$ctrl.errors.saveFailed"
          class="ui-btn primary-btn"
          ng-disabled="$ctrl.errors.invalidName()"
          ng-click="$ctrl.ok()"
        >
          Save template
        </button>
        <button ng-show="$ctrl.errors.saveFailed" class="ui-btn primary-btn" ng-click="$ctrl.dismiss()">Ok</button>
      </div>
    </div>
  </div>
`;

interface Template {
  id: number;
  name: string;
  channel: TemplateChannel;
}

export interface SaveTemplateDialogResolve {
  newTemplate: {
    name: string;
    targetGroupId: number;
  };
  panelistPoolContainsUrl: boolean;
}

interface Bindings {
  resolve: '<' | SaveTemplateDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class SaveTemplateDialogComponent implements Bindings {
  static componentName = selector;

  resolve: SaveTemplateDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  panelistPoolContainsUrl: boolean;
  template: {
    name: string;
    targetGroupId: number;
  };
  errors: {
    saveFailed: boolean;
    nameNotUnique: boolean;
    invalidName: () => boolean;
  };

  $onInit() {
    this.panelistPoolContainsUrl = this.resolve.panelistPoolContainsUrl;
    this.template = this.resolve.newTemplate;
    this.errors = {
      saveFailed: false,
      nameNotUnique: false,
      invalidName: () => !(this.template.name && this.template.name.trim().length),
    };
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  async ok() {
    if (this.errors.invalidName()) return;

    const tg = projectService.targetGroups.find((t) => t.id === this.template.targetGroupId);

    if (tg.panels.panelistPool.input === panelistPoolApiInputString) {
      const userConfirmed = await getUserConfirmationForPool(tg.panels.panelistPool.type, 'template');
      if (!userConfirmed) return;
    }

    api.template.getAllTemplates().then(async ({ data: existingTemplates }) => {
      if (!existingTemplates) {
        this.errors.saveFailed = true;
        return;
      }

      await this.validateAndCreate(existingTemplates);
    });
  }

  private async validateAndCreate(existingTemplates: Template[]): Promise<void> {
    if (this.templateNameExists(existingTemplates)) {
      this.errors.nameNotUnique = true;
      return;
    }

    await projectService.renameTargetGroup(this.template.targetGroupId, this.template.name);
    const loadedTargetGroup = cloneDeep(projectService.targetGroups.find((t) => t.id === this.template.targetGroupId));

    if (loadedTargetGroup.panels.panelistPool.input === panelistPoolApiInputString) {
      if (loadedTargetGroup.panels.panelistPool.type === PanelistPoolType.Inclusive) {
        loadedTargetGroup.panels = createSupplyModel();
      } else {
        loadedTargetGroup.panels = {
          ...loadedTargetGroup.panels,
          panelistPool: createSupplyModel().panelistPool,
        };
      }
    }

    this.createTemplate(loadedTargetGroup)
      .then(() => {
        this.saveTemplateAndClose();
      })
      .catch((err) => {
        if (isRuntypeError(err)) {
          $log.error(err);
        } else {
          this.errors.saveFailed = true;
        }
      });
  }

  private templateNameExists(existingTemplates: Template[]): boolean {
    return existingTemplates.some((d) => d.name === this.template.name);
  }

  private createTemplate(targetGroup: TargetGroupModel): IPromise<TemplateInfoResponse> {
    return api.template.createTemplate(targetGroup, projectSettingsService.settings).then((res) => res.data);
  }

  private saveTemplateAndClose(): void {
    analytics.template.save();
    this.modalInstance.close();
  }
}

export const ngSaveTemplateDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: SaveTemplateDialogComponent,
  },
};

export const saveTemplateDialogState = {
  name: 'targetGroups.saveTemplate',
  url: '/save-template/{name:string}/{targetGroupId:string}/{panelistPoolContainsUrl:bool}/',
  params: {
    isModalState: true,
  },
  resolve: {
    modalInstance: ($transition$: Transition) => {
      'ngInject';

      const { name, targetGroupId, panelistPoolContainsUrl } = $transition$.params();
      return modalService.openSmall(
        SaveTemplateDialogSettingsFactory.create({
          newTemplate: {
            name,
            targetGroupId: Number(targetGroupId),
          },
          panelistPoolContainsUrl: Boolean(panelistPoolContainsUrl),
        })
      );
    },
  },
  onEnter,
  onExit,
};
