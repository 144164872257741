import { Draft } from 'immer';
import { Census } from '../../models/active-target-group.model';
import {
  RegionCensus,
  GenderCensus,
  AgeCensus,
  ProfilingCensus,
} from '../../../../common/http-services/census-response.model';

export const mutators = {
  setCensus:
    (genders?: GenderCensus, ageSpans?: AgeCensus, regions?: RegionCensus, profiling?: ProfilingCensus[]) =>
    (state: Draft<Census>) => {
      state.gender = genders ?? state.gender;
      state.ageSpan = ageSpans ?? state.ageSpan;
      state.region = regions ?? state.region;
      state.profiling = profiling ?? state.profiling;
    },
};
