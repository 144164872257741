import { filter, flatten, includes, isEmpty, isEqual, map, uniq } from 'lodash-es';
import { Currency } from '../common/http-services/pricing.httpservice';
import { TargetGroupModel } from '../common/models/target-group.model';

import { isUndefinedOrNull } from '../helpers';
import { PanelistPoolSource } from '../common/enums';
import { Money } from '../common/pricing/price-models';

export class UpfrontPriceRequest {
  existingProjectId: number | null;
  items: UpfrontTargetGroupPriceRequest[];
  categoryIds: number[];
  filterOnPii: boolean;
  requireWebcam: boolean;
  displayCurrency?: Currency;
}

export class SetEffectiveCpiRequest {
  projectId: number;
  cpiValue: number;
  currencyCode: string;
}

export class UpdateEffectiveCpiForQuotaRequest {
  quotaId: number;
  cpi: Money;
}
export class SetEffectiveCpiForSupplyQuotasRequest {
  projectId: number;
  quotaUpdates: UpdateEffectiveCpiForQuotaRequest[];
}

export class UpfrontQuotaPriceRequest {
  quotaId: number;
  panelIds: number[];
  wantedNumberOfCompletes: number;
  keys: readonly string[];
  customCpi?: number;
}

export class UpfrontTargetGroupPriceRequest {
  targetGroupId: number;
  countryId: number;
  panelIds: number[];
  adHocSupplierId: number | null;
  wantedNumberOfCompletes: number;
  estimatedIncidenceRate: number;
  estimatedLengthOfInterview: number;
  panelistPoolSource: PanelistPoolSource;
  fixedIncentive?: {
    amount?: number;
    currencyCode?: string;
  };
  quotas: UpfrontQuotaPriceRequest[];
  customCpi?: number;
  useCustomCpi: boolean;

  constructor(targetGroupModel: TargetGroupModel) {
    if (!targetGroupModel) throw new Error('you must supply `targetGroupModel`');

    const adHocSupplierId =
      targetGroupModel.panels.adHocSupplier !== null ? targetGroupModel.panels.adHocSupplier.id : null;

    this.targetGroupId = targetGroupModel.id;
    this.countryId = targetGroupModel.basicSettings.countryId;
    this.panelIds = targetGroupModel.panels.selectedIds;
    this.adHocSupplierId = adHocSupplierId;
    this.wantedNumberOfCompletes = targetGroupModel.basicSettings.numberOfCompletes;
    this.estimatedIncidenceRate = targetGroupModel.basicSettings.estimatedIncidenceRate;
    this.estimatedLengthOfInterview = targetGroupModel.basicSettings.estimatedLengthOfInterview;
    this.panelistPoolSource = targetGroupModel.panels.panelistPool.source || PanelistPoolSource.None;
    this.customCpi = targetGroupModel.panels.useCustomCpi ? targetGroupModel.panels.customCpi : undefined;
    this.useCustomCpi = targetGroupModel.panels.useCustomCpi;
    if (
      !isEmpty(targetGroupModel.incentives) &&
      !isEmpty(targetGroupModel.incentives.fixedIncentive) &&
      !isUndefinedOrNull(targetGroupModel.incentives.fixedIncentive.amount) &&
      !isEmpty(targetGroupModel.panels.ownPanelCurrency)
    ) {
      this.fixedIncentive = {
        amount: targetGroupModel.incentives.fixedIncentive.amount,
        currencyCode: targetGroupModel.panels.ownPanelCurrency,
      };
    }

    this.quotas = map(targetGroupModel.quotas.quotas, (q): UpfrontQuotaPriceRequest => {
      const buckets = filter(targetGroupModel.quotas.buckets, (b) => includes(q.buckets, b.id));
      const panelIds = uniq(flatten(map(buckets, (b) => b.key.supply.panels)));
      const supplyGroup = q.keys.includes('supply')
        ? targetGroupModel.panels.supplyMix?.supplyGroups.find((g) => isEqual(g.panelIds, panelIds))
        : null;
      return {
        quotaId: q.matchId,
        panelIds,
        wantedNumberOfCompletes: q.wantedCompletes,
        keys: q.keys,
        customCpi: supplyGroup?.useCustomCpi ? q.customCpi : undefined,
      };
    });
  }
}
