import { Draft } from 'immer';
import {
  head,
  includes,
  remove,
  isEqual,
  map,
  uniqBy,
  find,
  uniqWith,
  reduce,
  flatten,
  intersection,
  some,
  indexOf,
  pull,
  uniq,
  isNumber,
} from 'lodash-es';
import { Dictionary, difference, flatMap } from 'lodash';
import { QuotaOptions, TargetGender } from '../../../../common/enums';
import {
  ActiveQuotasModel,
  Quota,
  QuotaKey,
  QuotaSection,
  QuotaKeyProfiling,
  createQuotaSection,
  createQuota,
} from '../../models/active-quotas.model';
import {
  parseQuestionIdFromProfilingQuotaKeys,
  isNonInterlocked,
  getCensusDistributedCompletesOrStarts,
  getCompletesOrStarts,
  sectionIsCompatibleWithCensus,
} from '../../../quotas/quotas-helper.functions';
import { Bucket, QuotaWithBuckets, BucketKey } from '../../../quotas/quota-bucket-builder';
import { DetailedSelectionItemQuestion } from '../../models/active-profiling.model';
import { normalize } from '../../../../common/normalize';
import { AgeSpan } from '../../models/active-target-group.model';
import { WeightingStrategy } from '../../../../common/models/quotas.model';
import { Feasibility } from '../../../../common/http-services/feasibility.response';
import { errorLogger } from '../../../../common/error-logger';
import { keys } from '../../../../helpers';
import { Constants } from '../../../../constants';

export const mutators = {
  createGenderQuotas: (
    option: QuotaOptions,
    completesDistribution: readonly number[],
    startsDistribution: readonly number[] | undefined,
    ignoreCompletes: boolean
  ) => {
    const [maleStartsDist, femaleStartsDist] = startsDistribution ?? [];
    const [maleCompletesDist, femaleCompletesDist] = completesDistribution;
    return (state: Draft<ActiveQuotasModel>) => {
      state.gender = createQuotaSection(
        ['gender'],
        option,
        [
          createGenderQuota(TargetGender.Male, maleCompletesDist, maleStartsDist),
          createGenderQuota(TargetGender.Female, femaleCompletesDist, femaleStartsDist),
        ],
        false,
        ignoreCompletes
      );
    };
  },

  clearGenderQuotas: () => (state: Draft<ActiveQuotasModel>) => {
    state.gender.option = QuotaOptions.None;
    state.gender.items = [];
    state.gender.usePanelDistribution = false;
    state.gender.ignoreCompletes = false;
  },

  clearAgeQuotas: () => (state: Draft<ActiveQuotasModel>) => {
    state.ageSpan.option = QuotaOptions.None;
    state.ageSpan.items = [];
    state.ageSpan.usePanelDistribution = false;
    state.ageSpan.ignoreCompletes = false;
  },

  createAgeQuotas: (
    option: QuotaOptions,
    ageSpans: readonly AgeSpan[],
    completesDistribution: readonly number[],
    startsDistribution: readonly number[] | undefined,
    ignoreCompletes: boolean,
    extrapolatedAgeCensus = { hasExtrapolatedAgeCensus: false, maxAgeInCensus: null as number }
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.extrapolatedAgeCensus = extrapolatedAgeCensus;
      state.ageSpan = createQuotaSection(
        ['ageSpan'],
        option,
        ageSpans.map((ageSpan, index) =>
          createAgeQuota(
            ageSpan,
            completesDistribution[index],
            startsDistribution !== undefined ? startsDistribution[index] : undefined
          )
        ),
        false,
        ignoreCompletes
      );
    };
  },

  clearRegionQuotas:
    (interlocking = false) =>
    (state: Draft<ActiveQuotasModel>) => {
      state.region.option = QuotaOptions.None;
      state.region.items = [];
      state.region.usePanelDistribution = false;
      state.region.ignoreCompletes = false;
      if (!interlocking && state.quotaGroupNames?.region) {
        state.quotaGroupNames.region = {};
      }
    },

  createRegionQuotas: (
    option: QuotaOptions,
    regionIds: readonly number[][],
    completesDistribution: readonly number[],
    startsDistribution: readonly number[] | undefined,
    ignoreCompletes: boolean
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.region = createQuotaSection(
        ['region'],
        option,
        regionIds.map((regionId, index) =>
          createRegionQuota(
            regionId,
            completesDistribution[index],
            startsDistribution !== undefined ? startsDistribution[index] : undefined
          )
        ),
        false,
        ignoreCompletes
      );
    };
  },

  clearProfilingQuotas:
    (questionId: number, interlocking = false) =>
    (state: Draft<ActiveQuotasModel>) => {
      const section = getProfilingSection(state, questionId);
      section.option = QuotaOptions.None;
      section.items = [];
      section.usePanelDistribution = false;
      section.ignoreCompletes = false;
      if (!interlocking && state.quotaGroupNames?.profiling) {
        removeProfilingQuotaGroupNames(state, [questionId]);
      }
    },

  createProfilingQuotas: (
    option: QuotaOptions,
    questionId: number,
    variableIds: readonly number[][],
    completesDistribution: readonly number[],
    startsDistribution: readonly number[] | undefined
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      const section = getProfilingSection(state, questionId);
      section.option = option;
      section.items = variableIds.map((variableIds, index) =>
        createProfilingQuota(
          variableIds.map((variableId) => ({ questionId, variableId })),
          completesDistribution[index],
          startsDistribution !== undefined ? startsDistribution[index] : undefined
        )
      );
    };
  },

  createProfilingQuotaSection: (questionId: number, usePanelDistribution = false, ignoreCompletes = false) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.profiling.push(
        createQuotaSection(
          [`profiling:question${questionId}`],
          QuotaOptions.None,
          [],
          usePanelDistribution,
          ignoreCompletes
        )
      );
    };
  },

  removeProfilingQuotaSections: (questionIds: readonly number[]) => {
    return (state: Draft<ActiveQuotasModel>) => {
      for (const questionId of questionIds) {
        const section = getProfilingSection(state, questionId);
        if (section) {
          remove(state.profiling, section);
        }
      }
      removeProfilingQuotaGroupNames(state, [...questionIds]);
    };
  },

  removeAllProfilingQuotaSections: () => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.profiling = [];
      if (state.quotaGroupNames?.profiling) {
        state.quotaGroupNames.profiling = {};
      }
    };
  },

  removeAllQuotas: (selectedQuestionIds: readonly number[]) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.usePercentages = false;

      state.gender.option = QuotaOptions.None;
      state.gender.items = [];
      state.gender.usePanelDistribution = false;
      state.gender.ignoreCompletes = false;

      state.ageSpan.option = QuotaOptions.None;
      state.ageSpan.items = [];
      state.ageSpan.usePanelDistribution = false;
      state.ageSpan.ignoreCompletes = false;

      state.region.option = QuotaOptions.None;
      state.region.items = [];
      state.region.usePanelDistribution = false;
      state.region.ignoreCompletes = false;

      state.profiling = state.profiling.filter((q) =>
        includes(selectedQuestionIds, parseQuestionIdFromProfilingQuotaKeys(q.keys))
      );
      for (const quotaSection of state.profiling) {
        quotaSection.option = QuotaOptions.None;
        quotaSection.items = [];
        quotaSection.usePanelDistribution = false;
        quotaSection.ignoreCompletes = false;
      }

      state.interlocked = [];
      state.quotaGroupNames = undefined;
    };
  },

  restoreGenderQuotas: (
    genderQuotas: readonly QuotaWithBuckets[],
    allBuckets: readonly Bucket[],
    usePanelDistribution: boolean,
    ignoreCompletes: boolean
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.gender = createQuotaSection(
        ['gender'],
        head(genderQuotas).quotaOption,
        genderQuotas.map((q) => {
          const genderValue = head(allBuckets.filter((b) => includes(q.buckets, b.id))).key.gender;
          return createGenderQuota(genderValue, q.wantedCompletes, q.wantedStarts);
        }),
        usePanelDistribution,
        ignoreCompletes
      );
    };
  },

  restoreAgeQuotas: (
    ageQuotas: readonly QuotaWithBuckets[],
    allBuckets: readonly Bucket[],
    usePanelDistribution: boolean,
    ignoreCompletes: boolean
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.ageSpan = createQuotaSection(
        ['ageSpan'],
        head(ageQuotas).quotaOption,
        ageQuotas.map((q) => {
          const ageValue = head(allBuckets.filter((b) => includes(q.buckets, b.id))).key.ageSpan;
          return createAgeQuota(ageValue, q.wantedCompletes, q.wantedStarts);
        }),
        usePanelDistribution,
        ignoreCompletes
      );
    };
  },

  restoreRegionQuotas: (
    regionQuotas: readonly QuotaWithBuckets[],
    allBuckets: readonly Bucket[],
    usePanelDistribution: boolean,
    ignoreCompletes: boolean
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.region = createQuotaSection(
        ['region'],
        head(regionQuotas).quotaOption,
        regionQuotas.map((q) => {
          const regionValue = head(allBuckets.filter((b) => includes(q.buckets, b.id))).key.region;
          return createRegionQuota(regionValue, q.wantedCompletes, q.wantedStarts);
        }),
        usePanelDistribution,
        ignoreCompletes
      );
    };
  },

  restoreProfilingQuotas: (
    selectedQuestions: readonly DetailedSelectionItemQuestion[],
    profilingQuotas: readonly QuotaWithBuckets[],
    allBuckets: readonly Bucket[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.profiling = selectedQuestions.map((question) => {
        const key = `profiling:question${question.id}`;
        const questionQuotas = profilingQuotas.filter((quota) => head(quota.keys) === key);
        const option = questionQuotas.length ? head(questionQuotas).quotaOption : QuotaOptions.None;

        let censusCompletes: { value: number; identifier?: any }[];
        let censusStarts: { value: number; identifier?: any }[];
        if (option === QuotaOptions.Completes) {
          censusCompletes = getCensusDistributedCompletesOrStarts('profiling', 'completes', undefined, question.id);
          censusStarts = getCensusDistributedCompletesOrStarts('profiling', 'starts', undefined, question.id);
        }
        const quotas = questionQuotas.map((q) => {
          const variables = head(allBuckets.filter((b) => includes(q.buckets, b.id))).key.profiling.filter(
            (variableId) =>
              includes(
                question.variables.map((v) => v.id),
                variableId
              )
          );

          const hasSameValues = (a: number[], b: number[]) => {
            return a.length === b.length && difference(a, b).length === 0;
          };

          return createProfilingQuota(
            variables.map((variableId) => ({ questionId: question.id, variableId })),
            option === QuotaOptions.Completes
              ? censusCompletes.find((c) => hasSameValues(c.identifier, variables)).value
              : q.wantedCompletes,
            option === QuotaOptions.Completes
              ? censusStarts?.find((c) => hasSameValues(c.identifier, variables)).value
              : q.wantedStarts
          );
        });

        const usePanelDistribution = some(sectionsUsingPanelDistribution, (s) => isEqual(s, [key]));
        const ignoreCompletes = some(sectionsUsingIgnoreCompletes, (s) => isEqual(s, [key]));
        return createQuotaSection([key], option, quotas, usePanelDistribution, ignoreCompletes);
      });
    };
  },

  restoreInterlockedQuotas: (
    interlockedQuotas: Dictionary<readonly QuotaWithBuckets[]>,
    allBuckets: readonly Bucket[],
    selectedQuestions: readonly DetailedSelectionItemQuestion[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ) => {
    return (state: Draft<ActiveQuotasModel>) => {
      state.interlocked = map(interlockedQuotas, (quotas) => {
        const {
          keys: [...mutableKeys],
          quotaOption,
        } = head(quotas);

        if (includes(mutableKeys, 'profiling')) {
          handleOldInterlockedProfilingSetup(mutableKeys, [...selectedQuestions]);
        }

        const usePanelDistribution = some(sectionsUsingPanelDistribution, (s) => isEqual(s, mutableKeys));
        const ignoreCompletes = some(sectionsUsingIgnoreCompletes, (s) => isEqual(s, mutableKeys));
        return createQuotaSection(
          mutableKeys,
          quotaOption,
          quotas.map((quota) => {
            const quotaBucketKeys = allBuckets.filter((b) => includes(quota.buckets, b.id)).map((b) => b.key);

            const firstBucketKey = head(quotaBucketKeys);

            const profilingCommonToAllQuotaBuckets = quotaBucketKeys.reduce(
              (acc, bucketKey) => {
                acc = intersection(acc, bucketKey.profiling);
                return acc;
              },
              [...firstBucketKey.profiling]
            );

            const key: QuotaKey = {
              gender: genderIsSameInAllBuckets(quotaBucketKeys) ? firstBucketKey.gender ?? undefined : undefined,
              ageSpan: ageSpanIsSameInAllBuckets(quotaBucketKeys) ? firstBucketKey.ageSpan ?? undefined : undefined,
              region: regionIsSameInAllBuckets(quotaBucketKeys) ? firstBucketKey.region : [],
              profiling: profilingCommonToAllQuotaBuckets.length
                ? profilingCommonToAllQuotaBuckets.map((variableId) =>
                    getProfilingInfoFromVariable(selectedQuestions, variableId)
                  )
                : [],
            };

            return createQuota(
              {
                gender: key.gender,
                ageSpan: key.ageSpan,
                region: key.region,
                profiling: key.profiling,
              },
              quota.wantedCompletes,
              quota.wantedStarts
            );
          }),
          usePanelDistribution,
          ignoreCompletes
        );
      });
    };
  },

  removeInterlockedSection: (keys: readonly string[]) => {
    return (state: Draft<ActiveQuotasModel>) => {
      const interlockedSection = getInterlockedSection(state, keys);
      removeQuotaGroupNamesInInterlocking(state, [...keys]);
      remove(state.interlocked, interlockedSection);
    };
  },

  removeDependentInterlockedSection: (key: string) => {
    return (state: Draft<ActiveQuotasModel>) => {
      const interlockedSection = getDependentInterlockedSection(state, key);
      if (interlockedSection !== undefined) {
        removeQuotaGroupNamesInInterlocking(state, [...interlockedSection.keys]);
        remove(state.interlocked, interlockedSection);
      }
    };
  },

  interlockSections:
    (
      keysToInterlock: readonly (readonly string[])[],
      numberOfCompletes: number,
      numberOfStarts: number | undefined = undefined
    ) =>
    (state: Draft<ActiveQuotasModel>) => {
      const interlockedSection = reduce(
        keysToInterlock,
        (result, keys) => {
          const quotaSection = getQuotaSection(state, keys);

          let quotas = flatten(
            quotaSection.items.map((q1) => {
              if (!result.items.length) {
                return createQuota(q1.key, q1.completes, q1.starts);
              }

              return result.items.map((q2) => {
                return createQuota(
                  mergeQuotaKeys(q2.key, q1.key),
                  q1.completes * q2.completes,
                  numberOfStarts !== undefined ? q1.starts * q2.starts : undefined
                );
              });
            })
          );

          quotas = normalize(getCompletesOrStarts(state.usePercentages, numberOfCompletes), quotas, 'completes');
          if (numberOfStarts !== undefined) {
            quotas = normalize(getCompletesOrStarts(state.usePercentages, numberOfStarts), quotas, 'starts');
          }

          return createQuotaSection([...result.keys, ...quotaSection.keys], QuotaOptions.Custom, quotas);
        },
        createQuotaSection([])
      );

      state.interlocked.push(interlockedSection);
    },

  groupRegionQuotas:
    (quotaHashesToGroup: readonly string[], name: string | undefined) => (state: Draft<ActiveQuotasModel>) => {
      const quotasToGroup = state.region.items.filter((q) => includes(quotaHashesToGroup, q.hash));
      const firstGroupQuotaIndex = indexOf(
        state.region.items.map((q) => q.hash),
        quotaHashesToGroup[0]
      );
      const regions: number[] = [];
      let completes = 0;
      let starts = 0;
      const hasStarts = quotasToGroup.every((q) => q.starts !== undefined);
      for (const quota of quotasToGroup) {
        regions.push(...quota.key.region);
        completes += quota.completes;
        if (hasStarts) {
          starts += quota.starts;
        }
        remove(state.region.items, (q) => q.hash === quota.hash);
      }

      state.region.option = QuotaOptions.Custom;
      const newQuota = createRegionQuota(regions.sort(), completes, hasStarts ? starts : undefined);
      state.region.items.splice(firstGroupQuotaIndex, 0, newQuota);
      if (name) {
        nameRegionGroup(state, newQuota.hash, name);
      }
    },

  groupProfilingQuotas:
    (quotaHashesToGroup: readonly string[], key: string, name: string | undefined) =>
    (state: Draft<ActiveQuotasModel>) => {
      const quotaSection = getQuotaSection(state, [key]);
      const quotasToGroup = quotaSection.items.filter((q) => includes(quotaHashesToGroup, q.hash));
      const firstGroupQuotaIndex = indexOf(
        quotaSection.items.map((q) => q.hash),
        quotaHashesToGroup[0]
      );
      const profiling: QuotaKeyProfiling = [];
      let completes = 0;
      let starts = 0;
      const hasStarts = quotasToGroup.every((q) => q.starts !== undefined);
      for (const quota of quotasToGroup) {
        profiling.push(...quota.key.profiling);
        completes += quota.completes;
        if (hasStarts) {
          starts += quota.starts;
        }
        remove(quotaSection.items, (q) => q.hash === quota.hash);
      }

      const newQuota = createProfilingQuota(profiling, completes, hasStarts ? starts : undefined);
      quotaSection.items.splice(firstGroupQuotaIndex, 0, newQuota);
      if (name) {
        nameProfilingGroup(
          state,
          newQuota.key.profiling.map((p) => p.variableId),
          name
        );
      }
    },

  toggleUsePercentages:
    (totalWantedCompletes: number, totalWantedStarts: number | undefined) => (state: Draft<ActiveQuotasModel>) => {
      state.usePercentages = !state.usePercentages;
      normalizeQuotaCompletesOrStarts(
        state,
        getCompletesOrStarts(state.usePercentages, totalWantedCompletes),
        'completes'
      );
      if (totalWantedStarts !== undefined) {
        normalizeQuotaCompletesOrStarts(state, getCompletesOrStarts(state.usePercentages, totalWantedStarts), 'starts');
      }
    },

  setWeightingStrategy: (strategy: WeightingStrategy) => (state: Draft<ActiveQuotasModel>) => {
    state.weightingStrategy = strategy;
  },

  rescaleQuotaCompletes: (numberOfCompletes: number, regionIds?: number[][]) => (state: Draft<ActiveQuotasModel>) => {
    rescaleCompletesOrStarts(state, numberOfCompletes, 'completes', regionIds);
  },

  rescaleQuotaStarts: (numberOfStarts: number, regionIds?: number[][]) => (state: Draft<ActiveQuotasModel>) => {
    rescaleCompletesOrStarts(state, numberOfStarts, 'starts', regionIds);
  },

  setQuotaCompletes: (hash: string, wantedCompletes: number) => (state: Draft<ActiveQuotasModel>) => {
    const section = getSectionFromQuotaHash(state, hash);
    if (section === undefined) return;

    const quota = findQuota(hash, section);
    section.option = QuotaOptions.Custom;
    section.usePanelDistribution = false;

    if (quota !== undefined) {
      quota.completes = wantedCompletes;
    }
  },

  setQuotaStarts: (hash: string, wantedStarts: number) => (state: Draft<ActiveQuotasModel>) => {
    const section = getSectionFromQuotaHash(state, hash);
    if (section === undefined) return;

    const quota = findQuota(hash, section);
    section.option = QuotaOptions.Custom;
    section.usePanelDistribution = false;

    if (quota !== undefined) {
      quota.starts = wantedStarts;
    }
  },

  initQuotaStarts: () => (state: Draft<ActiveQuotasModel>) => {
    for (const item of getQuotaItems(state)) {
      item.starts = 0;
    }
  },

  toggleUsePanelDistribution:
    (keys: readonly string[], numberOfCompletes: number, numberOfStarts: number | undefined, usePercentages: boolean) =>
    (state: Draft<ActiveQuotasModel>) => {
      const section = getQuotaSection(state, keys);
      section.option = QuotaOptions.Custom;
      section.usePanelDistribution = !section.usePanelDistribution;
      const usingQuotasOnStarts = numberOfStarts !== undefined;

      if (!section.usePanelDistribution) {
        if (usingQuotasOnStarts) {
          for (const quota of section.items) {
            quota.completes = 100;
            quota.starts = 100;
          }
          section.items = normalize(
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            section.items,
            'completes'
          );
          section.items = normalize(getCompletesOrStarts(usePercentages, numberOfStarts), section.items, 'starts');
        } else {
          for (const quota of section.items) {
            quota.completes = 100;
          }
          section.items = normalize(
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            section.items,
            'completes'
          );
        }
      }
    },

  ignoreCompletes: (keys: readonly string[], ignoreCompletes: boolean) => (state: Draft<ActiveQuotasModel>) => {
    const section = getQuotaSection(state, keys);
    section.ignoreCompletes = ignoreCompletes;
  },

  clearAllIgnoreCompletes: () => (state: Draft<ActiveQuotasModel>) => {
    state.ageSpan.ignoreCompletes = false;
    state.gender.ignoreCompletes = false;
    state.region.ignoreCompletes = false;
    state.profiling = state.profiling.map((ps) => ({ ...ps, ignoreCompletes: false }));
    state.interlocked = state.interlocked.map((is) => ({ ...is, ignoreCompletes: false }));
  },

  rescaleCompletesAndStartsAccordingToPanelDistribution:
    (
      sectionKeys: readonly (readonly string[])[],
      feasibility: Feasibility,
      matchIds: { [hash: string]: number },
      numberOfCompletes: number,
      numberOfStarts: number | undefined = undefined
    ) =>
    (state: Draft<ActiveQuotasModel>) => {
      const hasStarts = numberOfStarts !== undefined;
      for (const keys of sectionKeys) {
        const section = getQuotaSection(state, keys);
        if (!section.usePanelDistribution) continue;

        const quotaFeasibilities = feasibility.quotas.filter((q) => isEqual(q.keys, keys));

        let hasChanged = false;
        for (const quota of section.items) {
          const matchId = matchIds[quota.hash];
          if (matchId === undefined) continue;

          const quotaFeasibility = find(quotaFeasibilities, (q) => q.matchId === matchId);
          if (quotaFeasibility === undefined) continue;

          const possibleCompletes = quotaFeasibility.buckets.reduce(
            (acc, bucketId) => acc + find(feasibility.buckets, (b) => b.id === bucketId).count,
            0
          );

          if (quota.completes === possibleCompletes) continue;

          quota.completes = possibleCompletes;
          if (hasStarts) {
            quota.starts = possibleCompletes;
          }
          hasChanged = true;
        }

        if (hasChanged) {
          section.items = normalize(
            getCompletesOrStarts(state.usePercentages, numberOfCompletes),
            section.items,
            'completes'
          );
          if (hasStarts) {
            section.items = normalize(
              getCompletesOrStarts(state.usePercentages, numberOfStarts),
              section.items,
              'starts'
            );
          }
        }
      }
    },

  rescaleCompletesAccordingToStartsDistribution:
    (sectionKeys: readonly (readonly string[])[], numberOfCompletes: number) => (state: Draft<ActiveQuotasModel>) => {
      for (const keys of sectionKeys) {
        const section = getQuotaSection(state, keys);
        if (!section.ignoreCompletes) continue;

        let hasChanged = false;
        for (const quota of section.items) {
          if (!isNumber(quota.starts)) {
            errorLogger.error('tried to rescale completes according to starts but starts is not a number');
            throw new Error('cannot rescale completes according to starts when starts is not a number');
          }
          if (quota.completes !== quota.starts) {
            hasChanged = true;
          }
          quota.completes = quota.starts;
        }

        if (hasChanged) {
          section.items = normalize(
            getCompletesOrStarts(state.usePercentages, numberOfCompletes),
            section.items,
            'completes'
          );
        }
      }
    },

  toggleOffPanelDistributionForAllQuotas: (numberOfCompletes: number) => (state: Draft<ActiveQuotasModel>) => {
    const sections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];

    for (const section of sections) {
      if (!section.usePanelDistribution) continue;

      section.usePanelDistribution = false;

      for (const quota of section.items) {
        quota.completes = 100;
      }
      section.items = normalize(
        getCompletesOrStarts(state.usePercentages, numberOfCompletes),
        section.items,
        'completes'
      );
    }
  },
};

function removeQuotaGroupNamesInInterlocking(state: Draft<ActiveQuotasModel>, keys: string[]) {
  const questionIds = keys
    .filter((k) => k.startsWith('profiling'))
    .map((pk) => parseQuestionIdFromProfilingQuotaKeys([pk]));
  if (questionIds.length) {
    removeProfilingQuotaGroupNames(state, questionIds);
  }

  if (keys.includes('region') && state.quotaGroupNames?.region) {
    state.quotaGroupNames.region = {};
  }
}

function removeProfilingQuotaGroupNames(state: Draft<ActiveQuotasModel>, questionIds: number[]) {
  if (!state.quotaGroupNames?.profiling) return;
  const names = questionIds.map((id) => Constants.profilingQuotaGroupPrefix(id));
  if (state.quotaGroupNames.profiling) {
    const existingKeys = keys(state.quotaGroupNames.profiling);
    const keysToRemove = existingKeys.filter((key) => {
      const name = state.quotaGroupNames.profiling[key];
      const result = names.some((n) => name.includes(n));
      return result;
    });

    for (const key of keysToRemove) {
      delete state.quotaGroupNames.profiling[key];
    }
  }
}

function nameRegionGroup(state: Draft<ActiveQuotasModel>, quotaHash: string, name: string) {
  const quotaToName = [...state.interlocked.flatMap((p) => p.items), ...state.region.items].find(
    (q) => q.hash === quotaHash
  );
  const quotaGroupNames = state.quotaGroupNames ?? { region: {}, profiling: {} };
  quotaGroupNames.region[JSON.stringify(quotaToName.key.region)] = name;
  state.quotaGroupNames = quotaGroupNames;
}

function nameProfilingGroup(state: Draft<ActiveQuotasModel>, variableIds: number[], name: string) {
  const quotaGroupNames = state.quotaGroupNames ?? { region: {}, profiling: {} };
  quotaGroupNames.profiling[JSON.stringify(variableIds)] = name;
  state.quotaGroupNames = quotaGroupNames;
}

function rescaleCompletesOrStarts(
  state: Draft<ActiveQuotasModel>,
  numberOfCompletesOrStarts: number,
  property: 'completes' | 'starts',
  selectedRegionIds?: number[][]
) {
  const quotaSections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];
  const count = getCompletesOrStarts(state.usePercentages, numberOfCompletesOrStarts);

  for (const quotaSection of quotaSections.filter((q) => q.option !== QuotaOptions.None)) {
    if (!quotaSection.items.length) continue;
    if (quotaSection.option === QuotaOptions.Custom || quotaSection.keys.length > 1) {
      quotaSection.items = normalize(count, quotaSection.items, property);
      continue;
    }

    if (quotaSection.option === QuotaOptions.Completes && sectionIsCompatibleWithCensus(quotaSection)) {
      const quotaType = quotaSection.keys[0].startsWith('profiling') ? 'profiling' : quotaSection.keys[0];
      const distributedCounts = getCensusDistributedCompletesOrStarts(
        quotaType as 'gender' | 'ageSpan' | 'region' | 'profiling',
        property,
        quotaType === 'region' ? selectedRegionIds : undefined,
        quotaType === 'profiling' ? parseQuestionIdFromProfilingQuotaKeys(quotaSection.keys) : undefined
      )?.map((c) => c.value);

      distributedCounts.forEach((count, index) => {
        quotaSection.items[index][property] = count;
      });
    }
  }
}

function getSectionFromQuotaHash(state: Draft<ActiveQuotasModel>, quotaHash: string): Draft<QuotaSection> | undefined {
  const quotaSections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];

  for (const section of quotaSections) {
    if (section.items.find((q) => q.hash === quotaHash)) return section;
  }

  return undefined;
}

function normalizeQuotaCompletesOrStarts(
  state: Draft<ActiveQuotasModel>,
  numberOfCompletesOrStarts: number,
  property: 'completes' | 'starts'
) {
  const quotaSections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];
  const count = getCompletesOrStarts(state.usePercentages, numberOfCompletesOrStarts);
  for (const quotaSection of quotaSections) {
    quotaSection.items = normalize(count, quotaSection.items, property);
  }
}

function regionIsSameInAllBuckets(quotaBucketKeys: readonly BucketKey[]) {
  return uniqWith(quotaBucketKeys, (a, b) => isEqual([...a.region].sort(), [...b.region].sort())).length === 1;
}

function ageSpanIsSameInAllBuckets(quotaBucketKeys: readonly BucketKey[]) {
  return uniqWith(quotaBucketKeys, (a, b) => isEqual(a.ageSpan, b.ageSpan)).length === 1;
}

function genderIsSameInAllBuckets(quotaBucketKeys: readonly BucketKey[]) {
  return uniqBy(quotaBucketKeys, (k) => k.gender).length === 1;
}

function getProfilingSection(state: Draft<ActiveQuotasModel>, questionId: number): Draft<QuotaSection> {
  return state.profiling.find((p) => p.keys[0] === `profiling:question${questionId}`);
}

function getInterlockedSection(state: Draft<ActiveQuotasModel>, keys: readonly string[]): Draft<QuotaSection> {
  return state.interlocked.find((i) => isEqual([...i.keys].sort(), [...keys].sort()));
}

export function getDependentInterlockedSection(
  state: Draft<ActiveQuotasModel>,
  key: string
): Draft<QuotaSection> | undefined {
  return state.interlocked.find((s) => includes(s.keys, key));
}

function findQuota(hash: string, ...sections: readonly QuotaSection[]): Draft<Quota> {
  for (const section of sections) {
    const match = section.items.find((q) => q.hash === hash);
    if (match) return match;
  }

  return undefined;
}

export function createGenderQuota(
  gender: TargetGender,
  numberOfCompletes: number,
  numberOfStarts: number = undefined
): Quota {
  const key: QuotaKey = {
    gender,
    ageSpan: undefined,
    region: [],
    profiling: [],
  };

  return createQuota(key, numberOfCompletes, numberOfStarts);
}

export function createAgeQuota(
  ageSpan: AgeSpan,
  completesRawOrPercent: number,
  startsRawOrPercent: number = undefined
): Quota {
  const key: QuotaKey = {
    gender: undefined,
    ageSpan,
    region: [],
    profiling: [],
  };

  return createQuota(key, completesRawOrPercent, startsRawOrPercent);
}

export function createRegionQuota(
  regions: readonly number[],
  completesRawOrPercent: number,
  startsRawOrPercent: number = undefined
): Quota {
  const key: QuotaKey = {
    gender: undefined,
    ageSpan: undefined,
    region: [...regions],
    profiling: [],
  };

  return createQuota(key, completesRawOrPercent, startsRawOrPercent);
}

export function createProfilingQuota(
  profiling: QuotaKeyProfiling,
  completesRawOrPercent: number,
  startsRawOrPercent: number = undefined
): Quota {
  const key: QuotaKey = {
    gender: undefined,
    ageSpan: undefined,
    region: [],
    profiling,
  };

  return createQuota(key, completesRawOrPercent, startsRawOrPercent);
}

export function createSupplyQuota(
  name: string,
  panelIds: readonly number[],
  numberOfCompletes: number,
  numberOfStarts: number = undefined,
  customCpi?: number
): Quota {
  const key: QuotaKey = {
    supply: {
      name,
      panels: [...panelIds],
    },
    gender: undefined,
    ageSpan: undefined,
    region: [],
    profiling: [],
  };

  return createQuota(key, numberOfCompletes, numberOfStarts, customCpi);
}

function getProfilingInfoFromVariable(selectedQuestions: readonly DetailedSelectionItemQuestion[], variableId: number) {
  const { id: questionId } = find(selectedQuestions, (question) =>
    includes(
      question.variables.map((v) => v.id),
      variableId
    )
  );
  return {
    questionId,
    variableId,
  };
}

function getQuotaItems(state: ActiveQuotasModel): Draft<Quota>[] {
  const sections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];
  return flatMap(sections, (s) => s.items);
}

export function mergeQuotaKeys(key1: QuotaKey, key2: QuotaKey): QuotaKey {
  if (key1.gender !== undefined && key2.gender !== undefined)
    throw Error('bad QuotaKey: trying to interlock 2 gender quotas');
  if (key1.ageSpan !== undefined && key2.ageSpan !== undefined)
    throw Error('bad QuotaKey: trying to interlock 2 ageSpan quotas');
  if (key1.region.length && key2.region.length) throw Error('bad QuotaKey: trying to interlock 2 region quotas');
  if (
    key1.profiling.length &&
    key2.profiling.length &&
    head(key1.profiling).questionId === head(key2.profiling).questionId
  )
    throw Error('bad QuotaKey: trying to interlock the same question');

  return {
    gender: key1.gender || key2.gender,
    ageSpan: key1.ageSpan || key2.ageSpan,
    region: key1.region.length ? [...key1.region] : [...key2.region],
    profiling: [...[...key1.profiling], ...[...key2.profiling]].sort(),
  };
}

export function getQuotaSection(state: Draft<ActiveQuotasModel>, keys: readonly string[]): Draft<QuotaSection> {
  if (isNonInterlocked('gender', keys)) {
    return state.gender;
  }
  if (isNonInterlocked('ageSpan', keys)) {
    return state.ageSpan;
  }
  if (isNonInterlocked('region', keys)) {
    return state.region;
  }

  const sections = [...state.profiling, ...state.interlocked];

  const section = sections.find((s) => isEqual([...s.keys].sort(), [...keys].sort()));
  if (section === undefined) throw Error('could not find quota section. should not happen');
  return section;
}

function handleOldInterlockedProfilingSetup(
  keys: string[],
  interlockedQuestions: DetailedSelectionItemQuestion[]
): void {
  pull(keys, 'profiling');
  keys.push(...uniq(interlockedQuestions).map((q) => `profiling:question${q.id}`));
}
