import { isEmpty } from 'lodash-es';
import { ActiveProfilingModel } from '../../target-groups/active-target-group/models/active-profiling.model';

export interface ProfilingModel {
  readonly categoryId: number;
  readonly panelSpecificProfiling: boolean;
  readonly selectedVariables: {
    [id: number]: { readonly categoryId: number };
  };
}

export function createProfilingModel(): ProfilingModel {
  return {
    categoryId: undefined,
    panelSpecificProfiling: false,
    selectedVariables: {},
  };
}

export function createProfilingModelFromActive(model: ActiveProfilingModel): ProfilingModel {
  return {
    categoryId: !isEmpty(model.activeCategory) ? model.activeCategory.id : undefined,
    panelSpecificProfiling: model.panelSpecificProfiling,
    selectedVariables: model.selectedVariables,
  };
}
