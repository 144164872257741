import { inlineHelpService } from './inline-help.service';
import { html } from '../../helpers';

const selector = 'inlineHelpToggle';

const template = html`
  <i
    class="help-toggle fas fa-info-circle"
    ng-class="{ 'active': $ctrl.visibleFor[$ctrl.source] }"
    ng-click="$ctrl.toggle($ctrl.source); $event.stopPropagation();"
  >
  </i>
`;

interface Bindings {
  source: '@' | string;
}

const bindings: Bindings = {
  source: '@',
};

export class InlineHelpToggleComponent implements Bindings {
  static componentName = selector;

  source: string;
  visibleFor: { [id: string]: boolean };

  $onInit(): void {
    this.visibleFor = inlineHelpService.visibleFor;
  }

  toggle(source: string): void {
    inlineHelpService.toggle(source);
  }
}

export const ngInlineHelpToggleComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: InlineHelpToggleComponent,
  },
};
