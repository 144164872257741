import { ActiveTargetGroupModel } from './models/active-target-group.model';
import { mutators } from './store/active-target-group.mutators';
import { logProduce } from '../../common/immer-wrapper';
import { SupplyStateModel } from './models/supply-state.model';
import { featureFlipper } from '../../common/feature-flipper';
import { incentivesSubactions } from './store/incentives/incentives.subactions';
import { SupplySource } from '../../common/enums';
import { quotasSubactions } from './store/quotas/quotas.subactions';
import { WeightingStrategy } from '../../common/models/quotas.model';
import { profilingSubactions } from './store/profiling/profiling.subactions';
import { shouldShowTogglePanelProfiling } from '../supply/supply-helper.functions';

export class ActiveTargetGroupSubactions {
  partialReset(state: ActiveTargetGroupModel): ActiveTargetGroupModel {
    return logProduce(state, mutators.partialReset());
  }

  async persistSupplyState(
    supplyState: SupplyStateModel,
    model: ActiveTargetGroupModel
  ): Promise<ActiveTargetGroupModel> {
    const resetPanelSpecificProfiling =
      model.profiling.panelSpecificProfiling &&
      supplyState.supply.selectedIds.length &&
      supplyState.supply.selectedIds[0] !== model.supply.selectedIds[0];

    function adjustIncentives(tempModel: SupplyStateModel) {
      if (!featureFlipper.isEnabled('editIncentive')) return model.incentives;
      const combinedIncentives = { ...model.incentives, ...tempModel.incentives };
      if (tempModel.supply.ownPanelCurrency === undefined) {
        return incentivesSubactions.useDefaultIncentive(combinedIncentives);
      }
      return combinedIncentives;
    }

    const combinedBasicSettings = {
      ...model.basicSettings,
      estimatedIncidenceRate: supplyState.basicSettings.estimatedIr,
      estimatedLengthOfInterview: supplyState.basicSettings.estimatedLoi,
      useFixedLoi: supplyState.basicSettings.useFixedLoi,
      validationResult: supplyState.basicSettings.validationResult,
    };

    let adjustedQuotas = model.quotas;
    let adjustedProfiling = model.profiling;

    if (supplyState.supply.supplySource === SupplySource.AdHoc) {
      const newQuotasState = quotasSubactions.setWeightingStrategy(model.quotas, WeightingStrategy.EvenDistribution);
      adjustedQuotas = quotasSubactions.toggleOffPanelDistributionForAllQuotas(
        newQuotasState,
        model.basicSettings.numberOfCompletes
      );
    }

    if (
      supplyState.supply.supplyMix.ignoreCompletes &&
      model.quotas.weightingStrategy === WeightingStrategy.RimWeighting
    ) {
      adjustedQuotas = quotasSubactions.setWeightingStrategy(adjustedQuotas, WeightingStrategy.EvenDistribution);
    }

    // discard
    const [newProfilingState, hasUpdatedProfiling] = await profilingSubactions.handlePanelProfilingAsync(
      model.profiling,
      model.basicSettings.countryId,
      shouldShowTogglePanelProfiling(supplyState.supply),
      resetPanelSpecificProfiling
    );

    adjustedProfiling = newProfilingState;
    if (hasUpdatedProfiling) {
      adjustedQuotas = quotasSubactions.removeAllProfilingQuotaSections(model.quotas);
    }

    return logProduce(model, (draft) => {
      draft.basicSettings = combinedBasicSettings;
      draft.incentives = adjustIncentives(supplyState);
      draft.profiling = adjustedProfiling;
      draft.quotas = adjustedQuotas;
      draft.supply = supplyState.supply;
      draft.surveyMetadata = supplyState.surveyMetadata;
    });
  }
}

export const activeTargetGroupSubactions = new ActiveTargetGroupSubactions();
