import './fade-in-on-change.directive.scss';
import { IAttributes, IAugmentedJQuery, IDirective, IPromise, IScope } from 'angular';
import { $timeout } from '../../ngimport';

const directiveName = 'fadeInOnChange';
const defaultDelay = 200;

export function fadeInOnChange(): IDirective {
  return {
    link: (scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) => {
      const delay = attrs.delay || defaultDelay;
      let timeout: IPromise<void>;

      scope.$watch(attrs[directiveName], (newValue, oldValue) => {
        // eslint-disable-next-line eqeqeq
        if (newValue == oldValue) return;
        element.addClass('has-changed');
        if (timeout) {
          $timeout.cancel(timeout);
          timeout = null;
        }
        timeout = $timeout(() => {
          element.removeClass('has-changed');
        }, delay);
      });
    },
  };
}

export const ngFadeInOnChangeDirective = {
  [directiveName]: fadeInOnChange,
};
