import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { db } from './db';
import { RuntimeSettings } from '../runtime-settings';
import { StorageFactory } from './storage.factory';
import { Product } from './enums';
import { userService } from './user.service';

export class Session {
  get uuid(): string {
    if (this.uuidString === undefined) {
      this.uuidString = this.storage.getItem(this.sessionStorageKey);
    }
    return this.uuidString;
  }

  onCreated = {
    subscribe: (func: Function): void => {
      this.onCreatedCallback.push(func);
    },
  };

  onExpired = {
    subscribe: (func: Function): void => {
      this.onExpiredCallback.push(func);
    },
  };

  private storage: Storage;
  private sessionStorageKey = '_uuid';
  private productStorageKey = '_product';
  private onCreatedCallback: Function[] = [];
  private onExpiredCallback: Function[] = [];
  private uuidString: string;

  constructor() {
    this.storage = StorageFactory.getStorage();
  }

  get product(): Product {
    const defaultProduct = userService.isAccessProUser() ? Product.AccessPro : Product.Access;
    const storedProduct = this.storage.getItem(this.productStorageKey) as Product;

    if (storedProduct === Product.AccessPro && !userService.isAccessProUser()) return defaultProduct;

    return storedProduct || defaultProduct;
  }

  setProduct(product: Product) {
    this.storage.setItem(this.productStorageKey, product);
  }

  async init(): Promise<void> {
    const sessionId = this.storage.getItem(this.sessionStorageKey);
    if (sessionId) {
      await this.saveSessionInfoInDb(sessionId);
      return;
    }

    const uuidValue = uuid();
    this.storage.setItem(this.sessionStorageKey, uuidValue);
    await this.saveSessionInfoInDb(uuidValue);

    for (const f of this.onCreatedCallback) {
      f();
    }

    this.uuidString = uuidValue;
  }

  async clear(): Promise<void> {
    this.storage.removeItem(this.sessionStorageKey);
    if (db !== undefined) {
      if (db.existingProject !== undefined) {
        await db.existingProject.clear();
      }

      if (db.sessionInfo !== undefined) {
        await db.sessionInfo.clear();
        this.uuidString = undefined;
      }

      if (db.targetGroups !== undefined) {
        await db.targetGroups.clear();
      }
    }
  }

  private saveSessionInfoInDb(uuidV4: string): Promise<string> {
    return db.sessionInfo.put({
      uuid: uuidV4,
      buildNumber: RuntimeSettings.buildNumber,
      createdAt: dayjs().toISOString(),
    });
  }
}

export const session = new Session();
