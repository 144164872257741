import { RegionsModel } from '../../../common/models/regions.model';
import { RegionTypeKey } from '../../../common/enums';

export interface Region {
  readonly id: number;
  readonly mapId: number;
  readonly name: string;
  readonly isSelected?: boolean;
}

export interface RegionType {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly displayNotice: string | null;
  readonly type: RegionTypeKey;
  readonly regions?: Region[] | string[]; // string[] in case of postal codes :/
}

export interface ActiveRegionsModel {
  readonly detailedSelection: Region[];
  readonly regionTypes: RegionType[];
  readonly mainRegionsAutomaticallySelected: boolean;
  readonly selectedIds: number[];
  readonly postalCodes: any[];
  readonly selectedRegionType: {
    id: number;
    type: RegionTypeKey;
  };
}

export function createActiveRegionsModel(): ActiveRegionsModel {
  return {
    detailedSelection: [],
    regionTypes: [],
    mainRegionsAutomaticallySelected: false,
    selectedIds: [],
    postalCodes: [],
    selectedRegionType: {} as { id: number; type: RegionTypeKey },
  };
}

export function createActiveRegionsModelFrom(regions: RegionsModel): ActiveRegionsModel {
  return {
    detailedSelection: [],
    regionTypes: [],
    mainRegionsAutomaticallySelected: regions.mainRegionsAutomaticallySelected,
    selectedIds: regions.selectedIds,
    postalCodes: regions.postalCodes,
    selectedRegionType: regions.regionTypeId
      ? { id: regions.regionTypeId, type: undefined }
      : ({} as { id: number; type: any }),
  };
}

export function isPostalCodes(detailedSelection: Region[]): boolean {
  if (detailedSelection.length === 1 && detailedSelection[0].id === -1) return true;
  return false;
}
