import { includes, isEmpty, head, flatMap } from 'lodash-es';
import { ActiveTargetGroupModel, Census } from '../models/active-target-group.model';
import { InitiatingAction, SupplySource } from '../../../common/enums';
import { PaneName, targetGroupAccordionService } from '../../target-group-accordion.service';
import { censusSubactions } from './census/census.subactions';
import { regionsSubactions } from './regions/regions.subactions';
import { profilingSubactions } from './profiling/profiling.subactions';
import { supplySubactions } from './supply/supply.subactions';
import { TargetGroupModel } from '../../../common/models/target-group.model';
import { targetGroupChannel } from '../../channels/target-group-channel';
import { incentivesSubactions } from './incentives/incentives.subactions';
import {
  cachePanelistPoolIdsIfPresent,
  panelistPoolStorageService,
} from '../../../common/panelist-pool-storage.service';
import { $log, $q, $stateParams } from '../../../ngimport';
import { countryRestrictionsDisabledService } from '../../../common/country-restrictions-disabled.service';
import { ActiveProfilingModel, createActiveProfilingModelFrom } from '../models/active-profiling.model';
import { ActiveIncentivesModel, createActiveIncentivesModelFrom } from '../models/active-incentives.model';
import { ListenerComponent } from '../../../common/channels/channel';
import { mutators } from './active-target-group.mutators';
import { BasicSettingsActions, commitBasicSettings } from './basic-settings/basic-settings.actions';
import { RegionsActions, commitRegions } from './regions/regions.actions';
import { ExcludeProjectsActions, commitExcludeProjects } from './exclude-projects/exclude-projects.actions';
import { commitProfiling, ProfilingActions } from './profiling/profiling.actions';
import { commitSupply, SupplyActions } from './supply/supply.actions';
import { commitIncentives, IncentivesActions } from './incentives/incentives.actions';
import { createActiveBasicSettingsModelFrom, ActiveBasicSettingsModel } from '../models/active-basic-settings.model';
import { createActiveRegionsModelFrom, ActiveRegionsModel } from '../models/active-regions.model';
import { createActiveSupplyModelFrom, ActiveSupplyModel } from '../models/active-supply.model';
import {
  createActiveExcludeProjectsModelFrom,
  ActiveExcludeProjectsModel,
} from '../models/active-exclude-projects.model';
import { countryService } from '../../country.service';
import { BasicSettingsValidationResult, basicSettingsValidator } from '../../basic-settings/basic-settings-validator';
import { commitCensus } from './census/census.actions';
import {
  hasAnySupplyGroups,
  shouldShowTogglePanelProfiling,
  supplyIsValidForFixedIncentive,
  supplyIsValidForSupplierMetadata,
} from '../../supply/supply-helper.functions';
import { commitQuotas, QuotasActions } from './quotas/quotas.actions';
import { ActiveQuotasModel, createActiveQuotasModelFrom } from '../models/active-quotas.model';
import { quotasSubactions } from './quotas/quotas.subactions';
import {
  areInterlocked,
  getNumberOfBucketsFromQuotas,
  isInterlockedWithQuestions,
} from '../../quotas/quotas-helper.functions';
import { logProduce } from '../../../common/immer-wrapper';
import { SurveyMetadataModel } from '../../../common/models/survey-metadata.model';
import { SurveyMetadataActions, commitSurveyMetadata } from './survey-metadata/survey-metadata.actions';

export interface CommitFuncs {
  basicSettings: (basicSettingsState: ActiveBasicSettingsModel) => BasicSettingsValidationResult;
  regions: (regionsState: ActiveRegionsModel) => void;
  quotas: (quotasState: ActiveQuotasModel) => void;
  supply: (supplyState: ActiveSupplyModel) => void;
  profiling: (profilingState: ActiveProfilingModel) => void;
  incentives: (incentivesState: ActiveIncentivesModel) => void;
  excludeProjects: (excludeProjectsState: ActiveExcludeProjectsModel) => void;
  census: (censusState: Census) => void;
  surveyMetadata: (surveyMetadataState: SurveyMetadataModel) => void;
}

type UpdateFunc = (commit: CommitFuncs, done: (preventFeasibility?: boolean) => void) => void;

export class ActiveTargetGroupStore extends ListenerComponent {
  readonly basicSettings = new BasicSettingsActions(this);
  readonly regions = new RegionsActions(this);
  readonly excludeProjects = new ExcludeProjectsActions(this);
  readonly profiling = new ProfilingActions(this);
  readonly quotas = new QuotasActions(this);
  readonly supply = new SupplyActions(this);
  readonly incentives = new IncentivesActions(this);
  readonly surveyMetadata = new SurveyMetadataActions(this);

  readonly commitFuncs = {
    basicSettings: commitBasicSettings(() => this.overrideIrAndLoiRestrictionsFunc()),
    regions: commitRegions(),
    quotas: commitQuotas(),
    supply: commitSupply(),
    profiling: commitProfiling(),
    incentives: commitIncentives(),
    excludeProjects: commitExcludeProjects(),
    census: commitCensus(),
    surveyMetadata: commitSurveyMetadata(),
  };

  private state = {} as ActiveTargetGroupModel;

  get model(): ActiveTargetGroupModel {
    return this.state;
  }
  get showTogglePanelProfiling(): boolean {
    return shouldShowTogglePanelProfiling(this.state.supply);
  }

  get supplyCompatibleWithFeasibilityBasedDistribution(): boolean {
    if (!this.state.supply) return false;

    const { supplySource } = this.state.supply;
    return supplySource !== SupplySource.AdHoc;
  }

  init(persistedModel: TargetGroupModel) {
    const quotas = createActiveQuotasModelFrom(persistedModel.quotas);
    const supply = createActiveSupplyModelFrom(persistedModel.panels);

    this.state = {
      identity: {
        id: persistedModel.id,
        name: persistedModel.name,
      },
      ui: {
        activePane: persistedModel.activePane,
      },
      census: {} as Census,
      basicSettings: createActiveBasicSettingsModelFrom(persistedModel.basicSettings, countryService.countries),
      regions: createActiveRegionsModelFrom(persistedModel.regions),
      profiling: createActiveProfilingModelFrom(persistedModel.profiling),
      quotas,
      supply,
      bucketsCount: getNumberOfBucketsFromQuotas(quotas, supply),
      excludeProjects: createActiveExcludeProjectsModelFrom(persistedModel.excludeProjects),
      incentives: createActiveIncentivesModelFrom(persistedModel.incentives),
      deprecation: persistedModel.deprecation,
      surveyMetadata: persistedModel.surveyMetadata,
    };
  }

  clearActivePane(): void {
    this.state.ui = logProduce(this.state.ui, (draft) => {
      draft.activePane = '';
    });

    targetGroupChannel.model.activePane.updated.dispatch(this.state.ui.activePane);
  }

  setActivePane(paneIndex: number): void {
    const pane = targetGroupAccordionService.panes[paneIndex] || { index: -1, name: '' };
    this.state.ui = logProduce(this.state.ui, (draft) => {
      draft.activePane = pane.name;
    });

    targetGroupChannel.model.activePane.updated.dispatch(this.state.ui.activePane);
  }

  managePanes(): void {
    const signalPaneActivated = (paneName: PaneName) => targetGroupChannel.model.activePane.updated.dispatch(paneName);

    if (
      !basicSettingsValidator.isValid(this.state.basicSettings.validationResult, this.state.basicSettings.useStarts)
    ) {
      this.state.ui = logProduce(this.state.ui, (draft) => {
        draft.activePane = 'basic-settings';
      });

      targetGroupAccordionService.resetPanes();
      signalPaneActivated(this.state.ui.activePane);
    } else {
      const disabledPanes = [] as PaneName[];
      if (isEmpty(this.state.regions.regionTypes)) {
        disabledPanes.push('regions');
      }

      if (!supplyIsValidForFixedIncentive(this.state.supply)) {
        disabledPanes.push('incentives');
      }

      if (!supplyIsValidForSupplierMetadata(this.state.supply)) {
        disabledPanes.push('supplier-metadata');
      }

      if (!isEmpty(this.state.ui.activePane)) {
        signalPaneActivated(this.state.ui.activePane);
      } else {
        signalPaneActivated('');
      }

      targetGroupAccordionService.enableAllPanesExcept(...disabledPanes);
    }
  }

  showActive(persistedActiveTg: TargetGroupModel) {
    this.update((commit, done) => {
      activeTargetGroupStore.init(persistedActiveTg);

      commit.surveyMetadata(persistedActiveTg.surveyMetadata);
      commit.basicSettings(this.state.basicSettings);
      commit.excludeProjects(this.state.excludeProjects);

      const newState = incentivesSubactions.validateModel(this.state.incentives);
      commit.incentives(newState);

      targetGroupChannel.model.restoring.dispatch();

      this.restore(persistedActiveTg, this.state, commit, done)
        .then(() => {
          this.triggerFeasibility();
        })
        .finally(() => {
          targetGroupChannel.model.restored.dispatch();
        });
    });
  }

  renameTargetGroup(newName: string): void {
    this.state.identity = logProduce(this.state.identity, mutators.renameTargetGroup(newName));
  }

  static httpCallsForCountry(state: ActiveTargetGroupModel, commit: CommitFuncs) {
    const panelId =
      state.profiling.panelSpecificProfiling && state.supply.selectedIds.length === 1
        ? head(state.supply.selectedIds)
        : undefined;

    return $q.all({
      census: censusSubactions
        .createCensusPromise(
          state.census,
          state.basicSettings.countryId,
          state.basicSettings.minAge,
          state.basicSettings.maxAge
        )
        .then((newState) => {
          commit.census(newState);
        }),
      regionTypes: regionsSubactions
        .createRegionTypesPromise(state.regions, state.basicSettings.countryId)
        .then((newState) => {
          commit.regions(newState);
        }),
      supplySources: supplySubactions.createSupplySourcesPromise(state.supply, state.basicSettings).then((newState) => {
        commit.supply(newState);
      }),
      categories: profilingSubactions
        .createCategoriesPromise(state.profiling, state.basicSettings.countryId, panelId)
        .then((newState) => {
          commit.profiling(newState);
        }),
    });
  }

  // TAOBAO: Only call update functions from actions, this is more like an action helper.
  handleRegionsIfInterlockedWith(key: string, questionIds?: number[]): void {
    // BAIDU: This is to handle changes that affect multiple profiling questions (all if no questionIds provided)
    if (key === 'profiling') {
      if (isInterlockedWithQuestions('region', this.state.quotas.interlocked, questionIds)) {
        const newRegionsState = regionsSubactions.discardRegionType(this.state.regions);
        this.commitFuncs.regions(newRegionsState);
      }
    } else if (
      areInterlocked(key, 'region', this.state.quotas.interlocked) &&
      this.state.regions.mainRegionsAutomaticallySelected
    ) {
      const newRegionsState = regionsSubactions.discardRegionType(this.state.regions);
      this.commitFuncs.regions(newRegionsState);
    }
  }

  // TAOBAO: move to right place, figure out what to do with the accordionService
  handleFixedIncentive(state: ActiveIncentivesModel, ownPanelCurrency: string): ActiveIncentivesModel {
    let newState = state;
    if (ownPanelCurrency === undefined) {
      newState = incentivesSubactions.useDefaultIncentive(state);
    }
    this.managePanes();
    return newState;
  }

  signalUpdateStarted() {
    targetGroupChannel.model.updating.dispatch();
  }

  publishUpdate(preventFeasibility?: boolean) {
    targetGroupChannel.model.updated.dispatch({
      model: this.state,
      withRequestFeasibility: !preventFeasibility,
    });
  }

  private restore(
    persistedActiveTg: TargetGroupModel,
    state: ActiveTargetGroupModel,
    commit: CommitFuncs,
    done: (preventFeasibility: boolean) => void
  ) {
    if (includes([InitiatingAction.LoadDraft, InitiatingAction.ReuseTargetGroups], $stateParams.initiatingAction)) {
      panelistPoolStorageService.delete(state.supply.panelistPool.sourceUrl);
      cachePanelistPoolIdsIfPresent(persistedActiveTg, panelistPoolStorageService);
    }

    return ActiveTargetGroupStore.httpCallsForCountry(state, commit)
      .then(() => {
        const { selectedRegionType } = state.regions;
        const selectedPanelId = head(state.supply.selectedIds);

        if (persistedActiveTg.basicSettings.countryId > 0) {
          const promises = {
            regions: regionsSubactions
              .createRegionsPromise(
                state.regions,
                state.basicSettings.countryId,
                isEmpty(selectedRegionType) ? undefined : selectedRegionType.id
              )
              .then((newState) => {
                commit.regions(newState);
              }),
            categoriesWithVariables: profilingSubactions
              .createCategoriesWithVariablesPromise(state.profiling, state.basicSettings.countryId, selectedPanelId)
              .then((newState) => {
                commit.profiling(newState);
              }),
            //Should we also set active category on restore?
          };

          return $q.all(promises).then(
            () => {
              try {
                const newState = quotasSubactions.restoreQuotas(
                  state.quotas,
                  state.census,
                  persistedActiveTg.quotas,
                  // IMPORTANT: the parameter `state` does not reflect any commited profiling data from `categoriesWithVariables`
                  // We will NOT get the latest commited data by using `state.profiling` here!!
                  flatMap(this.state.profiling.detailedSelection, (c) => c.questions),
                  state.basicSettings.numberOfCompletes,
                  state.basicSettings.numberOfStarts,
                  state.regions.selectedIds.map((id) => [id])
                );
                commit.quotas(newState);
              } catch (error) {
                $log.warn(error);
                targetGroupChannel.model.failedToRestore.dispatch();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
        // QUOTAS: can this ever happen? The old code looks like it never worked
        return $q.when().then(() => {
          // quotasSubactions.resetAllQuotas(state.quotas);
        });
      })
      .then(() => {
        if (state.supply.ownPanelCurrency !== undefined) {
          const newIncentivesState = incentivesSubactions.validateModel(
            state.incentives,
            state.supply.ownPanelCurrency
          );

          commit.incentives(newIncentivesState);
        } else {
          const newIncentivesState = incentivesSubactions.useDefaultIncentive(state.incentives);
          commit.incentives(newIncentivesState);
        }

        let supplyState = supplySubactions.setIgnoreCompletesForSupplyMix(
          this.state.supply,
          persistedActiveTg.panels?.supplyMix?.ignoreCompletes
        );
        supplyState = supplySubactions.validateModel(
          supplyState,
          this.state.profiling.panelSpecificProfiling,
          this.state.basicSettings.numberOfCompletes,
          this.state.basicSettings.numberOfStarts
        );

        commit.supply(supplyState);
        this.managePanes();
        done(true);
      });
  }

  private triggerFeasibility() {
    const origin = $stateParams.initiatingAction;
    switch (origin) {
      case InitiatingAction.ChangeActiveTargetGroup:
        if (!this.shouldRunFeasibility()) return;
        targetGroupChannel.feasibility.runActive.dispatch();
        break;
      case InitiatingAction.LoadTargetGroups:
      case InitiatingAction.LoadDraft:
      case InitiatingAction.ReuseTargetGroups:
      case InitiatingAction.AddNewTargetGroup:
      case InitiatingAction.DeleteTargetGroup:
      case InitiatingAction.DeleteAllTargetGroups:
      case InitiatingAction.ContinueWithUnsaved:
        targetGroupChannel.feasibility.runAll.dispatch();
        break;
      default:
        targetGroupChannel.feasibility.runActive.dispatch();
    }
  }

  private shouldRunFeasibility(): boolean {
    if (this.quotas.hasQuotas()) return true;
    if (hasAnySupplyGroups(this.state.supply)) return true;
    return false;
  }

  private overrideIrAndLoiRestrictionsFunc() {
    return countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(this.state.supply);
  }

  private update(updateFunc: UpdateFunc): void {
    updateFunc(this.commitFuncs, (preventFeasibility?: boolean) => this.publishUpdate(preventFeasibility));
  }
}

export const activeTargetGroupStore = new ActiveTargetGroupStore();
