import { Number, Static, String } from 'runtypes';

export const NonNegative = Number.withConstraint((x) => x >= 0);
export type NonNegative = Static<typeof NonNegative>;

export const AboveZero = Number.withConstraint((x) => x > 0);
export type AboveZero = Static<typeof AboveZero>;

export const NonEmptyString = String.withConstraint((x) => x.length > 0);
export type NonEmptyString = Static<typeof NonEmptyString>;

export const EmptyString = String.withConstraint((x) => x.length === 0);
export type EmptyString = Static<typeof EmptyString>;

export const MinusOne = Number.withConstraint((x) => x === -1);
export type MinusOne = Static<typeof MinusOne>;

export const Iso8601UtcString = String.withConstraint(
  (x) => x.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,7})?Z$/) !== null
);
export type Iso8601UtcString = Static<typeof Iso8601UtcString>;

export const GuidString = String.withConstraint(
  (x) => x.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) !== null
);
export type GuidString = Static<typeof GuidString>;

export const IsoLanguageName = String.withConstraint((x) => x.length === 2);
export type IsoLanguageName = Static<typeof IsoLanguageName>;

export const HttpUrl = String.withConstraint((x) => x.match(/^https?:\/\//) !== null);
export type HttpUrl = Static<typeof HttpUrl>;

export const HighlightedString = String;
// String that may contain <em> HTML-tags marking a section to be highlighted
export type HighlightedString = Static<typeof HighlightedString>;
