import { INgModelController, IScope, IAugmentedJQuery, IAttributes } from 'angular';
import { DirectiveUsingNgModelController } from './directive.types';

const directiveName = 'selectNgFiles';

export function selectNgFiles(): DirectiveUsingNgModelController {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (_scope: IScope, elm: IAugmentedJQuery, _attrs: IAttributes, ngModelCtrl: INgModelController) => {
      elm.bind('change', () => {
        const { files } = elm[0] as any;
        ngModelCtrl.$setViewValue(files);
      });
    },
  };
}

export const ngSelectNgFiles = {
  [directiveName]: selectNgFiles,
};
