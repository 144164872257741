import { DeveloperToolsDialogSettingsFactory } from './developer-tools-dialog-settings.factory';
import { modalService } from '../common/modal.service';
import { DeveloperToolsDialogResult } from './developer-tools-dialog.component';
import { $window } from '../ngimport';
import { html } from '../helpers';

const selector = 'openDeveloperToolsButtonComponent';

const template = html` <button class="ui-btn devtools-btn" ng-click="$ctrl.showDevTools()">Dev tools</button> `;

export class OpenDeveloperToolsButtonComponent {
  static componentName = selector;

  showDevTools() {
    modalService
      .openLarge(DeveloperToolsDialogSettingsFactory.create())
      .result.then((res: DeveloperToolsDialogResult) => {
        if (res.reload) {
          $window.location.reload();
        }
      });
  }
}

export const ngOpenDeveloperToolsButtonComponent = {
  [selector]: {
    template,
    controller: OpenDeveloperToolsButtonComponent,
  },
};
