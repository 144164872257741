import { IHttpPromise } from 'angular';
import { Array, Boolean, Literal, Null, Number, Optional, Record, Static, String, Union } from 'runtypes';
import { SetEffectiveCpiForSupplyQuotasRequest, UpfrontPriceRequest } from '../../target-groups/price-quote.request';
import { urlService } from './url.service';
import { AboveZero, NonEmptyString, NonNegative } from '../../custom-runtypes';
import {
  ProjectPriceResponse,
  CpiChangeHistoryItem,
  CpiChangeHistoryForQuota,
  MoneyResponse,
} from './project-price.response';
import { PanelistPoolSource } from '../enums';
import { $http } from '../../ngimport';

export interface CompanyRateCardRequest {
  countryId: number;
  numberOfCompletes: number;
  panelIds: number[];
  panelistPoolSource: PanelistPoolSource;
  displayCurrencyCode?: string;
}

export interface TgRateCardRequest {
  targetGroupId: number;
  numberOfCompletes: number;
  displayCurrencyCode: string;
}

export const RateCardCellResponse = Record({
  value: String,
  cellType: Union(Literal('label'), Literal('even'), Literal('odd')),
});
export type RateCardCellResponse = Static<typeof RateCardCellResponse>;

export const RateCardRowResponse = Record({
  cells: Array(RateCardCellResponse),
});
export type RateCardRowResponse = Static<typeof RateCardRowResponse>;

export const RateCardResponse = Record({
  currencyCode: NonEmptyString,
  isPrivate: Boolean,
  isInternal: Boolean,
  rows: Array(RateCardRowResponse),
});
export type RateCardResponse = Static<typeof RateCardResponse>;

export const BillingEntity = Record({
  id: AboveZero,
  name: NonEmptyString,
  currencyCode: NonEmptyString,
  isDefault: Boolean,
  companyId: AboveZero,
});
export type BillingEntity = Static<typeof BillingEntity>;

export const BillingInfoResponse = Record({
  address: String.Or(Null),
  email: String.Or(Null),
  ccEmails: Array(String).Or(Null),
  billingEntity: BillingEntity.Or(Null),
});
export type BillingInfoResponse = Static<typeof BillingInfoResponse>;

export const Currency = Record({
  currencyCode: String,
  currencyName: String,
  incentiveStep: Number,
  isValidForFixedIncentive: Boolean,
});
export type Currency = Static<typeof Currency>;

export const CurrenciesResponse = Record({
  items: Array(Currency),
});
export type CurrenciesResponse = Static<typeof CurrenciesResponse>;

export const InternalTargetGroupPrice = Record({
  id: AboveZero,
  cpi: MoneyResponse,
  price: MoneyResponse,
});
export type InternalTargetGroupPrice = Static<typeof InternalTargetGroupPrice>;

export const InternalUpFrontPrice = Record({
  price: MoneyResponse,
  priceTotal: MoneyResponse,
  targetGroups: Array(InternalTargetGroupPrice),
});
export type InternalUpFrontPrice = Static<typeof InternalUpFrontPrice>;

export const UpfrontQuotaPriceResponse = Record({
  id: AboveZero,
  cpi: MoneyResponse,
  price: MoneyResponse,
  canUseCustomCpi: Boolean,
  hasCustomCpi: Boolean,
  customCpiMin: Number,
  customCpiMax: Number,
});
export type UpfrontQuotaPriceResponse = Static<typeof UpfrontQuotaPriceResponse>;

export const UpfrontTargetGroupPriceResponse = Record({
  id: AboveZero,
  cpi: MoneyResponse,
  price: MoneyResponse,
  hasRateCard: Boolean,
  canUseCustomCpi: Boolean,
  hasCustomCpi: Boolean,
  customCpiMin: Number,
  customCpiMax: Number,
  hasVolumeDiscount: Boolean,
  quotaPrices: Array(UpfrontQuotaPriceResponse).Or(Null),
});
export type UpfrontTargetGroupPriceResponse = Static<typeof UpfrontTargetGroupPriceResponse>;

export const UpfrontPriceResponse = Record({
  price: MoneyResponse,
  discount: MoneyResponse,
  targetGroups: Array(UpfrontTargetGroupPriceResponse),
  volumeDiscountCompletes: NonNegative.Or(Null),
  customCpiClamped: Optional(Boolean),
  internalPrice: Optional(InternalUpFrontPrice.Or(Null)),
});
export type UpfrontPriceResponse = Static<typeof UpfrontPriceResponse>;

export class PricingHttpService {
  getRateCard(request: CompanyRateCardRequest): IHttpPromise<RateCardResponse> {
    return $http.validatingPost(RateCardResponse, urlService.getRateCard(), request);
  }
  getInternalRateCard(request: CompanyRateCardRequest): IHttpPromise<RateCardResponse> {
    return $http.validatingPost(RateCardResponse, urlService.getInternalRateCard(), request);
  }

  getTargetGroupRateCard(request: TgRateCardRequest): IHttpPromise<RateCardResponse> {
    return $http.validatingPost(RateCardResponse, urlService.getTargetGroupRateCard(), request);
  }

  getBillingInformation(projectId: number): IHttpPromise<BillingInfoResponse> {
    return $http.validatingGet(BillingInfoResponse, urlService.getBillingInformation(projectId));
  }

  getProjectPrice(projectId: number, fetchInvoice?: boolean): IHttpPromise<ProjectPriceResponse> {
    return $http.validatingGet(ProjectPriceResponse, urlService.getProjectPrice(projectId, !!fetchInvoice));
  }

  getUpfrontPrice(request: UpfrontPriceRequest): IHttpPromise<UpfrontPriceResponse> {
    return $http.validatingPost(UpfrontPriceResponse, urlService.postUpfrontPrice(), request, { cache: true });
  }

  getCurrencies(): IHttpPromise<CurrenciesResponse> {
    return $http.validatingGet(CurrenciesResponse, urlService.getCurrencies(), { cache: true });
  }

  getISOCurrencies(): IHttpPromise<CurrenciesResponse> {
    return $http.validatingGet(CurrenciesResponse, urlService.getISOCurrencies(), { cache: true });
  }

  getCurrency(currencyCode: string): IHttpPromise<Currency> {
    return $http.validatingGet(Currency, urlService.getCurrency(encodeURIComponent(currencyCode)));
  }

  setEffectiveCpi(
    projectId: number,
    targetGroupId: number,
    effectiveCpi: number,
    currencyCode: string
  ): IHttpPromise<ProjectPriceResponse> {
    return $http.validatingPut(ProjectPriceResponse, urlService.setEffectiveCpi(targetGroupId), {
      projectId,
      cpiValue: effectiveCpi,
      currencyCode,
    });
  }

  setEffectiveCpiForSupplyQuotas(
    targetGroupId: number,
    request: SetEffectiveCpiForSupplyQuotasRequest
  ): IHttpPromise<ProjectPriceResponse> {
    return $http.validatingPut(ProjectPriceResponse, urlService.setEffectiveCpiForSupplyQuotas(targetGroupId), request);
  }

  getCpiChangeHistory(projectId: number, targetGroupId: number): IHttpPromise<CpiChangeHistoryItem[]> {
    return $http.validatingGet(
      Array(CpiChangeHistoryItem),
      urlService.getEffectiveCpiChanges(projectId, targetGroupId)
    );
  }

  getCpiChangeHistoryForSupplyMix(
    projectId: number,
    targetGroupId: number,
    quotaIds: number[]
  ): IHttpPromise<CpiChangeHistoryForQuota[]> {
    return $http.validatingPut(
      Array(CpiChangeHistoryForQuota),
      urlService.getEffectiveCpiChangesForSupplyMix(projectId, targetGroupId),
      quotaIds
    );
  }

  getBillingEntitiesList(): IHttpPromise<BillingEntity[]> {
    return $http.validatingGet(Array(BillingEntity), urlService.getBillingEntities());
  }
}

export const pricingHttpService = new PricingHttpService();
