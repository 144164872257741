import { IPromise } from 'angular';
import { debounce, find, map } from 'lodash-es';
import { TargetGroupModel } from '../../common/models/target-group.model';
import { ProjectSettings } from '../../common/project-settings.service';
import { ProfilingDepthRequest } from './profiling-depth-request.model';
import { TargetGroupConstants } from '../target-group.constants';
import { targetGroupValidator } from '../target-group-validator';
import { targetGroupChannel } from '../channels/target-group-channel';
import { api } from '../../common/api';
import { countryRestrictionsDisabledService } from '../../common/country-restrictions-disabled.service';
import { ActiveProfilingModel } from '../active-target-group/models/active-profiling.model';

export interface ProfilingCategoryDepth {
  id: number;
  incompatibleSupply: boolean;
  questions: ProfilingQuestionDepth[];
}
export interface ProfilingQuestionDepth {
  id: number;
  depth: number;
  depthSelectedVariables: number;
}

export class ProfilingDepthService {
  throttledGetProfilingDepth: (
    persistedTg: TargetGroupModel,
    projectSettings: ProjectSettings,
    categoryId: number
  ) => IPromise<any>;

  constructor() {
    this.throttledGetProfilingDepth = debounce(
      this.getProfilingDepth,
      TargetGroupConstants.profilingDepthThrottleInterval
    );
  }

  depthUnavailable(profiling: ActiveProfilingModel, incompatibleSupply = false): void {
    const activeCategoryWithQuestions = find(profiling.categories, (c) => c.id === profiling.activeCategory.id);

    if (activeCategoryWithQuestions) {
      const response: ProfilingCategoryDepth = {
        id: activeCategoryWithQuestions.id,
        incompatibleSupply,
        questions: map(activeCategoryWithQuestions.questions, (q) => ({
          id: q.id,
          depth: NaN,
          depthSelectedVariables: NaN,
        })),
      };

      targetGroupChannel.depth.updated.dispatch(response);
    }
  }

  getProfilingDepth(
    persistedTg: TargetGroupModel,
    projectSettings: ProjectSettings,
    categoryId: number
  ): IPromise<void> {
    const depthRequest = new ProfilingDepthRequest(persistedTg);
    const data = {
      targetGroup: depthRequest,
      projectSettings,
    };

    return api.profiling.getCategoryDepth(categoryId, data).then((response) => {
      if (!response.wasCanceled) {
        targetGroupChannel.depth.updated.dispatch({
          ...response.result,
          incompatibleSupply: false,
        });
      }
    });
  }
}

export function canCallProfilingDepth(targetGroupModel: TargetGroupModel) {
  const noRestrictionsOnIrAndLoi = countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(
    targetGroupModel.panels
  );

  return (
    !targetGroupValidator.validateBasicSettings(targetGroupModel.basicSettings, noRestrictionsOnIrAndLoi).hasError() &&
    !targetGroupValidator.validateRegions(targetGroupModel.regions).hasError() &&
    !targetGroupValidator
      .validateSupply(
        targetGroupModel.panels,
        targetGroupModel.profiling.panelSpecificProfiling,
        targetGroupModel.basicSettings.numberOfCompletes,
        targetGroupModel.basicSettings.numberOfStarts
      )
      .hasError() &&
    !targetGroupValidator.validateExcludeProjects(targetGroupModel.excludeProjects).hasError()
  );
}

export const profilingDepthService = new ProfilingDepthService();
