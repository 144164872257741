import { IDeferred, IPromise, IRequestShortcutConfig, IHttpResponse } from 'angular';
import { Runtype } from 'runtypes';
import { $http, $q, $log } from '../../ngimport';

export interface PotentiallyCanceledResult<T> {
  wasCanceled: boolean;
  result: T;
}

export class CancelingHttpService {
  cancelers: { [p: string]: IDeferred<any> } = {};

  get<T>(url: string, config?: IRequestShortcutConfig): IPromise<PotentiallyCanceledResult<T>> {
    config = config || {};
    this.setUpCanceler(url, config);
    return $http
      .get(url, config)
      .then((res) => ({
        wasCanceled: false,
        result: res.data,
      }))
      .catch((res) => {
        if (!this.requestWasCanceled(res)) return $q.reject() as any;

        return {
          wasCanceled: true,
          result: null,
        };
      });
  }

  post<T>(
    url: string,
    data: { [p: string]: any },
    config?: IRequestShortcutConfig
  ): IPromise<PotentiallyCanceledResult<T>> {
    config = config || {};
    this.setUpCanceler(url, config);

    return $http
      .post(url, data, config)
      .then((res) => ({
        wasCanceled: false,
        result: res.data,
      }))
      .catch((res) => {
        if (!this.requestWasCanceled(res)) return $q.reject() as any;

        return {
          wasCanceled: true,
          result: null,
        };
      });
  }

  validatingPost<T>(
    TCreator: Runtype<T>,
    url: string,
    data: { [p: string]: any },
    config?: IRequestShortcutConfig
  ): IPromise<PotentiallyCanceledResult<T>> {
    config = config || {};
    this.setUpCanceler(url, config);

    return $http
      .validatingPost(TCreator, url, data, config)
      .then((res) => ({
        wasCanceled: false,
        result: res.data,
      }))
      .catch((err) => {
        if (!this.requestWasCanceled(err)) {
          $log.error(err);
          return $q.reject() as any;
        }

        return {
          wasCanceled: true,
          result: null,
        };
      });
  }

  private setUpCanceler(url: string, config: IRequestShortcutConfig): void {
    if (this.cancelers[url]) {
      this.cancelers[url].resolve();
    }

    this.cancelers[url] = $q.defer();
    config.timeout = this.cancelers[url].promise;
  }

  private requestWasCanceled<T>(res: IHttpResponse<T>): boolean {
    return res.status < 1;
  }
}

export const cancelingHttpService = new CancelingHttpService();
