import { head, flatMap } from 'lodash-es';
import { ActiveProfilingModel } from '../../models/active-profiling.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { profilingSubactions } from './profiling.subactions';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { quotasSubactions } from '../quotas/quotas.subactions';
import { stateDebugService } from '../../../../common/state-debug.service';

type ProfilingCommitFunc = (
  state: ActiveTargetGroupModel,
  commit: CommitFuncs,
  done: (preventFeasibility?: boolean) => void
) => void;

export const commitProfiling =
  () =>
  (profilingSettingsState: ActiveProfilingModel): void => {
    activeTargetGroupStore.model.profiling = profilingSettingsState;
    targetGroupChannel.model.profiling.updated.dispatch();
  };

export class ProfilingActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  setActiveCategory(categoryId: number) {
    this.update((state, commit, done) => {
      targetGroupChannel.model.profiling.activeCategoryChanging.dispatch();

      const { countryId } = state.basicSettings;
      const panelId = head(state.supply.selectedIds);

      profilingSubactions
        .setActiveCategoryWithQuestionsAsync(state.profiling, categoryId, countryId, panelId)
        .then((newState) => {
          commit.profiling(newState);
          done();
        });
    });
  }

  search(searchString: string, categoryId?: number) {
    this.update((state, commit, done) => {
      const { countryId } = state.basicSettings;
      const panelId = head(state.supply.selectedIds);
      profilingSubactions
        .searchQuestionsAndVariablesAsync(state.profiling, countryId, searchString, categoryId, panelId)
        .then((newState) => {
          commit.profiling(newState);
          targetGroupChannel.model.profiling.searchResultsUpdated.dispatch();
          done(true);
        });
    });
  }

  clearSearch() {
    this.update((state, commit, done) => {
      const newState = profilingSubactions.clearSearchResults(state.profiling);
      commit.profiling(newState);
      targetGroupChannel.model.profiling.searchResultsUpdated.dispatch();
      done(true);
    });
  }

  unsetActiveCategory() {
    this.update((state, commit, done) => {
      const newState = profilingSubactions.removeActiveCategory(state.profiling);
      commit.profiling(newState);
      done();
    });
  }

  getVariableSelectionState(variableId: number): boolean {
    return this.rootStore.model.profiling.selectedVariables[variableId] !== undefined;
  }

  toggleVariables(questionId: number, variableIds: number[], categoryId: number, toggle?: boolean) {
    this.update((state, commit, done) => {
      const newProfilingState = profilingSubactions.variableSelectionChanged(
        state.profiling,
        variableIds,
        categoryId,
        toggle
      );
      const [wasAdded, wasRemoved] = this.wasQuestionAddedOrRemoved(state.profiling, newProfilingState, questionId);

      commit.profiling(newProfilingState);
      this.rootStore.handleRegionsIfInterlockedWith(`profiling:question${questionId}`);

      if (wasAdded && wasRemoved) throw Error('question was both added and removed, which should not happen');
      let newQuotasState;
      if (wasAdded) {
        newQuotasState = quotasSubactions.createProfilingQuotaSection(state.quotas, questionId);
      } else if (wasRemoved) {
        newQuotasState = quotasSubactions.removeProfilingQuotaSections(state.quotas, questionId);
      } else {
        // TODO: when does this happen?
        newQuotasState = quotasSubactions.clearProfilingQuotas(state.quotas, questionId);
      }
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(
        newQuotasState,
        `profiling:question${questionId}`
      );

      commit.quotas(newQuotasState);
      done();
    });
  }

  discardVariables() {
    this.update((state, commit, done) => {
      const newProfilingState = profilingSubactions.removeVariableSelectionFromActiveCategory(state.profiling);
      commit.profiling(newProfilingState);
      const questionIds = this.getActiveCategoryQuestionIds(state);
      this.rootStore.handleRegionsIfInterlockedWith('profiling', questionIds);

      let newQuotasState = quotasSubactions.removeProfilingQuotaSections(state.quotas, ...questionIds);
      for (const questionId of questionIds) {
        newQuotasState = quotasSubactions.removeDependentInterlockedSection(
          newQuotasState,
          `profiling:question${questionId}`
        );
      }
      commit.quotas(newQuotasState);
      done();
    });
  }

  discardAllVariables() {
    this.update((state, commit, done) => {
      const selectedQuestionIds = flatMap(state.profiling.detailedSelection, (cat) => cat.questions).map((q) => q.id);
      const newProfilingState = profilingSubactions.clearAllCategories(state.profiling);
      commit.profiling(newProfilingState);
      this.rootStore.handleRegionsIfInterlockedWith('profiling');
      let newQuotasState = quotasSubactions.removeAllProfilingQuotaSections(state.quotas);

      for (const questionId of selectedQuestionIds) {
        newQuotasState = quotasSubactions.removeDependentInterlockedSection(
          newQuotasState,
          `profiling:question${questionId}`
        );
      }
      commit.quotas(newQuotasState);
      done();
    });
  }

  togglePanelProfiling(isEnabled: boolean) {
    this.update((state, commit, done) => {
      const { countryId } = state.basicSettings;

      const panelId = isEnabled ? head(state.supply.selectedIds) : undefined;

      const selectedQuestionIds = flatMap(state.profiling.detailedSelection, (cat) => cat.questions).map((q) => q.id);

      // TODO: this should be an actual toggle, not a setter, with a value
      profilingSubactions
        .togglePanelProfilingAsync(state.profiling, countryId, panelId, isEnabled)
        .then((newProfilingState) => {
          commit.profiling(newProfilingState);
          this.rootStore.handleRegionsIfInterlockedWith('profiling');
          let newQuotasState = quotasSubactions.removeAllProfilingQuotaSections(state.quotas);

          for (const questionId of selectedQuestionIds) {
            newQuotasState = quotasSubactions.removeDependentInterlockedSection(
              newQuotasState,
              `profiling:question${questionId}`
            );
          }
          commit.quotas(newQuotasState);
          done();
        });
    });
  }

  private wasQuestionAddedOrRemoved(
    oldState: ActiveProfilingModel,
    newState: ActiveProfilingModel,
    questionId: number
  ): [boolean, boolean] {
    const existsInOld =
      flatMap(oldState.detailedSelection, (s) => s.questions).find((q) => q.id === questionId) !== undefined;
    const existsInNew =
      flatMap(newState.detailedSelection, (s) => s.questions).find((q) => q.id === questionId) !== undefined;
    const wasAdded = existsInNew && !existsInOld;
    const wasRemoved = existsInOld && !existsInNew;
    return [wasAdded, wasRemoved];
  }

  private getActiveCategoryQuestionIds(state: ActiveTargetGroupModel): number[] {
    const activeCategory = state.profiling.categories.find((c) => c.id === state.profiling.activeCategory.id);
    return activeCategory.questions.map((q) => q.id);
  }

  private update(updateFunc: ProfilingCommitFunc): void {
    stateDebugService.logIfUnsafeUpdate('profiling');
    this.rootStore.signalUpdateStarted();

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, (preventFeasibility?: boolean) =>
      this.rootStore.publishUpdate(preventFeasibility)
    );
  }
}
