import { IDirective } from 'angular';
import { session } from '../session';
import { Product } from '../enums';

const directiveName = 'showForProduct';

export function showForProduct(): IDirective {
  return {
    link(scope, element, attrs) {
      const attr = attrs[directiveName];
      if (attr == null || attr === '') return;

      const allowedProduct = attr as Product;
      scope.$watch(
        () => session.product,
        () => {
          if (session.product !== allowedProduct) {
            element.remove();
          }
        }
      );
    },
  };
}

export const ngShowForProductDirective = {
  [directiveName]: showForProduct,
};
