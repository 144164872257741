import { ui } from 'angular';
import { InfoDialogComponentName } from './InfoDialog';

export interface InfoDialogResolve {
  title: string;
  text: string;
  okCaption?: string;
}

export class InfoDialogSettingsFactory {
  static create(resolve?: InfoDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'info-dialog',
      component: InfoDialogComponentName,
      resolve: {
        title: () => resolve?.title ?? 'Information',
        text: () => resolve?.text ?? '',
        okCaption: () => resolve?.okCaption ?? '',
      },
    };
  }
}
