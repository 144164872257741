import './project-filters.component.scss';
import { copy as ngCopy } from 'angular';
import { toString, isEqual } from 'lodash-es';
import { CustomDropdownOption } from '../../common/controls/custom-dropdown/custom-dropdown.component';
import { ProjectUserFilter, SortField } from '../../common/enums';
import { Iso8601UtcString } from '../../custom-runtypes';
import { analytics } from '../../common/analytics';
import { html } from '../../helpers';
import { userService } from '../../common/user.service';
import { StorageFactory } from '../../common/storage.factory';

const selector = 'projectFiltersComponent';

interface Bindings {
  resultCount: '<' | number;
  dateLabel: '<' | string;
  onFilterClicked: '&' | Function;
  options: '=' | ProjectListFilterOptions;
}

const bindings: Bindings = {
  resultCount: '<',
  dateLabel: '<',
  onFilterClicked: '&',
  options: '=',
};

export const userFilterStorageKey = 'projectListingUserFilter';

export const ownerOptions: CustomDropdownOption[] = [
  {
    value: ProjectUserFilter.Company,
    name: 'company projects',
  },
  {
    value: ProjectUserFilter.User,
    name: 'my projects',
  },
  {
    value: ProjectUserFilter.CreatedBy,
    name: 'created by me',
  },
  {
    value: ProjectUserFilter.ByContact,
    name: 'by contact reference',
  },
];
export class ProjectListFilterOptions {
  sortOptions: {
    field: SortField;
    descending: boolean;
  };
  fromDate: Iso8601UtcString;
  toDate: Iso8601UtcString;
  filterText: string;
  userFilter: ProjectUserFilter;
  status?: string;
  constructor(status?: string) {
    const storage = StorageFactory.getStorage();
    this.sortOptions = {
      field: SortField.StartDate,
      descending: true,
    };
    this.fromDate = null;
    this.toDate = null;
    this.filterText = null;
    this.userFilter = userService.showCompanyProjects
      ? ((storage.getItem(userFilterStorageKey) || ProjectUserFilter.User) as ProjectUserFilter)
      : ProjectUserFilter.CreatedBy;
    this.status = status || null;
  }
}

const template = html`
  <div class="project-filters" on-enter-pressed="$ctrl.filterProjects()">
    <div class="project-filters__form ui-controls-group">
      <div class="project-filters__filter-input project-filters__separated ui-controls-group__last-element">
        <input
          class="ui-control"
          placeholder="Search for Name or Id"
          data-testid="drafts-search-input"
          ng-model="$ctrl.options.filterText"
          ng-change="$ctrl.checkInputLength()"
          ng-focus="$ctrl.checkInputLength()"
        />
      </div>
      <div
        class="project-filters__dates project-filters__separated ui-controls-group__first-element ui-controls-group__last-element"
      >
        <div class="ui-control">
          <input
            class="calendar"
            name="startDate"
            bs-datepicker
            ng-model="$ctrl.options.fromDate"
            ng-change="$ctrl.keepFocusOnFromDate()"
            ng-model-options="{}"
            autocomplete="off"
            placeholder="From ({{$ctrl.dateLabel}})"
            max-date="{{$ctrl.options.toDate}}"
          />
          <input
            class="project-filters__focus-keeper"
            tabindex="-1"
            focus-on="$ctrl.focusOnFromDate"
            ng-blur="$ctrl.focusOnFromDate = false;"
          />
          <span class="text-center">-</span>
          <input
            class="calendar"
            name="endDate"
            bs-datepicker
            ng-model="$ctrl.options.toDate"
            ng-change="$ctrl.keepFocusOnToDate()"
            ng-model-options="{}"
            autocomplete="off"
            placeholder="To"
            min-date="{{$ctrl.options.fromDate}}"
          />
          <input
            class="project-filters__focus-keeper"
            tabindex="-1"
            focus-on="$ctrl.focusOnToDate"
            ng-blur="$ctrl.focusOnToDate = false;"
          />
        </div>
      </div>
      <div class="project-filters__owner-select" ng-if="$ctrl.ownerFilteringEnabled">
        <custom-dropdown-component
          default-selection="$ctrl.defaultOptions.userFilter"
          options="$ctrl.ownerOptions"
          on-option-select="$ctrl.onOwnerSelect"
        >
        </custom-dropdown-component>
      </div>
      <div class="project-filters__confirm project-filters__separated">
        <button
          class="ui-btn full-btn primary-btn"
          ng-disabled="!$ctrl.allowFiltering"
          data-testid="drafts-do-filtering-button"
          title="Enter at least 3 characters to search"
          ng-click="$ctrl.filterProjects()"
        >
          Filter
        </button>
      </div>
      <div class="project-filters__clear">
        <button class="ui-btn full-btn default-btn" ng-click="$ctrl.resetToDefault()">Clear</button>
      </div>
    </div>
    <div class="project-filters__total_count">
      <span ng-if="$ctrl.resultCount > 1"> {{$ctrl.resultCount | thousandSeparatorFormat }} items found </span>
      <span ng-if="$ctrl.resultCount == 1"> 1 item found </span>
      <span ng-if="$ctrl.resultCount == 0"> No items found</span>
    </div>
  </div>
`;
export class ProjectFiltersComponent implements Bindings {
  static componentName = selector;
  storage: Storage;
  resultCount: number;
  dateLabel: string;
  onFilterClicked: Function;
  ownerOptions = ownerOptions;
  searchByOptions: CustomDropdownOption[] = [
    {
      value: false,
      name: 'Filter by name',
    },
    {
      value: true,
      name: 'Filter by Id',
    },
  ];

  options: ProjectListFilterOptions;
  defaultOptions: ProjectListFilterOptions;
  focusOnFromDate: boolean;
  focusOnToDate: boolean;

  get ownerFilteringEnabled() {
    return userService.showCompanyProjects;
  }

  get allowFiltering() {
    return !this.options.filterText || toString(this.options.filterText).length > 2;
  }

  $onInit(): void {
    this.storage = StorageFactory.getStorage();
    this.defaultOptions = ngCopy(this.options);
  }

  filterProjects(): void {
    if (!this.allowFiltering) return;
    this.sendAnalyticsEvents();
    this.onFilterClicked();
    this.storage.setItem(userFilterStorageKey, this.options.userFilter);
  }

  resetToDefault() {
    this.options.filterText = this.defaultOptions.filterText;
    this.options.fromDate = this.defaultOptions.fromDate;
    this.options.toDate = this.defaultOptions.toDate;
    this.options.status = this.defaultOptions.status;
    this.options.sortOptions = this.defaultOptions.sortOptions;
    this.options.userFilter = this.defaultOptions.userFilter;
    analytics.projectList.filter.clear();
    this.filterProjects();
  }

  resetFilterText() {
    this.options.filterText = '';
  }

  keepFocusOnFromDate() {
    this.focusOnFromDate = true;
  }

  keepFocusOnToDate() {
    this.focusOnToDate = true;
  }

  onOwnerSelect = (_: { option: CustomDropdownOption }) => {
    this.options.userFilter = _.option.value;
  };

  private sendAnalyticsEvents() {
    analytics.projectList.filter.select(this.getOptionNameForAnalytics(this.options.userFilter, ownerOptions));
    if (this.options.filterText) {
      analytics.projectList.filter.select('by-name');
    }
    if (this.options.fromDate) {
      analytics.projectList.filter.select('from-date');
    }
    if (this.options.toDate) {
      analytics.projectList.filter.select('to-date');
    }
  }

  private getOptionNameForAnalytics(value: any, allOptions: CustomDropdownOption[]): string {
    return allOptions
      .find((el) => isEqual(el.value, value))
      .name.toLowerCase()
      .replace(/[()]/g, '')
      .replace(/\s+/g, '-');
  }
}

export const ngProjectFiltersComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ProjectFiltersComponent,
  },
};
