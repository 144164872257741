import { copy as ngCopy } from 'angular';
import { sha1 } from 'object-hash';
import { QuotasModel, WeightingStrategy } from '../../../common/models/quotas.model';
import { QuotaOptions, TargetGender } from '../../../common/enums';
import { AgeSpan } from './active-target-group.model';

export interface ActiveQuotasModel {
  readonly extrapolatedAgeCensus: {
    readonly hasExtrapolatedAgeCensus: boolean;
    readonly maxAgeInCensus: number;
  };
  readonly usePercentages: boolean;
  readonly weightingStrategy: WeightingStrategy;
  readonly quotaGroupNames?: { region: { [ids: string]: string }; profiling: { [variableIds: string]: string } };
  readonly gender: QuotaSection;
  readonly ageSpan: QuotaSection;
  readonly region: QuotaSection;
  readonly profiling: QuotaSection[];
  readonly interlocked: QuotaSection[];
}

export function createActiveQuotasModel(): ActiveQuotasModel {
  return {
    extrapolatedAgeCensus: {
      hasExtrapolatedAgeCensus: false,
      maxAgeInCensus: null,
    },
    usePercentages: false,
    weightingStrategy: WeightingStrategy.EvenDistribution,
    gender: createQuotaSection(['gender']),
    ageSpan: createQuotaSection(['ageSpan']),
    region: createQuotaSection(['region']),
    profiling: [],
    interlocked: [],
  };
}

export function createActiveQuotasModelFrom(quotas: QuotasModel): ActiveQuotasModel {
  return {
    ...createActiveQuotasModel(),
    usePercentages: quotas.usePercentages,
    weightingStrategy: quotas.weightingStrategy,
    quotaGroupNames: quotas.quotaGroupNames,
  };
}

export interface QuotaSection {
  readonly keys: string[];
  readonly option: QuotaOptions;
  readonly usePanelDistribution: boolean;
  readonly ignoreCompletes: boolean;
  readonly items: Quota[];
}

export interface QuotaGroupNames {
  region: { [ids: string]: string };
  profiling: { [variableIds: string]: string };
}

export function createQuotaSection(
  keys: readonly string[],
  option: QuotaOptions = QuotaOptions.None,
  items: readonly Quota[] = [],
  usePanelDistribution = false,
  ignoreCompletes = false
): QuotaSection {
  return {
    keys: [...keys],
    option,
    usePanelDistribution,
    ignoreCompletes,
    items: [...items],
  };
}

export interface Quota {
  readonly key: QuotaKey;
  readonly hash: string;
  readonly completes: number | undefined;
  readonly starts?: number;
  readonly customCpi?: number;
}

export function createQuota(key: QuotaKey, completes: number, starts?: number, customCpi?: number): Quota {
  const keyCopy = ngCopy(key);
  return {
    completes,
    starts,
    hash: sha1(keyCopy),
    key: keyCopy,
    customCpi,
  };
}

export function* flattenQuotas(quotas: ActiveQuotasModel): Generator<ExtendedQuota> {
  let matchId = 1;

  for (const quota of quotas.gender.items) {
    yield { ...quota, option: quotas.gender.option, keys: quotas.gender.keys, matchId: matchId++ };
  }

  for (const quota of quotas.ageSpan.items) {
    yield { ...quota, option: quotas.ageSpan.option, keys: quotas.ageSpan.keys, matchId: matchId++ };
  }

  for (const quota of quotas.region.items) {
    yield { ...quota, option: quotas.region.option, keys: quotas.region.keys, matchId: matchId++ };
  }

  for (const section of quotas.profiling) {
    for (const quota of section.items) {
      yield { ...quota, option: section.option, keys: section.keys, matchId: matchId++ };
    }
  }

  for (const section of quotas.interlocked) {
    for (const quota of section.items) {
      yield { ...quota, option: section.option, keys: section.keys, matchId: matchId++ };
    }
  }
}

export interface ExtendedQuota extends Quota {
  readonly matchId: number;
  option: QuotaOptions;
  readonly keys: readonly string[];
}

export interface QuotaKey {
  readonly gender: TargetGender;
  readonly ageSpan: AgeSpan;
  readonly region: number[];
  readonly profiling: QuotaKeyProfiling;
  // not used in ActiveQuotasModel, but added later when concatenating with supply group quotas
  readonly supply?: QuotaKeySupply;
}

export type QuotaKeyProfiling = {
  readonly questionId: number;
  readonly variableId: number;
}[];

export interface QuotaKeySupply {
  readonly name: string;
  readonly panels: number[];
}
