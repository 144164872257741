import { Ng1StateDeclaration, Transition } from '@uirouter/angularjs';

function getInjector(transition: Transition): angular.auto.IInjectorService {
  return transition.injector().get('$injector');
}

const targetGroups: Ng1StateDeclaration = {
  name: 'targetGroups.**',
  url: '/target-groups',
  lazyLoad: ($transition$: Transition) => {
    return new Promise((resolve) => {
      require.ensure(
        ['./target-groups/target-groups.module'],
        () => {
          // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          const targetGroupsModule = require('./target-groups/target-groups.module').targetGroups;
          getInjector($transition$).loadNewModules([targetGroupsModule.name]);
          resolve(targetGroupsModule);
        },
        'target-groups'
      );
    });
  },
};

const overview: Ng1StateDeclaration = {
  name: 'overview.**',
  url: '/overview',
  lazyLoad: ($transition$: Transition) => {
    return new Promise((resolve) => {
      require.ensure(
        ['./overview/overview.module'],
        () => {
          // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          const overviewModule = require('./overview/overview.module').overview;
          getInjector($transition$).loadNewModules([overviewModule.name]);
          resolve(overviewModule);
        },
        'overview'
      );
    });
  },
};

const manage: Ng1StateDeclaration = {
  name: 'manage.**',
  url: '/manage',
  lazyLoad: ($transition$: Transition) => {
    return new Promise((resolve) => {
      require.ensure(
        ['./manage/manage.module'],
        () => {
          // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          const manageModule = require('./manage/manage.module').manage;
          getInjector($transition$).loadNewModules([manageModule.name]);
          resolve(manageModule);
        },
        'manage'
      );
    });
  },
};

const projectLists: Ng1StateDeclaration = {
  name: 'project-lists.**',
  url: '/projects',
  lazyLoad: ($transition$: Transition) => {
    return new Promise((resolve) => {
      require.ensure(
        ['./dashboard/project-lists.module'],
        () => {
          // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
          const projectListsModule = require('./dashboard/project-lists.module').projectLists;
          getInjector($transition$).loadNewModules([projectListsModule.name]);
          resolve(projectListsModule);
        },
        'project-lists'
      );
    });
  },
};

export const states = [targetGroups, overview, manage, projectLists];
