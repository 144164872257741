import { includes } from 'lodash-es';
import { Currency } from './http-services/pricing.httpservice';
import { Role } from '../app-settings';
import { RuntimeSettings } from '../runtime-settings';
import { currencyService } from '../target-groups/currency.service';

export class UserService {
  get staleHoursSetting(): number {
    return RuntimeSettings.userData.settings.staleHours;
  }

  get name(): string {
    return RuntimeSettings.userData.name;
  }

  get company(): string {
    return RuntimeSettings.userData.company;
  }

  get roles(): Role[] {
    return RuntimeSettings.userData.roles;
  }

  get impersonatedAs(): string {
    return RuntimeSettings.userData.impersonatedAs;
  }

  get showCompanyProjects(): boolean {
    return RuntimeSettings.userData.settings.showCompanyProjects;
  }

  get allowLockedPanels(): boolean {
    return RuntimeSettings.userData.settings.allowLockedPanels;
  }

  get hasMandatoryPurchaseOrder(): boolean {
    return RuntimeSettings.userData.settings.hasMandatoryPurchaseOrder;
  }

  get hasMandatoryJobNumber(): boolean {
    return RuntimeSettings.userData.settings.hasMandatoryJobNumber;
  }

  get predefinedAdditionalContactEmails(): string[] {
    return RuntimeSettings.userData.settings.predefinedAdditionalContactEmails !== null
      ? RuntimeSettings.userData.settings.predefinedAdditionalContactEmails
      : [];
  }

  get numberOfRequiredAdditionalEmails(): number {
    return RuntimeSettings.userData.settings.numberOfRequiredAdditionalEmails;
  }

  get hasRestrictedPanelUsage(): boolean {
    return RuntimeSettings.userData.settings.restrictedPanelUsage;
  }

  get hasRequiredWebcamAsDefault(): boolean {
    return RuntimeSettings.userData.settings.isWebcamRequired;
  }

  get surveyTestingNeeded(): boolean {
    return RuntimeSettings.userData.settings.surveyTestingNeeded;
  }

  get hasOutstandingBills(): boolean {
    return RuntimeSettings.userData.settings.hasOutstandingBills;
  }

  get allowPiiKeywordTagsOnUrls(): boolean {
    return RuntimeSettings.userData.settings.allowPiiKeywordTagsOnUrls;
  }

  get firstNotificationDays(): number {
    return RuntimeSettings.userData.settings.firstNotificationDaysForAutoClose;
  }

  get displayCurrency(): Currency | undefined {
    if (!RuntimeSettings.userData.settings.displayCurrencyCode) return undefined;
    return currencyService.getCurrency(RuntimeSettings.userData.settings.displayCurrencyCode);
  }

  get companyAllowedToCreateProjects(): boolean {
    return !RuntimeSettings.userData.settings.blockProjectCreation;
  }

  isAccessProUser(): boolean {
    return includes(RuntimeSettings.userData.roles, 'AccessPro');
  }

  isConnectUser(): boolean {
    return includes(RuntimeSettings.userData.roles, 'Connect');
  }

  isAdminUser(): boolean {
    return RuntimeSettings.userData.settings.isAdminUser;
  }

  isProjectLead(): boolean {
    return RuntimeSettings.userData.roles.includes('ProjectLead');
  }

  isCpxCountryAdmin(): boolean {
    return RuntimeSettings.userData.roles.includes('CpxCountryAdmin');
  }

  isImpersonatingOtherUser(): boolean {
    return (
      RuntimeSettings.userData.name &&
      RuntimeSettings.userData.impersonatedAs &&
      RuntimeSettings.userData.name !== RuntimeSettings.userData.impersonatedAs
    );
  }
}

export const userService = new UserService();
