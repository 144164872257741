import { ActiveIncentivesModel } from '../../target-groups/active-target-group/models/active-incentives.model';

export interface IncentivesModel {
  readonly fixedIncentive: {
    readonly amount: number | undefined;
  };
}

export function createIncentivesModel(): IncentivesModel {
  return {
    fixedIncentive: {
      amount: undefined,
    },
  };
}

export function createIncentivesModelFromActive(model: ActiveIncentivesModel): IncentivesModel {
  return {
    fixedIncentive: {
      amount: model.fixedIncentive ? model.fixedIncentive.amount : undefined,
    },
  };
}
