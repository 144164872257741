import { ui } from 'angular';
import { ExportProjectDialogComponent } from './export-project-dialog.component';

export class ExportProjectDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'export-project-dialog',
      backdrop: 'static',
      component: ExportProjectDialogComponent.componentName,
    };
  }
}
