import { isDefined, ITemplateCacheService } from 'angular';

import uiSelectMatchTpl from './ui-select-match.tpl.html';
import uiSelectMatchMultipleTpl from './ui-select-match-multiple.tpl.html';

const uiSelectMatchTplUrl = './ui-select-match.tpl.html';
const uiSelectMatchMultipleTplUrl = './ui-select-match-multiple.tpl.html';

export function uiSelectMatchDirectiveDecorator($delegate: any) {
  'ngInject';

  const directive = $delegate[0];

  function getAttribute(elem: any, attribute: any) {
    if (elem[0].hasAttribute(attribute)) return elem.attr(attribute);
    if (elem[0].hasAttribute(`data-${attribute}`)) return elem.attr(`data-${attribute}`);
    if (elem[0].hasAttribute(`x-${attribute}`)) return elem.attr(`x-${attribute}`);
    return undefined;
  }

  directive.templateUrl = (tElement: any) => {
    tElement.addClass('ui-select-match');
    const parent = tElement.parent();
    const multi = isDefined(getAttribute(parent, 'multiple'));

    return multi ? uiSelectMatchMultipleTplUrl : uiSelectMatchTplUrl;
  };

  return $delegate;
}

export function registerUiSelectMatchTemplates($templateCache: ITemplateCacheService) {
  $templateCache.put(uiSelectMatchTplUrl, uiSelectMatchTpl);
  $templateCache.put(uiSelectMatchMultipleTplUrl, uiSelectMatchMultipleTpl);
}
