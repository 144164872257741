import { ui } from 'angular';
import { SelectProjectGroupsDialogComponentName, SelectProjectGroupsDialogResolve } from './SelectProjectGroupsDialog';

export class SelectProjectGroupsDialogSettingsFactory {
  static create(resolve?: SelectProjectGroupsDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'select-project-groups-dialog',
      component: SelectProjectGroupsDialogComponentName,
      resolve: {
        projectId: resolve?.projectId,
      },
    };
  }
}
