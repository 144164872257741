import { IPromise } from 'angular';
import { TargetGroupConstants } from '../target-group.constants';
import { api } from '../../common/api';
import { debouncePromise } from '../../ngimport';
import { ProfilingSearchResult } from '../../common/http-services/profiling.httpservice';

export class ProfilingSearchService {
  throttledSearch: (countryId: number, searchString: string, categoryId?: number) => IPromise<ProfilingSearchResult>;

  init(): void {
    this.throttledSearch = debouncePromise(this.search, TargetGroupConstants.searchThrottleInterval);
  }

  private search(countryId: number, searchString: string, categoryId?: number): IPromise<ProfilingSearchResult> {
    if (!searchString) return Promise.resolve(null);
    searchString = encodeURIComponent(searchString.toLowerCase());
    return api.profiling.searchCategoriesByIds(countryId, categoryId, searchString).then((response) => {
      return response.data;
    });
  }
}

export const profilingSearchService = new ProfilingSearchService();
