import { merge } from 'lodash-es';
import { FileUploader as IFileUploader, FileUploaderFactory, FileItem, Filter } from 'angular-file-upload';

export enum UploadFilters {
  tooLargeFile = 'tooLargeFile',
}

export interface FileUploaderConfiguration<T extends Response> {
  url: string;
  maxFileSize: number;
  autoUpload: boolean;
  method: string;
  withCredentials: boolean;
  onErrorItem: (item: FileItem, response: T, status: number, headers: Headers) => void;
  onCompleteItem: (item: FileItem, response: T, status: number, headers: Headers) => void;
  onWhenAddingFileFailed: (item: FileItem, filter: Filter, options: object) => void;
  onAfterAddingFile: (item: FileItem) => void;
  onProgressItem: (item: FileItem, progress: number) => void;
  onCancelItem: (item: FileItem, response: T, status: number, headers: Headers) => void;
  onSuccessItem: (item: FileItem, response: T, status: number, headers: Headers) => void;
  onTooLargeFile: () => void;
}

const defaultConfig: Partial<FileUploaderConfiguration<Response>> = {
  autoUpload: true,
  method: 'POST',
  withCredentials: true,
};

const defaultMaxFileSize = 3145728;

export function createFileUploader<T extends Response>(
  FileUploader: FileUploaderFactory,
  config: Partial<FileUploaderConfiguration<T>>
): IFileUploader {
  const fileUploader = new FileUploader(merge({}, defaultConfig, config));
  fileUploader.filters = [
    {
      name: UploadFilters.tooLargeFile,
      fn: (item: /*FileLikeObject*/ any) =>
        item.size <= (config.maxFileSize == null ? defaultMaxFileSize : config.maxFileSize),
    },
  ];

  if (config.onErrorItem !== undefined) {
    fileUploader.onErrorItem = config.onErrorItem;
  }
  if (config.onSuccessItem !== undefined) {
    fileUploader.onSuccessItem = config.onSuccessItem;
  }
  if (config.onAfterAddingFile !== undefined) {
    fileUploader.onAfterAddingFile = config.onAfterAddingFile;
  }
  if (config.onProgressItem !== undefined) {
    fileUploader.onProgressItem = config.onProgressItem;
  }
  if (config.onCancelItem !== undefined) {
    fileUploader.onCancelItem = config.onCancelItem;
  }
  if (config.onCompleteItem !== undefined) {
    fileUploader.onCompleteItem = config.onCompleteItem;
  }

  fileUploader.onWhenAddingFileFailed = (item: FileItem, filter: Filter, options: object): void => {
    handleFilterFails(config, item, filter, options);
  };
  return fileUploader;
}

function handleFilterFails(config: any, item: FileItem, filter: Filter, options: object): void {
  if (filter.name === UploadFilters.tooLargeFile) {
    if (config.onTooLargeFile !== undefined) {
      config.onTooLargeFile();
    }
    return;
  }
  if (config.onWhenAddingFileFailed !== undefined) {
    config.onWhenAddingFileFailed(item, filter, options);
  }
}
