import { copy as ngCopy } from 'angular';
import { reduce } from 'lodash-es';
import { SelectProjectGroupsDialogSettingsFactory } from '../overview/project-summary/select-project-groups-dialog-settings.factory';
import { ProjectListFilterOptions } from './project-filters/project-filters.component';
import { ProjectListItem } from './project-list-item/project-list-item.component';
import { ListenerComponent, DataChannel } from '../common/channels/channel';
import { PagedProjectListResponse, ProjectResponse } from '../common/http-services/project-list.httpservice';
import { SortField, ProjectListItemStatus, ProjectChannel, BackgroundDataJobStatus } from '../common/enums';
import { projectListChannel } from '../common/channels/project-list-channel';
import { api } from '../common/api';
import { filters } from '../common/filters';
import { analytics } from '../common/analytics';
import { MenuListItem, MenuOptionType } from '../common/controls/options-menu/options-menu.component';
import { $state, $window } from '../ngimport';
import { asyncReportService, ReportTypes } from '../common/async-report-service';
import { featureFlipper } from '../common/feature-flipper';
import { html, silenceRejection } from '../helpers';
import { ErrorMessageDialogSettingsFactory } from '../common/dialogs/error-message-dialog-settings.factory';
import { modalService } from '../common/modal.service';

const selector = 'projectsComponent';

export enum ProjectType {
  Active = 'active',
  Closed = 'closed',
}

class ProjectPage {
  index: number;
  projects: ProjectListItem[];
}

const template = html`
  <div class="project-list__tab">
    <div class="project-list__filters">
      <project-filters-component
        date-label="$ctrl.dateLabel"
        result-count="$ctrl.totalProjectsCount"
        options="$ctrl.filterOptions"
        on-filter-clicked="$ctrl.refreshList()"
      ></project-filters-component>
    </div>

    <div class="project-list__head" stick-to-top scroll-down-position="0" scroll-up-position="74">
      <p
        class="project-list__column_id sortable"
        ng-class="$ctrl.sortIndicator('${SortField.Id}')"
        ng-click="$ctrl.sortOn('${SortField.Id}')"
      >
        ID
      </p>
      <p
        class="project-list__column_project sortable"
        ng-class="$ctrl.sortIndicator('${SortField.Name}')"
        ng-click="$ctrl.sortOn('${SortField.Name}')"
      >
        Project
      </p>
      <p class="project-list__column_completes">Completes</p>
      <p
        class="project-list__column_date sortable"
        ng-class="$ctrl.sortIndicator('${SortField.StartDate}')"
        ng-click="$ctrl.sortOn('${SortField.StartDate}')"
      >
        Start date
      </p>
      <p class="project-list__column_date">End date</p>
      <p class="project-list__column_control"></p>
    </div>
    <div class="project-list__projects">
      <div ng-repeat="page in $ctrl.projectPages track by page.index">
        <div class="project-list__project" ng-repeat="project in page.projects track by project.id">
          <project-list-item-component item="project" on-option-clicked="$ctrl.onMenuOptionClicked(option, item)">
          </project-list-item-component>
        </div>
      </div>
      <div
        class="project-list__load-trigger"
        on-scroll-into-view="$ctrl.getProjectListPage()"
        margin="{{$ctrl.scrollMargin}}"
      >
        &nbsp;
      </div>
      <div class="project-list__spinner" ng-if="$ctrl.loading">
        <div class="spinner-container text-center">
          <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
`;

interface Bindings {
  type: '<' | ProjectType;
}

const bindings: Bindings = {
  type: '<',
};

export class ProjectsComponent extends ListenerComponent implements Bindings {
  static componentName = selector;
  dateLabel = 'Start date';
  loading: boolean;
  projectPages: ProjectPage[] = [];
  totalProjectsCount: number;
  filteredProjectsCount = 0;
  nextIndex = 0;
  filterOptions: ProjectListFilterOptions;
  activeFilterOptions: ProjectListFilterOptions;
  type: ProjectType;
  channel: DataChannel<PagedProjectListResponse>;
  pageSize: number;
  scrollMargin: number;

  get sortFieldDate() {
    return SortField.StartDate;
  }
  get sortFieldId() {
    return SortField.Id;
  }
  get sortFieldName() {
    return SortField.Name;
  }

  $onInit(): void {
    this.pageSize = this.getPageSize();
    this.scrollMargin = this.getScrollMargin();
    this.filterOptions = this.initFilterOptions();
    this.activeFilterOptions = this.initFilterOptions();
    this.getProjectListPage();
  }

  sortOn(field: SortField): void {
    if (this.filterOptions.sortOptions.field === field) {
      this.filterOptions.sortOptions.descending = !this.filterOptions.sortOptions.descending;
    } else {
      this.filterOptions.sortOptions.descending = field === SortField.StartDate;
    }
    this.filterOptions.sortOptions.field = field;

    this.refreshList();
  }

  sortIndicator(field: SortField) {
    if (this.filterOptions.sortOptions.field === field) {
      return this.filterOptions.sortOptions.descending ? 'sort-desc' : 'sort-asc';
    }
    return '';
  }

  getProjectListPage(): void {
    if ((this.nextIndex > this.totalProjectsCount && this.totalProjectsCount) || this.loading) return;
    this.loading = true;
    api.projectList
      .getProjectListPage(this.nextIndex, this.pageSize, this.activeFilterOptions)
      .then((res) => {
        const projectsPage = res.data;
        this.totalProjectsCount = projectsPage.totalCount;
        const projects = projectsPage.items.map((el) => ({
          id: el.id,
          name: el.name,
          status: this.getStatus(el),
          startDate: el.startDate,
          endDate: el.endDate,
          owner: el.owner,
          channel: el.channel,
          url: filters.projectOverviewUrl()(el.id),
          menuOptions: this.createMenuOptions(el),
          wantedCompletes: el.neededNumberOfCompletes,
          currentCompletes: el.actualNumberOfCompletes,
        }));
        this.projectPages.push({ index: this.nextIndex, projects });
        this.nextIndex += this.pageSize;
        this.filteredProjectsCount = reduce(this.projectPages, (acc, page) => acc + page.projects.length, 0);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  refreshList(): void {
    this.totalProjectsCount = undefined;
    this.nextIndex = 0;
    this.activeFilterOptions = ngCopy(this.filterOptions);
    this.projectPages = [];
    analytics.projectList.filter.apply(this.type);
    this.getProjectListPage();
  }

  onMenuOptionClicked(option: MenuListItem, item: ProjectListItem) {
    switch (option.id) {
      case 0:
        analytics.projectList.projectMenu.view(item.status, item.startDate);
        break;
      case 1:
        $state.go('manage.monitor', { projectId: item.id });
        break;
      case 2:
        this.generateReport(item, ReportTypes.PanelistBackgroundData);
        analytics.projectList.projectMenu.downloadData(item.status, item.endDate);
        break;
      case 3:
        this.generateReport(item, ReportTypes.ExtendedPanelistBackgroundData);
        analytics.projectList.projectMenu.downloadDataExtended(item.status, item.endDate);
        break;
      case 4:
        this.generateReport(item, ReportTypes.ProjectProgress);
        analytics.projectList.projectMenu.downloadProgressReport(item.status, item.startDate);
        break;
      case 5:
        modalService
          .openLarge(SelectProjectGroupsDialogSettingsFactory.create({ projectId: item.id }))
          .result.catch(silenceRejection);
        break;
      default:
        break;
    }
  }

  private initFilterOptions() {
    return this.type === ProjectType.Active
      ? new ProjectListFilterOptions('Active,Edit')
      : new ProjectListFilterOptions('Closed');
  }

  private generateReport(project: ProjectListItem, reportType: ReportTypes): void {
    asyncReportService
      .generateReport(project.id, reportType)
      .then(() => {
        let seconds = 0;
        const id = setInterval(() => {
          if (seconds < 10 || seconds % 10 === 0)
            asyncReportService.getReportStatus(project.id, reportType).then((res) => {
              if (res.jobStatus === BackgroundDataJobStatus.Completed) {
                asyncReportService
                  .getReportDataFile(project.id, project.name, reportType)
                  .then(() => projectListChannel.loadedFile.dispatch(project.id));
                clearInterval(id);
              } else if (res.jobStatus === BackgroundDataJobStatus.Failed) {
                clearInterval(id);
              }
            });
          seconds += 1;
        }, 1000);
      })
      .catch(() => {
        projectListChannel.loadedFile.dispatch(project.id);
        ErrorMessageDialogSettingsFactory.create({
          title: `Download ${reportType}`,
          messages: ['Something went wrong while getting the data.', 'Please try again later'],
        });
      });
  }

  private getStatus(project: ProjectResponse): ProjectListItemStatus {
    if (this.filterOptions.status.toLowerCase() === ProjectListItemStatus.Closed) return ProjectListItemStatus.Closed;
    return project.actualNumberOfCompletes >= project.neededNumberOfCompletes
      ? ProjectListItemStatus.Complete
      : ProjectListItemStatus.Active;
  }

  private createMenuOptions(project: ProjectResponse) {
    const options = [
      {
        id: 0,
        name: 'View project',
        url: filters.projectOverviewUrl()(project.id),
        type: MenuOptionType.View,
      },
      {
        id: 1,
        name: 'Management & monitoring',
        url: filters.projectMonitorUrl()(project.id),
        type: MenuOptionType.Monitor,
        hidden: project.channel !== ProjectChannel.AccessPro,
      },
      {
        id: 2,
        name: 'Download background data',
        url: null,
        type: MenuOptionType.DownloadPanelistData,
      },
    ];

    if (featureFlipper.isEnabled('downloadPanelistBackgroundDataAsJson')) {
      options.push({
        id: 3,
        name: 'Download background data (JSON)',
        url: null,
        type: MenuOptionType.DownloadPanelistData,
      });
    }
    options.push({
      id: 4,
      name: 'Download progress report',
      url: null,
      type: MenuOptionType.DownloadReport,
      hidden: project.channel === ProjectChannel.Api || project.channel === ProjectChannel.Track,
    });
    options.push({
      id: 5,
      name: 'Groups',
      url: null,
      type: MenuOptionType.ManageGroups,
      hidden: project.channel === ProjectChannel.Api || project.channel === ProjectChannel.Track,
    });

    return options;
  }

  private getPageSize() {
    return Math.ceil(($window.innerHeight * 1.25) / 70);
  }

  private getScrollMargin() {
    return Math.ceil($window.innerHeight / 4);
  }
}

export const ngProjectsComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ProjectsComponent,
  },
};
