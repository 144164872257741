import { ui } from 'angular';
import { DeleteDraftDialogComponent, DeleteDraftDialogResolve } from './delete-draft-dialog.component';

export class DeleteDraftDialogSettingsFactory {
  static create(resolve: DeleteDraftDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'delete-draft-dialog',
      component: DeleteDraftDialogComponent.componentName,
      resolve: {
        draft: () => resolve.draft,
        savedDraft: () => resolve.savedDraft,
      },
    };
  }
}
