import { ActiveIncentivesModel, createActiveIncentivesModel } from '../../models/active-incentives.model';
import { targetGroupValidator } from '../../../target-group-validator';
import { logProduce } from '../../../../common/immer-wrapper';

export class IncentivesSubactions {
  setFixedIncentiveAmount(state: ActiveIncentivesModel, amount: number): ActiveIncentivesModel {
    if (amount === null) return this.useDefaultIncentive(state);

    // TODO: mutator
    return logProduce(state, (draft) => {
      draft.fixedIncentive.amount = amount;
    });
  }

  useDefaultIncentive(state: ActiveIncentivesModel): ActiveIncentivesModel {
    return logProduce(state, () => {
      return createActiveIncentivesModel();
    });
  }

  validateModel(state: ActiveIncentivesModel, ownPanelCurrency?: string): ActiveIncentivesModel {
    // TODO: mutator
    return logProduce(state, (draft) => {
      draft.validationResult = targetGroupValidator.validateIncentives(state, ownPanelCurrency);
    });
  }
}

export const incentivesSubactions = new IncentivesSubactions();
