import { userService } from '../../user.service';
import { html } from '../../../helpers';

const selector = 'availableLinkTagsComponent';

const template = html`
  <div class="panel panel-default available-link-tags-component">
    <div class="panel-heading">Mandatory tag</div>
    <ul class="list-group">
      <li class="list-group-item">
        <div class="row"><em class="col-xs-2">[ID]</em><span class="col-xs-10">Respondent ID</span></div>
      </li>
    </ul>
  </div>
  <div class="panel panel-default" bs-collapse data-start-collapsed="true">
    <div class="panel-heading expand-collapse" ng-click="$ctrl.expanded = !$ctrl.expanded" bs-collapse-toggle>
      <i class="accent fas" ng-class="{'fa-minus': $ctrl.expanded, 'fa-plus': !$ctrl.expanded}"></i>
      &nbsp;Optional tags
    </div>
    <ul class="list-group collapse" bs-collapse-target>
      <li class="list-group-item">
        <div class="row"><em class="col-xs-2">[AGE]</em><span class="col-xs-10">Age</span></div>
      </li>
      <li class="list-group-item">
        <div class="row"><em class="col-xs-2">[M]</em><span class="col-xs-10">Member ID</span></div>
      </li>
      <li class="list-group-item" ng-show="$ctrl.allowPiiKeywordTagsOnUrls">
        <div class="row"><em class="col-xs-2">[P]</em><span class="col-xs-10">Panelist ID</span></div>
      </li>
      <li class="list-group-item" ng-show="$ctrl.allowPiiKeywordTagsOnUrls">
        <div class="row"><em class="col-xs-2">[PC]</em><span class="col-xs-10">Postal code</span></div>
      </li>
      <li class="list-group-item">
        <div class="row"><em class="col-xs-2">[S]</em><span class="col-xs-10">Gender (male = 1, female = 2)</span></div>
      </li>
      <li class="list-group-item">
        <div class="row"><em class="col-xs-2">[YOB]</em><span class="col-xs-10">Year of birth</span></div>
      </li>
    </ul>
  </div>
`;

export class AvailableLinkTagsComponent {
  expanded: boolean;
  allowPiiKeywordTagsOnUrls: boolean = userService.allowPiiKeywordTagsOnUrls;
}

export const ngAvailableLinkTagsComponent = {
  [selector]: {
    template,
    controller: AvailableLinkTagsComponent,
  },
};
