import { ui } from 'angular';
import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import { api } from '../../common/api';
import { ModalCloseButton } from '../../common/dialogs/ModalCloseButton';

import './add-project-group-dialog.scss';

interface Props {
  modalInstance: ui.bootstrap.IModalInstanceService;
}

const AddProjectGroupDialog: React.FC<Props> = ({ modalInstance }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setName(e.target.value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError('');
    await api.project
      .createProjectGroup(name)
      .then(() => {
        modalInstance.close();
      })
      .catch(() => setError('Could not create new group. Please make sure group name is unique and try again.'))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="modal-container add-project-group-dialog">
      <ModalCloseButton onClose={modalInstance.dismiss} />

      <h1 className="modal-header" id="add-project-group-header">
        Create New Project Group
      </h1>

      <input
        className="form-control"
        type="text"
        placeholder="Project Group Name"
        value={name}
        onChange={handleNameChange}
      />
      {error !== '' && <div className="push-down create-group-error">{error}</div>}
      <div className="modal-controls">
        <button className="ui-btn default-btn" disabled={isLoading} onClick={modalInstance.dismiss}>
          Cancel
        </button>
        <button className="ui-btn primary-btn" disabled={name.length === 0 || isLoading} onClick={handleSave}>
          {isLoading && <i className="fas fa-spinner fa-spin" />}
          Save
        </button>
      </div>
    </div>
  );
};

export const AddProjectGroupDialogComponentName = 'addProjectGroupDialog';
export const ngAddProjectGroupDialogComponent = {
  addProjectGroupDialog: react2angular(AddProjectGroupDialog, ['modalInstance']),
};
