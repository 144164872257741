import './load-template-dialog.component.scss';
import { ui } from 'angular';
import { Transition } from '@uirouter/angularjs';
import { api } from '../../../common/api';
import { modalService } from '../../../common/modal.service';
import { onEnter, onExit } from '../../../common/dialogs/dialog.controller';
import { analytics } from '../../../common/analytics';
import { TemplatesListType } from '../../../common/enums';
import {
  LoadTemplateDialogSettingsFactory,
  LoadTemplateDialogResolve,
} from './target-groups-sidebar-dialog-settings.factories';
import { projectService } from '../../project.service';
import {
  LoadAndValidateTemplateResponse,
  LoadTemplateResponse,
  TemplateValidationResult,
} from '../../../common/http-services/template.httpservice';
import { projectSettingsService, ProjectSettings } from '../../../common/project-settings.service';
import { html } from '../../../helpers';
import { countryService } from '../../country.service';
import { existingProjectLookupService } from '../../../common/existing-project-lookup.service';
import { targetGroupNamer } from '../../../common/target-group/target-group-namer';
import { TargetGroupsStateIntent } from '../../../target-groups-state-intent';

const selector = 'loadTemplateDialogComponent';

const template = html`
  <div class="load-template-dialog-component">
    <div class="spinner-overlay" ng-if="$ctrl.isLoading"><i class="fas fa-spinner fa-spin" aria-hidden="true"></i></div>
    <modal-close-button-component close-action="$ctrl.discard()"></modal-close-button-component>
    <div class="modal-container">
      <h1>Create target group from template</h1>
      <div class="content">
        <div class="incompatible-template-container" ng-show="$ctrl.validation && !$ctrl.validation.isValid">
          <div class="validation-message">
            <p class="validation-header">
              The following panels are incompatible with current project settings and will not be included if you choose
              to continue.
            </p>
            <ul class="incompatible-panels-list">
              <li ng-repeat="panel in $ctrl.validation.incompatiblePanels">{{panel}}</li>
            </ul>
            <p class="validation-header">Side effects</p>
            <ul class="side-effects-list">
              <li ng-if="$ctrl.validation.hasInvalidSupplyMix">
                This template uses supply mix which becomes invalid and will be set to system-selected set of panels.
              </li>
              <li ng-if="$ctrl.validation.hasInvalidSingleSourceSupply">
                This template uses single source supply which becomes invalid and will be set to system-selected set of
                panels.
              </li>
              <li ng-if="$ctrl.validation.hasInvalidPanelSpecificProfiling">
                This template is set up with panel specific profiling which will be removed.
              </li>
              <li ng-if="$ctrl.validation.hasInvalidProfilingQuotas">
                This template has quotas on the panel specific profiling which will be removed.
              </li>
              <p
                ng-if="!$ctrl.validation.hasInvalidSupplyMix && !$ctrl.validation.hasInvalidSingleSourceSupply && !$ctrl.validation.hasInvalidPanelSpecificProfiling && !$ctrl.validation.hasInvalidProfilingQuotas"
              >
                This will not affect the target group setup in any other way.
              </p>
            </ul>
          </div>
          <div class="buttons">
            <button class="ui-btn default-btn flt-left" ng-click="$ctrl.abortLoadIncompatibleTemplate()">Cancel</button>
            <button class="ui-btn primary-btn flt-right" ng-click="$ctrl.loadIncompatibleTemplate()">
              Load template
            </button>
          </div>
        </div>
      </div>
      <templates-list-component
        ng-hide="$ctrl.validation && !$ctrl.validation.isValid"
        templates-list-type="'${TemplatesListType.UseTemplate}'"
        on-use-template="$ctrl.loadAndValidateTemplate(id)"
      ></templates-list-component>
    </div>
  </div>
`;

interface Bindings {
  resolve: '<' | LoadTemplateDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class LoadTemplateDialogComponent implements Bindings {
  static componentName = selector;
  modalInstance: ui.bootstrap.IModalInstanceService;
  panelistPoolContainsUrl: boolean;
  templateId: number;
  isLoading: boolean;
  resolve: LoadTemplateDialogResolve;
  loadToExistingProject: boolean;
  errors: {
    loadFailed: boolean;
  };

  validation: TemplateValidationResult;

  incompatibleTemplate: LoadTemplateResponse;

  $onInit() {
    this.loadToExistingProject = this.resolve.loadToExistingProject;
    countryService.init();

    this.errors = {
      loadFailed: false,
    };
    if (this.loadToExistingProject) this.persistExistingProjectSettings();
  }

  discard(): void {
    this.modalInstance.dismiss();
  }

  abortLoadIncompatibleTemplate(): void {
    this.validation = null;
    this.errors = {
      loadFailed: false,
    };
    analytics.template.cancelLoadIncompatible();
  }

  async loadIncompatibleTemplate() {
    return this.addLoadedTemplateAndClose(this.incompatibleTemplate, false);
  }

  async loadAndValidateTemplate(id: number): Promise<void> {
    this.isLoading = true;
    return api.template.loadAndValidateTemplate(id, projectSettingsService.settings).then((res) => {
      return this.loadTemplateAndClose(res.data);
    });
  }

  private async loadTemplateAndClose(templateAndValidation: LoadAndValidateTemplateResponse) {
    if (!templateAndValidation.validation.isValid) {
      this.incompatibleTemplate = templateAndValidation.template;
      this.validation = templateAndValidation.validation;
      this.isLoading = false;
      return;
    }

    await this.addLoadedTemplateAndClose(templateAndValidation.template, true);
  }

  private async addLoadedTemplateAndClose(loadedTemplate: LoadTemplateResponse, isCompatible: boolean) {
    if (this.loadToExistingProject) {
      this.updateTargetGroupName(loadedTemplate);
    }
    await projectService.addLoadedTemplate(loadedTemplate);
    this.isLoading = false;
    if (isCompatible) {
      analytics.template.load();
    } else {
      analytics.template.loadIncompatible();
    }
    this.modalInstance.close();
  }

  private updateTargetGroupName(loadedTemplate: LoadTemplateResponse) {
    const tgNames = existingProjectLookupService.targetGroups.map((tg) => tg.name);
    loadedTemplate.targetGroup.name = targetGroupNamer.updateName(loadedTemplate.targetGroup.name, tgNames);
  }

  private persistExistingProjectSettings() {
    const existingProject = existingProjectLookupService.project;
    if (existingProject.categories) {
      const projectSettings: ProjectSettings = {
        ...existingProject,
        filterOnPii: existingProject.includesPii,
        categoryIds: existingProject.categories,
        projectName: existingProject.name,
        isSet: true,
      };
      projectSettingsService.persist(projectSettings);
    }
  }
}

export const ngLoadTemplateDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: LoadTemplateDialogComponent,
  },
};

export const loadTemplateDialogState = {
  name: 'targetGroups.loadTemplate',
  url: '/load-template',
  params: {
    isModalState: true,
    intent: TargetGroupsStateIntent,
  },
  resolve: {
    modalInstance: ($transition$: Transition) => {
      'ngInject';

      const loadToExistingProject = $transition$.params().intent === TargetGroupsStateIntent.AddTargetGroups;
      return modalService.openLarge(LoadTemplateDialogSettingsFactory.create({ loadToExistingProject }));
    },
  },
  onEnter,
  onExit,
};
