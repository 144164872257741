import { IHttpPromise } from 'angular';
import { Array, Record, String, Static, Null, Dictionary } from 'runtypes';
import { urlService } from './url.service';
import { $http } from '../../ngimport';

export const SurveyMetadataResponse = Record({
  selectedTags: Array(String).Or(Null),
});
export type SurveyMetadataResponse = Static<typeof SurveyMetadataResponse>;

export const CompanySurveyMetadataResponse = Record({
  tags: Dictionary(Array(String), String),
});
export type CompanySurveyMetadataResponse = Static<typeof CompanySurveyMetadataResponse>;

export class SurveyMetadataHttpService {
  getCompanySurveyMetadataTags(): IHttpPromise<CompanySurveyMetadataResponse> {
    return $http.validatingGet(CompanySurveyMetadataResponse, urlService.getCompanySurveyMetadataTags());
  }

  getTargetGroupSurveyMetadata(projectId: number, targetGroupId: number): IHttpPromise<SurveyMetadataResponse> {
    return $http.validatingGet(
      SurveyMetadataResponse,
      urlService.getTargetGroupSurveyMetadata(projectId, targetGroupId)
    );
  }
}

export const surveyMetadataHttpService = new SurveyMetadataHttpService();
