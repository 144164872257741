import { QuotaWithBuckets } from '../../quotas/quota-bucket-builder';
import { createQuotasModelFromActive } from '../../../common/models/quotas.model';
import { ActiveSupplyGroup, ActiveSupplyModel } from '../models/active-supply.model';
import { SupplyGroupUpdate } from '../../supply/mixed-source/mixed-source-edit-group.component';
import { activeTargetGroupStore } from './active-target-group.store';
import { SupplyStateActions, commitSupply } from './supply/supply-state.actions';
import { SupplyStateModel } from '../models/supply-state.model';
import { logProduce } from '../../../common/immer-wrapper';
import { targetGroupValidator } from '../../target-group-validator';
import { createActiveBasicSettingsModelFrom, ActiveBasicSettingsModel } from '../models/active-basic-settings.model';
import { basicSettingsValidator } from '../../basic-settings/basic-settings-validator';
import { createIncentivesModel } from '../../../common/models/incentives.model';
import { countryRestrictionsDisabledService } from '../../../common/country-restrictions-disabled.service';
import { feasibilityService } from '../../feasibility.service';
import { projectSettingsService } from '../../../common/project-settings.service';
import { TargetGroupModel } from '../../../common/models/target-group.model';
import { $log, $q } from '../../../ngimport';
import { createExcludeProjectsModelFromActive } from '../../../common/models/exclude-projects.model';
import { createSupplyModelFrom } from '../../../common/models/supply.model';
import { createBasicSettingsModelFromActive } from '../../../common/models/basic-settings.model';
import { createRegionsModelFromActive } from '../../../common/models/regions.model';
import { createProfilingModelFromActive } from '../../../common/models/profiling.model';
import { activeTargetGroupSubactions } from '../active-target-group.subactions';
import { getGeneratedBucketsFromModel } from '../../quotas/quotas-helper.functions';
import { targetGroupChannel } from '../../channels/target-group-channel';
import { FeasibilityResponse } from '../../../common/http-services/feasibility.response';
import { isFeasibilityResponse } from '../../../helpers';
import { priceQuoteService } from '../../price-quote.service';
import { TargetGroupMapper } from '../../../common/target-group/target-group-mapper';

export interface SupplyStateCommitFuncs {
  supply: (supplyState: ActiveSupplyModel) => void;
}

export interface CustomCpiParameters {
  currencyCode: string;
  currentCpi: number;
  customCpiMin: number;
  customCpiMax: number;
}

export function isCustomCpiParameters(
  data: CustomCpiParameters | { isInvalid: boolean; error: boolean }
): data is CustomCpiParameters {
  return data !== undefined && 'currentCpi' in data;
}

export class SupplyStateStore {
  readonly supply = new SupplyStateActions(this);

  readonly commitFuncs = {
    supply: commitSupply(),
  };

  private state = {} as SupplyStateModel;

  get model(): SupplyStateModel {
    return this.state;
  }

  persistModel() {
    activeTargetGroupStore.supply.persistSupplyState(this.model);
  }

  initializeSupplyState(model: SupplyStateModel) {
    this.state = model;
  }

  setBasicSettingsModel(ir: number, loi: number, useFixedLoi: boolean) {
    const settings: ActiveBasicSettingsModel = {
      ...createActiveBasicSettingsModelFrom(activeTargetGroupStore.model.basicSettings, []), // TODO: SHould I do this differently?
      estimatedIncidenceRate: ir,
      estimatedLengthOfInterview: loi,
      useFixedLoi,
    };

    const overrideIrAndLoiRestrictions = countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(
      supplyStateStore.model.supply
    );
    const newBasicSettingsModel = logProduce(this.model.basicSettings, (draft) => {
      draft.estimatedIr = ir;
      draft.estimatedLoi = loi;
      draft.useFixedLoi = useFixedLoi;
      draft.validationResult = basicSettingsValidator.validate(settings, overrideIrAndLoiRestrictions);
    });

    this.model.basicSettings = newBasicSettingsModel;
    targetGroupChannel.model.supplyState.updated.dispatch();
  }

  setFixedIncentive(incentive: number, currency: string) {
    const newIncentivesModel = logProduce(this.model.incentives, (draft) => {
      draft.fixedIncentive.amount = incentive;
      draft.validationResult = targetGroupValidator.validateIncentives(draft, currency);
    });
    this.model.incentives = newIncentivesModel;
  }

  setDefaultIncentives() {
    this.model.incentives = createIncentivesModel();
  }

  publishUpdate(runFeasibility?: boolean) {
    if (!runFeasibility) return;
    this.runFeasibility();
  }

  async getCustomCpiParameters(
    supplyGroup?: ActiveSupplyGroup | SupplyGroupUpdate
  ): Promise<CustomCpiParameters | { isInvalid: boolean; error: boolean }> {
    if (!this.model.basicSettings || !this.model.incentives || !this.model.supply)
      return $q.when({ isInvalid: true, error: false });

    const targetGroup = await this.generateTargetGroupModel();
    if (supplyGroup) {
      targetGroup.panels = {
        ...targetGroup.panels,
        selectedIds: supplyGroup.panelIds,
        customCpi: supplyGroup.customCpi,
        supplySource: supplyGroup.source,
      };
      targetGroup.quotas = { ...targetGroup.quotas, quotas: [] as QuotaWithBuckets[] };
    }
    const validationResult = targetGroupValidator.validate(targetGroup);
    if (validationResult.hasError()) {
      targetGroupChannel.audienceFeasibility.invalid.dispatch({ targetGroup, validationResult });
      return $q.when({ isInvalid: true, error: false });
    }

    return priceQuoteService
      .getPriceQuoteForCustomCpi(targetGroup)
      .then((res) => {
        const [{ customCpiMin, customCpiMax, cpi }] = res.data.targetGroups;
        return {
          currencyCode: cpi.currency,
          currentCpi: cpi.value,
          customCpiMin,
          customCpiMax,
        } as CustomCpiParameters;
      })
      .catch(() => {
        return { isInvalid: false, error: true };
      });
  }

  runFeasibility() {
    if (!this.model.basicSettings || !this.model.incentives || !this.model.supply) return;

    targetGroupChannel.audienceFeasibility.initializing.dispatch();
    this.generateTargetGroupModel().then((tg) => this.runFeasibilityIfValid(tg));
  }

  private async generateTargetGroupModel(): Promise<TargetGroupModel> {
    const newActiveTargetGroupState = await activeTargetGroupSubactions.persistSupplyState(
      this.model,
      activeTargetGroupStore.model
    );

    const { quotas, buckets } = getGeneratedBucketsFromModel(newActiveTargetGroupState);
    const { sectionsUsingPanelDistribution, sectionsUsingIgnoreCompletes } = activeTargetGroupStore.quotas;

    const targetGroup: TargetGroupModel = {
      id: newActiveTargetGroupState.identity.id,
      projectTemplateSource: undefined, //this doesn't matter for feasibility
      name: newActiveTargetGroupState.identity.name,
      activePane: newActiveTargetGroupState.ui.activePane,
      basicSettings: createBasicSettingsModelFromActive(newActiveTargetGroupState.basicSettings),
      regions: createRegionsModelFromActive(newActiveTargetGroupState.regions),
      profiling: createProfilingModelFromActive(newActiveTargetGroupState.profiling),
      panels: createSupplyModelFrom(this.model.supply),
      excludeProjects: createExcludeProjectsModelFromActive(newActiveTargetGroupState.excludeProjects),
      incentives: newActiveTargetGroupState.incentives,
      surveyMetadata: newActiveTargetGroupState.surveyMetadata,
      quotas: createQuotasModelFromActive(
        quotas,
        buckets,
        newActiveTargetGroupState.quotas.usePercentages,
        newActiveTargetGroupState.quotas.weightingStrategy,
        sectionsUsingPanelDistribution,
        sectionsUsingIgnoreCompletes,
        newActiveTargetGroupState.quotas.quotaGroupNames
      ),

      requestMode: { includeLockedPanels: newActiveTargetGroupState.supply.includeLockedPanels },
    };

    return targetGroup;
  }

  private async runFeasibilityIfValid(targetGroup: TargetGroupModel): Promise<void> {
    const validationResult = targetGroupValidator.validate(targetGroup);
    if (validationResult.hasError()) {
      targetGroupChannel.audienceFeasibility.invalid.dispatch({ targetGroup, validationResult });
      return;
    }

    const price = await priceQuoteService.getPriceQuoteForSupplyState(this.model.persistedTargetGroups, targetGroup);
    const tgCpi = price.targetGroups.find((tg) => tg.id === targetGroup.id)?.cpi;

    const targetGroupForFeasibility = TargetGroupMapper.toTargetGroupForFeasibility(targetGroup, tgCpi);

    targetGroupChannel.audienceFeasibility.start.dispatch({ targetGroup: targetGroupForFeasibility, validationResult });

    feasibilityService
      .debouncedGetFeasibility(targetGroup, projectSettingsService.settings)
      .then((res: FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }) => {
        if (isFeasibilityResponse(res)) {
          targetGroupChannel.audienceFeasibility.done.dispatch({ targetGroup, feasibilityResponse: res });
        } else {
          targetGroupChannel.audienceFeasibility.error.dispatch({ targetGroupId: targetGroup.id, reason: res?.reason });
        }
      })
      .catch((err) => {
        $log.warn('supply feasibility failed', err);
      });
  }
}

export const supplyStateStore = new SupplyStateStore();
