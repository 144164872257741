import { fromJson, toJson } from 'angular';
import { StorageFactory } from './storage.factory';

export interface FeasibilityOptions {
  useFakeFeasibility: boolean;
  disableDropoutRateTransform: boolean;
  useCustomAvailabilityRatio: boolean;
  availabilityRatio: number;
}

export class GlobalFeasibilityOptions {
  options: FeasibilityOptions = {
    useFakeFeasibility: false,
    disableDropoutRateTransform: false,
    useCustomAvailabilityRatio: false,
    availabilityRatio: 0.5,
  };

  private storage: Storage;
  private storageKey = 'globalFeasibilityOptions';

  constructor() {
    this.storage = StorageFactory.getStorage();
    this.load();
  }

  persist(): void {
    this.storage.setItem(this.storageKey, toJson(this.options));
  }

  load(): void {
    const storageSettings = this.storage.getItem(this.storageKey);
    if (storageSettings !== null) {
      this.options = fromJson(storageSettings);
    }
  }
}

export const globalFeasibilityOptions = new GlobalFeasibilityOptions();
