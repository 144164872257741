import { IPromise, IHttpPromise } from 'angular';
import { Record, String, Boolean, Static, Null, Union, Literal, Array, Optional } from 'runtypes';
import { keyBy } from 'lodash-es';
import { urlService } from './url.service';
import { SurveyLinksUpdateRequest } from '../../overview/target-group/target-group-setup.component';
import { AutoDeliveryStrategy, LinkSource } from '../enums';
import { AboveZero, GuidString, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { Iso8601Date } from '../../helpers';
import { $http } from '../../ngimport';
import { ExistingTargetGroupResponse, ServiceFeatureError } from './existing-project.responses';

export const SurveyLinkSource = Union(
  Literal(LinkSource.None),
  Literal(LinkSource.LinkTemplate),
  Literal(LinkSource.LinkPool),
  Literal(LinkSource.UniqueLinkPool)
);
export type SurveyLinkSource = Static<typeof SurveyLinkSource>;

export const SurveyLinkInfo = Record({
  source: SurveyLinkSource,
  example: String.Or(Null),
  remainingLinkCount: NonNegative,
  isRunningOutOfLinks: Boolean,
});
export type SurveyLinkInfo = Static<typeof SurveyLinkInfo>;

const RespondentStatuses = Record({
  active: NonNegative,
  screenout: NonNegative,
  quotaFull: NonNegative,
  timedOut: NonNegative,
  complete: NonNegative,
  declined: NonNegative,
});
export type RespondentStatuses = Static<typeof RespondentStatuses>;

export const RespondentStats = Record({
  invited: NonNegative,
  responded: NonNegative,
  statuses: RespondentStatuses,
  lastCompleted: Iso8601UtcString.Or(Null),
});
export type RespondentStats = Static<typeof RespondentStats>;

export const QuotaStats = Record({
  id: NonNegative,
  name: String,
  invitedCount: NonNegative,
  respondedCount: NonNegative,
});
export type QuotaStats = Static<typeof QuotaStats>;

const TargetGroupStatsResponse = Record({
  targetGroupId: AboveZero,
  surveyLinkInfo: SurveyLinkInfo,
  respondentStats: RespondentStats,
  quotaStats: Array(QuotaStats),
});
export type TargetGroupStatsResponse = Static<typeof TargetGroupStatsResponse>;

export interface TargetGroupRespondentStatistics {
  lastComplete: Iso8601Date;
  responded: number;
  invited: number;
  statuses: RespondentStatuses;
  surveyLinkInfo: SurveyLinkInfo;
  quotaStats: { [quotaName: string]: QuotaStats };
}

const QuotaRespondedStats = Record({
  id: AboveZero,
  name: String,
  responded: NonNegative,
  completes: NonNegative,
  timeouts: NonNegative,
  screenouts: NonNegative,
  quotafulls: Optional(NonNegative),
});

export type QuotaRespondedStats = Static<typeof QuotaRespondedStats>;

export const InvitationMailTemplateResponse = Record({
  subject: String.Or(Null),
  body: String.Or(Null),
  error: String.Or(Null),
});
export type InvitationMailTemplateResponse = Static<typeof InvitationMailTemplateResponse>;

export const InvitationMailTemplatePreviewResponse = String.Or(Null);
export type InvitationMailTemplatePreviewResponse = Static<typeof InvitationMailTemplatePreviewResponse>;

export const AutoDeliveryStrategyResponse = Union(
  Literal(AutoDeliveryStrategy.None),
  Literal(AutoDeliveryStrategy.SystemConvenient),
  Literal(AutoDeliveryStrategy.SpreadCompletesOverTime),
  Literal(AutoDeliveryStrategy.CustomSpread),
  Literal(AutoDeliveryStrategy.Asap)
);
export type AutoDeliveryStrategyResponse = Static<typeof AutoDeliveryStrategyResponse>;

export const AutoDeliveryStatusResponse = Record({
  isEnabled: Boolean,
  strategy: AutoDeliveryStrategyResponse,
});
export type AutoDeliveryStatusResponse = Static<typeof AutoDeliveryStatusResponse>;

export const TargetPeriod = Record({
  to: Iso8601UtcString,
  cutOffFactor: NonNegative,
});
export type TargetPeriod = Static<typeof TargetPeriod>;

export const TentativeAutoDeliveryScheduleResponse = Record({
  targetPeriods: Array(TargetPeriod),
});
export type TentativeAutoDeliveryScheduleResponse = Static<typeof TentativeAutoDeliveryScheduleResponse>;

export const TargetGroupSamplingActivityResponse = Record({
  hasOngoingSampling: Boolean,
});
export type TargetGroupSamplingActivityResponse = Static<typeof TargetGroupSamplingActivityResponse>;

export const TargetGroupSamplingRequirementStatusResponse = Record({
  hasPanelsForSampling: Boolean,
});
export type TargetGroupSamplingRequirementStatusResponse = Static<typeof TargetGroupSamplingRequirementStatusResponse>;

export interface UpdateTargetGroupModel {
  startDate?: Iso8601Date;
  fieldPeriod?: number;
  inFieldLoiOverride?: number;
  fixedIncentiveAmount?: number;
  fixedIncentiveCurrency?: string;
  wantedCompletes?: number;
  wantedStarts?: number;
}

export const UpdateTargetGroupResponse = Record({
  hasErrors: Boolean,
  errors: Array(NonEmptyString),
});
export type UpdateTargetGroupResponse = Static<typeof UpdateTargetGroupResponse>;

export const CreateWorkingDocumentResponse = Record({
  workingDocumentId: GuidString,
});
export type CreateWorkingDocumentResponse = Static<typeof CreateWorkingDocumentResponse>;

export const AddUploadedLinksResponse = Record({
  linkCount: NonNegative,
});
export type AddUploadedLinksResponse = Static<typeof AddUploadedLinksResponse>;

const SuspensionLimit = Record({
  active: Boolean,
  upper: NonNegative.Or(Null),
  lower: NonNegative.Or(Null),
});
export type SuspensionLimit = Static<typeof SuspensionLimit>;

export const SuspensionLimits = Record({
  conversionRate: SuspensionLimit.Or(Null),
  dropOutRate: SuspensionLimit.Or(Null),
  incidenceRate: SuspensionLimit.Or(Null),
  lengthOfInterview: SuspensionLimit.Or(Null),
  quotaFull: SuspensionLimit.Or(Null),
});
export type SuspensionLimits = Static<typeof SuspensionLimits>;

export const HistoryEvent = Record({
  userName: String,
  timeStamp: Iso8601UtcString,
});
export type HistoryEvent = Static<typeof HistoryEvent>;

export const SuspensionLimitsResponse = Record({
  overrideSupported: Boolean,
  current: SuspensionLimits.Or(Null),
  history: Array(HistoryEvent),
  errors: Array(ServiceFeatureError),
});
export type SuspensionLimitsResponse = Static<typeof SuspensionLimitsResponse>;

export interface UpdateSuspensionLimitsModel {
  lengthOfInterviewLimit?: number;
  incidenceRateLimit?: number;
  conversionRateLimit?: number;
  quotaFullLimit?: number;
  dropOutRateLimit?: number;
}

export class TargetGroupHttpService {
  updateTargetGroup(
    projectId: number,
    targetGroupId: number,
    updateModel: UpdateTargetGroupModel
  ): IHttpPromise<UpdateTargetGroupResponse> {
    return $http.validatingPost(UpdateTargetGroupResponse, urlService.updateTargetGroup(projectId, targetGroupId), {
      startDate: updateModel.startDate,
      fieldPeriod: updateModel.fieldPeriod,
      inFieldLoiOverride: updateModel.inFieldLoiOverride,
      fixedIncentiveAmount: updateModel.fixedIncentiveAmount,
      fixedIncentiveCurrency: updateModel.fixedIncentiveCurrency,
      wantedCompletes: updateModel.wantedCompletes,
      wantedStarts: updateModel.wantedStarts,
    });
  }

  getRespondentStatistics(
    projectId: number,
    targetGroupId: number,
    refreshCache: boolean
  ): IPromise<TargetGroupRespondentStatistics> {
    const refreshOption = refreshCache ? '?refreshCache=true' : '';
    const url = urlService.getRespondentStatistics(projectId, targetGroupId) + refreshOption;
    return $http.validatingGet<TargetGroupStatsResponse>(TargetGroupStatsResponse, url).then((res) => {
      const { surveyLinkInfo, respondentStats, quotaStats } = res.data;
      return {
        lastComplete: respondentStats.lastCompleted,
        responded: respondentStats.responded,
        invited: respondentStats.invited,
        statuses: respondentStats.statuses,
        surveyLinkInfo,
        quotaStats: keyBy(quotaStats, 'name'),
      };
    });
  }

  getQuotaRespondedStats(projectId: number, targetGroupId: number, quotaName: string, refreshCache: boolean) {
    const refreshOption = refreshCache ? '?refreshCache=true' : '';
    const url = urlService.getQuotaRespondedStats(projectId, targetGroupId, quotaName) + refreshOption;
    return $http.validatingGet(QuotaRespondedStats, url);
  }

  updateLinkTemplate(projectId: number, targetGroupId: number, links: SurveyLinksUpdateRequest): IHttpPromise<void> {
    return $http.post(urlService.updateLinkTemplate(projectId, targetGroupId), links);
  }

  updateWantedCompletes(
    projectId: number,
    targetGroupId: number,
    newWantedCompletesNumber: number
  ): IHttpPromise<void> {
    return $http.post(urlService.updateTargetGroup(projectId, targetGroupId), {
      wantedCompletes: newWantedCompletesNumber,
    });
  }

  createWorkingDocument(projectId: number, targetGroupId: number): IPromise<GuidString> {
    return $http
      .validatingGet(CreateWorkingDocumentResponse, urlService.createWorkingDocument(projectId, targetGroupId))
      .then((res) => res.data.workingDocumentId);
  }

  deleteWorkingDocument(projectId: number, workingDocumentId: string): IHttpPromise<void> {
    return $http.post(urlService.deleteWorkingDocument(projectId, workingDocumentId), {});
  }

  addUploadedLinksToTargetGroup(
    projectId: number,
    workingDocumentId: string,
    links: SurveyLinksUpdateRequest
  ): IHttpPromise<AddUploadedLinksResponse> {
    return $http.validatingPost(
      AddUploadedLinksResponse,
      urlService.addUploadedLinksToTargetGroup(projectId, workingDocumentId),
      links
    );
  }

  getInvitationMailTemplate(projectId: number, targetGroupId: number): IHttpPromise<InvitationMailTemplateResponse> {
    return $http.validatingGet(
      InvitationMailTemplateResponse,
      urlService.getInvitationMailTemplate(projectId, targetGroupId)
    );
  }

  updateInvitationMailTemplate(
    projectId: number,
    targetGroupId: number,
    subject: string,
    body: string
  ): IHttpPromise<InvitationMailTemplateResponse> {
    const request = {
      subject,
      body,
    };

    return $http.validatingPost(
      InvitationMailTemplateResponse,
      urlService.getInvitationMailTemplate(projectId, targetGroupId),
      request
    );
  }

  previewInvitationMailTemplate(
    projectId: number,
    panelId: number,
    subject: string,
    body: string
  ): IHttpPromise<InvitationMailTemplatePreviewResponse> {
    const request = {
      panelId,
      subject,
      body,
    };

    return $http.validatingPost(
      InvitationMailTemplatePreviewResponse,
      urlService.previewInvitationMailTemplate(projectId),
      request
    );
  }

  getAutoDelivery(projectId: number, targetGroupId: number): IHttpPromise<AutoDeliveryStatusResponse> {
    return $http.validatingGet(AutoDeliveryStatusResponse, urlService.getAutoDelivery(projectId, targetGroupId));
  }

  getSuspensionLimits(projectId: number, targetGroupId: number): IHttpPromise<SuspensionLimitsResponse> {
    return $http.validatingGet(SuspensionLimitsResponse, urlService.getSuspensionLimits(projectId, targetGroupId));
  }

  updateSuspensionLimits(
    projectId: number,
    targetGroupId: number,
    newLimits: UpdateSuspensionLimitsModel
  ): IHttpPromise<any> {
    return $http.put(urlService.setSuspensionLimits(projectId, targetGroupId), newLimits);
  }

  enableAutoDelivery(
    projectId: number,
    targetGroupId: number,
    startDate: Iso8601Date,
    endDate: Iso8601Date,
    strategy: AutoDeliveryStrategy,
    enableOptimizer?: boolean
  ): IHttpPromise<AutoDeliveryStatusResponse> {
    const request = {
      startDate,
      endDate,
      strategy,
      enableOptimizer,
    };

    return $http.validatingPost(
      AutoDeliveryStatusResponse,
      urlService.enableAutoDelivery(projectId, targetGroupId),
      request
    );
  }

  disableAutoDelivery(projectId: number, targetGroupId: number): IHttpPromise<void> {
    return $http.post(urlService.disableAutoDelivery(projectId, targetGroupId), {});
  }

  getTentativeAutodeliverySchedule(
    projectId: number,
    targetGroupId: number,
    deliveryPeriodFrom: Iso8601Date,
    deliveryPeriodTo: Iso8601Date,
    endGoal: number,
    strategy: AutoDeliveryStrategy
  ): IHttpPromise<TentativeAutoDeliveryScheduleResponse> {
    const data = {
      deliveryPeriodFrom,
      deliveryPeriodTo,
      endGoal,
      strategy,
    };

    return $http.validatingPost(
      TentativeAutoDeliveryScheduleResponse,
      urlService.getTentativeAutoDeliverySchedule(projectId, targetGroupId),
      data
    );
  }

  getSamplingActivity(projectId: number, targetGroupId: number): IHttpPromise<TargetGroupSamplingActivityResponse> {
    return $http.validatingGet(
      TargetGroupSamplingActivityResponse,
      urlService.getSamplingActivity(projectId, targetGroupId)
    );
  }

  getSamplingRequirementStatus(
    projectId: number,
    targetGroupId: number
  ): IHttpPromise<TargetGroupSamplingRequirementStatusResponse> {
    return $http.validatingGet(
      TargetGroupSamplingRequirementStatusResponse,
      urlService.getSamplingRequirementStatus(projectId, targetGroupId)
    );
  }

  updateTargetGroupParameters(projectId: number, targetGroupId: number, data: string): IHttpPromise<void> {
    return $http.post(urlService.updateTargetGroupCustomParameters(projectId, targetGroupId), data);
  }

  targetGroupParametersSynced(projectId: number, targetGroupId: number): IHttpPromise<boolean> {
    return $http.get(urlService.targetGroupParametersSynced(projectId, targetGroupId));
  }

  projectParametersSynced(projectId: number): IHttpPromise<boolean> {
    return $http.get(urlService.projectParametersSynced(projectId));
  }

  getTargetGroup(
    projectId: number,
    targetGroupId: number,
    allowCache: boolean
  ): IHttpPromise<ExistingTargetGroupResponse> {
    return $http.validatingGet(
      ExistingTargetGroupResponse,
      `${urlService.getExistingTargetGroup(projectId, targetGroupId)}?allowCache=${allowCache}`
    );
  }

  approveLaunchTargetGroup(projectId: number, targetGroupId: number): IHttpPromise<void> {
    return $http.post(urlService.approveLaunchTargetGroup(projectId, targetGroupId), {});
  }
}

export const targetGroupHttpService = new TargetGroupHttpService();
