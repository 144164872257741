import './project-list.component.scss';
import { analytics } from '../common/analytics';
import { $window, $state, $stateParams } from '../ngimport';
import { TemplatesListType } from '../common/enums';
import { html } from '../helpers';
import { userService } from '../common/user.service';

const selector = 'projectListComponent';

export enum TabIndex {
  Active = 0,
  Drafts = 1,
  Closed = 2,
  Templates = 3,
}

const template = html`
  <div class="project-list">
    <div
      class="project-list__go-up"
      ng-click="$ctrl.scrollToTop()"
      ng-class="{'project-list__go-up_hidden': $ctrl.showScrollToTopButton()}"
    ></div>
    <div class="project-list__content" data-testid="project-lists">
      <div ng-if="$ctrl.hasMigrated" class="alert alert-warning" role="alert" style="line-height:2">
        <p>
          Your account was migrated to the Cint Exchange. Your previous work is still available here for 60 days from
          migration date.
        </p>
        <p>
          Visit
          <a href="https://exchange.cint.com" target="_blank" rel="noopener noreferrer" title="Go to Cint Exchange">
            exchange.cint.com
          </a>
          to start a new project. If you do not have access, please sign the updated terms and conditions emailed from
          <span style="text-decoration-line: underline;"> migration@cint.com</span>.
        </p>
      </div>

      <h1 class="project-list__header" feature-off="dashboard">Projects</h1>

      <div class="alert" role="alert" feature-off="dashboard" style="background:#f3f3f3">
        We have introduced a new project listing in Access. Try it out
        <a ng-click="$ctrl.showNewListView()" title="Go to the new project list view"> here</a>.
      </div>
      <div feature-on="dashboard">
        <a
          class="link-btn"
          ui-sref="project-lists.active"
          ng-if="$ctrl.activeTab === ${TabIndex.Active} || $ctrl.activeTab === ${TabIndex.Closed}"
          ><i class="fa fa-arrow-left"></i> Back to the new project list</a
        >
        <a
          class="link-btn"
          ui-sref="project-lists.active"
          ng-if="$ctrl.activeTab !== ${TabIndex.Active} && $ctrl.activeTab !== ${TabIndex.Closed}"
          ><i class="fa fa-arrow-left"></i> Projects</a
        >
        <div class="push-down">
          <h1 class="project-list__header">
            <span ng-if="$ctrl.activeTab === ${TabIndex.Active}">Projects</span>
            <span ng-if="$ctrl.activeTab === ${TabIndex.Drafts}">Project Drafts</span>
            <span ng-if="$ctrl.activeTab === ${TabIndex.Closed}">Projects</span>
            <span ng-if="$ctrl.activeTab === ${TabIndex.Templates}">Target Group Templates</span>
          </h1>
        </div>
      </div>

      <div class="project-list__tabs" feature-off="dashboard">
        <p
          class="project-list__tab-title"
          ui-sref="projectList"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Active}}"
        >
          Active
        </p>
        <p
          class="project-list__tab-title"
          ui-sref="projectList.drafts"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Drafts}}"
        >
          Drafts
        </p>
        <p
          class="project-list__tab-title"
          ui-sref="projectList.closed"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Closed}}"
        >
          Closed
        </p>
        <p
          class="project-list__tab-title"
          ui-sref="projectList.templates"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Templates}}"
        >
          Templates
        </p>
      </div>
      <div
        class="project-list__tabs"
        feature-on="dashboard"
        ng-if="$ctrl.activeTab === ${TabIndex.Active} || $ctrl.activeTab === ${TabIndex.Closed}"
      >
        <p
          class="project-list__tab-title"
          ui-sref="projectList"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Active}}"
        >
          Active
        </p>
        <p
          class="project-list__tab-title"
          ui-sref="projectList.closed"
          ui-sref-opts="{reload: true}"
          ng-class="{'project-list__tab-title_active': $ctrl.activeTab === ${TabIndex.Closed}}"
        >
          Closed
        </p>
      </div>
      <projects-component ng-show="$ctrl.activeTab === ${TabIndex.Active}" type="'active'"></projects-component>
      <div ng-if="$ctrl.loadedDrafts">
        <drafts-component ng-show="$ctrl.activeTab === ${TabIndex.Drafts}"></drafts-component>
      </div>
      <div ng-if="$ctrl.loadedClosed">
        <projects-component ng-if="$ctrl.activeTab === ${TabIndex.Closed}" type="'closed'"></projects-component>
      </div>
      <div ng-if="$ctrl.loadedTemplates">
        <templates-list-component
          ng-show="$ctrl.activeTab === ${TabIndex.Templates}"
          templates-list-type="'${TemplatesListType.ManageTemplates}'"
        ></templates-list-component>
      </div>
    </div>
  </div>
`;

export class ProjectListComponent {
  static componentName = selector;

  activeTab: TabIndex;
  loadedDrafts = false;
  loadedClosed = false;
  loadedTemplates = false;
  hasMigrated = false;

  $onInit() {
    this.setActiveTab($stateParams.tabIndex);
    this.checkMigrationStatus();
  }

  checkMigrationStatus() {
    this.hasMigrated = !userService.companyAllowedToCreateProjects;
  }

  setActiveTab(tab: TabIndex) {
    this.activeTab = tab;
    if (tab === TabIndex.Active) {
      analytics.projectList.openTab('active');
    }
    if (tab === TabIndex.Drafts) {
      this.loadedDrafts = true;
      analytics.projectList.openTab('drafts');
    }
    if (tab === TabIndex.Templates) {
      this.loadedTemplates = true;
      analytics.projectList.openTab('templates');
    }
    if (tab === TabIndex.Closed) {
      this.loadedClosed = true;
      analytics.projectList.openTab('closed');
    }
  }

  scrollToTop() {
    $window.scrollTo(0, 0);
  }

  showScrollToTopButton() {
    return $window.scrollY < $window.innerHeight;
  }

  showNewListView() {
    analytics.projectList.new();
    $state.go('project-lists.active', null, { reload: true });
  }
}

export const ngProjectListComponent = {
  [selector]: {
    template,
    controller: ProjectListComponent,
  },
};
