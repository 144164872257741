import React, { useCallback } from 'react';
import { IPromise, copy as ngCopy } from 'angular';
import { isEmpty, isFinite, isUndefined, findKey, reduce, flatten, map, debounce } from 'lodash-es';
import { Draft } from 'immer';
import reactStringReplace from 'react-string-replace';
import dayjs from 'dayjs';
import { Constants } from './constants';
import { $window } from './ngimport';
import { RuntimeSettings } from './runtime-settings';
import { FeasibilityResponse } from './common/http-services/feasibility.response';

export type Iso8601Date = string;

export const todayISOString = dayjs().format('YYYY-MM-DD');

export const getMaxDateOfInputAndToday = (dateToCompare: string) => {
  if (dateToCompare == null || dayjs(todayISOString) > dayjs(dateToCompare)) {
    return dayjs(todayISOString).toISOString();
  }
  return dayjs(dateToCompare).toISOString();
};

export const asJsonScalarString = (s: string) => `"${s}"`;

export function keys<T, K extends Extract<keyof T, string>>(obj: T): K[] {
  return Object.keys(obj) as K[];
}

export function isUndefinedOrNull(value: unknown): boolean {
  return value === undefined || value === null;
}

export function silenceRejection(promise: IPromise<unknown>): void {
  if (promise === null || promise === undefined) return;

  if (isPromise(promise)) {
    promise.catch(() => 0);
  }
}

export function isUndefinedOrEmpty(value: unknown): boolean {
  if (isFinite(value)) return false; // NOTE: .isEmpty === true for Numbers (huh?!)
  if (isUndefined(value)) return true;
  if (isEmpty(value)) return true;

  return false;
}

// NOTE: this doesn't work anymore in TS v2.4.1
export type PropertyPredicateFn = <T>() => T;

export function getPropertyName<T>(obj: T, predicate: /*PropertyPredicateFn*/ Function): keyof T {
  const value = predicate();
  return findKey(obj, value) as keyof T;
}

function isPromise<T>(promise: unknown): promise is Promise<T> {
  return (promise as Promise<T>).catch !== undefined;
}

export function cartesianProductOf(arr: any[][]): any[][] {
  return reduce(arr, (a, b) => flatten(map(a, (x) => map(b, (y) => x.concat([y])))), [[]]);
}

export function appInsightsSafeUserId(userName: string): string {
  if (userName === undefined) return '[unknown]';
  return userName.replace(/[,;=| ]+/g, '_');
}

export function isEmptyGuid(input: string): boolean {
  return isEmpty(input) || input === Constants.emptyGuid;
}

export function isGuid(input: string): boolean {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(input);
}

export function isFeasibilityResponse(
  res: FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }
): res is FeasibilityResponse {
  return res !== undefined && 'defaultFeasibility' in res;
}

/** NOTE: ForceMutable<T> is a temporary workaround and if this is used it means we should find a proper fix instead */
export type ForceMutable<T> = Draft<T>;

export function mutate<T>(model: T, action: (model: ForceMutable<T>) => void): T {
  if (__PROCESS__.ENV === 'testing') {
    if (Object.isFrozen(model)) {
      model = ngCopy(model);
    }
  }
  action(model as ForceMutable<T>);
  return model;
}

export function scrollToTop() {
  $window.scrollTo(0, 0);
}

export function* idGenerator(): Generator {
  let id = 0;
  while (id < id + 1) {
    yield id++;
  }
}

export function useDebounce<T extends (...args: any[]) => any>(callback: T, deps: readonly any[], ms: number) {
  // Dependencies will be checked when `useDebounce` is used.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(callback, ms), deps);
}

// prepends the API base URL to an URL. Note that you need a leading slash in your URL
export const apiUrl = (strings: TemplateStringsArray, ...values: readonly (string | number | boolean)[]): string =>
  strings.reduce((acc, curr, i) => `${acc}${curr}${values[i] ?? ''}`, RuntimeSettings.apiUrl);

// acts like a regular template string, but we need this tagged template named `html` for prettier plugin support
export const html = (strings: TemplateStringsArray, ...values: readonly (string | number)[]): string =>
  strings.reduce((acc, curr, i) => `${acc}${curr}${values[i] ?? ''}`, '');

export const highlightSearchResultInJsx = (text: string, searchTerm: string) => {
  if (!searchTerm) return <>{text}</>;

  return reactStringReplace(text, new RegExp(`(${searchTerm})`, 'gi'), (match, i) => (
    <span className="highlighted-search-text" key={i}>
      {match}
    </span>
  ));
};
