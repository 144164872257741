import './survey-links-dialog.component.scss';
import { ui, IFormController, IPromise, IScope } from 'angular';
import { FileUploader as IFileUploader, FileItem } from 'angular-file-upload';
import { compact } from 'lodash-es';
import { inlineHelpService } from '../../inline-help/inline-help.service';
import { HttpsStatusKey, LinkSource } from '../../enums';
import { urlService } from '../../http-services/url.service';
import { userService } from '../../user.service';
import { api } from '../../api';
import { createFileUploader } from '../../file-uploader.factory';
import { ExistingTargetGroup } from '../../http-services/existing-project.models';
import { ProjectOrderTargetGroupModel } from '../../../target-groups/project-order/project-order-target-group.model';
import { surveyLinksChannel } from '../../channels/survey-links-channel';
import { FileUploader } from '../../../ngimport';
import { hotkeyChannel } from '../../channels/hotkey-channel';
import { ListenerComponent } from '../../channels/channel';

import template from './survey-links-dialog.component.html';

const selector = 'surveyLinksDialogComponent';

export interface SurveyLinksDialogResult {
  explicitlyChoseNoLinks?: boolean;
  workingDocumentId?: string;
  targetGroupId: number;
  success: boolean;
  links: {
    testLinks?: string;
    screenout?: string;
    template?: string;
    numberOfLinks?: number;
    unique?: number;
  };
}

export interface SurveyLinksDialogResolve {
  targetGroup: ExistingTargetGroup | ProjectOrderTargetGroupModel;
  linkTemplate: string | IPromise<string>;
  workingDocumentId: string;
  suggestedNumberOfInvites: number;
  projectSettings: FeasibilityProjectSettings;
}

interface Bindings {
  resolve: '<' | SurveyLinksDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export interface FeasibilityProjectSettings {
  categoryIds: number[];
  filterOnPii: boolean;
}

export interface Links {
  template: string;
  testLinks: string;
  screenout: string;
}

interface UploadLinksResponseData {
  links: number;
  uniqueLinkCount: number;
}

enum UploadLinksResponseMessage {
  notEnoughNumberOfLinks = 'notEnoughNumberOfLinks',
  urlTooLong = 'urlTooLong',
  codeTooLong = 'codeTooLong',
  invalidHeader = 'invalidHeader',
  invalidFormat = 'invalidFormat',
}

interface UploadLinksResponse extends Response {
  success: boolean;
  message: UploadLinksResponseMessage | 'fileTooLarge';
  data: UploadLinksResponseData;
}

export class SurveyLinksDialogComponent extends ListenerComponent implements Bindings, SurveyLinksDialogResolve {
  static componentName = selector;

  resolve: SurveyLinksDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;

  targetGroup: any;
  linkTemplate: string;
  currentLinkTemplateHttpsStatus: string;
  linkTemplateHttpsStatus: HttpsStatusKey;
  workingDocumentId: string;
  suggestedNumberOfInvites: number;
  projectSettings: FeasibilityProjectSettings;

  loading = false;
  minimumTestLinkPoolCount = 5;
  showOptionalTagWarning = false;
  linkSources = {
    none: LinkSource.None,
    linkTemplate: LinkSource.LinkTemplate,
    linkPool: LinkSource.LinkPool,
  };
  surveyLinkSource: LinkSource;
  testLinkSource: LinkSource;
  links: Links;
  testLinksTemplate: string; // don't really know what this is...
  numberOfLinks: number;
  unique: number;
  uploadPerformed: boolean;
  uploadFailed: boolean;
  uploadFailedMessage: string;
  templateEntered: boolean;
  testLinksEntered: boolean;
  screenoutEntered: boolean;
  surveyTestingNeeded: boolean;
  fileUploader: IFileUploader;

  private tags: string;
  private mightHaveOptionalTags: RegExp;
  private uploadFailedMessageMap = {
    notEnoughNumberOfLinks: 'You seem to have uploaded too few links, please add links to your file and try again.',
    urlTooLong:
      'It looks to me as if one or several of the supplied links were too long. The maximum length is 1000 characters per link.',
    codeTooLong:
      'It looks to me as if one or several of the supplied codes were too long. The maximum length is 50 characters per code.',
    invalidHeader: `There is a problem with the header of the file you supplied.
            Please check the formatting so that it corresponds to the template file.`,
    invalidFormat: `I'm sorry, I could not understand the data content of the file you supplied.
            Please check the formatting so that it corresponds to the template file.`,
    fileTooLarge: 'It seems like the file you tried to upload is too large. The maximum size is 10MB',
  };

  constructor(private $scope: IScope) {
    'ngInject';

    super();

    this.tags = userService.allowPiiKeywordTagsOnUrls ? '(M|P|PC|S|YOB)' : '(M|S|YOB)';

    this.mightHaveOptionalTags = new RegExp(
      [`({${this.tags}})`, '|', `(<${this.tags}>)`, '|', `(\\(${this.tags}\\))`, '|', `(\\|${this.tags}\\|)`].join('')
    );
  }

  $onInit() {
    this.targetGroup = this.resolve.targetGroup;
    this.linkTemplate = this.resolve.linkTemplate as string;
    if (this.linkTemplate) {
      api.validation.getLinkHttpsStatus(this.linkTemplate).then((res) => {
        this.currentLinkTemplateHttpsStatus = res.data;
      });
    }
    this.workingDocumentId = this.resolve.workingDocumentId;
    this.suggestedNumberOfInvites = this.resolve.suggestedNumberOfInvites;
    this.projectSettings = this.resolve.projectSettings;

    this.$scope.$watch(
      () => this.surveyLinkSource,
      (newValue) => {
        if (newValue === LinkSource.LinkPool) {
          this.useUniqueLinks();
        } else {
          this.useLinkTemplate();
        }
      }
    );
    inlineHelpService.visibleFor['survey-links-dialog'] = false;
    this.setLinkDataFromTargetGroup();
    this.surveyTestingNeeded = userService.surveyTestingNeeded;

    this.fileUploader = createFileUploader<UploadLinksResponse>(FileUploader, {
      url: urlService.uploadSurveyLinks(
        this.workingDocumentId,
        this.targetGroup.id,
        this.suggestedNumberOfInvites || 0
      ),
      maxFileSize: 10485760,
      onProgressItem: this.onUploadStarted,
      onSuccessItem: this.onUploadSuccess,
      onTooLargeFile: this.onTooLargeFile,
    });

    hotkeyChannel.ctrlQ.pressed.listen(this.fillForm, this);
  }

  confirm(): void {
    const result: SurveyLinksDialogResult =
      this.surveyLinkSource === LinkSource.LinkTemplate
        ? {
            targetGroupId: this.targetGroup.id,
            links: {
              template: this.links.template || '',
              testLinks: this.links.testLinks || '',
              screenout: this.links.screenout || '',
            },
            workingDocumentId: this.workingDocumentId,
            success: true,
          }
        : {
            targetGroupId: this.targetGroup.id,

            links: {
              numberOfLinks: this.numberOfLinks,
              unique: this.unique,
              testLinks: this.links.testLinks,
              screenout: this.links.screenout,
            },
            workingDocumentId: this.workingDocumentId,
            success: true,
          };
    surveyLinksChannel.result.dispatch(result);
    this.modalInstance.close();
  }

  cancel(): void {
    const result: SurveyLinksDialogResult = {
      links: null,
      success: false,
      workingDocumentId: this.workingDocumentId,
      targetGroupId: this.targetGroup.id,
    };
    surveyLinksChannel.result.dispatch(result);
    this.modalInstance.dismiss();
  }

  // these have to be lambdas, or they won't work with file-uploader
  onUploadStarted = (_files: any): void => {
    this.numberOfLinks = 0;
    this.uploadFailed = false;
  };

  onTooLargeFile = () => {
    if (this.targetGroup.links && this.targetGroup.links.data) {
      this.targetGroup.links.data.uploaded = 0;
    }
    this.numberOfLinks = 0;
    this.uploadFailed = true;
    this.uploadFailedMessage = this.uploadFailedMessageMap.fileTooLarge;
  };

  onUploadSuccess = (_file: FileItem, response: UploadLinksResponse, _httpCode: number, _headers: Headers): void => {
    if (response.success) {
      this.numberOfLinks = response.data.links;
      this.unique = response.data.uniqueLinkCount;
      this.uploadPerformed = true;
      this.uploadFailed = false;
    } else {
      this.onUploadError(response);
    }
  };

  onUploadError = (response: UploadLinksResponse): void => {
    if (this.targetGroup.links && this.targetGroup.links.data) {
      this.targetGroup.links.data.uploaded = 0;
    }

    this.numberOfLinks = 0;
    this.uploadFailed = true;
    this.uploadFailedMessage = response.message
      ? this.uploadFailedMessageMap[response.message]
      : 'An error occurred when trying to upload file. Please try again.';
  };

  autoFillLink(what: keyof Links): void {
    if (__PROCESS__.ENV !== 'development') {
      return;
    }
    if ((this.links[what] || '').length > 0) {
      return;
    }

    if (what === 'testLinks' && this.testLinkSource === LinkSource.LinkPool) {
      this.links.testLinks = [
        'http://www.survey.com/1/[ID]',
        'http://www.survey.com/2/[ID]',
        'http://www.survey.com/3/[ID]',
        'http://www.survey.com/4/[ID]',
        'http://www.survey.com/5/[ID]',
      ].join('\n');
    } else {
      this.links[what] = 'http://www.survey.com/[ID]';
    }
  }

  clearTestLinks(): void {
    this.links.testLinks = '';
  }

  templateChanged(): void {
    this.showOptionalTagWarning = this.templateMightHaveOptionalTags();
    this.validateHttpsStatus();
  }

  validateHttpsStatus(): void {
    this.linkTemplateHttpsStatus = HttpsStatusKey.Unknown;
    if (this.links.template) {
      api.validation.getLinkHttpsStatus(this.links.template).then((res) => {
        this.linkTemplateHttpsStatus = res.data;
      });
    }
  }

  canConfirm(linkTemplateForm: IFormController, testLinksForm: IFormController, uploadForm: IFormController): boolean {
    let formToValidate = linkTemplateForm;
    if (this.surveyLinkSource === LinkSource.LinkPool) {
      if (this.notEnoughLinks()) return false;
      formToValidate = uploadForm;
    }

    const testLinkCount = compact((this.links.testLinks || '').split(/[\n]/)).length;
    if (this.testLinkSource === LinkSource.LinkPool && testLinkCount < this.minimumTestLinkPoolCount) return false;

    return formToValidate.$valid && testLinksForm.$valid;
  }

  private setLinkDataFromTargetGroup(): void {
    this.surveyLinkSource = this.getSurveyLinkSource();
    this.testLinkSource = this.getTestLinkSource();
    this.links = this.getLinks();
    this.numberOfLinks = this.getNumberOfLinks();
    this.unique = this.getNumberOfUnique();
  }

  private templateMightHaveOptionalTags(): boolean {
    return this.mightHaveOptionalTags.test(this.links.template);
  }

  private notEnoughLinks(): boolean {
    return this.numberOfLinks < this.suggestedNumberOfInvites;
  }

  private getSurveyLinkSource(): LinkSource {
    return this.targetGroup.surveyLinkSource === LinkSource.LinkPool ? LinkSource.LinkPool : LinkSource.LinkTemplate;
  }

  private getTestLinkSource(): LinkSource {
    const testLinks = this.getTestLinks();
    if (testLinks === null) return LinkSource.LinkTemplate;

    return testLinks.split(/[\n]/).length >= this.minimumTestLinkPoolCount
      ? LinkSource.LinkPool
      : LinkSource.LinkTemplate;
  }

  private getLinks(): Links {
    return {
      template: this.getLiveLink(),
      testLinks: this.getTestLinks(),
      screenout: this.getScreenoutInformation(),
    };
  }

  private getLiveLink(): string {
    return this.targetGroup.links ? this.targetGroup.links.data.template : null;
  }

  private getTestLinks(): string {
    return this.targetGroup.testLinks || (this.targetGroup.links ? this.targetGroup.links.data.testLinks : null);
  }

  private getScreenoutInformation(): string {
    return (
      this.targetGroup.screenoutInformation || (this.targetGroup.links ? this.targetGroup.links.data.screenout : null)
    );
  }

  private getNumberOfLinks(): number {
    return this.targetGroup.links && this.targetGroup.links.data ? this.targetGroup.links.data.uploaded || 0 : 0;
  }

  private getNumberOfUnique(): number {
    return this.targetGroup.links && this.targetGroup.links.data ? this.targetGroup.links.data.unique || 0 : 0;
  }

  private useLinkTemplate(): void {
    this.loading = false;
  }

  private setFileUploaderUrl(): void {
    this.fileUploader.url = urlService.uploadSurveyLinks(
      this.workingDocumentId,
      this.targetGroup.id,
      this.suggestedNumberOfInvites
    );
  }

  private useUniqueLinks(): void {
    if (!this.suggestedNumberOfInvites) {
      this.loading = true;

      api.feasibility
        .calculateSuggestedNumberOfInvites(this.targetGroup)
        .then((res) => {
          this.suggestedNumberOfInvites = res;
          this.setFileUploaderUrl();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private fillForm(): void {
    this.$scope.$applyAsync(() => {
      this.links.template = 'http://www.example.com/survey.html?id=[ID]';
      this.links.testLinks = 'http://www.example.com/survey.html?id=[ID]';
      this.links.screenout = 'http://www.example.com/survey.html?id=[ID]';
    });
  }
}

export const ngSurveyLinksDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: SurveyLinksDialogComponent,
  },
};
