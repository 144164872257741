import { IPromise, toJson, fromJson, IHttpResponse } from 'angular';
import { isEmpty } from 'lodash-es';
import { MD5 } from 'object-hash';
import { api } from './api';
import { errorLogger } from './error-logger';
import { isEmptyGuid } from '../helpers';
import { TargetGroupModel } from './models/target-group.model';
import { targetGroupChannel } from '../target-groups/channels/target-group-channel';
import { $q } from '../ngimport';
import { StorageFactory } from './storage.factory';
import { PanelistEntries } from './models/supply.model';
import { CreatePanelistPoolResponse } from './http-services/supply.httpservice';

interface PanelistPoolCache {
  [key: string]: CreatePanelistPoolResponse;
}

export class PanelistPoolStorageService {
  private storage: Storage;
  private storageKey = 'panelistPoolCache';

  constructor() {
    this.storage = StorageFactory.getStorage();
  }

  addForUrl(sourceUrl: string, poolId: string): void {
    this.add(sourceUrl, poolId);
  }

  addForPanelistEntries(panelistEntries: PanelistEntries, poolId: string): void {
    this.add(panelistEntries, poolId);
  }

  getFromUrl(sourceUrl: string): IPromise<CreatePanelistPoolResponse> {
    return this.get(sourceUrl, sourceUrl, (url) => api.supply.createPanelistPoolFromUrl(url));
  }

  getFromPanelistEntries(panelistEntries: PanelistEntries): IPromise<CreatePanelistPoolResponse> {
    return this.get(panelistEntries, panelistEntries, (entries) =>
      api.supply.createPanelistPoolFromPanelistEntries(entries)
    );
  }

  deletePanelistEntries(panelistEntries: PanelistEntries): void {
    this.delete(MD5(panelistEntries));
  }

  delete(key: string): void {
    const cache = this.load();
    if (!isEmpty(cache) && !isEmpty(cache[key])) {
      delete cache[key];
      this.persist(cache);
    }
  }

  clear(): void {
    this.persist({});
  }

  private add(source: PanelistEntries | string, poolId: string): void {
    const cache = this.load() || {};
    cache[MD5(source)] = { poolId } as CreatePanelistPoolResponse;
    this.persist(cache);
  }

  private get(
    source: PanelistEntries | string,
    key: string,
    createPoolFunction: (input: PanelistEntries | string) => IPromise<IHttpResponse<CreatePanelistPoolResponse>>
  ): IPromise<CreatePanelistPoolResponse> {
    const cache = this.load() || {};
    const hashedKey = MD5(key);
    const cachedResponse = cache[hashedKey];
    const poolId = cachedResponse?.poolId;
    if (!isEmptyGuid(poolId)) return $q.when(cachedResponse);

    return createPoolFunction(source)
      .then((res) => {
        if (isEmptyGuid(res.data.poolId)) throw new Error('backend returned empty panelist pool guid');
        cache[hashedKey] = res.data;
        targetGroupChannel.model.panelistPool.created.dispatch(res.data);
        this.persist(cache);
        return res.data;
      })
      .catch((err) => {
        errorLogger.errorWithData('unable to create panelist pool for {source}', [source], err);
        targetGroupChannel.model.panelistPool.error.dispatch(source);
        return $q.when({
          poolId: undefined,
          duplicationCount: undefined,
          receivedCount: undefined,
          committedCount: undefined,
          invalidIdCount: undefined,
        });
      });
  }

  private load(): PanelistPoolCache {
    return fromJson(this.storage.getItem(this.storageKey));
  }

  private persist(cache: PanelistPoolCache): void {
    this.storage.setItem(this.storageKey, toJson(cache));
  }
}

export function cachePanelistPoolIdsIfPresent(
  tg: TargetGroupModel,
  panelistPoolStorageService: PanelistPoolStorageService
) {
  const { panelistPool } = tg.panels;
  if (!panelistPool || isEmptyGuid(panelistPool.selectedPoolId)) return;

  if (panelistPool.sourceUrl) {
    panelistPoolStorageService.addForUrl(panelistPool.sourceUrl, panelistPool.selectedPoolId);
  } else {
    panelistPoolStorageService.addForPanelistEntries(panelistPool.input, panelistPool.selectedPoolId);
  }
}

export const panelistPoolStorageService = new PanelistPoolStorageService();
