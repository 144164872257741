export class Constants {
  static get emptyGuid() {
    return '00000000-0000-0000-0000-000000000000';
  }
  static get startupConfigUrl() {
    return '/startup.config.json';
  }
  static get accessDeniedPageUrl() {
    return '/access-denied.html';
  }
  static get httpTimeoutHeaderName() {
    return 'cint-access-request-timeout';
  }
  static get defaultProjectName() {
    return 'Untitled project';
  }
  static get externalIdsInputToken() {
    return 'External_IDs';
  }

  static get maxNumberOfCompletes() {
    return 99999;
  }

  static get maxNumberOfStarts() {
    return Constants.maxNumberOfCompletes * 10;
  }

  static get regionQuotaGroupPrefix() {
    return 'Region group:';
  }
  static profilingQuotaGroupPrefix(questionId: number) {
    return `Profiling group (${questionId}):`;
  }

  static get helpLinks() {
    return {
      frontPage: 'https://cintaccess.zendesk.com/hc/en-us',
      customCpi: '',
      surveyMetadata: '',
      surveyLinks: 'https://cintaccess.zendesk.com/hc/en-us/articles/360039641651-Survey-links',
      autoDelivery: 'https://cintaccess.zendesk.com/hc/en-us/articles/360039648091-Collecting-completes-automatically',
      inviteSpecificPanelists:
        'https://cintaccess.zendesk.com/hc/en-us/articles/360039606431-Invite-specific-panelists',
    };
  }
}
