import { keys } from '../helpers';

export class InMemoryStorage implements Storage {
  [index: number]: string;
  [key: string]: any;

  length = 0;
  private storage: { [p: string]: any } = {};

  clear(): void {
    this.length = 0;
    this.storage = {};
  }

  getItem(key: string): string | null {
    const item = this.storage[key];
    return typeof item !== 'undefined' ? item : null;
  }

  key(index: number): string | null {
    let counter = 0;
    let keyName = null;

    for (const key of keys(this.storage)) {
      if (counter === index) {
        keyName = key;
        break;
      }
      counter++;
    }
    return keyName;
  }

  removeItem(key: string): void {
    if (typeof this.storage[key] !== 'undefined') {
      this.length--;
    }

    delete this.storage[key];
  }

  setItem(key: string, data: string): void {
    if (typeof this.storage[key] === 'undefined') {
      this.length++;
    }

    this.storage[key] = data;
  }
}
