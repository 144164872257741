import './ProjectPrice.scss';
import '../../mixins.scss';

import { useEffect, useState } from 'react';
import { every, isEmpty, maxBy } from 'lodash';
import { react2angular } from 'react2angular';
import { ExistingProject, ExistingTargetGroupSummary } from '../http-services/existing-project.models';
import { InternalPriceResponse, ProjectPriceResponse } from '../http-services/project-price.response';
import { api } from '../api';
import { priceTrendService, TrendDirection } from './price-trend.service';

import { PriceDetails, PriceDetailModel } from './PriceDetails';
import { ProjectStatus, TargetGroupStatus } from '../enums';
import { infieldPriceService } from '../../overview/infield-price.service';

interface Props {
  project: ExistingProject;
  helpTextId: string;
  fetchInvoice: boolean;
}

const mapToPriceDetailModel = (internalPrice: InternalPriceResponse) => {
  return !isEmpty(internalPrice)
    ? {
        current: {
          priceTotal: internalPrice.currentTotal,
          price: internalPrice.current,
        },
        initial: {
          priceTotal: internalPrice.initialTotal,
          price: internalPrice.initial,
        },
        projected: {
          priceTotal: internalPrice.projectedTotal,
          price: internalPrice.projected,
        },
      }
    : (null as PriceDetailModel);
};

export const ProjectPrice: React.FC<Props> = ({ project, helpTextId, fetchInvoice }) => {
  const [price, setPrice] = useState<ProjectPriceResponse>();
  const [priceTrendDirection, setPriceTrendDirection] = useState<TrendDirection>();
  const maximumTargetGroupCompletes = maxBy(
    project.targetGroups,
    (tg) => tg.actualNumberOfCompletes
  ).actualNumberOfCompletes;
  const maximumTargetGroupResponded = maxBy(project.targetGroups, (tg) => tg.responded).responded;
  const allTgsArePending = every(
    project.targetGroups,
    (tg: ExistingTargetGroupSummary) => tg.status === TargetGroupStatus.Pending
  );

  useEffect(() => {
    api.pricing.getProjectPrice(project.id, fetchInvoice).then((res) => {
      const price = res.data;
      infieldPriceService.persist(price);
      setPrice(price);
      setPriceTrendDirection(
        priceTrendService.priceTrend(maximumTargetGroupCompletes, maximumTargetGroupResponded, price)
      );
    });
  }, [project]);

  if (!price) return <div className="push-down wait-spinner" />;
  const internalPriceModel = mapToPriceDetailModel(price.internalPrice);
  return (
    <div className="project-price-component">
      <PriceDetails
        price={price}
        internalPrice={internalPriceModel}
        isClosed={project.status === ProjectStatus.Closed}
        isPending={allTgsArePending}
        responded={maximumTargetGroupResponded}
        completes={maximumTargetGroupCompletes}
        priceTrend={priceTrendDirection}
        helpTextId={helpTextId}
        helpTexts={{
          initial: 'The price the project was ordered at, based on the estimated price of all target groups',
          current:
            'The price if the project were to be closed right now, based on the current price of all target groups',
          projected:
            'The price the project would cost if all wanted completes are collected, based on the projected price of all target groups',
          invoice:
            'The price if the project were to be closed right now, based on the current price of all target groups converted to the billing currency',
        }}
      />
    </div>
  );
};

export const ngProjectPriceReactComponent = {
  projectPrice: react2angular(ProjectPrice, ['project', 'helpTextId', 'fetchInvoice']),
};
