import { keys } from '../helpers';
import { StorageFactory } from './storage.factory';

const showSessionStateDebugInfo = 'showSessionStateDebugInfo';
const showBasicSettingsValidation = 'showBasicSettingsValidation';
const logPubSubToConsole = 'logPubSubToConsole';
const enableTransitionTracing = 'enableTransitionTracing';
const pauseAutoReload = 'pauseAutoReload';
const showImmerPatches = 'showImmerPatches';
const disableCompressRequests = 'disableCompressRequests';

export interface DebugOptions {
  [showSessionStateDebugInfo]: boolean;
  [showBasicSettingsValidation]: boolean;
  [logPubSubToConsole]: boolean;
  [enableTransitionTracing]: boolean;
  [pauseAutoReload]: boolean;
  [showImmerPatches]: boolean;
  [disableCompressRequests]: boolean;
}

export class GlobalDebugOptions {
  options: DebugOptions = {
    [showSessionStateDebugInfo]: false,
    [showBasicSettingsValidation]: false,
    [logPubSubToConsole]: false,
    [enableTransitionTracing]: false,
    [pauseAutoReload]: false,
    [showImmerPatches]: false,
    [disableCompressRequests]: false,
  };

  private storage: Storage;
  private storageKeyPrefix = 'globalDebugOptions';

  constructor() {
    this.storage = StorageFactory.getStorage();
    this.load();
  }

  load(): void {
    for (const option of keys(this.options)) {
      this.options[option] = this.getOption(option);
    }
  }

  persist(): void {
    for (const option of keys(this.options)) {
      this.persistOption(option);
    }
  }

  private getOption(option: keyof DebugOptions): boolean {
    return this.storage.getItem(`${this.storageKeyPrefix}:${option}`) === 'true';
  }

  private persistOption(option: keyof DebugOptions): void {
    if (this.options[option]) {
      this.storage.setItem(`${this.storageKeyPrefix}:${option}`, 'true');
    } else {
      this.storage.removeItem(`${this.storageKeyPrefix}:${option}`);
    }
  }
}

export const globalDebugOptions = new GlobalDebugOptions();
