import React from 'react';
import './modal-close-button.component.scss';

interface Props {
  onClose: () => void;
  disabled?: boolean;
}

export const ModalCloseButton: React.FC<Props> = ({ onClose, disabled }) => {
  const getClass = () => {
    return disabled ? 'sticky-close-button disabled-close-button' : 'sticky-close-button';
  };

  return (
    <div className="modal-close-button-component">
      <div className="modal-close-button-container">
        <div className={getClass()} onClick={onClose}>
          <i className="fas fa-times" />
        </div>
      </div>
    </div>
  );
};
