import { IHttpPromise } from 'angular';
import { Record, Static, String, Array, Null, Boolean } from 'runtypes';
import { NonNegative, Iso8601UtcString, AboveZero, HighlightedString } from '../../custom-runtypes';
import {
  DeviationFilter,
  ProjectChannel,
  ProjectGroupSortField,
  ProjectStatus,
  ProjectUserFilter,
  SortField,
} from '../enums';
import { urlService } from './url.service';
import { $http } from '../../ngimport';
import { featureFlipper } from '../feature-flipper';
import { projectListsSettingsRepository } from '../../dashboard/project-lists-filter-settings.repository';

export const ProjectSearchHit = Record({
  id: AboveZero,
  name: HighlightedString,
  created: Iso8601UtcString,
  createdByName: HighlightedString,
  wantedCompletes: NonNegative,
  currentCompletes: NonNegative,
  wantedCompletesOpen: NonNegative,
  currentCompletesOpen: NonNegative,
  remainingCompletes: NonNegative,
  remainingCompletesOpen: NonNegative,
  status: String, //TODO: should this be one of ProjectStatus, ProjectListItemStatus enums?
  poNumber: HighlightedString.Or(Null),
  jobNumber: HighlightedString.Or(Null),
  channel: String, //TODO: should this be ProjectChannel enum?
  contact: HighlightedString.Or(Null),
  firstStartDate: Iso8601UtcString,
  firstStartDateOpen: Iso8601UtcString.Or(Null),
  firstEndDateOpen: Iso8601UtcString.Or(Null),
  lastEndDateOpen: Iso8601UtcString.Or(Null),
  onHoldTgsCount: NonNegative,
  oldestLastActivity: Iso8601UtcString.Or(Null),
  activeTgsCount: NonNegative,
  pendingTgsCount: NonNegative,
  projectLastActivity: Iso8601UtcString.Or(Null),
  lastEndDate: Iso8601UtcString,
  totalTgsCount: NonNegative,
  hasStaleTargetGroups: Boolean,
  hasLaggingTargetGroups: Boolean,
  autoCloseDate: Iso8601UtcString.Or(Null),
});
export type ProjectSearchHit = Static<typeof ProjectSearchHit>;

export const ProjectGroupSearchHit = Record({
  id: String,
  name: HighlightedString,
  created: Iso8601UtcString,
  createdBy: String,
  projectIds: Array(AboveZero),
  lastStatisticsChange: Iso8601UtcString,
});
export type ProjectGroupSearchHit = Static<typeof ProjectGroupSearchHit>;

export const TargetGroupListModel = Record({
  name: String,
  id: AboveZero,
  isStale: Boolean,
  isLagging: Boolean,
  status: String, //TODO: should this be TargetGroupStatus enum?
  lastActivity: Iso8601UtcString.Or(Null),
  currentCompletes: NonNegative,
  wantedCompletes: NonNegative,
  countryId: AboveZero,
  endDate: Iso8601UtcString,
  startDate: Iso8601UtcString,
  currentExpectedCompletes: NonNegative.Or(Null),
});
export type TargetGroupListModel = Static<typeof TargetGroupListModel>;

export const ProjectSearchResponse = Record({
  paginationToken: String.Or(Null),
  hits: Array(ProjectSearchHit),
  totalHitCount: NonNegative,
});
export type ProjectSearchResponse = Static<typeof ProjectSearchResponse>;

export const ProjectGroupSearchResponse = Record({
  paginationToken: String.Or(Null),
  hits: Array(ProjectGroupSearchHit),
  totalHitCount: NonNegative,
});
export type ProjectGroupSearchResponse = Static<typeof ProjectGroupSearchResponse>;

export class ProjectSearchHttpService {
  getActiveProjects(
    scope: ProjectUserFilter,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    return $http.validatingGet(ProjectSearchResponse, urlService.getActiveProjects(), {
      params: {
        ownerFilter: scope,
        descending,
        sortField,
        paginationToken,
      },
    });
  }

  getUnclosedProjects(
    scope: ProjectUserFilter,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    return $http.validatingGet(
      ProjectSearchResponse,
      featureFlipper.isEnabled('autoCloseProject')
        ? urlService.getAutoClosingProjects()
        : urlService.getUnclosedProjects(),
      {
        params: {
          ownerFilter: scope,
          descending,
          sortField,
          paginationToken,
        },
      }
    );
  }

  getRecentProjects(
    scope: ProjectUserFilter,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    return $http.validatingGet(ProjectSearchResponse, urlService.searchProjectsV2(), {
      params: {
        ownerFilter: scope,
        descending,
        sortField,
        paginationToken,
      },
    });
  }

  getDeviatingProjects(
    scope: ProjectUserFilter,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    return $http.validatingGet(ProjectSearchResponse, urlService.getDeviatingProjects(), {
      params: {
        ownerFilter: scope,
        descending,
        sortField,
        paginationToken,
      },
    });
  }

  getGroupProjects(
    scope: ProjectUserFilter,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    const groupId = projectListsSettingsRepository.activeProjectGroup?.id;

    return !groupId
      ? null
      : $http.validatingGet(ProjectSearchResponse, urlService.getGroupProjects(), {
          params: {
            ownerFilter: scope,
            groupId,
            descending,
            sortField,
            paginationToken,
          },
        });
  }

  searchProjects(
    channels: ProjectChannel[],
    scope: ProjectUserFilter,
    projectStatuses: ProjectStatus[],
    countries: number[],
    deviationFilters: DeviationFilter[],
    projectGroups: string[],
    searchString: string,
    descending: boolean,
    sortField: SortField,
    paginationToken: string
  ): IHttpPromise<ProjectSearchResponse> {
    return $http.validatingGet(ProjectSearchResponse, urlService.searchProjectsV2(), {
      params: {
        ownerFilter: scope,
        channels,
        projectStatuses,
        countries,
        deviationFilters,
        projectGroups,
        sq: searchString,
        descending,
        sortField,
        paginationToken,
      },
    });
  }

  searchProjectGroups(
    descending: boolean,
    sortField: ProjectGroupSortField,
    paginationToken: string,
    disablePagination = false,
    searchQuery: string = null
  ): IHttpPromise<ProjectGroupSearchResponse> {
    return $http.validatingGet(ProjectGroupSearchResponse, urlService.searchProjectGroups(), {
      params: {
        sortField,
        descending,
        paginationToken,
        disablePagination,
        searchQuery,
      },
    });
  }

  getTargetGroups(projectId: number, deviatingOnly = false): IHttpPromise<TargetGroupListModel[]> {
    return $http.validatingGet(Array(TargetGroupListModel), urlService.getTargetGroupList(projectId, deviatingOnly));
  }
}

export const projectSearchHttpService = new ProjectSearchHttpService();
