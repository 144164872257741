import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { $window } from '../../ngimport';

const directiveName = 'onClickOutside';

export function onClickOutsideDirective(): IDirective {
  return {
    restrict: 'A',
    link: (scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      function handleBodyClick(e: MouseEvent) {
        if (elem[0] === e.target || contains(elem[0], e.target)) return;
        scope.$applyAsync(() => {
          $window.removeEventListener('click', handleBodyClick);
          scope.$eval(attrs[directiveName]);
        });
      }

      function contains(parent: HTMLElement, child: EventTarget) {
        let node = child as HTMLElement;
        // eslint-disable-next-line no-cond-assign
        while ((node = node.parentNode as HTMLElement) !== null && node !== parent) {
          // just here not to have an empty body
        }
        return node !== null;
      }

      function listenToElemClick(_e: MouseEvent) {
        $window.addEventListener('click', handleBodyClick);
      }

      elem[0].addEventListener('click', listenToElemClick);

      scope.$on('$destroy', () => {
        elem[0].removeEventListener('click', listenToElemClick);
      });
    },
  };
}

export const ngOnClickOutsideDirective = {
  [directiveName]: onClickOutsideDirective,
};
