import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { toNumber } from 'lodash-es';
import { $document, $window } from '../../ngimport';

const directiveName = 'onScrollIntoView';

export function onScrollIntoViewDirective(): IDirective {
  return {
    restrict: 'A',
    link: (scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      let isVisible = false;
      const margin = toNumber(attrs.margin) || 0;
      function checkOnScroll() {
        const rect = elem[0].getBoundingClientRect();
        if (
          !isVisible &&
          rect.bottom - margin <= $window.innerHeight &&
          rect.top + margin > 0 &&
          rect.top &&
          rect.bottom
        ) {
          isVisible = true;
          scope.$apply(() => {
            scope.$eval(attrs[directiveName]);
          });
        }
        if (
          isVisible &&
          (rect.bottom - margin > $window.innerHeight || rect.top + margin < 0) &&
          rect.top &&
          rect.bottom
        ) {
          isVisible = false;
        }
      }

      $document[0].addEventListener('scroll', checkOnScroll);

      scope.$on('$destroy', () => {
        $document[0].removeEventListener('scroll', checkOnScroll);
      });
    },
  };
}

export const ngOnScrollIntoViewDirective = {
  [directiveName]: onScrollIntoViewDirective,
};
