import { ActiveIncentivesModel } from '../../models/active-incentives.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { incentivesSubactions } from './incentives.subactions';
import { stateDebugService } from '../../../../common/state-debug.service';

type IncentivesUpdateFunc = (state: ActiveTargetGroupModel, commit: CommitFuncs, done: () => void) => void;

export const commitIncentives =
  () =>
  (incentivesState: ActiveIncentivesModel): void => {
    activeTargetGroupStore.model.incentives = incentivesState;
    targetGroupChannel.model.incentives.updated.dispatch();
  };

export class IncentivesActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  // TODO: skip feasibility but perform pricing?
  setFixedIncentiveAmount(amount: number) {
    this.update((state, commit, done) => {
      const newState = incentivesSubactions.setFixedIncentiveAmount(state.incentives, amount);
      commit.incentives(newState);
      done();
    });
  }

  // TODO: skip feasibility but perform pricing?
  useDefaultIncentive() {
    this.update((state, commit, done) => {
      const newState = incentivesSubactions.useDefaultIncentive(state.incentives);
      commit.incentives(newState);
      done();
    });
  }

  private update(updateFunc: IncentivesUpdateFunc) {
    stateDebugService.logIfUnsafeUpdate('incentive');
    this.rootStore.signalUpdateStarted();

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, () => this.rootStore.publishUpdate());
  }
}
