import { useEffect, useRef, useState } from 'react';
import './CustomCollapse.scss';

interface Props {
  expanded: boolean;
  deps?: any[]; // use to force update based on things that cannot be detected though the "children" prop.
  minHeight?: number;
  children: React.ReactNode;
}

export const CustomCollapse: React.FC<Props> = ({ expanded, deps = [], minHeight = 0, children }) => {
  const contentNode = useRef<HTMLDivElement>(undefined);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (expanded) setHeight(contentNode.current?.getBoundingClientRect().height);
    else setHeight(minHeight);
  }, [expanded, children, ...deps]);

  return (
    <div className="custom-collapse" style={{ height }}>
      <div ref={contentNode} className="content">
        {children}
      </div>
    </div>
  );
};
