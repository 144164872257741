import './BillingInfo.scss';
import { isString } from 'lodash-es';
import reactStringReplace from 'react-string-replace';
import { BillingInfoResponse } from '../http-services/pricing.httpservice';
import { featureFlipper } from '../feature-flipper';

type Props = {
  billingInfo: BillingInfoResponse;
};

const BILLING_EMAIL = 'accountsreceivable@cint.com';

export const BillingInfo: React.FC<Props> = ({ billingInfo }) => {
  return (
    <div className="billing-info">
      {!billingInfo && (
        <div className="billing-info push-down">
          <div className="panel-body wait-spinner" />
        </div>
      )}
      {billingInfo && (
        <div className="billing-info panel-body">
          <div className="billing-information-address">
            <BillingAddress address={billingInfo.address} />
          </div>
          {featureFlipper.isEnabled('billingEntity') && billingInfo.billingEntity && (
            <div className="billing-information-entity">
              <span>Billing entity: {billingInfo.billingEntity.name}</span>
              <br />
              <span>Billing currency: {billingInfo.billingEntity.currencyCode}</span>
            </div>
          )}
          <div className="billing-information-email">
            {billingInfo.email && (
              <div className="push-down-more" ng-show="$ctrl.billing.email">
                <span>{billingInfo.email}</span>
              </div>
            )}
            {billingInfo.ccEmails &&
              billingInfo.ccEmails.length > 0 &&
              billingInfo.ccEmails.map((cc) => (
                <div key={cc}>
                  <span>{cc}</span>
                </div>
              ))}

            <div className="push-down-more billing-information-footer">
              <span className="push-down-more">
                Is your billing information out of date?
                <br />
                Contact <a href={`mailto:${BILLING_EMAIL}`}>{BILLING_EMAIL}</a>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BillingAddress: React.FC<{ address: string }> = ({ address }) => {
  const addressIsMissing = !(isString(address) && address.length > 0);
  if (addressIsMissing) return <div>Adress N/A</div>;
  return (
    <>
      {reactStringReplace(address, /(\n)/g, (_, i) => (
        <br key={i} />
      ))}
    </>
  );
};
