import { Boolean, Literal, Null, Number, Record, Static, String, Union, Array } from 'runtypes';
import { AboveZero, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { BucketKeyResponse } from './quota-responses';
import { WeightingResult } from '../enums';

export const PanelCompletes = Record({
  panelId: AboveZero,
  feasibleCompletes: NonNegative,
});
export type PanelCompletes = Static<typeof PanelCompletes>;

export const FeasibilityQuota = Record({
  name: NonEmptyString,
  matchId: AboveZero,
  wanted: NonNegative,
  feasible: NonNegative,
  aiPushFeasibility: NonNegative.Or(Literal(-1)),
  pushFeasibility: NonNegative.Or(Literal(-1)),
  buckets: Array(AboveZero),
  keys: Array(NonEmptyString),
});

export type FeasibilityQuota = Static<typeof FeasibilityQuota>;

export const Feasibility = Record({
  targetGroupId: Number,
  isFeasible: Boolean,
  wantedNumberOfCompletes: Number,
  feasibleNumberOfCompletes: Number,
  suggestedNumberOfInvites: Number,
  userSelectedPanels: Boolean,
  panelCompletes: Array(PanelCompletes),
  quotas: Array(FeasibilityQuota),
  buckets: Array(
    Record({
      id: AboveZero,
      bucketKey: BucketKeyResponse,
      feasible: NonNegative.Or(Null), // can be null for ad hoc quotas
      aiPushFeasibility: NonNegative.Or(Literal(-1)),
      pushFeasibility: NonNegative.Or(Literal(-1)),
      name: NonEmptyString,
      wantedCompletes: Number.Or(Null),
      count: NonNegative,
    })
  ),
  weightingResult: Union(
    Literal(WeightingResult.None),
    Literal(WeightingResult.Failure),
    Literal(WeightingResult.Success),
    Literal(WeightingResult.Approximated)
  ),
  noPanelsForSampling: Boolean,
  error: Record({
    message: String,
  }).Or(Null),
});
export type Feasibility = Static<typeof Feasibility>;

export const FeasibilityResponse = Record({
  defaultFeasibility: Feasibility,
  potentialFeasibility: Feasibility.Or(Null),
  // TODO: rename to 'locked'
  hasAnyVisiblePanels: Boolean,
});
export type FeasibilityResponse = Static<typeof FeasibilityResponse>;

export const InfieldFeasibilityResponse = Record({
  targetGroupId: Number,
  feasibleNumberOfCompletes: Number,
  quotas: Array(FeasibilityQuota),
  error: Boolean.Or(Null),
});
export type InfieldFeasibilityResponse = Static<typeof InfieldFeasibilityResponse>;
