import { projectListsChannel } from './project-lists-channel';
import { Listener } from '../common/channels/channel';
import { ProjectGroupSearchHit } from '../common/http-services/project-search.httpService';
import { ProjectGroupSortField } from '../common/enums';
import { InitialLoadStatus } from './project-lists-service';
import { api } from '../common/api';
import { ProjectGroup } from './groups/ProjectGroups';
import { projectListsSettingsRepository } from './project-lists-filter-settings.repository';

export interface ProjectGroupSortProperties {
  descending: boolean;
  sortField: ProjectGroupSortField;
}

export type ProjectGroupListModel = {
  error: string;
  totalCount: number;
  projectGroups: ProjectGroupSearchHit[];
  paginationToken: string;
  sortSettings: ProjectGroupSortProperties;
  isLoading: boolean;
  initialLoadStatus: InitialLoadStatus;
};

export class ProjectGroupListService extends Listener {
  listModel: ProjectGroupListModel = {
    isLoading: false,
    initialLoadStatus: InitialLoadStatus.Uninitiated,
    error: '',
    paginationToken: null,
    projectGroups: [],
    sortSettings: {
      descending: true,
      sortField: ProjectGroupSortField.CreatedDate,
    },
    totalCount: null,
  };

  searchModel: ProjectGroupListModel = {
    isLoading: false,
    initialLoadStatus: InitialLoadStatus.Uninitiated,
    error: '',
    paginationToken: null,
    projectGroups: [],
    sortSettings: {
      descending: true,
      sortField: ProjectGroupSortField.CreatedDate,
    },
    totalCount: null,
  };

  companyProjectGroups: ProjectGroup[] = [];

  constructor() {
    super();
    projectListsChannel.scopeChanged.listen((_) => {
      this.reloadFirstPage(false);
      this.reloadFirstPage(true);
    }, this);
  }

  async loadNextPage(isSearch: boolean) {
    this.loadData(false, isSearch);
  }

  async loadCompanyProjectGroups() {
    const res = await api.search.searchProjectGroups(true, ProjectGroupSortField.CreatedDate, null, true);
    this.companyProjectGroups = res.data.hits;
  }

  changeSorting(newField: ProjectGroupSortField, isSearch: boolean) {
    const model = isSearch ? this.searchModel : this.listModel;

    const prev = model.sortSettings;
    model.sortSettings = {
      sortField: newField,
      descending: prev.sortField === newField ? !prev.descending : prev.descending,
    };

    this.reloadFirstPage(isSearch);
  }

  reloadFirstPage(isSearch: boolean, searchStringToUse: string = null) {
    this.loadData(true, isSearch, searchStringToUse);
  }

  removeProjectGroupFromList(id: string) {
    const hasDifferentId = (pg: ProjectGroupSearchHit) => pg.id !== id;
    this.listModel.projectGroups = this.listModel.projectGroups.filter(hasDifferentId);
    this.searchModel.projectGroups = this.searchModel.projectGroups.filter(hasDifferentId);

    this.dispatchModelUpdate(false);
    this.dispatchModelUpdate(true);
  }

  private loadData(isFirstPage: boolean, isSearch: boolean, searchStringToUse: string = null) {
    const model = isSearch ? this.searchModel : this.listModel;

    if (isFirstPage) {
      model.paginationToken = null;
      model.projectGroups = [];
    }

    model.isLoading = true;
    model.error = '';
    if (model.initialLoadStatus === InitialLoadStatus.Uninitiated)
      model.initialLoadStatus = InitialLoadStatus.InProgress;

    this.dispatchModelUpdate(isSearch);

    this.fetchData(isFirstPage, isSearch, searchStringToUse);
  }

  private async fetchData(isFirstPage: boolean, isSearch = false, searchStringToUse: string = null) {
    const model = isSearch ? this.searchModel : this.listModel;
    const searchString = isSearch
      ? searchStringToUse ?? projectListsSettingsRepository.settings.projectGroupSearchString
      : null;

    const { descending, sortField } = model.sortSettings;

    try {
      const response = await api.search.searchProjectGroups(
        descending,
        sortField,
        model.paginationToken,
        false,
        searchString
      );

      model.totalCount = response.data.totalHitCount;
      model.projectGroups = isFirstPage ? response.data.hits : [...model.projectGroups, ...response.data.hits];
      model.paginationToken = response.data.paginationToken;
    } catch (e) {
      console.error(e);
      model.error = 'Something went wrong when loading projects. Please try again or contact support.';
    }
    model.isLoading = false;
    model.initialLoadStatus = InitialLoadStatus.Completed;

    this.dispatchModelUpdate(isSearch);
  }

  private dispatchModelUpdate(isSearch: boolean) {
    const channel = isSearch
      ? projectListsChannel.projectGroupSearchUpdated
      : projectListsChannel.projectGroupListUpdated;
    const model = isSearch ? this.searchModel : this.listModel;

    channel.dispatch({
      error: model.error,
      paginationToken: model.paginationToken,
      projectGroups: model.projectGroups,
      sortSettings: model.sortSettings,
      totalCount: model.totalCount,
      isLoading: model.isLoading,
      initialLoadStatus: model.initialLoadStatus,
    });
  }
}

export const projectGroupListService = new ProjectGroupListService();
