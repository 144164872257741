import './existing-regions.component.scss';
import { IOnChangesObject } from 'angular';
import { ExistingTargetGroupRegions } from '../../common/http-services/existing-project.models';
import { html } from '../../helpers';

const selector = 'existingRegionsComponent';

const template = html`
  <div class="existing-regions-component" data-testid="existing-regions-component">
    <fieldset>
      <div ng-show="$ctrl.regions.isPostalCodesCustomRegion">Postal codes</div>
      <div ng-hide="$ctrl.regions.isPostalCodesCustomRegion">
        <div ng-hide="$ctrl.regions.items.length" class="irrelevant push-down">No specific regions selected</div>
        <div ng-show="$ctrl.regions.items.length">
          <h2>Selected regions</h2>
          <p><label class="option-label" ng-repeat="region in $ctrl.regions.items">{{region.name}}</label></p>
        </div>
      </div>
    </fieldset>
  </div>
`;

interface Bindings {
  regions: '<' | ExistingTargetGroupRegions;
}

const bindings: Bindings = {
  regions: '<',
};

export class ExistingRegionsComponent implements Bindings {
  static componentName = selector;

  regions: ExistingTargetGroupRegions;

  $onChanges(changes: IOnChangesObject): void {
    if (changes.regions) {
      this.regions = changes.regions.currentValue;
    }
  }
}

export const ngExistingRegionsComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ExistingRegionsComponent,
  },
};
