import './developer-tools-dialog.component.scss';
import { ui } from 'angular';
import { find } from 'lodash-es';
import { Omit } from 'lodash';
import { modalService } from '../common/modal.service';
import { userService } from '../common/user.service';
import { FeasibilityOptions, globalFeasibilityOptions } from '../common/global-feasibility-options';
import { ListCacheDialogSettingsFactory } from './list-cache-dialog-settings.factory';
import { ListCacheDialogResult } from './list-cache-dialog.component';
import { api, DetailedFeature } from '../common/api';
import { WarningDialogComponent } from './warning-dialog.component';
import { ExportProjectDialogSettingsFactory } from './export-project-dialog-settings.factory';
import { globalDebugOptions } from '../common/global-debug-options';
import { RuntimeSettings } from '../runtime-settings';
import { silenceRejection } from '../helpers';

import template from './developer-tools-dialog.component.html';
import { ImportProjectDialogSettingsFactory } from './import-project-dialog-settings.factory';
import { RecreateProjectDialogSettingsFactory } from './recreate-project-dialog.component';

const selector = 'developerToolsDialogComponent';

export interface DeveloperToolsDialogResult {
  reload: boolean;
}

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

interface Option {
  description: string;
  enabled: boolean;
  inoperable?: boolean;
  toggle: (option: Option) => void;
}

interface FeasibilitySetting<
  K extends keyof Omit<FeasibilityOptions, 'availabilityRatio'> = keyof Omit<FeasibilityOptions, 'availabilityRatio'>
> {
  description: string;
  enabled: boolean;
  type?: K;
  toggle: (setting: FeasibilitySetting) => void;
  isDisabled: boolean;
}

export class DeveloperToolsDialogComponent implements Bindings {
  [p: string]: any;

  static componentName = selector;

  get buildNumber() {
    return RuntimeSettings.buildNumber;
  }
  get isInProduction() {
    return RuntimeSettings.runtime.environment === 'Production';
  }

  modalInstance: ui.bootstrap.IModalInstanceService;
  features: DetailedFeature[];
  disallowToggleFeatures: boolean;
  displayFeasibilityOptions = true;
  reloadRequired = false;
  featureError = '';
  cacheError = '';

  clearAccessCacheInProgress = false;
  clearInternalServicesCacheInProgress = false;

  displayCustomAvailabilityRatio: boolean;
  customAvailabilityRatio: number;
  error: string;
  options: Option[] = [
    {
      description: 'basic settings validation',
      enabled: globalDebugOptions.options.showBasicSettingsValidation,
      toggle: (option) => this.toggleBasicSettingsValidation(option),
    },
    {
      description: 'target group debug info',
      enabled: globalDebugOptions.options.showSessionStateDebugInfo,
      toggle: (option) => this.toggleSessionStateDebugInfo(option),
    },
    {
      description: 'pub/sub console logging',
      enabled: globalDebugOptions.options.logPubSubToConsole,
      toggle: (option) => this.toggleLogPubSubToConsole(option),
    },
    {
      description: 'ui-router transition tracing',
      enabled: globalDebugOptions.options.enableTransitionTracing,
      toggle: (option) => this.toggleEnableTransitionTracing(option),
    },
    {
      description: 'immer patches',
      enabled: globalDebugOptions.options.showImmerPatches,
      toggle: (option) => this.toggleShowImmerPatches(option),
    },
    {
      description: 'disable compress requests',
      enabled: globalDebugOptions.options.disableCompressRequests,
      toggle: (option) => this.toggleDisableCompressRequests(option),
    },
  ];
  feasibilitySettings: FeasibilitySetting[] = [
    {
      description: 'use fake feasibility',
      enabled: globalFeasibilityOptions.options.useFakeFeasibility,
      toggle: (setting) => this.toggleUseFakeFeasibility(setting),
      isDisabled: false,
    },
    {
      description: 'disable dropout-rate transform',
      enabled: globalFeasibilityOptions.options.disableDropoutRateTransform,
      type: 'disableDropoutRateTransform',
      toggle: (setting) => this.toggleFeasibilitySetting(setting),
      isDisabled: globalFeasibilityOptions.options.useFakeFeasibility,
    },
    {
      description: 'use custom field period `availability` ratio',
      enabled: globalFeasibilityOptions.options.useCustomAvailabilityRatio,
      type: 'useCustomAvailabilityRatio',
      toggle: (setting) => this.toggleCustomAvailabilityRatioSetting(setting),
      isDisabled: globalFeasibilityOptions.options.useFakeFeasibility,
    },
  ];

  $onInit() {
    this.disallowToggleFeatures = this.isInProduction && !userService.isAdminUser();
    this.displayCustomAvailabilityRatio = globalFeasibilityOptions.options.useCustomAvailabilityRatio;
    this.customAvailabilityRatio = globalFeasibilityOptions.options.availabilityRatio;

    api.feature.getAllFeatures().then((res) => {
      this.features = res.data;
    });
  }

  close(result?: DeveloperToolsDialogResult) {
    return this.modalInstance.close(result);
  }

  clearAccessCache(): void {
    this.clearCacheFor('Access');
  }

  clearInternalServicesCache(): void {
    this.clearCacheFor('InternalServices');
  }

  toggleFeatureWithCheck(id: string): void {
    if (!this.isInProduction) {
      this.toggleFeature(id);
    } else {
      modalService
        .openMedium({
          keyboard: false,
          windowClass: 'warning-dialog',
          component: WarningDialogComponent.componentName,
        })
        .result.then(() => {
          this.toggleFeature(id);
        })
        .catch((e) => {
          if (e !== 'cancel') throw e;
        });
    }
  }

  toggleFeature(id: string): void {
    const feature = find(this.features, (f) => {
      return f.id === id;
    });
    feature.isTurnedOn = !feature.isTurnedOn;

    this.reloadRequired = true;
    this.error = '';
    api.feature
      .enableFeatures(this.getEnabledFeatureIds())
      .then((res) => {
        this.features = res.data;
      })
      .catch((err) => {
        this.error = 'something went wrong. check ELMAH logs.';
        throw err;
      });
  }

  listCacheKeys(): void {
    modalService.openLarge(ListCacheDialogSettingsFactory.create()).result.then((res: ListCacheDialogResult) => {
      if (res.reload) {
        this.reloadRequired = true;
      }
    });
  }

  customAvailabilityRatioChange(): void {
    const value = this.customAvailabilityRatio;
    if (value >= 0 && value <= 1) {
      globalFeasibilityOptions.options.availabilityRatio = value;
      globalFeasibilityOptions.persist();
    }
  }

  openExportProjectDialog(): void {
    modalService.openLarge(ExportProjectDialogSettingsFactory.create()).result.catch(silenceRejection);
  }

  openImportProjectDialog(): void {
    modalService.openLarge(ImportProjectDialogSettingsFactory.create()).result.catch(silenceRejection);
  }

  openRecreateProjectDialog(): void {
    modalService.openLarge(RecreateProjectDialogSettingsFactory.create()).result.catch(silenceRejection);
  }

  private clearCacheFor(application: string): void {
    const inProgressPropertyName = `clear${application}CacheInProgress`;

    this.cacheError = '';
    this.reloadRequired = true;
    this[inProgressPropertyName] = true;
    api.feature
      .clearCacheFor(application === 'Access' ? 'access' : 'internal-services')
      .then(() => {
        this[inProgressPropertyName] = false;
      })
      .catch(() => {
        this.cacheError = `could not clear ${application} cache. check logs.`;
        this[inProgressPropertyName] = false;
      });
  }

  private getEnabledFeatureIds(): string[] {
    return this.features.filter((f) => f.isTurnedOn).map((x) => x.id);
  }

  private toggleBasicSettingsValidation(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.showBasicSettingsValidation = !globalDebugOptions.options.showBasicSettingsValidation;
    globalDebugOptions.persist();
  }

  private toggleSessionStateDebugInfo(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.showSessionStateDebugInfo = !globalDebugOptions.options.showSessionStateDebugInfo;
    globalDebugOptions.persist();
  }

  private toggleLogPubSubToConsole(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.logPubSubToConsole = !globalDebugOptions.options.logPubSubToConsole;
    globalDebugOptions.persist();
    this.reloadRequired = true;
  }

  private toggleEnableTransitionTracing(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.enableTransitionTracing = !globalDebugOptions.options.enableTransitionTracing;
    globalDebugOptions.persist();
    this.reloadRequired = true;
  }

  private toggleShowImmerPatches(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.showImmerPatches = !globalDebugOptions.options.showImmerPatches;
    globalDebugOptions.persist();
    this.reloadRequired = true;
  }

  private toggleDisableCompressRequests(option: Option): void {
    option.enabled = !option.enabled;
    globalDebugOptions.options.disableCompressRequests = !globalDebugOptions.options.disableCompressRequests;
    globalDebugOptions.persist();
    this.reloadRequired = true;
  }

  private toggleUseFakeFeasibility(setting: FeasibilitySetting): void {
    setting.enabled = !setting.enabled;
    globalFeasibilityOptions.options.useFakeFeasibility = !globalFeasibilityOptions.options.useFakeFeasibility;

    this.feasibilitySettings
      .filter((s) => s !== setting)
      .forEach((item) => {
        item.isDisabled = setting.enabled;
        item.enabled = false;
        if (item.type !== 'useCustomAvailabilityRatio') {
          globalFeasibilityOptions.options[item.type] = false;
        }
      });

    globalFeasibilityOptions.persist();
  }

  private toggleFeasibilitySetting(setting: FeasibilitySetting): void {
    setting.enabled = !setting.enabled;
    globalFeasibilityOptions.options[setting.type] = !globalFeasibilityOptions.options[setting.type];
    globalFeasibilityOptions.persist();
  }

  private toggleCustomAvailabilityRatioSetting(setting: FeasibilitySetting): void {
    this.toggleFeasibilitySetting(setting);
    this.displayCustomAvailabilityRatio = setting.enabled;
  }
}

export const ngDeveloperToolsDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: DeveloperToolsDialogComponent,
  },
};
