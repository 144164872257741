import { IPromise } from 'angular';
import { modalService } from './common/modal.service';
import { ReloadApplicationDialogComponent } from './reload-application-dialog.component';
import { session } from './common/session';
import { applicationChannel } from './common/channels/application-channel';
import { api } from './common/api';
import { RuntimeSettings } from './runtime-settings';
import { $interval, $window } from './ngimport';
import { html } from './helpers';

const selector = 'checkForUpdatesComponent';

const template = html` <span class="check-for-updates-component invisible"> </span> `;

export class CheckForUpdatesComponent {
  static componentName = selector;

  private currentBuild: number;
  private intervalHandle: IPromise<void>;
  private defaultIntervalSeconds = 60;
  private cumulativeIntervalSeconds = this.defaultIntervalSeconds;
  private secondsToBackOff = this.defaultIntervalSeconds;

  $onInit() {
    this.currentBuild = RuntimeSettings.buildNumber;
    this.intervalHandle = $interval(
      () => this.checkForUpdate(this.currentBuild),
      this.cumulativeIntervalSeconds * 1000
    );
  }

  $onDestroy() {
    $interval.cancel(this.intervalHandle);
  }

  private checkForUpdate(currentBuild: number): void {
    api.config
      .getStartupConfig()
      .then((res) => {
        this.cumulativeIntervalSeconds = this.defaultIntervalSeconds;
        const latestBuild = res.data.buildNumber;
        if (currentBuild === latestBuild) return;

        $interval.cancel(this.intervalHandle);
        applicationChannel.version.frontendChanged.dispatch();

        this.currentBuild = res.data.buildNumber;
        modalService
          .openMedium({
            keyboard: false,
            windowClass: 'reload-application-dialog',
            component: ReloadApplicationDialogComponent.componentName,
          })
          .result.finally(() => {
            session.clear().then(() => {
              $window.location.reload();
            });
          });
      })
      .catch(() => {
        this.cumulativeIntervalSeconds += this.secondsToBackOff;
      });
  }
}

export const ngCheckForUpdatesComponent = {
  [selector]: {
    template,
    controller: CheckForUpdatesComponent,
  },
};
