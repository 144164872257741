import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../custom-hooks';
import { CustomDropdownOption } from './custom-dropdown.component';

interface Props {
  options: CustomDropdownOption[];
  defaultSelection?: any;
  onOptionSelect: Function;
  controlledInput?: boolean;
  value?: any;
  disabled?: boolean | undefined;
}

export const CustomDropdown: React.FC<Props> = ({
  options,
  defaultSelection,
  onOptionSelect,
  value,
  controlledInput,
  disabled,
}) => {
  const dropdownNode = useRef<HTMLDivElement>();
  const selectedNode = useRef<HTMLInputElement>();
  const optionsNode = useRef<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelection ?? '');
  useOutsideClick(() => setIsExpanded(false), dropdownNode, optionsNode);

  const defaultOption = options.find((el) => isEqual(el.value, defaultSelection));
  const selectedInternal = controlledInput ? value : selectedOption;

  const setFocus = (focus: boolean) => {
    setFocused(focus);
    if (focus) {
      selectedNode.current.focus();
    } else selectedNode.current.blur();
  };

  const selectOption = (option: CustomDropdownOption): void => {
    setFocus(false);
    if (!controlledInput) {
      setSelectedOption(option.value);
    }
    onOptionSelect({ option });
    setIsExpanded(false);
    setTimeout(() => {
      setFocus(true);
    }, 0);
  };

  const toggleList = () => {
    setIsExpanded(!isExpanded);
    setFocus(!focused);
  };

  const getDisplayText = () => {
    const option = options.find((el) => el.value === selectedInternal) || defaultOption;
    return option ? option.name : 'Select';
  };

  return (
    <div className="custom-dropdown">
      <input className="input" readOnly value={selectedInternal} ref={selectedNode} />
      <p
        data-testid="custom-dropdown"
        className={classNames('toggle ui-control', { toggle_selected: defaultSelection, disabled })}
        onClick={toggleList}
        ref={dropdownNode}
      >
        {getDisplayText()}
        <span
          className={classNames('arrow rounded-arrow_secondary fas fa-angle-down', {
            'rounded-arrow_up': isExpanded,
          })}
        />
      </p>
      <div ref={optionsNode} className={classNames('list-wrap', { list_expanded: isExpanded })}>
        <div className="list">
          {options.map((option) => (
            <p
              key={option.value}
              data-testid="custom-dropdown-option"
              className="option"
              onClick={() => selectOption(option)}
            >
              {option.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
