import './navigation.component.scss';
import './managed-services.component.scss';

import { IScope } from 'angular';
import { userService } from './common/user.service';
import { RuntimeSettings } from './runtime-settings';
import { $document, $state, $window, $q, $timeout, $stateParams } from './ngimport';
import { InitiatingAction, Product } from './common/enums';
import { db } from './common/db';
import { analytics } from './common/analytics';
import { html, silenceRejection } from './helpers';
import { ListenerComponent } from './common/channels/channel';
import { projectSettingsService } from './common/project-settings.service';
import { modalService } from './common/modal.service';
import { UnsavedChangesDialogSettingsFactory } from './common/dialogs/unsaved-changes-dialog-settings.factory';
import { currentWorkService } from './common/current-work.service';
import { TargetGroupsStateIntent } from './target-groups-state-intent';
import { targetGroupChannel } from './target-groups/channels/target-group-channel';
import { session } from './common/session';
import { ClearManagedServicesModeDialogComponent } from './clear-managed-services-mode-dialog.component';
import { targetGroupRepository } from './common/target-group/target-group.repository';
import { persistentSurveyLinksService } from './common/target-group/survey-links/persistent-survey-links.service';
import { managedServicesChannel } from './managed-services.channel';
import { Constants } from './constants';
import { saveStateService } from './common/save-state.service';

import accessLogo from '../images/access-logo-white.svg';
import accessProLogo from '../images/access-pro-logo-white.svg';
import accessIcon from '../images/access-icon.png';
import accessProIcon from '../images/access-pro-icon.png';
import projectsIcon from '../images/projects-icon.svg';
import learnIcon from '../images/learn-icon.svg';
import productsIcon from '../images/products-icon.svg';
import accountIcon from '../images/account-icon.svg';
import logoutIcon from '../images/logout-icon.svg';
import { featureFlipper } from './common/feature-flipper';
import { filters } from './common/filters';

const selector = 'navigationComponent';
const headerHeight = 74 + 3; //navigation__bar height + navigation box-shadow
const template = html`
  <div class="navigation" stick-to-top scroll-down-position="-${headerHeight}" scroll-up-position="0">
    <div class="navigation__bar">
      <img
        ng-cloak
        class="navigation__logo"
        ng-src="{{$ctrl.getProductLogo($ctrl.getCurrentProduct())}}"
        alt="{{$ctrl.getProductName($ctrl.getCurrentProduct())}}"
      />
      <nav class="navigation__options">
        <a
          class="navigation__link navigation__link_active font-primary hidden-tablet"
          title="Open projects"
          ui-sref="project-lists.active"
          feature-on="dashboard"
          data-testid="navigation-options-projects"
          >Projects</a
        >
        <a
          class="navigation__link navigation__link_active font-primary hidden-tablet"
          title="Open project list"
          feature-off="dashboard"
          ui-sref="projectList"
          data-testid="navigation-options-projects"
          >Projects</a
        >
        <a
          class="navigation__link navigation__link_active font-primary"
          ng-if="$ctrl.userService.companyAllowedToCreateProjects"
          ng-click="$ctrl.createNewProject()"
          ng-disabled="$ctrl.userService.hasOutstandingBills"
          title="Create new project"
        >
          <span>New project</span>
        </a>
        <button
          data-testid="continue-edit-button"
          class="ui-btn default-btn continue"
          ng-show="$ctrl.shouldShowContinueButton"
          ng-click="$ctrl.continueEditing()"
          title="Continue edit - {{$ctrl.currentWorkTitle}}"
        >
          <i
            class="editing-icon"
            ng-class="{
            'fas fa-clipboard-list': $ctrl.currentWorkIntent === 'edit-target-group-template',
            'far fa-file': $ctrl.currentWorkIntent !== 'edit-target-group-template',
            'far fa-file-alt': $ctrl.currentWorkIntent === 'add-to-project'}"
          ></i
          ><span class="button-text">{{$ctrl.currentWorkTitle}}</span>
          <span class="discard-current-work">
            <i
              title="Discard work"
              class="fas fa-times close-icon"
              ng-click="$ctrl.discardCurrentWork(); $event.stopPropagation();"
            ></i>
          </span>
        </button>
        <div ng-show="$ctrl.shouldShowCurrentWorkTitle" data-testid="current-work-title">
          <span class="title-text"><i>Editing: {{$ctrl.currentWorkTitle}}</i></span>
        </div>
      </nav>
      <div class="navigation__user-menu user-menu" on-click-outside="$ctrl.closeMenu()">
        <open-developer-tools-button-component
          style="margin-right: 25px"
          admin-only-in-production
        ></open-developer-tools-button-component>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="${Constants.helpLinks.frontPage}"
          ng-click="$ctrl.sendAnalyticsEvent.learn()"
          title="Get more help from the Knowledge Base"
          class="navigation__link navigation__link_active navigation__link_right font-primary hidden-tablet"
          >Learn</a
        >
        <div class="navigation__ms-indicator" ng-if="$ctrl.isMsEnabled()">MS MODE</div>
        <div
          class="user-menu__icon"
          ng-click="$ctrl.toggleMenu()"
          ng-class="{'user-menu__icon_notify': $ctrl.notifications}"
        >
          {{$ctrl.getInitials($ctrl.getName())}}
        </div>
        <div class="user-menu__dropdown" ng-class="{'user-menu__dropdown_open': $ctrl.menuIsOpen}">
          <div class="user-menu__dropdown-content">
            <div class="user-menu__dropdown-header">
              <div class="user-menu__login-info">
                <div class="user-menu__icon user-menu__icon_large">{{$ctrl.getInitials($ctrl.getName())}}</div>
                <div>
                  <div class="user-menu__name">{{$ctrl.getName()}}</div>
                  <div class="user-menu__company">{{$ctrl.userService.company}}</div>
                </div>
              </div>
              <div
                class="user-menu__ms"
                feature-on="msViaAccess"
                hide-for-product="${Product.AccessPro}"
                ng-if="$ctrl.userService.isImpersonatingOtherUser()"
              >
                Managed services
                <div class="managed-services-component">
                  <toggler-component
                    class="pull-right"
                    checked="$ctrl.getCurrentProduct() === '${Product.ManagedServices}'"
                    switch-id="toggle-ms-project-mode"
                    switch-type="onoff"
                    on-toggle="$ctrl.msProjectModeEnabledToggled()"
                  >
                  </toggler-component>
                </div>
              </div>
            </div>
            <div class="user-menu__section hidden visible-tablet">
              <a ui-sref="{{$ctrl.projectsState}}" class="user-menu__option font-paragraph">
                <img src="${projectsIcon}" class="user-menu__option-icon" />Projects
              </a>
              <a
                class="user-menu__option font-paragraph"
                target="_blank"
                rel="noopener noreferrer"
                ng-click="$ctrl.sendAnalyticsEvent.learn()"
                href="${Constants.helpLinks.frontPage}"
                title="Get more help from the Knowledge Base"
              >
                <img src="${learnIcon}" class="user-menu__option-icon" />Learn
              </a>
            </div>
            <div class="user-menu__section">
              <a
                ng-click="$ctrl.sendAnalyticsEvent.products()"
                href="{{$ctrl.externalUrls.products}}"
                class="user-menu__option font-paragraph"
              >
                <img src="${productsIcon}" class="user-menu__option-icon" />Products
              </a>
              <a
                ng-click="$ctrl.sendAnalyticsEvent.account()"
                href="{{$ctrl.externalUrls.account}}"
                class="user-menu__option font-paragraph"
              >
                <img src="${accountIcon}" class="user-menu__option-icon" />Account
              </a>
              <a
                ng-show="$ctrl.canSwitchToProduct()"
                ng-click="$ctrl.switchToProduct($ctrl.getOtherProduct())"
                class="user-menu__option font-paragraph"
              >
                <img ng-src="{{$ctrl.getProductImage($ctrl.getOtherProduct())}}" class="user-menu__option-icon" />Switch
                to {{$ctrl.getProductName($ctrl.getOtherProduct())}}
              </a>
            </div>
            <div class="user-menu__section">
              <a href="" ng-click="$ctrl.logOutWithChangesCheck()" class="user-menu__option font-paragraph">
                <img src="${logoutIcon}" class="user-menu__option-icon" />Log out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;

export class NavigationComponent extends ListenerComponent {
  static componentName = selector;

  menuIsOpen = false;
  notifications: number;
  currentWorkTitle: string;
  currentWorkIntent: TargetGroupsStateIntent;
  hasCurrentWork: boolean;

  get userService() {
    return userService;
  }
  get externalUrls() {
    return RuntimeSettings.externalUrls;
  }
  get sendAnalyticsEvent() {
    return analytics.navigation;
  }
  get shouldShowContinueButton() {
    return this.hasCurrentWork && !$state.current.name.startsWith('targetGroups');
  }
  get shouldShowCurrentWorkTitle() {
    return this.hasCurrentWork && $state.current.name.startsWith('targetGroups');
  }

  get projectsState() {
    return featureFlipper.isEnabled('dashboard') ? 'project-lists.active' : 'projectList';
  }
  constructor(private $scope: IScope) {
    'ngInject';

    super();
    targetGroupChannel.projectShow.listen(async () => {
      await this.updateCurrentWork();
    }, this);
    targetGroupChannel.navigatedAway.listen(async () => {
      await this.updateCurrentWork();
    }, this);
    targetGroupChannel.currentWorkUpdated.listen(async () => {
      await this.updateCurrentWork();
    }, this);
  }

  async $onInit() {
    projectSettingsService.load();
    await this.updateCurrentWork();

    // TODO: move to directive
    $document[0].addEventListener('scroll', () => {
      this.closeMenu();
      this.$scope.$apply();
    });
  }

  async discardCurrentWork(): Promise<void> {
    if (saveStateService.settings.isDirty) {
      await modalService
        .openSmall(UnsavedChangesDialogSettingsFactory.create())
        .result.then(async () => {
          currentWorkService.discardCurrentWork();
        })
        .catch(silenceRejection);
    } else {
      currentWorkService.discardCurrentWork();
    }
  }

  getInitials(fullName: string) {
    return filters.initials()(fullName);
  }

  getName(): string {
    return this.userService.isImpersonatingOtherUser() ? this.userService.impersonatedAs : this.userService.name;
  }

  toggleMenu(): void {
    this.menuIsOpen = !this.menuIsOpen;
  }

  closeMenu = () => {
    this.menuIsOpen = false;
  };

  createNewProject(): void {
    if (userService.hasOutstandingBills) return;

    analytics.newProject.initiate('navbar');
    const initiatingAction = InitiatingAction.CreateNewProjectTemplate;
    $state.go('targetGroups', { initiatingAction, intent: null, projectId: null }, { reload: true });
  }

  canSwitchToProduct(): boolean {
    return userService.roles.includes('Access') && userService.roles.includes('AccessPro');
  }

  getCurrentProduct(): Product {
    return session.product;
  }

  getOtherProduct(): Product {
    return session.product === Product.AccessPro ? Product.Access : Product.AccessPro;
  }

  getProductName(product: Product): string {
    if (product === Product.Access || product === Product.ManagedServices) {
      return 'Access';
    }
    if (product === Product.AccessPro) {
      return 'AccessPro';
    }

    throw new Error(`Invalid product: ${product}`);
  }

  getProductLogo(product: Product): string {
    if (product === Product.Access || product === Product.ManagedServices) {
      return accessLogo;
    }
    if (product === Product.AccessPro) {
      return accessProLogo;
    }

    throw new Error(`Invalid product: ${product}`);
  }

  getProductImage(product: Product): string {
    if (product === Product.Access || product === Product.ManagedServices) {
      return accessIcon;
    }
    if (product === Product.AccessPro) {
      return accessProIcon;
    }

    throw new Error(`Invalid product: ${product}`);
  }

  msProjectModeEnabledToggled(): void {
    const enable = this.getCurrentProduct() !== Product.ManagedServices;
    analytics.navigation.msViaAccess(enable);
    if ($state.current.name === 'targetGroups') {
      if (enable) {
        this.switchToProduct(Product.ManagedServices);
      } else {
        modalService
          .openSmall({
            windowClass: 'clear-managed-services-mode-dialog',
            component: ClearManagedServicesModeDialogComponent.componentName,
          })
          .result.then(
            () => {
              $stateParams.initiatingAction = InitiatingAction.DeleteAllTargetGroups;
              return targetGroupRepository.removeAll().then(() => {
                persistentSurveyLinksService.clear();
                managedServicesChannel.reset.dispatch();
                projectSettingsService.clear();
                this.switchToProduct(Product.Access);
                $timeout(() => {
                  $state.reload();
                }, 250);
              });
            },
            () => {
              // Do nothing
            }
          );
      }
    } else {
      this.switchToProduct(enable ? Product.ManagedServices : Product.Access);
    }
  }

  async switchToProduct(product: Product) {
    const setProductAndRefresh = () => {
      session.setProduct(product);
      $state.go(featureFlipper.isEnabled('dashboard') ? 'project-lists.active' : 'projectList', null, { reload: true });
    };

    if (this.hasCurrentWork) {
      const modal = modalService.openSmall(UnsavedChangesDialogSettingsFactory.create());
      try {
        await modal.result;
        await currentWorkService.discardCurrentWork();
        setProductAndRefresh();
      } catch (_) {
        // Do nothing, the user canceled
      }
    } else {
      setProductAndRefresh();
    }
  }

  async continueEditing(): Promise<void> {
    const initiatingAction = InitiatingAction.ContinueWithUnsaved;
    const intent = await currentWorkService.getCurrentWorkIntent();
    const projectId = await currentWorkService.getCurrentWorkId(intent);
    $state.go('targetGroups', { initiatingAction, intent, projectId }, { reload: true });
  }

  logOutWithChangesCheck(): void {
    if (this.hasCurrentWork) {
      modalService
        .openSmall(UnsavedChangesDialogSettingsFactory.create())
        .result.then(() => {
          this.logOut();
        })
        .catch(silenceRejection);
    } else {
      this.logOut();
    }
  }

  logOut(): void {
    $window.sessionStorage.clear();
    this.sendAnalyticsEvent.logOut();
    db.delete().finally(() => {
      $window.location.assign(`${RuntimeSettings.apiUrl}/logout`);
    });
  }

  isMsEnabled() {
    return session.product === Product.ManagedServices;
  }

  private async updateCurrentWork(): Promise<void> {
    this.currentWorkTitle = await currentWorkService.getCurrentWorkTitle();
    this.currentWorkIntent = await currentWorkService.getCurrentWorkIntent();
    this.hasCurrentWork = await currentWorkService.hasCurrentWork();
    return $q.when();
  }
}

export const ngNavigationComponent = {
  [selector]: {
    template,
    controller: NavigationComponent,
  },
};
