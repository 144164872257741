import MiniSignal, { MiniSignalBinding } from 'mini-signals';
import { analytics } from '../analytics';

export class InlineHelpService {
  visibleFor: { [id: string]: boolean } = {};
  onChange: {
    add: (callback: (source: string, state: boolean) => void) => MiniSignalBinding;
  };
  private signal: MiniSignal;

  constructor() {
    this.signal = new MiniSignal();
    this.onChange = {
      add: (callback: (source: string, state: boolean) => void) => {
        return this.signal.add(callback);
      },
    };
  }

  toggle(source: string) {
    const toggle = !(this.visibleFor[source] || false);
    this.visibleFor[source] = toggle;
    analytics.help.toggleHelp(source, toggle);
    this.signal.dispatch(source, toggle);
  }

  set(source: string, state: boolean) {
    this.visibleFor[source] = state;
    this.signal.dispatch(source, state);
  }
}

export const inlineHelpService = new InlineHelpService();
