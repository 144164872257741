import { Channel, DataChannel } from '../../common/channels/channel';
import { UpdateQuotaModel } from '../../common/http-services/manage.httpservice';
import { MonitorQuota, MonitorTargetGroup } from './monitor.component';
import { ExistingProject } from '../../common/http-services/existing-project.models';
import { RescheduleSendoutResponse } from '../sendouts/reschedule-sendout-dialog.component';
import { TargetGroupPrice } from '../../common/pricing/price-models';

export const monitorChannel = {
  changes: {
    unsaved: new Channel(),
    discarding: new Channel(),
    discarded: new Channel(),
    saving: new Channel(),
    saved: new Channel(),
  },
  project: {
    loading: new Channel(),
    loaded: new DataChannel<ExistingProject>(),
  },
  targetGroup: {
    expanded: new DataChannel<{ targetGroupId: number; isExpanded: boolean }>(),
    optimizer: {
      changing: new DataChannel<{ targetGroup: MonitorTargetGroup; useOptimizer: boolean }>(),
      changed: new DataChannel<{ targetGroupId: number }>(),
    },
  },
  quotas: {
    closing: new Channel(),
    closed: new Channel(),
    reopening: new Channel(),
    reopened: new Channel(),
    selection: {
      toggled: new DataChannel<{ targetGroupIds: number[]; quotaIds: number[]; isSelected: boolean }>(),
      deselectedAll: new Channel(),
      updated: new DataChannel<{ targetGroupIds: number[]; quotaIds: number[] }>(),
    },
    updated: new DataChannel<{ targetGroupId: number; updates: UpdateQuotaModel[] }>(),
    models: {
      updated: new Channel(),
    },
    completes: {
      wanted: {
        changing: new DataChannel<{ targetGroup: MonitorTargetGroup; quota: MonitorQuota; wantedCompletes: number }>(),
        changed: new DataChannel<{ targetGroupId: number; quotaId: number }>(),
      },
      surveyTool: {
        changing: new DataChannel<{
          targetGroup: MonitorTargetGroup;
          quota: MonitorQuota;
          surveyToolCompletes: number;
        }>(),
        changed: new DataChannel<{ targetGroupId: number; quotaId: number }>(),
        restoring: new DataChannel<{ targetGroup: MonitorTargetGroup; quota: MonitorQuota }>(),
        restored: new DataChannel<{ targetGroupId: number; quotaId: number }>(),
      },
      dontShowAgainChanged: new Channel(),
    },
    starts: {
      wanted: {
        changing: new DataChannel<{ targetGroup: MonitorTargetGroup; quota: MonitorQuota; wantedStarts: number }>(),
        changed: new DataChannel<{ targetGroupId: number; quotaId: number }>(),
      },
      dontShowAgainChanged: new Channel(),
    },
    autoClose: {
      all: {
        changing: new DataChannel<{ targetGroup: MonitorTargetGroup; enableAutoClose: boolean }>(),
        changed: new DataChannel<number>(),
      },
      single: {
        changing: new DataChannel<{ targetGroup: MonitorTargetGroup; quota: MonitorQuota; enableAutoClose: boolean }>(),
        changed: new DataChannel<number>(),
      },
    },
    buckets: {
      selection: {
        toggled: new DataChannel<{ targetGroupId: number; bucketIds: number[]; isSelected: boolean }>(),
        updated: new DataChannel<{ targetGroupIds: number[]; bucketIds: number[] }>(),
      },
      toggledShow: new DataChannel<number>(),
      updated: new DataChannel<{ quotaIds: number[] }>(),
    },
  },
  sampling: {
    succeeded: new Channel(),
  },
  fixedIncentive: {
    updated: new Channel(),
  },
  adjustLoi: {
    updated: new DataChannel<number>(),
  },
  wantedCompletes: {
    updated: new DataChannel<{ targetGroupId: number; newValue: number }>(),
  },
  wantedStarts: {
    updated: new DataChannel<{ targetGroupId: number; newValue: number }>(),
  },
  customCpi: {
    updated: new DataChannel<{ targetGroupId: number; newValue: number }>(),
    updatedQuotas: new DataChannel<{ targetGroupPrice: TargetGroupPrice }>(),
  },
  autoReload: {
    paused: new Channel(),
    resumed: new Channel(),
  },
  sendouts: {
    rescheduled: new DataChannel<RescheduleSendoutResponse>(),
    deleted: new Channel(),
  },
  suspensionLimits: {
    updated: new Channel(),
  },
  activePaneToggled: new DataChannel<number>(),
  aggregatedStatusesLoadError: new DataChannel<boolean>(),
};
