import { v4 as uuid } from 'uuid';
import { TargetGroupsStateIntent } from '../target-groups-state-intent';
import { db } from './db';
import { targetGroupChannel } from '../target-groups/channels/target-group-channel';
import { projectSettingsService } from './project-settings.service';
import { Listener } from './channels/channel';
import { templateHttpService } from './http-services/template.httpservice';
import { Constants } from '../constants';
import { saveStateService } from './save-state.service';
import { persistentSurveyLinksService } from './target-group/survey-links/persistent-survey-links.service';
import { targetGroupRepository } from './target-group/target-group.repository';
import { projectService } from '../target-groups/project.service';
import { StorageFactory } from './storage.factory';

export class CurrentWorkService extends Listener {
  private projectSetupIdKey = 'projectSetupId';
  private storage: Storage;

  get projectSetupId(): string {
    return this.storage.getItem(this.projectSetupIdKey);
  }

  constructor() {
    super();
    this.storage = StorageFactory.getStorage();
  }

  initCurrentWork() {
    if (!this.storage.getItem(this.projectSetupIdKey)) {
      this.storage.setItem(this.projectSetupIdKey, uuid());
    }
  }

  async hasCurrentWork() {
    const currentTargetGroups = await db.targetGroups.toArray();
    const currentWorkTitle = await this.getCurrentWorkTitle();
    return (
      currentTargetGroups &&
      currentTargetGroups.length &&
      (saveStateService.settings.isDirty ||
        currentWorkTitle !== Constants.defaultProjectName ||
        projectSettingsService.settings.isSet)
    );
  }

  async getCurrentWorkTitle(): Promise<string> {
    const currentTargetGroups = await db.targetGroups.toArray();
    const currentWorkIntent = await this.getCurrentWorkIntent();

    if (!projectSettingsService.settings || (!currentTargetGroups.length && !currentWorkIntent)) return '';

    if (currentWorkIntent === TargetGroupsStateIntent.EditTargetGroupTemplate) {
      return this.getTemplateName();
    }
    if (currentWorkIntent === TargetGroupsStateIntent.AddTargetGroups) {
      return (await this.getExistingProject()).name;
    }
    return projectSettingsService.settings.projectName;
  }

  async getCurrentWorkIntent(): Promise<TargetGroupsStateIntent> {
    if (!projectSettingsService.settings) {
      return undefined;
    }
    const { projectName } = projectSettingsService.settings;
    const targetGroupsCount = await db.targetGroups.count();
    if (!projectName && targetGroupsCount) {
      return TargetGroupsStateIntent.EditTargetGroupTemplate;
    }
    if (projectName === Constants.defaultProjectName && targetGroupsCount) {
      return undefined;
    }
    const existingProjectsCount = await db.existingProject.count();
    if (existingProjectsCount > 0 && targetGroupsCount) {
      return TargetGroupsStateIntent.AddTargetGroups;
    }
    if (
      projectName !== Constants.defaultProjectName &&
      targetGroupsCount &&
      projectService.targetGroups[0]?.projectTemplateSource.kind === 'draft'
    ) {
      return TargetGroupsStateIntent.EditDraft;
    }
    return undefined;
  }

  async getCurrentWorkId(intent: TargetGroupsStateIntent): Promise<number> {
    if (intent === TargetGroupsStateIntent.AddTargetGroups) return (await this.getExistingProject()).id;
    if (intent === TargetGroupsStateIntent.EditTargetGroupTemplate) {
      return projectService.targetGroups[0].projectTemplateSource.templateId;
    }
    return undefined;
  }

  async discardCurrentWork(): Promise<void> {
    await this.resetCurrentWork();
    targetGroupChannel.navigatedAway.dispatch();
  }

  async resetCurrentWork(): Promise<void> {
    this.storage.removeItem(this.projectSetupIdKey);
    saveStateService.setDirty(false);
    await targetGroupRepository.removeAll();
    persistentSurveyLinksService.clear();
    projectSettingsService.clear();
  }

  private async getTemplateName(): Promise<string> {
    const tgId = targetGroupRepository.findFirstTargetGroupId();
    const templeteId = (await targetGroupRepository.findById(tgId)).projectTemplateSource.templateId;

    const {
      data: { name },
    } = await templateHttpService.loadTemplate(templeteId);
    return name;
  }

  private async getExistingProject(): Promise<{ id: number; name: string }> {
    const existingProjectsCount = await db.existingProject.count();
    const targetGroupsCount = await db.targetGroups.count();

    if (existingProjectsCount > 0 && targetGroupsCount) {
      const existingProjects = await db.existingProject.toArray();
      return {
        id: existingProjects[0].project.id,
        name: existingProjects[0].project.name,
      };
    }
    return { id: null, name: null };
  }
}

export const currentWorkService = new CurrentWorkService();
