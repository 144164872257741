import produce, { Draft, Patch } from 'immer';
import { immerChannel } from '../target-groups/channels/immer-channel';
import { globalDebugOptions } from './global-debug-options';

export function logProduce<T>(state: T, recipe: (draft: Draft<T>) => void): T {
  const listener = globalDebugOptions.options.showImmerPatches ? logPatches : undefined;
  return produce(state, recipe, listener);
}

function logPatches(patches: Patch[], inversePatches: Patch[]): void {
  immerChannel.updated.dispatch({ patches, inversePatches });
}
