import { isEqual, isNumber, map } from 'lodash-es';
import { PanelistPoolType } from '../enums';
import { TargetGroupModel } from '../models/target-group.model';
import { FeatureFlipper } from '../feature-flipper';
import { SupplyMix } from '../models/supply.model';
import { isUndefinedOrNull } from '../../helpers';
import { Constants } from '../../constants';
import { ProjectTemplateSupplyMix, ProjectTemplateTargetGroup } from '../http-services/draft.httpservice';
import { createSurveyMetadataModel } from '../models/survey-metadata.model';
import { QuotaGroupNames } from '../../target-groups/active-target-group/models/active-quotas.model';

export class TargetGroupPersistentModelFactory {
  static createFromJSON(
    tg: ProjectTemplateTargetGroup,
    featureFlipper: FeatureFlipper,
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[] = [],
    quotaGroupNames: QuotaGroupNames = undefined
  ): TargetGroupModel {
    const isUsingStarts = isNumber(tg.wantedNumberOfStarts);
    const { regions } = tg;
    const { panels } = tg;

    return {
      id: undefined,
      replacesTargetGroupId: tg.replacesTargetGroupId,
      replacesProjectIds: tg.replacesProjectIds,
      name: tg.name,
      basicSettings: {
        countryId: tg.countryId,
        countryName: tg.country,
        gender: tg.gender,
        minAge: tg.minAge,
        maxAge: tg.maxAge,
        numberOfCompletes: tg.wantedNumberOfCompletes,
        numberOfStarts: tg.wantedNumberOfStarts,
        estimatedIncidenceRate: tg.estimatedIncidenceRate,
        estimatedLengthOfInterview: tg.estimatedLengthOfInterview,
        startDate: tg.startDate,
        numberOfDaysInField: tg.numberOfDaysInField,
        useFixedLoi: tg.useFixedLoi,
        useStarts: isUsingStarts,
      },
      regions: {
        selectedIds: map(regions.regionList, (region) => region.regionId),
        regionTypeId: regions.regionTypeId !== null ? regions.regionTypeId : undefined,
        postalCodes: regions.uploadedPostalCodes !== null ? regions.uploadedPostalCodes : [],
        mainRegionsAutomaticallySelected: regions.mainRegionsAutomaticallySelected || false,
      },
      profiling: {
        categoryId: undefined,
        panelSpecificProfiling: tg.profiling.panelSpecificProfiling,
        selectedVariables: tg.profiling.selectedVariables,
      },
      panels: {
        ownPanelCurrency: panels.ownPanelCurrency,
        selectedPrivatePricingRateCard: panels.selectedPrivatePricingRateCardHash,
        supplySource: panels.supplySource,
        selectedIds: map(panels.selectedPanels, (p) => p.id),
        adHocSupplier: panels.adHocSupplier,
        supplyMix: this.mapSupplyMix(panels.supplyMix, isUsingStarts, sectionsUsingIgnoreCompletes),
        useCustomCpi: !!tg.panels.customCpi,
        customCpi: tg.panels.customCpi,
        panelistPool: {
          source: panels.panelistPool.source || null,
          input: panels.panelistPool.importedPanelistIds || '',
          selectedGroup: panels.panelistPool.selectedGroup || '',
          rootPoolId: panels.panelistPool.rootPoolId || Constants.emptyGuid,
          selectedPoolId: panels.panelistPool.selectedPoolId || Constants.emptyGuid,
          panelistCount: 0,
          panelIds: panels.panelistPool.panelIds || [],
          hasAvailablePanelists: panels.panelistPool.hasAvailablePanelists || true,
          type: panels.panelistPool.type || PanelistPoolType.None,
          containsUrl: false,
          sourceUrl: panels.panelistPool.sourceUrl,
          createPoolResult: panels.panelistPool.createPoolResult,
        },
      },
      quotas: {
        quotas: tg.quotas.quotas,
        buckets: tg.quotas.buckets,
        usePercentages: false,
        weightingStrategy: tg.quotas.weightingStrategy,
        sectionsUsingPanelDistribution: [],
        sectionsUsingIgnoreCompletes: [...sectionsUsingIgnoreCompletes],
        quotaGroupNames,
      },
      activePane: 'basic-settings',
      excludeProjects: {
        projects: map(tg.excludeProjects.projects, ({ id, respondentStatusTimelines }) => ({
          projectId: id,
          respondentStatusTimelines,
        })),
        respondentStatusTimelines: tg.excludeProjects.respondentStatusTimelines,
      },
      incentives: featureFlipper.isEnabled('editIncentive')
        ? {
            fixedIncentive: {
              amount:
                !isUndefinedOrNull(tg.incentives) && !isUndefinedOrNull(tg.incentives.fixedIncentiveAmount)
                  ? tg.incentives.fixedIncentiveAmount
                  : undefined,
            },
          }
        : {
            fixedIncentive: {
              amount: undefined,
            },
          },
      requestMode: {
        includeLockedPanels:
          (tg.validation && tg.validation.visiblePanels && tg.validation.visiblePanels.length > 0) ||
          tg.panels.panelistPool.hasAnyLockedPanels,
      },
      projectTemplateSource: undefined,
      linksData: tg.linksData,
      surveyMetadata: tg.surveyMetadata ?? createSurveyMetadataModel(),
    };
  }

  private static mapSupplyMix(
    supplyMix: ProjectTemplateSupplyMix,
    useStarts: boolean,
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[] = []
  ): SupplyMix {
    return {
      supplyGroups: map(supplyMix.supplyGroups, (g) => ({
        id: g.id,
        name: g.name,
        panelIds: g.panelIds,
        percentage: g.percentage,
        wantedCompletes: g.wantedCompletes,
        wantedStarts: useStarts ? g.wantedStarts : undefined,
        source: g.source,
        useCustomCpi: !!g.customCpi,
        customCpi: g.customCpi,
      })),
      ignoreCompletes: sectionsUsingIgnoreCompletes.some((keys) => isEqual(keys, ['supply'])),
    };
  }
}
