import './existing-exclusions.component.scss';
import { react2angular } from 'react2angular';
import { flatMap, isEmpty, some, uniq } from 'lodash';
import { filters } from '../../common/filters';
import {
  ExcludedRespondentStatus,
  ExcludeStatusTimeline,
  PanelistPoolType,
  parseExcludedRespondentStatus,
  parseExcludeStatusWithTimeline,
} from '../../common/enums';
import {
  ExistingPanelistPool,
  ExistingTargetGroupExcludeProjects,
  ProjectExclude,
} from '../../common/http-services/existing-project.models';
import { isEmptyGuid } from '../../helpers';

class Props {
  excludeProjects: ExistingTargetGroupExcludeProjects;
  panelistPool: ExistingPanelistPool;
}

interface ExcludeProjectRow {
  id: number;
  projectName: string;
  statusTimelines: {
    status: ExcludedRespondentStatus;
    timeline: ExcludeStatusTimeline;
  }[];
}

export const hasExcludedPanelists = (panelistPool: ExistingPanelistPool): boolean => {
  return !(
    isEmpty(panelistPool) ||
    panelistPool.type !== PanelistPoolType.Exclusive ||
    isEmptyGuid(panelistPool.poolId)
  );
};

const renderExcludedPanelistsDetails = (panelistPool: ExistingPanelistPool) => {
  return (
    <div>
      <h2>Specific panelist exclusion</h2>
      <span>Specified panelists from {panelistPool.panelIds.length} panels are being excluded.</span>
    </div>
  );
};

const renderProjectExclusion = (rows: ExcludeProjectRow[], statuses: ExcludedRespondentStatus[]) => {
  return (
    <div>
      <h2>Project exclusion</h2>
      <table className="details-table">
        <thead>
          <tr>
            <th>Project</th>
            {statuses.map((status) => (
              <th className="status-cols" key={status}>
                {filters.excludeStatusDescription()(status)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td className="project-names" title={row.projectName}>
                {row.projectName}
              </td>
              {statuses.map((status) => (
                <td key={status}>{getTimelineForStatus(row, status)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapExcludedProjects = (excludeProjects: ExistingTargetGroupExcludeProjects): ExcludeProjectRow[] => {
  const generalStatusTimelines = excludeProjects.respondentStatusTimelines;
  return excludeProjects.projects.map((p): ExcludeProjectRow => {
    const statusTimelines = isEmpty(p.respondentStatusTimelines) ? generalStatusTimelines : p.respondentStatusTimelines;
    return {
      id: p.id,
      projectName: p.name,
      statusTimelines: statusTimelines.map((st) => ({
        status: parseExcludedRespondentStatus(st.respondentStatus),
        timeline: parseExcludeStatusWithTimeline(st.timeline),
      })),
    };
  });
};

const getTimelineForStatus = (row: ExcludeProjectRow, status: ExcludedRespondentStatus): string => {
  const found = row.statusTimelines.find((st) => st.status === status);
  return found ? filters.statusTimelineDescription()(found.timeline) : '-';
};

export const ExistingExclusions: React.FC<Props> = ({ panelistPool, excludeProjects }: Props) => {
  const excludeProjectsRows: ExcludeProjectRow[] = mapExcludedProjects(excludeProjects);
  const projectsWithStatusExcludes: ProjectExclude[] = excludeProjects.projects.filter((p) =>
    some(p.respondentStatusTimelines)
  );
  const statuses: ExcludedRespondentStatus[] = uniq([
    ...flatMap(projectsWithStatusExcludes, (p) =>
      p.respondentStatusTimelines.map((st): ExcludedRespondentStatus => st.respondentStatus)
    ),
    ...flatMap(excludeProjects.respondentStatusTimelines, (st) => st.respondentStatus),
  ]);

  return (
    <div className="existing-exclusions-component">
      <div>{hasExcludedPanelists(panelistPool) && renderExcludedPanelistsDetails(panelistPool)}</div>
      <div>{excludeProjectsRows.length > 0 && renderProjectExclusion(excludeProjectsRows, statuses)}</div>
      <div className="push-down">
        <span className="irrelevant">
          {!hasExcludedPanelists(panelistPool) && 'Exclusion on specific panelists is not enabled. '}
          {excludeProjectsRows.length === 0 && 'Exclusion on projects is not enabled.'}
        </span>
      </div>
    </div>
  );
};

export const ngExistingExclusionsComponent = {
  existingExclusionsComponent: react2angular(ExistingExclusions, ['excludeProjects', 'panelistPool']),
};
