import dayjs from 'dayjs';
import { ActiveBasicSettingsModel } from '../../target-groups/active-target-group/models/active-basic-settings.model';
import { TargetGender } from '../enums';

export interface BasicSettingsModel {
  readonly countryId: number;
  readonly countryName: string;
  readonly gender: string;
  readonly minAge: number;
  readonly maxAge: number;
  readonly numberOfCompletes: number;
  readonly numberOfStarts: number;
  readonly estimatedIncidenceRate: number;
  readonly estimatedLengthOfInterview: number;
  readonly startDate: string;
  readonly numberOfDaysInField: number;
  readonly useFixedLoi: boolean;
  readonly useStarts: boolean;
}

export function createBasicSettingsModel(): BasicSettingsModel {
  return {
    countryId: -1,
    countryName: '',
    gender: TargetGender.Both,
    minAge: 18,
    maxAge: 64,
    numberOfCompletes: undefined,
    numberOfStarts: undefined,
    estimatedIncidenceRate: 100,
    estimatedLengthOfInterview: 5,
    startDate: dayjs().toISOString(),
    numberOfDaysInField: 7,
    useFixedLoi: false,
    useStarts: false,
  };
}

export function createBasicSettingsModelFromActive(model: ActiveBasicSettingsModel): BasicSettingsModel {
  return {
    countryId: model.countryId,
    countryName: model.countryName,
    gender: model.gender,
    minAge: model.minAge,
    maxAge: model.maxAge,
    numberOfCompletes: model.numberOfCompletes,
    numberOfStarts: model.numberOfStarts,
    estimatedIncidenceRate: model.estimatedIncidenceRate,
    estimatedLengthOfInterview: model.estimatedLengthOfInterview,
    startDate: model.startDate,
    numberOfDaysInField: model.numberOfDaysInField,
    useFixedLoi: model.useFixedLoi,
    useStarts: model.useStarts,
  };
}
