import './FilterListButton.scss';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../custom-hooks';
import { CustomPopover } from '../react/CustomPopover';
import { Filter } from './filter';
import { FilterList } from './FilterList';

interface Props {
  filters: Filter[];
  onFilter: (filters: Filter[]) => void;
}

export const FilterListButton: React.FC<Props> = ({ filters, onFilter }) => {
  const filterButtonNode = useRef<HTMLButtonElement>(undefined);
  const popoverNode = useRef<HTMLDivElement>(undefined);
  const [open, setOpen] = useState(false);
  useOutsideClick(() => setOpen(false), popoverNode, filterButtonNode);

  const applyAndClose = (filters: Filter[]) => {
    setOpen(false);
    onFilter(filters);
  };

  return (
    <span className="filter-list-button">
      <CustomPopover
        visible={open}
        placement="right"
        content={
          <div ref={popoverNode}>
            <FilterList filters={filters} onApply={applyAndClose} />
          </div>
        }
      >
        <button
          ref={filterButtonNode}
          onClick={() => setOpen((o) => !o)}
          data-testid="open-filters-button"
          title="Add filters"
          className="ui-btn icon-btn-round filter-button"
        >
          <i className="fal fa-sliders-v" />
        </button>
      </CustomPopover>
    </span>
  );
};
