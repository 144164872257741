import './PriceDetails.scss';
import '../../mixins.scss';

import { useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { $state } from '../../ngimport';
import { Product } from '../enums';
import {
  EffectiveCpiDetailResponse,
  ProjectFeeResponse,
  QuotaPriceResponse,
} from '../http-services/project-price.response';
import { Money } from './price-models';
import { filters } from '../filters';
import { session } from '../session';
import { PriceTrendService, TrendDirection } from './price-trend.service';
import { TrendIcon } from '../controls/TrendIcon';
import { EffectiveCpiDetails } from '../../overview/target-group/EffectiveCpiDetails';
import { InlineHelp } from '../inline-help/InlineHelp';
import { featureFlipper } from '../feature-flipper';

export interface PriceModel {
  completes?: number;
  price: Money;
  priceTotal?: Money;
  cpi?: Money;
  cpiTotal?: Money;
  volumeDiscountCompletes?: number;
  fees?: ProjectFeeResponse[];
  priceWithoutFees?: Money;
  isCustomCpi?: boolean;
  effectiveLengthOfInterview?: number;
  effectiveIncidenceRate?: number;
  effectiveCpiDetails?: EffectiveCpiDetailResponse[];
  effectiveCpi?: Money;
  quotas?: QuotaPriceResponse[];
}

export interface PriceDetailModel {
  initial: PriceModel;
  current: PriceModel;
  projected: PriceModel;
  invoice?: PriceModel;
  completeFee?: Money;
  hasRateCard?: boolean;
  hasAgreedPrice?: boolean;
}

interface Props {
  price: PriceDetailModel;
  internalPrice?: PriceDetailModel;
  isClosed: boolean;
  isPending: boolean;
  completes: number;
  responded: number;
  estimatedIr?: number;
  estimatedLoi?: number;
  priceTrend?: TrendDirection;
  helpTextId: string;
  helpTexts: {
    current: string;
    initial: string;
    projected: string;
    invoice?: string;
  };
}

interface TabModel {
  name: string;
  model: PriceModel;
  internal: PriceModel;
  ir: number;
  loi: number;
  trend?: TrendDirection;
  showPriceDisclaimer?: boolean;
  helpText?: string;
  hideAgreedPrice?: boolean;
}

const formatPrice = (value: Money) => {
  return filters.formatPrice()(value);
};

const shouldShowPriceDisclaimer = (priceModel: PriceDetailModel, completes: number, responded: number) => {
  if (priceModel?.projected.isCustomCpi) return false;

  return (
    completes < PriceTrendService.threshold.completes ||
    (responded < PriceTrendService.threshold.responded && completes > 0)
  );
};

export const PriceDetails: React.FC<Props> = ({
  price,
  internalPrice,
  isClosed,
  isPending,
  completes,
  responded,
  estimatedIr,
  estimatedLoi,
  priceTrend,
  helpTexts,
  helpTextId,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(isPending ? 0 : 1);
  const tabs: TabModel[] = isClosed
    ? [
        {
          name: 'Initial',
          model: price?.initial,
          internal: internalPrice?.initial,
          ir: estimatedIr,
          loi: estimatedLoi,
          hideAgreedPrice: true,
          helpText: helpTexts.initial,
        },
        {
          name: 'Final',
          model: price?.current,
          internal: internalPrice?.current,
          ir: price?.current.effectiveIncidenceRate,
          loi: price?.current.effectiveLengthOfInterview,
        },
      ]
    : [
        {
          name: 'Initial',
          model: price?.initial,
          internal: internalPrice?.initial,
          ir: estimatedIr,
          loi: estimatedLoi,
          hideAgreedPrice: true,
          helpText: helpTexts.initial,
        },
        {
          name: 'Current',
          model: price?.current,
          internal: internalPrice?.current,
          ir: price?.current.effectiveIncidenceRate,
          loi: price?.current.effectiveLengthOfInterview,
          helpText: helpTexts.current,
        },
        {
          name: 'Projected',
          model: price?.projected,
          internal: internalPrice?.projected,
          ir: price?.projected.effectiveIncidenceRate,
          loi: price?.projected.effectiveLengthOfInterview,
          trend: priceTrend,
          showPriceDisclaimer: shouldShowPriceDisclaimer(price, completes, responded),
          helpText: helpTexts.projected,
        },
      ];

  if (price?.invoice && featureFlipper.isEnabled('billingEntity')) {
    tabs.push({
      name: 'Invoicing',
      model: price?.invoice,
      internal: null,
      ir: price?.invoice.effectiveIncidenceRate,
      loi: price?.invoice.effectiveLengthOfInterview,
      trend: priceTrend,
      showPriceDisclaimer: false,
      helpText: helpTexts.projected,
    });
  }

  const selectTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  const showRateCard = (): void => {
    const currentOrFinalPriceModel = tabs[1].model;
    $state.go('overview.targetGroup.rateCard', {
      totalCompletes: currentOrFinalPriceModel.volumeDiscountCompletes,
      currentCpi: currentOrFinalPriceModel.cpi.value * 100,
      internal: internalPrice !== null ? 1 : 0,
    });
  };

  if (!price) return <div className="push-down wait-spinner" />;
  const currentTab = tabs[activeTabIndex];
  const priceModel = currentTab.model;
  const internalPriceModel = currentTab.internal;
  const hasInternalPrice = !isEmpty(internalPriceModel);
  const hasCompleteFee = !isEmpty(price.completeFee);
  const showCpiDetails = session.product === Product.AccessPro && (hasCompleteFee || !isEmpty(internalPriceModel?.cpi));
  const showAgreedPriceInfo = currentTab.hideAgreedPrice !== true && price.hasAgreedPrice;
  const showRateCardInfo = !showAgreedPriceInfo && !priceModel.isCustomCpi && !hasCompleteFee && price.hasRateCard;
  const totalPrice = internalPriceModel?.priceTotal ?? priceModel.priceWithoutFees ?? priceModel.price;
  const totalCpi = internalPriceModel?.cpiTotal ?? priceModel.cpi;

  return (
    <div className="price-details">
      <ul className="nav nav-tabs">
        {tabs.map((tab, index) => {
          return (
            <li
              key={tab.name}
              data-testid={`price-details-tab-${tab.name}`}
              className={classNames('nav-item', { active: index === activeTabIndex })}
            >
              <a className="nav-link" onClick={() => selectTab(index)}>
                {tab.name} {index === activeTabIndex && 'price'}{' '}
                {!isEmpty(tab.trend) && <TrendIcon isPositive={false} direction={tab.trend} />}
              </a>
            </li>
          );
        })}
      </ul>

      <div className="content-border content-padding" data-testid="price-details">
        {priceModel.isCustomCpi ? (
          <EffectiveCpiDetails currentPrice={priceModel} showRateCard={showRateCard} />
        ) : (
          <>
            <div className="row">
              <div className="col-xs-7" style={{ paddingRight: '0px' }}>
                <label>
                  Completes <span>({filters.thousandSeparator()(priceModel.completes)}</span>
                  {!isEmpty(totalCpi) && (
                    <>
                      <span> &times; </span>
                      <span>
                        {formatPrice(totalCpi)}
                        {showCpiDetails && <span className="disclaimer-mark complete-fee">*</span>}
                      </span>
                    </>
                  )}
                  )
                </label>
              </div>
              <div className="col-xs-5 text-right" style={{ paddingLeft: '0px' }}>
                <span>
                  {!isEmpty(currentTab.trend) && <TrendIcon isPositive={false} direction={currentTab.trend} />}{' '}
                  {formatPrice(totalPrice)}
                  {currentTab.showPriceDisclaimer && (
                    <span className="price-disclaimer disclaimer-mark price-disclaimer-mark"> *</span>
                  )}
                </span>
              </div>
            </div>
            {priceModel.fees?.map((fee) => (
              <div key={fee.name} className="row">
                <div className="col-xs-7 text-left">
                  <label>{fee.name}</label>
                </div>
                <div className="col-xs-5 text-right">
                  <span>{formatPrice(fee.price)}</span>
                </div>
              </div>
            ))}
            {!isEmpty(priceModel.fees) && (
              <div className="row">
                <div className="col-xs-6">
                  <label className="pricing-value">Total</label>
                </div>
                <div className="col-xs-6 text-right">
                  <span className="pricing-value">{formatPrice(priceModel.price)}</span>
                </div>
              </div>
            )}
            {hasInternalPrice && !showCpiDetails && (
              <div className="row push-down">
                <p className="col-xs-12 price-details-description text-right">
                  Internal ({formatPrice(internalPriceModel.price)}) + Cint ({formatPrice(priceModel.price)})
                </p>
              </div>
            )}

            {showCpiDetails && (
              <div className="row push-down">
                <p className="col-xs-12 complete-fee">
                  {!hasInternalPrice && <span>* complete fee ({formatPrice(price.completeFee)}) + incentive</span>}
                  {hasInternalPrice && hasCompleteFee && (
                    <span>
                      * Internal ({formatPrice(internalPriceModel.cpi)}) + Cint complete fee (
                      {formatPrice(price.completeFee)}) + incentive
                    </span>
                  )}
                  {hasInternalPrice && !hasCompleteFee && (
                    <span>
                      * Internal ({formatPrice(internalPriceModel.cpi)}) + Cint ({formatPrice(priceModel.cpi)})
                    </span>
                  )}
                </p>
              </div>
            )}
            <InlineHelp id={helpTextId}>
              <div className="row push-down">
                <p className="col-xs-12 inline-help-text">{currentTab.helpText}</p>
              </div>
            </InlineHelp>
            {showAgreedPriceInfo && (
              <div className="row push-down">
                <p className="col-xs-12 complete-fee">* agreed CPI</p>
              </div>
            )}
            {showRateCardInfo && (
              <div className="row push-down">
                <div className="col-xs-12 complete-fee">
                  Using <a onClick={() => showRateCard()}>rate card</a> with IR:
                  <span className="bold-text"> {filters.percentFormat()(currentTab.ir)}</span> and LOI:
                  <span className="bold-text"> {filters.loiFormat()(currentTab.loi)}</span>
                </div>
              </div>
            )}
            {currentTab.showPriceDisclaimer && (
              <div className="row push-down">
                <p className="col-xs-12 price-disclaimer">
                  <span>* </span>
                  The price projection can fluctuate in the beginning of a project. It becomes more accurate the more
                  completes are collected.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
