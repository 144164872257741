import './ProjectChangeLogDialog.scss';
import { ui } from 'angular';
import { react2angular } from 'react2angular';
import classNames from 'classnames';
import { useState } from 'react';
import {
  ChangeDetailsTable,
  ProjectChangeEvent,
  ProjectChangeEventResponse,
} from '../http-services/project.httpservice';
import { ModalCloseButton } from './ModalCloseButton';
import { filters } from '../filters';
import { convertEventTypeToText } from './project-change-log-helper';
import { ChangeLogFilters } from './ChangeLogFilters';

export interface ProjectChangeLogDialogResolve {
  changeLog: ProjectChangeEventResponse;
}

export class ProjectChangeLogSettingsFactory {
  static create(resolve?: ProjectChangeLogDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'project-change-log-dialog',
      component: ProjectChangeLogDialogComponentName,
      resolve: {
        changeLog: () => resolve?.changeLog,
      },
    };
  }
}

interface Props {
  resolve: ProjectChangeLogDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
}

const ProjectChangeLogDialog: React.FC<Props> = ({ resolve: { changeLog }, modalInstance }) => {
  const [filteredLog, setFilteredLog] = useState(changeLog);

  const onHandleSearchAndFilter = (result: ProjectChangeEventResponse) => {
    setFilteredLog(result);
  };

  return (
    <div className="project-change-log-dialog">
      <ModalCloseButton onClose={modalInstance.dismiss} />
      <div className="modal-container">
        <div className="header">
          <h1 className="title">{`Project change log`}</h1>
        </div>
        <ChangeLogFilters changeLog={changeLog} onHandleChanges={onHandleSearchAndFilter} />
        {filteredLog.map((ev, index) => (
          <ProjectEventCard event={ev} key={index} />
        ))}
        <div className="modal-controls">
          <button className="ui-btn primary-btn" onClick={(_) => modalInstance.dismiss()}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

const ProjectEventCard: React.FC<{ event: ProjectChangeEvent }> = ({ event }) => {
  return (
    <div className="project-event-card">
      <span className="change-content">
        <span className="icon">
          <ChangeIcon userName={event.userName} />
        </span>
        <span className="description">
          <h2 className="change-text">
            {convertEventTypeToText(event.eventName, event.targetGroup?.name, event.quotaName)}
          </h2>
          {event.targetGroup != null && (
            <div className="subject-label">
              <em>Target group:</em> {`"${event.targetGroup.name}"`}
            </div>
          )}
          {event.quotaName != null && (
            <div className="subject-label">
              <em>Quota:</em> {`"${event.quotaName}"`}
            </div>
          )}
          <div className="time">
            <p>
              {filters.dateTimeFormat()(event.occuredAt)}, by {event.userName}
            </p>
          </div>
        </span>
        <span className="details">{!event.details.isEmpty && <ChangeTable changes={event.details} />}</span>
      </span>
    </div>
  );
};

const ChangeTable: React.FC<{ changes: ChangeDetailsTable }> = ({ changes }) => {
  return (
    <table className="change-table">
      <thead>
        {changes.columnHeaders?.length > 0 && (
          <tr>
            <th className="table-header" />
            {changes.columnHeaders.map((text, index) => (
              <th className="table-header" key={index}>
                {text}
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {changes.rows.map((r, index) => (
          <tr className="data-row" key={index}>
            <td className="table-header">{r.header}</td>
            {r.values.map((v, index) => (
              <td className="value" key={index}>
                {v}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ChangeIcon: React.FC<{ userName: string }> = ({ userName }) => {
  const isSystem = userName.toLowerCase() === 'system';
  return (
    <span
      className={classNames('change-icon', {
        system: isSystem,
        user: !isSystem,
      })}
      title={`Changed by ${userName}`}
    >
      {isSystem && <i className="fa-solid fa-cog" />}
      {!isSystem && <p>{filters.initials()(userName)}</p>}
    </span>
  );
};

export const ProjectChangeLogDialogComponentName = 'projectChangeLogDialog';
export const ngProjectChangeLogDialogComponent = {
  [ProjectChangeLogDialogComponentName]: react2angular(ProjectChangeLogDialog, ['resolve', 'modalInstance']),
};
