import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import { activeTargetGroupStore } from '../active-target-group/store/active-target-group.store';
import { PanelistPoolType } from '../../common/enums';
import { useChannel } from '../../common/channels/channel';
import { targetGroupChannel } from '../channels/target-group-channel';
import { panelistPoolHelper } from '../panelist-pool-helper';
import { useForceUpdate } from '../../common/custom-hooks';
import { PanelistPoolSearch } from '../supply/panelist-pool/PanelistPoolSearch';
import { validateIsPoolInUse } from '../supply/panelist-pool/TargetFromPanelistPoolApiDialog';

import './ExcludePanelistPool.scss';

export const panelistPoolApiInputString = '_PanelistPoolApi';

export const ExcludePanelistPool: React.FC = () => {
  const [selectionSaved, setSelectionSaved] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const forceUpdate = useForceUpdate();

  const panelistPool = activeTargetGroupStore.model.supply?.panelistPool;

  if (selectionSaved && !isValidating && panelistPool?.input !== panelistPoolApiInputString) setSelectionSaved(false);

  const hasIrrelevantPanelistPool =
    panelistPool &&
    (panelistPool.input !== panelistPoolApiInputString || panelistPool.type === PanelistPoolType.Inclusive) &&
    panelistPool.type !== PanelistPoolType.None;

  const hasRelevantPanelistPool =
    panelistPool?.type === PanelistPoolType.Exclusive && panelistPool.input === panelistPoolApiInputString;

  useChannel(targetGroupChannel.model.supplyState.panelistAvailabilityUpdated, () => {
    setIsValidating(false);
  });

  useChannel(targetGroupChannel.model.supply.updated, forceUpdate);
  useChannel(targetGroupChannel.model.restored, forceUpdate);

  const removeSelection = () => {
    setSelectionSaved(false);
    activeTargetGroupStore.supply.discardExclusionsPanelistPool();
  };

  const saveSelected = (selectedPoolId: string) => {
    validateIsPoolInUse(selectedPoolId, activeTargetGroupStore.model.identity.id).then((poolIsInUse) => {
      if (poolIsInUse) return;

      setSelectionSaved(true);
      setIsValidating(true);

      activeTargetGroupStore.supply.importFromPanelistPoolApi(selectedPoolId, PanelistPoolType.Exclusive);
    });
  };

  const availablePanelists = panelistPool
    ? panelistPoolHelper.getAvailablePanelistCount(panelistPool?.availabilityResult)
    : 0;

  const unavailablePanelists = panelistPool?.availabilityResult?.unavailablePanelists?.panelistCount ?? 0;

  return (
    <>
      {hasIrrelevantPanelistPool && panelistPool.type === PanelistPoolType.Inclusive && (
        <p className="exclusion-panelist-pool-disabled">
          Cannot exclude on a panelist pool while targeting specific panelists.
        </p>
      )}
      {hasIrrelevantPanelistPool && panelistPool.type === PanelistPoolType.Exclusive && (
        <p className="exclusion-panelist-pool-disabled">
          Cannot exclude on a panelist pool while excluding on individual panelists.
        </p>
      )}
      {!hasIrrelevantPanelistPool && (
        <>
          {!selectionSaved && !hasRelevantPanelistPool && (
            <div className="exclusion-panelist-pool-search-container">
              <PanelistPoolSearch onSave={saveSelected} />
            </div>
          )}
          {(selectionSaved || hasRelevantPanelistPool) && (
            <div>
              {isValidating && (
                <div className="spinner-container text-center">
                  <div className="exclusion-panelist-pool-spinner">Validating available panelists...</div>
                  <i className="fas fa-spinner fa-spin" aria-hidden="true" />
                </div>
              )}
              {!isValidating && (
                <div className="exclusion-panelist-pool-availability-container">
                  <div className="exclusion-panelist-pool-availability">
                    Out of {availablePanelists + unavailablePanelists} panelists, {availablePanelists} are available and
                    will be excluded.
                  </div>
                  <button type="button" onClick={removeSelection} className="ui-btn default-btn">
                    Remove exclusion
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export const ngExcludePanelistPoolComponent = {
  excludePanelistPoolComponent: react2angular(ExcludePanelistPool, [] as never[]),
};
