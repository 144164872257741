import { InMemoryStorage } from './in-memory-storage';

export class StorageFactory {
  private static useStorage: boolean = __PROCESS__.ENV !== 'testing';

  static shouldUseSessionStorage(): boolean {
    return this.useStorage && this.supportsSessionStorage();
  }

  static shouldUseLocalStorage(): boolean {
    return this.useStorage && this.supportsLocalStorage();
  }

  static getStorage(): Storage {
    return this.shouldUseSessionStorage() ? window.sessionStorage : new InMemoryStorage();
  }

  static getLocalStorage(): Storage {
    return this.shouldUseLocalStorage() ? window.localStorage : new InMemoryStorage();
  }

  private static supportsSessionStorage(): boolean {
    try {
      return 'sessionStorage' in window && window.sessionStorage !== null;
    } catch (e) {
      return false;
    }
  }

  private static supportsLocalStorage(): boolean {
    try {
      return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
      return false;
    }
  }
}
