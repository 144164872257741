import { ui } from 'angular';
import classNames from 'classnames';
import { capitalize, cloneDeep, compact, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { isUndefinedOrNull } from '../../helpers';
import { Quota, QuotaKey, QuotaSection } from '../../target-groups/active-target-group/models/active-quotas.model';
import { AgeSpan } from '../../target-groups/active-target-group/models/active-target-group.model';
import { activeTargetGroupStore } from '../../target-groups/active-target-group/store/active-target-group.store';
import { projectService } from '../../target-groups/project.service';
import { getTargetingTypesFromQuotasKeys } from '../../target-groups/quotas/quotas-helper.functions';
import { api, QuotaGroupPreset, QuotaPresetSaveResponse } from '../api';
import { CustomDropdownOption } from '../controls/custom-dropdown/custom-dropdown.component';
import { CustomDropdown } from '../controls/custom-dropdown/CustomDropdown';
import {
  Availability,
  CacheDuration,
  ProfilingScope,
  QuotaOptions,
  QuotaPresetSaveOutcome,
  TargetGender,
  TargetingType,
} from '../enums';
import { errorLogger } from '../error-logger';
import { filters, regionName, variableNames } from '../filters';
import { inMemoryCache } from '../in-memory-cache';
import { normalize } from '../normalize';
import { userService } from '../user.service';
import { ModalCloseButton } from './ModalCloseButton';
import { SaveQuotaPresetDialogResolve } from './save-quota-preset-dialog-settings.factory';
import './SaveQuotaPresetDialog.scss';

const cacheKeyPrefixRegion = 'QuotaPresetRegionData-';
const cacheKeyPrefixProfilingCategory = 'QuotaPresetProfilingCategoryData-';
const cacheKeyPrefixProfilingQuestion = 'QuotaPresetProfilingQuestionData-';

enum PresetType {
  Census = 'census',
  Custom = 'custom',
}

export type Variable = {
  id: number;
  questionId: number;
  scope: ProfilingScope;
  panelId: number;
};

type Region = {
  id: number;
  regionTypeId: number;
};

type QuotaPreset = {
  ordinal: number;
  variables: Variable[];
  regions: Region[];
  ageSpan: AgeSpan;
  gender: TargetGender.Male | TargetGender.Female | null;
  ratio: number;
};

export type UpsertQuotaGroupPresetRequest = {
  id: number;
  isGlobal: boolean;
  countryId: number;
  name: string;
  targetingTypes: TargetingType[];
  presetType: PresetType;
  interlocked: boolean;
  quotas: QuotaPreset[];
  description: string;
  source: string;
};

type FetchedNames = {
  done: boolean;
  regions?: { regionTypeId: number; regionNames: { id: number; name: string }[] }[];
  profiling?: { questionId: number; variableNames: { id: number; name: string }[] }[];
};

interface Props {
  resolve: SaveQuotaPresetDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
}

export const SaveQuotaPresetDialog: React.FC<Props> = ({
  resolve: { quotas, editMode, presetToEdit: quotaToEdit },
  modalInstance,
}) => {
  const [quotaGroupPreset, setQuotaGroupPreset] = useState(
    editMode ? mapPresetToRequest(quotaToEdit) : mapQuotasToRequest(quotas, Availability.Company)
  );
  const [fetchedNames, setFetchedNames] = useState<FetchedNames>({ done: !editMode });
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<QuotaPresetSaveOutcome>();
  const [censusSavingValidationError, setCensusSavingValidationError] = useState(false);
  const [hadChangeSinceLastError, setHadChangeSinceLastError] = useState(false);

  const isGlobalPresetUser = userService.isCpxCountryAdmin();

  const total = Math.round(quotaGroupPreset.quotas.reduce((acc, quota) => acc + quota.ratio, 0) * 100);
  const totalIsInvalid = total !== 100;
  const nameIsInvalid = isUndefinedOrNull(quotaGroupPreset.name) || quotaGroupPreset.name === '';

  const shouldShowProfilingQuestion =
    quotaGroupPreset.targetingTypes.length === 1 && quotaGroupPreset.targetingTypes[0] === TargetingType.Profiling;
  const canSave =
    fetchedNames.done &&
    !nameIsInvalid &&
    !totalIsInvalid &&
    !isSaving &&
    !censusSavingValidationError &&
    (isUndefinedOrNull(saveError) || hadChangeSinceLastError);

  const saveAsync = async () => {
    let saveResponse: QuotaPresetSaveResponse;

    try {
      saveResponse = (await api.quotaPresets.upsertPreset(quotaGroupPreset)).data;
    } catch (e) {
      errorLogger.errorWithData('unable to save quota preset: {quotaGroupPreset}', [quotaGroupPreset], e);
      setIsSaving(false);
      setSaveError(QuotaPresetSaveOutcome.UnknownError);
      setHadChangeSinceLastError(false);
      return;
    }

    if (saveResponse.status === QuotaPresetSaveOutcome.Success) {
      if (!quotaGroupPreset.isGlobal && quotaGroupPreset.presetType === PresetType.Census) {
        activeTargetGroupStore.quotas.handleNewlySavedCompanyCensusPreset({
          ...quotaGroupPreset,
          id: saveResponse.id,
        });

        if (quotaGroupPreset.targetingTypes.length !== 1) throw Error('Not implemented for interlocked presets...');

        switch (quotaGroupPreset.targetingTypes[0]) {
          case TargetingType.Age:
            activeTargetGroupStore.quotas.createAgeQuotas(QuotaOptions.Completes);
            break;
          case TargetingType.Gender:
            activeTargetGroupStore.quotas.createGenderQuotas(QuotaOptions.Completes);
            break;
          case TargetingType.Region:
            activeTargetGroupStore.quotas.createRegionQuotas(QuotaOptions.Completes);
            break;
          case TargetingType.Profiling:
            activeTargetGroupStore.quotas.createProfilingQuotas(
              QuotaOptions.Completes,
              quotaGroupPreset.quotas[0].variables[0].questionId
            );
            break;
          default:
            throw Error('Invalid targeting type');
        }
      }
      modalInstance.close();

      return;
    }

    setIsSaving(false);
    setSaveError(saveResponse.status);
    setHadChangeSinceLastError(false);
  };

  const getHeaderText = () => {
    const createTitle = (quotaType: string) =>
      `${editMode ? 'Edit' : 'Create'} ${quotaType.toLowerCase()} quota preset`;

    if (quotaGroupPreset.targetingTypes.length > 1) return createTitle('interlocked');

    return createTitle(quotaGroupPreset.targetingTypes[0]);
  };

  const getProfilingQuestion = () =>
    shouldShowProfilingQuestion ? filters.questionName()(quotaGroupPreset.quotas[0].variables[0].questionId) : '';

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuotaGroupPreset((prevValue) => ({ ...prevValue, name: e.target.value }));
    setHadChangeSinceLastError(true);
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuotaGroupPreset((prevValue) => ({ ...prevValue, source: e.target.value }));
    setHadChangeSinceLastError(true);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuotaGroupPreset((prevValue) => ({ ...prevValue, description: e.target.value }));
    setHadChangeSinceLastError(true);
  };

  const handleCancel = () => {
    modalInstance.dismiss();
  };

  const handleSave = () => {
    setIsSaving(true);
    saveAsync();
  };

  const onQuotaRatioChange = (e: React.ChangeEvent<HTMLInputElement>, ordinal: number) => {
    const newValue = e.target.value;
    if (newValue.includes('.') || newValue.includes(',')) return;

    const newRatio = parseInt(newValue, 10) / 100;
    if (Number.isNaN(newRatio)) return;

    setQuotaGroupPreset((prevValue) => ({
      ...prevValue,
      quotas: prevValue.quotas.map((quota) => {
        if (quota.ordinal === ordinal) quota.ratio = newRatio;
        return quota;
      }),
    }));

    setHadChangeSinceLastError(true);
  };

  const onPresetTypeSelect = ({ option }: { option: CustomDropdownOption }) => {
    setQuotaGroupPreset((prevValue) => ({
      ...prevValue,
      presetType: option.value,
      source: option.value === PresetType.Custom ? '' : prevValue.source,
      description: option.value === PresetType.Census ? '' : prevValue.description,
    }));
    setHadChangeSinceLastError(true);
  };

  const onAvailabilitySelect = ({ option }: { option: CustomDropdownOption }) => {
    setQuotaGroupPreset((prevValue) => ({ ...prevValue, isGlobal: option.value === Availability.Global }));
    setHadChangeSinceLastError(true);
  };

  useEffect(() => {
    if (editMode) fetchNamesForIds(quotaToEdit, setFetchedNames);
  }, []);

  const targetingType = quotaGroupPreset.targetingTypes[0]; // TODO: this will have to change if we will support interlocked quotas
  let key = targetingType === TargetingType.Age ? 'ageSpan' : targetingType;

  if (targetingType === TargetingType.Profiling)
    key += `:question${quotaGroupPreset.quotas[0].variables[0].questionId}`;

  const shouldSetValidationError =
    quotaGroupPreset.presetType === PresetType.Census && projectService.inactiveTgHasCensusQuota(key);

  if (shouldSetValidationError && !censusSavingValidationError) setCensusSavingValidationError(true);
  else if (!shouldSetValidationError && censusSavingValidationError) setCensusSavingValidationError(false);

  return (
    <>
      <ModalCloseButton onClose={modalInstance.dismiss} disabled={isSaving} />
      <div className="modal-container save-quota-preset-dialog">
        <h1 className="modal-header">{getHeaderText()}</h1>
        <div className="description">
          <input
            className="form-control"
            type="text"
            placeholder="Preset name"
            disabled={isSaving}
            value={quotaGroupPreset.name}
            data-testid="save-preset-name"
            onChange={handleNameChange}
          />
          {nameIsInvalid && (
            <div className="invalid-name">Please enter a valid name for the preset before saving it.</div>
          )}
          {shouldShowProfilingQuestion && <div className="profiling-question">{getProfilingQuestion()}</div>}
          <div className="quota-list">
            {!fetchedNames.done && (
              <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin" />
              </div>
            )}
            {fetchedNames.done &&
              quotaGroupPreset.quotas.map((quota) => (
                <div className="quota-item" key={quota.ordinal}>
                  <div className="quota-name" data-testid={`quota-name-${quota.ordinal}`}>
                    <span>{getQuotaNameToDisplay(quota, editMode, fetchedNames)}</span>
                  </div>
                  <div className="optional-percent-input active">
                    <input
                      data-testid={`quota-${quota.ordinal}`}
                      className="form-control"
                      type="number"
                      title="Quota percentage"
                      disabled={isSaving}
                      value={Math.round(quota.ratio * 100)}
                      onChange={(e) => {
                        onQuotaRatioChange(e, quota.ordinal);
                      }}
                    />
                    <span className="input-percent-mark">%</span>
                  </div>
                </div>
              ))}
          </div>
          {isGlobalPresetUser && quotaGroupPreset.presetType === PresetType.Census && quotaGroupPreset.isGlobal && (
            <input
              className="form-control"
              type="text"
              placeholder="Please describe the source of this census data"
              value={quotaGroupPreset.source}
              maxLength={100}
              data-testid="save-preset-source"
              onChange={handleSourceChange}
            />
          )}
          <div className="bottom-controls">
            {isGlobalPresetUser && (
              <div className="dropdown-container">
                <span className="dropdown-label">Availability</span>
                <CustomDropdown
                  options={getAvailabilityOptions()}
                  controlledInput={true}
                  value={quotaGroupPreset.isGlobal ? Availability.Global : Availability.Company}
                  onOptionSelect={onAvailabilitySelect}
                  disabled={isSaving || editMode}
                />
              </div>
            )}
            <div className="dropdown-container">
              <span className="dropdown-label">Type</span>
              <CustomDropdown
                options={getPresetTypeOptions()}
                controlledInput={true}
                value={quotaGroupPreset.presetType}
                onOptionSelect={onPresetTypeSelect}
                disabled={isSaving || editMode}
              />
            </div>
            <div className="total-container">
              <span className="total-label">Total:</span>
              <span
                className={classNames({ 'invalid-total': totalIsInvalid })}
                data-testid="save-preset-total-percentage"
              >{`${total}%`}</span>
            </div>
          </div>
          {totalIsInvalid && (
            <div className="alert alert-warning">Please ensure that the total ratio of quotas adds up to 100%.</div>
          )}
          {quotaGroupPreset.presetType === PresetType.Custom && (
            <>
              <div className="description-label">Description</div>
              <textarea
                className="form-control"
                placeholder="If you provide a description for this preset, it will be visible when users load presets, when the user places the mouse cursor over the preset's name."
                value={quotaGroupPreset.description}
                maxLength={2000}
                data-testid="save-preset-description"
                onChange={handleDescriptionChange}
              />
            </>
          )}
          {saveError === QuotaPresetSaveOutcome.NameExists && !hadChangeSinceLastError && (
            <div className="alert alert-danger" data-testid="save-preset-error-duplicate-name">
              A preset with the same name already exists. Please change the name and try again.
            </div>
          )}
          {saveError === QuotaPresetSaveOutcome.CensusNonProfilingPresetExists && !hadChangeSinceLastError && (
            <div className="alert alert-danger" data-testid="save-preset-error-duplicate-type">
              A census preset for the same targeting type already exists.
            </div>
          )}
          {saveError === QuotaPresetSaveOutcome.CensusProfilingPresetExists && !hadChangeSinceLastError && (
            <div className="alert alert-danger" data-testid="save-preset-error-duplicate-type">
              A census preset for the same profiling question already exists.
            </div>
          )}
          {saveError === QuotaPresetSaveOutcome.UnknownError && !hadChangeSinceLastError && (
            <div className="alert alert-danger" data-testid="save-preset-error-unknown">
              An unknown error has occurred while trying to save your preset.
            </div>
          )}
          {censusSavingValidationError && (
            <div className="alert alert-danger" data-testid="save-preset-error-unknown">
              Some of your other target groups have a census quota for the same quota type as the one you are trying to
              save here. Please remove those census quotas before attempting to save this census preset.
            </div>
          )}
        </div>
        <div className="modal-controls">
          <button
            className="ui-btn default-btn"
            disabled={isSaving}
            onClick={handleCancel}
            data-testid="save-preset-cancel-button"
          >
            Cancel
          </button>
          <button
            className="ui-btn primary-btn"
            disabled={!canSave}
            onClick={handleSave}
            data-testid="save-preset-save-button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

function getAvailabilityOptions() {
  return [
    { value: Availability.Global, name: 'Global' },
    { value: Availability.Company, name: 'My Company' },
  ];
}

function getPresetTypeOptions() {
  return [
    { value: PresetType.Census, name: 'Census' },
    { value: PresetType.Custom, name: 'Custom' },
  ];
}

async function fetchNamesForIds(
  preset: QuotaGroupPreset,
  setState: React.Dispatch<React.SetStateAction<FetchedNames>>
) {
  const { countryId } = activeTargetGroupStore.model.basicSettings;

  // Regions

  const regionTypeIds = preset.quotas
    .map((q) => q.regions)
    .flat()
    .map((r) => r.regionTypeId);

  const regionsWithNames = [];

  for await (const regionTypeId of uniq(regionTypeIds)) {
    const cacheKey = `${cacheKeyPrefixRegion}${regionTypeId}`;

    const apiResponse = await inMemoryCache.getOrAddAsync(
      cacheKey,
      async () => api.region.getRegions(countryId, regionTypeId),
      CacheDuration.Extreme
    );

    regionsWithNames.push({
      regionTypeId,
      regionNames: apiResponse.data.map((r) => ({ id: r.id, name: r.name })),
    });
  }

  // Profiling

  const questionIds = preset.quotas
    .map((q) => q.variables)
    .flat()
    .map((v) => v.questionId);

  const variablesWithNames = [];

  for await (const questionId of uniq(questionIds)) {
    const categoryCacheKey = `${cacheKeyPrefixProfilingCategory}${questionId}`;

    const categoryId = await inMemoryCache.getOrAddAsync(
      categoryCacheKey,
      async () => api.profiling.getCategoryIdByQuestionId(countryId, questionId),
      CacheDuration.Extreme
    );

    const questionCacheKey = `${cacheKeyPrefixProfilingQuestion}${categoryId}`;

    const apiResponse = await inMemoryCache.getOrAddAsync(
      questionCacheKey,
      async () => api.profiling.getCategoryDetails({ countryId }, categoryId),
      CacheDuration.Extreme
    );

    variablesWithNames.push({
      questionId,
      variableNames: apiResponse.data.questions
        .find((qs) => qs.id === questionId)
        .variables.map((v) => ({ id: v.id, name: v.name })),
    });
  }

  setState({
    done: true,
    regions: regionsWithNames,
    profiling: variablesWithNames,
  });
}

function mapPresetToRequest(preset: QuotaGroupPreset): UpsertQuotaGroupPresetRequest {
  return {
    id: preset.id,
    countryId: activeTargetGroupStore.model.basicSettings.countryId,
    name: preset.name,
    targetingTypes: preset.targetingTypes as TargetingType[],
    presetType: preset.presetType as PresetType,
    interlocked: false, // TODO: support for interlocked presets in a future iteration
    quotas: preset.quotas,
    isGlobal: preset.companyId == null,
    description: preset.presetType === PresetType.Custom ? preset.description : '',
    source: preset.presetType === PresetType.Census ? preset.source : '',
  };
}

function mapQuotasToRequest(quotas: QuotaSection, availability: Availability): UpsertQuotaGroupPresetRequest {
  return {
    id: 0,
    countryId: activeTargetGroupStore.model.basicSettings.countryId,
    name: '',
    targetingTypes: getTargetingTypesFromQuotasKeys(quotas.keys),
    presetType: quotas.option === QuotaOptions.Custom ? PresetType.Custom : PresetType.Census,
    interlocked: false, // TODO: support for interlocked presets in a future iteration
    quotas: getQuotaPresetsFromQuotasItems(quotas.items),
    isGlobal: availability === Availability.Global,
    description: '',
    source: '',
  };
}

function getQuotaPresetsFromQuotasItems(items: Quota[]) {
  const quotaPresets: QuotaPreset[] = [];
  const percentage = activeTargetGroupStore.model.quotas.usePercentages;
  const { panelSpecificProfiling } = activeTargetGroupStore.model.profiling;
  const { selectedIds } = activeTargetGroupStore.model.supply;

  let ordinalCounter = 0;

  items.forEach((item) => {
    const quotaPreset: QuotaPreset = {
      ordinal: ordinalCounter++,
      gender: quotaPresetGender(item.key.gender),
      ageSpan: getAgeSpanFromQuotaKey(item.key),
      regions: item.key.region.map((regionId) => ({
        id: regionId,
        regionTypeId: activeTargetGroupStore.model.regions.selectedRegionType.id,
      })),
      variables: item.key.profiling.map((variable) => ({
        id: variable.variableId,
        questionId: variable.questionId,
        scope: panelSpecificProfiling ? ProfilingScope.PanelSpecific : ProfilingScope.Global,
        panelId: panelSpecificProfiling ? selectedIds[0] : null,
      })),
      ratio: percentage ? item.completes / 100 : item.completes,
    };

    quotaPresets.push(quotaPreset);
  });

  if (!percentage) {
    normalize(100, quotaPresets, 'ratio');
    quotaPresets.forEach((preset) => (preset.ratio /= 100));
  }

  return quotaPresets;
}

function quotaPresetGender(gender: TargetGender) {
  switch (gender) {
    case TargetGender.Female:
    case TargetGender.Male:
      return gender;
    case undefined:
      return null;
    default:
      throw Error(`gender ${gender} unsupported for quota presets`);
  }
}

function getAgeSpanFromQuotaKey({ ageSpan }: QuotaKey) {
  if (isUndefinedOrNull(ageSpan)) return null;
  return ageSpan.from === 0 && ageSpan.to === 0 ? null : cloneDeep(ageSpan);
}

function getQuotaNameToDisplay(quota: QuotaPreset, editMode: boolean, fetchedNames: FetchedNames) {
  const isGenderQuota = (quota: QuotaPreset) => !isUndefinedOrNull(quota.gender);
  const isAgeQuota = (quota: QuotaPreset) => !isUndefinedOrNull(quota.ageSpan);
  const isRegionQuota = (quota: QuotaPreset) => quota.regions?.length;
  const isProfilingQuota = (quota: QuotaPreset) => quota.variables?.length;

  if (quota === undefined) return '';
  const name: string[] = [];
  if (isGenderQuota(quota)) name.push(capitalize(quota.gender));
  if (isAgeQuota(quota)) name.push(`${quota.ageSpan.from}-${quota.ageSpan.to}`);
  if (isRegionQuota(quota)) {
    for (const region of quota.regions) {
      if (editMode) {
        name.push(
          fetchedNames.regions
            ?.find((r) => r.regionTypeId === region.regionTypeId)
            .regionNames.find((r) => r.id === region.id).name
        );
      } else name.push(regionName(region.id));
    }
  }
  if (isProfilingQuota(quota)) {
    if (editMode) {
      quota.variables.forEach((v) =>
        name.push(
          fetchedNames.profiling?.find((p) => p.questionId === v.questionId).variableNames.find((x) => x.id === v.id)
            .name
        )
      );
    } else name.push(...variableNames(quota.variables));
  }

  return compact(name).join(', ');
}

export const SaveQuotaPresetDialogComponentName = 'saveQuotaPresetDialogComponent';
export const ngSaveQuotaPresetDialogComponent = {
  [SaveQuotaPresetDialogComponentName]: react2angular(SaveQuotaPresetDialog, ['resolve', 'modalInstance']),
};
