import { ui } from 'angular';
import { react2angular } from 'react2angular';
import { ModalCloseButton } from './ModalCloseButton';
import { InfoDialogResolve } from './info-dialog-settings.factory';

interface Props {
  resolve: InfoDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
}

const InfoDialog: React.FC<Props> = ({ resolve: { title, text, okCaption }, modalInstance }) => {
  return (
    <div className="info-dialog-component">
      <ModalCloseButton onClose={modalInstance.dismiss} />
      <div className="modal-container">
        <h1 className="modal-header">{title}</h1>
        <p>{text}</p>
        <div className="modal-controls">
          <button className="ui-btn primary-btn" onClick={(_) => modalInstance.dismiss()}>
            {okCaption || 'Ok'}
          </button>
        </div>
      </div>
    </div>
  );
};

export const InfoDialogComponentName = 'infoDialogComponent';
export const ngInfoDialogComponent = {
  [InfoDialogComponentName]: react2angular(InfoDialog, ['resolve', 'modalInstance']),
};
