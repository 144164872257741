import { ui } from 'angular';
import { TransitionOptions } from '@uirouter/angularjs';
import { ExtendedModalServiceInstance } from '../modal.service';
import { $state } from '../../ngimport';
import { silenceRejection } from '../../helpers';

export interface DialogMethods {
  onDismiss: '&' | ((_?: { reason: any }) => void);
  onConfirm: '&' | ((_?: { result: any }) => void);
}

export class DialogController implements DialogMethods {
  constructor(private $uibModalInstance: ui.bootstrap.IModalInstanceService) {}

  onDismiss(reason?: any): void {
    return this.$uibModalInstance.dismiss(reason);
  }
  onConfirm(result?: any): void {
    return this.$uibModalInstance.close(result);
  }
}

export function onEnter(modalInstance: ExtendedModalServiceInstance): void {
  'ngInject';

  modalInstance.result.catch(silenceRejection).finally(() => $state.go('^'));
}

export function onEnterWithOptions(transitionOptions: TransitionOptions) {
  return (modalInstance: ExtendedModalServiceInstance) => {
    'ngInject';

    modalInstance.result.catch(silenceRejection).finally(() => $state.go('^', null, transitionOptions));
  };
}

export function onExit(modalInstance: ExtendedModalServiceInstance): void {
  'ngInject';

  if (modalInstance === undefined) return;
  modalInstance.dismiss();
}
