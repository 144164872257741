import { ui } from 'angular';
import { html } from './helpers';

const selector = 'clearManagedServicesModeDialogComponent';

const template = html`
  <div class="clear-managed-services-mode-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Disable Managed Services</h1>
      <p>A Managed Services project cannot be converted to an Access project.</p>
      <p class="push-down">If you continue, the current project will be discarded.</p>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button class="ui-btn primary-btn" ng-click="$ctrl.close()">Continue</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class ClearManagedServicesModeDialogComponent implements Bindings {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;

  close(): void {
    this.modalInstance.close();
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }
}

export const ngClearManagedServicesModeDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ClearManagedServicesModeDialogComponent,
  },
};
