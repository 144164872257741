import { find } from 'lodash-es';
import { CurrenciesResponse, Currency } from '../common/http-services/pricing.httpservice';

export class CurrencyService {
  private currenciesList: Currency[];
  private ISOcurrenciesList: Currency[];

  get currencies() {
    return this.currenciesList;
  }
  get ISOcurrencies() {
    return this.ISOcurrenciesList;
  }

  persist(currencies: CurrenciesResponse): void {
    this.currenciesList = currencies.items;
  }

  persistISO(currencies: CurrenciesResponse): void {
    this.ISOcurrenciesList = currencies.items;
  }

  getCurrency(currencyCode: string): Currency {
    const currency = find(this.currencies, (c) => c.currencyCode === currencyCode);
    if (currency === undefined) throw Error('could not find currency');

    return currency;
  }
}

export const currencyService = new CurrencyService();
