import { element, ui } from 'angular';
import { $timeout, $document, $uibModal } from '../ngimport';

export enum ModalSize {
  Medium = 'm',
  Large = 'lg',
  Custom = 'custom',
}

export interface ExtendedModalServiceInstance extends ui.bootstrap.IModalInstanceService {
  show(): void;
  hide(): void;
}

export class ModalService {
  id = 1;

  openSmall(options: ui.bootstrap.IModalSettings): ExtendedModalServiceInstance {
    return this.openInternal(options, ModalSize.Custom, '400px');
  }

  openMedium(options: ui.bootstrap.IModalSettings): ExtendedModalServiceInstance {
    return this.openInternal(options, ModalSize.Medium);
  }
  openLarge(options: ui.bootstrap.IModalSettings): ExtendedModalServiceInstance {
    return this.openInternal(options, ModalSize.Large);
  }

  private openInternal(
    options: ui.bootstrap.IModalSettings,
    size: ModalSize,
    cssWidth?: string
  ): ExtendedModalServiceInstance {
    if (!options.windowClass) {
      throw Error('modalService error: every modal needs a `windowClass`');
    }

    options.windowClass = `${options.windowClass}--id--${this.id}`;
    this.id++;

    if (size === ModalSize.Custom && cssWidth) {
      options.size = cssWidth;
    } else {
      options.size = size;
    }

    if (options.keyboard === undefined) {
      options.keyboard = true;
    }

    if (options.backdrop === undefined) {
      options.backdrop = 'static';
    }

    const modalInstance = $uibModal.open(options) as ExtendedModalServiceInstance;
    modalInstance.opened.then(() => {
      if (cssWidth) {
        $timeout(() => {
          const modalDialog = this.querySelector(`.${options.windowClass} .modal-dialog`);
          element(modalDialog).css('width', cssWidth);
          element(modalDialog).addClass('modal-dialog-small');
        }, 0);
      }

      $timeout(() => {
        const elm: any = element(this.querySelector(`.${options.windowClass} [autofocus]`));
        if (elm && elm.length > 0) {
          elm[0].focus();
          if (!elm[0].attributes['no-autoselect']) {
            elm[0].select();
          }
        }
      }, 200);
    });

    modalInstance.closed.then((_) => {
      this.mirrorClassToHtml('modal-open');
    });

    modalInstance.opened.then((_) => {
      this.mirrorClassToHtml('modal-open');
    });

    modalInstance.hide = () => {
      element(this.querySelector(`.${options.windowClass}`)).addClass('hide');
    };

    modalInstance.show = () => {
      element(this.querySelector(`.${options.windowClass}`)).removeClass('hide');
    };

    return modalInstance;
  }

  private querySelector(selector: string) {
    return $document[0].querySelectorAll(selector);
  }

  private mirrorClassToHtml(className: string) {
    const hasClass = element(this.querySelector('body')).hasClass(className);

    if (hasClass) {
      element(this.querySelector('html')).addClass(className);
    } else {
      element(this.querySelector('html')).removeClass(className);
    }
  }
}

export const modalService = new ModalService();
