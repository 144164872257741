import { IPromise } from 'angular';
import { cloneDeep, sortBy } from 'lodash-es';
import { Census } from '../../models/active-target-group.model';
import { api } from '../../../../common/api';
import { mutators } from './census.mutators';
import { $q } from '../../../../ngimport';
import { logProduce } from '../../../../common/immer-wrapper';
import { featureFlipper } from '../../../../common/feature-flipper';
import {
  AgeCensus,
  GenderCensus,
  ProfilingCensus,
  RegionCensus,
} from '../../../../common/http-services/census-response.model';

export class CensusSubactions {
  setGenderCensus(state: Census, genderCensus: GenderCensus) {
    return logProduce(state, mutators.setCensus(genderCensus));
  }

  setAgeCensus(state: Census, ageCensus: AgeCensus) {
    return logProduce(state, mutators.setCensus(null, ageCensus));
  }

  setRegionCensus(state: Census, regionCensus: RegionCensus) {
    return logProduce(state, mutators.setCensus(null, null, regionCensus));
  }

  setProfilingCensusQuestion(state: Census, profilingCensusQuestion: ProfilingCensus) {
    const profilingCensus = cloneDeep(state.profiling).filter(
      (p) => p.questionId !== profilingCensusQuestion.questionId
    );

    profilingCensus.push(profilingCensusQuestion);

    return logProduce(state, mutators.setCensus(null, null, null, profilingCensus));
  }

  createCensusPromise(state: Census, countryId: number, minAge: number, maxAge: number): IPromise<Census> {
    if (countryId <= 0) {
      return $q.when(
        logProduce(state, () => {
          return {} as Census;
        })
      );
    }

    if (featureFlipper.isEnabled('useNewCensus')) {
      return api.census.getCensus(countryId, minAge, maxAge).then((res) => {
        const { genders, ageSpans, regions, profiling } = res.data;
        return logProduce(state, mutators.setCensus(genders, ageSpans, regions, profiling));
      });
    }

    return api.census.getCensusDeprecated(countryId, minAge, maxAge).then((res) => {
      const { genders, ageSpans } = res.data;
      let { regions } = res.data;
      regions = sortBy(regions, 'name');
      return logProduce(
        state,
        mutators.setCensus(
          { quotas: genders, source: '', isCompanyCensus: false, presetId: 0 },
          { quotas: ageSpans, source: '', isCompanyCensus: false, presetId: 0 },
          {
            quotas: regions.map((r) => ({ regionIds: [r.id], percentage: r.percentage })),
            source: '',
            isCompanyCensus: false,
            presetId: 0,
          },
          []
        )
      );
    });
  }
}

export const censusSubactions = new CensusSubactions();
