import { PanelistPool, SupplyModel } from '../../../common/models/supply.model';
import { DisqualifiedPanelReason, PanelistPoolType, SupplySource } from '../../../common/enums';
import {
  AvailabilityResultResponse,
  CreatePanelistPoolResponse,
} from '../../../common/http-services/supply.httpservice';
import { ErrorModel } from '../../validation-errors';
import { ActiveSupplyMixSource } from '../../supply/supply-mix/supply-mix-state.service';
import { Constants } from '../../../constants';
import { GuidString } from '../../../custom-runtypes';

export interface ActivePanelistPool extends PanelistPool {
  readonly availabilityResult: AvailabilityResultResponse;
}

export interface CreatePanelistPoolResult {
  readonly poolId: GuidString;
  readonly duplicationCount: number;
  readonly receivedCount: number;
  readonly committedCount: number;
  readonly invalidIdCount: number;
}

export interface SupplyGroupCustomCpiSettings {
  currency: string;
  minValue: number;
  maxValue: number;
}

export interface ActiveSupplyGroup {
  readonly id: number;
  readonly name: string;
  readonly panelIds: number[];
  readonly percentage: number;
  readonly wantedCompletes: number;
  readonly wantedStarts?: number | undefined;
  readonly source: ActiveSupplyMixSource;
  readonly feasibleCompletes?: number | null;
  readonly useCustomCpi: boolean;
  readonly customCpi?: number;
  readonly ccpiSettings?: SupplyGroupCustomCpiSettings;
}

export interface ActiveSupplyMix {
  readonly supplyGroups: ActiveSupplyGroup[];
  readonly ignoreCompletes: boolean;
}

export interface AdHocSupplier {
  readonly id: number;
  readonly name: string;
  readonly isSelected?: boolean; // TODO: argh why? we already have an `adHoc` property for the selected one so this is daft
}

export interface AdHocLanguage {
  readonly id: number;
  readonly name: string;
}

export interface SelectedAdHocSupplier {
  readonly id: number;
  readonly languageId: number;
}

export interface Panel {
  readonly id: number;
  readonly name: string;
  readonly isLocked: boolean;
  readonly isDisqualified: boolean;
  readonly isPush: boolean;
  readonly supportsCustomCpi: boolean;
  readonly currencyCode: string;
  readonly disqualifiedPanelReasons: DisqualifiedPanelReason[];
  readonly privatePricingRateCardHash: string;
  readonly feasibleCompletes: number | null;
  readonly panelistCount: number;
  readonly tags: string[];
}

export type EligiblePanelSourceKeys = SupplySource.CintPanels | SupplySource.OwnPanels | SupplySource.PrivatePricing;
export type PanelSources = {
  [key in EligiblePanelSourceKeys]: Panel[];
};

export interface ActiveSupplyModel {
  readonly panelSources: PanelSources;
  readonly adHocSuppliers: AdHocSupplier[];
  readonly languages: AdHocLanguage[];
  readonly selectedIds: number[];
  readonly adHoc: SelectedAdHocSupplier;
  readonly supplyMix: ActiveSupplyMix;
  readonly supplySource: SupplySource;
  readonly panelistPool: ActivePanelistPool;
  readonly includeLockedPanels: boolean;
  readonly ownPanelCurrency?: string;
  readonly selectedPrivatePricingRateCard?: string;
  readonly useCustomCpi: boolean;
  readonly validationResult?: ErrorModel;
  readonly customCpi?: number;
}

export function createActiveSupplyModel(): ActiveSupplyModel {
  return {
    panelSources: {} as PanelSources,
    adHocSuppliers: [],
    languages: [],
    selectedIds: [],
    adHoc: {} as SelectedAdHocSupplier,
    supplyMix: { supplyGroups: [], ignoreCompletes: false },
    supplySource: SupplySource.SystemSelected,
    includeLockedPanels: false,
    useCustomCpi: false,
    panelistPool: {
      source: null,
      input: '',
      rootPoolId: Constants.emptyGuid,
      selectedPoolId: Constants.emptyGuid,
      panelistCount: 0,
      panelIds: [],
      selectedGroup: '',
      availabilityResult: {} as AvailabilityResultResponse,
      createPoolResult: {} as CreatePanelistPoolResponse,
      hasAvailablePanelists: true,
      type: PanelistPoolType.None,
      containsUrl: false,
      sourceUrl: null,
    },
  };
}

export function createActiveSupplyModelFrom(supply: SupplyModel): ActiveSupplyModel {
  return {
    panelSources: {} as PanelSources,
    adHocSuppliers: [],
    languages: [],
    selectedIds: supply.selectedIds,
    adHoc: supply.adHocSupplier,
    supplyMix: { ...supply.supplyMix, ignoreCompletes: false },
    supplySource: supply.supplySource,
    includeLockedPanels: false,
    useCustomCpi: supply.useCustomCpi,
    panelistPool: {
      source: supply.panelistPool.source,
      input: supply.panelistPool.input,
      rootPoolId: supply.panelistPool.rootPoolId,
      selectedPoolId: supply.panelistPool.selectedPoolId,
      panelistCount: supply.panelistPool.panelistCount,
      panelIds: supply.panelistPool.panelIds,
      selectedGroup: supply.panelistPool.selectedGroup,
      createPoolResult: supply.panelistPool.createPoolResult,
      availabilityResult: {} as AvailabilityResultResponse, // calculated on restore
      hasAvailablePanelists: supply.panelistPool.hasAvailablePanelists,
      type: supply.panelistPool.type,
      containsUrl: supply.panelistPool.containsUrl,
      sourceUrl: supply.panelistPool.sourceUrl,
    },
    ownPanelCurrency: supply.ownPanelCurrency,
    selectedPrivatePricingRateCard: supply.selectedPrivatePricingRateCard,
    customCpi: supply.customCpi,
  };
}
