import './recreate-project-dialog.component.scss';
import { IFormController, ui } from 'angular';
import { html } from '../helpers';
import { api, RecreateProjectRequest } from '../common/api';
import { ProductMode } from '../common/enums';

const selector = 'recreateProjectDialogComponent';

const template = html`
  <div class="recreate-project-dialog-component">
    <recreate-project-component modal-instance="$ctrl.modalInstance"></recreate-project-component>
    <modal-close-button-component close-action="$ctrl.close()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Recreate Access project</h1>
      <div>
        <form name="$ctrl.recreateProjectForm" novalidate>
          <div class="form-group">
            <label>
              ProjectId
              <input type="number" class="form-control" ng-model="$ctrl.projectId" required />
            </label>
          </div>
          <div class="form-group">
            <label>
              Product mode
              <select ng-model="$ctrl.request.product" class="form-control" required>
                <option value="${ProductMode.Access}">Access</option>
                <option value="${ProductMode.AccessPro}">Access Pro</option>
                <option value="${ProductMode.ManagedServices}">Managed Services</option>
              </select>
            </label>
          </div>
          <div class="form-group">
            <label>
              Working Document ID
              <input
                type="text"
                class="form-control"
                ng-model="$ctrl.request.workingDocumentId"
                required
                ng-minlength="36"
                ng-maxlength="36"
              />
            </label>
          </div>
        </form>
        <div class="info-container">
          <span ng-if="$ctrl.error.length > 0" class="label label-danger">{{ $ctrl.error }}</span>
          <span ng-if="$ctrl.successMessage.length > 0" class="label label-success">{{$ctrl.successMessage}}</span>
        </div>
        <div class="modal-controls">
          <button type="button" class="ui-btn default-btn" ng-click="$ctrl.close()">Cancel</button>
          <button
            type="button"
            class="ui-btn primary-btn"
            ng-click="$ctrl.recreate()"
            ng-disabled="!$ctrl.recreateProjectForm.$valid"
          >
            Recreate
          </button>
        </div>
      </div>
    </div>
  </div>
`;
interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class RecreateProjectDialogComponent implements Bindings {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;
  projectId: number;
  request: RecreateProjectRequest = { workingDocumentId: null, product: null };
  error: string;
  successMessage: string;
  recreateProjectForm: IFormController;
  timeoutRef: NodeJS.Timeout;
  timeoutInSeconds = 5;

  close(): void {
    this.modalInstance.dismiss();
  }

  clearInput(): void {
    this.request = { workingDocumentId: null, product: null };
    this.projectId = undefined;
  }

  handleSuccess(projectId: number): void {
    this.successMessage = `Successfully created project '${projectId}' in Access db`;
    this.clearInput();
    this.timeoutRef = setTimeout(() => {
      this.successMessage = undefined;
    }, this.timeoutInSeconds * 1000);
  }

  recreate(): void {
    this.error = undefined;
    this.successMessage = undefined;
    if (!this.recreateProjectForm.$valid) return;
    api.feature
      .recreateProject(this.projectId, this.request)
      .then((res) => {
        const { projectId } = res.data;
        this.handleSuccess(projectId);
      })
      .catch(() => {
        this.error = 'Something went wrong, ensure the working document and project exist and matches';
      });
  }
}

export const ngRecreateProjectDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: RecreateProjectDialogComponent,
  },
};

export class RecreateProjectDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'recreate-project-dialog',
      backdrop: 'static',
      component: RecreateProjectDialogComponent.componentName,
    };
  }
}
