import { CountryRestrictions } from '../../../country.service';
import { ActiveBasicSettingsModel } from '../../models/active-basic-settings.model';
import { TargetGender } from '../../../../common/enums';
import { Iso8601Date } from '../../../../helpers';
import { mutators } from './basic-settings.mutators';
import { logProduce } from '../../../../common/immer-wrapper';
import { basicSettingsValidator } from '../../../basic-settings/basic-settings-validator';

export class BasicSettingsSubactions {
  countryChanged(
    state: ActiveBasicSettingsModel,
    country: { id: number; name: string },
    restrictions: CountryRestrictions
  ) {
    return logProduce(state, mutators.setCountry(country, restrictions));
  }

  genderChanged(state: ActiveBasicSettingsModel, gender: TargetGender) {
    return logProduce(state, mutators.setGender(gender));
  }

  ageChanged(state: ActiveBasicSettingsModel, age: { minAge: number; maxAge: number }) {
    return logProduce(state, mutators.setAge(age));
  }

  completesChanged(state: ActiveBasicSettingsModel, completes: number) {
    return logProduce(state, mutators.setCompletes(completes));
  }

  startsChanged(state: ActiveBasicSettingsModel, starts: number) {
    return logProduce(state, mutators.setStarts(starts));
  }

  useStartsChanged(state: ActiveBasicSettingsModel, useStarts: boolean) {
    return logProduce(state, mutators.setUseStarts(useStarts));
  }

  irChanged(state: ActiveBasicSettingsModel, ir: number) {
    return logProduce(state, mutators.setIr(ir));
  }

  loiChanged(state: ActiveBasicSettingsModel, loi: number) {
    return logProduce(state, mutators.setLoi(loi));
  }

  setUseFixedLoiChanged(state: ActiveBasicSettingsModel, useFixedLoi: boolean) {
    return logProduce(state, mutators.setUseFixedLoi(useFixedLoi));
  }

  startDateChanged(state: ActiveBasicSettingsModel, startDate: Iso8601Date) {
    return logProduce(state, mutators.setStartDate(startDate));
  }

  numberOfDaysInFieldChanged(state: ActiveBasicSettingsModel, numberOfDays: number) {
    return logProduce(state, mutators.setNumberOfDaysInField(numberOfDays));
  }

  validateBasicSettings(state: ActiveBasicSettingsModel, overrideIrAndLoiRestrictionsFunc: Function) {
    return logProduce(state, (draft) => {
      draft.validationResult = basicSettingsValidator.validate(state, overrideIrAndLoiRestrictionsFunc());
    });
  }
}

export const basicSettingsSubactions = new BasicSettingsSubactions();
