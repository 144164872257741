import './SearchAndFilter.scss';
import { some } from 'lodash-es';
import { Filter, FilterResult } from './filter';
import { FilterListButton } from './FilterListButton';
import { InlineActiveFilterList } from './InlineActiveFilterList';
import { SearchInput } from '../search-input/SearchInput';

interface Props {
  ['data-testid']?: string;
  filters: Filter[];
  search: string;
  searchPlaceholder?: string;
  onFilter: (filters: FilterResult[]) => void;
  onSearch: (search: string) => void;
}

export const SearchAndFilter: React.FC<Props> = ({
  filters,
  search,
  searchPlaceholder,
  onFilter,
  onSearch,
  'data-testid': testId,
}) => {
  const hasActiveFilters = some(filters, (a) => some(a.selected));
  const hasAnyFilter = some(filters, (a) => some(a.available));

  return (
    <div className="search-and-filter-component">
      <div className="search-filter-container">
        <div className="search-filter-grid">
          <SearchInput searchString={search} placeholder={searchPlaceholder} onSearch={onSearch} data-testid={testId} />
          {hasAnyFilter && <FilterListButton filters={filters} onFilter={onFilter} />}
        </div>
        {hasActiveFilters && <InlineActiveFilterList filters={filters} onFilter={onFilter} />}
      </div>
    </div>
  );
};
