import dayjs from 'dayjs';
import { SupplySource } from '../common/enums';
import { PanelSources } from './active-target-group/models/active-supply.model';
import { Iso8601Date } from '../helpers';

export class TargetGroupConstants {
  static get maximumBucketsAllowed() {
    return 1260;
  }

  static get increasedMaximumBucketsAllowed() {
    return 10000;
  }

  static get feasibilityThrottleInterval() {
    return 600;
  }

  static get profilingDepthThrottleInterval() {
    return 650;
  }

  static get searchThrottleInterval() {
    return 650;
  }

  static get maxLoiForFixedLoi() {
    return 100;
  }

  static get excludeProjectsFromDateDefaultValue(): Iso8601Date {
    return dayjs().add(-6, 'month').startOf('day').toISOString();
  }

  static get excludeProjectsToDateDefaultValue(): Iso8601Date {
    return dayjs().startOf('day').toISOString();
  }

  static get maximumExcludedProjectsAmount() {
    return 40;
  }

  static get validEmailRegex() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  static get validSupplierNameRegex() {
    return /^[\wåäöæøàâçéèêëîïôùûüešcržýáíÅÄÖÆØÀÂÇÉÈÊËÎÏÔÙÛÜEŠCRŽÝÁÍ\d\s_\-–.&#+(),:!?%£$€|/]{0,100}$/;
  }

  static get postalCodesRegionTypeId(): number {
    return -1;
  }

  static get eligiblePanelSources(): (keyof PanelSources)[] {
    return [SupplySource.CintPanels, SupplySource.OwnPanels, SupplySource.PrivatePricing];
  }
}
