import './target-group-selection-dialog.component.scss';
import { useEffect, useState } from 'react';
import { ui } from 'angular';
import { react2angular } from 'react2angular';
import { without, concat } from 'lodash-es';
import { feasibilityService } from '../../target-groups/feasibility.service';
import { ExistingTargetGroupSummary } from '../http-services/existing-project.models';
import { filters } from '../filters';
import { TargetGroupSelectionDialogResolve } from './target-group-selection-dialog-settings.factory';
import { ModalCloseButton } from './ModalCloseButton';

interface Props {
  resolve: TargetGroupSelectionDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
}

function getIncidenceRateFormat(ir: number): string {
  if (typeof ir !== 'number') return String(filters.percentFormat()(ir, true));
  return String(filters.percentFormat()(Number(filters.oneDecimalFormat()(ir)), true));
}

const renderTargetGroupRow = (
  onSelect: (id: number) => void,
  selectedIds: number[],
  targetGroup: ExistingTargetGroupSummary,
  feasibility: number | 'N/A'
) => {
  return (
    <tr key={targetGroup.id}>
      <td className="text-left">
        <input
          className="selection-box"
          type="checkbox"
          checked={selectedIds.includes(targetGroup.id)}
          onChange={(_) => onSelect(targetGroup.id)}
        />{' '}
        {targetGroup.name}
      </td>
      <td className="text-right">{filters.loiFormat()(targetGroup.actualLengthOfInterview)}</td>
      <td className="text-right">{getIncidenceRateFormat(targetGroup.actualIncidenceRate)}</td>
      <td className="text-right">
        <span>{targetGroup.actualNumberOfCompletes}</span>
        <span className="unimportant"> / {targetGroup.wantedNumberOfCompletes}</span>
      </td>
      <td className="text-right">
        {feasibility ? <span>{feasibility}</span> : <i className="fas fa-spinner fa-spin" />}
      </td>
    </tr>
  );
};

const TargetGroupSelectionDialog: React.FC<Props> = ({
  resolve: { targetGroups, projectName, projectId },
  modalInstance,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [feasibilityLoaded, setFeasibilityLoaded] = useState(false);
  const [infieldFeasibilityForAll, setInfieldFeasibilityForAll] = useState([]);

  useEffect(() => loadInfieldFeasibilityForAll(), []);

  const toggleId = (id: number) => {
    setSelectedIds((d) => (d.includes(id) ? without(d, id) : concat(d, id)));
  };
  const toggleAll = (toggle: boolean) => {
    setSelectedIds(toggle ? targetGroups.map((a) => a.id) : []);
  };

  const loadInfieldFeasibilityForAll = () => {
    if (!projectId) {
      setFeasibilityLoaded(true);
      return;
    }
    const feasPromises = targetGroups.map((tg) => feasibilityService.getInfieldFeasibility(projectId, tg.id));
    Promise.all(feasPromises).then((res) => {
      setInfieldFeasibilityForAll(
        res.map((r) => ({
          targetGroupId: r.data.targetGroupId,
          feasibility: r.data.feasibleNumberOfCompletes,
        }))
      );
      setFeasibilityLoaded(true);
    });
  };

  const getInfieldFeasibilityForTg = (targetGroup: ExistingTargetGroupSummary) => {
    if (!feasibilityLoaded) return undefined;
    return infieldFeasibilityForAll.find((item) => item.targetGroupId === targetGroup.id)?.feasibility || 'N/A';
  };

  const isAllSelected = targetGroups.map((a) => a.id).every((id) => selectedIds.includes(id));

  return (
    <div className="target-group-selection-dialog-component">
      <ModalCloseButton onClose={modalInstance.dismiss} />
      <div className="modal-container">
        <h1 className="modal-header">Replace target groups</h1>
        <h2 className="secondary-header">{projectName}</h2>
        <div className="description">
          The following sections will be updated in the replacement project
          <div>
            <strong>Basic settings</strong>: Number of wanted completes, Start date, Days in field
          </div>
          <div>
            <strong>Quotas</strong>: All
          </div>
          <div>
            <strong>Exclusions</strong>: Current Project
          </div>
          <br />
          The following sections will be copied into the replacement project
          <div>
            <strong>Regions, Audience, and Profiling</strong>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="text-left">
                <input
                  className="selection-box"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={(e) => toggleAll(e.target.checked)}
                />{' '}
                Target group name
              </th>
              <th className="text-right">LOI</th>
              <th className="text-right">IR</th>
              <th className="text-right">Completes</th>
              <th className="text-right">Feasible</th>
            </tr>
          </thead>
          <tbody>
            {targetGroups.map((tg) => renderTargetGroupRow(toggleId, selectedIds, tg, getInfieldFeasibilityForTg(tg)))}
          </tbody>
        </table>
        <div className="modal-controls">
          <button className="ui-btn default-btn" onClick={(_) => modalInstance.dismiss()}>
            Cancel
          </button>
          <button
            className="ui-btn primary-btn"
            disabled={selectedIds.length === 0}
            onClick={(_) => modalInstance.close(selectedIds)}
          >
            Replace target groups
          </button>
        </div>
      </div>
    </div>
  );
};

export const TargetGroupSelectionDialogComponentName = 'targetGroupSelectionDialogComponent';
export const ngTargetGroupSelectionDialogComponent = {
  [TargetGroupSelectionDialogComponentName]: react2angular(TargetGroupSelectionDialog, ['resolve', 'modalInstance']),
};
