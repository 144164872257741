import { targetGroupChannel } from '../../../channels/target-group-channel';
import { Census } from '../../models/active-target-group.model';
import { activeTargetGroupStore } from '../active-target-group.store';

export const commitCensus =
  () =>
  (censusState: Census): void => {
    activeTargetGroupStore.model.census = censusState;
    targetGroupChannel.model.census.updated.dispatch();
  };
