import { slice } from 'lodash-es';
import Pako from 'pako';

export function gzipData(input: any): string {
  const compressedData = Pako.gzip(JSON.stringify(input));
  const base64string = uint8ToBase64String(compressedData);
  return base64string;
}

export function unGzipData(input: string): any {
  const uint8Array = base64StringToUint8(input);
  const decompressedData = Pako.ungzip(uint8Array, { to: 'string' });
  return JSON.parse(decompressedData);
}

function uint8ToBase64String(u8a: Uint8Array): string {
  const chunkSize = 4096;
  const c = [];
  for (let i = 0; i < u8a.length; i += chunkSize) {
    const stringChunk = String.fromCharCode.apply(null, slice(u8a, i, i + chunkSize));
    c.push(stringChunk);
  }
  return btoa(c.join(''));
}

function base64StringToUint8(b64s: string): Uint8Array {
  const binaryString = window.atob(b64s);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
