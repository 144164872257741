import { isEmpty } from 'lodash';
import {
  InternalTargetGroupPrice,
  UpfrontPriceResponse,
  UpfrontTargetGroupPriceResponse,
} from '../common/http-services/pricing.httpservice';
import { Money } from '../common/pricing/price-models';

export class UpfrontPrice {
  price: Money;
  discount: Money;
  targetGroups: UpfrontTargetGroupPrice[];
  volumeDiscountCompletes: number;
  customCpiClamped?: boolean;
  internalPrice?: Money;
  cintPrice?: Money;

  constructor(priceResult: UpfrontPriceResponse) {
    this.discount = priceResult.discount !== undefined ? priceResult.discount : { value: 0, currency: '' };
    this.volumeDiscountCompletes = priceResult.volumeDiscountCompletes || 0;
    this.customCpiClamped = priceResult.customCpiClamped;

    if (isEmpty(priceResult.internalPrice)) {
      // do we really need to do this?
      this.price = priceResult.price !== undefined ? priceResult.price : { value: 0, currency: '' };
      this.targetGroups = priceResult.targetGroups || [];
    } else {
      this.price = {
        value: priceResult.internalPrice.priceTotal.value,
        currency: priceResult.price.currency,
      };
      this.cintPrice = priceResult.price;
      this.internalPrice = priceResult.internalPrice.price;

      this.targetGroups = (priceResult.targetGroups || []).map((tgPrice) =>
        this.populateInternalPrice(tgPrice, priceResult.internalPrice?.targetGroups)
      );
    }
  }

  private populateInternalPrice(
    tgPrice: UpfrontTargetGroupPriceResponse,
    internalPrices: InternalTargetGroupPrice[]
  ): UpfrontTargetGroupPrice {
    if (isEmpty(internalPrices)) return tgPrice;

    const internalTgPrice = internalPrices.find((p) => p.id === tgPrice.id);

    if (isEmpty(internalTgPrice)) return tgPrice;

    return {
      ...tgPrice,
      cintPrice: tgPrice.price,
      internalPrice: internalTgPrice?.price,
      price: { value: tgPrice.price.value + internalTgPrice.price.value, currency: tgPrice.price.currency },
      cintCpi: tgPrice.cpi,
      internalCpi: internalTgPrice?.cpi,
      cpi: { value: tgPrice.cpi.value + internalTgPrice.cpi.value, currency: tgPrice.cpi.currency },
      quotaPrices: tgPrice.quotaPrices,
    };
  }
}

export interface UpfrontQuotaPrice {
  id: number;
  cpi: Money;
  price: Money;
  canUseCustomCpi: boolean;
  hasCustomCpi: boolean;
  customCpiMin: number;
  customCpiMax: number;
}
export interface UpfrontTargetGroupPrice {
  id: number;
  hasRateCard: boolean;
  cpi: Money;
  price: Money;
  canUseCustomCpi: boolean;
  hasCustomCpi: boolean;
  customCpiMin: number;
  customCpiMax: number;
  hasVolumeDiscount: boolean;
  internalCpi?: Money;
  internalPrice?: Money;
  cintCpi?: Money;
  cintPrice?: Money;
  quotaPrices: UpfrontQuotaPrice[];
}

export interface ProjectPriceQuoteView {
  subtotal: { value: number | null; currency: string };
  volumeDiscount: { value: number | null; currency: string };
  totalPrice: { value: number | null; currency: string };
  cintPrice?: { value: number | null; currency: string };
  internalPrice?: { value: number | null; currency: string };
}
