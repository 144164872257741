import { maxBy, some } from 'lodash-es';
import escapeStringRegexp from 'escape-string-regexp';

export class TargetGroupNamer {
  updateName(candidateName: string, nameList: string[]) {
    if (!this.exists(candidateName, nameList)) return candidateName;

    const matches = /([-\w\s]*)\s+(\d+)$/.exec(candidateName);
    const baseName = matches === null ? candidateName : matches[1];
    const highestNumber = this.findHighestNumber(baseName, nameList);
    return `${baseName} ${highestNumber + 1}`;
  }

  private findHighestNumber(baseName: string, nameList: string[]): number {
    const pattern = new RegExp(`^${escapeStringRegexp(baseName)}\\s*(\\d+)$`);

    const max = maxBy(nameList, (name) => {
      const result = pattern.exec(name);
      return result !== null ? parseInt(result[1], 10) : null;
    });

    const match = pattern.exec(max);
    return match === null || match[1] === '' ? 0 : parseInt(match[1], 10);
  }

  private exists(candidateName: string, nameList: string[]): boolean {
    return some(nameList, (name) => name === candidateName);
  }
}

export const targetGroupNamer = new TargetGroupNamer();
