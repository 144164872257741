import './rate-card-dialog.component.scss';
import { ui } from 'angular';
import { findIndex, isEmpty } from 'lodash-es';
import { isEmptyGuid, isUndefinedOrNull } from '../../../helpers';
import { TargetGroupModel } from '../../models/target-group.model';
import { ExistingTargetGroup, ExistingTargetGroupSummary } from '../../http-services/existing-project.models';
import { PanelistPoolSource } from '../../enums';
import { api } from '../../api';
import { AgeSpan } from '../../../target-groups/active-target-group/models/active-target-group.model';

import template from './rate-card-dialog.component.html';
import { TargetGroupPrice } from '../price-models';
import { pricingHttpService } from '../../http-services/pricing.httpservice';
import { featureFlipper } from '../../feature-flipper';

export interface RateCardCell {
  value: string;
  cellType: 'label' | 'even' | 'odd';
}

export interface RateCardRow {
  cells: RateCardCell[];
}

export interface RateCard {
  currencyCode: string;
  isPrivate: boolean;
  rows: RateCardRow[];
}

const selector = 'rateCardDialogComponent';

export interface RateCardDialogResolve {
  targetGroup: TargetGroupModel | ExistingTargetGroupSummary;
  targetGroupPrice: TargetGroupPrice | null;
  projectId?: number;
  totalCompletes: number;
  currentCpi: number;
  isInternalRateCard: boolean;
  displayCurrencyCode: string;
}

interface Bindings {
  resolve: '<' | RateCardDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class RateCardDialogComponent implements Bindings {
  static componentName = selector;

  resolve: RateCardDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  targetGroupId: number;
  targetGroup: TargetGroupModel | ExistingTargetGroup; // hmm?
  targetGroupPrice: TargetGroupPrice | null;
  projectId?: number;
  totalCompletes: number;
  rateCard: RateCard;
  numberOfCompletes: number;
  countryName: string;
  gender: string;
  ageRange: AgeSpan;
  estimatedLoi: number;
  estimatedIncidenceRate: number;
  startDate: string;
  showActualValues: boolean;
  effectiveLoi: number;
  effectiveIncidenceRate: number;
  currentCpi: number;
  isInternalRateCard: boolean;

  $onInit() {
    this.initProperties();
  }

  initProperties(): void {
    if (this.resolve.projectId) {
      this.projectId = this.resolve.projectId;
      this.targetGroup = this.resolve.targetGroup as ExistingTargetGroup;

      this.totalCompletes = this.resolve.totalCompletes;
      this.numberOfCompletes = this.targetGroup.wantedNumberOfCompletes;
      this.countryName = this.targetGroup.country;
      this.gender = this.targetGroup.gender;
      this.ageRange = { from: this.targetGroup.minAge, to: this.targetGroup.maxAge };
      this.estimatedLoi = this.targetGroup.estimatedLengthOfInterview;
      this.estimatedIncidenceRate = this.targetGroup.estimatedIncidenceRate;
      this.startDate = this.targetGroup.startDate;

      if (this.resolve.targetGroupPrice) {
        this.showActualValues = true;
        this.targetGroupPrice = this.resolve.targetGroupPrice;
        this.effectiveLoi = this.targetGroupPrice?.current.effectiveLengthOfInterview;
        this.currentCpi = this.targetGroupPrice?.current.cpi.value;
        this.effectiveIncidenceRate = this.targetGroupPrice?.current.effectiveIncidenceRate;
      } else {
        pricingHttpService.getProjectPrice(this.projectId).then((res) => {
          this.targetGroupPrice = res.data.targetGroupPrices.find((tgp) => tgp.targetGroupId === this.targetGroup.id);
          this.showActualValues = true;
          this.effectiveLoi = this.targetGroupPrice?.current.effectiveLengthOfInterview;
          this.effectiveIncidenceRate = this.targetGroupPrice?.current.effectiveIncidenceRate;
          this.currentCpi = this.targetGroupPrice?.current.cpi.value;
          this.isInternalRateCard = !isEmpty(res.data.internalPrice);
        });
      }

      this.getExistingTargetGroupRateCard(this.targetGroup.id).then((res) => {
        this.rateCard = res.data;
      });
    } else {
      this.totalCompletes = this.resolve.totalCompletes;
      this.targetGroup = this.resolve.targetGroup as TargetGroupModel;
      const { basicSettings } = this.targetGroup;
      this.numberOfCompletes = basicSettings.numberOfCompletes;
      this.countryName = basicSettings.countryName;
      this.gender = basicSettings.gender;
      this.ageRange = { from: basicSettings.minAge, to: basicSettings.maxAge };
      this.estimatedLoi = basicSettings.estimatedLengthOfInterview;
      this.estimatedIncidenceRate = basicSettings.estimatedIncidenceRate;
      this.startDate = basicSettings.startDate;
      this.showActualValues = false;
      this.currentCpi = this.resolve.currentCpi / 100;
      this.isInternalRateCard = this.resolve.isInternalRateCard;

      this.getRateCard(basicSettings.countryId, this.totalCompletes, this.targetGroup.panels.selectedIds).then(
        (res) => {
          this.rateCard = res.data;
        }
      );
    }
  }

  shouldHighlightCell(cpiValue: string, loiRange: string, irIndex: number): boolean {
    const isCpiMatch = this.currentCpi === parseFloat(cpiValue);

    if (isCpiMatch) {
      return this.isLoiMatch(loiRange) && this.isIrMatch(irIndex);
    }
    return false;
  }

  dismiss(): void {
    this.modalInstance.close();
  }

  private getRateCard(countryId: number, totalCompletes: number, panelIds: number[]) {
    const request = {
      countryId,
      numberOfCompletes: totalCompletes,
      panelIds,
      panelistPoolSource: isEmptyGuid((this.targetGroup as TargetGroupModel).panels.panelistPool.selectedPoolId)
        ? PanelistPoolSource.None
        : (this.targetGroup as TargetGroupModel).panels.panelistPool.source,
      displayCurrencyCode: this.resolve.displayCurrencyCode,
    };
    if (this.isInternalRateCard && featureFlipper.isEnabled('internalPricing')) {
      return api.pricing.getInternalRateCard(request);
    }
    return api.pricing.getRateCard(request);
  }

  private getExistingTargetGroupRateCard(targetGroupId: number) {
    const request = {
      targetGroupId,
      numberOfCompletes: this.totalCompletes,
      displayCurrencyCode: this.resolve.displayCurrencyCode,
    };

    return api.pricing.getTargetGroupRateCard(request);
  }

  private isLoiMatch(loiRange: string): boolean {
    const tgLoi = !isUndefinedOrNull(this.projectId) ? this.effectiveLoi : this.estimatedLoi;
    const parts = loiRange.split('-');
    const minLoi = parseInt(parts[0], 10);
    const maxLoi = parts.length > 1 ? parseInt(parts[1], 10) : minLoi;

    return tgLoi >= minLoi && tgLoi <= maxLoi;
  }

  private isIrMatch(irIndex: number): boolean {
    const isInRange = (irRange: string): boolean => {
      const tgIr = !isUndefinedOrNull(this.projectId) ? this.effectiveIncidenceRate : this.estimatedIncidenceRate;

      if (isEmpty(irRange)) return false;

      const parts = irRange.replace('%', '').split('-');
      const maxIr = parseInt(parts[0], 10);
      const minIr = parts.length > 1 ? parseInt(parts[1], 10) : maxIr;

      return tgIr <= maxIr && tgIr >= minIr;
    };

    const irColumns: any[] = this.rateCard.rows[0].cells;
    const index = findIndex(irColumns, (c) => isInRange(c.value));

    if (index === -1) return true;

    return irIndex === index;
  }
}

export const ngRateCardDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: RateCardDialogComponent,
  },
};
