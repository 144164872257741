import './initial-value-placeholder.directive.scss';
import { IAttributes, IAugmentedJQuery, INgModelController, IScope } from 'angular';
import { $timeout } from '../../ngimport';
import { DirectiveUsingNgModelController } from './directive.types';

const directiveName = 'initialValuePlaceholder';

export function initialValuePlaceholder(): DirectiveUsingNgModelController {
  const placeHolderClassName = 'textarea-placeholder';

  return {
    restrict: 'A',
    require: 'ngModel',
    priority: 99,
    link: (scope: IScope, elm: IAugmentedJQuery, attrs: IAttributes, ngModelCtrl: INgModelController) => {
      if (attrs.type === 'radio' || attrs.type === 'checkbox') return;

      const placeholder = scope.$eval(attrs.initialValuePlaceholder);

      // TODO: see if this fix still works when reusing
      if (!ngModelCtrl.$viewValue) {
        ngModelCtrl.$setViewValue(placeholder);
        ngModelCtrl.$render();
      }

      $timeout(() => {
        if (ngModelCtrl.$viewValue === placeholder) {
          elm.addClass(placeHolderClassName);
        }
      }, 0);

      elm.bind('focus', () => {
        if (ngModelCtrl.$viewValue === placeholder) {
          ngModelCtrl.$setViewValue('');
          elm.removeClass(placeHolderClassName);
          ngModelCtrl.$render();
        }
      });

      elm.bind('blur', () => {
        if (ngModelCtrl.$viewValue.length === 0) {
          ngModelCtrl.$setViewValue(placeholder);
          elm.addClass(placeHolderClassName);
          ngModelCtrl.$render();
        }
      });
    },
  };
}

export const ngInitialValuePlaceholderDirective = {
  [directiveName]: initialValuePlaceholder,
};
