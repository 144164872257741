import './immer-patches-item.component.scss';
import { Patch } from 'immer';
import { IOnChangesObject } from 'angular';
import { html } from '../helpers';

const selector = 'immerPatchesItemComponent';

const template = html`
  <div class="immer-patches-item-component">
    <div class="patches-item" ng-if="!$ctrl.inverse" ng-repeat="patches in $ctrl.patches track by $index">
      <span class="patches-number">{{ $index + 1 }}</span>
      <div class="patch-item" ng-repeat="patch in patches track by $index">
        <span class="pull-right">[{{ $index }}]</span>
        <div class="row patch-row">
          <span class="col-xs-12">{{$ctrl.getPathString(patch.path)}}</span>
        </div>
        <div class="row patch-row">
          <span
            class="col-xs-12"
            ng-class="{
              'add': patch.op === 'add',
              'remove': patch.op === 'remove',
              'replace': patch.op === 'replace'
            }"
            >{{patch.op}}
          </span>
        </div>
        <div class="row patch-row">
          <span class="col-xs-12">
            <json-formatter json="patch.value" open="2"></json-formatter>
          </span>
        </div>
      </div>
    </div>
  </div>
`;

interface Bindings {
  patches: '<' | Patch[][];
}

const bindings: Bindings = {
  patches: '<',
};

export class ImmerPatchesItemComponent implements Bindings {
  static componentName = selector;
  patches: Patch[][];

  $onChanges(changes: IOnChangesObject): void {
    if (changes.patches) {
      this.patches = changes.patches.currentValue;
    }
  }

  getPathString(path: string[]): string {
    return path.join(' -> ');
  }
}

export const ngImmerPatchesItemComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ImmerPatchesItemComponent,
  },
};
