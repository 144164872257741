import { Literal, Null, Record, Static, Union } from 'runtypes';
import { Array } from 'runtypes/lib/types/array';
import { TargetGender } from '../enums';
import { AboveZero } from '../../custom-runtypes';

export const BucketKeyResponse = Record({
  gender: Union(Literal(TargetGender.Male), Literal(TargetGender.Female)).Or(Null),
  ageSpan: Record({
    from: AboveZero,
    to: AboveZero,
  }).Or(Null),
  region: Array(AboveZero).Or(Null),
  supply: Record({
    panels: Array(AboveZero).Or(Null),
  }),
  profiling: Array(AboveZero),
});
export type BucketKeyResponse = Static<typeof BucketKeyResponse>;
