import './custom-dropdown.component.scss';
import { isEqual } from 'lodash-es';
import { $timeout } from '../../../ngimport';
import { html } from '../../../helpers';

export interface CustomDropdownOption {
  value: any;
  name: string;
  icon?: string;
}

interface Bindings {
  options: '<' | CustomDropdownOption[];
  defaultSelection: '<' | any;
  onOptionSelect: '<' | ((_: { option: CustomDropdownOption }) => void);
  disabled: '<' | boolean | undefined;
}

const bindings: Bindings = {
  options: '<',
  defaultSelection: '<',
  onOptionSelect: '<',
  disabled: '<',
};

const selector = 'customDropdownComponent';

const template = html`
  <div class="custom-dropdown">
    <input class="input" ng-value="$ctrl.selectedOption" focus-on="$ctrl.focused" />
    <p
      data-testid="custom-dropdown"
      class="toggle ui-control"
      ng-click="$ctrl.toggleList()"
      on-click-outside="$ctrl.hideList()"
      ng-class="{'toggle_selected': $ctrl.defaultSelection || $ctrl.model, 'disabled': $ctrl.disabled}"
    >
      {{$ctrl.getDisplayText()}}
      <span
        class="arrow rounded-arrow_secondary fas fa-angle-down"
        ng-class="{'rounded-arrow_up': $ctrl.isExpanded}"
      ></span>
    </p>
    <div class="list-wrap" ng-class="{'list_expanded': $ctrl.isExpanded}">
      <div class="list">
        <p
          data-testid="custom-dropdown-option"
          class="option"
          ng-repeat="option in $ctrl.options track by option.name"
          ng-click="$ctrl.selectOption(option)"
        >
          {{option.name}}
        </p>
      </div>
    </div>
  </div>
`;

export class CustomDropdownComponent implements Bindings {
  static componentName = selector;

  options: CustomDropdownOption[];
  defaultSelection: any;
  isExpanded: boolean;
  onOptionSelect: (_: { option: CustomDropdownOption }) => void;
  focused = false;
  defaultOption: CustomDropdownOption;
  disabled: boolean;
  selectedOption: any;

  $onInit() {
    if (this.defaultSelection !== undefined) {
      this.defaultOption = this.options.find((el) => isEqual(el.value, this.defaultSelection));
      if (this.defaultOption === undefined) return;
      this.selectOption(this.defaultOption);
    }
  }

  selectOption(option: CustomDropdownOption): void {
    this.focused = false;
    this.selectedOption = option.value;
    this.onOptionSelect({ option });
    $timeout(() => {
      this.focused = true;
    }, 0);
  }

  toggleList() {
    this.isExpanded = !this.isExpanded;
    this.focused = !this.focused;
  }

  hideList() {
    this.isExpanded = false;
  }

  getDisplayText() {
    const option = this.options.find((el) => el.value === this.selectedOption) || this.defaultOption;
    return option ? option.name : 'Select';
  }
}

export const ngCustomDropdownComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: CustomDropdownComponent,
  },
};
