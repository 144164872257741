import { IDirective } from 'angular';
import { featureFlipper } from '../feature-flipper';

const directiveName = 'featureOn';

export function featureOn(): IDirective {
  return {
    link: (_scope, element, attrs) => {
      const featureId = attrs[directiveName];

      if (!featureFlipper.isEnabled(featureId)) {
        element.remove();
      }
    },
  };
}

export const ngFeatureOnDirective = {
  [directiveName]: featureOn,
};
