import { html } from '../../helpers';

const selector = 'bsPopoverComponent';

const template = html`
  <div
    data-title="{{$ctrl.popoverTitle}}"
    data-placement="{{$ctrl.placement}}"
    html="{{$ctrl.html}}"
    data-content="{{$ctrl.content}}"
    data-trigger="{{$ctrl.trigger}}"
    class="{{$ctrl.className}}"
    bs-popover
  >
    &nbsp;{{$ctrl.popoverTitle}} <i class="fas fa-info-circle"></i>
  </div>
`;

interface Bindings {
  html: '<' | boolean;
  popoverTitle: '<' | string;
  placement: '<' | string;
  content: '<' | string;
  trigger: '<' | string;
  className: '<' | string;
}

const bindings: Bindings = {
  html: '<',
  popoverTitle: '<',
  placement: '<',
  content: '<',
  trigger: '<',
  className: '<',
};

class BsPopoverComponent implements Bindings {
  static componentName = selector;
  html: boolean;
  popoverTitle: string;
  content: string;
  trigger: string;
  placement: string;
  className: string;
}

export const ngBsPopoverComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: BsPopoverComponent,
  },
};
