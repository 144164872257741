import './stick-to-top.directive.scss';
import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { toNumber } from 'lodash-es';
import { $document, $timeout, $window } from '../../ngimport';

const directiveName = 'stickToTop';

export function stickToTopDirective(): IDirective {
  return {
    restrict: 'A',
    link: (scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      let scrollingUp: boolean;
      let elemHeight: number;
      let sticked: boolean;
      let elemWidth: number;
      let lastScrollTop = 0;
      let initialTop: number;
      let scrollUpPosition: number;
      let scrollDownPosition: number;
      let placeHolder: HTMLElement;
      let elemStyleWidth: string;
      let elemStyleTop: string;

      $timeout(init, 0);

      function init() {
        const rect = elem[0].getBoundingClientRect();
        initialTop = rect.top;
        elemHeight = rect.height;
        elemWidth = rect.width;
        elemStyleWidth = elem[0].style.width;
        elemStyleTop = elem[0].style.top;
        scrollUpPosition = toNumber(attrs.scrollUpPosition) || 0;
        scrollDownPosition = toNumber(attrs.scrollDownPosition) || 0;
        addPlaceholder();
      }

      function addPlaceholder() {
        placeHolder = $document[0].createElement('div');
        placeHolder.classList.add('stick-to-top-placeholder');
        placeHolder.style.height = `${elemHeight}px`;
        elem[0].parentElement.insertBefore(placeHolder, elem[0]);
      }

      $document[0].addEventListener('scroll', trackScroll);

      function trackScroll() {
        if ($window.scrollY < lastScrollTop && !scrollingUp) {
          scrollingUp = true;
          if (sticked) {
            elem[0].style.top = `${scrollUpPosition}px`;
          }
        }
        if ($window.scrollY > lastScrollTop && (scrollingUp || scrollingUp === undefined)) {
          scrollingUp = false;
          if (sticked) {
            elem[0].style.top = `${scrollDownPosition}px`;
          }
        }
        lastScrollTop = $window.scrollY;
        if ($window.scrollY > initialTop - scrollDownPosition && !sticked) {
          sticked = true;
          elemWidth = elem[0].getBoundingClientRect().width;
          elem[0].classList.add('is-sticked');
          placeHolder.classList.add('stick-to-top-placeholder_active');
          elem[0].style.top = `${scrollDownPosition}px`;
          elem[0].style.width = `${elemWidth}px`;
        }
        if (sticked && $window.scrollY + scrollUpPosition <= initialTop) {
          sticked = false;
          elem[0].classList.remove('is-sticked');
          placeHolder.classList.remove('stick-to-top-placeholder_active');
          elem[0].style.top = elemStyleTop;
          elem[0].style.width = elemStyleWidth;
        }
      }

      scope.$on('$destroy', () => {
        $document[0].body.removeEventListener('scroll', trackScroll);
      });
    },
  };
}

export const ngStickToTopDirective = {
  [directiveName]: stickToTopDirective,
};
