import { toJson } from 'angular';
import { urlService } from './http-services/url.service';

export type LogLevel = 'verbose' | 'debug' | 'information' | 'warning' | 'error' | 'fatal';

export class ErrorLogger {
  // Serilog format with message template and positional parameters:
  // verbose("user {UserId} performed action {Action}", user.id, action)
  verbose(messageTemplate: string, ...parameters: any[]) {
    this.log('verbose', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // verboseWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  verboseWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('verbose', messageTemplate, parameters, additionalData);
  }

  // Serilog format with message template and positional parameters:
  // debug("user {UserId} performed action {Action}", user.id, action)
  debug(messageTemplate: string, ...parameters: any[]) {
    this.log('debug', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // debugWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  debugWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('debug', messageTemplate, parameters, additionalData);
  }

  // Serilog format with message template and positional parameters:
  // information("user {UserId} performed action {Action}", user.id, action)
  information(messageTemplate: string, ...parameters: any[]) {
    this.log('information', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // informationWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  informationWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('information', messageTemplate, parameters, additionalData);
  }

  // Serilog format with message template and positional parameters:
  // warning("user {UserId} performed action {Action}", user.id, action)
  warning(messageTemplate: string, ...parameters: any[]) {
    this.log('warning', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // warningWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  warningWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('warning', messageTemplate, parameters, additionalData);
  }

  // Serilog format with message template and positional parameters:
  // error("user {UserId} performed action {Action}", user.id, action)
  error(messageTemplate: string, ...parameters: any[]) {
    this.log('error', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // errorWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  errorWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('error', messageTemplate, parameters, additionalData);
  }

  // Serilog format with message template and positional parameters:
  // fatal("user {UserId} performed action {Action}", user.id, action)
  fatal(messageTemplate: string, ...parameters: any[]) {
    this.log('fatal', messageTemplate, parameters);
  }

  // Serilog format with message template, positional params as array, and addl. JSON data to add to the context:
  // fatalWithData("user {UserId} performed action {Action}", [user.id, action], { company: user.company, ... })
  fatalWithData(messageTemplate: string, parameters: any[], additionalData: { [p: string]: any }): void {
    this.logWithProperties('fatal', messageTemplate, parameters, additionalData);
  }

  log(level: LogLevel, messageTemplate: string, parameters: any[]): void {
    this.postRequest(level, messageTemplate, parameters);
  }

  private logWithProperties(
    level: LogLevel,
    messageTemplate: string,
    parameters: any[],
    additionalData: { [p: string]: any }
  ): void {
    this.postRequest(level, messageTemplate, parameters, additionalData);
  }

  private postRequest(
    level: LogLevel,
    messageTemplate: string,
    parameters: object[],
    additionalData?: { [p: string]: any }
  ) {
    const logEvent = {
      messageTemplate,
      parameters,
      additionalData: toJson(additionalData),
    };

    const xhr = new XMLHttpRequest();
    xhr.open('POST', urlService.clientLogger(level), true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.withCredentials = true;
    xhr.send(toJson(logEvent));
  }
}

export const errorLogger = new ErrorLogger();
