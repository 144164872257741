export interface ProjectCategory {
  id: number;
  name: string;
  onlyShowForManagedServices: boolean;
  isSelected?: boolean;
}

export class ProjectCategoryService {
  categories: ProjectCategory[];

  persist(categories: ProjectCategory[]): void {
    this.categories = categories;
  }
}

export const projectCategoryService = new ProjectCategoryService();
