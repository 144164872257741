import { IDirective } from 'angular';

const directiveName = 'spinWhileLoadingFancy';

export function spinWhileLoadingFancy(): IDirective {
  return {
    transclude: true,
    restrict: 'E',
    scope: {
      condition: '=',
    },
    template: `
            <span ng-hide="condition"><i class="fas fa-spin fa-spinner" style="color: #444;"></i></span>
            <span ng-show="condition" ng-transclude></span>
        `,
  };
}

export const ngSpinWhileLoadingFancyDirective = {
  [directiveName]: spinWhileLoadingFancy,
};
