import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { $timeout } from '../../ngimport';

const directiveName = 'focusOn';

export function focusOn(): IDirective {
  return {
    restrict: 'A',
    link: (scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      scope.$watch(attrs[directiveName], () => {
        const isFocused = scope.$eval(attrs[directiveName]);
        if (isFocused) {
          $timeout(() => {
            elem[0].focus();
            (elem as any)[0].select();
          }, 0);
        }
      });
    },
  };
}

export const ngFocusOnDirective = {
  [directiveName]: focusOn,
};
