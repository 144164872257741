import { TargetGroupValidationResult } from '../common/http-services/draft.httpservice';

import { targetGroupChannel } from './channels/target-group-channel';
import { activeTargetGroupStore } from './active-target-group/store/active-target-group.store';

export class DeprecationService {
  acknowledgeRegion(): void {
    activeTargetGroupStore.model.deprecation.deprecatedRegions = [];
    activeTargetGroupStore.model.deprecation.hasDeprecatedPostalCodes = false;
    activeTargetGroupStore.model.deprecation.embargoedRegions = [];
    activeTargetGroupStore.model.deprecation.hasEmbargoedPostalCodes = false;
    targetGroupChannel.model.regions.updated.dispatch();
    this.notifyDiscard();
  }

  acknowledgeProfiling(): void {
    activeTargetGroupStore.model.deprecation.hasDeprecatedProfiling = false;
    targetGroupChannel.model.profiling.updated.dispatch();
    this.notifyDiscard();
  }

  acknowledgeSupply(): void {
    activeTargetGroupStore.model.deprecation.unavailablePanels = [];
    activeTargetGroupStore.model.deprecation.visiblePanels = [];
    activeTargetGroupStore.model.deprecation.hasPanelsFromMultipleSupplySources = false;
    activeTargetGroupStore.model.deprecation.hasDeprecatedAdHocSupplier = false;
    activeTargetGroupStore.model.deprecation.hasIncompatiblePanels = false;
    activeTargetGroupStore.model.deprecation.hasDeprecatedPanelistPoolSource = false;
    activeTargetGroupStore.model.deprecation.hasInclusivePanelistPoolFromApi = false;
    targetGroupChannel.model.supply.updated.dispatch();
    this.notifyDiscard();
  }

  acknowledgeQuotas(): void {
    activeTargetGroupStore.model.deprecation.hasDeprecatedQuotas = false;
    targetGroupChannel.model.quotas.updated.dispatch();
    this.notifyDiscard();
  }

  acknowledgeExcludeProjects(): void {
    activeTargetGroupStore.model.deprecation.hasDeprecatedExcludedProjects = false;
    activeTargetGroupStore.model.deprecation.hasExclusivePanelistPoolFromApi = false;
    targetGroupChannel.model.excludeProjects.updated.dispatch();
    this.notifyDiscard();
  }

  acknowledgeDeletedProjects(): void {
    activeTargetGroupStore.model.deprecation.hasDeletedExcludedProjects = false;
    targetGroupChannel.model.excludeProjects.updated.dispatch();
    this.notifyDiscard();
  }

  hasAnyDeprecation(data: TargetGroupValidationResult): boolean {
    data = data || activeTargetGroupStore.model.deprecation;
    return (
      this.hasRegions(data) ||
      this.hasProfiling(data) ||
      this.hasQuotas(data) ||
      this.hasPanels(data) ||
      this.hasAdHoc(data) ||
      this.hasIncompatiblePanels(data) ||
      this.hasExcludeProjects(data) ||
      this.hasDeletedExcludedProjects(data) ||
      this.hasPanelistPoolSource(data) ||
      this.hasInclusivePanelistPoolFromApi(data) ||
      this.hasExclusivePanelistPoolFromApi(data)
    );
  }

  hasRegions(data: TargetGroupValidationResult): boolean {
    return data.deprecatedRegions.length > 0 || data.hasDeprecatedPostalCodes;
  }

  hasProfiling(data: TargetGroupValidationResult): boolean {
    return data.hasDeprecatedProfiling;
  }

  hasQuotas(data: TargetGroupValidationResult): boolean {
    return data.hasDeprecatedQuotas;
  }

  hasPanels(data: TargetGroupValidationResult): boolean {
    return (data.unavailablePanels && data.unavailablePanels.length > 0) || data.hasPanelsFromMultipleSupplySources;
  }

  hasAdHoc(data: TargetGroupValidationResult): boolean {
    return data.hasDeprecatedAdHocSupplier;
  }

  hasIncompatiblePanels(data: TargetGroupValidationResult): boolean {
    return data.hasIncompatiblePanels;
  }

  hasExcludeProjects(data: TargetGroupValidationResult): boolean {
    return data.hasDeprecatedExcludedProjects;
  }

  hasPanelistPoolSource(data: TargetGroupValidationResult): boolean {
    return data.hasDeprecatedPanelistPoolSource;
  }

  hasInclusivePanelistPoolFromApi(data: TargetGroupValidationResult): boolean {
    return data.hasInclusivePanelistPoolFromApi;
  }

  hasExclusivePanelistPoolFromApi(data: TargetGroupValidationResult): boolean {
    return data.hasExclusivePanelistPoolFromApi;
  }

  hasDeletedExcludedProjects(data: TargetGroupValidationResult): boolean {
    return data.hasDeletedExcludedProjects;
  }

  hasEmbargoedRegions(data: TargetGroupValidationResult): boolean {
    return data.embargoedRegions.length > 0 || data.hasEmbargoedPostalCodes;
  }

  private notifyDiscard(): void {
    const hasAnyDeprecation = this.hasAnyDeprecation(activeTargetGroupStore.model.deprecation);
    targetGroupChannel.model.deprecation.updated.dispatch({
      deprecationModel: activeTargetGroupStore.model.deprecation,
      hasAnyDeprecation,
    });
  }
}

export const deprecationService = new DeprecationService();
