import { IAttributes, IAugmentedJQuery, IScope, INgModelController } from 'angular';
import { DirectiveUsingNgModelController } from './directive.types';

type DirectiveScope = IScope & { func?: Function };

const directiveName = 'runOnBlurIfValueCleared';

export function runOnBlurIfValueCleared(): DirectiveUsingNgModelController {
  return {
    restrict: 'A',
    require: 'ngModel',
    priority: 99,
    scope: {
      func: '&runOnBlurIfValueCleared',
    },
    link: (scope: DirectiveScope, elm: IAugmentedJQuery, attrs: IAttributes, ngModelCtrl: INgModelController) => {
      if (attrs.type === 'radio' || attrs.type === 'checkbox') return;

      elm.bind('blur', () => {
        if (elm.val() === undefined || elm.val().trim().length === 0) {
          scope.$apply(() => {
            if (scope.func) {
              scope.func();
            }
            ngModelCtrl.$render();
          });
        }
      });
    },
  };
}

export const ngRunOnBlurIfValueClearedDirective = {
  [directiveName]: runOnBlurIfValueCleared,
};
