import { toJson } from 'angular';
import { isEmpty } from 'lodash';
import { ProjectUserFilter, ProjectChannel, ProjectStatus, DeviationFilter } from '../common/enums';
import { StorageFactory } from '../common/storage.factory';
import { userFilterStorageKey } from '../project-list/project-filters/project-filters.component';
import { userService } from '../common/user.service';
import { projectListsChannel } from './project-lists-channel';
import { ProjectGroup } from './groups/ProjectGroups';

export interface CountryFilter {
  id: number;
  name: string;
}

export interface ProjectListsFilterSettings {
  channels: ProjectChannel[];
  status: ProjectStatus[];
  countries: string[];
  projectGroups: string[];
  deviationFilters: DeviationFilter[];
  searchString: string;
  projectGroupSearchString: string;
}

export const activeProjectGroupStorageKey = 'activeProjectGroup';

const defaultSettings = {
  searchString: '',
  projectGroupSearchString: '',
  channels: [] as ProjectChannel[],
  status: [] as ProjectStatus[],
  countries: [] as string[],
  projectGroups: [] as string[],
  deviationFilters: [] as DeviationFilter[],
};

export const isDefaultSettings = (settings: ProjectListsFilterSettings): boolean => {
  return (
    isEmpty(settings.channels) &&
    isEmpty(settings.searchString) &&
    settings.status.length === 0 &&
    settings.countries.length === 0 &&
    settings.deviationFilters.length === 0 &&
    settings.projectGroups.length === 0
  );
};

export class ProjectListsSettingsRepository {
  private storage: Storage;
  private storageKey = 'projectListsFilterSettings';
  private projectListsFilterSettings: ProjectListsFilterSettings;

  get settings(): Readonly<ProjectListsFilterSettings> {
    return this.projectListsFilterSettings;
  }

  get scope(): Readonly<ProjectUserFilter> {
    return this.getScope();
  }

  get activeProjectGroup(): Readonly<ProjectGroup> {
    return JSON.parse(this.storage.getItem(activeProjectGroupStorageKey));
  }

  constructor() {
    this.storage = StorageFactory.getLocalStorage();
    this.saveSettings(defaultSettings);
  }

  setActiveProjectGroup(projectGroup: ProjectGroup) {
    const currentlyActive = JSON.parse(this.storage.getItem(activeProjectGroupStorageKey)) as ProjectGroup;

    if (currentlyActive?.id !== projectGroup.id) {
      this.storage.setItem(activeProjectGroupStorageKey, JSON.stringify(projectGroup));
      projectListsChannel.activeProjectGroupChanged.dispatch(projectGroup);
    }
  }

  clearActiveProjectGroup() {
    this.storage.removeItem(activeProjectGroupStorageKey);
    projectListsChannel.activeProjectGroupChanged.dispatch(null);
  }

  saveScope(scope: ProjectUserFilter) {
    if (this.storage.getItem(userFilterStorageKey) !== scope) {
      this.storage.setItem(userFilterStorageKey, scope);
      projectListsChannel.scopeChanged.dispatch(scope);
    }
  }

  saveSettings(settings: ProjectListsFilterSettings) {
    this.projectListsFilterSettings = settings;
    this.storage.setItem(
      this.storageKey,
      toJson({
        channels: settings.channels,
        searchString: settings.searchString,
        countries: settings.countries,
        status: settings.status,
        deviationFilters: settings.deviationFilters,
      })
    );
    projectListsChannel.filtersApplied.dispatch(settings);
  }

  private getScope = () => {
    return userService.showCompanyProjects
      ? ((this.storage.getItem(userFilterStorageKey) || ProjectUserFilter.User) as ProjectUserFilter)
      : ProjectUserFilter.CreatedBy;
  };
}

export const projectListsSettingsRepository = new ProjectListsSettingsRepository();
