import './on-off-switch.component.scss';
import { IOnChangesObject } from 'angular';
import { html } from '../../../helpers';

const selector = 'onOffSwitchComponent';

const template = html`
  <div
    class="on-off-switch-component"
    ng-class="{ 'on-off-text': $ctrl.switchType === 'onoff', 'yes-no-text': $ctrl.switchType === 'yesno'}"
  >
    <div class="onoffswitch">
      <input
        type="checkbox"
        class="onoffswitch-checkbox"
        ng-if="!$ctrl.useTwoWayBinding()"
        id="{{ $ctrl.switchId }}"
        ng-model="$ctrl.checked"
        ng-change="$ctrl.switchToggled()"
        ng-disabled="$ctrl.disabled"
      />
      <input
        type="checkbox"
        class="onoffswitch-checkbox"
        ng-if="$ctrl.useTwoWayBinding()"
        id="{{ $ctrl.switchId }}"
        ng-model="$ctrl.model"
        ng-change="$ctrl.switchToggled()"
        ng-disabled="$ctrl.disabled"
      />
      <label class="onoffswitch-label" for="{{ $ctrl.switchId }}" data-testid="onoffswitch-label">
        <span class="onoffswitch-inner"></span>
        <span class="onoffswitch-switch"></span>
      </label>
    </div>
  </div>
`;

interface Bindings {
  checked: '<' | boolean;
  model: '=' | boolean;
  switchId: '@' | string;
  switchType: '@' | 'onoff' | 'yesno';
  disabled: '<' | boolean;
  onToggle: '&' | (() => void);
}

const bindings: Bindings = {
  checked: '<',
  model: '=',
  switchId: '@',
  switchType: '@',
  disabled: '<',
  onToggle: '&',
};

export class OnOffSwitchComponent implements Bindings {
  static componentName = selector;

  checked: boolean;
  model: boolean;
  switchId: string;
  switchType: '@' | 'yesno' | 'onoff';
  disabled: boolean;
  isOnOffSwitch: boolean;
  onToggle: () => void;

  $onInit() {
    this.isOnOffSwitch = this.switchType === 'onoff';
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.checked) {
      this.checked = changes.checked.currentValue;
    }

    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }

  switchToggled(): void {
    this.onToggle();
  }

  useTwoWayBinding(): boolean {
    return this.model !== undefined;
  }
}

export const ngOnOffSwitchComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: OnOffSwitchComponent,
  },
};
