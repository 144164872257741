import { Record, Static, Array, Union, Literal, String, Null, Boolean, Number } from 'runtypes';
import { AboveZero, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { TargetGender } from '../enums';

export const AgeCensusQuota = Record({
  from: AboveZero,
  to: AboveZero,
  percentage: NonNegative,
});

export const AgeCensus = Record({
  quotas: Array(AgeCensusQuota).Or(Null),
  source: String.Or(Null),
  isCompanyCensus: Boolean,
  presetId: Number,
});

export type AgeCensus = Static<typeof AgeCensus>;

export const GenderCensusQuota = Record({
  name: Union(Literal(TargetGender.Male), Literal(TargetGender.Female)),
  percentage: AboveZero,
});

export const GenderCensus = Record({
  quotas: Array(GenderCensusQuota).Or(Null),
  source: String.Or(Null),
  isCompanyCensus: Boolean,
  presetId: Number,
});

export type GenderCensus = Static<typeof GenderCensus>;

export const RegionCensusQuota = Record({
  regionIds: Array(AboveZero),
  percentage: NonNegative,
});

export const RegionCensus = Record({
  quotas: Array(RegionCensusQuota).Or(Null),
  source: String.Or(Null),
  isCompanyCensus: Boolean,
  presetId: Number,
});

export type RegionCensus = Static<typeof RegionCensus>;

export const ProfilingCensusQuota = Record({
  variableIds: Array(AboveZero),
  percentage: NonNegative,
});

export const ProfilingCensus = Record({
  questionId: AboveZero,
  quotas: Array(ProfilingCensusQuota),
  source: String.Or(Null),
  isCompanyCensus: Boolean,
  presetId: Number,
});

export type ProfilingCensus = Static<typeof ProfilingCensus>;

export const CensusRegionsDeprecated = Array(
  Record({
    id: AboveZero,
    percentage: NonNegative,
    name: NonEmptyString,
    mapId: AboveZero,
  })
);
export type CensusRegionsDeprecated = Static<typeof CensusRegionsDeprecated>;

export const CensusResponseDeprecated = Record({
  genders: Array(GenderCensusQuota),
  ageSpans: Array(AgeCensusQuota),
  regions: CensusRegionsDeprecated,
});
export type CensusResponseDeprecated = Static<typeof CensusResponseDeprecated>;

export const CensusResponse = Record({
  genders: GenderCensus,
  ageSpans: AgeCensus,
  regions: RegionCensus,
  profiling: Array(ProfilingCensus),
});

export type CensusResponse = Static<typeof CensusResponse>;
