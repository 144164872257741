import { BasicSettingsModel, createBasicSettingsModel } from './basic-settings.model';
import { createProfilingModel, ProfilingModel } from './profiling.model';
import { createQuotasModel, QuotasModel } from './quotas.model';
import { createExcludeProjectsModel, ExcludeProjectsModel } from './exclude-projects.model';
import { PaneName } from '../../target-groups/target-group-accordion.service';
import { createRegionsModel, RegionsModel } from './regions.model';
import { SupplyModel, createSupplyModel } from './supply.model';
import { createIncentivesModel, IncentivesModel } from './incentives.model';
import { LinksData, TargetGroupValidationResult } from '../http-services/draft.httpservice';
import { SurveyMetadataModel, createSurveyMetadataModel } from './survey-metadata.model';
import { Money } from '../pricing/price-models';

export interface RequestMode {
  includeLockedPanels: boolean;
}

export interface ProjectTemplateSource {
  kind: 'none' | 'project' | 'draft' | 'template';
  projectId?: number;
  draftId?: number;
  templateId?: number;
  targetGroupId?: number;
  updateCount: number;
}

export interface TargetGroupModel {
  id: number;
  replacesTargetGroupId?: number;
  replacesProjectIds?: number[];
  name: string;

  activePane: PaneName;
  requestMode: RequestMode;
  projectTemplateSource: ProjectTemplateSource;

  basicSettings: BasicSettingsModel;
  regions: RegionsModel;
  profiling: ProfilingModel;
  panels: SupplyModel;
  quotas: QuotasModel;
  excludeProjects: ExcludeProjectsModel;
  incentives: IncentivesModel;
  surveyMetadata?: SurveyMetadataModel;

  cpi?: Money;

  deprecation?: TargetGroupValidationResult;
  hasDeprecation?: boolean;
  linksData?: LinksData;
}

export function createTargetGroupValidationResult(): TargetGroupValidationResult {
  return {
    deprecatedRegions: [],
    hasDeprecatedPostalCodes: false,
    hasDeprecatedProfiling: false,
    hasDeprecatedExcludedProjects: false,
    hasDeprecatedPanelistPoolSource: false,
    hasInclusivePanelistPoolFromApi: false,
    hasExclusivePanelistPoolFromApi: false,
    hasDeletedExcludedProjects: false,
    hasDeprecatedQuotas: false,
    hasDeprecatedAdHocSupplier: false,
    hasIncompatiblePanels: false,
    unavailablePanels: [],
    visiblePanels: [],
    hasPanelsFromMultipleSupplySources: false,
    embargoedRegions: [],
    hasEmbargoedPostalCodes: false,
  };
}

export function createTargetGroupModel(id: number, name: string): TargetGroupModel {
  return {
    id,
    name,
    activePane: 'basic-settings',
    requestMode: { includeLockedPanels: false },
    projectTemplateSource: { kind: 'none', updateCount: 0 },

    basicSettings: createBasicSettingsModel(),
    regions: createRegionsModel(),
    profiling: createProfilingModel(),
    panels: createSupplyModel(),
    quotas: createQuotasModel(),
    excludeProjects: createExcludeProjectsModel(),
    incentives: createIncentivesModel(),
    surveyMetadata: createSurveyMetadataModel(),
  };
}
