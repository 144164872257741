import './unsaved-changes-dialog.component.scss';
import { ui } from 'angular';
import { html } from '../../helpers';
import { UnsavedChangesDialogResolve } from './unsaved-changes-dialog-settings.factory';
import { analytics } from '../analytics';
import { TargetGroupsStateIntent } from '../../target-groups-state-intent';
import { CommonConstants } from '../common.constants';

const selector = 'unsavedChangesDialogComponent';

const template = html`
  <div class="unsaved-changes-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <div ng-if="!$ctrl.canMerge">
        <h1 class="modal-header">Discard current work</h1>
        <div>
          <div class="description">
            You are currently {{ $ctrl.currentWorkDescription }}&nbsp;
            <span class="current-work-title">{{ $ctrl.currentWorkTitle }}</span>
          </div>
          <br />
          <p>Your current work may contain changes that will be lost if you continue.</p>
        </div>
      </div>
      <div ng-if="$ctrl.canMerge">
        <h1 class="modal-header">Discard or merge with current work</h1>
        <div>
          <div class="description">
            You are currently {{ $ctrl.currentWorkDescription }}&nbsp;
            <span class="current-work-title">{{ $ctrl.currentWorkTitle }}</span>
          </div>
          <br />
          <p>
            You can choose to <em>discard</em> this work and create a new project with just the selected target
            group(s).
          </p>
          <br />
          <p>Or <em>merge</em> the selected target group(s) with the current work and create a new project.</p>
          <span ng-if="$ctrl.mergeExceedsMaxTgLimit">
            <br />
            <div class="alert alert-warning">
              <strong>Note:</strong> Merging is disabled because the {{${CommonConstants.maximumTargetGroupsCount}}}
              target group limit would be exceeded.
            </div>
          </span>
        </div>
      </div>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button
          ng-if="$ctrl.canMerge"
          class="ui-btn default-btn"
          ng-disabled="$ctrl.mergeExceedsMaxTgLimit"
          ng-click="$ctrl.closeAndMerge()"
        >
          Merge and continue
        </button>
        <button class="ui-btn primary-btn" ng-click="$ctrl.closeAndDiscard()">Discard and continue</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  resolve: '<' | UnsavedChangesDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class UnsavedChangesDialogComponent implements Bindings {
  static componentName = selector;

  resolve: UnsavedChangesDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  currentWorkTitle: string;
  currentWorkIntent: TargetGroupsStateIntent;
  canMerge: boolean;
  mergeExceedsMaxTgLimit: boolean;

  get currentWorkDescription() {
    switch (this.currentWorkIntent) {
      case TargetGroupsStateIntent.AddTargetGroups:
        return 'adding target groups to project';
      case TargetGroupsStateIntent.EditTargetGroupTemplate:
        return 'editing template';
      case TargetGroupsStateIntent.EditDraft:
        return 'editing draft';
      default:
        return 'editing';
    }
  }

  $onInit(): void {
    this.canMerge = this.resolve.canMerge;
    this.currentWorkTitle = this.resolve.currentWorkTitle;
    this.currentWorkIntent = this.resolve.currentWorkIntent;
    this.mergeExceedsMaxTgLimit = this.resolve.mergeExceedsMaxTgLimit;
  }

  dismiss(): void {
    analytics.currentWork.cancel(this.currentWorkIntent);
    this.modalInstance.dismiss();
  }

  closeAndMerge(): void {
    if (this.mergeExceedsMaxTgLimit) return;
    analytics.currentWork.merge(this.currentWorkIntent);
    this.modalInstance.close({ merge: true });
  }

  closeAndDiscard() {
    analytics.currentWork.discard(this.currentWorkIntent);
    this.modalInstance.close({ merge: false });
  }
}

export const ngUnsavedChangesDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: UnsavedChangesDialogComponent,
  },
};
