import { fromJson, toJson } from 'angular';
import { Currency } from './http-services/pricing.httpservice';
import { userService } from './user.service';
import { keys } from '../helpers';
import { StorageFactory } from './storage.factory';
import { Constants } from '../constants';

export interface ProjectSettings {
  isSet: boolean;
  projectName: string;
  categoryIds: number[];
  filterOnPii: boolean;
  checkGeoIp: boolean;
  useDeduplication: boolean;
  suitableForMobile: boolean;
  suitableForTablet: boolean;
  suitableForDesktop: boolean;
  requireWebcam: boolean;
  displayCurrency?: Currency;
}

export class ProjectSettingsService {
  private storage: Storage;
  private storageKey = 'projectSettings';
  private projectSettings: ProjectSettings;

  get settings(): Readonly<ProjectSettings> {
    return this.projectSettings;
  }

  constructor() {
    this.storage = StorageFactory.getStorage();
  }

  persist(projectSettings: ProjectSettings): void {
    if (this.projectSettings === undefined) {
      this.projectSettings = this.getDefaultSettings();
    }

    for (const psKey of keys(projectSettings)) {
      switch (psKey) {
        case 'projectName':
          this.projectSettings.projectName = projectSettings.projectName;
          break;
        case 'categoryIds':
          this.projectSettings.categoryIds = projectSettings.categoryIds;
          break;
        case 'displayCurrency':
          this.projectSettings.displayCurrency = projectSettings.displayCurrency;
          break;
        default:
          this.projectSettings[psKey] = projectSettings[psKey];
          break;
      }
    }
    this.storage.setItem(this.storageKey, toJson(this.projectSettings));
  }

  load(): void {
    this.projectSettings = fromJson(this.storage.getItem(this.storageKey)) || this.getDefaultSettings();
  }

  clear(): void {
    this.storage.removeItem(this.storageKey);
    this.projectSettings = this.getDefaultSettings();
  }

  updateProjectName(name: string): void {
    this.update('projectName', name);
  }

  unset(): void {
    this.update('isSet', false);
  }

  update<K extends keyof ProjectSettings>(prop: K, value: any): void {
    this.projectSettings[prop] = value;
    this.persist(this.projectSettings);
  }

  private getDefaultSettings(): ProjectSettings {
    return {
      isSet: false,
      projectName: Constants.defaultProjectName,
      categoryIds: [],
      filterOnPii: false,
      checkGeoIp: true,
      useDeduplication: true,
      suitableForMobile: true,
      suitableForTablet: true,
      suitableForDesktop: true,
      requireWebcam: userService.hasRequiredWebcamAsDefault,
      displayCurrency: null,
    };
  }
}

export const projectSettingsService = new ProjectSettingsService();
