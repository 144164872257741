import dayjs from 'dayjs';
import { ProjectListFilterOptions } from './project-filters/project-filters.component';
import { ProjectListItem } from './project-list-item/project-list-item.component';
import { ListenerComponent } from '../common/channels/channel';
import { SortField, ProjectListItemStatus, ProjectUserFilter, BackgroundDataJobStatus } from '../common/enums';
import { draftChannel } from '../common/channels/draft-channel';
import { api } from '../common/api';
import { projectListChannel } from '../common/channels/project-list-channel';
import { userService } from '../common/user.service';
import { MenuListItem, MenuOptionType } from '../common/controls/options-menu/options-menu.component';
import { analytics } from '../common/analytics';
import { modalService } from '../common/modal.service';
import { DeleteDraftDialogSettingsFactory } from './delete-draft-dialog-settings.factory';
import { targetGroupRepository } from '../common/target-group/target-group.repository';
import { html, silenceRejection } from '../helpers';
import { persistentSurveyLinksService } from '../common/target-group/survey-links/persistent-survey-links.service';
import { projectSettingsService } from '../common/project-settings.service';
import { filters } from '../common/filters';
import { $window } from '../ngimport';
import { InfoDialogSettingsFactory } from '../common/dialogs/info-dialog-settings.factory';
import { asyncReportService, ReportTypes } from '../common/async-report-service';
import { ErrorMessageDialogSettingsFactory } from '../common/dialogs/error-message-dialog-settings.factory';

const selector = 'draftsComponent';

const template = html`
  <!-- TODO: test id on below line can be removed after old project view is disabled for good -->
  <div class="project-list__tab" data-testid="drafts-component">
    <div class="project-list__filters">
      <project-filters-component
        result-count="$ctrl.filteredDrafts.length"
        options="$ctrl.filterOptions"
        date-label="$ctrl.dateLabel"
        on-filter-clicked="$ctrl.updateFilterOptions()"
      >
      </project-filters-component>
    </div>

    <div class="project-list__head" stick-to-top scroll-down-position="0" scroll-up-position="74">
      <p
        class="project-list__column_id sortable"
        ng-class="$ctrl.sortIndicator('${SortField.Id}')"
        ng-click="$ctrl.sortOn('${SortField.Id}')"
      >
        ID
      </p>
      <p
        class="project-list__column_project sortable"
        ng-class="$ctrl.sortIndicator('${SortField.Name}')"
        ng-click="$ctrl.sortOn('${SortField.Name}')"
      >
        Name
      </p>
      <p class="project-list__column_completes"></p>
      <p
        class="project-list__column_date sortable"
        ng-class="$ctrl.sortIndicator('${SortField.CreatedDate}')"
        ng-click="$ctrl.sortOn('${SortField.CreatedDate}')"
      >
        Created
      </p>
      <p
        class="project-list__column_date sortable"
        ng-class="$ctrl.sortIndicator('${SortField.UpdatedDate}')"
        ng-click="$ctrl.sortOn('${SortField.UpdatedDate}')"
      >
        Updated
      </p>
      <p class="project-list__column_control"></p>
    </div>
    <!-- TODO: test id on below line can be removed after old project view is disabled for good -->
    <div class="project-list__projects" data-testid="drafts-component">
      <p class="project-list__empty-text" ng-if="!$ctrl.filteredDrafts.length && !$ctrl.isLoading">No drafts found</p>
      <div
        class="project-list__project"
        ng-repeat="draft in $ctrl.visibleDrafts
                    | orderBy:$ctrl.sortOptions.field:$ctrl.descendingOrder()
                    track by draft.id"
      >
        <project-list-item-component item="draft" on-option-clicked="$ctrl.onMenuOptionClicked(option, item)">
        </project-list-item-component>
      </div>
      <div
        class="project-list__load-trigger"
        on-scroll-into-view="$ctrl.showNextDraftsPage()"
        margin="{{$ctrl.scrollMargin}}"
      >
        &nbsp;
      </div>
      <div class="project-list__spinner" ng-if="$ctrl.isLoading">
        <div class="spinner-container text-center">
          <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
`;

export class DraftsComponent extends ListenerComponent {
  static componentName = selector;
  dateLabel = 'Created';
  drafts: ProjectListItem[] = [];
  filteredDrafts: ProjectListItem[];
  visibleDrafts: ProjectListItem[];
  isLoading = false;
  pageSize: number;
  currentPage = 1;
  scrollMargin: number;
  filterOptions = new ProjectListFilterOptions();

  $onInit(): void {
    draftChannel.saved.listen(this.getDrafts, this);
    draftChannel.deleted.listen(this.whenDraftDeleted, this);
    this.pageSize = this.getPageSize();
    this.scrollMargin = this.getScrollMargin();
    this.filterOptions.sortOptions.field = SortField.UpdatedDate;
    this.filterOptions.sortOptions.descending = true;
    this.getDrafts();
  }

  sortOn(field: SortField): void {
    if (this.filterOptions.sortOptions.field === field) {
      this.filterOptions.sortOptions.descending = !this.filterOptions.sortOptions.descending;
    } else {
      this.filterOptions.sortOptions.descending = field === SortField.CreatedDate || field === SortField.UpdatedDate;
    }
    this.filterOptions.sortOptions.field = field;
    this.filterDrafts();
  }

  sortIndicator(field: SortField) {
    if (this.filterOptions.sortOptions.field === field) {
      return this.filterOptions.sortOptions.descending ? 'sort-desc' : 'sort-asc';
    }
    return '';
  }

  getDrafts() {
    this.isLoading = true;
    api.draft
      .getAllDrafts()
      .then((res) => {
        this.drafts = res.data.map((el) => ({
          id: el.id,
          name: el.name,
          owner: el.owner,
          channel: el.channel,
          status: ProjectListItemStatus.Draft,
          startDate: el.created,
          endDate: el.updated,
          url: this.getUrl(el.id),
          menuOptions: this.createMenuOptions(el.id),
        }));
        this.filterDrafts();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onMenuOptionClicked(option: MenuListItem, item: ProjectListItem) {
    switch (option.id) {
      case 0:
        analytics.projectList.projectMenu.draft.view();
        break;
      case 1:
        this.deleteDraft(item);
        break;
      case 2:
        modalService.openSmall(
          InfoDialogSettingsFactory.create({
            title: 'Draft snapshot',
            text: 'The report is being generated in the background. It may take several minutes. You can close this window and continue your work.',
          })
        );
        this.downloadReport(item);
        analytics.projectList.projectMenu.draft.downloadSnapshot(item.id);
        break;
      default:
        break;
    }
  }

  deleteDraft(draft: ProjectListItem) {
    modalService
      .openSmall(
        DeleteDraftDialogSettingsFactory.create({
          draft: { id: draft.id, name: draft.name },
          savedDraft: targetGroupRepository.getStorageDraft(),
        })
      )
      .result.catch(silenceRejection);
  }

  async downloadReport(draft: ProjectListItem) {
    asyncReportService
      .generateReport(draft.id, ReportTypes.DraftSnapshot)
      .then(() => {
        let seconds = 0;
        const id = setInterval(() => {
          if (seconds < 10 || seconds % 10 === 0)
            asyncReportService.getReportStatus(draft.id, ReportTypes.DraftSnapshot).then((res) => {
              if (res.jobStatus === BackgroundDataJobStatus.Completed) {
                asyncReportService
                  .getReportDataFile(draft.id, draft.name, ReportTypes.DraftSnapshot)
                  .then(() => projectListChannel.loadedFile.dispatch(draft.id));
              }
              if (
                res.jobStatus === BackgroundDataJobStatus.Completed ||
                res.jobStatus === BackgroundDataJobStatus.Failed
              ) {
                clearInterval(id);
              }
            });
          seconds += 1;
        }, 1000);
      })
      .catch(() => {
        projectListChannel.loadedFile.dispatch(draft.id);
        return ErrorMessageDialogSettingsFactory.create({
          title: 'Draft snapshot report',
          messages: ['Something went wrong while getting the data.', 'Please try again later'],
        });
      });
  }

  filterDrafts() {
    this.currentPage = 1;
    const currentUser = userService.isImpersonatingOtherUser() ? userService.impersonatedAs : userService.name;
    const searchString = this.filterOptions.filterText?.toLowerCase();
    this.filteredDrafts = this.drafts
      .filter((d) => this.filter(d, searchString, currentUser))
      .sort((d1, d2) =>
        this.sort(d1, d2, this.filterOptions.sortOptions.field, this.filterOptions.sortOptions.descending)
      );
    this.visibleDrafts = this.filteredDrafts.slice(0, this.pageSize);
  }

  sort(d1: ProjectListItem, d2: ProjectListItem, field: SortField, descending: boolean) {
    const order = descending ? -1 : 1;
    switch (field) {
      case SortField.CreatedDate:
        return dayjs(d1.startDate).isAfter(dayjs(d2.startDate)) ? order : -order;
      case SortField.UpdatedDate:
        return dayjs(d1.endDate).isAfter(dayjs(d2.endDate)) ? order : -order;
      case SortField.Name:
        return d1.name > d2.name ? order : -order;
      case SortField.Id:
        return d1.id > d2.id ? order : -order;
      default:
        return 0;
    }
  }

  updateFilterOptions() {
    projectListChannel.paneExpanded.dispatch('none');
    this.filterDrafts();
    analytics.projectList.filter.apply('drafts');
  }

  showNextDraftsPage() {
    if (!this.filteredDrafts || !this.filteredDrafts.length) {
      this.currentPage = 1;
      this.visibleDrafts = [];
      return;
    }
    this.currentPage = Math.min(this.currentPage + 1, Math.ceil(this.filteredDrafts.length / this.pageSize));
    this.visibleDrafts = this.filteredDrafts.slice(0, this.pageSize * this.currentPage);
  }

  private async whenDraftDeleted(wasInStorage: boolean): Promise<void> {
    if (wasInStorage) {
      await this.onDeletedLoadedDraft();
    }
    this.getDrafts();
  }

  private filter(draft: ProjectListItem, searchString: string, owner: string): boolean {
    if (
      (this.filterOptions.userFilter === ProjectUserFilter.User ||
        this.filterOptions.userFilter === ProjectUserFilter.CreatedBy) &&
      draft.owner !== owner
    ) {
      return false;
    }

    const { fromDate, toDate } = this.filterOptions;
    if (searchString) {
      const draftName = draft.name.toLowerCase();
      const draftId = draft.id.toString();
      if (!draftName.includes(searchString) && !draftId.includes(searchString)) return false;
    }
    if (fromDate && dayjs(draft.startDate).isBefore(dayjs(fromDate))) return false;
    if (toDate && dayjs(toDate).add(1, 'day').isBefore(dayjs(draft.startDate))) return false;
    return true;
  }

  private async onDeletedLoadedDraft(): Promise<void> {
    await targetGroupRepository.removeAll().then(() => {
      persistentSurveyLinksService.clear();
      projectSettingsService.clear();
    });
  }

  private getUrl(id: number): string {
    return filters.loadDraftUrl()(id);
  }

  private createMenuOptions(id: number) {
    const options = [
      {
        id: 0,
        name: 'View draft',
        url: this.getUrl(id),
        type: MenuOptionType.View,
      },
      {
        id: 1,
        name: 'Delete draft',
        url: null,
        type: MenuOptionType.Delete,
      },
    ];
    options.push({
      id: 2,
      name: 'Download draft snapshot',
      url: null,
      type: MenuOptionType.DownloadReport,
    });

    return options;
  }

  private getPageSize() {
    return Math.ceil(($window.innerHeight * 1.25) / 70);
  }

  private getScrollMargin() {
    return Math.ceil($window.innerHeight / 4);
  }
}

export const ngDraftsComponent = {
  [selector]: {
    template,
    controller: DraftsComponent,
  },
};
