import './show-links-dialog.component.scss';
import { ui } from 'angular';
import { TargetGroupRespondentStatistics } from '../../http-services/target-group.httpservice';
import { html } from '../../../helpers';

const selector = 'showLinksDialogComponent';

const template = html`
  <div class="show-links-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Survey Links - {{$ctrl.targetGroupName}}</h1>
      <target-group-links-component
        project-id="$ctrl.projectId"
        target-group-id="$ctrl.targetGroupId"
        screenout-information="$ctrl.screenoutInformation"
        test-links="$ctrl.testLinks"
        ad-hoc-supplier-link="$ctrl.adHocSupplierLink"
      ></target-group-links-component>
      <div class="modal-controls">
        <button class="ui-btn primary-btn min-width-btn" ng-click="$ctrl.dismiss()">Close</button>
      </div>
    </div>
  </div>
`;

export interface ShowLinksDialogResolve {
  projectId: number;
  targetGroupId: number;
  targetGroupName: string;
  screenoutInformation: string;
  testLinks: string[];
  adHocSupplierLink?: string;
}

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
  resolve: '<' | ShowLinksDialogResolve;
}

const bindings: Bindings = {
  modalInstance: '<',
  resolve: '<',
};

export class ShowLinksDialogComponent implements Bindings, ShowLinksDialogResolve {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;
  resolve: ShowLinksDialogResolve;
  projectId: number;
  targetGroupId: number;
  targetGroupName: string;
  screenoutInformation: string;
  testLinks: string[];
  adHocSupplierLink?: string;

  stats: TargetGroupRespondentStatistics;

  $onInit(): void {
    this.projectId = this.resolve.projectId;
    this.targetGroupId = this.resolve.targetGroupId;
    this.targetGroupName = this.resolve.targetGroupName;
    this.screenoutInformation = this.resolve.screenoutInformation;
    this.testLinks = this.resolve.testLinks;
    this.adHocSupplierLink = this.resolve.adHocSupplierLink;
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }
}

export const ngShowLinksDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ShowLinksDialogComponent,
  },
};
