import { map, intersection } from 'lodash-es';
import { IDirective } from 'angular';
import { userService } from '../user.service';

const directiveName = 'hideForRoles';

export function hideForRolesDirective(): IDirective {
  return {
    link(scope, element, attrs) {
      const rolesAllowed = scope.$eval(attrs[directiveName].toLowerCase());
      const userRoles = map(userService.roles, (role) => role.toLowerCase());

      const rolesThatMatch = intersection(userRoles, rolesAllowed);
      if (rolesThatMatch.length > 0) {
        element.remove();
      }
    },
  };
}

export const ngHideForRolesDirective = {
  [directiveName]: hideForRolesDirective,
};
