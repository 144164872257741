import { isEmpty, flatMap, some, sumBy, uniq } from 'lodash-es';
import { ActiveTargetGroupModel, AgeSpan, Census, ProfilingCensus } from '../../models/active-target-group.model';
import { ActiveTargetGroupStore, activeTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { ActiveQuotasModel } from '../../models/active-quotas.model';
import { ProfilingScope, QuotaOptions, QuotaPresetLoadFailureReason, TargetingType } from '../../../../common/enums';
import { quotasSubactions } from './quotas.subactions';
import { QuotasAndBuckets } from '../../../quotas/quota-bucket-builder';
import { regionsSubactions } from '../regions/regions.subactions';
import { WeightingStrategy } from '../../../../common/models/quotas.model';
import {
  getGeneratedBucketsFromModel,
  getCompletesOrStarts,
  getSectionFromQuotaHash,
  getNumberOfBucketsFromQuotas,
  isInterlockedWith,
  sanitizeCensusAgeSpans,
  allCensusVariablesSelected,
  parseQuestionIdFromProfilingQuotaKeys,
} from '../../../quotas/quotas-helper.functions';
import { stateDebugService } from '../../../../common/state-debug.service';
import { TargetGroupConstants } from '../../../target-group.constants';
import { api, QuotaGroupPreset } from '../../../../common/api';
import { basicSettingsSubactions } from '../basic-settings/basic-settings.subactions';
import { profilingSubactions } from '../profiling/profiling.subactions';
import { ActiveProfilingModel } from '../../models/active-profiling.model';
import { censusSubactions } from '../census/census.subactions';
import { UpsertQuotaGroupPresetRequest } from '../../../../common/dialogs/SaveQuotaPresetDialog';

type QuotasUpdateFunc = (
  state: ActiveTargetGroupModel,
  commit: CommitFuncs,
  done: (preventFeasibility?: boolean) => void
) => void;

export const commitQuotas =
  () =>
  (quotasState: ActiveQuotasModel): void => {
    activeTargetGroupStore.model.quotas = quotasState;
    activeTargetGroupStore.model.bucketsCount = getNumberOfBucketsFromQuotas(
      quotasState,
      activeTargetGroupStore.model.supply
    );
    checkWeightingStrategy();
    targetGroupChannel.model.quotas.updated.dispatch();
  };

function checkWeightingStrategy() {
  const { quotas } = activeTargetGroupStore.model;

  if (
    quotas.weightingStrategy === WeightingStrategy.RimWeighting &&
    (!activeTargetGroupStore.quotas.hasQuotas(quotas) ||
      activeTargetGroupStore.model.bucketsCount > TargetGroupConstants.maximumBucketsAllowed)
  ) {
    activeTargetGroupStore.model.quotas = quotasSubactions.setWeightingStrategy(
      quotas,
      WeightingStrategy.EvenDistribution
    );
  }
}

export class QuotasActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  get generatedBuckets(): QuotasAndBuckets {
    return getGeneratedBucketsFromModel(this.rootStore.model);
  }

  get sectionsUsingPanelDistribution(): readonly (readonly string[])[] {
    return [
      this.rootStore.model.quotas.gender,
      this.rootStore.model.quotas.ageSpan,
      this.rootStore.model.quotas.region,
      ...this.rootStore.model.quotas.profiling,
      ...this.rootStore.model.quotas.interlocked,
    ]
      .filter((section) => section.usePanelDistribution)
      .map((section) => section.keys);
  }

  get sectionsUsingIgnoreCompletes(): readonly (readonly string[])[] {
    const sections = [];
    if (this.rootStore.model.supply.supplyMix.ignoreCompletes) {
      sections.push(['supply']);
    }
    return [
      ...sections,
      ...[
        this.rootStore.model.quotas.gender,
        this.rootStore.model.quotas.ageSpan,
        this.rootStore.model.quotas.region,
        ...this.rootStore.model.quotas.profiling,
        ...this.rootStore.model.quotas.interlocked,
      ]
        .filter((section) => section.ignoreCompletes)
        .map((section) => section.keys),
    ];
  }

  hasQuotas(quotaOverride: ActiveQuotasModel = undefined): boolean {
    const quotas = quotaOverride || this.rootStore.model.quotas;
    if (quotas.gender.items.length) return true;
    if (quotas.ageSpan.items.length) return true;
    if (quotas.region.items.length) return true;
    if (some(quotas.profiling, (p) => p.items.length)) return true;
    if (some(quotas.interlocked, (p) => p.items.length)) return true;
    return false;
  }

  clearGenderQuotas() {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.clearGenderQuotas(state.quotas);
      commit.quotas(newState);
      done();
    });
  }

  createGenderQuotas(option: QuotaOptions) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.createGenderQuotas(
        state.quotas,
        option,
        getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfCompletes),
        getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfStarts),
        state.quotas.gender.ignoreCompletes
      );
      commit.quotas(newState);
      done();
    });
  }

  clearAgeQuotas() {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.clearAgeQuotas(state.quotas);
      commit.quotas(newState);
      done();
    });
  }

  createAgeQuotas(option: QuotaOptions, ageSpans?: readonly AgeSpan[]) {
    this.update((state, commit, done) => {
      let hasExtrapolatedAgeCensus = false;
      let maxAgeInCensus: number = null;
      let ageSpansToUse = ageSpans;

      if (option === QuotaOptions.Completes) {
        const [sortedCensusAgeSpans, boolFlagFromHelper, maxAgeFromHelper] = sanitizeCensusAgeSpans(
          state.census.ageSpan.quotas
        );
        hasExtrapolatedAgeCensus = boolFlagFromHelper;
        maxAgeInCensus = maxAgeFromHelper;
        ageSpansToUse = sortedCensusAgeSpans;
      } else if (isEmpty(ageSpans)) {
        throw Error('must supply ageSpans if not using census');
      }

      const newState = quotasSubactions.createAgeQuotas(
        state.quotas,
        option,
        ageSpansToUse,
        getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfCompletes),
        getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfStarts),
        state.quotas.ageSpan.ignoreCompletes,
        { hasExtrapolatedAgeCensus, maxAgeInCensus }
      );
      commit.quotas(newState);
      done();
    });
  }

  clearRegionQuotas() {
    this.update((state, commit, done) => {
      if (state.regions.mainRegionsAutomaticallySelected) {
        const newRegionsState = regionsSubactions.discardRegionType(state.regions);
        commit.regions(newRegionsState);
      }
      const newQuotasState = quotasSubactions.clearRegionQuotas(state.quotas);
      commit.quotas(newQuotasState);
      done();
    });
  }

  createRegionQuotas(option: QuotaOptions) {
    this.update(async (state, commit, done) => {
      if (!isEmpty(state.regions.selectedRegionType) && !isEmpty(state.regions.selectedIds)) {
        const newQuotasState = quotasSubactions.createRegionQuotas(
          state.quotas,
          option,
          state.regions.selectedIds.map((id) => [id]),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfCompletes),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfStarts),
          state.quotas.region.ignoreCompletes
        );
        commit.quotas(newQuotasState);
        done();
      } else {
        targetGroupChannel.model.regions.regionTypeChanging.dispatch();
        const newRegionsState = await regionsSubactions.selectAllRegionsForMainRegionType(
          state.regions,
          state.basicSettings.countryId,
          state.regions.selectedRegionType.id
        );
        commit.regions(newRegionsState);
        const newQuotasState = quotasSubactions.createRegionQuotas(
          state.quotas,
          option,
          newRegionsState.selectedIds.map((id) => [id]),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfCompletes),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfStarts),
          state.quotas.region.ignoreCompletes
        );
        commit.quotas(newQuotasState);
        done();
      }
    });
  }

  clearProfilingQuotas(questionId: number) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.clearProfilingQuotas(state.quotas, questionId);
      commit.quotas(newState);
      done();
    });
  }

  createProfilingQuotas(option: QuotaOptions, questionId: number) {
    this.update(async (state, commit, done) => {
      if (option === QuotaOptions.Completes) {
        const newQuotasState = await this.createCensusProfilingQuotas(
          state.quotas,
          state.census.profiling,
          questionId,
          state.basicSettings.numberOfCompletes,
          state.basicSettings.numberOfStarts
        );
        commit.quotas(newQuotasState);
      } else {
        const selectedVariableIds = flatMap(state.profiling.detailedSelection, (d) => d.questions)
          .find((q) => q.id === questionId)
          .variables.map((v) => v.id);

        const newState = quotasSubactions.createProfilingQuotas(
          state.quotas,
          option,
          questionId,
          selectedVariableIds.map((id) => [id]),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfCompletes),
          getCompletesOrStarts(state.quotas.usePercentages, state.basicSettings.numberOfStarts),
          undefined,
          'profiling'
        );
        commit.quotas(newState);
      }

      done();
    });
  }

  interlock(keysToInterlock: readonly (readonly string[])[]) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.interlock(
        state.quotas,
        state.basicSettings.numberOfCompletes,
        state.basicSettings.numberOfStarts,
        keysToInterlock
      );
      commit.quotas(newState);
      done();
    });
  }

  group(quotaHashesToGroup: readonly string[], key: string, name: string = undefined) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.group(state.quotas, quotaHashesToGroup, key, name);
      commit.quotas(newState);
      done();
    });
  }

  removeInterlockedQuotasSection(keys: readonly string[]) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.removeInterlockedQuotasSection(state.quotas, keys);
      commit.quotas(newState);
      done();
    });
  }

  toggleUsePercentages() {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.toggleUsePercentages(
        state.quotas,
        state.basicSettings.numberOfCompletes,
        state.basicSettings.numberOfStarts
      );
      commit.quotas(newState);
      done();
    });
  }

  setWeightingStrategy(strategy: WeightingStrategy) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.setWeightingStrategy(state.quotas, strategy);
      commit.quotas(newState);
      done();
    });
  }

  setQuotaCompletes(hash: string, completes: number): void {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.setQuotaCompletes(state.quotas, hash, completes);
      commit.quotas(newState);
      done();
    });
  }

  setQuotaStarts(hash: string, starts: number): void {
    this.update((state, commit, done) => {
      let newState = quotasSubactions.setQuotaStarts(state.quotas, hash, starts);
      const { useStarts, numberOfStarts, numberOfCompletes } = state.basicSettings;
      if (useStarts) {
        const section = getSectionFromQuotaHash(newState, hash);
        if (section.ignoreCompletes && sumBy(section.items, (i) => i.starts) === numberOfStarts) {
          newState = quotasSubactions.rescaleCompletesAccordingToStartsDistribution(
            newState,
            [section.keys],
            numberOfCompletes
          );
        }
      }
      commit.quotas(newState);
      done();
    });
  }

  toggleUsePanelDistribution(keys: readonly string[]) {
    this.update((state, commit, done) => {
      const newState = quotasSubactions.toggleUsePanelDistribution(
        state.quotas,
        keys,
        state.basicSettings.numberOfCompletes,
        state.basicSettings.numberOfStarts
      );
      commit.quotas(newState);
      done();
    });
  }

  ignoreCompletes(keys: readonly string[], ignoreCompletes: boolean) {
    this.update((state, commit, done) => {
      let newState = state.quotas;
      if (state.quotas.weightingStrategy === WeightingStrategy.RimWeighting) {
        newState = quotasSubactions.setWeightingStrategy(newState, WeightingStrategy.EvenDistribution);
      }
      newState = quotasSubactions.ignoreCompletes(newState, keys, ignoreCompletes);
      newState = quotasSubactions.rescaleCompletesAccordingToStartsDistribution(
        newState,
        [keys],
        state.basicSettings.numberOfCompletes
      );
      commit.quotas(newState);
      done();
    });
  }

  removeQuotasAndReloadCensus(quotaKey: string, callback?: () => void) {
    this.update(async (state, commit, done) => {
      const {
        quotas,
        census,
        basicSettings: { countryId, minAge, maxAge },
      } = state;

      let newQuotasState: ActiveQuotasModel;

      if (quotaKey.includes('profiling'))
        newQuotasState = quotasSubactions.clearProfilingQuotas(
          quotas,
          parseQuestionIdFromProfilingQuotaKeys([quotaKey])
        );
      else {
        switch (quotaKey) {
          case 'ageSpan':
            newQuotasState = quotasSubactions.clearAgeQuotas(quotas);
            break;
          case 'gender':
            newQuotasState = quotasSubactions.clearGenderQuotas(quotas);
            break;
          case 'region':
            newQuotasState = quotasSubactions.clearRegionQuotas(quotas);
            break;
          default:
            throw Error('Could not parse quota key');
        }
      }

      const newCensusState = await censusSubactions.createCensusPromise(census, countryId, minAge, maxAge);

      commit.quotas(newQuotasState);
      commit.census(newCensusState);

      callback?.();

      done();
    });
  }

  handleNewlySavedCompanyCensusPreset(preset: UpsertQuotaGroupPresetRequest) {
    this.update((state, commit, done) => {
      let newCensusState: Census;

      if (preset.targetingTypes.length !== 1) throw Error('Interlocked presets not supported');

      api.census.clearCountryCensusCacheForCompany(preset.countryId);

      const targetingType = preset.targetingTypes[0];

      const baseCensusObject = {
        source: '',
        isCompanyCensus: true,
        presetId: preset.id,
      };

      switch (targetingType) {
        case TargetingType.Age:
          newCensusState = censusSubactions.setAgeCensus(state.census, {
            ...baseCensusObject,
            quotas: preset.quotas.map((pq) => ({ from: pq.ageSpan.from, to: pq.ageSpan.to, percentage: pq.ratio })),
          });
          break;
        case TargetingType.Gender:
          newCensusState = censusSubactions.setGenderCensus(state.census, {
            ...baseCensusObject,
            quotas: preset.quotas.map((pq) => ({ name: pq.gender, percentage: pq.ratio })),
          });
          break;
        case TargetingType.Region:
          newCensusState = censusSubactions.setRegionCensus(state.census, {
            ...baseCensusObject,
            quotas: preset.quotas.map((pq) => ({ regionIds: pq.regions.map((r) => r.id), percentage: pq.ratio })),
          });
          break;
        case TargetingType.Profiling:
          newCensusState = censusSubactions.setProfilingCensusQuestion(state.census, {
            ...baseCensusObject,
            questionId: preset.quotas[0].variables[0].questionId,
            quotas: preset.quotas.map((pq) => ({ variableIds: pq.variables.map((v) => v.id), percentage: pq.ratio })),
          });
          break;
        default:
          break;
      }

      commit.census(newCensusState);
      done();
    });
  }

  selectCensusVariables(questionId: number, categoryId: number = undefined) {
    this.update(async (state, commit, done) => {
      const questionSelection = flatMap(state.profiling.detailedSelection, (d) => d.questions).find(
        (q) => q.id === questionId
      );
      const questionCensus = state.census.profiling.find((p) => p.questionId === questionId);
      if (!allCensusVariablesSelected(questionSelection, questionCensus)) {
        const newProfilingState = await this.selectCensusProfiling(
          state.profiling,
          state.census.profiling,
          state.basicSettings.countryId,
          questionId,
          categoryId
        );
        commit.profiling(newProfilingState);
      }
      let newQuotasState = state.quotas;
      if (
        !newQuotasState.profiling
          .map((p) => JSON.stringify(p.keys))
          .includes(JSON.stringify([`profiling:question${questionId}`]))
      ) {
        newQuotasState = quotasSubactions.createProfilingQuotaSection(newQuotasState, questionId);
        commit.quotas(newQuotasState);
      }
      done();
    });
  }

  applyQuotaCustomPreset(quotaPreset: QuotaGroupPreset, targetingType: TargetingType) {
    this.update(async (state, commit, done) => {
      const { numberOfCompletes, numberOfStarts } = state.basicSettings;
      const { usePercentages, gender, ageSpan, region, profiling } = state.quotas;

      switch (targetingType) {
        case TargetingType.Gender: {
          const newState = quotasSubactions.createGenderQuotas(
            state.quotas,
            QuotaOptions.Custom,
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            getCompletesOrStarts(usePercentages, numberOfStarts),
            gender.ignoreCompletes,
            quotaPreset
          );
          commit.quotas(newState);
          break;
        }
        case TargetingType.Age: {
          const from = Math.min(...quotaPreset.quotas.map((q) => q.ageSpan.from));
          const to = Math.max(...quotaPreset.quotas.map((q) => q.ageSpan.to));
          const newBasicSettingsState = basicSettingsSubactions.ageChanged(state.basicSettings, {
            minAge: from,
            maxAge: to,
          });
          commit.basicSettings(newBasicSettingsState);
          const newQuotasState = quotasSubactions.createAgeQuotas(
            state.quotas,
            QuotaOptions.Custom,
            quotaPreset.quotas.map((q) => q.ageSpan),
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            getCompletesOrStarts(usePercentages, numberOfStarts),
            ageSpan.ignoreCompletes,
            { hasExtrapolatedAgeCensus: false, maxAgeInCensus: null },
            quotaPreset
          );
          commit.quotas(newQuotasState);
          break;
        }
        case TargetingType.Region: {
          targetGroupChannel.model.regions.regionTypeChanging.dispatch();
          const newRegionsState = await regionsSubactions.selectRegionsFromPreset(state.regions, quotaPreset);
          const regionIds = quotaPreset.quotas.map((q) => q.regions.map((r) => r.id));
          commit.regions(newRegionsState);
          const newQuotasState = quotasSubactions.createRegionQuotas(
            state.quotas,
            QuotaOptions.Custom,
            regionIds,
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            getCompletesOrStarts(usePercentages, numberOfStarts),
            region.ignoreCompletes,
            quotaPreset
          );
          commit.quotas(newQuotasState);
          break;
        }
        case TargetingType.Profiling: {
          const uniqueQuestionIds = uniq(quotaPreset.quotas.flatMap((q) => q.variables.map((v) => v.questionId)));
          if (uniqueQuestionIds.length !== 1) {
            throw new Error('profiling quota preset must include variables from exactly one question');
          }
          const questionId = uniqueQuestionIds[0];

          const presetIsPanelSpecific = quotaPreset.quotas.some((q) =>
            q.variables.some((v) => v.scope === ProfilingScope.PanelSpecific)
          );

          if (presetIsPanelSpecific) {
            const { panelId } = quotaPreset.quotas[0].variables[0];

            const supplyShouldBeChanged =
              state.supply.selectedIds?.length !== 1 ||
              state.supply.selectedIds[0] !== panelId ||
              !state.profiling.panelSpecificProfiling;

            if (supplyShouldBeChanged) {
              targetGroupChannel.model.quotas.quotaPresetFailed.dispatch({
                reason: QuotaPresetLoadFailureReason.SupplySetupIncorrectForPanelProfiling,
                payload: panelId,
              });
              done();
              return;
            }
          } else if (state.profiling.panelSpecificProfiling) {
            targetGroupChannel.model.quotas.quotaPresetFailed.dispatch({
              reason: QuotaPresetLoadFailureReason.SupplySetupIncorrectForGlobalProfiling,
            });
            done();
            return;
          }

          const categoryId = await api.profiling.getCategoryIdByQuestionId(
            quotaPreset.countryId,
            questionId,
            presetIsPanelSpecific
          );

          let newProfilingState = state.profiling;
          if (!newProfilingState.categories.find((c) => c.id === categoryId)?.questions?.length) {
            newProfilingState = await profilingSubactions.setActiveCategoryWithQuestionsAsync(
              newProfilingState,
              categoryId,
              quotaPreset.countryId,
              presetIsPanelSpecific ? state.supply.selectedIds[0] : null
            );
          }
          newProfilingState = profilingSubactions.selectPresetProfiling(newProfilingState, quotaPreset, categoryId);
          commit.profiling(newProfilingState);
          let newQuotasState = state.quotas;
          if (
            !profiling.map((p) => JSON.stringify(p.keys)).includes(JSON.stringify([`profiling:question${questionId}`]))
          ) {
            newQuotasState = quotasSubactions.createProfilingQuotaSection(newQuotasState, questionId);
          }

          const interlockedWithKeys = isInterlockedWith(
            [`profiling:question${questionId}`],
            newQuotasState.interlocked
          );
          if (interlockedWithKeys.length > 0) {
            newQuotasState = quotasSubactions.removeDependentInterlockedSection(
              newQuotasState,
              `profiling:question${questionId}`
            );
          }
          const variableIds = quotaPreset.quotas.map((q) => q.variables.map((v) => v.id));
          newQuotasState = quotasSubactions.createProfilingQuotas(
            newQuotasState,
            QuotaOptions.Custom,
            questionId,
            variableIds,
            getCompletesOrStarts(usePercentages, numberOfCompletes),
            getCompletesOrStarts(usePercentages, numberOfStarts),
            quotaPreset
          );
          commit.quotas(newQuotasState);
          break;
        }
        default:
          throw Error(`unsupported targeting type: '${targetingType}'`);
      }
      targetGroupChannel.model.quotas.quotaPresetLoaded.dispatch();
      done();
    });
  }

  private async selectCensusProfiling(
    profiling: ActiveProfilingModel,
    profilingCensus: ProfilingCensus[],
    countryId: number,
    questionId: number,
    categoryId: number = undefined
  ): Promise<ActiveProfilingModel> {
    const variablesToSelect = profilingCensus
      .find((c) => c.questionId === questionId)
      .quotas.flatMap((q) => q.variableIds);

    if (categoryId === undefined) {
      categoryId = await api.profiling.getCategoryIdByQuestionId(countryId, questionId);
    }

    const newProfilingState = profilingSubactions.selectCensusProfiling(
      profiling,
      categoryId,
      questionId,
      variablesToSelect
    );
    return newProfilingState;
  }

  private async createCensusProfilingQuotas(
    quotas: ActiveQuotasModel,
    profilingCensus: ProfilingCensus[],
    questionId: number,
    numberOfCompletes: number,
    numberOfStarts: number
  ): Promise<ActiveQuotasModel> {
    let newQuotasState = quotasSubactions.removeProfilingQuotaSections(quotas, questionId);
    newQuotasState = quotasSubactions.createProfilingQuotaSection(newQuotasState, questionId);
    const censusVariables = profilingCensus.find((c) => c.questionId === questionId).quotas.map((q) => q.variableIds);
    return quotasSubactions.createProfilingQuotas(
      newQuotasState,
      QuotaOptions.Completes,
      questionId,
      censusVariables,
      getCompletesOrStarts(newQuotasState.usePercentages, numberOfCompletes),
      getCompletesOrStarts(newQuotasState.usePercentages, numberOfStarts),
      undefined,
      'profiling'
    );
  }

  private update(updateFunc: QuotasUpdateFunc, preventSpinner = false): void {
    stateDebugService.logIfUnsafeUpdate('quotas');
    if (!preventSpinner) {
      this.rootStore.signalUpdateStarted();
    }

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, (preventFeasibility = false) =>
      this.rootStore.publishUpdate(preventFeasibility)
    );
  }
}
