import { isEmpty, map } from 'lodash-es';
import { SupplySource, PanelistPoolSource } from '../enums';
import { TargetGroupModel } from '../models/target-group.model';
import { createSupplyModel, SupplyMix } from '../models/supply.model';
import { Bucket, QuotaWithBuckets } from '../../target-groups/quotas/quota-bucket-builder';
import {
  mapDisabledCompletesAndStarts,
  normalizeMixedQuotas,
} from '../../target-groups/quotas/quotas-helper.functions';
import { Constants } from '../../constants';
import { PrepareProjectTargetGroup } from '../http-services/project.httpservice';
import { Money } from '../pricing/price-models';
import { UpfrontPrice } from '../../target-groups/price-quote.response';
import { panelistPoolApiInputString } from '../../target-groups/exclude-projects/ExcludePanelistPool';

export class TargetGroupMapper {
  // TODO: describe return type as a runtype
  static toDraft(targetGroups: TargetGroupModel[]) {
    return map(targetGroups, (tg) => {
      return this.toTargetGroup(tg);
    });
  }

  static toTargetGroup(targetGroup: TargetGroupModel) {
    const panelistPool =
      targetGroup.panels.panelistPool.containsUrl ||
      targetGroup.panels.panelistPool.input === panelistPoolApiInputString
        ? createSupplyModel().panelistPool
        : targetGroup.panels.panelistPool;

    return {
      name: targetGroup.name,
      country: targetGroup.basicSettings.countryName,
      countryId: targetGroup.basicSettings.countryId,
      wantedNumberOfCompletes: targetGroup.basicSettings.numberOfCompletes,
      wantedNumberOfStarts: targetGroup.basicSettings.numberOfStarts,
      startDate: targetGroup.basicSettings.startDate,
      estimatedIncidenceRate: targetGroup.basicSettings.estimatedIncidenceRate,
      estimatedLengthOfInterview: targetGroup.basicSettings.estimatedLengthOfInterview,
      gender: targetGroup.basicSettings.gender,
      minAge: targetGroup.basicSettings.minAge,
      maxAge: targetGroup.basicSettings.maxAge,
      numberOfDaysInField: targetGroup.basicSettings.numberOfDaysInField,
      useFixedLoi: targetGroup.basicSettings.useFixedLoi,
      panels: {
        selectedPanels: this.mapSelectedPanels(targetGroup),
        adHocSupplier: isEmpty(targetGroup.panels.adHocSupplier) ? null : targetGroup.panels.adHocSupplier,
        panelistPool: {
          importedPanelistIds: panelistPool.input,
          selectedGroup: panelistPool.selectedGroup,
          source: panelistPool.source || PanelistPoolSource.None,
          type: panelistPool.type,
          sourceUrl: panelistPool.sourceUrl,
          rootPoolId: panelistPool.input === Constants.externalIdsInputToken ? panelistPool.rootPoolId : undefined,
        },
        supplyMix: this.mapSupplyMix(targetGroup.panels.supplyMix),
        useCustomCpi: targetGroup.panels.useCustomCpi,
        customCpi: targetGroup.panels.useCustomCpi ? targetGroup.panels.customCpi : undefined,
      },
      quotas: {
        quotas: mapDisabledCompletesAndStarts(
          this.normalizeCompletesAndStartsInNumbers(
            targetGroup.quotas.quotas,
            targetGroup.basicSettings.numberOfCompletes,
            targetGroup.basicSettings.useStarts === true ? targetGroup.basicSettings.numberOfStarts : undefined
          ),
          targetGroup.quotas.sectionsUsingIgnoreCompletes,
          targetGroup.basicSettings.useStarts
        ),
        buckets: targetGroup.quotas.buckets,
        weightingStrategy: targetGroup.quotas.weightingStrategy,
      },
      regions: {
        regionTypeId: this.mapRegionTypeId(targetGroup),
        regionList: this.mapRegionList(targetGroup),
        mainRegionsAutomaticallySelected: targetGroup.regions.mainRegionsAutomaticallySelected,
      },
      profiling: {
        panelSpecificProfiling: targetGroup.profiling.panelSpecificProfiling,
        selectedVariables: targetGroup.profiling.selectedVariables,
      },
      excludeProjects: {
        projects: this.mapProject(targetGroup),
        respondentStatusTimelines: targetGroup.excludeProjects.respondentStatusTimelines,
      },
      incentives:
        !isEmpty(targetGroup.incentives) &&
        !isEmpty(targetGroup.incentives.fixedIncentive) &&
        !isEmpty(targetGroup.panels.ownPanelCurrency)
          ? {
              fixedIncentiveAmount: targetGroup.incentives.fixedIncentive.amount,
            }
          : undefined,
      surveyMetadata: targetGroup.surveyMetadata,
    };
  }

  // TODO: describe return type as a runtype
  static toPrepareProjectTargetGroups(
    targetGroups: TargetGroupModel[],
    price: UpfrontPrice
  ): PrepareProjectTargetGroup[] {
    return map(targetGroups, (tg) => {
      return {
        id: tg.id,
        replacesTargetGroupId: tg.replacesTargetGroupId,
        replacesProjectIds: tg.replacesProjectIds,
        name: tg.name,
        country: tg.basicSettings.countryName,
        countryId: tg.basicSettings.countryId,
        wantedNumberOfCompletes: tg.basicSettings.numberOfCompletes,
        wantedNumberOfStarts: tg.basicSettings.numberOfStarts,
        startDate: tg.basicSettings.startDate,
        estimatedIncidenceRate: tg.basicSettings.estimatedIncidenceRate,
        estimatedLengthOfInterview: tg.basicSettings.estimatedLengthOfInterview,
        cpi: price.targetGroups.find((targetGroup) => targetGroup.id === tg.id)?.cpi,
        gender: tg.basicSettings.gender,
        minAge: tg.basicSettings.minAge,
        maxAge: tg.basicSettings.maxAge,
        numberOfDaysInField: tg.basicSettings.numberOfDaysInField,
        useFixedLoi: tg.basicSettings.useFixedLoi,
        surveyMetadata: {
          studyTypes: tg.surveyMetadata?.studyTypes,
        },
        panels: {
          selectedPanels: this.mapSelectedPanels(tg),
          adHocSupplier: isEmpty(tg.panels.adHocSupplier) ? null : tg.panels.adHocSupplier,
          panelistPool: {
            rootPoolId: tg.panels.panelistPool.rootPoolId,
            selectedPoolId: tg.panels.panelistPool.selectedPoolId,
            panelIds: tg.panels.panelistPool.panelIds,
            selectedGroup: tg.panels.panelistPool.selectedGroup,
            importedPanelistIds: tg.panels.panelistPool.input,
            source: tg.panels.panelistPool.source || PanelistPoolSource.None,
            type: tg.panels.panelistPool.type,
            sourceUrl: tg.panels.panelistPool.sourceUrl,
          },
          supplyMix: this.mapSupplyMix(tg.panels.supplyMix),
          customCpi: tg.panels.useCustomCpi ? tg.panels.customCpi : undefined,
          useCustomCpi: tg.panels.useCustomCpi,
        },
        quotas: {
          quotas: this.mapQuotas(tg),
          buckets: tg.quotas.buckets as Bucket[],
          weightingStrategy: tg.quotas.weightingStrategy,
        },
        regions: {
          regionTypeId: this.mapRegionTypeId(tg),
          regionList: this.mapRegionList(tg),
          mainRegionsAutomaticallySelected: tg.regions.mainRegionsAutomaticallySelected,
        },
        profiling: {
          panelSpecificProfiling: tg.profiling.panelSpecificProfiling,
          selectedVariables: tg.profiling.selectedVariables,
        },
        excludeProjects: {
          projects: this.mapProject(tg),
          respondentStatusTimelines: tg.excludeProjects.respondentStatusTimelines,
        },
        incentives:
          tg.incentives !== undefined && tg.incentives.fixedIncentive !== undefined
            ? {
                fixedIncentiveAmount: tg.incentives.fixedIncentive.amount,
              }
            : undefined,
        ownsAllPanels:
          tg.panels.supplySource === SupplySource.OwnPanels || tg.panels.panelistPool.selectedGroup === 'ownPanels',
        ownPanelCurrency:
          tg.incentives !== undefined &&
          tg.incentives.fixedIncentive !== undefined &&
          tg.incentives.fixedIncentive.amount !== undefined
            ? tg.panels.ownPanelCurrency
            : undefined,
        requestMode: {
          includeLockedPanels: tg.requestMode.includeLockedPanels,
        },
        projectTemplateSource: tg.projectTemplateSource,
        panelistPoolContainsUrl: tg.panels.panelistPool.containsUrl,
        supplySource: tg.panels.supplySource,
      };
    });
  }

  static toTargetGroupForFeasibility(targetGroup: TargetGroupModel, cpi: Money): TargetGroupModel {
    return {
      ...targetGroup,
      cpi,
      quotas: {
        ...targetGroup.quotas,
        quotas: this.normalizeCompletesAndStartsInNumbers(
          targetGroup.quotas.quotas,
          targetGroup.basicSettings.numberOfCompletes,
          targetGroup.basicSettings.numberOfStarts
        ),
      },
    };
  }

  static toTargetGroupForPricing(targetGroup: TargetGroupModel): TargetGroupModel {
    return {
      ...targetGroup,
      quotas: {
        ...targetGroup.quotas,
        quotas: this.normalizeCompletesAndStartsInNumbers(
          targetGroup.quotas.quotas,
          targetGroup.basicSettings.numberOfCompletes,
          targetGroup.basicSettings.numberOfStarts
        ),
      },
    };
  }

  private static mapSelectedPanels(tg: TargetGroupModel) {
    return map(tg.panels.selectedIds, (panelId) => ({ id: panelId }));
  }

  private static mapRegionTypeId(tg: TargetGroupModel): number | string {
    return tg.regions.regionTypeId === undefined ? null : tg.regions.regionTypeId;
  }

  private static mapRegionList(tg: TargetGroupModel) {
    return map(tg.regions.selectedIds, (regionId) => {
      return { regionId };
    });
  }

  private static mapQuotas(tg: TargetGroupModel): QuotaWithBuckets[] {
    const normalizedQuotas = mapDisabledCompletesAndStarts(
      this.normalizeCompletesAndStartsInNumbers(
        tg.quotas.quotas,
        tg.basicSettings.numberOfCompletes,
        tg.basicSettings.useStarts ? tg.basicSettings.numberOfStarts : undefined
      ),
      tg.quotas.sectionsUsingIgnoreCompletes,
      tg.basicSettings.useStarts
    );
    return normalizedQuotas;
  }

  private static mapProject(tg: TargetGroupModel) {
    return map(tg.excludeProjects.projects, ({ projectId, respondentStatusTimelines }) => {
      return { id: projectId, respondentStatusTimelines };
    });
  }

  private static mapSupplyMix(supplyMix: SupplyMix) {
    return {
      supplyGroups: map(supplyMix.supplyGroups, (g) => ({
        id: g.id,
        name: g.name,
        panelIds: g.panelIds,
        percentage: g.percentage,
        wantedCompletes: g.wantedCompletes,
        wantedStarts: g.wantedStarts,
        source: g.source,
        customCpi: g.useCustomCpi ? g.customCpi : undefined,
      })),
    };
  }

  private static normalizeCompletesAndStartsInNumbers(
    quotas: readonly QuotaWithBuckets[],
    numberOfCompletes: number,
    numberOfStarts: number | undefined
  ): QuotaWithBuckets[] {
    return normalizeMixedQuotas(quotas, false, numberOfCompletes, numberOfStarts);
  }
}
