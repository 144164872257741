import './CollapsibleBillingInfo.scss';
import { isString } from 'lodash-es';
import { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { api } from '../api';
import { CustomCollapse } from '../controls/CustomCollapse';
import { BillingInfoResponse } from '../http-services/pricing.httpservice';
import { BillingInfo } from './BillingInfo';

const getCollapseMinHeight = (billing: BillingInfoResponse) => {
  const hasBillingAddress = isString(billing?.address) && billing.address.length > 0;
  if (hasBillingAddress) return 77;
  return 54;
};

type Props = {
  collapsible: boolean;
  projectId: number;
};

export const CollapsibleBillingInfo: React.FC<Props> = ({ collapsible, projectId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [billingInfo, setBillingInfo] = useState<BillingInfoResponse>();
  useEffect(() => {
    const getBilling = async () => {
      const res = await api.pricing.getBillingInformation(projectId);
      setBillingInfo(res.data);
    };
    getBilling();
  }, []);

  if (!collapsible) {
    return <BillingInfo billingInfo={billingInfo} />;
  }
  return (
    <div className="collapsible-billing-info col-xs-12">
      {!billingInfo && (
        <div className="billing-info panel panel-default push-down">
          <div className="panel-body wait-spinner" />
        </div>
      )}
      {billingInfo && (
        <div className="panel panel-default push-down">
          <span className="bottom-fade">
            <CustomCollapse expanded={isExpanded} minHeight={getCollapseMinHeight(billingInfo)}>
              <div className="collapse-content">
                <h2 className="col-xs-12">Billing information</h2>
                <BillingInfo billingInfo={billingInfo} />
              </div>
            </CustomCollapse>
          </span>

          <div className="panel-footer">
            <a className="text-center center-block" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Hide' : 'Show'} details
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export const ngCollapsibleBillingInfo = {
  collapsibleBillingInfo: react2angular(CollapsibleBillingInfo, ['collapsible', 'projectId']),
};
