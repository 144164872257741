import { react2angular } from 'react2angular';
import { orderBy, sortedUniq } from 'lodash-es';
import { ExistingProject } from './http-services/existing-project.models';
import { Filter, FilterResult } from './controls/filter/filter';
import { SearchAndFilter } from './controls/filter/SearchAndFilter';

interface Props {
  project: ExistingProject;
  selectedCountries: string[];
  selectedStatuses: string[];
  search: string;
  onFilter: (countries: string[], statuses: string[]) => void;
  onSearch: (search: string) => void;
}

export const TargetGroupFilterAndSearchComponent: React.FC<Props> = ({
  project,
  search,
  selectedStatuses,
  selectedCountries,
  onFilter,
  onSearch,
}) => {
  const statuses = sortedUniq(orderBy(project.targetGroups.map((tg) => tg.status as string)));
  const countries = sortedUniq(orderBy(project.targetGroups.map((tg) => tg.country)));
  const filters: Filter[] = [
    { title: 'Status', available: statuses, selected: selectedStatuses },
    { title: 'Countries', available: countries, selected: selectedCountries },
  ];

  const handleFilter = (filters: FilterResult[]) => {
    onFilter(filters.find((a) => a.title === 'Countries').selected, filters.find((a) => a.title === 'Status').selected);
  };

  return (
    <SearchAndFilter
      searchPlaceholder="Search target group name..."
      search={search}
      filters={filters}
      onFilter={handleFilter}
      onSearch={onSearch}
    />
  );
};

export const ngTargetGroupFilterAndSearchComponent = {
  targetGroupFilterAndSearchComponent: react2angular(TargetGroupFilterAndSearchComponent, [
    'project',
    'search',
    'selectedCountries',
    'selectedStatuses',
    'onFilter',
    'onSearch',
  ]),
};
