import dayjs from 'dayjs';
import { isNumber, isEmpty, isFinite, find, trimEnd, trimStart, flatMap, compact } from 'lodash-es';
import { EndPageSecurityType } from '../target-groups/project-order/ProjectOrderLinksSetup';
import { TargetGroupValidationResult } from './http-services/draft.httpservice';
import { ProjectCategory, projectCategoryService } from './project-category.service';
import { PriceTrendService } from './pricing/price-trend.service';
import {
  ProfilingCategory,
  SelectedVariables,
} from '../target-groups/active-target-group/models/active-profiling.model';
import {
  ProjectStatus,
  QuotaStatus,
  RespondentStatusTextual,
  SupplySource,
  ExcludeStatusTimeline,
  getExcludeStatusTimelineDescription,
  getExcludedRespondentStatusDescription,
  ExcludedRespondentStatus,
  Product,
  TargetGroupStatus,
  FeatureError,
} from './enums';
import { deprecationService } from '../target-groups/deprecation.service';
import { PaneName } from '../target-groups/target-group-accordion.service';
import { AlarmTypeKey, ExistingTargetGroupRegions, LatestCriticalAlarm } from './http-services/existing-project.models';
import { Iso8601Date, isUndefinedOrEmpty, isUndefinedOrNull } from '../helpers';
import { SamplingItem } from './http-services/manage.httpservice';
import { messageFromStatusMap, headerFromStatusMap, severityFromStatusMap } from '../manage/monitor/monitor-statuses';
import { AutoDeliveryError } from '../manage/monitor/sampling/auto-delivery-validation-functions';
import { Money } from './pricing/price-models';
import { ProfilingQuestionDepth } from '../target-groups/profiling/profiling-depth.service';
import { UnavailablePanelistCounts } from './http-services/supply.httpservice';
import { BasicSettingsValidationResult } from '../target-groups/basic-settings/basic-settings-validator';
import { countryService } from '../target-groups/country.service';
import { activeTargetGroupStore } from '../target-groups/active-target-group/store/active-target-group.store';
import { TargetGroupConstants } from '../target-groups/target-group.constants';
import { isPostalCodes } from '../target-groups/active-target-group/models/active-regions.model';
import { QuotaKey, QuotaKeyProfiling } from '../target-groups/active-target-group/models/active-quotas.model';
import { AgeSpan } from '../target-groups/active-target-group/models/active-target-group.model';
import { Constants } from '../constants';
import { session } from './session';
import { TargetGroupSummary } from '../target-groups/sidebar/target-group-list.service';
import { TargetGroupModel } from './models/target-group.model';
import { SamplingItemStatus } from '../manage/monitor/monitor.component';
import { featureFlipper } from './feature-flipper';
import { Variable } from './dialogs/SaveQuotaPresetDialog';

interface AlarmFormatting {
  suffix: string;
  divisor: number;
  text: string;
}

export function genderLongFormat(gender: string) {
  if (gender === 'female') return 'Female';
  return gender === 'male' ? 'Male' : 'Both';
}

export function ageSpanFormat(ageSpan: AgeSpan) {
  return isEmpty(ageSpan) ? undefined : `${ageSpan.from}-${ageSpan.to}`;
}

export function removeExtraSpaces(input: string) {
  return input.replace(/\s+/g, ' ').trim();
}

export function regionName(regionId: number): string {
  const { regions } = activeTargetGroupStore.model;
  if (isPostalCodes(regions.detailedSelection)) return '';
  const match = regions.detailedSelection.find((r) => r.id === regionId);
  return match ? match.name : undefined;
}

export function variableNames(profilingVariables: QuotaKeyProfiling): string[];
export function variableNames(profilingVariables: Variable[]): string[];

export function variableNames(profilingVariables: QuotaKeyProfiling | Variable[]): string[] {
  if (!profilingVariables.length) return [];

  const names: string[] = [];

  const questions = flatMap(activeTargetGroupStore.model.profiling.detailedSelection, (c) => c.questions);
  if (isUndefinedOrNull(questions)) return names;

  if (!isUndefinedOrNull((profilingVariables[0] as Variable).id)) {
    const variables = profilingVariables as Variable[];

    for (const variable of variables) {
      const detailedVariable = questions
        .find((q) => q.id === variable.questionId)
        ?.variables.find((v) => v.id === variable.id);
      if (isUndefinedOrNull(detailedVariable)) continue;
      names.push(detailedVariable.name);
    }
  } else {
    const quotaKeyProfiling = profilingVariables as QuotaKeyProfiling;

    for (const profiling of quotaKeyProfiling) {
      const detailedVariable = questions
        .find((q) => q.id === profiling.questionId)
        ?.variables.find((v) => v.id === profiling.variableId);
      if (isUndefinedOrNull(detailedVariable)) continue;
      names.push(detailedVariable.name);
    }
  }

  return names;
}

export const formatNumber = (num: number, replaceWithNo = true): string => {
  if (replaceWithNo && num === 0) return 'No';
  return filters.thousandSeparatorFormat()(`${num}`);
};

export const formatPlural = (num: number, word: string, pluralForm?: string): string => {
  if (num === 1) return word;
  if (pluralForm) return pluralForm;
  return `${word}s`;
};

export const pluralize = (num: number, text: string, pluralForm?: string) => {
  return `${formatNumber(num)} ${formatPlural(num, text, pluralForm)}`;
};

export const filters = {
  page() {
    return (input: any, page: any, size: any) => {
      if (input === undefined) return [];

      const first = page * size;
      const last = Math.min(first + size, input.length);
      return input.slice(first, last);
    };
  },

  projectStatus() {
    const projectStatusMap = {
      [ProjectStatus.Active]: 'Active',
      [ProjectStatus.Edit]: 'Pending',
      [ProjectStatus.Closed]: 'Closed',
    };

    return (status: ProjectStatus) => projectStatusMap[status];
  },

  serviceErrorMessage() {
    const projectStatusMap = {
      [FeatureError.Alarms]: 'alarms',
      [FeatureError.AlarmLimits]: 'suspension limits',
      [FeatureError.Statistics]: 'statistics',
      [FeatureError.TgActivityStatus]: 'activity status',
      [FeatureError.Pricing]: 'pricing',
    };

    return (errors: FeatureError[]) => {
      if (isEmpty(errors)) return '';

      const currentUnavailableFeatures = errors.map((e) => projectStatusMap[e]).join(' or ');
      return `It is currently not possible to load ${currentUnavailableFeatures} for project`;
    };
  },

  decimalToPercentage() {
    return (value: number, base?: number) => {
      return value * (base ?? 100);
    };
  },

  loiFormat() {
    return (time: any) => {
      if (time === undefined) return '';
      return time !== null ? `${time}\u00A0min` : 'N/A';
    };
  },

  percentFormat() {
    return (value: number, hideSpace?: boolean) => {
      if (value === undefined) return '';
      if (value === null) return 'N/A';
      return hideSpace === true ? `${value}%` : `${value}\u00A0%`;
    };
  },

  percentFormatWithoutSpace() {
    return (value: number) => {
      if (value === undefined) return '';
      if (value === null) return 'N/A';
      return `${value}%`;
    };
  },

  twoDecimalsFormat() {
    return (value: number): string => (isNumber(value) && value !== 0 ? value.toFixed(2) : `${value}`);
  },

  oneDecimalFormat() {
    return (value: number) => (isNumber(value) && value !== 0 ? value.toFixed(1) : 0);
  },

  onOffFormat() {
    return (value: any) => (value ? 'On' : 'Off');
  },

  yesNoFormat() {
    return (value: any) => (value ? 'Yes' : 'No');
  },

  notApplicableFormat() {
    return (value: any) => (isEmpty(value) && !isFinite(value) ? 'N/A' : value);
  },

  projectCategoryName() {
    return (categoryId: number): string => {
      if (categoryId === 23) return 'Other';
      const category = find(projectCategoryService.categories, (c) => c.id === categoryId);
      return category ? category.name : '';
    };
  },

  thousandSeparatorFormat() {
    // Regex from http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const addThousandSeparator = (value: string) => {
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
      return parts.join('.');
    };

    return (value: string) => {
      if (value === null || value === undefined) return value;
      return `${addThousandSeparator(value)}`;
    };
  },
  thousandSeparator() {
    return (value: number) => {
      if (value === null || value === undefined) return value;

      return filters.thousandSeparatorFormat()(`${value}`);
    };
  },

  formatPrice() {
    return (price: Money, includeCurrency = true): string => {
      const formatSingle = (priceValue: string, currency: string) => {
        return `${addThousandSeparator(priceValue)}\u00A0${includeCurrency ? currency : ''}`;
      };

      // Regex from http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
      const addThousandSeparator = (value: string) => {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
        return parts.join('.');
      };

      if (price == null) return '';
      if (price.value == null) return 'N/A';
      return formatSingle(price.value.toFixed(2), price.currency);
    };
  },

  priceAvailability() {
    return (value: any, completes: number, respondentCount: number) => {
      if (value == null) return value;

      if (
        completes >= PriceTrendService.threshold.completes ||
        (respondentCount >= PriceTrendService.threshold.responded && completes > 0)
      ) {
        return value;
      }

      return 'N/A';
    };
  },

  formatFeasibilityValue() {
    return (input: number) => (!isNumber(input) || input < 0 ? 'N/A' : filters.thousandSeparator()(input));
  },

  newLineFormat() {
    return (input: string) => (input ? input.replace(/\r\n|\r|\n/g, '<br/>') : '');
  },

  nonBreakingSpace() {
    return (input: string) => (input ? input.replace(/\s/g, '\u00A0') : '');
  },

  dateFormat() {
    return (date: any, includeYear = true) => {
      const formatString = includeYear ? 'D MMM YYYY' : 'D MMM';
      return date === undefined ? '' : dayjs(date).format(formatString);
    };
  },

  dateFormatWithTime() {
    return (date: Iso8601Date) => (!date ? '' : dayjs(date).format('D MMM - HH:mm'));
  },

  dateTimeFormat() {
    return (date: Iso8601Date) => (date === undefined ? '' : dayjs(date).format('D MMM YYYY HH:mm'));
  },

  timeFormat() {
    return (date: Iso8601Date) => (date === undefined ? '' : dayjs(date).format('HH:mm'));
  },

  daysDiff() {
    return (date: Iso8601Date) => {
      if (isEmpty(date)) return '';
      return dayjs(date).diff(dayjs(), 'day');
    };
  },

  dateTimeAge() {
    return (date: Iso8601Date, showTime = false, canBeInFuture = true) => {
      if (isEmpty(date)) return '';
      const today = dayjs().endOf('day');
      const currentDate = dayjs(date).endOf('day');
      // Today
      if (today.isSame(currentDate, 'day')) {
        if (showTime) return this.timeFormat()(date);
        return 'today';
      }
      const daysAgo = -currentDate.diff(today, 'day');
      if (daysAgo < 0) {
        if (canBeInFuture) {
          // Tomorrow
          if (daysAgo === -1) return 'tomorrow';
          return `in ${-1 * daysAgo} days`;
        }
        return 'Invalid date';
      }
      // Yesterday
      if (daysAgo === 1) return 'Yesterday';
      if (daysAgo <= 14) return `${daysAgo} days ago`;
      const thisYear = today.isSame(currentDate, 'year');
      return this.dateFormat()(date, !thisYear);
    };
  },

  overviewRegionFormat() {
    return (regions: ExistingTargetGroupRegions): string => {
      if (!regions || !regions.items || regions.items.length === 0) return 'No specific regions selected';
      if (regions.isPostalCodesCustomRegion) return 'Postal codes';

      return regions.items
        .map((r) => r.name)
        .sort((a, b) => (a || '').toLowerCase().localeCompare((b || '').toLowerCase()))
        .join(', ');
    };
  },

  ageSpanFormat() {
    return ageSpanFormat;
  },

  genderShortFormat() {
    return (gender: string) => {
      if (gender === 'female') return 'F';
      return gender === 'male' ? 'M' : 'F & M';
    };
  },

  genderLongFormat() {
    return genderLongFormat;
  },

  realConversionRateFormat() {
    return (conversionRate: number) => (isUndefinedOrEmpty(conversionRate) ? 'N/A' : `${conversionRate.toFixed(0)}%`);
  },

  allowedCategories() {
    return (categories: ProjectCategory[]) => {
      if (session.product === Product.ManagedServices) return categories;
      if (isEmpty(categories)) return [];
      return categories.filter((c) => !c.onlyShowForManagedServices);
    };
  },

  criticalAlarmFormatter() {
    return (alarm: LatestCriticalAlarm, country: string) => {
      const formattingMap: { [p in AlarmTypeKey]: AlarmFormatting } = {
        lengthOfInterview: {
          suffix: ' min',
          divisor: 1,
          text: 'the <em>LOI</em>',
        },
        incidenceRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'its <em>Incidence Rate</em>',
        },
        dropOutRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'its <em>Dropout Rate</em>',
        },
        conversionRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'its <em>Conversion Rate</em>',
        },
        quotaFull: {
          suffix: '%',
          divisor: 0.01,
          text: 'its <em>Quota full rate</em>',
        },
      };

      const limitToTwoSignificantDecimals = (value: number) => {
        return value
          .toFixed(2)
          .replace(/[.,]0?0$/, '')
          .replace(/([.,])(\d)0$/, '$1$2');
      };

      const formatLimit = (alarmData: LatestCriticalAlarm, formattingInfo: AlarmFormatting) => {
        if (alarmData.limitRange.lower !== null && alarmData.limitRange.lower > alarmData.triggerValue) {
          return [
            'lower than the required minimum of ',
            limitToTwoSignificantDecimals(alarmData.limitRange.lower / formattingInfo.divisor),
            formattingInfo.suffix,
          ].join('');
        }

        if (alarmData.limitRange.upper !== null && alarmData.limitRange.upper < alarmData.triggerValue) {
          return [
            'higher than the allowed maximum of ',
            limitToTwoSignificantDecimals(alarmData.limitRange.upper / formattingInfo.divisor),
            formattingInfo.suffix,
          ].join('');
        }

        return '';
      };

      const formatting = formattingMap[alarm.type];
      return [
        'This target group is on hold because ',
        formatting.text,
        ' (',
        limitToTwoSignificantDecimals(alarm.triggerValue / formatting.divisor),
        formatting.suffix,
        ') is ',
        formatLimit(alarm, formatting),
        country ? ` for ${country}` : '',
        '.',
      ].join('');
    };
  },
  alarmTooltipFormattingMap() {
    return (alarmType: AlarmTypeKey) => {
      const formattingMap: { [p in AlarmTypeKey]: AlarmFormatting } = {
        lengthOfInterview: {
          suffix: ' min',
          divisor: 1,
          text: 'Length of interview',
        },
        incidenceRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'Incidence rate',
        },
        dropOutRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'Drop out rate',
        },
        conversionRate: {
          suffix: '%',
          divisor: 0.01,
          text: 'Conversion rate',
        },
        quotaFull: {
          suffix: '%',
          divisor: 0.01,
          text: 'Quota full',
        },
      };
      return formattingMap[alarmType];
    };
  },
  alarmTooltipFormatter() {
    return (alarm: LatestCriticalAlarm, country: string) => {
      const limitToTwoSignificantDecimals = (value: number) => {
        return value
          .toFixed(2)
          .replace(/[.,]0?0$/, '')
          .replace(/([.,])(\d)0$/, '$1$2');
      };

      const formatLimit = (alarmData: LatestCriticalAlarm, formattingInfo: AlarmFormatting) => {
        if (alarmData.limitRange.lower !== null && alarmData.limitRange.lower > alarmData.triggerValue) {
          return [
            'lower than the required minimum of ',
            limitToTwoSignificantDecimals(alarmData.limitRange.lower / formattingInfo.divisor),
            formattingInfo.suffix,
          ].join('');
        }

        if (alarmData.limitRange.upper !== null && alarmData.limitRange.upper < alarmData.triggerValue) {
          return [
            'higher than the allowed maximum of ',
            limitToTwoSignificantDecimals(alarmData.limitRange.upper / formattingInfo.divisor),
            formattingInfo.suffix,
          ].join('');
        }

        return '';
      };

      const formatting = this.alarmTooltipFormattingMap()(alarm.type);
      return [formatting.text, ' is ', formatLimit(alarm, formatting), country ? ` for ${country}` : '', '.'].join('');
    };
  },

  supplySourceFormatter() {
    return (targetGroup: TargetGroupModel) => {
      const { supplySource } = targetGroup.panels;
      let summary;
      switch (supplySource) {
        case SupplySource.AdHoc:
          summary = 'Ad hoc supplier';
          break;
        case SupplySource.SystemSelected:
          summary = 'System selected set of IX panels';
          break;
        case SupplySource.SupplyMix:
          summary = 'Supply mix';
          break;
        case SupplySource.PanelistPool:
          summary = 'Specific panelists';
          break;
        default:
          summary = `${targetGroup.panels.selectedIds.length} ${this.supplySourceName()(supplySource)}`;
          break;
      }
      return summary;
    };
  },

  supplySourceFormatterShort() {
    return (supplySource: SupplySource) => {
      let summary = '';
      switch (supplySource) {
        case SupplySource.AdHoc:
          summary = 'Ad hoc';
          break;
        case SupplySource.SystemSelected:
          summary = 'IX (auto)';
          break;
        case SupplySource.CintPanels:
          summary = 'IX';
          break;
        case SupplySource.SupplyMix:
          summary = 'Supply mix';
          break;
        case SupplySource.PanelistPool:
          summary = 'Spec. panelists';
          break;
        case SupplySource.PrivatePricing:
          summary = 'Private Pricing';
          break;
        case SupplySource.OwnPanels:
          summary = 'Own panels';
          break;

        default:
          summary = supplySource;
          break;
      }
      return summary;
    };
  },

  tgSidebarTooltipFormatter() {
    return (summary: TargetGroupSummary) => {
      const ir = filters.percentFormat()(summary.estimatedIr, true);
      const loi = filters.loiFormat()(summary.estimatedLoi);
      const profiling =
        summary.selectedProfilingAttributesCount > 0
          ? `${summary.selectedProfilingAttributesCount} profiling attributes`
          : 'No profiling';
      const quotaSelection = summary.quotaCount > 0 ? 'Quotas on completes' : 'No quotas';
      const supplierMetadata = pluralize(summary.surveyMetadataTagsCount, 'supplier metadata tag');
      // Keep on separate rows to get this in tooltip
      const rows = [
        `IR: ${ir}`,
        `LOI: ${loi}`,
        summary.supplySummary,
        profiling,
        quotaSelection,
        featureFlipper.isEnabled('supplierMetadata') ? supplierMetadata : '',
      ];
      return rows.join('\n');
    };
  },

  samplingItemHeader() {
    return (item: SamplingItemStatus) => {
      if (isEmpty(item)) return '';
      return headerFromStatusMap[item];
    };
  },

  samplingItemMessage() {
    return (item: SamplingItem) => {
      if (isEmpty(item)) return '';
      return messageFromStatusMap(item.status, item.estimatedCompletes, item.quantity);
    };
  },

  samplingItemSeverity() {
    return (item: SamplingItemStatus) => {
      if (isEmpty(item)) return '';
      return severityFromStatusMap[item];
    };
  },

  selectedVariableCount() {
    return (categoryId: number, selectedVariables: SelectedVariables): number => {
      let count = 0;
      for (const variableId in selectedVariables) {
        if (selectedVariables[variableId].categoryId === categoryId) {
          count++;
        }
      }
      return count;
    };
  },

  activeCategorySummaryText() {
    return (category: ProfilingCategory): string => {
      if (isEmpty(category.questions)) return '';

      const count = category.questions.reduce((a, q) => a.concat(q.variables), []).filter((v) => v.isSelected).length;
      const countString = count === 0 ? 'No' : count.toString(10);
      return `${countString} ${count === 1 ? 'attribute' : 'attributes'} selected`;
    };
  },

  questionProfilingDepth() {
    const notAvailableMessage = 'Profiling depth: Not calculated';

    return (depth: ProfilingQuestionDepth, incompatibleSupply: boolean, loading: boolean): string => {
      if (loading) return 'Generating...';
      if (incompatibleSupply) return '\u00A0';
      if (depth === undefined) return notAvailableMessage;
      if (depth.depth === -1) return notAvailableMessage;
      if (Number.isNaN(depth.depth)) return 'Profiling depth: N/A';
      const rounded = Math.round(depth.depth);
      return `Profiling depth: ${rounded < 1 ? '<1' : rounded}%`;
    };
  },

  variableProfilingDepth() {
    return (depth: ProfilingQuestionDepth, incompatibleSupply: boolean, loading: boolean): string => {
      if (loading) return 'Generating...';
      if (incompatibleSupply) return '\u00A0';
      if (depth === undefined) return '';
      if (depth.depthSelectedVariables === undefined) return '';
      if (Number.isNaN(depth.depthSelectedVariables)) return 'N/A';
      if (depth.depthSelectedVariables === -1) return 'Selected profiling depth: Not calculated';
      const rounded = Math.round(depth.depthSelectedVariables);
      return `Selected profiling depth: ${rounded < 1 ? '<1' : rounded}%`;
    };
  },

  nonInviteablePanelistsFilter() {
    return (unavailableCounts: UnavailablePanelistCounts): string => {
      if (unavailableCounts === undefined) return '';

      const output = [];
      if (unavailableCounts.adHocCount > 0) {
        output.push('Ad hoc supply');
      }

      if (unavailableCounts.pushSupplyCount > 0) {
        output.push('Push supply');
      }

      if (unavailableCounts.lockedCount > 0) {
        output.push('Locked panels');
      }

      if (unavailableCounts.badUrl > 0) {
        output.push('Bad URLs');
      }

      return output.join('/');
    };
  },

  mailStatus() {
    return (status: string) => {
      const statuses: { [p: string]: string } = {
        NotInQueue: 'Not in queue',
        NotActivated: 'Not activated',
        WaitingToBeSent: 'Waiting to be sent',
        Sent: 'Sent',
        Deleted: 'Deleted',
        WaitingForAuthorizationNotUsed: 'Waiting for authorization - not used',
        WaitingForAuthorization: 'Waiting for authorization',
        Scheduled: 'Scheduled',
        ScheduledPreliminaryAccepted: 'Scheduled - Preliminary accepted',
        ScheduledNeedsApproval: 'Scheduled - Needs approval',
        Sending: 'Sending',
        InvalidMail: 'Invalid mail',
        InvalidTemplate: 'Invalid template',
        Answered: 'Answered',
      };
      return statuses[status];
    };
  },

  deprecatedSupplyFilter() {
    return (deprecation: TargetGroupValidationResult, supplySource: SupplySource) => {
      if (deprecation === undefined) return '';

      deprecation.visiblePanels = deprecation.visiblePanels || [];

      if (deprecationService.hasPanelistPoolSource(deprecation))
        return 'The selected panelist pool source is no longer available. Please use a supply setup which is currently available.';

      if (deprecationService.hasInclusivePanelistPoolFromApi(deprecation))
        return 'The panelist pool that you tried to target has already been used in another Target Group. Please select another. Your supply settings have been reset to default.';

      if (deprecationService.hasAdHoc(deprecation)) {
        return 'The selected Ad hoc supplier is no longer available. Please choose another Ad hoc supplier.';
      }

      if (deprecationService.hasIncompatiblePanels(deprecation)) {
        return `
          Changes to one or more of your selected panels have made your supply setup invalid.
          Please set up your supply again.
        `;
      }

      if (deprecationService.hasPanels(deprecation)) {
        const unavailablePanelNames = deprecation.unavailablePanels.join(', ');
        if (deprecation.hasPanelsFromMultipleSupplySources) {
          return `
            In order to calculate correct pricing, a target group can no longer contain multiple types of supply.
            Please set up a separate target group for each supply type.
          `;
        }
        if (supplySource !== SupplySource.PanelistPool) {
          if (deprecation.hasPanelsFromMultipleSupplySources) {
            return `
              In order to calculate correct pricing, a target group can no longer contain multiple types of supply.
              Please set up a separate target group for each supply type. Furthermore, this target group used to
              contain the following panels: ${unavailablePanelNames} which are no longer available and have been removed.
            `;
          }

          if (deprecation.unavailablePanels && deprecation.unavailablePanels.length > 0) {
            return `The panel(s) ${unavailablePanelNames} are no longer available and have been removed from the target group.`;
          }
        }
      }

      return '';
    };
  },

  paneName() {
    return (paneName: PaneName): string => {
      switch (paneName) {
        case 'basic-settings':
          return 'Basic settings';
        case 'regions':
          return 'Regions';
        case 'profiling':
          return 'Profiling';
        case 'quotas':
          return 'Quotas';
        case 'audience':
          return 'Audience';
        case 'exclusions':
          return 'Exclusions';
        case 'incentives':
          return 'Incentives';
        case 'supplier-metadata':
          return 'Supplier metadata tags';
        default:
          return '';
      }
    };
  },

  autoDeliveryErrorMessage() {
    return (error: AutoDeliveryError): string => {
      switch (error) {
        case AutoDeliveryError.None:
          return '';
        case AutoDeliveryError.EndDateHasPassedSamplingNoLongerOngoingError:
          return `
            Automatic sampling is no longer ongoing because the field period has ended.
            To continue automatic sampling, extend your field period by editing the end date.`;
        case AutoDeliveryError.EndDateHasPassedError:
          return `
            You cannot automatically invite respondents since the end date of the target group has already passed.`;
        case AutoDeliveryError.DeliveryPeriodLessThan72HourSpreadError:
          return `You cannot automatically invite respondents since the end of the delivery period
            has to be at least 72 hours in the future when using the Spread strategy.`;
        case AutoDeliveryError.HasOngoingSamplingError:
          return `You cannot automatically invite respondents while manual invites are being processed`;
        case AutoDeliveryError.EndsInLessThan24HoursFromNowSpreadError:
          return `
            You cannot automatically invite respondents since the end of the delivery period has to be at least 24 hours in the future
            when using the ASAP strategy.`;
        default:
          throw Error('Unknown auto delivery error type');
      }
    };
  },

  formatError() {
    return (error: any): string => {
      switch (error) {
        case 'TgNameNotMatching':
          return 'The Target group definition was not found. This could be a result of the target group name being changed outside Access.';
        default:
          return '';
      }
    };
  },

  trimBrackets() {
    return (text: string): string => trimEnd(trimStart(text, '['), ']');
  },

  projectOverviewUrl() {
    return (projectId: number): string => `#!/overview/${projectId}/summary`;
  },

  projectMonitorUrl() {
    return (projectId: number): string => `#!/manage/${projectId}/monitor`;
  },

  targetGroupOverviewUrl() {
    return (projectId: number, targetGroupId: number): string =>
      `#!/overview/${projectId}/target-group/${targetGroupId}`;
  },

  updateRespondentStatusesUrl() {
    return (projectId: number): string => `#!/manage/${projectId}/update-respondent-statuses`;
  },

  loadDraftUrl() {
    return (draftId: number): string => `#!/target-groups/load-draft/${draftId}`;
  },

  loadTemplateUrl() {
    return (templateId: number): string => `#!/target-groups/load-template/${templateId}`;
  },

  editTemplateUrl() {
    return (templateId: number): string => `#!/target-groups/edit-template/${templateId}`;
  },

  ceil() {
    return (value: number): number => Math.ceil(value);
  },

  respondentStatusDescription() {
    return (value: RespondentStatusTextual): string => {
      switch (value) {
        case RespondentStatusTextual.Unanswered:
          return 'Unanswered';
        case RespondentStatusTextual.Started:
          return 'Started';
        case RespondentStatusTextual.Declined:
          return 'Declined';
        case RespondentStatusTextual.EarlyScreenout:
          return 'Early screenout';
        case RespondentStatusTextual.LateScreenout:
          return 'Late screenout';
        case RespondentStatusTextual.Complete:
          return 'Complete';
        case RespondentStatusTextual.QuotaFull:
          return 'Quota full';
        case RespondentStatusTextual.Bounced:
          return 'Bounced';
        case RespondentStatusTextual.SurveyClosed:
          return 'Survey closed';
        case RespondentStatusTextual.TimedOut:
          return 'Timed out';
        case RespondentStatusTextual.Other:
          return 'Other';
        case RespondentStatusTextual.Suspicious:
          return 'Suspicious';
        case RespondentStatusTextual.BadProjectToken:
          return 'Bad project token';
        case RespondentStatusTextual.PossibleDuplicate:
          return 'Possible duplicate';
        case RespondentStatusTextual.Routed:
          return 'Routed';
        case RespondentStatusTextual.EndOfRouter:
          return 'End of router';
        case RespondentStatusTextual.Fraudulent:
          return 'Fraudulent';
        case RespondentStatusTextual.QualityTerminate:
          return 'Quality terminate';
        case RespondentStatusTextual.PreScreenStarted:
          return 'Pre-screen started';
        case RespondentStatusTextual.PreScreenTerminate:
          return 'Pre-screen terminate';
        case RespondentStatusTextual.PreScreenTimedOut:
          return 'Pre-screen timed out';
        case RespondentStatusTextual.TargetableScreenout:
          return 'Targetable screenout';
        case RespondentStatusTextual.ProfileTerminated:
          return 'Profile terminated';
        case RespondentStatusTextual.RelevantIdDuplicate:
          return 'RelevantId duplicate';
        case RespondentStatusTextual.RelevantIdBadGeoip:
          return 'RelevantId bad Geoip';
        case RespondentStatusTextual.RelevantIdFraudulent:
          return 'RelevantId fraudulent';
        case RespondentStatusTextual.RelevantIdFailure:
          return 'RelevantId failure';
        case RespondentStatusTextual.BadGeoip:
          return 'Bad Geoip';
        case RespondentStatusTextual.TechnicalTerminate:
          return 'Technical terminate';
        case RespondentStatusTextual.Unsubscribed:
          return 'Unsubscribed';
        default:
          return '';
      }
    };
  },

  statusTimelineDescription() {
    return (value: ExcludeStatusTimeline): string => getExcludeStatusTimelineDescription(value);
  },

  excludeStatusDescription() {
    return (value: ExcludedRespondentStatus): string => getExcludedRespondentStatusDescription(value);
  },

  quotaStatus() {
    const statusMap = {
      [QuotaStatus.NotStarted]: 'not started',
      [QuotaStatus.Active]: 'active',
      [QuotaStatus.Paused]: 'paused',
      [QuotaStatus.Closed]: 'closed',
    };

    return (status: QuotaStatus) => statusMap[status];
  },

  targetGroupStatus() {
    const statusMap = {
      [TargetGroupStatus.Activated]: 'Active',
      [TargetGroupStatus.Pending]: 'Pending',
      [TargetGroupStatus.Paused]: 'On Hold',
      [TargetGroupStatus.Closed]: 'Closed',
    };

    return (status: TargetGroupStatus) => statusMap[status];
  },

  endPageSecurityTypeFormat() {
    return (type: EndPageSecurityType) => {
      switch (type) {
        case EndPageSecurityType.S2S:
          return 'Server to server redirects';
        case EndPageSecurityType.ProjectSpecific:
          return 'Project specific links';
        default:
          return 'Generic links';
      }
    };
  },

  supplySourceName() {
    return (supplySource: SupplySource) => {
      switch (supplySource) {
        case SupplySource.CintPanels:
          return 'Insight Exchange panels';
        case SupplySource.OwnPanels:
          return 'Own panels';
        case SupplySource.PrivatePricing:
          return 'Private Pricing panels';
        case SupplySource.AdHoc:
          return 'Ad hoc supplier';
        case SupplySource.SupplyMix:
          return 'Supply mix';
        case SupplySource.PanelistPool:
          return 'Specific panelists';
        default:
          return 'Supply Source';
      }
    };
  },

  irErrorMessage() {
    return (validationResult: BasicSettingsValidationResult, shouldOverrideIrAndLoiCountryRestictions: boolean) => {
      if (!errorFilterEnabled(validationResult)) return '';
      if (validationResult.estimatedIncidenceRate.isValid) return '';
      if (!validationResult.countryId.isValid) return 'This field is required.';

      const countryRestrictions = countryService.getRestrictions(
        activeTargetGroupStore.model.basicSettings.countryId,
        shouldOverrideIrAndLoiCountryRestictions
      );

      const irMin = filters.percentFormat()(countryRestrictions.irMin, true);
      const irMax = filters.percentFormat()(countryRestrictions.irMax, true);

      return `The incidence rate must be between ${irMin} and ${irMax}.`;
    };
  },

  loiErrorMessage() {
    return (
      validationResult: BasicSettingsValidationResult,
      useFixedLoi: boolean,
      shouldOverrideIrAndLoiCountryRestictions: boolean
    ) => {
      if (!errorFilterEnabled(validationResult)) return '';
      if (validationResult.estimatedLengthOfInterview.isValid) return '';
      if (!validationResult.countryId.isValid) return 'This field is required.';

      const countryRestrictions = countryService.getRestrictions(
        activeTargetGroupStore.model.basicSettings.countryId,
        shouldOverrideIrAndLoiCountryRestictions
      );

      const loiMax = useFixedLoi ? TargetGroupConstants.maxLoiForFixedLoi : countryRestrictions.loiMax;

      return `The length of interview must be between 1 and ${loiMax}.`;
    };
  },

  numberOfCompletesErrorMessage() {
    return (validationResult: BasicSettingsValidationResult, suppressValidationMessages: boolean) => {
      if (!errorFilterEnabled(validationResult)) return '';
      const { errors } = validationResult.numberOfCompletes;
      if (!suppressValidationMessages && errors.required) {
        return 'To create a target group, you will need to put in the amount of completed interviews you require.';
      }
      if (errors.integer || errors.min) return 'The number of completes must be a whole number above 0.';
      if (errors.max) {
        return `The value can not be higher than ${Constants.maxNumberOfCompletes}.`;
      }

      return '';
    };
  },

  numberOfStartsErrorMessage() {
    return (validationResult: BasicSettingsValidationResult, suppressValidationMessages: boolean) => {
      if (!errorFilterEnabled(validationResult)) return '';
      if (validationResult.numberOfStarts === undefined) return '';
      const { errors } = validationResult.numberOfStarts;
      if (!suppressValidationMessages && errors.required) {
        return 'When using quotas on starts, you need to put in the amount of started interviews you require.';
      }
      if (errors.integer || errors.min)
        return 'The number of starts must be a whole number greater than or equal to the number of completes.';
      if (errors.max) {
        return `The value can not be higher than ${Constants.maxNumberOfStarts}.`;
      }

      return '';
    };
  },

  ageSpanErrorMessage() {
    return (validationResult: BasicSettingsValidationResult) => {
      if (!errorFilterEnabled(validationResult)) return '';
      const countryRestrictions = countryService.getRestrictions(
        activeTargetGroupStore.model.basicSettings.countryId,
        false
      );
      const min = validationResult.minAge.errors;
      const max = validationResult.maxAge.errors;
      if (min.required || min.integer || min.min || min.max) {
        return `Minimum age should be a whole number between ${countryRestrictions.minimumAge} and 100.`;
      }
      if (max.required || max.integer || max.min || max.max) {
        return `Maximum age should be a whole number between ${countryRestrictions.minimumAge} and 100.`;
      }
      if (min.lessThanMaxAge || max.greaterThanMinAge) {
        return 'The maximum age should be higher than the minimum age.';
      }

      return '';
    };
  },

  countryErrorMessage() {
    return (validationResult: BasicSettingsValidationResult) => {
      if (!errorFilterEnabled(validationResult)) return '';
      if (validationResult.countryId.isValid) return '';

      return 'You will need to pick a country to create a target group.';
    };
  },

  regionName() {
    return regionName;
  },

  questionName() {
    return (questionId: number): string => {
      const { profiling } = activeTargetGroupStore.model;
      const questions = flatMap(profiling.detailedSelection, (c) => c.questions);
      if (questions === undefined) return '';

      const question = questions.find((q) => q.id === questionId);
      if (question === undefined) return '';

      return question.name;
    };
  },

  quotaName() {
    const isGenderQuota = (quotaKey: QuotaKey) => quotaKey.gender !== undefined;
    const isAgeQuota = (quotaKey: QuotaKey) => quotaKey.ageSpan !== undefined;
    const isRegionQuota = (quotaKey: QuotaKey) => quotaKey.region.length;
    const isProfilingQuota = (quotaKey: QuotaKey) => quotaKey.profiling.length;

    return (key: QuotaKey): string => {
      if (key === undefined) return '';
      const name: string[] = [];
      if (isGenderQuota(key)) name.push(genderLongFormat(key.gender));
      if (isAgeQuota(key)) name.push(ageSpanFormat(key.ageSpan));
      if (isRegionQuota(key)) {
        for (const region of key.region) {
          name.push(regionName(region));
        }
      }
      if (isProfilingQuota(key)) {
        name.push(...variableNames(key.profiling));
      }

      return compact(name).join(', ');
    };
  },

  initials() {
    return (fullName: string): string => {
      if (fullName.indexOf('@') > -1) {
        fullName = fullName.substring(0, fullName.indexOf('@'));
      }
      const names = fullName.split(/[\s,_\-.]+/);
      let initials = names[0][0].toUpperCase();

      if (names.length > 1) {
        initials += names[1][0].toUpperCase();
      }
      return initials;
    };
  },
};

function errorFilterEnabled(validationResult: BasicSettingsValidationResult) {
  return (
    validationResult &&
    validationResult.estimatedIncidenceRate &&
    validationResult.numberOfCompletes &&
    validationResult.minAge &&
    validationResult.maxAge &&
    activeTargetGroupStore.model.basicSettings &&
    activeTargetGroupStore.model.basicSettings.countryId
  );
}
