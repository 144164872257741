import { module as ngModule } from 'angular';
import { RedirectToResult } from '@uirouter/core';
import { common } from '../common/common.module';
import { InitiatingAction } from '../common/enums';
import { $stateProvider, $q } from '../ngimport';
import { currentWorkService } from '../common/current-work.service';
import { featureFlipper } from '../common/feature-flipper';

export const welcome = ngModule('access.welcome', [common.name]);

welcome.config(() => {
  $stateProvider.state('welcome', {
    url: '/',
    params: { initiatingAction: InitiatingAction.Welcome },
    redirectTo: ResolveAndRedirect,
  });
});

async function ResolveAndRedirect(): Promise<RedirectToResult> {
  await $q.all([clearProjectDataOnLogin()]);
  if (featureFlipper.isEnabled('dashboard')) return { state: 'project-lists.active' };
  return { state: 'projectList' };
}

async function clearProjectDataOnLogin(): Promise<void> {
  currentWorkService.resetCurrentWork();
}
