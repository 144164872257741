import './save-draft-dialog.component.scss';
import { ui, IPromise } from 'angular';
import { capitalize, find } from 'lodash-es';
import { Transition } from '@uirouter/angularjs';
import { projectService } from '../../project.service';
import { projectSettingsService } from '../../../common/project-settings.service';
import { TargetGroupModel } from '../../../common/models/target-group.model';
import { DraftChannel, PanelistPoolType, Product } from '../../../common/enums';
import { api } from '../../../common/api';
import { modalService } from '../../../common/modal.service';
import { onEnter, onExit } from '../../../common/dialogs/dialog.controller';
import { StorageDraft } from '../../../common/target-group/target-group.repository';
import { DraftInfoResponse } from '../../../common/http-services/draft.httpservice';
import { isRuntypeError } from '../../../common/runtime-type-validating-http-provider.decorator';
import { analytics } from '../../../common/analytics';
import { SaveDraftDialogSettingsFactory } from './target-groups-sidebar-dialog-settings.factories';
import { $log } from '../../../ngimport';
import { html } from '../../../helpers';
import { targetGroupChannel } from '../../channels/target-group-channel';
import { session } from '../../../common/session';
import { panelistPoolApiInputString } from '../../exclude-projects/ExcludePanelistPool';
import { ConfirmationDialogSettingsFactory } from '../../../common/dialogs/confirmation-dialog-settings.factory';

const selector = 'saveDraftDialogComponent';

const template = html`
  <div class="save-draft-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Save draft</h1>
      <p ng-show="$ctrl.loadedDraft && $ctrl.loadedDraft.name">
        If you enter a new name, you can rename your draft, or you can save a copy of the current draft with a new name.
      </p>
      <form class="push-down" ng-submit="$ctrl.ok()">
        <input
          data-testid="save-draft-name-input"
          type="text"
          class="form-control"
          ng-model="$ctrl.draft.name"
          autofocus
          value=""
          maxlength="100"
        />
        <div class="validator-message" ng-show="$ctrl.errors.invalidName()">Enter a name for your draft project.</div>
        <div class="validator-message" ng-show="$ctrl.errors.nameNotUnique">
          Please choose a unique name for your draft project.
        </div>
        <div class="validator-message" ng-show="$ctrl.errors.saveFailed">Unable to save. Please try again later.</div>
        <div class="warning-message" ng-if="$ctrl.panelistPoolContainsUrl">
          Please note that target groups using included panelists with
          <em>unique links</em> will not have the panelists/links saved in drafts
        </div>
      </form>
      <div class="modal-controls">
        <button ng-hide="$ctrl.errors.saveFailed" class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button
          ng-hide="$ctrl.errors.saveFailed"
          class="ui-btn primary-btn"
          ng-disabled="$ctrl.errors.invalidName()"
          ng-click="$ctrl.ok()"
          data-testid="save-draft-button"
        >
          {{ $ctrl.saveButtonCaption }}
        </button>
        <button
          ng-hide="$ctrl.errors.saveFailed"
          class="ui-btn primary-btn"
          ng-disabled="$ctrl.errors.invalidName()"
          ng-if="$ctrl.allowRename"
          ng-click="$ctrl.ok(true)"
        >
          Save
        </button>
        <button ng-show="$ctrl.errors.saveFailed" class="ui-btn primary-btn" ng-click="$ctrl.dismiss()">Ok</button>
      </div>
    </div>
  </div>
`;

interface Draft {
  id: number;
  name: string;
  channel: DraftChannel;
}

export interface SaveDraftDialogResolve {
  newDraft: {
    name: string;
  };
  panelistPoolContainsUrl: boolean;
}

interface Bindings {
  resolve: '<' | SaveDraftDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class SaveDraftDialogComponent implements Bindings {
  static componentName = selector;

  resolve: SaveDraftDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  panelistPoolContainsUrl: boolean;
  draft: {
    name: string;
  };
  loadedDraft: StorageDraft;
  errors: {
    saveFailed: boolean;
    nameNotUnique: boolean;
    invalidName: () => boolean;
  };

  get allowRename(): boolean {
    return this.loadedDraft?.name && this.loadedDraft.name.trim() !== this.draft.name.trim();
  }

  get saveButtonCaption(): string {
    return !this.loadedDraft || this.loadedDraft.name.trim() === this.draft.name.trim() ? 'Save' : 'Save as new';
  }

  $onInit() {
    this.panelistPoolContainsUrl = this.resolve.panelistPoolContainsUrl;
    this.draft = this.resolve.newDraft;
    this.loadedDraft = projectService.getStorageDraft();
    this.errors = {
      saveFailed: false,
      nameNotUnique: false,
      invalidName: () => !(this.draft.name && this.draft.name.trim().length),
    };
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  async ok(shouldRename?: boolean) {
    if (this.errors.invalidName()) return;

    const tgs = projectService.targetGroups;

    let hasExclusiveApiPool = false;
    let hasInclusiveApiPool = false;

    tgs.forEach((tg) => {
      if (tg.panels.panelistPool.input === panelistPoolApiInputString) {
        if (tg.panels.panelistPool.type === PanelistPoolType.Exclusive) hasExclusiveApiPool = true;
        if (tg.panels.panelistPool.type === PanelistPoolType.Inclusive) hasInclusiveApiPool = true;
      }
    });

    if (hasExclusiveApiPool) {
      const userConfirmed = await getUserConfirmationForPool(PanelistPoolType.Exclusive, 'draft');
      if (!userConfirmed) return;
    }

    if (hasInclusiveApiPool) {
      const userConfirmed = await getUserConfirmationForPool(PanelistPoolType.Inclusive, 'draft');
      if (!userConfirmed) return;
    }

    api.draft
      .getAllDrafts()
      .then((res) => {
        const drafts = res.data;
        if (this.sameUserRole(drafts)) {
          this.validateAndCreateOrUpdate(drafts, shouldRename);
        } else {
          if (this.draftNameExists(drafts)) {
            this.errors.nameNotUnique = true;
            return;
          }
          api.draft
            .createDraft(this.draft.name, tgs, projectSettingsService.settings)
            .then((res2) => {
              this.saveStorageDraftAndClose(res2.data);
            })
            .catch(() => {
              this.errors.saveFailed = true;
            });
        }
      })
      .catch(() => {
        this.errors.saveFailed = true;
      });
  }

  private validateAndCreateOrUpdate(drafts: Draft[], shouldRename?: boolean): void {
    if (!this.nameValidationSucceeds(drafts)) {
      this.errors.nameNotUnique = true;
      return;
    }

    this.createOrUpdateDraft(projectService.targetGroups, shouldRename)
      .then((draft) => {
        targetGroupChannel.currentWorkUpdated.dispatch();
        this.saveStorageDraftAndClose(draft);
      })
      .catch((err) => {
        if (isRuntypeError(err)) {
          $log.error(err);
        } else {
          this.errors.saveFailed = true;
        }
      });
  }

  private sameUserRole(drafts: Draft[]): boolean {
    if (this.loadedDraft) {
      const draft = find(drafts, (d) => d.id === this.loadedDraft.id);
      if (draft === undefined) return true;

      const isAccessRoleDraft = draft.channel === DraftChannel.Access && session.product === Product.Access;
      const isAccessProDraft = draft.channel === DraftChannel.AccessPro && session.product === Product.AccessPro;
      const isMsDraft = draft.channel === DraftChannel.ManagedServices && session.product === Product.ManagedServices;

      return isAccessRoleDraft || isAccessProDraft || isMsDraft;
    }
    return true;
  }

  private draftNameExists(drafts: Draft[]): boolean {
    let currentDraftChannel = DraftChannel.Access;
    switch (session.product) {
      case Product.AccessPro:
        currentDraftChannel = DraftChannel.AccessPro;
        break;
      case Product.ManagedServices:
        currentDraftChannel = DraftChannel.ManagedServices;
        break;
      default:
        break;
    }
    return drafts.some((d) => d.name.trim() === this.draft.name.trim() && d.channel === currentDraftChannel);
  }

  private nameValidationSucceeds(drafts: Draft[]): boolean {
    if (this.sameDraft()) return true;
    return !this.draftNameExists(drafts);
  }

  private sameDraft(): boolean {
    return this.loadedDraft && this.loadedDraft.name === this.draft.name;
  }

  private createOrUpdateDraft(targetGroups: TargetGroupModel[], shouldRename?: boolean): IPromise<DraftInfoResponse> {
    if (!this.sameDraft() && !shouldRename)
      return api.draft
        .createDraft(this.draft.name, targetGroups, projectSettingsService.settings)
        .then((res) => res.data);

    return api.draft
      .updateDraft(this.loadedDraft, targetGroups, projectSettingsService.settings, shouldRename ? this.draft.name : '')
      .then((res) => res.data);
  }

  private saveStorageDraftAndClose(draft: StorageDraft): void {
    projectService.saveStorageDraft(draft);
    analytics.draft.save();
    this.modalInstance.close();
  }
}

export const ngSaveDraftDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: SaveDraftDialogComponent,
  },
};

export const saveDraftDialogState = {
  name: 'targetGroups.saveDraft',
  url: '/save-draft/{name:string}/{panelistPoolContainsUrl:bool}/',
  params: {
    isModalState: true,
  },
  resolve: {
    modalInstance: ($transition$: Transition) => {
      'ngInject';

      const { name, panelistPoolContainsUrl } = $transition$.params();
      return modalService.openMedium(
        SaveDraftDialogSettingsFactory.create({
          newDraft: {
            name,
          },
          panelistPoolContainsUrl: Boolean(panelistPoolContainsUrl),
        })
      );
    },
  },
  onEnter,
  onExit,
};

export async function getUserConfirmationForPool(
  poolType: PanelistPoolType,
  dialogType: 'draft' | 'template'
): Promise<boolean> {
  const forInclusive = poolType === PanelistPoolType.Inclusive;

  const modal = modalService.openMedium(
    ConfirmationDialogSettingsFactory.create({
      header: `Confirm Saving of ${capitalize(dialogType)}`,
      message: `One or more Target Groups are ${
        forInclusive ? 'targeting panelists' : 'using exclusions'
      } based on a panelist pool. ${
        forInclusive ? 'Targeting based' : 'Exclusion'
      } on panelist pools is not available when using ${dialogType}s. If you continue, your ${dialogType} will be saved without the panelist pool ${
        forInclusive ? 'targeting' : 'exclusion'
      }.`,
      callbackFactory: (modalInstance: ui.bootstrap.IModalInstanceService) => () => {
        modalInstance.close(true);
      },
      okayCaption: `Save ${dialogType} without panelist pool ${forInclusive ? 'targeting' : 'exclusion'}`,
    })
  );

  try {
    const userClickedOk = await modal.result;
    return userClickedOk;
  } catch (_) {
    return false; // The user closed the dialog, do not proceed
  }
}
