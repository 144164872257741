import { Transition } from '@uirouter/core';
import { analytics } from './analytics';
import { isUndefinedOrNull } from '../helpers';

export class StateTransitionService {
  isStateTransitioning = false;

  private count = 0;

  transitionStart(): void {
    if (++this.count > 0) {
      this.isStateTransitioning = true;
    }
  }

  transitionEnd(trans: Transition): void {
    if (--this.count <= 0) {
      if (!isUndefinedOrNull(trans.$from().name) && trans.$from().name !== '') {
        analytics.transition(trans.$to().name);
      }
      this.isStateTransitioning = false;
    }
  }
}

export const stateTransitionService = new StateTransitionService();
