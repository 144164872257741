import './project-order-links-setup.scss';
import { useRef } from 'react';
import classNames from 'classnames';
import { InlineHelp } from '../../common/inline-help/InlineHelp';
import { LabeledInput } from '../../common/controls/react/LabeledInput';
import { RedirectLinks } from '../../common/http-services/project.httpservice';
import { featureFlipper } from '../../common/feature-flipper';
import { analytics } from '../../common/analytics';

type Props = {
  disabled: boolean;
  isAddingTargetGroups: boolean;
  selectedRedirectLinks: RedirectLinks;
  selectedSecurityType: EndPageSecurityType;
  securityTypeChangeHandler: (type: EndPageSecurityType) => void;
};

const s2sTitleText = `Server to Server registration requires configuration and support in your survey tool. Verify that this is setup accordingly. Using this option will block all client redirects and only allow completes and respondent statuses to be registered through the S2S connection.`;

export enum EndPageSecurityType {
  Generic = 'generic',
  ProjectSpecific = 'projectSpecificLinks',
  S2S = 'serverToServer',
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const ProjectOrderLinksSetup: React.FC<Props> = ({
  disabled,
  isAddingTargetGroups,
  selectedRedirectLinks,
  selectedSecurityType,
  securityTypeChangeHandler: registrationMethodChangedHandler,
}: Props) => {
  const genericRadioButton = useRef<HTMLInputElement>();
  const projectSpecificRadioButton = useRef<HTMLInputElement>();
  const s2sRadioButton = useRef<HTMLInputElement>();

  const onChangeMethod = (event: any) => {
    const { value } = event.target;
    switch (value) {
      case EndPageSecurityType.Generic:
      case EndPageSecurityType.ProjectSpecific:
      case EndPageSecurityType.S2S:
        registrationMethodChangedHandler(value);
        break;
      default:
        throw new Error(`unknown registration method '${value}'`);
    }
  };

  const trackS2SReadMore = (_: React.MouseEvent) => analytics.help.visitKnowledgeBase('s2s-respondent-registration');

  return (
    <div className="project-order-links-setup">
      <div>
        <h2>{isAddingTargetGroups ? 'Redirect security method' : 'Select redirect security method'}</h2>
        <fieldset className="redirects">
          {!isAddingTargetGroups && (
            <InlineHelp id="create-project-dialog">
              <span className="help-text">
                When using the project specific or generic redirect options, your project will not start without
                redirect links. They are needed to redirect your respondents to the appropriate place once they are done
                with your survey.
              </span>
            </InlineHelp>
          )}

          {!isAddingTargetGroups && (
            <ul className="respondent-registration" onChange={onChangeMethod}>
              <li
                className={classNames('use-s2s', {
                  disabled: !featureFlipper.isEnabled('serverToServerEndLinks') || disabled,
                  'selected-security': selectedSecurityType === EndPageSecurityType.S2S,
                })}
                onClick={() => featureFlipper.isEnabled('serverToServerEndLinks') && s2sRadioButton.current.click()}
                title={
                  !featureFlipper.isEnabled('serverToServerEndLinks')
                    ? 'This method requires configuration that has not yet been set up for your company. See link below for more information'
                    : s2sTitleText
                }
              >
                <p className="method-name">Server to server (S2S) registration</p>
                <span className="method-info-container">
                  <i className="fa-solid fa-circle-exclamation" />
                  <p className="method-info-text">Requires configuration in survey tool</p>
                </span>
                <p className="security-label high">Secure</p>
                <input type="radio" ref={s2sRadioButton} name="registration-method" value={EndPageSecurityType.S2S} />
                <InlineHelp id="create-project-dialog">
                  <span className="help-text">
                    The respondent information is being registered through a server to server call using a secure API.
                  </span>
                  <a
                    href="https://cintaccess.zendesk.com/hc/en-us/articles/360052968351-Server-2-Server-Respondent-Registration"
                    target="_blank"
                    onClick={trackS2SReadMore}
                    rel="noopener noreferrer"
                  >
                    Read more
                  </a>
                </InlineHelp>
              </li>
              <li
                className={classNames('use-specific', {
                  'selected-security': selectedSecurityType === EndPageSecurityType.ProjectSpecific,
                  disabled,
                })}
                onClick={() => projectSpecificRadioButton.current.click()}
                title="Your redirect links are generated with a unique identifier. This prevents respondents from being registered as a complete without taking the survey, providing better security and quality. Supports using S2S along side."
              >
                <p className="method-name">Project specific client redirects</p>
                <span className="method-info-container">
                  <p className="method-info-text">With S2S support</p>
                </span>
                <p className="security-label medium">Medium security</p>
                <input
                  ref={projectSpecificRadioButton}
                  type="radio"
                  name="registration-method"
                  value={EndPageSecurityType.ProjectSpecific}
                />
                <InlineHelp id="create-project-dialog">
                  <span className="help-text">
                    Your redirect links are generated with a unique identifier. This prevents respondents from being
                    registered as a complete without taking the survey, providing better security and quality.
                  </span>
                </InlineHelp>
              </li>
              <li
                className={classNames('use-generic', {
                  'selected-security': selectedSecurityType === EndPageSecurityType.Generic,
                  disabled,
                })}
                onClick={() => genericRadioButton.current.click()}
                title="It is not recommended to use generic redirect links as they pose a security risk and jeopardize the quality of your survey results."
              >
                <p className="method-name">Generic client redirects</p>
                <span className="method-info-container">
                  <p className="method-info-text">With S2S support</p>
                </span>
                <p className="security-label low">Not secure</p>
                <input
                  ref={genericRadioButton}
                  type="radio"
                  name="registration-method"
                  value={EndPageSecurityType.Generic}
                />
              </li>
            </ul>
          )}
          {!featureFlipper.isEnabled('serverToServerEndLinks') && (
            <span className="enable-s2s-text">
              To enable support for S2S,&nbsp;
              <a
                href="https://cintaccess.zendesk.com/hc/en-us/articles/360052968351-Server-2-Server-Respondent-Registration"
                target="_blank"
                onClick={trackS2SReadMore}
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </span>
          )}
          {selectedSecurityType === EndPageSecurityType.Generic && (
            <div className="warning-message">
              <span className="fa fa-exclamation-triangle warning-triangle" />
              <div className="dark-warning-text">
                It is <b>not recommended</b> to use generic redirect links as they pose a security risk and jeopardize
                the quality of your survey results. <br />
                For more information and recommendations please reach out to your Cint representative, Support, or read:
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://cint-demand-api.readme.io/docs/respondents-response-statuses"
                >
                  Respondents & Response Statuses
                </a>
              </div>
            </div>
          )}
          {[EndPageSecurityType.ProjectSpecific, EndPageSecurityType.Generic].includes(selectedSecurityType) && (
            <div className="redirect-links row">
              <h2 className="col-xs-12">
                {isAddingTargetGroups ? 'Redirect links' : 'Add the following redirect links to your survey tool'}
              </h2>
              <div className="col-xs-4">
                <LabeledInput
                  verticalOrientation
                  type="text"
                  name="quotaFull"
                  value={selectedRedirectLinks?.quotaFull}
                  title={selectedRedirectLinks?.quotaFull}
                  label="Quota full"
                  maxLength={255}
                  readonly
                  buttonVisible={navigator.clipboard?.writeText !== undefined}
                  buttonClick={() => copyToClipboard(selectedRedirectLinks?.quotaFull)}
                  buttonIcon="fa fa-clipboard"
                  buttonFeedback={{
                    iconClicked: 'fa fa-clipboard-check',
                    iconClickedText: 'Copied',
                  }}
                  buttonTitle="Copy to clipboard"
                />
              </div>
              <div className="col-xs-4">
                <LabeledInput
                  verticalOrientation
                  type="text"
                  name="earlyScreenOut"
                  value={selectedRedirectLinks?.earlyScreenout}
                  title={selectedRedirectLinks?.earlyScreenout}
                  label="Screen out"
                  maxLength={255}
                  readonly
                  buttonVisible={navigator.clipboard?.writeText !== undefined}
                  buttonClick={() => copyToClipboard(selectedRedirectLinks?.earlyScreenout)}
                  buttonIcon="fa fa-clipboard"
                  buttonFeedback={{
                    iconClicked: 'fa fa-clipboard-check',
                    iconClickedText: 'Copied',
                  }}
                  buttonTitle="Copy to clipboard"
                />
              </div>
              <div className="col-xs-4">
                <LabeledInput
                  verticalOrientation
                  type="text"
                  name="complete"
                  value={selectedRedirectLinks?.complete}
                  title={selectedRedirectLinks?.complete}
                  label="Complete"
                  maxLength={255}
                  readonly
                  buttonVisible={navigator.clipboard?.writeText !== undefined}
                  buttonClick={() => copyToClipboard(selectedRedirectLinks?.complete)}
                  buttonIcon="fa fa-clipboard"
                  buttonFeedback={{
                    iconClicked: 'fa fa-clipboard-check',
                    iconClickedText: 'Copied',
                  }}
                  buttonTitle="Copy to clipboard"
                />
              </div>
            </div>
          )}
        </fieldset>
      </div>
    </div>
  );
};
