import './labeled-input.control.scss';
import { useEffect, useRef } from 'react';
import { TargetGroupConstants } from '../../../target-groups/target-group.constants';
import { FeedbackButton } from './FeedbackButton';

interface Props {
  ['data-testid']?: string;
  type: string;
  name: string;
  value: string;
  label?: string;
  verticalOrientation?: boolean;
  title?: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxLength: number;
  onChange?: (value: string) => void;
  buttonVisible?: boolean;
  buttonClick?: () => void;
  buttonIcon?: string;
  buttonTitle?: string;
  buttonFeedback?: {
    iconClicked: string;
    iconClickedText: string;
  };
}

const getValidationMessage = ({ required, value, type }: Props) => {
  // undefined is the 'untouched' value, so do not display validation unless the user typed something
  if (value === undefined) return undefined;
  if (required && !value) {
    return 'This is a required field.';
  }
  if (type === 'email' && !TargetGroupConstants.validEmailRegex.test(value)) {
    return 'Enter a valid email adress';
  }
  return undefined;
};

export const LabeledInput: React.FC<Props> = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = (value: string) => {
    if (props.onChange) props.onChange(value);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.setCustomValidity(getValidationMessage(props) ?? '');
  });

  const validationMessage = getValidationMessage(props);

  return (
    <div className="labeled-input-control">
      <div className="row">
        {props.label && (
          <div className={props.verticalOrientation ? 'col-xs-12' : 'col-xs-4'}>
            <label>
              {props.label}
              {props.required && <span className="mandatory-input-marker">*</span>}
            </label>
          </div>
        )}
        <div className={props.verticalOrientation ? 'col-xs-12' : 'col-xs-8'}>
          <div className={props.buttonVisible ? 'input-group' : ''}>
            <input
              data-testid={props['data-testid'] ?? ''}
              ref={inputRef}
              title={props.title}
              disabled={props.disabled}
              readOnly={props.readonly}
              type={props.type}
              maxLength={props.maxLength}
              value={props.value ?? ''}
              onChange={(e) => handleChange(e.target.value)}
              name={props.name}
              required={props.required}
              autoComplete="off"
              autoFocus
            />
            {props.buttonVisible && (
              <span className="input-group-btn">
                {props.buttonFeedback && (
                  <FeedbackButton
                    onClick={props.buttonClick}
                    disabled={props.disabled}
                    className="ui-btn default-btn"
                    title={props.buttonTitle}
                    icon={props.buttonIcon}
                    iconClicked={props.buttonFeedback.iconClicked}
                    iconClickedText={props.buttonFeedback.iconClickedText}
                  />
                )}
                {!props.buttonFeedback && (
                  <button
                    onClick={props.buttonClick}
                    disabled={props.disabled}
                    className="ui-btn default-btn"
                    title={props.buttonTitle}
                    type="button"
                  >
                    <span className={props.buttonIcon} />
                  </button>
                )}
              </span>
            )}
          </div>
        </div>
        {validationMessage && !props.verticalOrientation && (
          <div className="col-xs-8 col-xs-offset-4">
            <div className="validation-message">{validationMessage}</div>
          </div>
        )}
        {validationMessage && props.verticalOrientation && (
          <div className="col-xs-12">
            <div className="validation-message">{validationMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};
