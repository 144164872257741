import { ui } from 'angular';
import { html } from './helpers';

const selector = 'reloadApplicationDialogComponent';

const template = html`
  <div class="reload-application-dialog-component">
    <div class="modal-container">
      <h1 class="modal-header">New version detected</h1>
      <p>The application has been updated with new features and needs to be reloaded.</p>
      <div class="modal-controls">
        <button class="ui-btn primary-btn min-width-btn" ng-click="$ctrl.reload()">Reload</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class ReloadApplicationDialogComponent implements Bindings {
  static componentName = selector;
  modalInstance: ui.bootstrap.IModalInstanceService;

  reload(): void {
    this.modalInstance.close();
  }
}

export const ngReloadApplicationDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ReloadApplicationDialogComponent,
  },
};
