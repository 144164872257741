import { Draft } from 'immer';

import { find, includes, remove } from 'lodash-es';
import { ActiveExcludeProjectsModel, ExcludedProject } from '../../models/active-exclude-projects.model';
import { ExcludedRespondentStatus, ExcludeStatusTimeline } from '../../../../common/enums';

export const mutators = {
  setAllProjects:
    (projects: ExcludedProject[], paginationToken: string) => (state: Draft<ActiveExcludeProjectsModel>) => {
      state.paginationToken = paginationToken;
      state.projects = projects.map((p) => ({
        ...p,
        selected: includes(state.selectedProjectIds, p.id),
      }));
    },

  setSelectedProjects: (projects: ExcludedProject[]) => (state: Draft<ActiveExcludeProjectsModel>) => {
    state.selectedProjects = projects;
    for (const project of state.selectedProjects) {
      project.selected = true;
    }
  },
  toggleProject: (projectId: number) => (state: Draft<ActiveExcludeProjectsModel>) => {
    // The user cannot change project specific status exclusion, they are always active:
    if (state.projectStatusesToExclude[projectId]?.length > 0) return;

    const project = find(state.projects, (p) => p.id === projectId);

    if (project) project.selected = !project.selected;

    if (project?.selected) {
      state.selectedProjectIds.push(projectId);
    } else {
      remove(state.selectedProjectIds, (el) => el === projectId);
    }
  },

  toggleStatus: (statusValue: ExcludedRespondentStatus) => (state: Draft<ActiveExcludeProjectsModel>) => {
    const status = find(state.statusesToExclude, (s) => s.value === statusValue);
    if (!status) {
      state.statusesToExclude.push({
        value: statusValue,
        timeline: ExcludeStatusTimeline.TwelveMonths,
      });
    } else {
      remove(state.statusesToExclude, (s) => s.value === statusValue);
    }
  },

  setTimelineForStatus:
    (statusValue: ExcludedRespondentStatus, timeline: ExcludeStatusTimeline) =>
    (state: Draft<ActiveExcludeProjectsModel>) => {
      const status = find(state.statusesToExclude, (s) => s.value === statusValue);
      if (status) {
        status.timeline = timeline;
        if (timeline === ExcludeStatusTimeline.None) {
          remove(state.statusesToExclude, (s) => s.value === statusValue);
        }
      } else {
        if (timeline === ExcludeStatusTimeline.None) return;
        state.statusesToExclude.push({ value: statusValue, timeline });
      }
    },

  removeExclusion: () => (state: Draft<ActiveExcludeProjectsModel>) => {
    state.selectedProjectIds = [];
    // The user cannot change project specific status exclusion, they are always active
    state.selectedProjectIds.push(...Object.keys(state.projectStatusesToExclude).map(Number));
    state.selectedProjects = [];
    state.statusesToExclude = [];
    for (const project of state.projects) {
      // The user cannot change project specific status exclusion, they are always active
      if (state.projectStatusesToExclude[project.id]?.length > 0) continue;
      project.selected = false;
    }
  },

  clearAllClosedProjects: () => (state: Draft<ActiveExcludeProjectsModel>) => {
    state.projects = [];
  },
};
