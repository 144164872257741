import { Draft } from 'immer';
import { SurveyMetadataModel } from '../../../../common/models/survey-metadata.model';

export const mutators = {
  setSurveyTags: (tags: string[]) => (state: Draft<SurveyMetadataModel>) => {
    state.studyTypes = tags;
  },
  clearSurveyTags: () => (state: Draft<SurveyMetadataModel>) => {
    state.studyTypes = [];
  },
};
