import { ui } from 'angular';
import { DeveloperToolsDialogComponent } from './developer-tools-dialog.component';

export class DeveloperToolsDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'developer-tools-dialog',
      keyboard: false,
      backdrop: false,
      component: DeveloperToolsDialogComponent.componentName,
    };
  }
}
