import { Draft } from 'immer';
import { isEmpty, uniq, includes, map, find, some, isNumber, difference, union } from 'lodash-es';
import {
  ActiveSupplyModel,
  PanelSources,
  ActivePanelistPool,
  SelectedAdHocSupplier,
  EligiblePanelSourceKeys,
  ActiveSupplyGroup,
  Panel,
} from '../../models/active-supply.model';
import { SupplySource, PanelistPoolSource, PanelistPoolType, DisqualifiedPanelReason } from '../../../../common/enums';
import { Constants } from '../../../../constants';
import {
  AvailabilityResultResponse,
  AvailablePanelistGroups,
  PanelistGroup,
  CreatePanelistPoolResponse,
} from '../../../../common/http-services/supply.httpservice';
import { keys } from '../../../../helpers';
import { ErrorModel } from '../../../validation-errors';
import { normalize } from '../../../../common/normalize';
import { getPanelSourcesWithSelectedPanels } from '../../../supply/supply-helper.functions';
import { SupplyGroupUpdate } from '../../../supply/supply-mix/supply-mix-state.service';
import { panelistPoolApiInputString } from '../../../exclude-projects/ExcludePanelistPool';

export const mutators = {
  resetPanelistPool: (isConnectedUser: boolean, supplySource?: SupplySource) => (state: Draft<ActiveSupplyModel>) => {
    state.panelistPool = {
      input: '',
      createPoolResult: {} as CreatePanelistPoolResponse,
      availabilityResult: {} as AvailabilityResultResponse,
      source: supplySource === SupplySource.PanelistPool && !isConnectedUser ? PanelistPoolSource.AccessProject : null,
      panelistCount: 0,
      rootPoolId: Constants.emptyGuid,
      selectedPoolId: Constants.emptyGuid,
      panelIds: [],
      selectedGroup: '',
      hasAvailablePanelists: true, // should it really be true by default?
      type: PanelistPoolType.None,
      containsUrl: false,
      sourceUrl: null,
    };
  },

  setCustomCpi: (customCpi: number | undefined) => (state: Draft<ActiveSupplyModel>) => {
    state.customCpi = customCpi;
  },

  setIgnoreCompletesForSupplyMix: (ignoreCompletes: boolean) => (state: Draft<ActiveSupplyModel>) => {
    state.supplyMix.ignoreCompletes = ignoreCompletes;
  },

  clearCustomCpi: () => (state: Draft<ActiveSupplyModel>) => {
    state.customCpi = undefined;
    state.useCustomCpi = false;
  },

  setOwnPanelCurrency: () => (state: Draft<ActiveSupplyModel>) => {
    state.ownPanelCurrency = getOwnPanelCurrency(state);
  },

  setSelectedPrivatePricingRateCard: (rateCard: string) => (state: Draft<ActiveSupplyModel>) => {
    state.selectedPrivatePricingRateCard = rateCard;
  },

  setInternalPanelistPool:
    (
      poolSource: PanelistPoolSource,
      panelistEntries: string,
      panelistPoolType: PanelistPoolType,
      createPoolResult: CreatePanelistPoolResponse,
      containsUrl: boolean
    ) =>
    (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.createPoolResult = createPoolResult;
      state.panelistPool.source = poolSource;
      state.panelistPool.input = panelistEntries;
      state.panelistPool.type = panelistPoolType;
      state.panelistPool.rootPoolId = createPoolResult.poolId;
      state.panelistPool.containsUrl = containsUrl;
      state.panelistPool.sourceUrl = null;
    },

  setFromPanelistPoolApi:
    (poolSource: PanelistPoolSource, panelistPoolId: string, panelistPoolType: PanelistPoolType) =>
    (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.createPoolResult = {
        poolId: panelistPoolId,
        committedCount: 0,
        invalidIdCount: 0,
        duplicationCount: 0,
        receivedCount: 0,
      };
      state.panelistPool.source = poolSource;
      state.panelistPool.input = panelistPoolApiInputString;
      state.panelistPool.type = panelistPoolType;
      state.panelistPool.rootPoolId = panelistPoolId;
      state.panelistPool.containsUrl = false;
      state.panelistPool.sourceUrl = null;
    },

  setInternalPanelistPoolForConnectPool:
    (
      poolSource: PanelistPoolSource,
      panelistEntries: string,
      panelistPoolType: PanelistPoolType,
      rootPoolId: string,
      selectedGroup: keyof AvailablePanelistGroups | '',
      containsUrl: boolean
    ) =>
    (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.source = poolSource;
      state.panelistPool.input = panelistEntries;
      state.panelistPool.type = panelistPoolType;
      state.panelistPool.rootPoolId = rootPoolId;
      state.panelistPool.containsUrl = containsUrl;
      state.panelistPool.sourceUrl = null;
      state.panelistPool.selectedGroup = selectedGroup;
      state.panelistPool.selectedPoolId = state.panelistPool.rootPoolId;
    },

  setPanelistPoolForExternalIdsSource:
    (
      poolSource: PanelistPoolSource,
      panelistEntries: string,
      panelistPoolType: PanelistPoolType,
      rootPoolId: string,
      selectedGroup: keyof AvailablePanelistGroups | '',
      containsUrl: boolean
    ) =>
    (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.source = poolSource;
      state.panelistPool.input = panelistEntries;
      state.panelistPool.type = panelistPoolType;
      state.panelistPool.rootPoolId = rootPoolId;
      state.panelistPool.containsUrl = containsUrl;
      state.panelistPool.sourceUrl = null;
      state.panelistPool.selectedGroup = selectedGroup;
    },

  setInternalPanelistPoolExternalIds:
    (createPoolResult: CreatePanelistPoolResponse) => (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.createPoolResult = createPoolResult;
    },

  setExternalPanelistPool:
    (url: string, poolId: string, panelistPoolType: PanelistPoolType) => (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.source = PanelistPoolSource.AccessProject;
      state.panelistPool.sourceUrl = url;
      state.panelistPool.rootPoolId = poolId;
      state.panelistPool.type = panelistPoolType;
    },

  setSelectedPanelistPool:
    (poolId: string, panelIds: number[], availabilityGroup: keyof AvailablePanelistGroups) =>
    (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.selectedPoolId = poolId;
      state.panelistPool.panelIds = [...panelIds];
      state.panelistPool.selectedGroup = availabilityGroup;
      if (state.panelistPool.type === PanelistPoolType.Inclusive) {
        state.panelistPool.panelistCount = isEmpty(state.panelistPool.selectedGroup)
          ? 0
          : state.panelistPool.availabilityResult.availablePanelists[state.panelistPool.selectedGroup].panelistCount;
      }
      state.includeLockedPanels = hasLockedPanels(
        (state.panelistPool.availabilityResult.availablePanelists as any)[state.panelistPool.selectedGroup].panels
      );
    },

  selectRootPool: (panelistPool: ActivePanelistPool) => (state: Draft<ActiveSupplyModel>) => {
    const groups: AvailablePanelistGroups = panelistPool.availabilityResult.availablePanelists;
    const unavailable: PanelistGroup = panelistPool.availabilityResult.unavailablePanelists;

    const panels = uniq([
      ...map(groups.opinionHub.panels, (p) => p.id),
      ...map(groups.ownPanels.panels, (p) => p.id),
      ...map(groups.privatePricing.panels, (p) => p.id),
      ...map(unavailable.panels, (p) => p.id),
    ]);

    mutators.setSelectedPanelistPool(panelistPool.rootPoolId, panels, 'opinionHub')(state);
  },

  // TODO: Maybe it's a bit confusing that this is doing so much more
  selectSupplySource: (supplySource: SupplySource) => (state: Draft<ActiveSupplyModel>) => {
    state.supplySource = supplySource;
    state.adHoc = {} as SelectedAdHocSupplier;
    state.selectedIds = [];
    state.adHocSuppliers = [];
    state.languages = [];
    state.supplyMix.supplyGroups = [];
    state.supplyMix.ignoreCompletes = false;
    state.includeLockedPanels = false;
  },

  setPanelistPoolSupplySource: () => (state: Draft<ActiveSupplyModel>) => {
    state.supplySource = SupplySource.PanelistPool;
  },

  selectNonDisqualifiedPanels: () => (state: Draft<ActiveSupplyModel>) => {
    const selectedPanelSources = getPanelSourcesWithSelectedPanels(state) as Draft<PanelSources>;
    state.selectedIds = [];
    for (const key of keys(selectedPanelSources)) {
      const panelIdsToSelect = selectedPanelSources[key].filter((p) => !p.isDisqualified).map((p) => p.id);
      state.selectedIds.push(...panelIdsToSelect);
    }
    if (isEmpty(state.selectedIds)) {
      state.supplySource = SupplySource.SystemSelected;
    }
  },

  resetLoiDisqualifiedPanels: () => (state: Draft<ActiveSupplyModel>) => {
    const selectedPanelSources = getPanelSourcesWithSelectedPanels(state) as Draft<PanelSources>;
    for (const key of keys(selectedPanelSources)) {
      for (const panel of selectedPanelSources[key]) {
        if (includes(panel.disqualifiedPanelReasons, DisqualifiedPanelReason.Loi)) {
          panel.isDisqualified = false;
        }
      }
    }
  },

  setValidationResult: (validationResult: ErrorModel) => (state: Draft<ActiveSupplyModel>) => {
    state.validationResult = validationResult;
  },

  setSupplyGroupPercentage:
    (supplyGroupId: number, percentage: number, wantedCompletes: number, wantedStarts: number | undefined) =>
    (state: Draft<ActiveSupplyModel>) => {
      const supplyGroup = state.supplyMix.supplyGroups.find((s) => s.id === supplyGroupId);
      supplyGroup.percentage = percentage;
      supplyGroup.wantedCompletes = wantedCompletes;
      supplyGroup.wantedStarts = wantedStarts;
    },

  rescaleSupplyGroupsWantedCompletes: (totalCompletes: number) => (state: Draft<ActiveSupplyModel>) => {
    rescaleSupplyGroupsWantedCompletesOrStarts(state, totalCompletes, 'wantedCompletes');
  },

  rescaleSupplyGroupWantedStarts: (totalStarts: number) => (state: Draft<ActiveSupplyModel>) => {
    rescaleSupplyGroupsWantedCompletesOrStarts(state, totalStarts, 'wantedStarts');
  },

  setSupplyGroupCustomCpi: (supplyGroupId: number, newCcpi: number) => (state: Draft<ActiveSupplyModel>) => {
    const supplyGroup = state.supplyMix.supplyGroups.find((g) => g.id === supplyGroupId);
    supplyGroup.customCpi = newCcpi;
  },

  setAdhocSuppliers: (adhocSuppliers: { id: number; name: string }[]) => (state: Draft<ActiveSupplyModel>) => {
    state.adHocSuppliers = adhocSuppliers;
    for (const s of state.adHocSuppliers) {
      s.isSelected = state.adHoc && s.id === state.adHoc.id;
    }
  },

  setAdhocLanguages: (adhocLanguages: { id: number; name: string }[]) => (state: Draft<ActiveSupplyModel>) => {
    state.languages = adhocLanguages;
  },

  deselectAllFromSupplyType: (activeSupplySource: SupplySource) => (state: Draft<ActiveSupplyModel>) => {
    const sourcePanels = getSourcePanels(state, activeSupplySource);
    state.selectedIds = difference(
      state.selectedIds,
      sourcePanels.map((p) => p.id)
    );
  },

  selectAllAvailableFromSupplyType: (activeSupplySource: SupplySource) => (state: Draft<ActiveSupplyModel>) => {
    const sourcePanels = getSourcePanels(state, activeSupplySource);
    const panelIdsToSelect = sourcePanels.filter((p) => !p.isDisqualified && !p.isLocked).map((p) => p.id);
    state.selectedIds = union(state.selectedIds, panelIdsToSelect);
  },

  setPanelistPoolAvailability:
    (availabilityResult: AvailabilityResultResponse) => (state: Draft<ActiveSupplyModel>) => {
      state.panelistPool.availabilityResult = availabilityResult;
      state.panelistPool.panelistCount = sumPanelists(
        state.panelistPool.availabilityResult,
        state.panelistPool.type === PanelistPoolType.Exclusive
      );
      state.panelistPool.hasAvailablePanelists = state.panelistPool.panelistCount > 0;
    },

  setPanelSources: (panelSources: PanelSources) => (state: Draft<ActiveSupplyModel>) => {
    state.panelSources = panelSources;
  },

  updatePanelsState: (selectedRateCard: string) => (state: Draft<ActiveSupplyModel>) => {
    state.ownPanelCurrency = getOwnPanelCurrency(state);
    state.selectedPrivatePricingRateCard = selectedRateCard;
    state.includeLockedPanels = hasAnyLockedPanels(state);
  },

  togglePanel: (panelId: number, activeSuppylSource: SupplySource) => (state: Draft<ActiveSupplyModel>) => {
    const sourcePanels = state.panelSources[activeSuppylSource as EligiblePanelSourceKeys];
    const panel = find(sourcePanels, (p) => p.id === panelId);
    if (!panel) return;
    const index = state.selectedIds.indexOf(panel.id);
    if (index > -1) {
      state.selectedIds.splice(index, 1);
    } else {
      state.selectedIds.push(panel.id);
    }
  },

  setPanelSelection: (panelIds: number[], selected: boolean) => (state: Draft<ActiveSupplyModel>) => {
    if (selected) {
      state.selectedIds = union(state.selectedIds, panelIds);
    } else {
      state.selectedIds = difference(state.selectedIds, panelIds);
    }
  },

  setAdHocLanguage: (languageId: number) => (state: Draft<ActiveSupplyModel>) => {
    state.adHoc.languageId = languageId;
  },

  toggleAdhoc: (supplierId: number) => (state: Draft<ActiveSupplyModel>) => {
    for (const s of state.adHocSuppliers) {
      s.isSelected = s.id === supplierId && (!state.adHoc || s.id !== state.adHoc.id);
    }

    const toggledSupplier = find(state.adHocSuppliers, (s) => s.isSelected);
    state.adHoc.id = toggledSupplier ? toggledSupplier.id : undefined;
  },

  addSupplyMixGroup: (supplyGroup: ActiveSupplyGroup) => (state: Draft<ActiveSupplyModel>) => {
    state.supplyMix.supplyGroups.push({
      ...supplyGroup,
      name: supplyGroup.name.trim(),
    });
    state.selectedIds = union(state.selectedIds, supplyGroup.panelIds);
  },

  removeSupplyMixGroup: (supplyGroupId: number, numberOfCompletes: number) => (state: Draft<ActiveSupplyModel>) => {
    const groups = state.supplyMix.supplyGroups;
    const currentGroup = groups.find((g) => g.id === supplyGroupId);
    state.supplyMix.supplyGroups = state.supplyMix.supplyGroups.filter((g) => g !== currentGroup);
    state.selectedIds = difference(state.selectedIds, currentGroup.panelIds);

    if (groups.reduce((acc, g) => acc + g.percentage, 0) === 1) {
      normalize(numberOfCompletes, state.supplyMix.supplyGroups, 'wantedCompletes');
    }
  },

  updateSupplyMixGroup: (supplyGroupUpdate: SupplyGroupUpdate) => (state: Draft<ActiveSupplyModel>) => {
    const groups = state.supplyMix.supplyGroups;
    const currentGroup = groups.find((g) => g.id === supplyGroupUpdate.id);
    currentGroup.name = supplyGroupUpdate.name;
    const removedPanelIds = difference(currentGroup.panelIds, supplyGroupUpdate.panelIds);
    currentGroup.panelIds = supplyGroupUpdate.panelIds;
    currentGroup.useCustomCpi = supplyGroupUpdate.useCustomCpi;
    currentGroup.customCpi = supplyGroupUpdate.customCpi;
    currentGroup.ccpiSettings = supplyGroupUpdate.ccpiSettings;
    state.selectedIds = union(difference(state.selectedIds, removedPanelIds), supplyGroupUpdate.panelIds);
  },

  setAdHocSupplySource: () => (state: Draft<ActiveSupplyModel>) => {
    state.adHoc = state.adHoc || ({} as SelectedAdHocSupplier);
    state.supplySource = SupplySource.AdHoc;
  },

  toggleUseCustomCpi: () => (state: Draft<ActiveSupplyModel>) => {
    state.useCustomCpi = !state.useCustomCpi;
  },
};

function rescaleSupplyGroupsWantedCompletesOrStarts(
  state: Draft<ActiveSupplyModel>,
  totalCount: number,
  property: 'wantedCompletes' | 'wantedStarts'
): void {
  const rescaledGroups = state.supplyMix.supplyGroups.map((group) => ({
    ...group,
    [property]: Math.ceil(totalCount * group.percentage) / 100,
  }));
  const normalizedSupplyGroups = normalize(totalCount, rescaledGroups, property);
  state.supplyMix.supplyGroups = normalizedSupplyGroups;
}

function getOwnPanelCurrency(state: Draft<ActiveSupplyModel>): string | undefined {
  const ownPanels = state.panelSources[SupplySource.OwnPanels];
  if (isEmpty(ownPanels)) return undefined;

  const selected = ownPanels.filter((p) => includes(state.selectedIds, p.id));
  if (isEmpty(selected)) return undefined;

  const currencies = uniq(selected.map((p) => p.currencyCode));
  return currencies.length === 1 ? currencies[0] : undefined;
}

function getSourcePanels(state: Draft<ActiveSupplyModel>, activeSupplySource: SupplySource) {
  return state.panelSources[activeSupplySource as EligiblePanelSourceKeys] || [];
}

function sumPanelists(availabilityResult: AvailabilityResultResponse, includeUnavailable: boolean): number {
  if (isEmpty(availabilityResult)) return 0;
  let total = 0;
  for (const key of keys(availabilityResult.availablePanelists)) {
    const count = availabilityResult.availablePanelists[key].panelistCount;
    if (isNumber(count)) {
      total += count;
    }
  }
  if (includeUnavailable) {
    total += availabilityResult.unavailablePanelists.panelistCount;
  }
  return total;
}

function hasAnyLockedPanels(state: Draft<ActiveSupplyModel>): boolean {
  const { panelSources } = state;
  if (isEmpty(panelSources)) return false;

  const filterSelectedPanels = (panels: Panel[]): Panel[] => panels.filter((p) => includes(state.selectedIds, p.id));

  return hasLockedPanels({
    cintPanels: filterSelectedPanels(panelSources[SupplySource.CintPanels]),
    ownPanels: filterSelectedPanels(panelSources[SupplySource.OwnPanels]),
    privatePricing: filterSelectedPanels(panelSources[SupplySource.PrivatePricing]),
  });
}

function hasLockedPanels(panelSources: { [key: string]: Panel[] }): boolean {
  for (const key of keys(panelSources)) {
    if (some(panelSources[key], (p) => p.isLocked)) return true;
  }

  return false;
}
