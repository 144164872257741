import { zipObject, range } from 'lodash-es';
import { FeatureKey } from '../app-settings';
import { RuntimeSettings } from '../runtime-settings';

export interface EnabledFeatures {
  [p: string]: boolean;
}

export class FeatureFlipper {
  get features(): EnabledFeatures {
    return zipObject(
      RuntimeSettings.features,
      range(RuntimeSettings.features.length).map(() => true)
    );
  }

  disableFeature(key: FeatureKey): void {
    this.features[key] = false;
  }

  enableFeature(key: FeatureKey): void {
    this.features[key] = true;
  }

  isEnabled(key: FeatureKey): boolean {
    return this.features[key];
  }

  name(key: FeatureKey): string {
    return key;
  }
}

export const featureFlipper = new FeatureFlipper();
