import { module as ngModule } from 'angular';
import { common } from '../common/common.module';
import { ProjectListComponent, ngProjectListComponent, TabIndex } from './project-list.component';
import { ngDraftsComponent } from './drafts.component';
import { ngProjectsComponent } from './projects.component';
import { ngProjectFiltersComponent } from './project-filters/project-filters.component';
import { ngProjectListItemComponent } from './project-list-item/project-list-item.component';
import { $stateProvider } from '../ngimport';
import { ngDeleteTemplateDialogComponent } from './templates/delete-template-dialog.component';
import { ngTemplateHistoryDialogComponent } from './templates/template-history-dialog.component';
import { countryService } from '../target-groups/country.service';

export const projectList = ngModule('access.projectList', [common.name]);

projectList.config(() => {
  $stateProvider
    .state('projectList', {
      url: '/project-list',
      params: { tabIndex: TabIndex.Active },
      views: {
        main: {
          component: ProjectListComponent.componentName,
        },
      },
      resolve: {
        _: async () => {
          await countryService.init();
        },
      },
    })
    .state('projectList.templates', {
      url: '/templates',
      params: { tabIndex: TabIndex.Templates },
      views: {
        main: {
          component: ProjectListComponent.componentName,
        },
      },
    })
    .state('projectList.closed', {
      url: '/closed',
      params: { tabIndex: TabIndex.Closed },
      views: {
        main: {
          component: ProjectListComponent.componentName,
        },
      },
    })
    .state('projectList.drafts', {
      url: '/drafts',
      params: { tabIndex: TabIndex.Drafts },
      views: {
        main: {
          component: ProjectListComponent.componentName,
        },
      },
    });
});

projectList.component(ngProjectListComponent);
projectList.component(ngDraftsComponent);
projectList.component(ngProjectsComponent);
projectList.component(ngProjectFiltersComponent);
projectList.component(ngProjectListItemComponent);
projectList.component(ngDeleteTemplateDialogComponent);
projectList.component(ngTemplateHistoryDialogComponent);
