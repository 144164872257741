import { Draft } from 'immer';
import { createActiveRegionsModel } from '../models/active-regions.model';
import { createActiveProfilingModel } from '../models/active-profiling.model';
import { createActiveExcludeProjectsModel } from '../models/active-exclude-projects.model';
import { createActiveSupplyModel } from '../models/active-supply.model';
import { ActiveTargetGroupModel, ActiveIdentityModel, Census } from '../models/active-target-group.model';

export const mutators = {
  partialReset: () => (state: Draft<ActiveTargetGroupModel>) => {
    // don't reset bs and quotas yet
    state.census = {} as Census;
    state.regions = createActiveRegionsModel();
    state.profiling = createActiveProfilingModel();
    state.excludeProjects = createActiveExcludeProjectsModel();
    state.incentives.fixedIncentive = {
      amount: undefined,
    };
    state.supply = createActiveSupplyModel();
  },

  renameTargetGroup: (newName: string) => (state: Draft<ActiveIdentityModel>) => {
    state.name = newName;
  },
};
