import { groupBy, intersection, uniq } from 'lodash-es';
import {
  filterInterlockedQuotas,
  filterProfilingQuotas,
  filterRegionQuotas,
  parseQuestionIdFromProfilingQuotaKeys,
} from '../../target-groups/quotas/quotas-helper.functions';
import { Constants } from '../../constants';
import { QuotaGroupNames } from '../../target-groups/active-target-group/models/active-quotas.model';
import { keys } from '../../helpers';
import { ProjectTemplateTargetGroup } from '../http-services/draft.httpservice';

export function getSectionsUsingIgnoreCompletesFromReused(reusedTargetGroup: ProjectTemplateTargetGroup) {
  if (reusedTargetGroup.quotas?.quotas === null) return [];
  const quotasGroupedBySection = groupBy(reusedTargetGroup.quotas.quotas, (q) => q.keys);
  const sectionsUsingIgnoreCompletes: (readonly string[])[] = [];
  for (const key of keys(quotasGroupedBySection)) {
    if (quotasGroupedBySection[key].every((q) => q.wantedCompletes === null)) {
      sectionsUsingIgnoreCompletes.push(key.split(','));
    }
  }
  return sectionsUsingIgnoreCompletes;
}

export function getQuotaGroupNamesFromReused(reusedTargetGroup: ProjectTemplateTargetGroup): QuotaGroupNames {
  const startEndBracketsRegex = new RegExp(/^\[+|\]+$/g);
  if (reusedTargetGroup.quotas?.quotas === null) return undefined;

  const { quotas, buckets } = reusedTargetGroup.quotas;
  const groupNames: QuotaGroupNames = { region: {}, profiling: {} };

  const regionPrefix = Constants.regionQuotaGroupPrefix;
  const profilingPrefix = (keys: string[]) =>
    Constants.profilingQuotaGroupPrefix(parseQuestionIdFromProfilingQuotaKeys(keys));

  const customNamedProfilingQuotas = quotas
    .filter(filterProfilingQuotas)
    .filter((q) => q.name.includes(profilingPrefix(q.keys)));
  const customNamedRegionQuotas = quotas.filter(filterRegionQuotas).filter((q) => q.name.includes(regionPrefix));
  const customNamedInterlockedQuotas = quotas.filter(filterInterlockedQuotas);

  const bucketVariablesByGroupName: { [name: string]: number[][] } = {};

  for (const quota of customNamedInterlockedQuotas) {
    const allBucketsVariables = buckets.filter((b) => quota.buckets.includes(b.id)).map((b) => b.key.profiling);
    const profiling = intersection<number>(...allBucketsVariables);
    if (profiling.length > 1) {
      const parts = quota.name.split(']:[');
      const questionIds = quota.keys
        .filter((k) => k.startsWith('profiling'))
        .map((k) => parseQuestionIdFromProfilingQuotaKeys([k]));
      const nameParts = questionIds
        .map((id) => parts.find((p) => p.includes(Constants.profilingQuotaGroupPrefix(id))))
        .filter((p) => p);
      for (const name of nameParts) {
        const fixedName = name.replace(startEndBracketsRegex, '');
        const value = bucketVariablesByGroupName[fixedName];
        if (!value) {
          bucketVariablesByGroupName[fixedName] = quota.buckets.map(
            (bucketId) => buckets.find((b) => b.id === bucketId)?.key.profiling
          );
        } else {
          bucketVariablesByGroupName[fixedName] = [
            ...value,
            ...quota.buckets.map((bucketId) => buckets.find((b) => b.id === bucketId)?.key.profiling),
          ];
        }
      }
    }
    const region = uniq(buckets.filter((b) => quota.buckets.includes(b.id)).flatMap((b) => b.key.region));

    if (region.length > 1) {
      const parts = quota.name.split(']:[');
      const name = parts.find((p) => p.includes(regionPrefix));
      if (name) {
        groupNames.region[JSON.stringify(region)] = name.replace(startEndBracketsRegex, '');
      }
    }
  }

  for (const quota of customNamedProfilingQuotas) {
    const allBucketsVariables = buckets.filter((b) => quota.buckets.includes(b.id)).map((b) => b.key.profiling);
    const profiling = intersection<number>(...allBucketsVariables);
    groupNames.profiling[JSON.stringify(profiling)] = quota.name.replace(startEndBracketsRegex, '');
  }

  addInterlockedProfilingGroupNames(bucketVariablesByGroupName, groupNames);

  for (const quota of customNamedRegionQuotas) {
    const region = uniq(buckets.filter((b) => quota.buckets.includes(b.id)).flatMap((b) => b.key.region));
    groupNames.region[JSON.stringify(region)] = quota.name.replace(startEndBracketsRegex, '');
  }
  return groupNames;
}

function addInterlockedProfilingGroupNames(
  bucketVariablesByGroupName: { [name: string]: number[][] },
  groupNames: QuotaGroupNames
) {
  for (const name of keys(bucketVariablesByGroupName)) {
    if (bucketVariablesByGroupName[name].length > 1) {
      const common = intersection<number>(...bucketVariablesByGroupName[name]);
      groupNames.profiling[JSON.stringify(common)] = name;
    }
  }
}
