import { IHttpPromise, IDeferred } from 'angular';
import { Record, Boolean, Union, Literal, Static, Array, Null, String } from 'runtypes';
import { urlService } from './url.service';
import { AboveZero, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { ProjectChannel } from '../enums';
import { $http, $q } from '../../ngimport';
import { ProjectListFilterOptions } from '../../project-list/project-filters/project-filters.component';
import { featureFlipper } from '../feature-flipper';

export const ProjectResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  owner: NonEmptyString,
  startDate: Iso8601UtcString,
  endDate: Iso8601UtcString,
  actualNumberOfCompletes: NonNegative,
  neededNumberOfCompletes: NonNegative,
  hasUnhandledRequests: Boolean,
  hasTargetGroupsWithLowSurveyLinkSource: Boolean,
  hasTargetGroupsWithoutSurveyLinkSource: Boolean,
  channel: Union(
    Literal(ProjectChannel.Access),
    Literal(ProjectChannel.AccessPro),
    Literal(ProjectChannel.ManagedServices),
    Literal(ProjectChannel.Test),
    Literal(ProjectChannel.Unknown),
    Literal(ProjectChannel.Api),
    Literal(ProjectChannel.Track)
  ),
});
export type ProjectResponse = Static<typeof ProjectResponse>;

export const PagedProjectListResponse = Record({
  totalCount: NonNegative,
  pageSize: NonNegative,
  descending: Boolean,
  sortField: NonEmptyString, //TODO: Use Literals
  startIndex: NonNegative,
  filterText: String.Or(Null),
  items: Array(ProjectResponse),
});

export type PagedProjectListResponse = Static<typeof PagedProjectListResponse>;

export const ProjectModel = Record({
  id: AboveZero,
  name: NonEmptyString,
  channel: Union(
    Literal(ProjectChannel.Access),
    Literal(ProjectChannel.AccessPro),
    Literal(ProjectChannel.ManagedServices),
    Literal(ProjectChannel.Test),
    Literal(ProjectChannel.Unknown),
    Literal(ProjectChannel.Api),
    Literal(ProjectChannel.Track)
  ),
});

export type ProjectModel = Static<typeof ProjectModel>;

export const ProjectsByIdsResponse = Record({
  projects: Array(ProjectModel),
});
export type ProjectsByIdsResponse = Static<typeof ProjectsByIdsResponse>;

export const ProjectForExclusion = Record({
  id: AboveZero,
  name: NonEmptyString,
  channel: Union(
    Literal(ProjectChannel.Access),
    Literal(ProjectChannel.AccessPro),
    Literal(ProjectChannel.ManagedServices),
    Literal(ProjectChannel.Test),
    Literal(ProjectChannel.Unknown),
    Literal(ProjectChannel.Api),
    Literal(ProjectChannel.Track)
  ),
  startDate: Iso8601UtcString,
});
export type ProjectForExclusion = Static<typeof ProjectForExclusion>;

export const ProjectsForExclusionResponse = Record({
  paginationToken: String.Or(Null),
  projects: Array(ProjectForExclusion),
});
export type ProjectsForExclusionResponse = Static<typeof ProjectsForExclusionResponse>;

export class ProjectListHttpService {
  cancelers = {
    closedProjectStatistics: undefined as IDeferred<any>,
  };

  getProjectsForExclusion(
    countryId?: number,
    fromDate?: string,
    toDate?: string,
    textFilter?: string,
    idFilter?: string,
    paginationToken?: string
  ): IHttpPromise<ProjectsForExclusionResponse> {
    if (this.cancelers.closedProjectStatistics !== undefined) {
      this.cancelers.closedProjectStatistics.resolve();
    }
    this.cancelers.closedProjectStatistics = $q.defer();

    if (featureFlipper.isEnabled('dashboard')) {
      return $http.validatingGet(
        ProjectsForExclusionResponse,
        urlService.filterProjectListForExclusionPaginated(
          countryId,
          paginationToken,
          fromDate,
          toDate,
          textFilter,
          idFilter
        )
      );
    }

    return paginationToken
      ? $http.validatingGet(ProjectsForExclusionResponse, urlService.paginateProjectListForExclusion(paginationToken))
      : $http.validatingGet(
          ProjectsForExclusionResponse,
          urlService.filterProjectListForExclusion(countryId, fromDate, toDate, textFilter, idFilter)
        );
  }

  getProjectNames(ids: number[]) {
    return $http.validatingPost(ProjectsByIdsResponse, urlService.getProjectNamesByIds(), { projectIds: ids });
  }

  getProjectListPage(
    index: number,
    pageSize: number,
    sortAndFilterOptions: ProjectListFilterOptions
  ): IHttpPromise<PagedProjectListResponse> {
    return $http.validatingGet(
      PagedProjectListResponse,
      urlService.getProjectListPage(index, pageSize, sortAndFilterOptions)
    );
  }
}

export const projectListHttpService = new ProjectListHttpService();
