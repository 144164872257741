import { ProjectPrice } from '../common/pricing/price-models';

class InfieldPriceService {
  projectPrice: ProjectPrice;

  persist = (newPrice: ProjectPrice) => {
    this.projectPrice = newPrice;
  };
}

export const infieldPriceService = new InfieldPriceService();
