import './search-input.component.scss';
import { useState, useEffect } from 'react';

interface Props {
  ['data-testid']?: string;
  searchString: string;
  placeholder: string;
  onSearch: (search: string) => void;
  disabled?: boolean;
}

export const SearchInput: React.FC<Props> = ({
  searchString,
  placeholder,
  onSearch,
  'data-testid': testId,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState<string>(searchString);
  const clearSearch = () => {
    setInputValue('');
    onSearch('');
  };

  useEffect(() => {
    setInputValue(searchString);
  }, [searchString]);

  const onSearchInternal = (t: string) => {
    setInputValue(t);
    onSearch(t);
  };
  return (
    <div className="search-input-component">
      <i className="fal fa-search search-icon" />
      <input
        type="text"
        placeholder={placeholder}
        data-testid={testId}
        className="ui-control search-input"
        value={inputValue}
        onChange={(input) => onSearchInternal(input.target.value)}
        disabled={Boolean(disabled)}
      />
      {inputValue && (
        <i className="fas fa-times-circle clear-icon" data-testid={`${testId}-clear-search`} onClick={clearSearch} />
      )}
    </div>
  );
};
