import { indexOf, find, max } from 'lodash-es';

export class TargetGroupSorter {
  sortOrder: number[] = [];

  loadWith(numbers: number[]): void {
    this.sortOrder = numbers;
  }

  add(sortOrderId: number): void {
    this.sortOrder.push(sortOrderId);
  }

  insert(sortOrderId: number, after: number): void {
    const index = indexOf(this.sortOrder, after) + 1;
    this.sortOrder.splice(index, 0, sortOrderId);
  }

  remove(sortOrderId: number | string): void {
    const index = indexOf(this.sortOrder, sortOrderId);
    this.sortOrder.splice(index, 1);
  }

  findHighestNumber(offset = 0): number {
    return offset + (this.sortOrder.length === 0 ? 0 : max(this.sortOrder));
  }

  sort<T>(array: T[], propFn: (value: T) => number): T[] {
    const orderedArray = [];

    for (const sortOrderId of this.sortOrder) {
      const found = find(array, (x) => propFn(x) === sortOrderId);
      orderedArray.push(found);
    }
    return orderedArray;
  }

  clear() {
    this.sortOrder = [];
  }
}
