import { isEmpty } from 'lodash-es';
import { TargetGroupModel, ProjectTemplateSource } from '../models/target-group.model';
import { SupplySource, PanelistPoolType } from '../enums';
import { FeasibilityProjectSettings } from '../target-group/survey-links/survey-links-dialog.component';
import { FeasibilityOptions, GlobalFeasibilityOptions } from '../global-feasibility-options';
import { QuotaWithBuckets, BucketKey } from '../../target-groups/quotas/quota-bucket-builder';
import { WeightingStrategy } from '../models/quotas.model';
import { ProjectWithStatusExclude } from '../models/exclude-projects.model';
import { ExcludedStatusWithTimeline } from './existing-project.models';
import { Money } from '../pricing/price-models';

export interface FeasibilityTargetGroup {
  countryId: number;
  gender: string;
  minAge: number;
  maxAge: number;
  cpi: Money;
  wantedNumberOfCompletes: number;
  estimatedIncidenceRate: number;
  estimatedLengthOfInterview: number;
  startDate: string;
  numberOfDaysInField: number;
  panelIds: number[];
  useCustomCpi: boolean;
  poolId: string;
  panelistPoolType: PanelistPoolType;
  adHocSupplier: any;
  panelSpecificProfiling: boolean;
  regionIds: number[];
  targetGroupId: number;
  profilingVariables: any[];
  ownsAllPanels: boolean;
  buckets: { id: number; name: string; bucketKey: BucketKey }[];

  quotas: readonly QuotaWithBuckets[];
  weightingStrategy: WeightingStrategy;
  excludeProjects: {
    projects: ProjectWithStatusExclude[];
    respondentStatusTimelines: ExcludedStatusWithTimeline[];
  };
  requestMode: {
    includeLockedPanels: boolean;
  };
  projectTemplateSource: ProjectTemplateSource;
}
export class FeasibilityRequest {
  projectSettings: FeasibilityProjectSettings;
  targetGroup: FeasibilityTargetGroup;
  feasibilityOptions: FeasibilityOptions;

  constructor(
    targetGroupId: number,
    targetGroup: TargetGroupModel,
    projectSettings: FeasibilityProjectSettings,
    feasibilityOptions?: GlobalFeasibilityOptions
  ) {
    if (targetGroup !== undefined) {
      this.targetGroup = {
        countryId: targetGroup.basicSettings.countryId,
        cpi: targetGroup.cpi,
        gender: targetGroup.basicSettings.gender,
        minAge: targetGroup.basicSettings.minAge,
        maxAge: targetGroup.basicSettings.maxAge,
        wantedNumberOfCompletes: targetGroup.basicSettings.numberOfCompletes,
        estimatedIncidenceRate: targetGroup.basicSettings.estimatedIncidenceRate,
        estimatedLengthOfInterview: targetGroup.basicSettings.estimatedLengthOfInterview,
        startDate: targetGroup.basicSettings.startDate,
        numberOfDaysInField: targetGroup.basicSettings.numberOfDaysInField,
        panelIds: targetGroup.panels.selectedIds || [],
        useCustomCpi: targetGroup.panels.useCustomCpi,
        poolId: targetGroup.panels.panelistPool.selectedPoolId,
        panelistPoolType: targetGroup.panels.panelistPool.type,
        adHocSupplier: isEmpty(targetGroup.panels.adHocSupplier) ? null : targetGroup.panels.adHocSupplier,
        panelSpecificProfiling: targetGroup.profiling.panelSpecificProfiling,
        regionIds: targetGroup.regions.selectedIds || [],
        targetGroupId,
        profilingVariables: Object.keys(targetGroup.profiling.selectedVariables).map((id) => ({ id: Number(id) })),
        ownsAllPanels:
          targetGroup.panels.supplySource === SupplySource.OwnPanels ||
          targetGroup.panels.panelistPool.selectedGroup === 'ownPanels',
        buckets: targetGroup.quotas.buckets.map((b) => {
          return {
            id: b.id,
            name: b.name,
            bucketKey: {
              gender: b.key.gender,
              ageSpan: b.key.ageSpan,
              region: b.key.region,
              supply: b.key.supply,
              profiling: b.key.profiling,
            },
          };
        }),
        quotas: targetGroup.quotas.quotas,
        weightingStrategy: targetGroup.quotas.weightingStrategy,
        excludeProjects: {
          projects: targetGroup.excludeProjects.projects,
          respondentStatusTimelines: targetGroup.excludeProjects.respondentStatusTimelines,
        },
        requestMode: {
          includeLockedPanels: targetGroup.requestMode.includeLockedPanels,
        },
        projectTemplateSource: targetGroup.projectTemplateSource,
      };
    }
    this.feasibilityOptions = feasibilityOptions
      ? feasibilityOptions.options
      : {
          useFakeFeasibility: false,
          availabilityRatio: undefined,
          disableDropoutRateTransform: undefined,
          useCustomAvailabilityRatio: undefined,
        };
    this.projectSettings = {
      categoryIds: projectSettings.categoryIds,
      filterOnPii: projectSettings.filterOnPii,
    };
  }
}
