import { QuotaGroupNames } from '../../target-groups/active-target-group/models/active-quotas.model';
import { Bucket, QuotaWithBuckets } from '../../target-groups/quotas/quota-bucket-builder';

export enum WeightingStrategy {
  EvenDistribution = 'evenDistribution',
  RimWeighting = 'rimWeighting',
}

export interface QuotasModel {
  readonly quotas: readonly QuotaWithBuckets[];
  readonly buckets: readonly Bucket[];
  readonly usePercentages: boolean;
  readonly weightingStrategy: WeightingStrategy;
  readonly sectionsUsingPanelDistribution: readonly (readonly string[])[];
  readonly sectionsUsingIgnoreCompletes: readonly (readonly string[])[];
  readonly quotaGroupNames: QuotaGroupNames;
}

export function createQuotasModel(): QuotasModel {
  return {
    quotas: [],
    buckets: [],
    usePercentages: false,
    weightingStrategy: WeightingStrategy.EvenDistribution,
    sectionsUsingPanelDistribution: [],
    sectionsUsingIgnoreCompletes: [],
    quotaGroupNames: undefined,
  };
}

export function createQuotasModelFromActive(
  quotas: readonly QuotaWithBuckets[],
  buckets: readonly Bucket[],
  usePercentages = false,
  weightingStrategy: WeightingStrategy = WeightingStrategy.EvenDistribution,
  sectionsUsingPanelDistribution: readonly (readonly string[])[] = [],
  sectionsUsingIgnoreCompletes: readonly (readonly string[])[] = [],
  quotaGroupNames: QuotaGroupNames = undefined
): QuotasModel {
  return {
    quotas,
    buckets,
    usePercentages,
    weightingStrategy:
      weightingStrategy === WeightingStrategy.RimWeighting
        ? WeightingStrategy.RimWeighting
        : WeightingStrategy.EvenDistribution,
    sectionsUsingPanelDistribution,
    sectionsUsingIgnoreCompletes,
    quotaGroupNames,
  };
}
