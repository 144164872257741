import './TargetGroupPrice.scss';
import { react2angular } from 'react2angular';
import { TargetGroupStatus } from '../../common/enums';
import { ExistingTargetGroup } from '../../common/http-services/existing-project.models';
import { InternalPriceResponse, TargetGroupPriceResponse } from '../../common/http-services/project-price.response';
import { PriceDetails } from '../../common/pricing/PriceDetails';

interface Props {
  price: TargetGroupPriceResponse;
  internalPrice: InternalPriceResponse;
  targetGroup: ExistingTargetGroup;
}
export const TargetGroupPrice: React.FC<Props> = ({ targetGroup, price, internalPrice }) => {
  const internalTargetGroupPrice = internalPrice?.targetGroupPrices.find(
    (internalTgPrice) => internalTgPrice.targetGroupId === targetGroup.id
  );
  return (
    <div className="col-xs-12 target-group-price-component">
      <PriceDetails
        price={price}
        internalPrice={internalTargetGroupPrice}
        isClosed={targetGroup.status === TargetGroupStatus.Closed}
        isPending={targetGroup.status === TargetGroupStatus.Pending}
        responded={targetGroup.responded}
        completes={targetGroup.actualNumberOfCompletes}
        estimatedIr={targetGroup.estimatedIncidenceRate}
        estimatedLoi={targetGroup.estimatedLengthOfInterview}
        helpTextId="overview-target-group"
        helpTexts={{
          projected: 'The price the target group would cost if all wanted completes are collected',
          initial: 'The price the target group was ordered at',
          current: 'What the target group would cost if closed now, based on its Actual IR and Actual LOI',
        }}
      />
    </div>
  );
};

export const ngTargetGroupPriceReactComponent = {
  targetGroupPrice: react2angular(TargetGroupPrice, ['targetGroup', 'price', 'internalPrice']),
};
