import { Draft } from 'immer';
import { find, isEmpty, includes } from 'lodash-es';
import { ActiveRegionsModel, RegionType, Region } from '../../models/active-regions.model';
import { RegionTypeKey } from '../../../../common/enums';
import { TargetGroupConstants } from '../../../target-group.constants';

export const mutators = {
  setRegionType: (regionTypeId: number, type: RegionTypeKey) => (state: Draft<ActiveRegionsModel>) => {
    state.selectedRegionType = {
      id: regionTypeId,
      type,
    };
  },

  discardRegionType: () => (state: Draft<ActiveRegionsModel>) => {
    state.selectedIds = [];
    state.postalCodes = [];
    state.mainRegionsAutomaticallySelected = false;
    for (const regionType of state.regionTypes) {
      regionType.regions = undefined;
    }
    state.selectedRegionType = {} as RegionType;
    state.detailedSelection = [];
  },

  addPostalCodes: (postalCodes: string[], regionIds: number[]) => (state: Draft<ActiveRegionsModel>) => {
    const postalCodesRegionType = find(state.regionTypes, { id: TargetGroupConstants.postalCodesRegionTypeId });

    postalCodesRegionType.regions = postalCodes;
    state.postalCodes = postalCodes;
    state.selectedIds = regionIds;
    state.detailedSelection = [
      { id: TargetGroupConstants.postalCodesRegionTypeId, name: 'Postal codes', mapId: undefined },
    ];
  },

  toggleRegion: (regionId: number) => (state: Draft<ActiveRegionsModel>) => {
    state.mainRegionsAutomaticallySelected = false;

    const { regions } = state.regionTypes.find((rt) => rt.id === state.selectedRegionType.id);
    for (const region of regions) {
      if (typeof region === 'string')
        throw new Error('tried to set postal codes region as selected. should not happen');

      if (region.id === regionId) {
        region.isSelected = !region.isSelected;
      }
    }
    state.selectedIds = (regions as Region[]).filter((r) => r.isSelected).map((r) => r.id);

    state.detailedSelection = getSelectedRegions(state);
  },

  setRegionsSelectedState: (isSelected: boolean, regionIds?: number[]) => (state: Draft<ActiveRegionsModel>) => {
    state.mainRegionsAutomaticallySelected = false;

    const allRegions = state.regionTypes.find((rt) => rt.id === state.selectedRegionType.id).regions;
    let regionsToSetState = allRegions;
    if (regionIds !== undefined && regionIds.length > 0 && typeof allRegions[0] !== 'string') {
      regionsToSetState = (allRegions as Region[]).filter((r) => regionIds.includes(r.id));
    }
    for (const region of regionsToSetState) {
      if (typeof region === 'string')
        throw new Error('tried to set postal codes region as selected. should not happen');

      region.isSelected = isSelected;
    }
    state.selectedIds = (allRegions as Region[]).filter((r) => r.isSelected).map((r) => r.id);

    state.detailedSelection = getSelectedRegions(state);
  },

  clearSelectedRegionType: () => (state: Draft<ActiveRegionsModel>) => {
    state.selectedRegionType = {} as RegionType;
  },

  setSelectedIds: (selectedIds: number[]) => (state: Draft<ActiveRegionsModel>) => {
    state.selectedIds = selectedIds;
  },

  setRegions: (regionTypeId: number, regions: Region[]) => (state: Draft<ActiveRegionsModel>) => {
    const selectedRegionType = state.regionTypes.find((rt) => rt.id === regionTypeId);
    selectedRegionType.regions = regions;

    if (!isEmpty(state.selectedIds)) {
      for (const region of selectedRegionType.regions) {
        if (typeof region === 'string')
          throw new Error('tried to set postal codes region as selected. should not happen');
        region.isSelected = includes(state.selectedIds, region.id);
      }
    }
    state.detailedSelection = getSelectedRegions(state);
  },

  setSelectedRegionType: (regionTypeId: number) => (state: Draft<ActiveRegionsModel>) => {
    const selectedRegionType = state.regionTypes.find((rt) => rt.id === regionTypeId);
    state.selectedRegionType = { id: regionTypeId, type: selectedRegionType.type };
  },
};

function getSelectedRegions(state: Draft<ActiveRegionsModel>) {
  const selectedRegionType = find(state.regionTypes, (rt) => rt.id === state.selectedRegionType.id);
  return (selectedRegionType.regions as Region[]).filter((region) => region.isSelected);
}
