import './options-menu.component.scss';
import { html } from '../../../helpers';

export enum MenuOptionType {
  View = 'view far fa-eye',
  DownloadPanelistData = 'download data far fa-arrow-down-to-bracket',
  DownloadReport = 'download report far fa-arrow-down-to-bracket',
  Monitor = 'monitor far fa-desktop',
  Delete = 'delete far fa-trash-alt',
  Edit = 'edit fas fa-pencil-alt',
  Create = 'create fas fa-plus',
  Template = 'template fas fa-clipboard-list',
  Duplicate = 'duplicate far fa-clone',
  DuplicateWithLinks = 'duplicate fas fa-link',
  History = 'history fas fa-history',
  ManageGroups = 'groups far fa-layer-group',
}
export interface MenuListItem {
  id: number;
  name: string;
  type: MenuOptionType;
  hidden?: boolean;
  url?: string;
  disabled?: boolean;
  title?: string;
}

const selector = 'optionsMenuComponent';

const template = html`
  <div class="options-menu">
    <button
      data-testid="options-menu-button"
      class="ui-btn icon-btn options-menu__toggler"
      ng-disabled="$ctrl.disabled"
      ng-click="$event.stopPropagation(); $ctrl.toggleMenu();"
      on-click-outside="$ctrl.closeMenu()"
    >
      <i
        ng-class="{
                '{{$ctrl.iconClass}}': $ctrl.iconClass.length,
                'fas fa-ellipsis-h': !$ctrl.iconClass.length
            }"
      ></i>
    </button>
    <div class="options-menu__spinner"><i class="fas fa-spinner fa-spin" aria-hidden="true"></i></div>
    <div class="options-menu__list-wrap">
      <div
        class="options-menu__list"
        data-testid="{{ $ctrl.showMenu ? 'options-menu-list-visible' : '' }}"
        ng-class="{'options-menu__list_expanded': $ctrl.showMenu}"
      >
        <a
          class="options-menu__option"
          ng-repeat="option in $ctrl.options track by option.name"
          ng-if="!option.hidden"
          ng-class="{'disabled-option': option.disabled}"
          href="{{option.url}}"
          ng-click="$event.stopPropagation(); $ctrl.onSelect(option, $event)"
          ng-attr-title="{{option.title}}"
        >
          <div class="options-menu__icon-holder">
            <div class="{{'options-menu__icon options-menu__icon_' + option.type}}"></div>
          </div>
          <span class="font-paragraph-sm">{{option.name}}</span>
        </a>
      </div>
    </div>
  </div>
`;

interface Bindings {
  options: '<' | MenuListItem[];
  onOptionClick: '&' | ((_: { option: MenuListItem }) => void);
  iconClass: '<' | string;
  disabled: '<' | boolean;
}

const bindings: Bindings = {
  options: '<',
  onOptionClick: '&',
  iconClass: '<',
  disabled: '<',
};

export class OptionsMenuComponent implements Bindings {
  static componentName = selector;

  options: MenuListItem[];
  showMenu: boolean;
  onOptionClick: (_: { option: MenuListItem }) => void;
  iconClass: string;
  disabled: boolean;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    this.showMenu = false;
  }

  onSelect(option: MenuListItem, event: Event) {
    if (option.disabled) {
      event.preventDefault();
    } else {
      this.onOptionClick({ option });
      this.showMenu = false;
    }
  }
}

export const ngOptionsMenuComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: OptionsMenuComponent,
  },
};
