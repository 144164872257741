import { ui } from 'angular';
import { DeleteTemplateDialogComponent, DeleteTemplateDialogResolve } from './delete-template-dialog.component';

export class DeleteTemplateDialogSettingsFactory {
  static create(resolve: DeleteTemplateDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'delete-template-dialog',
      component: DeleteTemplateDialogComponent.componentName,
      resolve: {
        template: () => resolve.template,
        savedTemplate: () => resolve.savedTemplate,
      },
    };
  }
}
