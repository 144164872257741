import { IDirective } from 'angular';
import { some } from 'lodash-es';
import { featureFlipper } from '../feature-flipper';

const directiveName = 'featureOffAll';

export function featureOffAll(): IDirective {
  return {
    link: (_scope, element, attrs) => {
      const featureIds = attrs[directiveName].split(/[,;|]/);

      if (some(featureIds, (f) => featureFlipper.isEnabled(f))) {
        element.remove();
      }
    },
  };
}

export const ngFeatureOffAllDirective = {
  [directiveName]: featureOffAll,
};
