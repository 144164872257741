import { ActiveSupplyModel, ActiveSupplyGroup } from '../../models/active-supply.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import {
  SupplySource,
  PanelistPoolType,
  PanelistPoolSource,
  availabilityGroupToEligiblePanelSourceKey,
} from '../../../../common/enums';
import {
  AvailabilityResultResponse,
  AvailablePanelistGroups,
  CreatePanelistPoolResponse,
} from '../../../../common/http-services/supply.httpservice';
import { panelistPoolStorageService } from '../../../../common/panelist-pool-storage.service';
import { supplySubactions } from './supply.subactions';
import { supplyStateStore, SupplyStateStore, SupplyStateCommitFuncs } from '../supply-state.store';
import { SupplyStateModel } from '../../models/supply-state.model';
import { SupplyGroupUpdate } from '../../../supply/supply-mix/supply-mix-state.service';
import { activeTargetGroupStore } from '../active-target-group.store';
import { analytics } from '../../../../common/analytics';
import { stateDebugService } from '../../../../common/state-debug.service';
import { persistentSurveyLinksService } from '../../../../common/target-group/survey-links/persistent-survey-links.service';
import { Constants } from '../../../../constants';
import { isExternalPoolSource } from '../../../supply/panelist-pool/supply-panelist-pool-helper.functions';

type SupplyStateUpdateFunc = (
  state: SupplyStateModel,
  commit: SupplyStateCommitFuncs,
  done: (runFeasibility?: boolean) => void,
  persistedState: Readonly<ActiveTargetGroupModel>
) => void;

export const commitSupply = () => (supplyState: ActiveSupplyModel) => {
  supplyStateStore.model.supply = supplyState;
  targetGroupChannel.model.supplyState.updated.dispatch();
};

export class SupplyStateActions {
  constructor(private rootStore: SupplyStateStore) {}

  importInternalPanelistPool(
    panelistEntries: string,
    panelistPoolType: PanelistPoolType,
    poolSource: PanelistPoolSource,
    containsUrl = false
  ) {
    this.update((state, commit, done, persistedState) => {
      panelistPoolStorageService.deletePanelistEntries(panelistEntries);
      supplySubactions
        .importInternalPanelistPool(
          state.supply,
          panelistEntries,
          panelistPoolType,
          poolSource,
          containsUrl,
          persistedState.basicSettings.countryId
        )
        .then((newState) => {
          commit.supply(newState);
          done();
        });
    });
  }

  savePanelistPool(
    createPoolResponse: CreatePanelistPoolResponse,
    availabilityResult: AvailabilityResultResponse,
    poolSource: PanelistPoolSource,
    panelistEntries: string,
    containsUrl: boolean
  ) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.savePanelistPoolAvailability(state.supply, availabilityResult);
      if (isExternalPoolSource(poolSource)) {
        const newStateExternalSource = supplySubactions.saveInternalPanelistPoolForExternalSource(
          newState,
          poolSource,
          createPoolResponse,
          Constants.externalIdsInputToken
        );
        commit.supply(newStateExternalSource);
      } else {
        if (containsUrl) persistentSurveyLinksService.addLinksLater(activeTargetGroupStore.model.identity.id); //TODO: is this correct?
        const newStateInternalSource = supplySubactions.saveInternalPanelistPoolForInternalIds(
          newState,
          PanelistPoolType.Inclusive,
          poolSource,
          panelistEntries,
          createPoolResponse,
          containsUrl
        );
        commit.supply(newStateInternalSource);
      }
      done();
    });
  }

  saveFromPanelistPoolApi(availabilityResult: AvailabilityResultResponse, poolId: string, countryId: number) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.savePanelistPoolAvailability(state.supply, availabilityResult);

      supplySubactions
        .importFromPanelistPoolApi(newState, poolId, PanelistPoolType.Inclusive, countryId)
        .then((state) => {
          commit.supply(state);
          done();
        });
    });
  }

  importExternalPanelistPool(url: string, panelistPoolType: PanelistPoolType) {
    this.update((state, commit, done, persistedState) => {
      panelistPoolStorageService.delete(url);
      supplySubactions
        .importExternalPanelistPool(state.supply, url, panelistPoolType, persistedState.basicSettings.countryId)
        .then((newState) => {
          commit.supply(newState);
          done();
        });
    });
  }

  selectPanelistPoolId(
    availabilityGroup: keyof AvailablePanelistGroups,
    poolId: string,
    panelIds: number[],
    autoSelectPanels?: boolean,
    fromPanelistPoolApi?: boolean
  ) {
    this.update((state, commit, done) => {
      let newSupplyState = supplySubactions.selectPanelistPool(state.supply, availabilityGroup, poolId, panelIds);

      const sourceKey = availabilityGroupToEligiblePanelSourceKey(availabilityGroup);
      const sourcePanels = newSupplyState.panelSources[sourceKey];
      if (autoSelectPanels && !fromPanelistPoolApi) {
        for (const p of sourcePanels) {
          const isSelected = newSupplyState.selectedIds.indexOf(p.id) !== -1;
          const shouldBeSelected = panelIds.indexOf(p.id) !== -1;
          if (isSelected !== shouldBeSelected) {
            newSupplyState = supplySubactions.togglePanelSource(newSupplyState, p.id, sourceKey);
          }
        }
      } else if (fromPanelistPoolApi) {
        for (const p of sourcePanels) {
          const isSelected = newSupplyState.selectedIds.indexOf(p.id) !== -1;
          if (!isSelected && !p.isLocked && !p.isDisqualified)
            newSupplyState = supplySubactions.togglePanelSource(newSupplyState, p.id, sourceKey);
        }
      }

      commit.supply(newSupplyState);
      done();
    });
  }

  setSupplySource(supplySource: SupplySource) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setSupplySource(state.supply, supplySource);
      if (supplySource === SupplySource.AdHoc) {
        supplySubactions.createAdHocSuppliersAndLanguagesPromise(newSupplyState).then((newSupplyStateWithAdHoc) => {
          commit.supply(newSupplyStateWithAdHoc);
          done();
        });
      }
      commit.supply(newSupplyState);
      done();
    });
  }

  setIgnoreCompletes(ignoreCompletes: boolean) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setIgnoreCompletesForSupplyMix(state.supply, ignoreCompletes);
      commit.supply(newSupplyState);
      done();
    });
  }

  toggleUseCustomCpiForTargetGroup() {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.toggleUseCustomCpi(state.supply);
      commit.supply(newSupplyState);
      done();
    });
  }

  setCustomCpi(customCpi: number) {
    this.update((state, commit, done) => {
      analytics.newProject.pricing.setCustomCpi(state.supply.supplySource);
      const newSupplyState = supplySubactions.setCustomCpi(state.supply, customCpi);
      commit.supply(newSupplyState);
      done();
    });
  }

  setPanelistPoolSupplySource() {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setPanelistPoolSupplySource(state.supply);
      commit.supply(newSupplyState);
      done();
    });
  }

  togglePanel(panelId: number) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.togglePanel(state.supply, panelId);
      commit.supply(newSupplyState);
      done();
    });
  }

  selectAll() {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.selectAllAvailableFromSupplyType(state.supply);
      commit.supply(newSupplyState);
      done();
    });
  }

  selectPanels(panelIds: number[]) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setPanelsSelection(state.supply, panelIds, true);
      commit.supply(newSupplyState);
      done();
    });
  }

  deselectAll() {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.deselectAllFromSupplyType(state.supply);
      commit.supply(newSupplyState);
      done();
    });
  }

  deselectPanels(panelIds: number[]) {
    this.update((state, commit, done) => {
      const newSupplyState = supplySubactions.setPanelsSelection(state.supply, panelIds, false);
      commit.supply(newSupplyState);
      done();
    });
  }

  setLanguage(languageId: number) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.setLanguage(state.supply, languageId);
      commit.supply(newState);
      done();
    });
  }

  toggleAdHoc(adHocSupplierId: number) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.toggleAdHoc(state.supply, adHocSupplierId);
      commit.supply(newState);
      done();
    });
  }

  reloadAdHocSuppliers() {
    this.update((state, commit) => {
      targetGroupChannel.model.supply.loadingAdHocSuppliers.dispatch();
      supplySubactions.loadAdHocSuppliersAsync(state.supply).then((newState) => {
        commit.supply(newState);
      });
    });
  }

  addSupplyMixGroup(supplyGroup: ActiveSupplyGroup) {
    this.update((state, commit, done, persistedState) => {
      const newState = supplySubactions.addSupplyMixGroup(
        state.supply,
        supplyGroup,
        persistedState.profiling.panelSpecificProfiling,
        persistedState.basicSettings.numberOfCompletes,
        persistedState.basicSettings.numberOfStarts
      );
      commit.supply(newState);
      done();
    });
  }

  removeSupplyMixGroup(supplyGroupId: number) {
    this.update((state, commit, done, persistedState) => {
      const newState = supplySubactions.removeSupplyMixGroup(
        state.supply,
        supplyGroupId,
        persistedState.basicSettings.numberOfCompletes,
        persistedState.profiling.panelSpecificProfiling,
        persistedState.basicSettings.numberOfStarts
      );
      commit.supply(newState);
      done(true);
    });
  }

  updateSupplyMixGroup(supplyGroupUpdate: SupplyGroupUpdate) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.updateSupplyMixGroups(state.supply, [supplyGroupUpdate]);
      commit.supply(newState);
      done(true);
    });
  }

  setSupplyGroupPercentage(supplyGroupId: number, percentage: number) {
    this.update((state, commit, done, persistedState) => {
      const newState = supplySubactions.setSupplyGroupPercentage(
        state.supply,
        supplyGroupId,
        percentage,
        persistedState.basicSettings.numberOfCompletes,
        persistedState.profiling.panelSpecificProfiling,
        persistedState.basicSettings.numberOfStarts
      );
      commit.supply(newState);
      done(true);
    });
  }

  setSupplyGroupCustomCpi(supplyGroupId: number, ccpi: number) {
    this.update((state, commit, done) => {
      const newState = supplySubactions.setSupplyGroupCustomCpi(state.supply, supplyGroupId, ccpi);
      commit.supply(newState);
      done(true);
    });
  }

  discardPanelistPool() {
    this.update((state, commit, done) => {
      const newState = supplySubactions.discardPanelistPool(state.supply);
      commit.supply(newState);
      done();
    });
  }

  private update(updateFunc: SupplyStateUpdateFunc) {
    stateDebugService.logIfUnsafeUpdate('supply state');

    updateFunc(
      this.rootStore.model,
      this.rootStore.commitFuncs,
      (runFeasibility?: boolean) => this.rootStore.publishUpdate(runFeasibility),
      activeTargetGroupStore.model
    );
  }
}
