import { errorLogger } from './error-logger';
import { CacheDuration } from './enums';
import { StorageFactory } from './storage.factory';
import { gzipData, unGzipData } from './compression-helper.functions';

const cacheKeyPrefix = '_%!.cache.!%_';

type InMemoryCacheEntry<T> = [value: T, expirationUnixDate: number];

class InMemoryCache {
  private storage = StorageFactory.getStorage();
  constructor() {
    setInterval(() => {
      for (let i = 0; i < this.storage.length; i++) {
        const key = this.storage.key(i);

        if (!key.startsWith(cacheKeyPrefix)) continue;

        const [, expirationUnixDate] = this.tryGetCacheEntryFromStorage(key);
        if (expirationUnixDate < Date.now()) this.storage.removeItem(key);
      }
    }, CacheDuration.VeryShort);
  }

  getOrAdd<T>(key: string, factory: () => T, cacheDuration: CacheDuration): T {
    const [cachedValue, expirationUnixDate] = (JSON.parse(this.storage.getItem(getStorageKey(key))) ?? [
      null,
      null,
    ]) as InMemoryCacheEntry<T>;

    if (expirationUnixDate > Date.now()) return cachedValue;

    const valueToCache = factory();

    this.storeNewItem(key, valueToCache, cacheDuration);

    return valueToCache;
  }

  async getOrAddAsync<T>(key: string, factory: () => Promise<T>, cacheDuration: CacheDuration): Promise<T> {
    const [cachedValue, expirationUnixDate] = this.tryGetCacheEntryFromStorage<T>(key);

    if (expirationUnixDate > Date.now()) return cachedValue;

    const valueToCache = await factory();

    this.storeNewItem(key, valueToCache, cacheDuration);

    return valueToCache;
  }

  remove(keyName: string) {
    this.storage.removeItem(getStorageKey(keyName));
  }

  clear() {
    let counter = 0;
    while (this.storage.key(counter) !== null) {
      const key = this.storage.key(counter);
      if (key.startsWith(cacheKeyPrefix)) this.storage.removeItem(key);
      counter++;
    }
  }

  private storeNewItem<T>(keyName: string, item: T, cacheDuration: CacheDuration) {
    const entry: InMemoryCacheEntry<T> = [item, Date.now() + cacheDuration];
    const compressedEntryString = gzipData(entry);
    try {
      this.storage.setItem(getStorageKey(keyName), compressedEntryString);
    } catch (e: any) {
      errorLogger.warning(`Failed to save value to sessionStorage. Key: ${keyName}. Error: ${e.message}`);
    }
  }

  private tryGetCacheEntryFromStorage<T>(key: string) {
    const compressedEntryString = this.storage.getItem(getStorageKey(key));
    if (!compressedEntryString) return [null, null] as InMemoryCacheEntry<T>;
    const decompressedEntry = unGzipData(compressedEntryString);
    return decompressedEntry;
  }
}

function getStorageKey(keyName: string) {
  return keyName.startsWith(cacheKeyPrefix) ? keyName : `${cacheKeyPrefix}${keyName}`;
}

export const inMemoryCache = new InMemoryCache();
