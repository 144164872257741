import { isEmpty, find } from 'lodash-es';
import { BasicSettingsModel } from '../../../common/models/basic-settings.model';

import { Country } from '../../../common/api';
import { BasicSettingsValidationResult } from '../../basic-settings/basic-settings-validator';

export interface ActiveBasicSettingsModel {
  readonly countryId: number;
  readonly countryName: string;
  readonly gender: string;
  readonly minAge: number;
  readonly maxAge: number;
  readonly numberOfCompletes: number;
  readonly numberOfStarts: number;
  readonly estimatedIncidenceRate: number;
  readonly estimatedLengthOfInterview: number;
  readonly startDate: string;
  readonly numberOfDaysInField: number;
  readonly useFixedLoi: boolean;
  readonly useStarts: boolean;
  readonly validationResult?: BasicSettingsValidationResult; // these transient properties that get added "sometime later" are not that fun...
}

export function createActiveBasicSettingsModelFrom(basicSettings: BasicSettingsModel, countries: Country[]) {
  if (!basicSettings) throw new Error('basicSettings is falsy: should never happen!');

  return {
    countryId: basicSettings.countryId ? basicSettings.countryId : -1,
    countryName: getCountryName(basicSettings, countries),
    gender: basicSettings.gender,
    minAge: basicSettings.minAge,
    maxAge: basicSettings.maxAge,
    numberOfCompletes: basicSettings.numberOfCompletes,
    numberOfStarts: basicSettings.numberOfStarts,
    estimatedIncidenceRate: basicSettings.estimatedIncidenceRate,
    estimatedLengthOfInterview: basicSettings.estimatedLengthOfInterview,
    startDate: basicSettings.startDate,
    numberOfDaysInField: basicSettings.numberOfDaysInField,
    useFixedLoi: basicSettings.useFixedLoi,
    useStarts: basicSettings.useStarts,
  };
}

function getCountryName(bsModel: BasicSettingsModel, countries: Country[]): string {
  if (!isEmpty(bsModel.countryName)) {
    return bsModel.countryName;
  }

  const country = find(countries, (c) => c.id === bsModel.countryId);
  return !isEmpty(country) ? country.name : '';
}
