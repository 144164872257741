import './list-cache-dialog.component.scss';
import { ui } from 'angular';
import { api } from '../common/api';
import { html } from '../helpers';

const selector = 'listCacheDialogComponent';

const template = html`
  <div class="list-cache-dialog">
    <modal-close-button-component
      close-action="$ctrl.close({ reload: $ctrl.reloadRequired })"
    ></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">
        list keys in cache
        <small class="text-muted">&nbsp;&nbsp;&nbsp;&nbsp;and if need be, remove the items</small>
      </h1>
      <ul class="list-group push-down">
        <li class="list-group-item" ng-repeat="key in $ctrl.cacheKeys" ng-class="{ 'odd': $odd }">
          <a ng-click="$ctrl.removeSpecificItemFromCache(key)"><i class="fas fa-trash-alt"></i></a>
          <fancy-cache-key-component value="key"></fancy-cache-key-component>
        </li>
      </ul>
      <div ng-show="$ctrl.error.length > 0">
        <div class="well well-sm error-container">
          <span class="label label-danger">{{ $ctrl.error }}</span>
        </div>
      </div>
      <div class="modal-controls">
        <button class="ui-btn primary-btn" ng-click="$ctrl.close({ reload: $ctrl.reloadRequired })">Close</button>
      </div>
    </div>
  </div>
`;

export interface ListCacheDialogResolve {
  cacheKeys: string[];
}

export interface ListCacheDialogResult {
  reload: boolean;
}

interface Bindings {
  resolve: '<' | ListCacheDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class ListCacheDialogComponent implements Bindings {
  static componentName = selector;

  resolve: ListCacheDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  cacheKeys: string[];
  reloadRequired: boolean;
  error: string;

  $onInit(): void {
    this.cacheKeys = this.resolve.cacheKeys;
  }

  close(result: ListCacheDialogResult): void {
    this.modalInstance.close(result);
  }

  removeSpecificItemFromCache(key: string): void {
    this.error = '';
    api.feature
      .clearCacheItem(key)
      .then(() => {
        const index = this.cacheKeys.indexOf(key);
        this.cacheKeys.splice(index, 1);
        this.reloadRequired = true;
      })
      .catch(() => {
        this.error = 'something went wrong. check ELMAH logs.';
      });
  }
}

export const ngListCacheDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ListCacheDialogComponent,
  },
};
