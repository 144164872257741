import './template-history-dialog.component.scss';
import { ui } from 'angular';
import { concat, orderBy } from 'lodash-es';
import dayjs from 'dayjs';
import { TemplateHistoryItem } from '../../common/http-services/template.httpservice';
import { filters } from '../../common/filters';
import { html } from '../../helpers';

export class TemplateHistoryDialogSettingsFactory {
  static create(resolve: TemplateHistoryDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'template-history-dialog',
      component: TemplateHistoryDialogComponent.componentName,
      resolve: {
        owner: () => resolve.owner,
        created: () => resolve.created,
        history: () => resolve.history,
      },
    };
  }
}

const selector = 'templateHistoryDialogComponent';

const template = html`
  <div class="template-history-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Update history</h1>
      <ul ng-repeat="dictionaryItem in $ctrl.historyByDate track by $index">
        <p class="date-header">{{dictionaryItem[0].dateTime | dateFormat}}</p>
        <li ng-repeat="item in dictionaryItem track by item.dateTime" class="history-item">
          <span class="description"
            >{{item.type === 'created' ? 'Template created' : 'Edited'}} - {{item.userName}}</span
          >
          <span class="time">{{item.dateTime | timeFormat}}</span>
        </li>
      </ul>
      <div class="modal-controls">
        <button class="ok-btn ui-btn primary-btn wide" ng-click="$ctrl.ok()">Close</button>
      </div>
    </div>
  </div>
`;

export interface TemplateHistoryDialogResolve {
  owner: string;
  created: string;
  history: TemplateHistoryItem[];
}

interface DisplayHistoryItem {
  userName: string;
  dateTime: string;
  type: 'created' | 'edited';
}

interface Bindings {
  resolve: '<' | TemplateHistoryDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class TemplateHistoryDialogComponent implements Bindings, TemplateHistoryDialogResolve {
  static componentName = selector;

  resolve: TemplateHistoryDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  history: TemplateHistoryItem[];
  owner: string;
  created: string;
  historyByDate: { [date: string]: DisplayHistoryItem[] };

  $onInit(): void {
    this.history = this.resolve.history;
    this.created = this.resolve.created;
    this.owner = this.resolve.owner;

    const displayItems: DisplayHistoryItem[] = orderBy(
      [
        { userName: this.owner, dateTime: this.created, type: 'created' },
        ...this.history.map(
          (h): DisplayHistoryItem => ({
            userName: h.userName,
            dateTime: h.updated,
            type: 'edited',
          })
        ),
      ],
      (i) => dayjs(i.dateTime),
      ['desc']
    );

    this.historyByDate = this.getHistoryDictionary(displayItems);
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  ok(): void {
    this.modalInstance.dismiss();
  }

  private getHistoryDictionary(history: DisplayHistoryItem[]): { [date: number]: DisplayHistoryItem[] } {
    const historyDictionary: { [date: string]: DisplayHistoryItem[] } = {};
    for (const item of history) {
      const index = filters.dateFormat()(item.dateTime);
      if (!historyDictionary[index]) {
        historyDictionary[index] = [];
      }
      historyDictionary[index] = concat(historyDictionary[index], [item]);
    }
    return historyDictionary;
  }
}

export const ngTemplateHistoryDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TemplateHistoryDialogComponent,
  },
};
