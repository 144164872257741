import { includes, isInteger, isNaN, toPairs } from 'lodash-es';
import dayjs from 'dayjs';
import { countryService } from '../country.service';
import { isUndefinedOrEmpty } from '../../helpers';
import { BasicSettingsModel } from '../../common/models/basic-settings.model';
import { TargetGroupConstants } from '../target-group.constants';
import { TargetGender } from '../../common/enums';
import { Constants } from '../../constants';

export interface BasicSettingsValidation {
  isValid: boolean;
  errors: { [p: string]: boolean };
}

export interface BasicSettingsValidationResult {
  countryId: BasicSettingsValidation;
  numberOfCompletes: BasicSettingsValidation;
  numberOfStarts: BasicSettingsValidation;
  gender: BasicSettingsValidation;
  minAge: BasicSettingsValidation;
  maxAge: BasicSettingsValidation;
  estimatedIncidenceRate: BasicSettingsValidation;
  estimatedLengthOfInterview: BasicSettingsValidation;
  startDate: BasicSettingsValidation;
  numberOfDaysInField: BasicSettingsValidation;
}

export class BasicSettingsValidator {
  validate(model: BasicSettingsModel, overrideIrAndLoiRestrictions: boolean): BasicSettingsValidationResult {
    const countryRestrictions = countryService.getRestrictions(model.countryId, overrideIrAndLoiRestrictions);

    const sanitized = {
      numberOfCompletes: this.parsePositiveIntegerFromUserInput(model.numberOfCompletes),
      numberOfStarts: this.parsePositiveIntegerFromUserInput(model.numberOfStarts),
      minAge: this.parsePositiveIntegerFromUserInput(model.minAge),
      maxAge: this.parsePositiveIntegerFromUserInput(model.maxAge),
      estimatedIncidenceRate: this.parsePositiveIntegerFromUserInput(model.estimatedIncidenceRate),
      estimatedLengthOfInterview: this.parsePositiveIntegerFromUserInput(model.estimatedLengthOfInterview),
      numberOfDaysInField: this.parsePositiveIntegerFromUserInput(model.numberOfDaysInField),
    };

    const defaults = {
      required: (value: any) => isUndefinedOrEmpty(value),
      integer: (value: any) => !isInteger(value),
      min: (value: any, lowerLimitInclusive: any) => value < lowerLimitInclusive,
      max: (value: any, upperLimitInclusive: any) => value > upperLimitInclusive,
    };

    // a validator definition as follows:
    //
    //  { property1: [
    //     { key: 'error1', func: function () {
    //         return true; } },
    //     { key: 'error2', func: function () {
    //         return false; } }
    //  ]},
    //  { property2: [
    //     { key: 'error', func: function () {
    //         return null; } }
    //  }
    //
    // returns a model whose definition looks like:
    //
    //  {
    //    property1: {
    //      isValid: true,
    //      errors: {
    //        error1: true,   // error!
    //        error2: false   // no error!
    //      }
    //    },
    //    property2: {
    //      isValid: true,
    //      errors: {
    //        error: null    // indeterminate/not run
    //      }
    //    }
    //  }

    const validators = [
      { countryId: [{ key: 'required', func: () => defaults.required(model.countryId) || model.countryId === -1 }] },
      {
        numberOfCompletes: [
          { key: 'required', func: () => defaults.required(model.numberOfCompletes) },
          { key: 'integer', func: () => defaults.integer(sanitized.numberOfCompletes) },
          { key: 'min', func: () => defaults.min(sanitized.numberOfCompletes, 1) },
          { key: 'max', func: () => defaults.max(sanitized.numberOfCompletes, Constants.maxNumberOfCompletes) },
        ],
      },
      {
        numberOfStarts: model.useStarts
          ? [
              { key: 'required', func: () => defaults.required(model.numberOfStarts) },
              { key: 'integer', func: () => defaults.integer(sanitized.numberOfStarts) },
              { key: 'min', func: () => defaults.min(sanitized.numberOfStarts, sanitized.numberOfCompletes) },
              { key: 'max', func: () => defaults.max(sanitized.numberOfStarts, Constants.maxNumberOfStarts) },
            ]
          : [{ key: 'required', func: () => false }],
      },
      {
        gender: [
          { key: 'required', func: () => defaults.required(model.gender) },
          {
            key: 'value',
            func: () => !includes([TargetGender.Both, TargetGender.Male, TargetGender.Female, ''], model.gender),
          },
        ],
      },
      {
        minAge: [
          { key: 'required', func: () => defaults.required(model.minAge) },
          { key: 'integer', func: () => defaults.integer(sanitized.minAge) },
          { key: 'min', func: () => defaults.min(sanitized.minAge, countryRestrictions.minimumAge) },
          { key: 'max', func: () => defaults.max(sanitized.minAge, 100) },
          { key: 'lessThanMaxAge', func: () => defaults.max(sanitized.minAge, sanitized.maxAge) },
        ],
      },
      {
        maxAge: [
          { key: 'required', func: () => defaults.required(model.maxAge) },
          { key: 'integer', func: () => defaults.integer(sanitized.maxAge) },
          { key: 'min', func: () => defaults.min(sanitized.maxAge, countryRestrictions.minimumAge) },
          { key: 'max', func: () => defaults.max(sanitized.maxAge, 100) },
          { key: 'greaterThanMinAge', func: () => defaults.min(sanitized.maxAge, sanitized.minAge) },
        ],
      },
      {
        estimatedIncidenceRate: [
          { key: 'required', func: () => defaults.required(model.estimatedIncidenceRate) },
          { key: 'integer', func: () => defaults.integer(sanitized.estimatedIncidenceRate) },
          { key: 'min', func: () => defaults.min(sanitized.estimatedIncidenceRate, 1) },
          { key: 'max', func: () => defaults.max(sanitized.estimatedIncidenceRate, 100) },
          {
            key: 'countryMin',
            func: () =>
              !isUndefinedOrEmpty(countryRestrictions)
                ? defaults.min(sanitized.estimatedIncidenceRate, countryRestrictions.irMin)
                : false,
          },
          {
            key: 'countryMax',
            func: () =>
              !isUndefinedOrEmpty(countryRestrictions)
                ? defaults.max(sanitized.estimatedIncidenceRate, countryRestrictions.irMax)
                : false,
          },
        ],
      },
      {
        estimatedLengthOfInterview: [
          { key: 'required', func: () => defaults.required(model.estimatedLengthOfInterview) },
          { key: 'integer', func: () => defaults.integer(sanitized.estimatedLengthOfInterview) },
          { key: 'min', func: () => defaults.min(sanitized.estimatedLengthOfInterview, 1) },
          {
            key: 'max',
            // there must be a prettier/eslint bug here...
            // eslint-disable-next-line prettier/prettier
            func: () => (model.useFixedLoi ? false : defaults.max(sanitized.estimatedLengthOfInterview, 100)),
          },
          {
            key: 'countryMin',
            func: () =>
              !isUndefinedOrEmpty(countryRestrictions)
                ? defaults.min(sanitized.estimatedLengthOfInterview, countryRestrictions.loiMin)
                : false,
          },
          {
            key: 'countryMax',
            func: () =>
              !model.useFixedLoi && !isUndefinedOrEmpty(countryRestrictions)
                ? defaults.max(sanitized.estimatedLengthOfInterview, countryRestrictions.loiMax)
                : false,
          },
          {
            key: 'fixedLoiMax',
            func: () =>
              model.useFixedLoi
                ? defaults.max(sanitized.estimatedLengthOfInterview, TargetGroupConstants.maxLoiForFixedLoi)
                : false,
          },
        ],
      },
      {
        startDate: [
          { key: 'required', func: () => defaults.required(model.startDate) },
          { key: 'value', func: () => !dayjs(model.startDate).isValid() },
        ],
      },
      {
        numberOfDaysInField: [
          { key: 'required', func: () => defaults.required(model.numberOfDaysInField) },
          { key: 'integer', func: () => defaults.integer(sanitized.numberOfDaysInField) },
          { key: 'min', func: () => defaults.min(sanitized.numberOfDaysInField, 1) },
          { key: 'max', func: () => defaults.max(sanitized.numberOfDaysInField, 31) },
        ],
      },
    ].filter((v) => v);

    const errors = {} as BasicSettingsValidationResult;

    for (const validator of validators) {
      const pairs = toPairs(validator)[0];
      const propertyKey = pairs[0] as keyof BasicSettingsValidationResult;
      const propertyValidators = pairs[1];

      errors[propertyKey] = { errors: {}, isValid: true };

      let encounteredError = false;
      for (const propertyValidator of propertyValidators) {
        errors[propertyKey].errors[propertyValidator.key] = null;

        if (!encounteredError) {
          const result = propertyValidator.func();
          errors[propertyKey].errors[propertyValidator.key] = result;

          if (result) {
            errors[propertyKey].isValid = false;
            encounteredError = true;
          }
        }
      }
    }

    return errors;
  }

  isValid(v: BasicSettingsValidationResult, useStarts: boolean) {
    return (
      v.countryId.isValid &&
      v.minAge.isValid &&
      v.maxAge.isValid &&
      v.numberOfCompletes.isValid &&
      (useStarts ? v.numberOfStarts.isValid : true) &&
      v.estimatedIncidenceRate.isValid &&
      v.estimatedLengthOfInterview.isValid &&
      v.numberOfDaysInField.isValid
    );
  }

  private parsePositiveIntegerFromUserInput(input: number) {
    if (input === null || input === undefined || isNaN(input)) return Number.NaN;

    if (isInteger(input)) return input;

    const inputString = input.toString().trim();
    const matches = inputString.match(/^\d+$/);
    if (matches === null || matches === undefined || matches.length === 0) return Number.NaN;

    const value = matches[0];
    if (value === null || value === undefined || value.length === 0) return Number.NaN;

    return parseInt(value, 10);
  }
}

export const basicSettingsValidator = new BasicSettingsValidator();
