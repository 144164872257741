import { fromPairs } from 'lodash-es';
import { ExcludeProjectsModel } from '../../../common/models/exclude-projects.model';
import { ExcludedRespondentStatus, ProjectChannel, ExcludeStatusTimeline } from '../../../common/enums';
import { Iso8601Date } from '../../../helpers';

export interface ExcludedProject {
  readonly id: number;
  readonly name: string;
  readonly channel: ProjectChannel;
  readonly startDate: Iso8601Date;
  readonly selected?: boolean;
}

export interface StatusToExclude {
  readonly value: ExcludedRespondentStatus;
  readonly timeline: ExcludeStatusTimeline;
}

export interface ActiveExcludeProjectsModel {
  readonly projects: ExcludedProject[];
  readonly projectStatusesToExclude: {
    [id: number]: StatusToExclude[];
  };
  readonly statusesToExclude: StatusToExclude[];
  readonly selectedProjectIds: number[];
  readonly selectedProjects: ExcludedProject[];
  readonly paginationToken: string;
}

export function createActiveExcludeProjectsModel(): ActiveExcludeProjectsModel {
  return {
    projects: [],
    projectStatusesToExclude: [],
    statusesToExclude: [],
    selectedProjectIds: [],
    selectedProjects: [],
    paginationToken: null,
  };
}

export function createActiveExcludeProjectsModelFrom(
  excludeProjects: ExcludeProjectsModel
): ActiveExcludeProjectsModel {
  const statusesToExclude = excludeProjects.respondentStatusTimelines.map((s) => ({
    value: s.respondentStatus,
    timeline: s.timeline,
  }));
  const projectExclusion = fromPairs(
    excludeProjects.projects
      .filter((p) => p.respondentStatusTimelines?.length > 0)
      .map(({ projectId, respondentStatusTimelines }) => [
        projectId,
        respondentStatusTimelines.map(({ timeline, respondentStatus }) => ({ timeline, value: respondentStatus })),
      ])
  );
  return {
    projects: [],
    projectStatusesToExclude: projectExclusion,
    statusesToExclude,
    selectedProjectIds: excludeProjects.projects.map((p) => p.projectId),
    selectedProjects: [],
    paginationToken: null,
  };
}
