import {
  fill,
  toInteger,
  head,
  fromPairs,
  isEqual,
  includes,
  difference,
  find,
  isEmpty,
  map,
  groupBy,
  some,
  uniq,
  filter,
  isNumber,
  cloneDeep,
  orderBy,
  findIndex,
  flatMap,
} from 'lodash-es';
import { toJson } from 'angular';
import { activeTargetGroupStore } from '../active-target-group/store/active-target-group.store';
import { normalize } from '../../common/normalize';
import { FeasibilityResponse } from '../../common/http-services/feasibility.response';
import { getActualFeasibility } from '../feasibility.service';
import {
  QuotaKey,
  QuotaSection,
  Quota,
  QuotaKeyProfiling,
  ExtendedQuota,
  ActiveQuotasModel,
  flattenQuotas,
} from '../active-target-group/models/active-quotas.model';
import { QuotaOptions, SupplySource, TargetGender, TargetingType } from '../../common/enums';
import { quotaBucketBuilder, QuotaWithBuckets } from './quota-bucket-builder';
import { ActiveSupplyGroup, ActiveSupplyModel } from '../active-target-group/models/active-supply.model';
import { createSupplyQuota } from '../active-target-group/store/quotas/quotas.mutators';
import { keys } from '../../helpers';
import { QuotaGroupPreset } from '../../common/api';
import { ActiveTargetGroupModel, AgeSpan, Census } from '../active-target-group/models/active-target-group.model';
import {
  DetailedSelectionItem,
  DetailedSelectionItemQuestion,
} from '../active-target-group/models/active-profiling.model';
import { ProfilingCensus } from '../../common/http-services/census-response.model';
import { Region } from '../active-target-group/models/active-regions.model';

export function getEvenlyDistributedCompletes(completesRawOrPercent: number, quotasCount: number): number[] {
  const completesPerQuota = Math.floor(completesRawOrPercent / quotasCount);
  const result = fill(Array(quotasCount), completesPerQuota);

  const rest = completesRawOrPercent % quotasCount;
  if (rest > 0) {
    for (let i = 0; i < rest; i++) {
      result[i]++;
    }
  }

  return result;
}

export function quotaTargetingTypesToEnum(targetingTypes: string[]) {
  if (targetingTypes.length !== 1) {
    throw Error('interlocked quota presets not yet supported');
  }
  const type = targetingTypes[0];
  switch (type) {
    case 'gender':
      return TargetingType.Gender;
    case 'age':
      return TargetingType.Age;
    case 'region':
      return TargetingType.Region;
    case 'profiling':
      return TargetingType.Profiling;
    default:
      throw Error(`unsupported preset targeting type '${type}'`);
  }
}

export function quotaKeyToTargetingType(key: string): TargetingType {
  switch (key) {
    case 'ageSpan':
      return TargetingType.Age;
    case 'region':
      return TargetingType.Region;
    case 'gender':
      return TargetingType.Gender;
    default:
      if (key.startsWith('profiling')) return TargetingType.Profiling;
      throw Error('unsupported quota key');
  }
}

export function getTargetingTypesFromQuotasKeys(keys: string[]): TargetingType[] {
  const types: TargetingType[] = [];
  keys.forEach((key) => {
    types.push(quotaKeyToTargetingType(key));
  });
  return Array.from(new Set(types)).sort();
}

export function getCensusDistributedCompletesOrStarts(
  censusKey: keyof Census,
  quotaType: 'completes' | 'starts' = 'completes',
  selectedRegionIds?: number[][] | readonly number[][],
  questionId?: number
): { value: number; identifier?: any }[] {
  const {
    census,
    quotas,
    basicSettings: { numberOfCompletes, numberOfStarts },
  } = activeTargetGroupStore.model;
  if (quotaType === 'starts' && !isNumber(numberOfStarts)) return undefined;

  const multiplier = 100;

  let completesOrStarts: { value: number; identifier?: any }[];
  const tgCompletesOrStarts = quotaType === 'completes' ? numberOfCompletes : numberOfStarts;

  switch (censusKey) {
    case 'gender':
      completesOrStarts = [
        {
          value: census.gender.quotas.find((g) => g.name === 'male').percentage * multiplier,
        },
        {
          value: census.gender.quotas.find((g) => g.name === 'female').percentage * multiplier,
        },
      ];
      break;
    case 'ageSpan':
      completesOrStarts = census.ageSpan.quotas.map((a) => ({
        value: a.percentage * multiplier,
      }));
      break;
    case 'region':
      completesOrStarts = getSortedBySortOrder<{ regionIds: number[]; percentage: number }>(
        census.region.quotas,
        'regionIds',
        selectedRegionIds
      ).map((r) => ({
        value: r.percentage * multiplier,
      }));
      break;
    case 'profiling':
      completesOrStarts = census.profiling
        .find((p) => p.questionId === questionId)
        .quotas.map((r) => ({
          identifier: r.variableIds,
          value: r.percentage * multiplier,
        }));
      break;
    default:
      throw Error(`unexpected census key '${censusKey}'`);
  }

  // BAIDU: refactor to remove need for property
  return normalize(getCompletesOrStarts(quotas.usePercentages, tgCompletesOrStarts), completesOrStarts, 'value');
}

export function getPresetDistributedCompletesOrStarts(
  targetingType: TargetingType,
  quotaType: 'completes' | 'starts' = 'completes',
  quotaPreset: QuotaGroupPreset
): number[] {
  const {
    quotas,
    basicSettings: { numberOfCompletes, numberOfStarts },
  } = activeTargetGroupStore.model;
  if (quotaType === 'starts' && !isNumber(numberOfStarts)) return undefined;

  let completesOrStarts: { value: number }[];
  const tgCompletesOrStarts = quotaType === 'completes' ? numberOfCompletes : numberOfStarts;
  const multiplier = 100;

  switch (targetingType) {
    case TargetingType.Gender:
      completesOrStarts = [
        {
          value: quotaPreset.quotas.find((q) => q.gender === TargetGender.Male).ratio * multiplier,
        },
        {
          value: quotaPreset.quotas.find((q) => q.gender === TargetGender.Female).ratio * multiplier,
        },
      ];
      break;
    case TargetingType.Age:
      completesOrStarts = orderBy(quotaPreset.quotas, (q) => q.ageSpan.from, 'asc').map((a) => ({
        value: a.ratio * multiplier,
      }));
      break;
    case TargetingType.Region:
      completesOrStarts = orderBy(quotaPreset.quotas, (q) => q.ordinal, 'asc').map((r) => ({
        value: r.ratio * multiplier,
      }));
      break;
    case TargetingType.Profiling:
      completesOrStarts = orderBy(quotaPreset.quotas, (q) => q.ordinal, 'asc').map((q) => ({
        value: q.ratio * multiplier,
      }));
      break;
    default:
      throw Error(`unexpected targeting type '${targetingType}'`);
  }

  // BAIDU: refactor to remove need for property
  return normalize(getCompletesOrStarts(quotas.usePercentages, tgCompletesOrStarts), completesOrStarts, 'value').map(
    (c) => c.value
  );
}

export function getSortedBySortOrder<T>(
  list: any[],
  numberTarget: string,
  sortOrder: number[][] | readonly number[][] | undefined
): T[] {
  if (!sortOrder) return cloneDeep(list);
  return cloneDeep(list).sort(
    (a, b) =>
      findIndex(sortOrder, (i) => JSON.stringify(i) === JSON.stringify(a[numberTarget])) -
      findIndex(sortOrder, (i) => JSON.stringify(i) === JSON.stringify(b[numberTarget]))
  );
}

export function normalizeMixedQuotas(
  quotas: readonly QuotaWithBuckets[],
  usePercentages: boolean,
  numberOfCompletes: number,
  numberOfStarts: number | undefined
) {
  const quotasCopy = cloneDeep(quotas);
  let normalizedQuotas: QuotaWithBuckets[] = [];
  const groupedQuotas = groupBy(quotasCopy, (q) => q.keys);
  for (const key of keys(groupedQuotas)) {
    let normalized = normalize(
      getCompletesOrStarts(usePercentages, numberOfCompletes),
      groupedQuotas[key],
      'wantedCompletes'
    );

    if (isNumber(numberOfStarts)) {
      normalized = normalize(getCompletesOrStarts(usePercentages, numberOfStarts), normalized, 'wantedStarts');
    }
    normalizedQuotas = [...normalizedQuotas, ...normalized];
  }
  return normalizedQuotas;
}

// TODO QOS: Write tests
export function mapDisabledCompletesAndStarts(
  quotas: readonly QuotaWithBuckets[],
  sectionsUsingIgnoreCompletes: readonly (readonly string[])[],
  useStarts: boolean
): QuotaWithBuckets[] {
  const groupedQuotas = groupBy(quotas, (q) => q.keys);
  let quotasAndBuckets: QuotaWithBuckets[] = [];
  for (const key of keys(groupedQuotas)) {
    const result: any[] = groupedQuotas[key].slice();
    const ignoreCompletes = sectionsUsingIgnoreCompletes.some((k) => isEqual(k.join(','), key));
    if (ignoreCompletes) {
      for (const item of result) {
        item.wantedCompletes = null;
      }
    }
    if (!useStarts) {
      for (const item of result) {
        item.wantedStarts = undefined;
      }
    }
    quotasAndBuckets = [...quotasAndBuckets, ...result];
  }
  return quotasAndBuckets;
}

export function parseQuestionIdFromProfilingQuotaKeys(keys: readonly string[]): number {
  const parts = keys[0].split(':');
  return toInteger(parts[1].substring('question'.length));
}

export function sumUpCompletes(quotas: { wantedCompletes: number }[]): number {
  return quotas.reduce((acc, q) => acc + q.wantedCompletes, 0);
}

export function sumUpStarts(quotas: { wantedStarts?: number }[]): number {
  return quotas.reduce((acc, q) => acc + q.wantedStarts, 0);
}

export interface QuotaFeasibilities {
  [p: number]: {
    feasible: number;
    push: number;
    aiPush: number;
  };
}

export function getQuotaFeasibilities(
  feasibility: FeasibilityResponse,
  quotasFilter: (quota: { keys: readonly string[] }) => boolean
): QuotaFeasibilities {
  const filteredQuotaFeasibilities = getActualFeasibility(feasibility).quotas.filter(quotasFilter);
  return fromPairs(
    filteredQuotaFeasibilities.map((q) => [
      q.matchId,
      { feasible: q.feasible, push: q.pushFeasibility, aiPush: q.aiPushFeasibility },
    ])
  );
}

export function isInterlockedWith(keys: readonly string[], interlocked: QuotaSection[]): string[] {
  if (interlocked === undefined) return [];
  if (keys.length > 1) throw Error('this is only meant to be called on noninterlocked sections');

  const result = find(interlocked, (section) => includes(section.keys, head(keys)));
  if (result === undefined) return [];

  return difference(result.keys, keys);
}

export function areInterlocked(firstKey: string, secondKey: string, interlocked: QuotaSection[]): boolean {
  const interlockedList = find(interlocked, (list) => includes(list.keys, firstKey));
  if (interlockedList && includes(interlockedList.keys, secondKey)) return true;
  return false;
}

export function isInterlockedWithQuestions(key: string, interlocked: QuotaSection[], questionIds?: number[]): boolean {
  if (!questionIds) {
    const interlockedList = find(interlocked, (list) => includes(list.keys, key));
    if (interlockedList && some(interlockedList.keys, (k) => k.startsWith('profiling'))) return true;
    return false;
  }

  const interlockedList = find(interlocked, (list) => includes(list.keys, key));
  if (interlockedList && some(questionIds, (id) => includes(interlockedList.keys, `profiling:question${id}`)))
    return true;
  return false;
}

export function isNonInterlocked(key: string, keys: readonly string[]): boolean {
  if (keys.length !== 1) return false;
  if (key === 'profiling' && !head(keys).startsWith('profiling:')) return false;
  if (key !== 'profiling' && head(keys) !== key) return false;
  return true;
}

export interface QuotaGroupNamesMap {
  region: {
    counter: number;
    stored: {
      [regionIds: string]: number;
    };
  };
  profiling: {
    counter: number;
    stored: {
      [variableIds: string]: number;
    };
  };
}

export interface QuotaGroupKeys {
  region: string;
  profiling: string[];
}

export function getGroupIndices(quotaGroupNames: QuotaGroupNamesMap, quotaGroupKeys: QuotaGroupKeys) {
  const quotaGroupIndices: {
    region: { [key: string]: number };
    profiling: { [key: string]: number };
  } = { region: {}, profiling: {} };

  if (quotaGroupKeys.region !== undefined) {
    const storedRegionGroups = quotaGroupNames.region.stored[quotaGroupKeys.region];
    if (storedRegionGroups === undefined) {
      quotaGroupNames.region.stored[quotaGroupKeys.region] = ++quotaGroupNames.region.counter;
    }
    quotaGroupIndices.region[quotaGroupKeys.region] = quotaGroupNames.region.stored[quotaGroupKeys.region];
  }

  for (const profilingKey of quotaGroupKeys.profiling) {
    const storedProfilingGroup = quotaGroupNames.profiling.stored[profilingKey];
    if (storedProfilingGroup === undefined) {
      quotaGroupNames.profiling.stored[profilingKey] = ++quotaGroupNames.profiling.counter;
    }
    quotaGroupIndices.profiling[profilingKey] = quotaGroupNames.profiling.stored[profilingKey];
  }
  return quotaGroupIndices;
}

export function getGeneratedBucketsFromModel(model: ActiveTargetGroupModel) {
  const nonSupplyQuotas = [...flattenQuotas(model.quotas)];

  const supplyQuotas = buildSupplyQuotas(
    model.supply.supplyMix.supplyGroups,
    model.supply.supplySource,
    nonSupplyQuotas.length
  );
  return quotaBucketBuilder.generate(
    [...nonSupplyQuotas, ...supplyQuotas],
    model.regions.detailedSelection,
    model.profiling.detailedSelection,
    model.quotas.quotaGroupNames
  );
}

export function getNumberOfBucketsFromQuotas(quotas: ActiveQuotasModel, supply: ActiveSupplyModel): number {
  if (!quotas) return 0;

  const { gender, ageSpan, region, profiling, interlocked } = quotas;
  const {
    supplySource,
    supplyMix: { supplyGroups },
  } = supply;

  const quotaNumbers = [];

  if (gender.items.length) quotaNumbers.push(gender.items.length);
  if (ageSpan.items.length) quotaNumbers.push(ageSpan.items.length);
  if (region.items.length) quotaNumbers.push(region.items.length);
  profiling.forEach((profilingQuota) => {
    if (profilingQuota.items.length) quotaNumbers.push(profilingQuota.items.length);
  });
  interlocked.forEach((interlockedQuota) => {
    if (interlockedQuota.items.length) quotaNumbers.push(interlockedQuota.items.length);
  });
  if (supplySource === SupplySource.SupplyMix && supplyGroups.length) quotaNumbers.push(supplyGroups.length);

  return !quotaNumbers.length ? 0 : quotaNumbers.reduce((acc, current) => (acc ?? 1) * current);
}

export function findQuota(quotas: ActiveQuotasModel, quotaHash: string) {
  return find([...flattenQuotas(quotas)], (q) => q.hash === quotaHash);
}

export function getVariableIdsFromQuotaHashes(quotas: ActiveQuotasModel, quotaHashes: string[]): number[] {
  const variableIds: number[] = [];
  for (const quota of [...flattenQuotas(quotas)].filter((q) => quotaHashes.includes(q.hash))) {
    quota.key.profiling.map((p) => p.variableId).forEach((id) => variableIds.push(id));
  }
  return variableIds;
}

export function getRegionIdsFromQuotaHashes(quotas: ActiveQuotasModel, quotaHashes: string[]): number[] {
  const regionIds: number[] = [];
  for (const quota of [...flattenQuotas(quotas)].filter((q) => quotaHashes.includes(q.hash))) {
    quota.key.region.forEach((id) => regionIds.push(id));
  }
  return regionIds;
}

export function getProfilingQuotaName(
  profiling: readonly number[],
  selectedProfiling: readonly DetailedSelectionItem[]
) {
  const variablePairs = flatMap(
    flatMap(selectedProfiling, (category) => category.questions),
    (questions) => questions.variables.map((v) => [v.id, v.name])
  );

  const variableToNameMap = fromPairs(variablePairs);

  const profilingParts = map(profiling, (variableId) => variableToNameMap[variableId]);
  return `[${profilingParts.join(', ')}]`;
}

export function getRegionQuotaName(region: readonly number[], selectedRegions: readonly Region[]) {
  const regionParts: string[] = [];
  for (const regionId of region) {
    const match = selectedRegions.find((r) => r.id === regionId);
    if (match !== undefined) {
      regionParts.push(match.name);
    } else {
      throw new Error(
        `Error in project setup - region with Id ${regionId} not found in selected regions: ${selectedRegions
          .map((r) => r.id)
          .join(',')}.`
      );
    }
  }
  return `[${regionParts.join(', ')}]`;
}

export function getSectionFromQuotaHash(state: ActiveQuotasModel, quotaHash: string): QuotaSection | undefined {
  const quotaSections = [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];

  for (const section of quotaSections) {
    if (section.items.find((q) => q.hash === quotaHash)) return section;
  }

  return undefined;
}

export function getCompletesOrStarts(usePercentages: boolean, numberOfType: number, ignoreCompletes = false) {
  if (ignoreCompletes) return null;
  if (numberOfType === undefined) return undefined;
  return usePercentages ? 100 : numberOfType;
}

export function buildSupplyQuotas(
  supplyGroups: ActiveSupplyGroup[],
  supplySource: SupplySource,
  otherQuotaCount: number
): ExtendedQuota[] {
  if (supplySource !== SupplySource.SupplyMix) return [] as ExtendedQuota[];
  let matchId = otherQuotaCount + 1;
  return supplyGroups.map(({ name, panelIds, wantedCompletes, wantedStarts, customCpi, useCustomCpi }) => {
    return {
      ...createSupplyQuota(name, panelIds, wantedCompletes, wantedStarts, useCustomCpi ? customCpi : undefined),
      keys: ['supply'],
      option: QuotaOptions.Custom,
      get matchId() {
        return matchId++;
      },
    };
  });
}

export interface QuotaGroupDetails {
  isGrouped: boolean;
  quotaGroupKeys?: {
    region: string;
    profiling: string[];
  };
}

export function getQuotaSections(state: ActiveQuotasModel): QuotaSection[] {
  return [state.ageSpan, state.gender, state.region, ...state.profiling, ...state.interlocked];
}

export function isGroupedQuota(key: QuotaKey): QuotaGroupDetails {
  const quotaGroupDetails: QuotaGroupDetails = {
    isGrouped: false,
    quotaGroupKeys: { region: undefined, profiling: [] },
  };

  if (key.region.length > 1) {
    quotaGroupDetails.isGrouped = true;
    quotaGroupDetails.quotaGroupKeys.region = regionGroupKey(key);
  }
  const questionVariables = filter(
    groupBy(key.profiling, (q) => q.questionId),
    (q) => q.length > 1
  );

  if (key.profiling.length > 1 && some(questionVariables)) {
    quotaGroupDetails.isGrouped = true;
    quotaGroupDetails.quotaGroupKeys.profiling = map(questionVariables, (g) => profilingGroupKey(g));
  }
  return quotaGroupDetails;
}

export function regionGroupKey(key: QuotaKey) {
  return toJson(key.region);
}

export function profilingGroupKey(key: QuotaKeyProfiling) {
  return toJson(key.map((q) => q.variableId));
}

export function isInterlockedQuota(key: QuotaKey): boolean {
  return (
    map(key, (k) => !isEmpty(k)).filter((k) => k).length > 1 || uniq(key.profiling.map((p) => p.questionId)).length > 1
  );
}

export function sumCompletes(quotas: Quota[]): number {
  if (isEmpty(quotas) || quotas === undefined) return 0;
  return quotas.reduce((acc, item) => acc + item.completes, 0) || undefined;
}

export function sumStarts(quotas: Quota[]): number {
  if (isEmpty(quotas) || quotas === undefined) return 0;
  return quotas.reduce((acc, item) => acc + item.starts, 0) || undefined;
}

const filterQuotas = (quota: { keys: readonly string[] }, quotaType: string) =>
  quota.keys.length === 1 && head(quota.keys) === quotaType;

export const hasAnyQuotas = (quotas: ExtendedQuota[]): boolean => quotas.some((q) => q.option !== QuotaOptions.None);

export const filterGenderQuotas = (quota: { keys: readonly string[] }) => filterQuotas(quota, 'gender');

export const filterAgeQuotas = (quota: { keys: readonly string[] }) => filterQuotas(quota, 'ageSpan');

export const filterRegionQuotas = (quota: { keys: readonly string[] }) => filterQuotas(quota, 'region');

export const filterSupplyQuotas = (quota: { keys: readonly string[] }) => filterQuotas(quota, 'supply');

export const filterProfilingQuotas = (quota: { keys: readonly string[] }) =>
  quota.keys.length === 1 && head(quota.keys).startsWith('profiling:');

export const filterProfilingQuotasByQuestion = (questionId: number) => (quota: { keys: readonly string[] }) =>
  filterQuotas(quota, `profiling:question${questionId}`);

export const filterInterlockedQuotas = (quota: { keys: readonly string[] }) => quota.keys.length > 1;

export const filterInterlockedQuotasByKeys = (keys: readonly string[]) => (quota: { keys: readonly string[] }) =>
  filterInterlockedQuotas(quota) && isEqual([...quota.keys].sort(), [...keys].sort());

export const sanitizeCensusAgeSpans = (
  rawCensusAgeData: AgeSpan[]
): [sanitizedAgeSpans: AgeSpan[], hasExtrapolatedAgeCensus: boolean, maxAgeInCensus: number] => {
  let hasExtrapolatedAgeCensus = false;
  const sortedCensusAgeSpans = rawCensusAgeData.map((a) => ({ from: a.from, to: a.to })).sort((a, b) => a.to - b.to);
  const highestCensusAgeBracket = sortedCensusAgeSpans[sortedCensusAgeSpans.length - 1];
  const maxAgeInCensus = highestCensusAgeBracket.to;
  const tgUpperAgeLimit = activeTargetGroupStore.model.basicSettings.maxAge;
  if (tgUpperAgeLimit > highestCensusAgeBracket.to) {
    highestCensusAgeBracket.to = tgUpperAgeLimit;
    hasExtrapolatedAgeCensus = true;
  }
  return [sortedCensusAgeSpans, hasExtrapolatedAgeCensus, maxAgeInCensus];
};

export const allCensusVariablesSelected = (
  questionSelection: DetailedSelectionItemQuestion,
  questionCensus: ProfilingCensus
) => {
  if (questionSelection === undefined) return false;
  if (questionCensus === undefined) return false;
  const selectedVariableIds = questionSelection.variables.map((v) => v.id);
  const censusVariableIds = questionCensus.quotas.flatMap((q) => q.variableIds);
  if (selectedVariableIds.length !== censusVariableIds.length) return false;
  if (difference(selectedVariableIds, censusVariableIds).length !== 0) return false;
  return true;
};

export const sectionIsCompatibleWithCensus = (quotaSection: QuotaSection) => {
  if (quotaSection.keys.length !== 1) return false;

  const nonInterlockedKey = quotaSection.keys[0];

  if (nonInterlockedKey.startsWith('profiling')) return true;

  return includes(['gender', 'ageSpan', 'region'], nonInterlockedKey);
};

export function validateCompletesAndStarts(quotas: Quota[]) {
  const validation = {
    completes: { isValid: true, message: '' },
    starts: { isValid: true, message: '' },
  };

  if (isEmpty(quotas) || !activeTargetGroupStore.model.basicSettings) return validation;

  const {
    basicSettings: { numberOfCompletes, numberOfStarts, useStarts },
    quotas: { usePercentages },
  } = activeTargetGroupStore.model;

  validation.completes = validateCompletes(quotas, usePercentages, numberOfCompletes);

  if (useStarts) validation.starts = validateStarts(quotas, usePercentages, numberOfStarts);

  return validation;

  function validateCompletes(quotas: Quota[], usePercentages: boolean, targetGroupWantedCompletes: number) {
    if (quotas.some((q) => q.completes === undefined))
      return { isValid: false, message: 'Please make sure all quotas have a size.' };

    if (sumCompletes(quotas) !== getCompletesOrStarts(usePercentages, targetGroupWantedCompletes)) {
      const message = usePercentages
        ? 'Please make sure that the sum of the percentages add up to 100%.'
        : `Please make sure that the sum of the completes add up to the target group wanted completes.`;
      return { isValid: false, message };
    }

    return { isValid: true, message: '' };
  }

  function validateStarts(quotas: Quota[], usePercentages: boolean, targetGroupWantedStarts: number | undefined) {
    if (quotas.some((q) => !isNumber(q.starts)))
      return { isValid: false, message: 'Please make sure all quotas have a size.' };

    if (sumStarts(quotas) !== getCompletesOrStarts(usePercentages, targetGroupWantedStarts)) {
      const message = usePercentages
        ? 'Please make sure that the sum of the percentages add up to 100%.'
        : `Please make sure that the sum of the starts add up to the target group wanted starts.`;
      return { isValid: false, message };
    }

    return { isValid: true, message: '' };
  }
}
