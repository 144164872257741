import { IDirective } from 'angular';

const directiveName = 'glyphLink';

export function glyphLink(): IDirective {
  return {
    restrict: 'E',
    replace: true,
    template: `
            <span class="glyph-link">
                <span ng-show="isDisabled">
                    <span class="{{ faPrefix !== undefined ? faPrefix : 'fas'}} {{ iconClass }} text-muted"></span>
                </span>
                <span ng-show="!isDisabled">
                    <a ng-click="onClick(); $event.stopPropagation();">
                       <span class="{{ faPrefix !== undefined ? faPrefix : 'fas'}} {{ iconClass }}" title="{{ titleText }}"></span>
                    </a>
                </span>
            </span>
        `,
    scope: {
      onClick: '&',
      iconClass: '@',
      isDisabled: '=',
      titleText: '@',
      faPrefix: '@',
    },
  };
}

export const ngGlyphLinkDirective = {
  [directiveName]: glyphLink,
};
