import { find } from 'lodash-es';
import { Country, api } from '../common/api';

export interface CountryRestrictions {
  loiMin: number;
  loiMax: number;
  irMin: number;
  irMax: number;
  minimumAge: number;
}

export class CountryService {
  countries: Country[];

  async init() {
    const { data } = await api.country.getCountries();
    this.persist(data);
  }

  persist(countries: Country[]) {
    this.countries = countries;
  }

  getRestrictions(countryId: number, overrideIrAndLoiRestrictions: boolean): CountryRestrictions {
    // TODO: this is not great :/
    if (countryId <= 0) return {} as CountryRestrictions;

    const country = this.getCountry(countryId);
    return {
      loiMin: overrideIrAndLoiRestrictions ? 1 : country.loi.min,
      loiMax: overrideIrAndLoiRestrictions ? 100 : country.loi.max,
      irMin: overrideIrAndLoiRestrictions ? 1 : country.ir.min,
      irMax: overrideIrAndLoiRestrictions ? 100 : country.ir.max,
      minimumAge: country.minimumAge,
    };
  }

  getCountry(countryId: number): Country {
    return find(this.countries, (c) => c.id === countryId);
  }

  getCountryFromName(countryName: string): Country {
    return find(this.countries, (c) => c.name === countryName);
  }

  countryHasAvailablePanels(countryId: number): boolean {
    const country = this.getCountry(countryId);
    return country && country.hasAvailablePanels;
  }
}

export const countryService = new CountryService();
