import { IPromise } from 'angular';
import { find, uniq } from 'lodash-es';
import { ActiveRegionsModel, createActiveRegionsModel } from '../../models/active-regions.model';
import { TargetGroupConstants } from '../../../target-group.constants';
import { RegionTypeKey } from '../../../../common/enums';
import { api, QuotaGroupPreset } from '../../../../common/api';
import { $q } from '../../../../ngimport';
import { mutators } from './regions.mutators';
import { logProduce } from '../../../../common/immer-wrapper';

export interface UploadPostalCodesData {
  postalCodes: string[];
  regionIds: number[];
}

export class RegionsSubactions {
  createRegionTypesPromise(state: ActiveRegionsModel, countryId: number): IPromise<ActiveRegionsModel> {
    return countryId > 0
      ? api.region.getRegionTypes(countryId).then((res) => {
          return logProduce(state, (draft) => {
            draft.regionTypes = res.data;
          });
        })
      : $q.when().then(() => {
          return logProduce(state, () => {
            return createActiveRegionsModel();
          });
        });
  }

  createRegionsPromise(
    state: ActiveRegionsModel,
    countryId: number,
    regionTypeId: number | undefined
  ): IPromise<ActiveRegionsModel> {
    if (state.mainRegionsAutomaticallySelected) {
      const selectedRegionType = find(state.regionTypes, (rt) => rt.type === RegionTypeKey.Main);
      regionTypeId = selectedRegionType.id;
    }

    return regionTypeId !== undefined && regionTypeId !== TargetGroupConstants.postalCodesRegionTypeId
      ? api.region.getRegions(countryId, regionTypeId).then((res) => {
          const regions = res.data;
          return logProduce(state, (draft) => {
            mutators.setSelectedRegionType(regionTypeId)(draft);

            if (state.mainRegionsAutomaticallySelected) {
              mutators.setSelectedIds(regions.map((r) => r.id))(draft);
            }

            mutators.setRegions(regionTypeId, regions)(draft);
          });
        })
      : $q.when(regionTypeId).then((resolvedId) => {
          if (resolvedId === undefined) return logProduce(state, mutators.clearSelectedRegionType());

          const newState = logProduce(
            state,
            mutators.setRegionType(TargetGroupConstants.postalCodesRegionTypeId, RegionTypeKey.PostalCodes)
          );
          if (newState.postalCodes.length === 0) return newState;

          return logProduce(newState, mutators.addPostalCodes(newState.postalCodes, newState.selectedIds));
        });
  }

  toggleRegion(state: ActiveRegionsModel, regionId: number): ActiveRegionsModel {
    return logProduce(state, mutators.toggleRegion(regionId));
  }

  selectAllRegions(state: ActiveRegionsModel, regionIds?: number[]): ActiveRegionsModel {
    return logProduce(state, mutators.setRegionsSelectedState(true, regionIds));
  }

  async selectRegionsFromPreset(state: ActiveRegionsModel, quotaPreset: QuotaGroupPreset): Promise<ActiveRegionsModel> {
    const regionTypeIds = uniq(quotaPreset.quotas.flatMap((q) => q.regions.map((r) => r.regionTypeId)));
    if (regionTypeIds.length !== 1) {
      throw Error('unsupported quota preset with multiple region type ids found');
    }
    const presetRegionTypeId = regionTypeIds[0];
    const regionIds = quotaPreset.quotas.flatMap((q) => q.regions.map((r) => r.id));
    const newState = await this.createRegionsPromise(state, quotaPreset.countryId, presetRegionTypeId);
    return logProduce(newState, mutators.setRegionsSelectedState(true, regionIds));
  }

  deselectAllRegions(state: ActiveRegionsModel, regionIds?: number[]): ActiveRegionsModel {
    return logProduce(state, mutators.setRegionsSelectedState(false, regionIds));
  }

  discardRegionType(state: ActiveRegionsModel): ActiveRegionsModel {
    return logProduce(state, mutators.discardRegionType());
  }

  selectAllRegionsForMainRegionType(
    state: ActiveRegionsModel,
    countryId: number,
    regionTypeId?: number
  ): IPromise<ActiveRegionsModel> {
    const newState = logProduce(state, (draft) => {
      draft.mainRegionsAutomaticallySelected = true;
    });

    return this.createRegionsPromise(newState, countryId, regionTypeId);
  }

  addPostalCodes(state: ActiveRegionsModel, data: UploadPostalCodesData): ActiveRegionsModel {
    return logProduce(state, mutators.addPostalCodes(data.postalCodes, data.regionIds));
  }
}

export const regionsSubactions = new RegionsSubactions();
