import { Runtype } from 'runtypes';
import { IHttpPromise, IHttpResponse, IHttpService as IHttpServiceBase, IRequestShortcutConfig } from 'angular';
import { includes } from 'lodash-es';
import { errorLogger } from './error-logger';
import { RuntimeSettings } from '../runtime-settings';
import { gzipData } from './compression-helper.functions';
import { asJsonScalarString } from '../helpers';

const runtypeErrorMessagePrefix = 'Invalid shape for runtime type';

export function isRuntypeError(error: unknown): boolean {
  const isError = (err: unknown): err is Error => err instanceof Error;

  if (!isError(error)) return false;
  return includes(error.message, runtypeErrorMessagePrefix);
}

export interface CompressedHttpRequest {
  payload: any;
  compressRequest: boolean;
}

export interface HttpService extends IHttpServiceBase {
  validatingGet<T>(TCreator: Runtype<T>, url: string, options?: IRequestShortcutConfig): IHttpPromise<T>;
  validatingPost<T>(TCreator: Runtype<T>, url: string, data: any, options?: IRequestShortcutConfig): IHttpPromise<T>;
  validatingPostGzip<T>(
    TCreator: Runtype<T>,
    url: string,
    data: CompressedHttpRequest,
    options?: IRequestShortcutConfig
  ): IHttpPromise<T>;
  validatingPut<T>(TCreator: Runtype<T>, url: string, data: any, options?: IRequestShortcutConfig): IHttpPromise<T>;
  validatingPutGzip<T>(
    TCreator: Runtype<T>,
    url: string,
    data: CompressedHttpRequest,
    options?: IRequestShortcutConfig
  ): IHttpPromise<T>;
}

export function runtimeTypeValidatingHttpProviderDecorator($delegate: HttpService) {
  'ngInject';

  const { get } = $delegate;
  const { post } = $delegate;
  const { put } = $delegate;

  $delegate.validatingGet = <T>(runtype: Runtype<T>, url: string, options?: IRequestShortcutConfig) => {
    return get<T>(url, options).then((res: IHttpResponse<T>) => {
      return validateAndLog(runtype, res);
    });
  };

  $delegate.validatingPost = <T>(runtype: Runtype<T>, url: string, data: any, options?: IRequestShortcutConfig) => {
    return post<T>(url, data, options).then((res: IHttpResponse<T>) => {
      return validateAndLog(runtype, res);
    });
  };

  $delegate.validatingPostGzip = <T>(
    runtype: Runtype<T>,
    url: string,
    data: CompressedHttpRequest,
    options?: IRequestShortcutConfig
  ) => {
    let { payload } = data;
    if (data.compressRequest) {
      payload = asJsonScalarString(gzipData(data.payload));
    }
    return post<T>(url, payload, options).then((res: IHttpResponse<T>) => {
      return validateAndLog(runtype, res);
    });
  };

  $delegate.validatingPut = <T>(runtype: Runtype<T>, url: string, data: any, options?: IRequestShortcutConfig) => {
    return put<T>(url, data, options).then((res: IHttpResponse<T>) => {
      return validateAndLog(runtype, res);
    });
  };

  $delegate.validatingPutGzip = <T>(
    runtype: Runtype<T>,
    url: string,
    data: CompressedHttpRequest,
    options?: IRequestShortcutConfig
  ) => {
    let { payload } = data;
    if (data.compressRequest) {
      payload = asJsonScalarString(gzipData(data.payload));
    }
    return put<T>(url, payload, options).then((res: IHttpResponse<T>) => {
      return validateAndLog(runtype, res);
    });
  };

  return $delegate;
}

function validateAndLog<T>(runtype: Runtype<T>, response: IHttpResponse<T>) {
  try {
    runtype.check(response.data);
  } catch (error: any | TypeError) {
    const isDevEnvironment = __PROCESS__.ENV === 'development';
    const level = isDevEnvironment ? 'error' : 'warning';

    const { method, url } = response.config;
    const path = url.replace(RuntimeSettings.apiUrl, '');
    if (error instanceof TypeError) {
      errorLogger.log(level, `[{Method} {Path}] {ErrorMessage}`, [method, path, error.message]);
      return response;
    }
    const runtypeString = runtype.toString();

    errorLogger.log(level, `[{Method} {Path}] ${runtypeErrorMessagePrefix} {RuntimeType}:: {Key}: {ErrorMessage}`, [
      method,
      path,
      runtypeString,
      error.key,
      error.message,
    ]);

    if (isDevEnvironment) {
      throw Error(
        `[${method} ${path}]  ${runtypeErrorMessagePrefix} ${runtypeString}:: ${error.key}: ${error.message}`
      );
    }
  }
  return response;
}
