import { find, isEmpty, filter, includes, some, keys } from 'lodash-es';
import { QuotaWithBuckets } from '../quotas/quota-bucket-builder';
import { SupplyGroup } from '../../common/models/supply.model';
import { targetGroupValidator } from '../target-group-validator';
import { deprecationService } from '../deprecation.service';
import { ErrorModel, ValidationErrors } from '../validation-errors';
import { TargetGroupModel } from '../../common/models/target-group.model';
import { QuotaOptions, SupplySource, WeightingResult } from '../../common/enums';
import {
  TargetGroup,
  TargetGroupSummary,
  TargetGroupFeasibility,
  SupplyGroupSummary,
} from './target-group-list.service';
import { UpfrontTargetGroupPrice } from '../price-quote.response';
import { getActualFeasibility } from '../feasibility.service';
import { FeasibilityResponse } from '../../common/http-services/feasibility.response';
import { countryRestrictionsDisabledService } from '../../common/country-restrictions-disabled.service';
import { filters } from '../../common/filters';

export class TargetGroupListHelper {
  createTargetGroups(persistedTargetGroups: TargetGroupModel[], activeTgId: number): TargetGroup[] {
    return persistedTargetGroups.map((targetGroup) => this.addNew(targetGroup, activeTgId));
  }

  updateTargetGroups(
    persistedTargetGroups: TargetGroupModel[],
    activeTgId: number,
    scopeTargetGroups: TargetGroup[]
  ): TargetGroup[] {
    return persistedTargetGroups.map((targetGroup) => {
      const match = find(scopeTargetGroups, (tg) => tg.id === targetGroup.id);
      return this.addNewWithPersistedProperties(targetGroup, activeTgId, match);
    });
  }

  areAllTargetGroupsValid(scopeTargetGroups: TargetGroup[]): boolean {
    return scopeTargetGroups.every((tg) => tg.definition.isValid);
  }

  isFeasibilityRunningForAnyTg(scopeTargetGroups: TargetGroup[]): boolean {
    // TODO: I don't like that we check `definition.isUpdating` and not `feasibility.isRunning` here...
    return scopeTargetGroups.some((tg) => tg.definition.isUpdating);
  }

  allTgsHaveValidBasicSettings(targetGroups: TargetGroupModel[]): boolean {
    return targetGroups.every(this.targetGroupHasValidBasicSettings);
  }

  targetGroupHasValidBasicSettings(tg: TargetGroupModel): boolean {
    const noRestrictionsOnIrAndLoi = countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(
      tg.panels
    );
    return !targetGroupValidator.validateBasicSettings(tg.basicSettings, noRestrictionsOnIrAndLoi).hasError();
  }

  atLeastOneTargetGroupHasPanelistPoolContainingUrl(targetGroups: TargetGroupModel[]): boolean {
    return targetGroups.length && targetGroups.some((tg) => tg.panels.panelistPool.containsUrl);
  }

  mapTargetGroupDefinition(
    persistedTg: TargetGroupModel,
    isUpdating: boolean,
    validationResult: ErrorModel,
    isActive: boolean
  ): TargetGroupSummary {
    isUpdating = isUpdating || false;
    return {
      isValid: !validationResult.hasError(),
      error: validationResult,
      includeLockedPanels: persistedTg.requestMode.includeLockedPanels,
      hasPrivatePricing:
        persistedTg.panels.supplySource === SupplySource.PrivatePricing ||
        persistedTg.panels.panelistPool.selectedGroup === 'privatePricing',

      isActive,
      hasCensus: this.hasCensus(persistedTg),
      wantedNumberOfCompletes: persistedTg.basicSettings.numberOfCompletes,
      wantedNumberOfStarts: persistedTg.basicSettings.numberOfStarts,
      useStarts: persistedTg.basicSettings.useStarts,
      hasManuallyChosenPanels: persistedTg.panels.selectedIds.length > 0,
      isUpdating,
      hasRestrictionErrors: this.hasRestrictionErrors(validationResult),
      hasPanelistPool: !isEmpty(persistedTg.panels.panelistPool.selectedGroup),
      quotaCount: persistedTg.quotas.quotas.length,
      estimatedIr: persistedTg.basicSettings.estimatedIncidenceRate,
      estimatedLoi: persistedTg.basicSettings.estimatedLengthOfInterview,
      selectedProfilingAttributesCount: keys(persistedTg.profiling.selectedVariables).length,
      country: persistedTg.basicSettings.countryName,
      supplySummary: filters.supplySourceFormatter()(persistedTg),
      supplySource: persistedTg.panels.supplySource,
      surveyMetadataTagsCount: persistedTg.surveyMetadata?.studyTypes.length ?? 0,
      supplyGroups: persistedTg.panels.supplyMix.supplyGroups.map((g) =>
        this.getSupplyGroupSummary(g, persistedTg.quotas.quotas)
      ),
    };
  }

  mapFeasibilityResponse(feasibility: FeasibilityResponse): TargetGroupFeasibility {
    const actualFeasibility = getActualFeasibility(feasibility);
    const nonSupplyQuotas = filter(actualFeasibility.quotas, (q) => !includes(q.keys, 'supply'));

    return {
      isRunning: false,
      isFeasible: actualFeasibility.isFeasible,
      feasibleNumberOfCompletes: actualFeasibility.feasibleNumberOfCompletes,
      completesWithoutLockedPanels: feasibility.defaultFeasibility.feasibleNumberOfCompletes,
      isFeasibleWithoutLockedPanels: feasibility.defaultFeasibility.isFeasible,
      areAllQuotasFeasible:
        !some(nonSupplyQuotas, (q) => q.wanted > q.feasible) &&
        actualFeasibility.weightingResult !== WeightingResult.Approximated,
    };
  }

  private hasRestrictionErrors(validationResult: ErrorModel): boolean {
    return (
      validationResult &&
      (validationResult.equals(ValidationErrors.basicSettingsCountryRestrictions) ||
        validationResult.equals(ValidationErrors.noAvailablePanelists) ||
        validationResult.equals(ValidationErrors.basicSettingsFixedLoiRestrictions))
    );
  }

  private addNew(persistedTg: TargetGroupModel, activeTgId: number): TargetGroup {
    return {
      id: persistedTg.id,
      replacesTargetGroupId: persistedTg.replacesTargetGroupId,
      replacesProjectIds: persistedTg.replacesProjectIds,
      name: persistedTg.name,
      hasDeprecation: deprecationService.hasAnyDeprecation(persistedTg.deprecation) || false,
      definition: {
        isUpdating: true,
        isValid: undefined,
        isActive: persistedTg.id === activeTgId,
        quotaCount: persistedTg.quotas.quotas.length,
        estimatedIr: persistedTg.basicSettings.estimatedIncidenceRate,
        estimatedLoi: persistedTg.basicSettings.estimatedLengthOfInterview,
        selectedProfilingAttributesCount: keys(persistedTg.profiling.selectedVariables).length,
        country: persistedTg.basicSettings.countryName,
        supplySummary: filters.supplySourceFormatter()(persistedTg),
        supplySource: persistedTg.panels.supplySource,
        surveyMetadataTagsCount: persistedTg.surveyMetadata?.studyTypes.length ?? 0,
        supplyGroups: persistedTg.panels.supplyMix?.supplyGroups.map((g) =>
          this.getSupplyGroupSummary(g, persistedTg.quotas.quotas)
        ),
      },
      feasibility: {
        isFeasible: undefined,
        isRunning: true,
      } as TargetGroupFeasibility,
      price: {} as UpfrontTargetGroupPrice,
    };
  }

  private getSupplyGroupSummary(supplyGroup: SupplyGroup, quotas: readonly QuotaWithBuckets[]): SupplyGroupSummary {
    const matchingQuota = quotas.find((q) => q.name === `[${supplyGroup.name}]`);
    return {
      id: supplyGroup.id,
      name: supplyGroup.name,
      source: supplyGroup.source,
      panelIds: supplyGroup.panelIds,
      customCpi: supplyGroup.customCpi,
      ccpiSettings: supplyGroup.ccpiSettings,
      useCustomCpi: supplyGroup.useCustomCpi,
      quotaMatchId: matchingQuota?.matchId,
    };
  }

  private addNewWithPersistedProperties(
    persistedTg: TargetGroupModel,
    activeTgId: number,
    tgMatchInList: TargetGroup
  ): TargetGroup {
    const tg: TargetGroup = {
      id: persistedTg.id,
      name: persistedTg.name,

      hasDeprecation: deprecationService.hasAnyDeprecation(persistedTg.deprecation) || false,
      definition: tgMatchInList
        ? tgMatchInList.definition
        : {
            isUpdating: persistedTg.id === activeTgId,
            isValid: true,
            country: persistedTg.basicSettings.countryName,
            estimatedIr: persistedTg.basicSettings.estimatedIncidenceRate,
            estimatedLoi: persistedTg.basicSettings.estimatedLengthOfInterview,
            quotaCount: persistedTg.quotas.quotas.length,
            selectedProfilingAttributesCount: keys(persistedTg.profiling.selectedVariables).length,
            supplySummary: filters.supplySourceFormatter()(persistedTg),
            supplySource: persistedTg.panels.supplySource,
            surveyMetadataTagsCount: persistedTg.surveyMetadata?.studyTypes.length ?? 0,
          },
      feasibility: tgMatchInList
        ? tgMatchInList.feasibility
        : ({
            isFeasible: undefined,
            isRunning: true,
          } as TargetGroupFeasibility),
      price: tgMatchInList ? tgMatchInList.price : ({} as UpfrontTargetGroupPrice),
    };
    tg.definition.isActive = persistedTg.id === activeTgId;
    return tg;
  }

  private hasCensus(targetGroupModel: TargetGroupModel): boolean {
    return some(targetGroupModel.quotas.quotas, (q) => q.quotaOption === QuotaOptions.Completes);
  }
}

export const targetGroupListHelper = new TargetGroupListHelper();
