import { ActiveExcludeProjectsModel, ExcludedProject } from '../../models/active-exclude-projects.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { excludeProjectsSubactions } from './exclude-projects.subactions';
import { ExcludedRespondentStatus, ExcludeStatusTimeline } from '../../../../common/enums';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { stateDebugService } from '../../../../common/state-debug.service';

type ExcludeProjectsUpdateFunc = (
  state: ActiveTargetGroupModel,
  commit: CommitFuncs,
  done: (preventFeasibility?: boolean) => void
) => void;

export const commitExcludeProjects =
  () =>
  (excludeProjectsState: ActiveExcludeProjectsModel): void => {
    activeTargetGroupStore.model.excludeProjects = excludeProjectsState;
    targetGroupChannel.model.excludeProjects.updated.dispatch();
  };

export class ExcludeProjectsActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  getProjects(textFilter?: string, idFilter?: string, fromDate?: string, toDate?: string, paginationToken?: string) {
    this.update(
      (state, commit, done) => {
        excludeProjectsSubactions
          .fetchProjects(
            state.excludeProjects,
            state.basicSettings.countryId,
            fromDate,
            toDate,
            textFilter,
            idFilter,
            paginationToken
          )
          .then((newState) => {
            commit.excludeProjects(newState);
            done(true);
          });
      },
      { doNotStartSpinner: true }
    );
  }

  getSelectedProjects() {
    this.update((state, commit, done) => {
      excludeProjectsSubactions.getSelectedProjects(state.excludeProjects).then((newState) => {
        commit.excludeProjects(newState);
        done();
      });
    });
  }

  setSelectedProjects(excludeProjects: ExcludedProject[]) {
    this.update((state, commit, done) => {
      const newState = excludeProjectsSubactions.setSelectedProjects(state.excludeProjects, excludeProjects);
      commit.excludeProjects(newState);
      done();
    });
  }

  toggleProject(projectId: number) {
    this.update((state, commit, done) => {
      const newState = excludeProjectsSubactions.toggleProject(state.excludeProjects, projectId);
      commit.excludeProjects(newState);
      done();
    });
  }

  toggleStatus(status: ExcludedRespondentStatus) {
    this.update((state, commit, done) => {
      const newState = excludeProjectsSubactions.toggleStatus(state.excludeProjects, status);
      commit.excludeProjects(newState);
      done();
    });
  }

  setStatusTimeline(status: ExcludedRespondentStatus, timeline: ExcludeStatusTimeline) {
    this.update((state, commit, done) => {
      const newState = excludeProjectsSubactions.setStatusTimeline(state.excludeProjects, status, timeline);
      commit.excludeProjects(newState);
      done();
    });
  }

  discard() {
    this.update((state, commit, done) => {
      const newState = excludeProjectsSubactions.removeExclusions(state.excludeProjects);
      commit.excludeProjects(newState);
      done();
    });
  }

  private update(updateFunc: ExcludeProjectsUpdateFunc, options?: { doNotStartSpinner: boolean }) {
    stateDebugService.logIfUnsafeUpdate('exclude projects');
    if (!options || !options.doNotStartSpinner) {
      this.rootStore.signalUpdateStarted();
    }

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, (preventFeasibility?: boolean) =>
      this.rootStore.publishUpdate(preventFeasibility)
    );
  }
}
