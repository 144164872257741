import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { analytics } from '../analytics';

const directiveName = 'trackKbLink';

export function trackKbLink(): IDirective {
  return {
    link: (_scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) => {
      if (attrs[directiveName]) {
        element.bind('click', () => {
          analytics.help.visitKnowledgeBase(attrs[directiveName]);
        });
      }
    },
  };
}

export const ngTrackKbLinkDirective = {
  [directiveName]: trackKbLink,
};
