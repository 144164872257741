import { IDirective } from 'angular';
import { featureFlipper } from '../feature-flipper';

const directiveName = 'featureOff';

export function featureOff(): IDirective {
  return {
    link: (_scope, element, attrs) => {
      const featureId = attrs[directiveName];

      if (featureFlipper.isEnabled(featureId)) {
        element.remove();
      }
    },
  };
}

export const ngFeatureOffDirective = {
  [directiveName]: featureOff,
};
