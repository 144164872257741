import { head, some, groupBy, isEqual } from 'lodash-es';
import { toJson } from 'angular';
import { ActiveQuotasModel } from '../../models/active-quotas.model';
import { QuotaOptions } from '../../../../common/enums';
import { mutators } from './quotas.mutators';
import {
  getEvenlyDistributedCompletes as getEvenlyDistributedCompletesOrStarts,
  getCensusDistributedCompletesOrStarts,
  filterGenderQuotas,
  filterAgeQuotas,
  filterRegionQuotas,
  filterProfilingQuotas,
  parseQuestionIdFromProfilingQuotaKeys,
  filterInterlockedQuotas,
  isNonInterlocked,
  normalizeMixedQuotas,
  getPresetDistributedCompletesOrStarts,
  quotaTargetingTypesToEnum,
  sanitizeCensusAgeSpans,
} from '../../../quotas/quotas-helper.functions';
import { DetailedSelectionItemQuestion } from '../../models/active-profiling.model';
import { AgeSpan, Census, AgeCensus } from '../../models/active-target-group.model';
import { QuotasModel, WeightingStrategy } from '../../../../common/models/quotas.model';
import { QuotaWithBuckets, Bucket } from '../../../quotas/quota-bucket-builder';
import { Feasibility } from '../../../../common/http-services/feasibility.response';
import { logProduce } from '../../../../common/immer-wrapper';
import { QuotaGroupPreset } from '../../../../common/api';

export class QuotasSubactions {
  clearGenderQuotas(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.clearGenderQuotas());
  }

  createGenderQuotas(
    state: ActiveQuotasModel,
    option: QuotaOptions,
    completesRawOrPercent: number,
    startsRawOrPercent: number = undefined,
    ignoreCompletes: boolean,
    quotaPreset: QuotaGroupPreset = undefined
  ): ActiveQuotasModel {
    const completesDistribution = this.getDistributedCompletesOrStarts(
      option,
      completesRawOrPercent,
      2,
      'gender',
      undefined,
      'completes',
      quotaPreset
    );
    const startsDistribution = startsRawOrPercent
      ? this.getDistributedCompletesOrStarts(option, startsRawOrPercent, 2, 'gender', undefined, 'starts', quotaPreset)
      : undefined;
    return logProduce(
      state,
      mutators.createGenderQuotas(option, completesDistribution, startsDistribution, ignoreCompletes)
    );
  }

  clearAgeQuotas(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.clearAgeQuotas());
  }

  createAgeQuotas(
    state: ActiveQuotasModel,
    option: QuotaOptions,
    ageSpans: readonly AgeSpan[],
    completesRawOrPercent: number,
    startsRawOrPercent: number | undefined = undefined,
    ignoreCompletes: boolean,
    extrapolatedAgeCensus = { hasExtrapolatedAgeCensus: false, maxAgeInCensus: null as number },
    quotaPreset: QuotaGroupPreset = undefined
  ): ActiveQuotasModel {
    const completesDistribution = this.getDistributedCompletesOrStarts(
      option,
      completesRawOrPercent,
      ageSpans.length,
      'ageSpan',
      undefined,
      'completes',
      quotaPreset
    );
    const startsDistribution = startsRawOrPercent
      ? this.getDistributedCompletesOrStarts(
          option,
          startsRawOrPercent,
          ageSpans.length,
          'ageSpan',
          undefined,
          'starts',
          quotaPreset
        )
      : undefined;
    return logProduce(
      state,
      mutators.createAgeQuotas(
        option,
        ageSpans,
        completesDistribution,
        startsDistribution,
        ignoreCompletes,
        extrapolatedAgeCensus
      )
    );
  }

  clearRegionQuotas(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.clearRegionQuotas());
  }

  createRegionQuotas(
    state: ActiveQuotasModel,
    option: QuotaOptions,
    regionIds: readonly number[][],
    completesRawOrPercent: number,
    startsRawOrPercent: number | undefined = undefined,
    ignoreCompletes: boolean,
    quotaPreset: QuotaGroupPreset = undefined
  ): ActiveQuotasModel {
    const completesDistribution = this.getDistributedCompletesOrStarts(
      option,
      completesRawOrPercent,
      regionIds.length,
      'region',
      regionIds,
      'completes',
      quotaPreset
    );
    const startsDistribution = startsRawOrPercent
      ? this.getDistributedCompletesOrStarts(
          option,
          startsRawOrPercent,
          regionIds.length,
          'region',
          regionIds,
          'starts',
          quotaPreset
        )
      : undefined;
    return logProduce(
      state,
      mutators.createRegionQuotas(option, regionIds, completesDistribution, startsDistribution, ignoreCompletes)
    );
  }

  clearProfilingQuotas(state: ActiveQuotasModel, questionId: number): ActiveQuotasModel {
    return logProduce(state, mutators.clearProfilingQuotas(questionId));
  }

  createProfilingQuotas(
    state: ActiveQuotasModel,
    option: QuotaOptions,
    questionId: number,
    variableIds: readonly number[][],
    completesRawOrPercent: number,
    startsRawOrPercent: number | undefined = undefined,
    quotaPreset: QuotaGroupPreset = undefined,
    censusKey?: keyof Census
  ): ActiveQuotasModel {
    const completesDistribution = this.getDistributedCompletesOrStarts(
      option,
      completesRawOrPercent,
      variableIds.length,
      censusKey,
      undefined,
      'completes',
      quotaPreset,
      questionId
    );
    const startsDistribution = startsRawOrPercent
      ? this.getDistributedCompletesOrStarts(
          option,
          startsRawOrPercent,
          variableIds.length,
          censusKey,
          undefined,
          'starts',
          quotaPreset,
          questionId
        )
      : undefined;
    return logProduce(
      state,
      mutators.createProfilingQuotas(option, questionId, variableIds, completesDistribution, startsDistribution)
    );
  }

  createProfilingQuotaSection(state: ActiveQuotasModel, questionId: number): ActiveQuotasModel {
    return logProduce(state, mutators.createProfilingQuotaSection(questionId));
  }

  removeProfilingQuotaSections(state: ActiveQuotasModel, ...questionIds: readonly number[]): ActiveQuotasModel {
    return logProduce(state, mutators.removeProfilingQuotaSections(questionIds));
  }

  removeAllProfilingQuotaSections(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.removeAllProfilingQuotaSections());
  }

  removeAllQuotas(state: ActiveQuotasModel, selectedQuestionIds: readonly number[]): ActiveQuotasModel {
    return logProduce(state, mutators.removeAllQuotas(selectedQuestionIds));
  }

  restoreQuotas(
    state: ActiveQuotasModel,
    census: Census,
    quotasModel: QuotasModel,
    selectedQuestions: readonly DetailedSelectionItemQuestion[],
    numberOfCompletes: number,
    numberOfStarts: number | undefined,
    selectedRegionIds?: number[][]
  ): ActiveQuotasModel {
    const { quotas, buckets, sectionsUsingPanelDistribution, sectionsUsingIgnoreCompletes } = quotasModel;
    const normalizedQuotas = normalizeMixedQuotas(quotas, state.usePercentages, numberOfCompletes, numberOfStarts);
    const genderQuotas = normalizedQuotas.filter(filterGenderQuotas);
    const ageQuotas = normalizedQuotas.filter(filterAgeQuotas);
    const regionQuotas = normalizedQuotas.filter(filterRegionQuotas);
    const profilingQuotas = normalizedQuotas.filter(filterProfilingQuotas);
    const interlockedQuotas = normalizedQuotas.filter(filterInterlockedQuotas);

    let newState = state;
    newState = this.restoreGenderQuotas(
      newState,
      genderQuotas,
      buckets,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes
    );
    newState = this.restoreAgeQuotas(
      newState,
      ageQuotas,
      census.ageSpan,
      buckets,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes
    );
    newState = this.restoreRegionQuotas(
      newState,
      regionQuotas,
      buckets,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes,
      selectedRegionIds
    );
    newState = this.restoreProfilingQuotas(
      newState,
      profilingQuotas,
      buckets,
      selectedQuestions,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes
    );
    newState = this.restoreInterlockedQuotas(
      newState,
      interlockedQuotas,
      buckets,
      selectedQuestions,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes
    );
    return newState;
  }

  restoreGenderQuotas(
    state: ActiveQuotasModel,
    genderQuotas: readonly QuotaWithBuckets[],
    buckets: readonly Bucket[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      if (!genderQuotas.length) return;

      const ignoreCompletes = some(sectionsUsingIgnoreCompletes, (s) => isEqual(s, ['gender']));

      switch (head(genderQuotas).quotaOption) {
        case QuotaOptions.Completes: {
          const completes = getCensusDistributedCompletesOrStarts('gender').map((c) => c.value);
          const starts = getCensusDistributedCompletesOrStarts('gender', 'starts')?.map((c) => c.value);
          mutators.createGenderQuotas(QuotaOptions.Completes, completes, starts, ignoreCompletes)(draft);
          break;
        }
        case QuotaOptions.Custom: {
          const usePanelDistribution = some(sectionsUsingPanelDistribution, (s) => isEqual(s, ['gender']));
          mutators.restoreGenderQuotas(genderQuotas, buckets, usePanelDistribution, ignoreCompletes)(draft);
          break;
        }
        case QuotaOptions.None:
          throw Error('cannot create quotas for option `None`');
        default:
          throw Error('not yet implemented');
      }
    });
  }

  restoreAgeQuotas(
    state: ActiveQuotasModel,
    ageQuotas: readonly QuotaWithBuckets[],
    census: AgeCensus,
    buckets: readonly Bucket[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      if (!ageQuotas.length) return;

      const ignoreCompletes = some(sectionsUsingIgnoreCompletes, (s) => isEqual(s, ['ageSpan']));

      switch (head(ageQuotas).quotaOption) {
        case QuotaOptions.Completes: {
          const completesRawOrPercent = getCensusDistributedCompletesOrStarts('ageSpan').map((c) => c.value);
          const startsRawOrPercent = getCensusDistributedCompletesOrStarts('ageSpan', 'starts')?.map((c) => c.value);
          const [sortedCensusAgeSpans, hasExtrapolatedAgeCensus, maxAgeInCensus] = sanitizeCensusAgeSpans(
            census.quotas
          );
          mutators.createAgeQuotas(
            QuotaOptions.Completes,
            sortedCensusAgeSpans,
            completesRawOrPercent,
            startsRawOrPercent,
            ignoreCompletes,
            { hasExtrapolatedAgeCensus, maxAgeInCensus }
          )(draft);
          break;
        }
        case QuotaOptions.Custom: {
          const usePanelDistribution = some(sectionsUsingPanelDistribution, (s) => isEqual(s, ['ageSpan']));
          mutators.restoreAgeQuotas(ageQuotas, buckets, usePanelDistribution, ignoreCompletes)(draft);
          break;
        }
        case QuotaOptions.None:
          throw Error('cannot create quotas for option `None`');
        default:
          throw Error('not yet implemented');
      }
    });
  }

  restoreRegionQuotas(
    state: ActiveQuotasModel,
    regionQuotas: readonly QuotaWithBuckets[],
    buckets: readonly Bucket[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[],
    selectedRegionIds: number[][]
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      if (!regionQuotas.length) return;

      const ignoreCompletes = some(sectionsUsingIgnoreCompletes, (s) => isEqual(s, ['region']));

      switch (head(regionQuotas).quotaOption) {
        case QuotaOptions.Completes: {
          const completesRawOrPercent = getCensusDistributedCompletesOrStarts(
            'region',
            'completes',
            selectedRegionIds
          ).map((c) => c.value);
          const startsRawOrPercent = getCensusDistributedCompletesOrStarts('region', 'starts', selectedRegionIds)?.map(
            (c) => c.value
          );
          mutators.createRegionQuotas(
            QuotaOptions.Completes,
            selectedRegionIds,
            completesRawOrPercent,
            startsRawOrPercent,
            ignoreCompletes
          )(draft);
          break;
        }
        case QuotaOptions.Custom: {
          const usePanelDistribution = some(sectionsUsingPanelDistribution, (s) => isEqual(s, ['region']));
          mutators.restoreRegionQuotas(regionQuotas, buckets, usePanelDistribution, ignoreCompletes)(draft);

          break;
        }
        case QuotaOptions.None:
          throw Error('cannot create quotas for option `None`');
        default:
          throw Error('not yet implemented');
      }
    });
  }

  restoreProfilingQuotas(
    state: ActiveQuotasModel,
    profilingQuotas: readonly QuotaWithBuckets[],
    buckets: readonly Bucket[],
    selectedQuestions: readonly DetailedSelectionItemQuestion[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      if (!selectedQuestions.length) return;

      mutators.restoreProfilingQuotas(
        selectedQuestions,
        profilingQuotas,
        buckets,
        sectionsUsingPanelDistribution,
        sectionsUsingIgnoreCompletes
      )(draft);
    });
  }

  restoreInterlockedQuotas(
    state: ActiveQuotasModel,
    interlockedQuotas: readonly QuotaWithBuckets[],
    buckets: readonly Bucket[],
    selectedQuestions: readonly DetailedSelectionItemQuestion[],
    sectionsUsingPanelDistribution: readonly (readonly string[])[],
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[]
  ): ActiveQuotasModel {
    const groupedQuotas = groupBy(interlockedQuotas, (q) => toJson([...q.keys]));
    return logProduce(
      state,
      mutators.restoreInterlockedQuotas(
        groupedQuotas,
        buckets,
        selectedQuestions,
        sectionsUsingPanelDistribution,
        sectionsUsingIgnoreCompletes
      )
    );
  }

  interlock(
    state: ActiveQuotasModel,
    numberOfCompletes: number,
    numberOfStarts: number | undefined = undefined,
    keysToInterlock: readonly (readonly string[])[]
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      mutators.interlockSections(keysToInterlock, numberOfCompletes, numberOfStarts)(draft);

      if (some(keysToInterlock, (k) => isNonInterlocked('gender', k))) {
        mutators.clearGenderQuotas()(draft);
      }
      if (some(keysToInterlock, (k) => isNonInterlocked('ageSpan', k))) {
        mutators.clearAgeQuotas()(draft);
      }
      if (some(keysToInterlock, (k) => isNonInterlocked('region', k))) {
        mutators.clearRegionQuotas(true)(draft);
      }

      const interlockedQuestionIds = keysToInterlock
        .filter((key) => isNonInterlocked('profiling', key))
        .map((key) => parseQuestionIdFromProfilingQuotaKeys(key));

      for (const questionId of interlockedQuestionIds) {
        mutators.clearProfilingQuotas(questionId, true)(draft);
      }

      const interlockedSectionKeys = keysToInterlock.filter((k) => k.length > 1);
      for (const keys of interlockedSectionKeys) {
        mutators.removeInterlockedSection(keys)(draft);
      }
    });
  }

  removeInterlockedQuotasSection(state: ActiveQuotasModel, keys: readonly string[]): ActiveQuotasModel {
    return logProduce(state, mutators.removeInterlockedSection(keys));
  }

  removeDependentInterlockedSection(state: ActiveQuotasModel, key: string): ActiveQuotasModel {
    return logProduce(state, mutators.removeDependentInterlockedSection(key));
  }

  group(
    state: ActiveQuotasModel,
    quotaHashesToGroup: readonly string[],
    key: string,
    name: string | undefined
  ): ActiveQuotasModel {
    return logProduce(state, (draft) => {
      if (key === 'region') {
        mutators.groupRegionQuotas(quotaHashesToGroup, name)(draft);
      } else if (key.startsWith('profiling:')) {
        mutators.groupProfilingQuotas(quotaHashesToGroup, key, name)(draft);
      } else {
        throw Error(`key '${key}' has an invalid value`);
      }
    });
  }

  toggleUsePercentages(
    state: ActiveQuotasModel,
    totalWantedCompletes: number,
    totalWantedStarts: number | undefined = undefined
  ): ActiveQuotasModel {
    return logProduce(state, mutators.toggleUsePercentages(totalWantedCompletes, totalWantedStarts));
  }

  rescaleQuotaCompletes(
    state: ActiveQuotasModel,
    numberOfCompletes: number,
    selectedRegionIds?: number[][]
  ): ActiveQuotasModel {
    return logProduce(state, mutators.rescaleQuotaCompletes(numberOfCompletes, selectedRegionIds));
  }

  rescaleQuotaStarts(
    state: ActiveQuotasModel,
    numberOfStarts: number,
    selectedRegionIds?: number[][]
  ): ActiveQuotasModel {
    return logProduce(state, mutators.rescaleQuotaStarts(numberOfStarts, selectedRegionIds));
  }

  setWeightingStrategy(state: ActiveQuotasModel, strategy: WeightingStrategy): ActiveQuotasModel {
    return logProduce(state, mutators.setWeightingStrategy(strategy));
  }

  setQuotaCompletes(state: ActiveQuotasModel, hash: string, completes: number): ActiveQuotasModel {
    return logProduce(state, mutators.setQuotaCompletes(hash, completes));
  }

  setQuotaStarts(state: ActiveQuotasModel, hash: string, starts: number): ActiveQuotasModel {
    return logProduce(state, mutators.setQuotaStarts(hash, starts));
  }

  initQuotaStarts(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.initQuotaStarts());
  }

  toggleUsePanelDistribution(
    state: ActiveQuotasModel,
    keys: readonly string[],
    numberOfCompletes: number,
    numberOfStarts: number | undefined = undefined
  ): ActiveQuotasModel {
    return logProduce(
      state,
      mutators.toggleUsePanelDistribution(keys, numberOfCompletes, numberOfStarts, state.usePercentages)
    );
  }

  ignoreCompletes(state: ActiveQuotasModel, keys: readonly string[], ignoreCompletes: boolean): ActiveQuotasModel {
    return logProduce(state, mutators.ignoreCompletes(keys, ignoreCompletes));
  }

  clearAllIgnoreCompletes(state: ActiveQuotasModel): ActiveQuotasModel {
    return logProduce(state, mutators.clearAllIgnoreCompletes());
  }

  // TODO: Should probably test this one
  rescaleCompletesAndStartsAccordingToPanelDistribution(
    state: ActiveQuotasModel,
    sectionKeys: readonly (readonly string[])[],
    feasibility: Feasibility,
    matchIds: { [hash: string]: number },
    numberOfCompletes: number,
    numberOfStarts: number | undefined = undefined
  ): ActiveQuotasModel {
    return logProduce(
      state,
      mutators.rescaleCompletesAndStartsAccordingToPanelDistribution(
        sectionKeys,
        feasibility,
        matchIds,
        numberOfCompletes,
        numberOfStarts
      )
    );
  }

  rescaleCompletesAccordingToStartsDistribution(
    state: ActiveQuotasModel,
    sectionKeys: readonly (readonly string[])[],
    numberOfCompletes: number
  ): ActiveQuotasModel {
    return logProduce(state, mutators.rescaleCompletesAccordingToStartsDistribution(sectionKeys, numberOfCompletes));
  }

  toggleOffPanelDistributionForAllQuotas(state: ActiveQuotasModel, numberOfCompletes: number): ActiveQuotasModel {
    return logProduce(state, mutators.toggleOffPanelDistributionForAllQuotas(numberOfCompletes));
  }

  private getDistributedCompletesOrStarts(
    option: QuotaOptions,
    amountRawOrPercent: number,
    quotasCount: number,
    censusKey?: keyof Census,
    selectedRegionIds?: number[][] | readonly number[][],
    quotaType: 'completes' | 'starts' = 'completes',
    quotaPreset: QuotaGroupPreset = undefined,
    questionId: number = undefined
  ): number[] {
    switch (option) {
      case QuotaOptions.Custom: {
        if (quotaPreset !== undefined) {
          return getPresetDistributedCompletesOrStarts(
            quotaTargetingTypesToEnum(quotaPreset.targetingTypes),
            quotaType,
            quotaPreset
          );
        }
        return getEvenlyDistributedCompletesOrStarts(amountRawOrPercent, quotasCount);
      }
      case QuotaOptions.Completes:
        return getCensusDistributedCompletesOrStarts(censusKey, quotaType, selectedRegionIds, questionId).map(
          (c) => c.value
        );
      default:
        throw Error('not supported');
    }
  }
}

export const quotasSubactions = new QuotasSubactions();
