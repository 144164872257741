import { map, find, isEmpty } from 'lodash-es';
import { keys } from '../helpers';

export function normalize(top: number, list: any[], property: string): any[] {
  if (isEmpty(list)) return list;

  const result = list.slice();

  const getGroupsWithSpreadRemainder = (suggestedRounding: any[], rest: number) => {
    if (rest === 0) return suggestedRounding;

    const toAdd = rest > 0 ? 1 : -1;
    rest *= toAdd;

    const sortedValues = suggestedRounding.sort((a, b) => b[property] - a[property]);
    while (rest > 0) {
      for (const value of sortedValues) {
        if (rest === 0) break;

        if (value[property] > 0) {
          value[property] += toAdd;
        } else {
          value[property] += 1;
        }

        rest--;
      }
    }

    return suggestedRounding;
  };

  const hasProperty = result.every((item) => item[property] !== undefined);

  // here we monkey patch on an index that we remove further down. do we REALLY need this index? JS arrays have guaranteed order
  for (const key of keys(result)) {
    const index = Number(key);
    (result as any)[key].index = index;
  }

  const groups = map(result, (item) => {
    return { index: item.index, [property]: item[property] };
  });

  if (hasProperty) {
    const total = groups.reduce((acc, item) => acc + item[property], 0);
    let multiplier = 1;
    if (total !== 0) {
      multiplier = Number(top) / total;
    }

    const rounded = map(groups, (g) => {
      return { index: g.index, [property]: Math.round(g[property] * multiplier) };
    });

    const remainder = rounded.reduce((acc, item) => acc - item[property], top);
    const adjustedRounding = getGroupsWithSpreadRemainder(rounded, remainder);

    for (const item of result) {
      item[property] = find(adjustedRounding, (r) => r.index === item.index)[property];
    }

    for (const item of result) {
      delete item.index;
    }
  }

  // We should probably not return this result if the above check fails, because the way we use normalize (for census)
  // means if this step doesn't happen it will mess up the result completely. Also it will hide potential bugs.
  // Maybe it should just throw an exception?
  return result;
}
