import { ListenerComponent } from './channels/channel';
import { targetGroupChannel } from '../target-groups/channels/target-group-channel';
import { errorLogger } from './error-logger';

class StateDebugService extends ListenerComponent {
  isRestoring: boolean;
  constructor() {
    super();
    targetGroupChannel.model.restoring.listen(() => (this.isRestoring = true), this);
    targetGroupChannel.model.restored.listen(() => (this.isRestoring = false), this);
  }

  logIfUnsafeUpdate(actionSource: string) {
    if (this.isRestoring) {
      errorLogger.warning('{ActionSource} action called while restoring, needs fixing!', actionSource);
      console.error(`${actionSource} action called while restoring, needs fixing!`);
    }
  }
}

export const stateDebugService = new StateDebugService();
