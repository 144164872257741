import { IDirective } from 'angular';

const directiveName = 'spinWhileLoading';

export function spinWhileLoading(): IDirective {
  return {
    transclude: true,
    restrict: 'E',
    scope: {
      condition: '=',
    },
    template: `
          <div class="wait-small inline pull-right" style="width: 15px" ng-hide="condition">&nbsp;</div>
          <span ng-show="condition" ng-transclude></span>
        `,
  };
}

export const ngSpinWhileLoadingDirective = {
  [directiveName]: spinWhileLoading,
};
