import { IDirective } from 'angular';
import { session } from '../session';
import { Product } from '../enums';

const directiveName = 'hideForProduct';

export function hideForProduct(): IDirective {
  return {
    link(scope, element, attrs) {
      const attr = attrs[directiveName];
      if (attr == null || attr === '') return;

      const forbiddenProduct = attr as Product;
      scope.$watch(
        () => session.product,
        () => {
          if (session.product === forbiddenProduct) {
            element.remove();
          }
        }
      );
    },
  };
}

export const ngHideForProductDirective = {
  [directiveName]: hideForProduct,
};
