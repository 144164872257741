import './drop-file.directive.scss';
import { INgModelController, IScope, IAugmentedJQuery, IAttributes } from 'angular';
import { DirectiveUsingNgModelController } from './directive.types';

const directiveName = 'dropFile';

interface DropFileEventObject {
  preventDefault: Function;
  stopPropagation: Function;
  dataTransfer: any;
}

interface DirectiveScope extends IScope {
  accept?: string;
}

export function dropFile(): DirectiveUsingNgModelController {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      accept: '@',
    },
    link: (scope: DirectiveScope, elm: IAugmentedJQuery, _attrs: IAttributes, ngModelCtrl: INgModelController) => {
      const acceptedExtensions = scope.accept.split(',');
      elm.addClass('drag-inactive');
      elm.bind('dragleave', (e) => {
        e.preventDefault();
        e.stopPropagation();
        elm.removeClass('drag-highlight');
      });
      elm.bind('dragenter', (e) => {
        e.preventDefault();
        e.stopPropagation();
        elm.addClass('drag-highlight');
      });
      elm.bind('drop', (e) => {
        e.preventDefault();
        e.stopPropagation();

        elm.removeClass('drag-highlight');

        const dragData = (e as unknown as DropFileEventObject).dataTransfer;
        if (dragData && dragData.files.length > 0) {
          const validFiles = filterFiles(dragData.files);
          if (!validFiles.length) return;
          ngModelCtrl.$setViewValue(validFiles);
        }
      });

      const filterFiles = (fileList: FileList): File[] => {
        const files = Array.from(fileList);
        if (!acceptedExtensions || !acceptedExtensions.length) return files;
        return files.filter((f) => acceptedExtensions.find((ext) => f.name.endsWith(ext)));
      };
    },
  };
}

export const ngDropFileDirective = {
  [directiveName]: dropFile,
};
