import { ui } from 'angular';
import { ConfirmationDialogComponentName } from './ConfirmationDialog';

export interface ConfirmationDialogResolve {
  header: string;
  message: string | JSX.Element;
  callbackFactory?: (modalInstance: ui.bootstrap.IModalInstanceService) => () => void;
  cancelCaption?: string;
  okayCaption?: string;
  useDangerColor?: boolean;
}

export class ConfirmationDialogSettingsFactory {
  static create(resolve?: ConfirmationDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'confirmation-dialog',
      component: ConfirmationDialogComponentName,
      resolve: {
        header: () => resolve?.header ?? '',
        message: () => resolve?.message ?? '',
        callbackFactory: () => resolve?.callbackFactory,
        cancelCaption: () => resolve?.cancelCaption,
        okayCaption: () => resolve?.okayCaption,
        useDangerColor: () => resolve?.useDangerColor,
      },
    };
  }
}
