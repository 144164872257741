export interface SurveyMetadataModel {
  // this property should be renamed/replaced with selectedTags - matched with BE. But keep in mind existing drafts etc.
  readonly studyTypes: string[];
}

export function createSurveyMetadataModel(): SurveyMetadataModel {
  return {
    studyTypes: [],
  };
}
