import MiniSignal, { MiniSignalBinding } from 'mini-signals';
import { useEffect, useState } from 'react';

export class Listener {
  protected signals: MiniSignal.MiniSignalBinding[];
  protected contextName: string;
  protected enableLogging: boolean;

  constructor() {
    this.signals = [];
    this.enableLogging = false;
    if (__PROCESS__.ENV === 'development') {
      this.enableLogging = window.sessionStorage.getItem('globalDebugOptions:logPubSubToConsole') === 'true';
    }
  }

  getContextName(context: any): string {
    return this.contextName || (context.constructor || {}).name || 'no name';
  }

  registerSignal(signal: MiniSignal.MiniSignalBinding, context: Listener): void {
    this.contextName = this.getContextName(context);
    this.signals.push(signal);
    if (this.enableLogging) {
      console.debug(`registered signal for [${this.contextName}]`);
    }
  }
}

export class ListenerComponent extends Listener {
  getContextName(context: any): string {
    return this.contextName || (context.constructor || {}).componentName || 'no name';
  }

  $onDestroy(): void {
    if (this.enableLogging) {
      console.debug(`in '$onDestroy' for [${this.contextName}]`);
    }
    this.doCleanup();
  }

  private doCleanup() {
    const total = this.signals.length;
    let count = 0;
    for (const signal of this.signals) {
      signal.detach();
      count++;
    }
    if (this.enableLogging) {
      console.debug(`detached ${count} signals out of ${total} for [${this.contextName}]`);
    }
  }
}

export class Channel {
  private signal: MiniSignal;

  constructor() {
    this.signal = new MiniSignal();
  }

  listen(listener: () => void, context: Listener): void {
    const binding = this.signal.add(listener, context);
    context.registerSignal(binding, context);
  }

  /** IMPORTANT!! Don't use directly, use either useDataChannel or useChannel hook instead */
  listenReact(listener: () => void): MiniSignalBinding {
    return this.signal.add(listener);
  }

  dispatch(): void {
    this.signal.dispatch();
  }

  removeAll(): void {
    this.signal.detachAll();
  }
}

export function useDataChannel<T>(ch: DataChannel<T>, defaultValue: T) {
  const [value, setValue] = useState<T>(defaultValue);
  useEffect(() => {
    const b = ch.listenReact(setValue);
    return () => {
      b.detach();
    };
  }, [ch]);

  return value;
}

export function useChannel(ch: Channel, cb: () => void): void;
export function useChannel<T>(ch: DataChannel<T>, cb: (t: T) => void): void;
export function useChannel<T>(ch: Channel | DataChannel<T>, cb: (t?: T) => void) {
  useEffect(() => {
    const b = ch.listenReact(cb);
    return () => {
      b.detach();
    };
  }, [ch, cb]);
}

export class DataChannel<T> {
  private signal: MiniSignal;

  constructor() {
    this.signal = new MiniSignal();
  }

  listen(listener: (t: T) => void, context: Listener): void {
    const binding = this.signal.add(listener, context);
    context.registerSignal(binding, context);
  }

  listenReact(listener: (t: T) => void): MiniSignalBinding {
    return this.signal.add(listener);
  }

  dispatch(data: T): void {
    this.signal.dispatch(data);
  }

  removeAll(): void {
    this.signal.detachAll();
  }
}
