import { RequestMode, TargetGroupModel, ProjectTemplateSource } from '../../common/models/target-group.model';
import { Constants } from '../../constants';
import { PanelistPoolType } from '../../common/enums';
import { ExcludeProjectsModel } from '../../common/models/exclude-projects.model';

export class ProfilingDepthRequest {
  countryId: number;
  gender: string;
  minAge: number;
  maxAge: number;
  wantedNumberOfCompletes: number;
  estimatedIncidenceRate: number;
  estimatedLengthOfInterview: number;
  startDate: string;
  numberOfDaysInField: number;
  panelIds: number[];
  poolId: string;
  panelistPoolType: 'none' | 'exclusive' | 'inclusive';
  panelSpecificProfiling: boolean;
  regionIds: number[];
  profilingVariables: {
    id: number;
  }[];
  excludeProjects: ExcludeProjectsModel;
  requestMode: RequestMode;
  projectTemplateSource: ProjectTemplateSource;

  constructor(targetGroup: TargetGroupModel) {
    this.countryId = targetGroup.basicSettings.countryId;
    this.gender = targetGroup.basicSettings.gender;
    this.gender = targetGroup.basicSettings.gender;
    this.minAge = targetGroup.basicSettings.minAge;
    this.maxAge = targetGroup.basicSettings.maxAge;
    this.wantedNumberOfCompletes = targetGroup.basicSettings.numberOfCompletes;
    this.estimatedIncidenceRate = targetGroup.basicSettings.estimatedIncidenceRate;
    this.estimatedLengthOfInterview = targetGroup.basicSettings.estimatedLengthOfInterview;
    this.startDate = targetGroup.basicSettings.startDate;
    this.numberOfDaysInField = targetGroup.basicSettings.numberOfDaysInField;
    this.panelIds = targetGroup.panels.selectedIds || [];
    this.poolId = targetGroup.panels.panelistPool.selectedPoolId || Constants.emptyGuid;
    this.panelistPoolType = targetGroup.panels.panelistPool.type || PanelistPoolType.None;
    this.panelSpecificProfiling = targetGroup.profiling.panelSpecificProfiling;
    this.regionIds = targetGroup.regions.selectedIds || [];
    this.profilingVariables = Object.keys(targetGroup.profiling.selectedVariables).map((id) => ({ id: Number(id) }));
    this.excludeProjects = targetGroup.excludeProjects;
    this.requestMode = { includeLockedPanels: targetGroup.requestMode.includeLockedPanels };
    this.projectTemplateSource = targetGroup.projectTemplateSource;
  }
}
