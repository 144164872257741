import { IPromise } from 'angular';
import { ExcludedRespondentStatus, ExcludeStatusTimeline } from '../../../../common/enums';
import { ActiveExcludeProjectsModel, ExcludedProject } from '../../models/active-exclude-projects.model';
import { api } from '../../../../common/api';
import { mutators } from './exclude-projects.mutators';
import { $q } from '../../../../ngimport';
import { logProduce } from '../../../../common/immer-wrapper';

export class ExcludeProjectsSubactions {
  fetchProjects(
    state: ActiveExcludeProjectsModel,
    countryId: number,
    fromDate?: string,
    toDate?: string,
    textFilter?: string,
    idFilter?: string,
    paginationToken?: string
  ): IPromise<ActiveExcludeProjectsModel> {
    if (countryId > 0) {
      return api.projectList
        .getProjectsForExclusion(countryId, fromDate, toDate, textFilter, idFilter, paginationToken)
        .then((res) => {
          return logProduce(state, (draft) => {
            const { projects, paginationToken: nextPaginationToken } = res.data;
            mutators.setAllProjects(projects, nextPaginationToken)(draft);
          });
        });
    }
    const newState = logProduce(state, mutators.clearAllClosedProjects());
    return $q.when(newState);
  }

  getSelectedProjects(state: ActiveExcludeProjectsModel): IPromise<ActiveExcludeProjectsModel> {
    return api.projectList.getProjectNames(state.selectedProjectIds).then((res) => {
      const projects = res.data.projects.map((p) => ({ ...p, startDate: null, selected: true }));
      return logProduce(state, mutators.setSelectedProjects(projects));
    });
  }

  setSelectedProjects(
    state: ActiveExcludeProjectsModel,
    excludeProjects: ExcludedProject[]
  ): ActiveExcludeProjectsModel {
    return logProduce(state, mutators.setSelectedProjects(excludeProjects));
  }

  toggleProject(state: ActiveExcludeProjectsModel, projectId: number): ActiveExcludeProjectsModel {
    return logProduce(state, mutators.toggleProject(projectId));
  }

  toggleStatus(state: ActiveExcludeProjectsModel, statusValue: ExcludedRespondentStatus): ActiveExcludeProjectsModel {
    return logProduce(state, mutators.toggleStatus(statusValue));
  }

  setStatusTimeline(
    state: ActiveExcludeProjectsModel,
    statusValue: ExcludedRespondentStatus,
    timeline: ExcludeStatusTimeline
  ): ActiveExcludeProjectsModel {
    return logProduce(state, mutators.setTimelineForStatus(statusValue, timeline));
  }

  removeExclusions(state: ActiveExcludeProjectsModel): ActiveExcludeProjectsModel {
    return logProduce(state, mutators.removeExclusion());
  }
}

export const excludeProjectsSubactions = new ExcludeProjectsSubactions();
