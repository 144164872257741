import { IHttpPromise } from 'angular';
import { Array, Boolean, Literal, Null, Record, Static, Union, String, Optional } from 'runtypes';
import { urlService } from './url.service';
import {
  CreateOrUpdateAdHocSupplierError,
  DisqualifiedPanelReason,
  ExternalIdsJobStatus,
  PanelistPoolSource,
  SupplySource,
} from '../enums';
import { AboveZero, GuidString, IsoLanguageName, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { $http } from '../../ngimport';
import { PanelistEntries } from '../models/supply.model';
import { asJsonScalarString } from '../../helpers';

export interface SupplyRequest {
  ir: string | number;
  loi: string | number;
  categoryIds: number[];
  filterOnPii: boolean;
}

export interface SpecificPanelsRequest {
  panelIds: number[];
}

export const PanelistGroupPanel = Record({
  id: AboveZero,
  isLocked: Boolean,
  panelistCount: NonNegative,
});
export type PanelistGroupPanel = Static<typeof PanelistGroupPanel>;

export const PanelistGroup = Record({
  panelistCount: NonNegative,
  panels: Array(PanelistGroupPanel),
  poolId: GuidString,
});
export type PanelistGroup = Static<typeof PanelistGroup>;

export const AvailablePanelistGroups = Record({
  privatePricing: PanelistGroup,
  ownPanels: PanelistGroup,
  opinionHub: PanelistGroup,
});
export type AvailablePanelistGroups = Static<typeof AvailablePanelistGroups>;

export const UnavailablePanelistCounts = Record({
  inactiveCount: NonNegative,
  adHocCount: NonNegative,
  pushSupplyCount: NonNegative,
  alreadyInPoolCount: NonNegative,
  countryMismatchCount: NonNegative,
  lockedCount: NonNegative,
  badUrl: NonNegative,
});
export type UnavailablePanelistCounts = Static<typeof UnavailablePanelistCounts>;

export const AvailabilityResultResponse = Record({
  availablePanelists: AvailablePanelistGroups,
  unavailableCounts: UnavailablePanelistCounts,
  unavailablePanelists: PanelistGroup,
});
export type AvailabilityResultResponse = Static<typeof AvailabilityResultResponse>;

export interface AvailabilityRequest {
  countryId: number;
  poolId: string;
  fromPanelistPoolApi: boolean;
}

export const SpecificPanelResponse = Array(
  Record({
    id: AboveZero,
    name: NonEmptyString,
    currencyCode: NonEmptyString,
    twoLetterIsoLanguageName: IsoLanguageName,
    userIsOwner: Boolean,
    hasPrivatePricing: Boolean,
    isPush: Boolean,
    privatePricingRateCardHash: String.Or(Null),
  })
);
export type SpecificPanelResponse = Static<typeof SpecificPanelResponse>;

export const AdHocSuppliersResponse = Array(
  Record({
    id: AboveZero,
    name: NonEmptyString,
  })
);
export type AdHocSuppliersResponse = Static<typeof AdHocSuppliersResponse>;

export const AdHocLanguagesResponse = Array(
  Record({
    id: AboveZero,
    name: NonEmptyString,
  })
);
export type AdHocLanguagesResponse = Static<typeof AdHocLanguagesResponse>;

export const AdHocSupplierErrorType = Union(
  Literal(CreateOrUpdateAdHocSupplierError.GeneralError),
  Literal(CreateOrUpdateAdHocSupplierError.AlreadyExists)
);
export type AdHocSupplierErrorType = Static<typeof AdHocSupplierErrorType>;

export const CreateOrUpdateAdHocSupplierResponse = Record({
  type: Optional(AdHocSupplierErrorType),
});
export type CreateOrUpdateAdHocSupplierResponse = Static<typeof CreateOrUpdateAdHocSupplierResponse>;

export const PanelResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  isLocked: Boolean,
  isDisqualified: Boolean,
  isPush: Boolean,
  supportsCustomCpi: Boolean,
  currencyCode: NonEmptyString,
  disqualifiedPanelReasons: Array(
    Union(
      Literal(DisqualifiedPanelReason.None),
      Literal(DisqualifiedPanelReason.Ir),
      Literal(DisqualifiedPanelReason.Loi),
      Literal(DisqualifiedPanelReason.Category),
      Literal(DisqualifiedPanelReason.AllowWebcamStudies),
      Literal(DisqualifiedPanelReason.AllowRespondentIdentifiableInformation)
    )
  ),
  privatePricingRateCardHash: String.Or(Null),
  tags: Array(String),
});
export type PanelResponse = Static<typeof PanelResponse>;

export const PanelSuspensionLimits = Record({
  panelId: AboveZero,
  ir: NonNegative.Or(Null),
  qf: NonNegative.Or(Null),
  cr: NonNegative.Or(Null),
  dor: NonNegative.Or(Null),
  loi: NonNegative.Or(Null),
});

export type PanelSuspensionLimits = Static<typeof PanelSuspensionLimits>;

export const PanelSuspensionLimitsResponse = Array(PanelSuspensionLimits);
export type PanelSuspensionLimitsResponse = Static<typeof PanelSuspensionLimitsResponse>;

export const SupplyResponse = Record({
  [SupplySource.CintPanels]: Array(PanelResponse),
  [SupplySource.OwnPanels]: Array(PanelResponse),
  [SupplySource.PrivatePricing]: Array(PanelResponse),
});
export type SupplyResponse = Static<typeof SupplyResponse>;

export const PanelInfoResponse = Record({
  name: NonEmptyString,
  companyName: NonEmptyString,
  currencyCode: NonEmptyString,
  information: String,
  bookedCount: NonNegative,
  panelistCount: NonNegative,
  categories: Array(NonEmptyString),
  isPush: Boolean,
  isOwnPanel: Boolean,
});
export type PanelInfoResponse = Static<typeof PanelInfoResponse>;

export const CreatePanelistPoolResponse = Record({
  poolId: GuidString,
  duplicationCount: NonNegative,
  receivedCount: NonNegative,
  committedCount: NonNegative,
  invalidIdCount: NonNegative,
});
export type CreatePanelistPoolResponse = Static<typeof CreatePanelistPoolResponse>;

export const ExternalIdsProgressResponse = Record({
  processedItems: NonNegative,
  status: Union(
    Literal(ExternalIdsJobStatus.None),
    Literal(ExternalIdsJobStatus.InProgress),
    Literal(ExternalIdsJobStatus.Succeeded),
    Literal(ExternalIdsJobStatus.Failed)
  ),
  matchedItems: NonNegative,
});
export type ExternalIdsProgressResponse = Static<typeof ExternalIdsProgressResponse>;
export class SupplyHttpService {
  createAdHocSupplier(name: string): IHttpPromise<CreateOrUpdateAdHocSupplierResponse> {
    return $http.validatingPost(
      CreateOrUpdateAdHocSupplierResponse,
      urlService.createAdHocSupplier(),
      asJsonScalarString(name)
    );
  }

  updateAdHocSupplier(supplierId: number, newName: string): IHttpPromise<CreateOrUpdateAdHocSupplierResponse> {
    return $http.validatingPost(CreateOrUpdateAdHocSupplierResponse, urlService.updateAdHocSupplier(supplierId), {
      name: newName,
    });
  }

  getPanelInfo(panelId: number): IHttpPromise<PanelInfoResponse> {
    return $http.validatingGet(PanelInfoResponse, urlService.getPanelInfo(panelId));
  }

  getPanelSuspensionLimits(panelIds: number[]): IHttpPromise<PanelSuspensionLimitsResponse> {
    return $http.validatingPost(PanelSuspensionLimitsResponse, urlService.getPanelSuspensionLimits(), panelIds);
  }

  getSpecificPanels(request: SpecificPanelsRequest): IHttpPromise<SpecificPanelResponse> {
    return $http.validatingPost(SpecificPanelResponse, urlService.getSpecificPanels(), request);
  }

  checkPanelistAvailability(request: AvailabilityRequest): IHttpPromise<AvailabilityResultResponse> {
    return $http.validatingPost(AvailabilityResultResponse, urlService.checkPanelistAvailability(), request);
  }

  getSupply(countryId: number, request: SupplyRequest): IHttpPromise<SupplyResponse> {
    return $http.validatingPost(SupplyResponse, urlService.getSupply(countryId), request);
  }

  getAdHocSuppliers(): IHttpPromise<AdHocSuppliersResponse> {
    return $http.validatingGet(AdHocSuppliersResponse, urlService.getAdHocSuppliers());
  }

  getAdHocSupplierLanguages(): IHttpPromise<AdHocLanguagesResponse> {
    return $http.validatingGet(AdHocLanguagesResponse, urlService.getAdHocSupplierLanguages());
  }

  createPanelistPoolFromUrl(url: string): IHttpPromise<CreatePanelistPoolResponse> {
    return $http.validatingPost(CreatePanelistPoolResponse, urlService.panelistPoolUrl(), asJsonScalarString(url));
  }

  createPanelistPoolFromExternalIds(lookupJobId: string): IHttpPromise<CreatePanelistPoolResponse> {
    return $http.validatingPost(
      CreatePanelistPoolResponse,
      urlService.panelistPoolExternalIdJob(),
      asJsonScalarString(lookupJobId)
    );
  }

  createPanelistPoolFromPanelistEntries(panelistEntries: PanelistEntries): IHttpPromise<CreatePanelistPoolResponse> {
    return $http.validatingPost(
      CreatePanelistPoolResponse,
      urlService.panelistPoolEntries(),
      asJsonScalarString(panelistEntries)
    );
  }

  uploadExternalIdsForTranslation(
    panelistEntries: PanelistEntries,
    poolSource: PanelistPoolSource
  ): IHttpPromise<string> {
    const requestBody = {
      type: poolSource,
      entries: panelistEntries,
    };
    return $http.validatingPost(NonEmptyString, urlService.externalIdsEntries(), requestBody);
  }

  getExternalIdsTranslationStatus(id: string): IHttpPromise<ExternalIdsProgressResponse> {
    return $http.validatingGet(ExternalIdsProgressResponse, urlService.externalIdsJobStatus(id));
  }
}

export const supplyHttpService = new SupplyHttpService();
