import { Literal, Record, Union, Boolean, Number, String, Array, Null, Static } from 'runtypes';
import {
  AlarmType,
  FeatureError,
  LinkSource,
  PanelistPoolType,
  ProjectChannel,
  ProjectStatus,
  QuotaGrouping,
  TargetGender,
  TargetGroupStatus,
} from '../enums';
import { AboveZero, GuidString, HttpUrl, Iso8601UtcString, NonEmptyString, NonNegative } from '../../custom-runtypes';
import { RespondentStatusTimeline } from './draft.httpservice';
import { EndPageSecurityType } from '../../target-groups/project-order/ProjectOrderLinksSetup';
import { Currency } from './pricing.httpservice';

export const LatestCriticalAlarmResponse = Record({
  type: Union(
    Literal(AlarmType.LengthOfInterview),
    Literal(AlarmType.ConversionRate),
    Literal(AlarmType.DropOutRate),
    Literal(AlarmType.IncidenceRate),
    Literal(AlarmType.QuotaFull)
  ),
  limitRange: Record({
    lower: Number.Or(Null),
    upper: Number.Or(Null),
  }),
  triggerValue: Number.Or(Null),
}).Or(Null);
export type LatestCriticalAlarmResponse = Static<typeof LatestCriticalAlarmResponse>;

export const ExistingTargetGroupRegionsResponse = Record({
  isPostalCodesCustomRegion: Boolean,
  regionTypeId: Number.Or(Null),
  items: Array(
    Record({
      id: AboveZero,
      name: NonEmptyString,
    })
  ),
});
export type ExistingTargetGroupRegionsResponse = Static<typeof ExistingTargetGroupRegionsResponse>;

export const ExistingTargetGroupQuestionResponse = Record({
  name: NonEmptyString,
  variables: Array(
    Record({
      id: AboveZero,
      name: NonEmptyString,
    })
  ),
});
export type ExistingTargetGroupQuestionResponse = Static<typeof ExistingTargetGroupQuestionResponse>;

export const ExistingTargetGroupProfilingResponse = Record({
  panelSpecificProfiling: Boolean,
  questions: Array(ExistingTargetGroupQuestionResponse),
});
export type ExistingTargetGroupProfilingResponse = Static<typeof ExistingTargetGroupProfilingResponse>;

export const ExistingTargetGroupQuotaResponse = Record({
  name: NonEmptyString,
  wantedCompletes: NonNegative,
  initialFeasible: NonNegative.Or(Null),
  actualCompletes: Number,
  wantedStarts: NonNegative.Or(Null),
  actualStarts: NonNegative,
  buckets: Array(AboveZero),
  quotaGrouping: Array(
    Union(
      Literal(QuotaGrouping.Ungrouped),
      Literal(QuotaGrouping.Age),
      Literal(QuotaGrouping.Gender),
      Literal(QuotaGrouping.Region),
      Literal(QuotaGrouping.Profiling),
      Literal(QuotaGrouping.Supply)
    )
  ),
});
export type ExistingTargetGroupQuotaResponse = Static<typeof ExistingTargetGroupQuotaResponse>;

export const ExistingTargetGroupQuotaSetupResponse = Record({
  hasUserDefinedQuotas: Boolean,
  quotas: Array(ExistingTargetGroupQuotaResponse),
});
export type ExistingTargetGroupQuotaSetupResponse = Static<typeof ExistingTargetGroupQuotaSetupResponse>;

export const ExistingTargetGroupExcludeProjectsResponse = Record({
  projects: Array(
    Record({
      id: AboveZero,
      name: NonEmptyString.Or(Null),
      respondentStatusTimelines: Array(RespondentStatusTimeline),
    })
  ),
  respondentStatusTimelines: Array(RespondentStatusTimeline),
});

export type ExistingTargetGroupExcludeProjectsResponse = Static<typeof ExistingTargetGroupExcludeProjectsResponse>;

export const ExistingPanelistPoolResponse = Record({
  panelIds: Array(AboveZero),
  poolId: GuidString,
  containsUrl: Boolean,
  type: Union(Literal(PanelistPoolType.None), Literal(PanelistPoolType.Inclusive), Literal(PanelistPoolType.Exclusive)),
});
export type ExistingPanelistPoolResponse = Static<typeof ExistingPanelistPoolResponse>;

export const ExistingTargetGroupResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  status: Union(
    Literal(TargetGroupStatus.Pending),
    Literal(TargetGroupStatus.Activated),
    Literal(TargetGroupStatus.Paused),
    Literal(TargetGroupStatus.Closed)
  ),
  isApprovedForSendout: Boolean,
  countryId: AboveZero,
  country: NonEmptyString,
  gender: Union(Literal(TargetGender.Both), Literal(TargetGender.Male), Literal(TargetGender.Female)),
  minAge: NonNegative,
  maxAge: NonNegative,
  estimatedIncidenceRate: AboveZero,
  estimatedLengthOfInterview: AboveZero,
  startDate: Iso8601UtcString,
  endDate: Iso8601UtcString,
  numberOfDaysInField: AboveZero,
  wantedNumberOfCompletes: AboveZero,
  wantedNumberOfStarts: NonNegative.Or(Null),
  initialFeasibleNumberOfCompletes: NonNegative.Or(Null),
  additionalNumberOfCompletes: NonNegative,
  useOptimizer: Boolean,
  regions: ExistingTargetGroupRegionsResponse,

  profiling: ExistingTargetGroupProfilingResponse,

  supply: Record({
    hasPanelistPool: Boolean,
    selectedPanels: Array(
      Record({
        id: AboveZero,
        name: NonEmptyString,
        tags: Array(NonEmptyString),
      })
    ),
    panelistPool: ExistingPanelistPoolResponse,
  }),

  quotas: ExistingTargetGroupQuotaSetupResponse,

  excludeProjects: ExistingTargetGroupExcludeProjectsResponse,

  actualNumberOfCompletes: NonNegative,
  actualNumberOfStarts: NonNegative.Or(Null),
  completesFromOptimizer: NonNegative,
  startsFromOptimizer: NonNegative,
  dropoutRate: NonNegative,
  actualIncidenceRate: NonNegative.Or(Null),
  incidenceRateOverride: NonNegative.Or(Null),
  actualLengthOfInterview: NonNegative.Or(Null),
  inFieldLengthOfInterviewOverride: NonNegative.Or(Null),
  responded: NonNegative,
  quotaFullsOutOfResponded: NonNegative,
  conversionRate: NonNegative,
  responseRate: NonNegative,

  testLinks: String,
  screenoutInformation: String,
  surveyLinkSource: Union(
    Literal(LinkSource.None),
    Literal(LinkSource.LinkTemplate),
    Literal(LinkSource.LinkPool),
    Literal(LinkSource.UniqueLinkPool)
  ),
  isRunningOutOfLinks: Boolean,

  adHocSupplier: Record({
    id: AboveZero,
    name: NonEmptyString,
  }).Or(Null),

  adHocAllocationStatus: Number.Or(Null),
  adHocAllocation: Record({
    monitorUrl: HttpUrl,
    status: Number,
  }).Or(Null),

  hasRequests: Boolean,
  hasSignificantLoi: Boolean,
  hasSignificantNumberOfRespondents: Boolean,
  isApprovedForLaunch: Boolean,
});
export type ExistingTargetGroupResponse = Static<typeof ExistingTargetGroupResponse>;

export const ExistingTargetGroupSummaryResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  status: Union(
    Literal(TargetGroupStatus.Activated),
    Literal(TargetGroupStatus.Closed),
    Literal(TargetGroupStatus.Paused),
    Literal(TargetGroupStatus.Pending)
  ),
  country: NonEmptyString,
  startDate: Iso8601UtcString,
  endDate: Iso8601UtcString,
  numberOfDaysInField: AboveZero,
  wantedNumberOfCompletes: AboveZero,
  actualNumberOfCompletes: NonNegative,
  wantedNumberOfStarts: NonNegative.Or(Null),
  actualNumberOfStarts: NonNegative.Or(Null),
  estimatedIncidenceRate: NonNegative,
  actualIncidenceRate: NonNegative.Or(Null),
  estimatedLengthOfInterview: NonNegative,
  actualLengthOfInterview: NonNegative.Or(Null),
  inFieldLengthOfInterviewOverride: NonNegative.Or(Null),
  hasSignificantLoi: Boolean,
  hasSignificantNumberOfRespondents: Boolean,
  hasRequests: Boolean,
  responded: NonNegative,
  completesFromOptimizer: NonNegative,
  startsFromOptimizer: NonNegative,
  useOptimizer: Boolean,
  surveyLinkSource: Union(
    Literal(LinkSource.None),
    Literal(LinkSource.LinkTemplate),
    Literal(LinkSource.LinkPool),
    Literal(LinkSource.UniqueLinkPool)
  ),

  isApprovedForSendout: Boolean,
  isApprovedForLaunch: Boolean,

  adHocAllocationStatus: Number.Or(Null),

  quotaFullsOutOfResponded: NonNegative,
  conversionRate: NonNegative,
  responseRate: NonNegative,
  dropoutRate: NonNegative,
  remainingLinksCount: NonNegative,
});
export type ExistingTargetGroupSummaryResponse = Static<typeof ExistingTargetGroupSummaryResponse>;

export const EndPageSecurityTypeResponse = Union(
  Literal(EndPageSecurityType.Generic),
  Literal(EndPageSecurityType.ProjectSpecific),
  Literal(EndPageSecurityType.S2S)
);

export type EndPageSecurityTypeResponse = Static<typeof EndPageSecurityTypeResponse>;

export const ExistingProjectResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  status: Union(Literal(ProjectStatus.Active), Literal(ProjectStatus.Edit), Literal(ProjectStatus.Closed)),
  channel: Union(
    Literal(ProjectChannel.Access),
    Literal(ProjectChannel.AccessPro),
    Literal(ProjectChannel.Api),
    Literal(ProjectChannel.Track),
    Literal(ProjectChannel.ManagedServices),
    Literal(ProjectChannel.Test),
    Literal(ProjectChannel.Unknown)
  ),
  isReusable: Boolean,
  primaryOwner: NonEmptyString,
  companyName: NonEmptyString,
  companyId: AboveZero,
  contact: Record({
    name: NonEmptyString,
    primaryEmail: NonEmptyString,
    additionalEmails: Array(NonEmptyString).Or(Null),
    emails: String.Or(Null),
  }),
  purchaseOrderNumber: String.Or(Null),
  jobNumber: String.Or(Null),
  startDate: Iso8601UtcString,
  endDate: Iso8601UtcString,
  totalNumberOfWantedCompletes: NonNegative,
  totalNumberOfActualCompletes: NonNegative,
  totalNumberOfWantedStarts: NonNegative.Or(Null),
  totalNumberOfActualStarts: NonNegative.Or(Null),
  earlyScreenoutLink: NonEmptyString.Or(Null),
  quotaFullLink: NonEmptyString.Or(Null),
  completeLink: NonEmptyString.Or(Null),
  endPageSecurityType: EndPageSecurityTypeResponse,
  companyCountryName: String.Or(Null),
  hasRequests: Boolean,

  categories: Array(AboveZero),
  includesPii: Boolean,
  checkGeoIp: Boolean,
  useDeduplication: Boolean,
  suitableForMobile: Boolean,
  suitableForTablet: Boolean,
  suitableForDesktop: Boolean,
  requireWebcam: Boolean,
  displayCurrency: Currency.Or(Null),
  targetGroups: Array(ExistingTargetGroupSummaryResponse),
  hasShowOptimizerEnabled: Boolean,
});
export type ExistingProjectResponse = Static<typeof ExistingProjectResponse>;

export const TargetGroupAlarm = Record({
  targetGroupId: Number,
  latestCriticalAlarm: LatestCriticalAlarmResponse.Or(Null),
  criticalAlarms: Array(LatestCriticalAlarmResponse).Or(Null),
});
export type TargetGroupAlarm = Static<typeof TargetGroupAlarm>;

export const TargetGroupLiveDataResponse = Record({
  id: AboveZero,
  status: Union(
    Literal(TargetGroupStatus.Activated),
    Literal(TargetGroupStatus.Closed),
    Literal(TargetGroupStatus.Paused),
    Literal(TargetGroupStatus.Pending)
  ),
  actualNumberOfCompletes: NonNegative,
  actualNumberOfStarts: NonNegative.Or(Null),
  actualIncidenceRate: NonNegative.Or(Null),
  actualLengthOfInterview: NonNegative.Or(Null),
  hasSignificantLoi: Boolean,
  hasSignificantNumberOfRespondents: Boolean,
  responded: NonNegative,
  completesFromOptimizer: NonNegative,
  startsFromOptimizer: NonNegative,
  quotaFullsOutOfResponded: NonNegative,
  conversionRate: NonNegative,
  responseRate: NonNegative,
  dropoutRate: NonNegative,
  latestAlarms: TargetGroupAlarm.Or(Null),
  // Deviation properties
  lastActivity: Iso8601UtcString.Or(Null),

  isStale: Boolean,
  isLagging: Boolean,
  currentExpectedCompletes: NonNegative.Or(Null),
});
export type TargetGroupLiveDataResponse = Static<typeof TargetGroupLiveDataResponse>;

export const ServiceFeatureError = Record({
  feature: Union(
    Literal(FeatureError.Alarms),
    Literal(FeatureError.AlarmLimits),
    Literal(FeatureError.Statistics),
    Literal(FeatureError.Pricing),
    Literal(FeatureError.TgActivityStatus)
  ),
});
export type ServiceFeatureError = Static<typeof ServiceFeatureError>;

export const ProjectLiveDataResponse = Record({
  id: AboveZero,
  autoCloseDate: Iso8601UtcString.Or(Null),
  targetGroups: Array(TargetGroupLiveDataResponse),
  errors: Array(ServiceFeatureError),
});
export type ProjectLiveDataResponse = Static<typeof ProjectLiveDataResponse>;
