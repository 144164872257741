import { isEqual, find, toInteger } from 'lodash-es';
import { SupplyGroupCustomCpiSettings } from '../active-target-group/models/active-supply.model';
import { targetGroupListHelper } from './target-group-list-helper';
import { ErrorModel, ValidationErrors, FeasibilityErrors } from '../validation-errors';
import { TargetGroupModel } from '../../common/models/target-group.model';
import { SupplySource, WeightingResult } from '../../common/enums';
import { ExistingTargetGroupSummary } from '../../common/http-services/existing-project.models';
import { UpfrontPrice, UpfrontTargetGroupPrice } from '../price-quote.response';
import { db } from '../../common/db';
import { session } from '../../common/session';
import { Listener } from '../../common/channels/channel';
import { targetGroupChannel } from '../channels/target-group-channel';
import { getActualFeasibility } from '../feasibility.service';
import { FeasibilityResponse, Feasibility } from '../../common/http-services/feasibility.response';
import { activeTargetGroupStore } from '../active-target-group/store/active-target-group.store';
import { ActiveSupplyMixSource } from '../supply/supply-mix/supply-mix-state.service';
import { Money } from '../../common/pricing/price-models';

export function rebindPriceIfChanged<K extends keyof UpfrontTargetGroupPrice>(
  currentPrice: UpfrontTargetGroupPrice,
  newPrice: UpfrontTargetGroupPrice,
  propertyName: K
): void {
  if (isEqual(currentPrice[propertyName], newPrice[propertyName])) return;
  currentPrice[propertyName] = newPrice[propertyName];
}

export interface TargetGroupFeasibility {
  isFeasible: boolean;
  isRunning: boolean;
  feasibleNumberOfCompletes: number;
  completesWithoutLockedPanels: number;
  isFeasibleWithoutLockedPanels: boolean;
  areAllQuotasFeasible: boolean;
}

export interface TargetGroup {
  id: number;
  replacesTargetGroupId?: number;
  replacesProjectIds?: number[];
  name: string;
  hasDeprecation: boolean;
  definition: TargetGroupSummary;
  feasibility: TargetGroupFeasibility;
  price: UpfrontTargetGroupPrice;
}

export interface SupplyGroupSummary {
  id: number;
  name: string;
  quotaMatchId: number;
  panelIds: number[];
  source: ActiveSupplyMixSource;
  useCustomCpi: boolean;
  customCpi?: number;
  ccpiSettings?: SupplyGroupCustomCpiSettings;
}

export interface SupplyGroupMonitorSummary extends SupplyGroupSummary {
  cpi: Money;
  wantedNumberOfCompletes: number;
  collectedNumberOfCompletes: number;
}

//  wayyyy too many optional fields here :/
export interface TargetGroupSummary {
  isValid: boolean;
  isUpdating: boolean;
  isActive?: boolean;
  wantedNumberOfCompletes?: number;
  wantedNumberOfStarts?: number;
  useStarts?: boolean;
  hasCensus?: boolean;
  hasRestrictionErrors?: boolean;
  hasPrivatePricing?: boolean;
  hasManuallyChosenPanels?: boolean;
  hasPanelistPool?: boolean;
  includeLockedPanels?: boolean;
  error?: ErrorModel;
  country: string;
  estimatedIr: number;
  estimatedLoi: number;
  quotaCount: number;
  selectedProfilingAttributesCount: number;
  supplySummary: string;
  supplySource: SupplySource;
  surveyMetadataTagsCount: number;
  supplyGroups?: SupplyGroupSummary[];
}

export interface SurveyMetadataListUpdate {
  targetGroupId: number;
  surveyMetadataTagsCount: number;
}

export class TargetGroupListService extends Listener {
  targetGroups: TargetGroup[];
  existingTargetGroups: ExistingTargetGroupSummary[];

  constructor() {
    super();
    this.targetGroups = [];
    this.existingTargetGroups = [];
    targetGroupChannel.model.updating.listen(this.whenUpdating, this);
    targetGroupChannel.model.updated.listen(this.whenUpdated, this);
    targetGroupChannel.feasibility.invalid.listen(this.whenTargetGroupInvalid, this);
    targetGroupChannel.feasibility.start.listen(this.whenFeasibilityStart, this);
    targetGroupChannel.feasibility.done.listen(this.whenFeasibilityDone, this);
    targetGroupChannel.feasibility.error.listen(this.whenFeasibilityError, this);
    targetGroupChannel.pricing.done.listen(this.whenPriceDone, this);
    targetGroupChannel.pricing.error.listen(this.whenPriceError, this);
    targetGroupChannel.pricing.cancel.listen(this.whenPriceCancel, this);
    targetGroupChannel.model.panelistPool.error.listen(this.whenPanelistPoolError, this);
    targetGroupChannel.model.basicSettings.updated.listen(this.whenBasicSettingsUpdated, this);
    targetGroupChannel.model.surveyMetadata.updated.listen(this.updateSurveyMetadataTagsCount, this);
  }

  createNewList(persistedTargetGroups: TargetGroupModel[], activeTgId: number, isAddingTargetGroups?: boolean): void {
    this.targetGroups = targetGroupListHelper.createTargetGroups(persistedTargetGroups, activeTgId);
    targetGroupChannel.targetGroupList.updated.dispatch();

    if (isAddingTargetGroups) {
      db.existingProject.get(session.uuid).then((tgs) => {
        this.existingTargetGroups = tgs.project.targetGroups;
        targetGroupChannel.existingTargetGroupList.loaded.dispatch();
      });
    }
  }

  setActive(id: number): void {
    for (const tg of this.targetGroups) {
      if (tg.id !== id) {
        tg.definition.isActive = false;
        tg.definition.isUpdating = false;
      } else {
        tg.definition.isActive = true;
      }
    }
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  updateList(persistedTargetGroups: TargetGroupModel[], activeTgId: number): void {
    for (const tg of this.targetGroups) {
      tg.definition.isActive = false;
    }
    this.targetGroups = targetGroupListHelper.updateTargetGroups(persistedTargetGroups, activeTgId, this.targetGroups);

    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  renameTargetGroup(id: number, name: string): void {
    const tg = this.findTargetGroupInList(id);
    tg.name = name;
    targetGroupChannel.targetGroupList.renamed.dispatch();
  }

  deprecationChanged(targetGroupId: number, hasAnyDeprecation: boolean): void {
    const targetGroup = this.findTargetGroupInList(targetGroupId);
    targetGroup.hasDeprecation = hasAnyDeprecation;
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  updateSurveyMetadataTagsCount(...updates: SurveyMetadataListUpdate[]): void {
    for (const update of updates) {
      const targetGroup = this.findTargetGroupInList(update.targetGroupId);
      if (targetGroup?.definition) {
        targetGroup.definition.surveyMetadataTagsCount = update.surveyMetadataTagsCount;
      }
    }
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenPanelistPoolError() {
    const targetGroup = find(this.targetGroups, (tg) => tg.definition.isActive);
    targetGroup.definition.isUpdating = false;

    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenTargetGroupInvalid(data: { targetGroup: TargetGroupModel; validationResult: ErrorModel }): void {
    const targetGroup = this.findTargetGroupInList(data.targetGroup.id);
    if (targetGroup === undefined) return;
    targetGroup.price = {} as UpfrontTargetGroupPrice;
    targetGroup.feasibility = { isRunning: false } as TargetGroupFeasibility;
    targetGroup.definition = targetGroupListHelper.mapTargetGroupDefinition(
      data.targetGroup,
      false,
      data.validationResult,
      targetGroup.definition.isActive
    );

    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenUpdating(): void {
    const targetGroup = find(this.targetGroups, (tg) => tg.definition.isActive);
    if (!targetGroup) return;
    targetGroup.definition.isUpdating = true;

    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenUpdated(): void {
    const targetGroup = find(this.targetGroups, (tg) => tg.definition.isActive);
    if (!targetGroup) return;
    targetGroup.definition.isUpdating = false;

    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenFeasibilityStart(data: { targetGroup: TargetGroupModel; validationResult: ErrorModel }): void {
    const targetGroup = this.findTargetGroupInList(data.targetGroup.id);
    if (targetGroup) {
      targetGroup.feasibility.isRunning = true;

      targetGroup.definition = targetGroupListHelper.mapTargetGroupDefinition(
        data.targetGroup,
        true,
        ValidationErrors.noError,
        targetGroup.definition.isActive
      );

      targetGroupChannel.targetGroupList.updated.dispatch();
    }
  }

  private whenBasicSettingsUpdated(): void {
    const tgId = activeTargetGroupStore.model.identity.id;
    const targetGroup = this.findTargetGroupInList(tgId);
    if (targetGroup) {
      targetGroupChannel.targetGroupList.updated.dispatch();
    }
  }

  private whenFeasibilityDone(data: { targetGroup: TargetGroupModel; feasibilityResponse: FeasibilityResponse }): void {
    const { targetGroupId } = data.feasibilityResponse.defaultFeasibility;
    const targetGroup = this.findTargetGroupInList(targetGroupId);
    if (targetGroup) {
      targetGroup.definition = targetGroupListHelper.mapTargetGroupDefinition(
        data.targetGroup,
        false,
        this.getValidationResult(getActualFeasibility(data.feasibilityResponse)),
        targetGroup.definition.isActive
      );

      targetGroup.feasibility = targetGroupListHelper.mapFeasibilityResponse(data.feasibilityResponse);

      targetGroupChannel.targetGroupList.updated.dispatch();
    }
  }

  private getValidationResult(response: Feasibility): ErrorModel {
    if (response.weightingResult === WeightingResult.Failure) {
      return ValidationErrors.rimWeightingIsNotConvergent;
    }
    if (response.noPanelsForSampling) {
      return ValidationErrors.noPanelsForSampling;
    }
    return ValidationErrors.noError;
  }

  private whenPriceCancel(): void {
    for (const tg of this.targetGroups) {
      tg.definition.isUpdating = false;
    }
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenPriceDone(projectPrice: UpfrontPrice): void {
    for (const tg of this.targetGroups) {
      const newPrice = find(projectPrice.targetGroups, (tp) => toInteger(tp.id) === tg.id);
      if (newPrice === undefined) {
        tg.price = {} as UpfrontTargetGroupPrice;
      } else {
        rebindPriceIfChanged(tg.price, newPrice, 'price');
        rebindPriceIfChanged(tg.price, newPrice, 'cpi');
        rebindPriceIfChanged(tg.price, newPrice, 'hasRateCard');
        rebindPriceIfChanged(tg.price, newPrice, 'hasCustomCpi');
        rebindPriceIfChanged(tg.price, newPrice, 'canUseCustomCpi');
        rebindPriceIfChanged(tg.price, newPrice, 'customCpiMin');
        rebindPriceIfChanged(tg.price, newPrice, 'customCpiMax');
        rebindPriceIfChanged(tg.price, newPrice, 'internalCpi');
        rebindPriceIfChanged(tg.price, newPrice, 'cintCpi');
        rebindPriceIfChanged(tg.price, newPrice, 'internalPrice');
        rebindPriceIfChanged(tg.price, newPrice, 'cintPrice');
        rebindPriceIfChanged(tg.price, newPrice, 'quotaPrices');
      }
      tg.definition.isUpdating = false;
    }
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenFeasibilityError(data: { targetGroupId: number; reason: 'timeout' | 'error' }): void {
    const tg = this.findTargetGroupInList(data.targetGroupId);
    if (tg !== undefined) {
      tg.definition.isUpdating = false;
      tg.feasibility.isRunning = false;
      tg.definition.error = data.reason === 'timeout' ? FeasibilityErrors.timeout : FeasibilityErrors.generic;
    }
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private whenPriceError(): void {
    // todo how to handle?
    targetGroupChannel.targetGroupList.updated.dispatch();
  }

  private findTargetGroupInList(id: number | string): TargetGroup {
    return find(this.targetGroups, (tg) => tg.id === id);
  }
}

export const targetGroupListService = new TargetGroupListService();
