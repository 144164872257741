export const convertEventTypeToText = (eventName: string, targetGroupName?: string, quotaName?: string) => {
  switch (eventName) {
    case 'ProjectCreated':
      return 'Project was created';
    case 'ClosedForInvoicing':
      return 'Closed for invoicing';
    case 'ProjectRejected':
      return 'Project was rejected';
    case 'TestSelectionCreated':
      return 'Test selection was created';
    case 'OwnershipChanged':
      return 'Project ownership changed';
    case 'TargetGroupApproved':
      return `Target Group '${targetGroupName}' Approved`;
    case 'TargetGroupClosed':
      return `Target Group '${targetGroupName}' Closed`;
    case 'SetCompleted':
      return 'Project set completed';
    case 'ProjectUpdated':
      return 'Project was updated';
    case 'QuotaClosed':
      return `Quota '${quotaName}' Closed`;
    case 'QuotaReopened':
      return `Quota '${quotaName}' Reopened`;
    case 'TargetGroupReopened':
      return `Target Group '${targetGroupName}' Reopened`;
    case 'AllTargetGroupsClosed':
      return 'All Target Groups closed';
    case 'FulfillmentGoalUpdated':
      return 'Fulfillment goal updated';
    case 'WantedQuantityUpdated':
      return 'Wanted quantity updated';
    case 'QuotaUpdated':
      return `Respondent Quota '${quotaName}' was updated`;
    case 'TargetGroupUpdated':
      return `Target Group '${targetGroupName}' was updated`;
    case 'InterviewInfoUpdated':
      return 'Interview Info was updated';
    case 'TrackingProjectCoefficientsUpdated':
      return 'Tracking project coefficients were updated';
    case 'BucketUpdated':
      return 'Respondent Bucket was updated';
    case 'TargetGroupCreated':
      return `Target group '${targetGroupName}' was created`;
    case 'TrackingProjectMonthlyFeeUpdated':
      return 'Tracking project monthly fee was updated';
    case 'IgnoredAlertReasonUpdated':
      return 'Ignored alert reason updated';
    case 'TrackingProjectUpdated':
      return 'Tracking project details updated';
    case 'TrackingProjectWeeklyWantedUpdated':
      return 'Tracking project weekly wanted updated';
    case 'SamplingOnNumberOfStarts':
      return 'Sample on starts';
    case 'SamplingOnNumberOfInvites':
      return 'Sample on invites';
    case 'TrackingProjectAgeQuotaUpdated':
      return 'Tracking project age quotas were updated';
    case 'TrackingProjectGenderQuotaUpdated':
      return 'Tracking project gender quota was updated';
    case 'TrackingProjectRegionUpdated':
      return 'Tracking project regions were updated';
    case 'TrackingProjectPanelUpdated':
      return 'Tracking project panels were updated';
    case 'TargetGroupLinkTemplateChanged':
      return 'Target group link template changed';
    case 'AutoSamplingStateChanged':
      return 'Auto delivery changed';
    case 'ResetAlertReason':
      return 'Reset alert reason';
    case 'ProjectMerged':
      return 'Project merged';
    case 'ProjectDeleted':
      return 'Project deleted';
    case 'QuotaPaused':
      return `Quota '${quotaName}' paused`;
    case 'TargetGroupAlarmLimitsChanged':
      return 'Target group alarm limits changed';
    case 'AlarmCausedCriticalAction':
      return 'Alarm caused critical action';
    case 'TargetGroupUploadPanelists':
      return 'Panelists uploaded';
    case 'AlarmCausedWarningAction':
      return 'Alarm caused warning action';
    case 'CpiWarningSentToCustomer':
      return 'CPI email alert sent to customer';
    case 'ProjectMobileBlocked':
      return 'Mobile was blocked for project due to declines';
    case 'TargetGroupPanelistPoolPanelistRemoved':
      return 'Panelist removed from panelist pool';
    case 'ProjectClosing':
      return 'Project closing initiated';
    case 'AutoSamplingExecuted':
      return 'Auto sampling executed';
    case 'TargetGroupUseOptimizerChanged':
      return 'Use optimizer changed';
    case 'ProjectDescriptionChanged':
      return 'Project description changed';
    case 'CustomCpiChanged':
      return 'Custom CPI changed';
    case 'ProjectParametersChanged':
      return 'Project parameters changed';
    case 'TargetGroupParametersChanged':
      return 'Target group parameters changed';
    case 'TargetGroupClientApproved':
      return 'Launch approved';
    default:
      return 'Unknown';
  }
};
