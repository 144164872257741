import { IPromise } from 'angular';
import { TargetGroupConstants } from '../target-group.constants';
import { api, SearchableRegion } from '../../common/api';
import { debouncePromise } from '../../ngimport';

export class RegionSearchService {
  throttledSearchRegions: (countryId: number, searchString: string) => IPromise<SearchableRegion[]>;

  init(): void {
    this.throttledSearchRegions = debouncePromise(this.searchRegions, TargetGroupConstants.searchThrottleInterval);
  }

  private searchRegions(countryId: number, searchString: string): IPromise<SearchableRegion[]> {
    searchString = searchString.trim();
    if (!searchString || searchString.length < 3) return Promise.resolve(null);
    searchString = encodeURIComponent(searchString.toLowerCase());
    return api.region.getRegionSearchResults(countryId, searchString).then((response) => {
      if (!response.wasCanceled) {
        return response.result.hits;
      }
      return null;
    });
  }
}

export const regionSearchService = new RegionSearchService();
