import { ui } from 'angular';
import { ImportProjectDialogComponent } from './import-project-dialog.component';

export class ImportProjectDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'import-project-dialog',
      backdrop: 'static',
      component: ImportProjectDialogComponent.componentName,
    };
  }
}
