import { hotkeyChannel } from './channels/hotkey-channel';
import { RuntimeSettings } from '../runtime-settings';
import { $document } from '../ngimport';

interface KeyMap {
  [key: string]: {
    matchModifiers: (event: JQueryEventObject) => boolean;
    invoke: () => void;
  };
}

const Hotkeys: KeyMap = {
  q: { matchModifiers: (e) => e.ctrlKey, invoke: () => hotkeyChannel.ctrlQ.pressed.dispatch() },
};

export class HotkeyService {
  registerHotkeys(): void {
    if (RuntimeSettings.runtime.environment === 'Production') return;

    $document.bind('keydown', (event) => {
      const invoker = Hotkeys[event.key];
      if (invoker === undefined) return;
      if (!invoker.matchModifiers(event)) return;
      invoker.invoke();
    });
  }
}

export const hotkeyService = new HotkeyService();
