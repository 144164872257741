import { Runtime, FeatureKey, User, ExternalUrls, ProductModeSettings, UsageTrackingSettings } from './app-settings';

interface RuntimeSettingsData {
  apiUrl: string;
  buildNumber: number;
  runtime: Runtime;
  features: FeatureKey[];
  userData: User;
  externalUrls: ExternalUrls;
  productMode: ProductModeSettings;
  usageTracking: UsageTrackingSettings;
}

const settings: RuntimeSettingsData = {
  apiUrl: undefined,
  buildNumber: undefined,
  runtime: undefined,
  features: undefined,
  userData: undefined,
  externalUrls: undefined,
  productMode: undefined,
  usageTracking: undefined,
};

export class RuntimeSettings {
  static get apiUrl(): string {
    if (settings.apiUrl === undefined) {
      throw new Error('`apiUrl` is undefined, which means you are calling it before angular has been bootstrapped.');
    }
    return settings.apiUrl;
  }
  static get buildNumber(): number {
    return settings.buildNumber;
  }
  static get runtime(): Runtime {
    return settings.runtime;
  }
  static get features(): FeatureKey[] {
    return settings.features;
  }
  static get userData(): User {
    return settings.userData;
  }
  static get externalUrls(): ExternalUrls {
    return settings.externalUrls;
  }
  static get productMode(): ProductModeSettings {
    return settings.productMode;
  }
  static get usageTracking(): UsageTrackingSettings {
    return settings.usageTracking;
  }
}

export function initRuntimeSetting<K extends keyof RuntimeSettingsData>(prop: K, value: any) {
  settings[prop] = value;
}
