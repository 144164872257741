import './export-project-dialog.component.scss';
import { ui } from 'angular';
import { api, ProjectSearchResult } from '../common/api';
import { html } from '../helpers';

const selector = 'exportProjectDialogComponent';

const template = html`
  <div class="export-project-dialog-component">
    <modal-close-button-component close-action="$ctrl.close()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Export project</h1>
      <div class="container-fluid no-padding">
        <div class="row">
          <div class="col-xs-4">
            <form class="form form-inline" autocomplete="off">
              <div class="form-group-sm">
                <label>
                  Project ID
                  <input
                    type="number"
                    ng-model="$ctrl.projectIdQuery"
                    ng-model-options="{
                      'updateOn': 'default blur',
                      'debounce': { 'default': 200, 'blur': 0 }
                    }"
                    name="project-id"
                    class="form-control"
                    autofocus
                    ng-change="$ctrl.searchFieldUpdated()"
                  />
                </label>
              </div>
            </form>
            <ul class="list-group push-down">
              <li
                class="list-group-item"
                ng-repeat="result in $ctrl.results track by result.id"
                ng-class="{ 'odd': $odd }"
              >
                <a ng-click="$ctrl.export(result)"><strong>{{result.projectId}}</strong>&nbsp;|&nbsp;{{result.name}}</a>
              </li>
            </ul>
            <p ng-show="$ctrl.results.length === 0">No results found</p>
          </div>

          <div class="col-xs-8">
            <textarea name="testLinks" rows="30" ng-model="$ctrl.exportedProjectData" spellcheck="false"> </textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="well well-sm error-container">
              <span class="label label-danger">{{ $ctrl.error }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-controls">
        <button class="ui-btn primary-btn" ng-click="$ctrl.close()">Close</button>
      </div>
    </div>
  </div>
`;

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class ExportProjectDialogComponent implements Bindings {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;
  projectIdQuery: number;
  results: ProjectSearchResult[];
  exportedProjectData: string;
  error: string;

  $onInit(): void {
    this.results = null;
  }

  close(): void {
    this.modalInstance.dismiss();
  }

  searchFieldUpdated(): void {
    this.results = null;
    if (Number.isNaN(this.projectIdQuery)) return;
    if (this.projectIdQuery < 10) return;
    if (this.projectIdQuery > 10000000) return;

    api.feature
      .searchProjects(this.projectIdQuery)
      .then((res) => {
        this.results = res.data;
      })
      .catch((res) => {
        this.error = res.data.exception.message;
      });
  }

  export(project: ProjectSearchResult): void {
    api.feature
      .exportProject(project.id)
      .then((res) => {
        // Circumvent the angular auto json parse
        this.exportedProjectData = JSON.stringify(res.data);
      })
      .catch((res) => {
        this.error = res.data.exception.message;
      });
  }
}

export const ngExportProjectDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ExportProjectDialogComponent,
  },
};
