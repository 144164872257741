import { flatMap, without } from 'lodash-es';
import { ListItemPill } from '../react/ListItemPill';
import { Filter, FilterResult } from './filter';

interface Props {
  filters: Filter[];
  onFilter: (filters: FilterResult[]) => void;
}

export const InlineActiveFilterList: React.FC<Props> = ({ filters, onFilter }) => {
  const handleRemove = (title: string, name: string) => {
    onFilter(
      filters.map((filter) =>
        filter.title === title ? { ...filter, selected: without(filter.selected, name) } : filter
      )
    );
  };

  const handleClear = () => {
    onFilter(filters.map((filter) => ({ title: filter.title, selected: [] })));
  };

  return (
    <span className="active-filters">
      <ul>
        {flatMap(filters, (setting) =>
          setting.selected.map((label) => (
            <ListItemPill
              key={label}
              isSelected={false}
              text={label}
              onClick={(a) => handleRemove(setting.title, a)}
              removeIcon={true}
              title="Remove filter"
            />
          ))
        )}
        <li>
          <span className="clear-link">
            <a title="Clear filters" onClick={handleClear} data-testid="filter-list-clear-button">
              Clear
            </a>
          </span>
        </li>
      </ul>
    </span>
  );
};
