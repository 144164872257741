import { IncentivesModel } from '../../../common/models/incentives.model';
import { ErrorModel } from '../../validation-errors';

export interface ActiveIncentivesModel {
  readonly fixedIncentive: {
    readonly amount: number | undefined;
  };

  readonly validationResult?: ErrorModel;
}

export function createActiveIncentivesModel(): ActiveIncentivesModel {
  return {
    fixedIncentive: {
      amount: undefined,
    },
    validationResult: undefined,
  };
}

export function createActiveIncentivesModelFrom(incentives: IncentivesModel): ActiveIncentivesModel {
  return {
    fixedIncentive: {
      amount: incentives.fixedIncentive ? incentives.fixedIncentive.amount : undefined,
    },
    validationResult: undefined,
  };
}
