/* eslint-disable import/no-mutable-exports */
import angular from 'angular';
import { StateService, StateProvider, UrlService } from '@uirouter/angularjs';
import { TransitionService, StateParams } from '@uirouter/core';
import { FileUploaderFactory } from 'angular-file-upload';
import { HttpService } from './common/runtime-type-validating-http-provider.decorator';
import { DebouncePromise } from './common/debounce-promise';

// copied and adapted from https://github.com/bcherny/ngimport
// more examples of testing https://github.com/bcherny/ngimport-demo

// ------------------------------------------------------
// providers
// -------------------------------------------------------
// ---- angular
export let $provide: angular.auto.IProvideService;
export let $httpProvider: angular.IHttpProvider;
export let $compileProvider: angular.ICompileProvider;
// ---- ui-router
export let $stateProvider: StateProvider;
export let $urlServiceProvider: UrlService; // also available during runtime as $urlService
export let $transitionsProvider: TransitionService; // also available during runtime as $transitions
// ---- ng-strap
export let $datepickerProvider: mgcrea.ngStrap.datepicker.IDatepickerProvider;
export let $timepickerProvider: mgcrea.ngStrap.timepicker.ITimepickerProvider;
export let $popoverProvider: mgcrea.ngStrap.popover.IPopoverProvider;
// ---- json-formatter
export let JSONFormatterConfigProvider: any;

// -------------------------------------------------------
// services
// -------------------------------------------------------
// ---- angular
export let $document: angular.IDocumentService;
export let $filter: angular.IFilterService;
export let $http: HttpService; // uses our patched interface instead (with validatingGet/Post etc.)
export let $httpBackend: angular.IHttpBackendService;
export let $injector: angular.auto.IInjectorService;
export let $interval: angular.IIntervalService;
export let $location: angular.ILocationService;
export let $log: angular.ILogService;
export let $q: angular.IQService;
export let $rootScope: angular.IRootScopeService;
export let $templateCache: angular.ITemplateCacheService;
export let $timeout: angular.ITimeoutService;
export let $window: angular.IWindowService;
export let $animate: angular.animate.IAnimateService;
export let $compile: angular.ICompileService;
// ---- ui-router
export let $state: StateService;
export let $transitions: TransitionService; // also available during config as $transitionsProvider
export let $stateParams: StateParams;
// ---- ui-bootstrap
export let $uibModal: angular.ui.bootstrap.IModalService;
// ---- angular-file-upload
export let FileUploader: FileUploaderFactory;
// ---- angular-file-saver
export let FileSaver: any;
// ---- ours
export let debouncePromise: DebouncePromise;

// prevent double-loading, which has the potential
// to prevent sharing state between services
let m: angular.IModule;
try {
  m = angular.module('ngimport');
} catch (e) {
  m = angular.module('ngimport', []);
}

// providers are only injectable in .config blocks, whereas services cannot be
m.config([
  '$provide',
  '$httpProvider',
  '$logProvider',
  '$compileProvider',
  '$datepickerProvider',
  '$timepickerProvider',
  '$popoverProvider',
  '$stateProvider',
  '$transitionsProvider',
  '$urlServiceProvider',
  'JSONFormatterConfigProvider',
  (
    _$provide_: angular.auto.IProvideService,
    _$httpProvider_: angular.IHttpProvider,
    _$logProvider_: angular.ILogProvider,
    _$compileProvider_: angular.ICompileProvider,
    _$datepickerProvider_: mgcrea.ngStrap.datepicker.IDatepickerProvider,
    _$timepickerProvider_: mgcrea.ngStrap.timepicker.ITimepickerProvider,
    _$popoverProvider_: mgcrea.ngStrap.popover.IPopoverProvider,
    _$stateProvider_: StateProvider,
    _$transitionsProvider_: TransitionService,
    _$urlService_: UrlService,
    _JSONFormatterConfigProvider_: any
  ) => {
    $provide = _$provide_;
    $httpProvider = _$httpProvider_;
    $compileProvider = _$compileProvider_;
    $datepickerProvider = _$datepickerProvider_;
    $timepickerProvider = _$timepickerProvider_;
    $popoverProvider = _$popoverProvider_;
    $stateProvider = _$stateProvider_;
    $transitionsProvider = _$transitionsProvider_;
    $urlServiceProvider = _$urlService_;
    JSONFormatterConfigProvider = _JSONFormatterConfigProvider_;
  },
]);

// services are only injectable during runtime
m.run([
  '$injector',
  ($i: angular.auto.IInjectorService) => {
    $document = $i.get('$document');
    $filter = $i.get('$filter');
    $http = $i.get('$http') as HttpService;
    $httpBackend = $i.get('$httpBackend');
    $injector = $i;
    $interval = $i.get('$interval');
    $location = $i.get('$location');
    $log = $i.get('$log');
    $q = $i.get('$q');
    $rootScope = $i.get('$rootScope');
    $templateCache = $i.get('$templateCache');
    $timeout = $i.get('$timeout');
    $window = $i.get('$window');
    $animate = $i.get('$animate');
    $compile = $i.get('$compile');

    $state = $i.get('$state');
    $transitions = $i.get('$transitions');
    $stateParams = $i.get('$stateParams');

    $uibModal = $i.get('$uibModal');
    FileUploader = $i.get('FileUploader');
    FileSaver = $i.get('FileSaver');
    debouncePromise = $i.get('debouncePromise');
  },
]);

export const moduleName = m.name;
