import { copy as ngCopy, IHttpResponse } from 'angular';
import { cloneDeep, some, isEmpty, find, union, includes, keys, difference, remove, findIndex } from 'lodash-es';
import hash from 'object-hash';
import { StorageDraft, targetGroupRepository } from '../common/target-group/target-group.repository';
import { activeTargetGroupStore } from './active-target-group/store/active-target-group.store';
import { projectSettingsService } from '../common/project-settings.service';
import { persistentSurveyLinksService } from '../common/target-group/survey-links/persistent-survey-links.service';
import { saveStateService } from '../common/save-state.service';
import { TargetGroupModel, ProjectTemplateSource } from '../common/models/target-group.model';
import { createBasicSettingsModelFromActive } from '../common/models/basic-settings.model';
import { createRegionsModelFromActive } from '../common/models/regions.model';
import { createProfilingModelFromActive, createProfilingModel } from '../common/models/profiling.model';
import { createSupplyModelFrom, createSupplyModel } from '../common/models/supply.model';
import { canCallProfilingDepth, profilingDepthService } from './profiling/profiling-depth.service';
import { createExcludeProjectsModelFromActive } from '../common/models/exclude-projects.model';
import { createQuotasModelFromActive, createQuotasModel } from '../common/models/quotas.model';
import { getActualFeasibility, feasibilityService } from './feasibility.service';
import { SurveyMetadataListUpdate, targetGroupListService } from './sidebar/target-group-list.service';
import { PaneName } from './target-group-accordion.service';
import { ActiveTargetGroupModel } from './active-target-group/models/active-target-group.model';
import { projectSettingsChannel } from './channels/project-settings-channel';
import { Listener } from '../common/channels/channel';
import { draftChannel } from '../common/channels/draft-channel';
import { targetGroupChannel } from './channels/target-group-channel';
import { Panel, EligiblePanelSourceKeys } from './active-target-group/models/active-supply.model';
import { createIncentivesModelFromActive } from '../common/models/incentives.model';
import { featureFlipper } from '../common/feature-flipper';
import { priceQuoteService } from './price-quote.service';
import { managedServicesChannel } from '../managed-services.channel';
import { CommonConstants } from '../common/common.constants';
import { isEmptyGuid, isFeasibilityResponse } from '../helpers';
import { Feasibility, FeasibilityResponse } from '../common/http-services/feasibility.response';
import { $log, $stateParams } from '../ngimport';
import { TargetGroupsStateIntent } from '../target-groups-state-intent';
import { targetGroupsLoadingGuard } from './target-groups-loading-guard';
import { LoadTemplateResponse } from '../common/http-services/template.httpservice';
import { LinksData, TargetGroupValidationResult } from '../common/http-services/draft.httpservice';
import { ExistingTargetGroupSummary } from '../common/http-services/existing-project.models';
import { targetGroupValidator } from './target-group-validator';
import { SupplyResponse } from '../common/http-services/supply.httpservice';
import { api } from '../common/api';
import { CacheDuration, PanelistPoolType, QuotaOptions, SupplySource } from '../common/enums';
import { TargetGroupMapper } from '../common/target-group/target-group-mapper';
import { currentWorkService } from '../common/current-work.service';
import { inMemoryCache } from '../common/in-memory-cache';

const oxFeasibilityCachePrefix = 'oxFeasibility';
export interface ProjectSettingsTargetGroupValidation {
  isValid: boolean;
  tgId: number;
  name: string;
  incompatiblePanelIds?: number[];
  incompatiblePanelNames?: string[];
  supplySource?: SupplySource;
  hasNoCompatibleSelectedIds?: boolean;
  hasPanelSpecificProfiling?: boolean;
  hasQuotasOnProfiling?: boolean;
}

export class ProjectService extends Listener {
  active: TargetGroupModel;
  targetGroups: TargetGroupModel[];
  hasInitialized: boolean;
  restoring: boolean;

  constructor() {
    super();
    this.active = {} as TargetGroupModel;
    this.targetGroups = [];
    targetGroupChannel.model.restoring.listen(() => (this.restoring = true), this);
    targetGroupChannel.model.restored.listen(() => (this.restoring = false), this);
    targetGroupChannel.feasibility.runAll.listen(this.callFeasibilityForAllTargetGroups, this);
    targetGroupChannel.feasibility.runActive.listen(this.callFeasibilityForActive, this);
    targetGroupChannel.feasibility.done.listen(this.whenFeasibilityDone, this);
    targetGroupChannel.depth.run.listen(this.callProfilingDepthIfValid, this);
    targetGroupChannel.model.updated.listen(this.update, this);
    targetGroupChannel.model.activePane.updated.listen(this.updateActivePane, this);
    targetGroupChannel.model.deprecation.updated.listen(this.deprecationChanged, this);
    targetGroupChannel.linksData.updated.listen(async () => {
      this.targetGroups = await targetGroupRepository.findAll();
      this.active = await this.findById(this.active.id);
    }, this);
    managedServicesChannel.reset.listen(this.reset, this);
    projectSettingsChannel.saved.listen(async () => {
      if (!this.hasInitialized) return;
      this.active = await this.findFirst();
      this.show();
    }, this);
  }

  async init(): Promise<void> {
    this.hasInitialized = false;
    const isAddingTargetGroups = $stateParams.intent === TargetGroupsStateIntent.AddTargetGroups;
    this.targetGroups = await targetGroupRepository.findAll();
    saveStateService.init();
    if (!isEmpty(this.targetGroups)) {
      this.active = await this.findFirst();
    } else if (!isAddingTargetGroups) {
      await this.create();
    }
    currentWorkService.initCurrentWork();
    targetGroupsLoadingGuard.loaded('projectService');
    this.hasInitialized = true;
  }

  show(isAddingTargetGroups?: boolean): void {
    if (this.restoring) return; // We should probably do this because show calls restore so we don't want two restores at the same time
    targetGroupChannel.projectShow.dispatch();
    if (projectSettingsService.settings.isSet) {
      activeTargetGroupStore.showActive(this.active);
      if (!isEmpty(this.active.quotas.quotas)) {
        // Jakob: Is this needed? in showActive we call restore that triggers feas.
        this.callFeasibilityForActive();
      }
      targetGroupListService.createNewList(this.targetGroups, this.active.id, isAddingTargetGroups);
    }
  }

  async updateActivePane(paneName: PaneName): Promise<void> {
    this.active.activePane = paneName;
    const tgInMemory = await this.findById(this.active.id);
    tgInMemory.activePane = paneName;
    await targetGroupRepository.save(this.active);
  }

  async findById(targetGroupId: number): Promise<TargetGroupModel> {
    return targetGroupRepository.findById(targetGroupId);
  }

  async getTotalWantedCompletes(): Promise<number> {
    const allTgs = await targetGroupRepository.findAll();
    return allTgs.map((tg) => tg.basicSettings.numberOfCompletes).reduce((num, result) => num + result);
  }

  isAnyTgInRequestMode(): boolean {
    return some(this.targetGroups, (tg) => tg.requestMode.includeLockedPanels === true);
  }

  getAreQuotasTooComplexForActive(): boolean {
    return this.active.quotas ? targetGroupValidator.validateAreQuotasTooComplex(this.active.quotas) : false;
  }

  async changeActiveTargetGroup(id: number): Promise<void> {
    if (id === this.active.id) return;

    this.active = await this.findById(id);
    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.setActive(this.active.id);
  }

  async createNewTargetGroup(existingTargetGroups: ExistingTargetGroupSummary[] = []): Promise<number | undefined> {
    if (this.targetGroups.length >= CommonConstants.maximumTargetGroupsCount) return undefined;

    await this.create(existingTargetGroups);
    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.updateList(this.targetGroups, this.active.id);
    return this.active.id;
  }

  async renameTargetGroup(id: number, name: string) {
    this.updateInMemTargetGroup(id);
    const target = this.targetGroups.find((tg) => tg.id === id);
    target.name = name;
    await targetGroupRepository.save(target);
    if (this.active.id === id) {
      activeTargetGroupStore.renameTargetGroup(name);
      this.active.name = name;
    }
    targetGroupListService.renameTargetGroup(id, name);
  }

  async remove(id: number): Promise<void> {
    const nextActiveTargetGroup = await targetGroupRepository.getNextActiveTargetGroup(id);
    await targetGroupRepository.remove(id);
    this.targetGroups = await targetGroupRepository.findAll();
    if (!isEmpty(this.targetGroups)) {
      this.sortTargetGroups();
      this.active = nextActiveTargetGroup;
    } else {
      await this.create(targetGroupListService.existingTargetGroups);
    }

    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.updateList(this.targetGroups, this.active.id);
  }

  async reset(): Promise<void> {
    await targetGroupRepository.removeAll();
    persistentSurveyLinksService.clear();
    await this.create();

    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.createNewList(this.targetGroups, this.active.id);
  }

  async duplicateActive(copyUrls: boolean, removePool = false): Promise<void> {
    if (this.targetGroups.length >= CommonConstants.maximumTargetGroupsCount) return;
    this.active = await targetGroupRepository.duplicate(this.active, copyUrls, removePool);
    this.targetGroups = await targetGroupRepository.findAll();
    this.sortTargetGroups();
    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.updateList(this.targetGroups, this.active.id);
  }

  async refreshList(newTg: TargetGroupModel) {
    this.active = newTg;
    this.targetGroups = await targetGroupRepository.findAll();
    this.sortTargetGroups();
    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.updateList(this.targetGroups, this.active.id);
  }

  async addLoadedTemplate(template: LoadTemplateResponse): Promise<void> {
    const templateSource: ProjectTemplateSource = {
      kind: 'template',
      templateId: template.id,
      targetGroupId: 1,
      updateCount: 0,
    };
    this.active = await targetGroupRepository.createFromJSON(
      { ...template.targetGroup, linksData: null },
      templateSource
    );
    this.targetGroups = await targetGroupRepository.findAll();
    this.sortTargetGroups();
    activeTargetGroupStore.showActive(this.active);
    targetGroupListService.updateList(this.targetGroups, this.active.id);
  }

  getStorageDraft(): StorageDraft {
    return targetGroupRepository.getStorageDraft();
  }

  saveStorageDraft(draft: StorageDraft): void {
    saveStateService.setDirty(false);
    targetGroupRepository.saveStorageDraft(draft);
    projectSettingsService.updateProjectName(draft.name);
    draftChannel.saved.dispatch(draft.id);
  }

  async updateLinksData(targetGroupId: number, linksData: LinksData) {
    await targetGroupRepository.updateLinksData(targetGroupId, linksData);
    this.targetGroups = await targetGroupRepository.findAll();
  }

  async updateSupplySource(targetGroupId: number, supplySource: SupplySource) {
    await targetGroupRepository.updateSupplySource(targetGroupId, supplySource);
    this.targetGroups = await targetGroupRepository.findAll();
  }

  async validateTargetGroupsForProjectSettings(
    categoryIds: number[],
    filterOnPii: boolean,
    filterOnWebcam: boolean
  ): Promise<ProjectSettingsTargetGroupValidation[]> {
    const promises = [];
    for (const tg of this.targetGroups) {
      if (tg.panels.supplySource === SupplySource.AdHoc) {
        promises.push(
          Promise.resolve({
            tgId: tg.id,
            isValid: true,
            name: tg.name,
          })
        );
        continue;
      }
      const postData = {
        ir: tg.basicSettings.estimatedIncidenceRate,
        loi: tg.basicSettings.estimatedLengthOfInterview,
        categoryIds,
        filterOnPii,
        filterOnWebcam,
      };
      promises.push(
        api.supply.getSupply(tg.basicSettings.countryId, postData).then(({ data }: IHttpResponse<SupplyResponse>) => {
          const availablePanelIds: number[] = [];
          const unavailablePanels: { id: number; name: string }[] = [];
          for (const key of keys(data)) {
            for (const panel of data[key as EligiblePanelSourceKeys]) {
              if (!panel.isDisqualified) {
                availablePanelIds.push(panel.id);
              }
              unavailablePanels.push({ id: panel.id, name: panel.name });
            }
          }
          const incompatiblePanelIds = difference(tg.panels.selectedIds, availablePanelIds);
          const incompatiblePanelNames = unavailablePanels
            .filter((p) => includes(incompatiblePanelIds, p.id))
            .map((p) => p.name);
          let { supplySource } = tg.panels;
          if (supplySource === SupplySource.UnspecifiedPanels) {
            supplySource = this.getSupplySource(tg.panels.selectedIds, data);
            this.updateSupplySource(tg.id, supplySource);
          }
          const result: ProjectSettingsTargetGroupValidation = {
            tgId: tg.id,
            name: tg.name,
            isValid: isEmpty(incompatiblePanelIds),
            incompatiblePanelIds,
            incompatiblePanelNames,
            supplySource,
            hasNoCompatibleSelectedIds: isEmpty(difference(tg.panels.selectedIds, incompatiblePanelIds)),
            hasPanelSpecificProfiling: tg.profiling.panelSpecificProfiling,
            hasQuotasOnProfiling: tg.quotas.quotas.some((q) => q.keys.some((k) => k.startsWith('profiling:question'))),
          };
          return result;
        })
      );
    }
    return Promise.all(promises);
  }

  async adjustTargetGroupsForProjectSettings(validationResult: ProjectSettingsTargetGroupValidation[]) {
    const promises = [];
    for (const tg of validationResult) {
      if (tg.isValid) continue;
      const persistedTg = this.targetGroups.find((targetGroup) => targetGroup.id === tg.tgId);
      const updatedTg = ngCopy(persistedTg);
      remove(updatedTg.panels.selectedIds, (p) => includes(tg.incompatiblePanelIds, p));
      if (tg.supplySource === SupplySource.SupplyMix) {
        updatedTg.panels = createSupplyModel();
        updatedTg.quotas = createQuotasModel();
      }
      if (isEmpty(updatedTg.panels.selectedIds)) {
        updatedTg.panels = createSupplyModel();
      }
      if (tg.hasPanelSpecificProfiling) {
        updatedTg.profiling = createProfilingModel();
        if (tg.hasQuotasOnProfiling) {
          updatedTg.quotas = createQuotasModel();
        }
      }

      promises.push(targetGroupRepository.save(updatedTg));
    }
    await Promise.all(promises);
    this.active = ngCopy(await targetGroupRepository.findById(this.active.id));
    this.targetGroups = ngCopy(await targetGroupRepository.findAll());
  }

  async applySurveyMetadataToAll(targetGroupIds: number[], tags: string[]) {
    const promises: Promise<void>[] = [];
    const supportedTgs = this.targetGroups.filter((tg) => targetGroupIds.find((tgId) => tgId === tg.id));
    const listServiceUpdates: SurveyMetadataListUpdate[] = [];

    for (const persistedTg of supportedTgs) {
      const updatedTg = ngCopy(persistedTg);
      updatedTg.surveyMetadata = { studyTypes: tags };

      promises.push(targetGroupRepository.save(updatedTg));
      listServiceUpdates.push({
        targetGroupId: updatedTg.id,
        surveyMetadataTagsCount: updatedTg.surveyMetadata?.studyTypes?.length ?? 0,
      });
    }
    await Promise.all(promises);
    targetGroupListService.updateSurveyMetadataTagsCount(...listServiceUpdates);

    this.active = ngCopy(await targetGroupRepository.findById(this.active.id));
    this.targetGroups = ngCopy(await targetGroupRepository.findAll());
  }

  async callOxSupplyFeasibilityForActive(): Promise<FeasibilityResponse> {
    const targetGroup = this.getClonedActiveTargetGroupForOxFeasibility();
    const requestHash = hash(targetGroup, { algorithm: 'md5', encoding: 'base64' });

    return inMemoryCache.getOrAddAsync(
      `${oxFeasibilityCachePrefix}_${requestHash}`,
      async () => {
        const validationResult = targetGroupValidator.validate(targetGroup);
        if (validationResult.hasError()) return null;

        const newTargetGroups = cloneDeep(this.targetGroups);

        newTargetGroups.splice(
          this.targetGroups.findIndex((tg) => tg.id === this.active.id),
          1,
          targetGroup
        );
        const price = await priceQuoteService.getPriceQuoteForAllValidTargetGroups(newTargetGroups);
        const tgCpi = price.targetGroups.find((tg) => tg.id === targetGroup.id)?.cpi;
        const tgForFeasibilty = TargetGroupMapper.toTargetGroupForFeasibility(targetGroup, tgCpi);

        const feasibilityResponse = (await feasibilityService.debouncedGetFeasibility(
          tgForFeasibilty,
          projectSettingsService.settings
        )) as FeasibilityResponse;

        return feasibilityResponse;
      },
      CacheDuration.Medium
    );
  }

  inactiveTgHasCensusQuota(quotaKey: string) {
    const activeTgId = activeTargetGroupStore.model.identity.id;
    const activeCountryId = activeTargetGroupStore.model.basicSettings.countryId;

    return this.targetGroups.some(
      (tg) =>
        tg.id !== activeTgId &&
        tg.basicSettings.countryId === activeCountryId &&
        tg.quotas.quotas.some(
          (q) => q.quotaOption === QuotaOptions.Completes && q.keys.length === 1 && q.keys[0] === quotaKey
        )
    );
  }

  private getSupplySource(panelIds: number[], supply: SupplyResponse) {
    if (panelIds.every((id) => supply.cintPanels.map((p) => p.id).includes(id))) return SupplySource.CintPanels;
    if (panelIds.every((id) => supply.ownPanels.map((p) => p.id).includes(id))) return SupplySource.OwnPanels;
    if (panelIds.every((id) => supply.privatePricing.map((p) => p.id).includes(id))) return SupplySource.PrivatePricing;
    return SupplySource.UnspecifiedPanels;
  }

  private async findFirst(): Promise<TargetGroupModel> {
    const id = targetGroupRepository.findFirstTargetGroupId();
    return targetGroupRepository.findById(id);
  }

  private async create(existingTargetGroups: ExistingTargetGroupSummary[] = []): Promise<void> {
    this.active = await targetGroupRepository.createDefault(existingTargetGroups.length, existingTargetGroups);
    this.targetGroups = await targetGroupRepository.findAll();
    this.sortTargetGroups();
  }

  private sortTargetGroups(): void {
    this.targetGroups = targetGroupRepository.getTargetGroupSorter().sort(this.targetGroups, (tg) => tg.id);
  }

  private async update(data: { model: ActiveTargetGroupModel; withRequestFeasibility?: boolean }): Promise<void> {
    saveStateService.setDirty(true);
    this.active.projectTemplateSource.updateCount++;

    const { model } = data;

    this.active.name = model.identity.name;
    this.active.activePane = model.ui.activePane;
    this.active.basicSettings = createBasicSettingsModelFromActive(model.basicSettings);
    this.active.regions = createRegionsModelFromActive(model.regions);
    this.active.panels = createSupplyModelFrom(model.supply);
    this.active.profiling = createProfilingModelFromActive(model.profiling);
    this.active.excludeProjects = createExcludeProjectsModelFromActive(model.excludeProjects);
    this.active.surveyMetadata = ngCopy(model.surveyMetadata);
    if (featureFlipper.isEnabled('editIncentive')) {
      this.active.incentives = createIncentivesModelFromActive(model.incentives);
    }

    const { quotas, buckets } = activeTargetGroupStore.quotas.generatedBuckets;
    const { sectionsUsingPanelDistribution, sectionsUsingIgnoreCompletes } = activeTargetGroupStore.quotas;

    this.active.quotas = createQuotasModelFromActive(
      quotas,
      buckets,
      model.quotas.usePercentages,
      model.quotas.weightingStrategy,
      sectionsUsingPanelDistribution,
      sectionsUsingIgnoreCompletes,
      model.quotas.quotaGroupNames
    );

    this.active.requestMode = { includeLockedPanels: model.supply.includeLockedPanels };

    await targetGroupRepository.save(this.active);

    await this.updateInMemTargetGroup(this.active.id);

    targetGroupChannel.depth.validated.dispatch(canCallProfilingDepth(this.active));

    if (data.withRequestFeasibility) {
      this.callFeasibilityForActive();
    }
  }

  private async callFeasibilityForAllTargetGroups(): Promise<void> {
    if (!projectSettingsService.settings.isSet) return;
    try {
      const price = await priceQuoteService.getPriceQuoteForAllValidTargetGroups(this.targetGroups);
      const validTargetGroups = !price
        ? []
        : this.targetGroups.filter((targetGroup) => price.targetGroups.map((tg) => tg.id).includes(targetGroup.id));

      await feasibilityService.getFeasibilityForTargetGroups(validTargetGroups, projectSettingsService.settings, price);
    } catch (err) {
      $log.warn('one or more feasibilities failed', err);
    }
  }

  private async callFeasibilityForActive(): Promise<void> {
    const targetGroup = this.active;
    const validationResult = targetGroupValidator.validate(targetGroup);
    if (validationResult.hasError()) {
      targetGroupChannel.feasibility.invalid.dispatch({ targetGroup, validationResult });
      return;
    }
    const price = await priceQuoteService.getPriceQuoteForAllValidTargetGroups(this.targetGroups);
    const tgCpi = price.targetGroups.find((tg) => tg.id === targetGroup.id)?.cpi;
    const tgForFeasibilty = TargetGroupMapper.toTargetGroupForFeasibility(targetGroup, tgCpi);

    targetGroupChannel.feasibility.start.dispatch({ targetGroup, validationResult });

    await feasibilityService
      .debouncedGetFeasibility(tgForFeasibilty, projectSettingsService.settings)
      .then((res: FeasibilityResponse | { isInvalid: boolean; reason: 'error' | 'timeout' }) => {
        if (isFeasibilityResponse(res)) {
          targetGroupChannel.feasibility.done.dispatch({ targetGroup, feasibilityResponse: res });
        } else {
          targetGroupChannel.feasibility.error.dispatch({ targetGroupId: targetGroup.id, reason: res?.reason });
        }
      })
      .catch((err) => {
        $log.warn('single feasibility failed', err);
      });
  }

  private callProfilingDepthIfValid(categoryId: number): void {
    const activeTgModel = activeTargetGroupStore.model;

    const hasChosenPanels = this.active.panels.selectedIds.length > 0;
    const hasPanelistPool = !isEmptyGuid(this.active.panels.panelistPool.selectedPoolId);
    const hasAdHoc = !isEmpty(this.active.panels.adHocSupplier);
    const selectedPanels = this.getSelectedPanels(activeTgModel);

    const onlyPushSelected =
      hasChosenPanels && selectedPanels.length > 0 ? selectedPanels.every((p) => p.isPush) : false;

    if (
      (!hasChosenPanels && !hasAdHoc) ||
      (hasChosenPanels && !onlyPushSelected) ||
      (hasChosenPanels && hasPanelistPool)
    ) {
      profilingDepthService.throttledGetProfilingDepth(this.active, projectSettingsService.settings, categoryId);
    }
    if (onlyPushSelected || hasAdHoc) {
      profilingDepthService.depthUnavailable(activeTgModel.profiling, true);
    }
  }

  // TODO: Can we use selectedIds here instead of checking isSelected?
  private getSelectedPanels(activeTargetGroup: ActiveTargetGroupModel): Panel[] {
    const panels: Panel[] = union(
      activeTargetGroup.supply.panelSources.cintPanels,
      activeTargetGroup.supply.panelSources.privatePricing,
      activeTargetGroup.supply.panelSources.ownPanels
    );

    return panels ? panels.filter((p) => includes(activeTargetGroup.supply.selectedIds, p.id)) : [];
  }

  private deprecationChanged(data: { deprecationModel: TargetGroupValidationResult; hasAnyDeprecation: boolean }) {
    this.active.hasDeprecation = data.hasAnyDeprecation;
    targetGroupRepository.saveDeprecation(this.active.id, data.deprecationModel);
    targetGroupListService.deprecationChanged(this.active.id, data.hasAnyDeprecation);
  }

  private async whenFeasibilityDone(data: { targetGroup: TargetGroupModel; feasibilityResponse: FeasibilityResponse }) {
    const feasibility = getActualFeasibility(data.feasibilityResponse);
    const updatedTargetGroup = await this.updateBucketsWithWeightingResult(data.targetGroup, feasibility);

    await targetGroupRepository.save(updatedTargetGroup);
    this.updateInMemTargetGroup(updatedTargetGroup.id);

    targetGroupChannel.model.quotas.updatedBucketsWithWantedCompletes.dispatch();
  }

  private async updateBucketsWithWeightingResult(targetGroup: TargetGroupModel, feasibility: Feasibility) {
    for (const bucket of targetGroup.quotas.buckets) {
      const feasibilityBucket = find(feasibility.buckets, (b) => b.id === bucket.id);
      if (feasibilityBucket === undefined) {
        throw Error('bucket not found in feasibility: should not happen');
      }
      bucket.wantedCompletes = feasibilityBucket.wantedCompletes;
    }
    return targetGroup;
  }

  private async updateInMemTargetGroup(id: number) {
    const tgIndex = findIndex(this.targetGroups, { id });
    const persistedTg = await targetGroupRepository.findById(id);
    this.targetGroups.splice(tgIndex, 1, persistedTg);

    if (this.active.id === persistedTg.id) {
      this.active = persistedTg;
    }
  }

  private getClonedActiveTargetGroupForOxFeasibility(): TargetGroupModel {
    const clonedTg = cloneDeep(this.active);
    clonedTg.name = `oxFeasibilityRequest`;
    clonedTg.id = 1;
    clonedTg.panels = {
      ...this.active.panels,
      supplySource: SupplySource.SystemSelected,
      selectedIds: [],
      adHocSupplier: {},
      supplyMix: {
        supplyGroups: [],
        ignoreCompletes: false,
      },
      panelistPool: {
        source: null,
        input: '',
        rootPoolId: '00000000-0000-0000-0000-000000000000',
        selectedPoolId: '00000000-0000-0000-0000-000000000000',
        panelistCount: 0,
        panelIds: [],
        selectedGroup: '',
        hasAvailablePanelists: true,
        type: PanelistPoolType.None,
        containsUrl: false,
        sourceUrl: null,
        createPoolResult: {} as any,
      },
      useCustomCpi: false,
    };

    // Remove supply quotas and their buckets, if they exist
    if (this.active.panels.supplySource === SupplySource.SupplyMix) {
      let foundFirstSupplyQuota = false;
      const supplyBucketIdsToBeDeleted: number[] = [];
      clonedTg.quotas.quotas.forEach((q) => {
        if (q.keys[0] === 'supply') {
          if (foundFirstSupplyQuota) supplyBucketIdsToBeDeleted.push(...q.buckets);
          else foundFirstSupplyQuota = true;
        }
      });

      const newBuckets = clonedTg.quotas.buckets
        .filter((b) => !supplyBucketIdsToBeDeleted.includes(b.id))
        .map((b) => ({
          ...b,
          key: {
            ...b.key,
            supply: { panels: [] },
          },
        }));
      const newQuotas = clonedTg.quotas.quotas
        .filter((q) => q.keys[0] !== 'supply')
        .map((q) => ({
          ...q,
          buckets: q.buckets.filter((b) => !supplyBucketIdsToBeDeleted.includes(b)),
        }));

      return {
        ...clonedTg,
        quotas: {
          ...clonedTg.quotas,
          buckets: newBuckets,
          quotas: newQuotas,
          sectionsUsingPanelDistribution: [],
        },
      };
    }

    return clonedTg;
  }
}

export const projectService = new ProjectService();
