import { ui } from 'angular';
import { html } from '../../../helpers';

const selector = 'deleteTargetGroupDialogComponent';

const template = html`
  <div class="delete-target-group-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Delete target group?</h1>
      <p class="push-down">Deleting <em>{{$ctrl.targetGroupName}}</em> will remove it from the list.</p>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button data-testid="delete-target-group-button" class="ui-btn red-btn" ng-click="$ctrl.ok()">
          Delete target group
        </button>
      </div>
    </div>
  </div>
`;

export interface DeleteTargetGroupDialogResolve {
  targetGroupName: string;
}

interface Bindings {
  resolve: '<' | DeleteTargetGroupDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class DeleteTargetGroupDialogComponent implements Bindings {
  static componentName = selector;

  resolve: DeleteTargetGroupDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;

  targetGroupName: string;

  $onInit() {
    this.targetGroupName = this.resolve.targetGroupName;
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  ok(): void {
    this.modalInstance.close();
  }
}

export const ngDeleteTargetGroupDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: DeleteTargetGroupDialogComponent,
  },
};
