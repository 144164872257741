import { isEmpty, map } from 'lodash-es';
import { IOnChangesObject } from 'angular';
import { ExistingTargetGroupQuestion, ExistingTargetGroupProfiling } from '../http-services/existing-project.models';
import { html } from '../../helpers';

const selector = 'targetGroupProfilingComponent';

const template = html`
  <div class="target-group-profiling-component">
    <p ng-hide="$ctrl.questions.length" class="irrelevant push-down">No profiling attributes selected</p>
    <ul ng-show="$ctrl.questions.length">
      <li ng-repeat="question in $ctrl.questions">
        <h2>{{question.name}}</h2>
        <p><label class="option-label" ng-repeat="variable in question.variables">{{variable}}</label></p>
      </li>
    </ul>
  </div>
`;

interface Question {
  name: string;
  variables: string[];
}

interface Bindings {
  profiling: '<' | ExistingTargetGroupProfiling;
}

const bindings: Bindings = {
  profiling: '<',
};

export class TargetGroupProfilingComponent implements Bindings {
  static componentName = selector;

  profiling: ExistingTargetGroupProfiling;
  questions: Question[];

  $onInit(): void {
    if (isEmpty(this.profiling)) return;

    this.questions = map(this.profiling.questions, (q) => this.mapQuestion(q));
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.profiling && changes.profiling.currentValue) {
      this.questions = map(changes.profiling.currentValue.questions, (q: ExistingTargetGroupQuestion) =>
        this.mapQuestion(q)
      );
    }
  }

  private mapQuestion(question: ExistingTargetGroupQuestion): Question {
    return {
      name: question.name,
      variables: map(question.variables, (a) => a.name),
    };
  }
}

export const ngTargetGroupProfilingComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TargetGroupProfilingComponent,
  },
};
