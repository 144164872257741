import { ReactNode, RefCallback, useLayoutEffect, useState } from 'react';

interface Props {
  visible: boolean;
  content: ReactNode;
  placement?: 'left' | 'right';
  size?: string;
  children: React.ReactNode;
}

const containerStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 99999,
  borderRadius: 6,
  backgroundColor: '#fff',
  WebkitBoxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.35)',
  boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.35)',
};

const translatePlacement = (placement: 'left' | 'right' | undefined, screenOffset: number) => {
  if (!placement) return {};
  return placement === 'right' ? { right: screenOffset } : { left: screenOffset };
};

const translateSize = (size: string | undefined) => {
  if (!size) return {};
  return { width: size, maxWidth: size };
};

// Calculates by how much an element needs to be offset from either left or right side of the viewport to not overflow.
// Attach the returned `elementRef` to the element to be checked using the ref attribute (eg <div ref={}).
// Note that the placement parameter refers to the custom popover "anchor", so right would check overflow of the left side of the viewport.
const useScreenOffset = (
  placement: 'left' | 'right' | undefined
): [screenOffset: number, elementRef: RefCallback<HTMLElement>] => {
  const [domElement, setDomElement] = useState<HTMLElement>(null);
  const [screenOffset, setScreenOffset] = useState<number>(0);

  useLayoutEffect(() => {
    if (domElement != null) {
      const boundingBox = domElement.getBoundingClientRect();
      if (placement === 'right') {
        const target = boundingBox.x + screenOffset;
        setScreenOffset(Math.min(target, 0));
      }
      if (placement === 'left') {
        const target = document.documentElement.clientWidth - boundingBox.right + screenOffset;
        setScreenOffset(Math.min(target, 0));
      }
    }
  }, [domElement, placement]);

  return [screenOffset, setDomElement];
};

export const CustomPopover: React.FC<Props> = ({ visible, content, placement, size, children }) => {
  const [screenOffset, containerRef] = useScreenOffset(placement);

  return (
    <div>
      {children}
      {visible && (
        <div
          ref={containerRef}
          style={{ ...containerStyle, ...translateSize(size), ...translatePlacement(placement, screenOffset) }}
        >
          {content}
        </div>
      )}
    </div>
  );
};
