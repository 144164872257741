import { react2angular } from 'react2angular';
import { filters } from '../../filters';
import { TargetGroupListModel } from '../../http-services/project-search.httpService';

interface Props {
  status: string;
  showText: boolean;
  deviations?: TargetGroupListModel;
}

export const tgStatusIcons = {
  closed: <label className="tg-status closed" />,
  active: <label className="tg-status active" />,
  pending: <label className="tg-status pending" />,
  onHold: <label className="tg-status on-hold" />,
  stale: <label className="tg-status stale" />,
  warning: <label className="tg-status on-hold" />,
};

export const TargetGroupStatus: React.FC<Props> = ({ status, deviations }) => {
  let tooltip = '';
  let statusIcon = <></>;
  switch (status?.toLowerCase()) {
    case 'active':
    case 'activated': //TODO: why not using enum TargetGroupStatus here?
      if (deviations?.isStale) {
        tooltip = `Target group is considered Stale. It has not had any respondent activity since ${filters.dateTimeFormat()(
          deviations.lastActivity
        )}`;
        statusIcon = tgStatusIcons.warning;
      } else {
        tooltip = `Target group is active`;
        statusIcon = tgStatusIcons.active;
      }
      break;
    case 'pending':
      if (deviations?.isLagging) {
        tooltip = `Target group is not collecting completes as expected. Expected ${deviations.currentExpectedCompletes} completes at this moment.`;
        statusIcon = tgStatusIcons.warning;
      } else {
        tooltip = `Target group is not active`;
        statusIcon = tgStatusIcons.pending;
      }
      break;
    case 'paused':
      tooltip = `Target group is suspended (On Hold) and not collecting any completes at the moment`;
      statusIcon = tgStatusIcons.warning;
      break;
    default:
      break;
  }
  return <span title={tooltip}>{statusIcon}</span>;
};

export const ngTargetGroupStatus = {
  targetGroupStatus: react2angular(TargetGroupStatus, ['status', 'showText', 'deviations']),
};
