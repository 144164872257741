import { find, every, isEmpty } from 'lodash-es';
import { ActiveRegionsModel, Region } from '../../models/active-regions.model';
import { ActiveTargetGroupModel } from '../../models/active-target-group.model';
import { targetGroupChannel } from '../../../channels/target-group-channel';
import { regionsSubactions, UploadPostalCodesData } from './regions.subactions';
import { activeTargetGroupStore, ActiveTargetGroupStore, CommitFuncs } from '../active-target-group.store';
import { quotasSubactions } from '../quotas/quotas.subactions';
import { RegionTypeKey } from '../../../../common/enums';
import { stateDebugService } from '../../../../common/state-debug.service';

type RegionsUpdateFunc = (state: ActiveTargetGroupModel, commit: CommitFuncs, done: () => void) => void;

export const commitRegions =
  () =>
  (regionsState: ActiveRegionsModel): void => {
    activeTargetGroupStore.model.regions = regionsState;
    targetGroupChannel.model.regions.updated.dispatch();
  };

export class RegionsActions {
  constructor(private rootStore: ActiveTargetGroupStore) {}

  get areAllRegionsForMainRegionTypeSelected(): boolean {
    const { regions } = this.rootStore.model;
    const isMainRegType =
      !isEmpty(regions.selectedRegionType) && regions.selectedRegionType.type === RegionTypeKey.Main;
    if (isMainRegType) {
      const mainRegType = find(regions.regionTypes, (rt) => {
        return rt.id === regions.selectedRegionType.id;
      });
      return every(mainRegType.regions, (reg: Region) => reg.isSelected);
    }
    return false;
  }

  setActiveRegionType(regionTypeId: number) {
    this.update((state, commit, done) => {
      targetGroupChannel.model.regions.regionTypeChanging.dispatch();
      regionsSubactions
        .createRegionsPromise(state.regions, state.basicSettings.countryId, regionTypeId)
        .then((newState) => {
          commit.regions(newState);
          done();
        });
    });
  }

  setActiveRegionTypeAndPreselect(regionTypeId: number, selectRegionId: number) {
    this.update((state, commit, done) => {
      targetGroupChannel.model.regions.regionTypeChanging.dispatch();
      regionsSubactions
        .createRegionsPromise(state.regions, state.basicSettings.countryId, regionTypeId)
        .then((newState) => {
          commit.regions(newState);
          this.toggleRegion(selectRegionId);
          done();
        });
    });
  }

  discardRegionType() {
    this.update((state, commit, done) => {
      const newRegionsState = regionsSubactions.discardRegionType(state.regions);
      commit.regions(newRegionsState);
      let newQuotasState = quotasSubactions.clearRegionQuotas(state.quotas);
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'region');
      commit.quotas(newQuotasState);
      done();
    });
  }

  toggleRegion(regionId: number) {
    this.update((state, commit, done) => {
      const newRegionsState = regionsSubactions.toggleRegion(state.regions, regionId);
      commit.regions(newRegionsState);
      let newQuotasState = quotasSubactions.clearRegionQuotas(state.quotas);
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'region');
      commit.quotas(newQuotasState);
      done();
    });
  }

  setPostalCodes(postalCodesModel: UploadPostalCodesData) {
    this.update((state, commit, done) => {
      const newState = regionsSubactions.addPostalCodes(state.regions, postalCodesModel);
      commit.regions(newState);
      done();
    });
  }

  selectAllRegions(regionIds?: number[]) {
    this.update((state, commit, done) => {
      const newState = regionsSubactions.selectAllRegions(state.regions, regionIds);
      commit.regions(newState);
      let newQuotasState = quotasSubactions.clearRegionQuotas(state.quotas);
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'region');
      commit.quotas(newQuotasState);
      done();
    });
  }

  deselectAllRegions(regionIds?: number[]) {
    this.update((state, commit, done) => {
      const newState = regionsSubactions.deselectAllRegions(state.regions, regionIds);
      commit.regions(newState);
      let newQuotasState = quotasSubactions.clearRegionQuotas(state.quotas);
      newQuotasState = quotasSubactions.removeDependentInterlockedSection(newQuotasState, 'region');
      commit.quotas(newQuotasState);
      done();
    });
  }

  private update(updateFunc: RegionsUpdateFunc) {
    stateDebugService.logIfUnsafeUpdate('regions');
    this.rootStore.signalUpdateStarted();

    updateFunc(this.rootStore.model, this.rootStore.commitFuncs, () => this.rootStore.publishUpdate());
  }
}
