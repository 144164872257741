import {
  difference,
  pick,
  uniq,
  includes,
  isEmpty,
  groupBy,
  values,
  every,
  isFinite,
  isNumber,
  isInteger,
  head,
  isEqual,
} from 'lodash-es';
import { Dictionary } from 'lodash';
import { isUndefinedOrEmpty, isUndefinedOrNull, keys } from '../helpers';
import { basicSettingsValidator } from './basic-settings/basic-settings-validator';
import { countryService } from './country.service';
import { ErrorModel, ValidationErrors } from './validation-errors';
import { BasicSettingsModel } from '../common/models/basic-settings.model';
import { RegionsModel } from '../common/models/regions.model';
import { ProfilingModel } from '../common/models/profiling.model';
import { SupplyModel } from '../common/models/supply.model';
import { SupplySource, PanelistPoolType, Product } from '../common/enums';
import { QuotasModel } from '../common/models/quotas.model';
import { ExcludeProjectsModel } from '../common/models/exclude-projects.model';
import { validatePanelistPool, validateAdHoc, validateSupplyMix } from './validator.functions';
import { ActiveSupplyModel } from './active-target-group/models/active-supply.model';
import { TargetGroupModel } from '../common/models/target-group.model';
import { IncentivesModel } from '../common/models/incentives.model';
import { featureFlipper } from '../common/feature-flipper';
import { TargetGroupConstants } from './target-group.constants';
import { CommonConstants } from '../common/common.constants';
import { currencyService } from './currency.service';
import { countryRestrictionsDisabledService } from '../common/country-restrictions-disabled.service';
import {
  filterGenderQuotas,
  filterInterlockedQuotas,
  filterAgeQuotas,
  filterRegionQuotas,
  filterProfilingQuotas,
  sumUpCompletes,
  filterSupplyQuotas,
  getCompletesOrStarts,
  sumUpStarts,
} from './quotas/quotas-helper.functions';
import { QuotaWithBuckets } from './quotas/quota-bucket-builder';
import { session } from '../common/session';

export interface ValidationSections {
  basicSettings: ErrorModel;
  regions: ErrorModel;
  profiling: ErrorModel;
  supply: ErrorModel;
  excludeProjects: ErrorModel;
  incentives: ErrorModel;
  quotas: ErrorModel;
}

export type ValidationSection = keyof ValidationSections;

export class TargetGroupValidator {
  validate(tg: Partial<TargetGroupModel>, excludeFromValidation?: ValidationSection[]): ErrorModel {
    if (excludeFromValidation === undefined || !Array.isArray(excludeFromValidation)) {
      excludeFromValidation = [];
    }

    const noRestrictionsOnIrAndLoi = countryRestrictionsDisabledService.shouldOverrideIrAndLoiCountryRestrictions(
      tg.panels
    );

    const validation: ValidationSections = {
      basicSettings: this.validateBasicSettings(tg.basicSettings, noRestrictionsOnIrAndLoi),
      regions: this.validateRegions(tg.regions),
      profiling: this.validateProfiling(tg.profiling),
      supply: this.validateSupply(
        tg.panels,
        tg.profiling.panelSpecificProfiling,
        tg.basicSettings.numberOfCompletes,
        tg.basicSettings.numberOfStarts
      ),
      excludeProjects: this.validateExcludeProjects(tg.excludeProjects),
      incentives: this.validateIncentives(tg.incentives, tg.panels.ownPanelCurrency),
      quotas: this.validateQuotas(tg.quotas, tg.basicSettings, tg.quotas.sectionsUsingIgnoreCompletes),
    };

    const sectionsToValidate = difference(keys(validation), excludeFromValidation);

    const isValid = every(pick(validation, sectionsToValidate), (v: ErrorModel) => !v.hasError());

    if (isValid) return ValidationErrors.noError;
    if (validation.basicSettings.hasError()) return validation.basicSettings;
    if (validation.regions.hasError()) return validation.regions;
    if (validation.profiling.hasError()) return validation.profiling;
    if (validation.supply.hasError()) return validation.supply;
    if (validation.excludeProjects.hasError()) return validation.excludeProjects;
    if (validation.incentives.hasError()) return validation.incentives;
    if (validation.quotas.hasError()) return validation.quotas;
    return ValidationErrors.noError;
  }

  validateBasicSettings(basicSettings: BasicSettingsModel, noRestrictionsOnIrAndLoi: boolean): ErrorModel {
    const bsValidationResult = basicSettingsValidator.validate(basicSettings, noRestrictionsOnIrAndLoi);

    const countryRestrictions = countryService.getRestrictions(basicSettings.countryId, noRestrictionsOnIrAndLoi);

    const isCountryValid = bsValidationResult.countryId.isValid;
    const isAgeValid = bsValidationResult.minAge.isValid && bsValidationResult.maxAge.isValid;
    const isNumberOfCompletesValid = bsValidationResult.numberOfCompletes.isValid;
    const isNumberOfStartsValid = basicSettings.useStarts ? bsValidationResult.numberOfStarts.isValid : true;
    const isIrValid = bsValidationResult.estimatedIncidenceRate.isValid;
    const isLoiValid = bsValidationResult.estimatedLengthOfInterview.isValid;
    const isNumDaysInFieldValid = bsValidationResult.numberOfDaysInField.isValid;

    if (!isCountryValid) {
      return ValidationErrors.basicSettingsCountryRequired;
    }
    if (!isNumberOfCompletesValid) {
      return ValidationErrors.basicSettingsCompletesRequired;
    }
    if (!isNumberOfStartsValid) {
      return ValidationErrors.basicSettingsStartsRequired;
    }

    if (!isIrValid || !isLoiValid) {
      if (basicSettings.useFixedLoi) {
        return ValidationErrors.basicSettingsFixedLoiRestrictions.withData({
          ir: !isUndefinedOrEmpty(countryRestrictions) ? countryRestrictions.irMin : null,
          loi: TargetGroupConstants.maxLoiForFixedLoi,
        });
      }
      return ValidationErrors.basicSettingsCountryRestrictions.withData({
        ir: !isUndefinedOrEmpty(countryRestrictions) ? countryRestrictions.irMin : null,
        loi: !isUndefinedOrEmpty(countryRestrictions) ? countryRestrictions.loiMax : null,
      });
    }
    if (!isAgeValid || !isNumDaysInFieldValid) {
      return ValidationErrors.basicSettingsInput;
    }

    return ValidationErrors.noError;
  }

  validateRegions(regions: RegionsModel): ErrorModel {
    const regionTypeSelected = !isUndefinedOrEmpty(regions.regionTypeId);
    const postalCodesType = regions.regionTypeId === TargetGroupConstants.postalCodesRegionTypeId;
    const anyRegionsSelected = regionTypeSelected && regions.selectedIds.length > 0;

    if (!(!regionTypeSelected || anyRegionsSelected)) {
      return postalCodesType ? ValidationErrors.regionsPostalCode : ValidationErrors.regionsRequired;
    }

    return ValidationErrors.noError;
  }

  validateProfiling(profiling: ProfilingModel): ErrorModel {
    const getCategoryIds = () => {
      if (isEmpty(profiling.selectedVariables)) return [];
      const catIds = keys(profiling.selectedVariables).map((key) => profiling.selectedVariables[key].categoryId);
      return uniq(catIds);
    };

    const hasActiveCategory = profiling.categoryId !== undefined;
    const activeCategoryHasSelection = includes(getCategoryIds(), profiling.categoryId);
    const isValid = (hasActiveCategory && activeCategoryHasSelection) || !hasActiveCategory;

    if (!isValid) {
      return ValidationErrors.profilingRequired;
    }

    return ValidationErrors.noError;
  }

  validateSupply(
    supply: SupplyModel | ActiveSupplyModel,
    panelSpecificProfiling: boolean,
    wantedNumberOfCompletes: number,
    wantedNumberOfStarts: number | undefined
  ): ErrorModel {
    const isSupplyModel = (model: SupplyModel | ActiveSupplyModel): model is SupplyModel => 'adHocSupplier' in model;

    switch (supply.supplySource) {
      case SupplySource.PanelistPool:
        return validatePanelistPool(supply.panelistPool);
      case SupplySource.AdHoc:
        return validateAdHoc(isSupplyModel(supply) ? supply.adHocSupplier : supply.adHoc);
      case SupplySource.SupplyMix:
        return validateSupplyMix(supply.supplyMix, wantedNumberOfCompletes, wantedNumberOfStarts);
      default: {
        const isValid = supply.supplySource === SupplySource.SystemSelected || supply.selectedIds.length > 0;

        if (!isValid) {
          if (panelSpecificProfiling) return ValidationErrors.panelRequired;
          return ValidationErrors.panelsRequired;
        }

        if (supply.panelistPool.type === PanelistPoolType.Exclusive) {
          return validatePanelistPool(supply.panelistPool);
        }
      }
    }

    return ValidationErrors.noError;
  }

  validateAreQuotasTooComplex(quotasAndBuckets: QuotasModel): boolean {
    const bucketLimit =
      featureFlipper.isEnabled('increasedBucketLimit') && session.product === Product.AccessPro
        ? TargetGroupConstants.increasedMaximumBucketsAllowed
        : TargetGroupConstants.maximumBucketsAllowed;

    return quotasAndBuckets.buckets.length > bucketLimit;
  }

  validateQuotas(
    quotasAndBuckets: QuotasModel,
    basicSettings: { numberOfCompletes: number; numberOfStarts?: number | undefined; useStarts: boolean },
    sectionsUsingIgnoreCompletes: readonly (readonly string[])[] = []
  ): ErrorModel {
    if (this.validateAreQuotasTooComplex(quotasAndBuckets)) return ValidationErrors.tooManyBuckets;

    const validateAllQuotas = (): boolean => {
      const interlockedQuotas = groupBy(quotasAndBuckets.quotas.filter(filterInterlockedQuotas), (i) => i.keys);
      const nonInterlockedQuotas = {
        gender: quotasAndBuckets.quotas.filter(filterGenderQuotas),
        ageSpan: quotasAndBuckets.quotas.filter(filterAgeQuotas),
        region: quotasAndBuckets.quotas.filter(filterRegionQuotas),
        supply: quotasAndBuckets.quotas.filter(filterSupplyQuotas),
        profiling: quotasAndBuckets.quotas.filter(filterProfilingQuotas),
      };
      const interlockedQuotasValid = areInterlockedQuotasValid(interlockedQuotas, quotasAndBuckets.usePercentages);
      const nonInterlockedQuotasValid = areNonInterlockedQuotasValid(
        nonInterlockedQuotas,
        quotasAndBuckets.usePercentages
      );
      return interlockedQuotasValid && nonInterlockedQuotasValid;
    };

    const validateQuotasWithBuckets = (
      quotasWithBuckets: QuotaWithBuckets[],
      usePercentages: boolean,
      ignoreCompletes: boolean
    ) => {
      const hasWantedCompletes = quotasWithBuckets.every(
        (q: { wantedCompletes: number }) => !isUndefinedOrEmpty(q.wantedCompletes)
      );

      const hasWantedStarts = quotasWithBuckets.every(
        (q: { wantedStarts?: number }) => !isUndefinedOrEmpty(q.wantedStarts)
      );
      const completesAddUp =
        sumUpCompletes(quotasWithBuckets) === getCompletesOrStarts(usePercentages, basicSettings.numberOfCompletes);
      const startsAddUp =
        sumUpStarts(quotasWithBuckets) === getCompletesOrStarts(usePercentages, basicSettings.numberOfStarts);

      if (isEmpty(quotasWithBuckets)) return true;
      if (!basicSettings.useStarts) return hasWantedCompletes && completesAddUp;
      if (basicSettings.useStarts && ignoreCompletes) {
        return hasWantedStarts && startsAddUp;
      }
      if (basicSettings.useStarts && !ignoreCompletes) {
        return hasWantedCompletes && hasWantedStarts && startsAddUp && completesAddUp;
      }
      return false;
    };

    const areInterlockedQuotasValid = (
      interlockedQuotas: Dictionary<QuotaWithBuckets[]>,
      usePercentages: boolean
    ): boolean => {
      for (const key of keys(interlockedQuotas)) {
        const ignoreCompletes = shouldIgnoreCompletesForSection(interlockedQuotas[key]);
        const valid = validateQuotasWithBuckets(interlockedQuotas[key], usePercentages, ignoreCompletes);
        if (!valid) return false;
      }
      return true;
    };

    const shouldIgnoreCompletesForSection = (quotasWithBuckets: QuotaWithBuckets[]): boolean => {
      if (isEmpty(quotasWithBuckets)) return false;
      return sectionsUsingIgnoreCompletes.some((k) => isEqual(k, head(quotasWithBuckets).keys));
    };

    const areNonInterlockedQuotasValid = (
      quotas: {
        gender: QuotaWithBuckets[] & { isValid?: boolean };
        ageSpan: QuotaWithBuckets[] & { isValid?: boolean };
        region: QuotaWithBuckets[] & { isValid?: boolean };
        supply: QuotaWithBuckets[] & { isValid?: boolean };
        profiling: QuotaWithBuckets[] & { isValid?: boolean };
      },
      usePercentages: boolean
    ): boolean => {
      const ks = keys(quotas);
      for (const key of ks) {
        let valid = false;
        if (key === 'ageSpan') {
          valid = validateQuotasWithBuckets(
            quotas.ageSpan,
            usePercentages,
            shouldIgnoreCompletesForSection(quotas.ageSpan)
          );
        } else if (key === 'profiling') {
          const grouped: { [key: string]: QuotaWithBuckets[] & { isValid?: boolean } } = groupBy(
            quotas.profiling,
            (q: { keys: any[] }) => q.keys[0]
          ) as any;
          valid = keys(grouped).every((groupKey) =>
            validateQuotasWithBuckets(
              grouped[groupKey],
              usePercentages,
              shouldIgnoreCompletesForSection(grouped[groupKey])
            )
          );
        } else {
          valid = validateQuotasWithBuckets(quotas[key], usePercentages, shouldIgnoreCompletesForSection(quotas[key]));
        }

        quotas[key].isValid = valid;
      }

      return values(pick(quotas, ['gender', 'ageSpan', 'region', 'profiling'])).every((v) => v.isValid);
    };

    if (!validateAllQuotas()) {
      return ValidationErrors.quotasHaveNoCompletes;
    }

    return ValidationErrors.noError;
  }

  validateExcludeProjects(excludeProjects: ExcludeProjectsModel): ErrorModel {
    // Check projects without explicitly set exclusions
    const projectsWithoutExclusions = excludeProjects.projects.filter(
      (a) => a.respondentStatusTimelines == null || a.respondentStatusTimelines.length === 0
    );
    const isValid =
      (projectsWithoutExclusions.length === 0 && excludeProjects.respondentStatusTimelines.length === 0) ||
      (projectsWithoutExclusions.length > 0 && excludeProjects.respondentStatusTimelines.length > 0);

    if (!isValid) {
      return ValidationErrors.excludeProjectsRequired;
    }

    return ValidationErrors.noError;
  }

  validateIncentives(incentives: IncentivesModel, currencyCode: string): ErrorModel {
    if (!featureFlipper.isEnabled('editIncentive')) {
      return ValidationErrors.noError;
    }

    const { amount } = incentives.fixedIncentive;
    if (amount === undefined) return ValidationErrors.noError;
    if (!isNumber(amount) || !isFinite(amount) || amount < 0 || amount > CommonConstants.maximumFixedIncentiveAmount) {
      return ValidationErrors.fixedIncentiveAmountInvalid;
    }

    if (isUndefinedOrNull(currencyCode)) return ValidationErrors.fixedIncentiveCurrencyInvalid;

    const currency = currencyService.getCurrency(currencyCode);
    if (!currency.isValidForFixedIncentive) {
      return ValidationErrors.fixedIncentiveCurrencyInvalid;
    }

    if (!isInteger(amount / currency.incentiveStep)) {
      return ValidationErrors.fixedIncentiveAmountStepInvalid;
    }

    return ValidationErrors.noError;
  }
}

export const targetGroupValidator = new TargetGroupValidator();
