import { IHttpPromise, IRequestShortcutConfig } from 'angular';
import { Array, Boolean, Literal, Null, Number, Record, Static, String, Union } from 'runtypes';
import { $http } from '../../ngimport';
import {
  AggregatedRespondentBucketStatus,
  CircularType,
  QuotaFulfillmentType,
  QuotaGrouping,
  QuotaStatus,
  RespondentStatusNumeral,
  RespondentStatusTextual,
} from '../enums';
import { urlService } from './url.service';
import {
  AboveZero,
  EmptyString,
  GuidString,
  HttpUrl,
  Iso8601UtcString,
  NonEmptyString,
  NonNegative,
} from '../../custom-runtypes';
import { asJsonScalarString } from '../../helpers';

const SamplingStatus = Union(
  Literal('success'),
  Literal('waiting'),
  Literal('processing'),
  Literal('processingFailed'),
  Literal('insufficientLinks'),
  Literal('failedToAssignLinks'),
  Literal('successWithLinkShortage'),
  Literal('successWithRespondentShortage'),
  Literal('removed')
);

export const SamplingItem = Record({
  id: AboveZero,
  status: SamplingStatus,
  quantity: NonNegative,
  estimatedStarts: NonNegative.Or(Null),
  estimatedCompletes: NonNegative.Or(Null),
});
export type SamplingItem = Static<typeof SamplingItem>;

export const TargetGroupQuota = Record({
  id: AboveZero,
  name: NonEmptyString,
  wantedCompletes: NonNegative,
  wantedStarts: NonNegative.Or(Null),
  status: Union(
    Literal(QuotaStatus.NotStarted),
    Literal(QuotaStatus.Active),
    Literal(QuotaStatus.Paused),
    Literal(QuotaStatus.Closed)
  ),
  autoClose: Boolean,
  quotaType: Union(Literal(QuotaFulfillmentType.QuotasOnCompletes), Literal(QuotaFulfillmentType.QuotasOnStarted)),
  quotaGrouping: Array(
    Union(
      Literal(QuotaGrouping.Ungrouped),
      Literal(QuotaGrouping.Age),
      Literal(QuotaGrouping.Gender),
      Literal(QuotaGrouping.Region),
      Literal(QuotaGrouping.Profiling),
      Literal(QuotaGrouping.Supply)
    )
  ),
  sortOrder: NonNegative,
  currentStarted: NonNegative,
  currentCompletes: NonNegative,
  customerDiff: Number,
  currentCompletesFromOptimizer: NonNegative,
  currentStartsFromOptimizer: NonNegative,
  realConversionRate: NonNegative,
  bucketIds: Array(Number),
  aggregateQuotaSamplingItem: SamplingItem.Or(Null),
});
export type TargetGroupQuota = Static<typeof TargetGroupQuota>;

export const TargetGroupQuotasResponse = Array(
  Record({
    targetGroupId: AboveZero,
    quotas: Array(TargetGroupQuota),
  })
);
export type TargetGroupQuotasResponse = Static<typeof TargetGroupQuotasResponse>;

export const TargetGroupAggregatedStatus = Record({
  targetGroupId: AboveZero,
  aggregatedQuotaStatus: Union(
    Literal(QuotaStatus.NotStarted),
    Literal(QuotaStatus.Active),
    Literal(QuotaStatus.Paused),
    Literal(QuotaStatus.Closed).Or(Null)
  ),
  aggregatedSamplingStatus: SamplingStatus.Or(Null),
  quotaIds: Array(Number).Or(Null),
});

export type TargetGroupAggregatedStatus = Static<typeof TargetGroupAggregatedStatus>;

export const TargetGroupsAggregatedStatusResponse = Array(TargetGroupAggregatedStatus);

export type TargetGroupsAggregatedStatusResponse = Static<typeof TargetGroupsAggregatedStatusResponse>;

export const CalculateSendoutsResponse = Record({
  wantedQuantity: NonNegative,
  suggestedSendouts: NonNegative,
  estimatedConversionRate: NonNegative,
  suggestedConversionRate: NonNegative,
  suggestedValuesFromNodeType: Union(Literal('project'), Literal('target group'), Literal('quota'), Literal('bucket')),
  suggestionBasedOnRealValues: Boolean,
  validationError: String.Or(Null),
  hasValidationError: Boolean,
});
export type CalculateSendoutsResponse = Static<typeof CalculateSendoutsResponse>;

export const EnqueueSampleResponse = Record({
  validationError: String,
  hasValidationError: Boolean,
});
export type EnqueueSampleResponse = Static<typeof EnqueueSampleResponse>;

export interface UpdateQuotasRequest {
  targetGroups: UpdateTargetGroupQuotaModel[];
}

export interface UpdateTargetGroupQuotaModel {
  targetGroupId: number;
  quotas: UpdateQuotaModel[];
  useOptimizer?: boolean;
}

export interface UpdateQuotaModel {
  quotaId: number;
  enableAutoClose?: boolean;
  customerDiff?: number;
  wantedCompletes?: number;
  wantedStarts?: number;
}

export interface UpdateBucketModel {
  id: number;
  useOptimizer: boolean;
}

export interface BucketWantedQuantityModel {
  bucketId: number;
  wantedQuantity: number;
}

export interface UpdateBucketWantedQuantityRequest {
  targetGroupId: number;
  buckets: BucketWantedQuantityModel[];
}

export interface RelaxBucketsRequest {
  percentage: number;
  removeRestrictions: boolean;
  quotaIds: number[];
  bucketIds: number[];
}

export interface UpdateQuotasOpenStateModel {
  targetGroupId: number;
  quotaIds: number[];
  updateAllQuotas: boolean;
}
export interface UpdateQuotaOpenStateRequest {
  targetGroupsWithQuotas: UpdateQuotasOpenStateModel[];
}

// Union currently only supports up to 20 arguments
export const RespondentStatusUnion = Union(
  Union(
    Literal(RespondentStatusTextual.Unanswered),
    Literal(RespondentStatusTextual.Started),
    Literal(RespondentStatusTextual.Declined),
    Literal(RespondentStatusTextual.EarlyScreenout),
    Literal(RespondentStatusTextual.LateScreenout),
    Literal(RespondentStatusTextual.Complete),
    Literal(RespondentStatusTextual.QuotaFull),
    Literal(RespondentStatusTextual.Bounced),
    Literal(RespondentStatusTextual.SurveyClosed),
    Literal(RespondentStatusTextual.TimedOut),
    Literal(RespondentStatusTextual.Other),
    Literal(RespondentStatusTextual.Suspicious),
    Literal(RespondentStatusTextual.BadProjectToken),
    Literal(RespondentStatusTextual.PossibleDuplicate),
    Literal(RespondentStatusTextual.Routed)
  ),
  Union(
    Literal(RespondentStatusTextual.EndOfRouter),
    Literal(RespondentStatusTextual.Fraudulent),
    Literal(RespondentStatusTextual.QualityTerminate),
    Literal(RespondentStatusTextual.PreScreenStarted),
    Literal(RespondentStatusTextual.PreScreenTerminate),
    Literal(RespondentStatusTextual.PreScreenTimedOut),
    Literal(RespondentStatusTextual.TargetableScreenout),
    Literal(RespondentStatusTextual.ProfileTerminated),
    Literal(RespondentStatusTextual.RelevantIdDuplicate),
    Literal(RespondentStatusTextual.RelevantIdBadGeoip),
    Literal(RespondentStatusTextual.RelevantIdFraudulent),
    Literal(RespondentStatusTextual.RelevantIdFailure),
    Literal(RespondentStatusTextual.BadGeoip),
    Literal(RespondentStatusTextual.TechnicalTerminate),
    Literal(RespondentStatusTextual.Unsubscribed)
  )
);
export type RespondentStatusUnion = Static<typeof RespondentStatusUnion>;

export const RespondentStatusPair = Record({
  from: RespondentStatusUnion,
  to: RespondentStatusUnion,
});
export type RespondentStatusPair = Static<typeof RespondentStatusPair>;

export const RespondentPairCount = Record({
  count: NonNegative,
  statuses: RespondentStatusPair,
});
export type RespondentPairCount = Static<typeof RespondentPairCount>;

export const PrepareRespondentStatusesResponse = Record({
  id: GuidString.Or(Null),
  groups: Array(RespondentPairCount),
});
export type PrepareRespondentStatusesResponse = Static<typeof PrepareRespondentStatusesResponse>;

export const ConfirmRespondentStatusesResponse = Array(RespondentPairCount);
export type ConfirmRespondentStatusesResponse = Static<typeof ConfirmRespondentStatusesResponse>;

export interface PrepareRespondentStatusesRequest {
  respondents: {
    key: string;
    // NOTE: for payload/performance reasons, we forgo our standard and send these to the backend as numerical enums
    toStatus: RespondentStatusNumeral;
  }[];
}

export const SendoutItemResponse = Record({
  circularId: AboveZero.Or(Literal(-1)),
  dateToSend: Iso8601UtcString,
  isInPreQueue: Boolean,
  isSent: Boolean,
  sentCount: Number,
  status: String,
  total: Number,
});
export type SendoutItemResponse = Static<typeof SendoutItemResponse>;

export const SelectionResponse = Record({
  id: AboveZero,
  name: NonEmptyString,
  targetedCompletes: NonNegative,
});
export type SelectionResponse = Static<typeof SelectionResponse>;

export const ActiveSendoutResponse = Record({
  bucketId: AboveZero,
  initial: SendoutItemResponse,
  initialInvitesCount: NonNegative.Or(Null),
  reminder: SendoutItemResponse.Or(Null),
  reminderInvitesCount: NonNegative.Or(Null),
  selection: SelectionResponse,
});
export type ActiveSendoutResponse = Static<typeof ActiveSendoutResponse>;

export const ActiveSendoutsResponse = Record({
  sendouts: Array(ActiveSendoutResponse),
});
export type ActiveSendoutsResponse = Static<typeof ActiveSendoutsResponse>;

export const SendoutModel = Record({
  id: AboveZero,
  name: NonEmptyString,
  initialDateToSend: Iso8601UtcString.Or(Null),
  reminderDateToSend: Iso8601UtcString.Or(Null),
  currentCompleted: NonNegative,
  wantedCompletes: NonNegative,
  sentInvitesCount: NonNegative,
  totalInvited: NonNegative,
  isFromOptimizer: Boolean,
  aggregatedRespondentBucketStatus: Union(
    Literal(AggregatedRespondentBucketStatus.NotStarted),
    Literal(AggregatedRespondentBucketStatus.Active),
    Literal(AggregatedRespondentBucketStatus.Paused),
    Literal(AggregatedRespondentBucketStatus.Closed)
  ),
});
export type SendoutModel = Static<typeof SendoutModel>;

export const SendoutHistoryBucket = Record({
  bucketId: AboveZero,
  sendouts: Array(SendoutModel),
});
export type SendoutHistoryBucket = Static<typeof SendoutHistoryBucket>;

export const SendoutHistoryResponse = Record({
  buckets: Array(SendoutHistoryBucket),
});
export type SendoutHistoryResponse = Static<typeof SendoutHistoryResponse>;

export const QuotaSelectionDetailsPanel = Record({
  id: AboveZero,
  name: NonEmptyString,
  sampledRespondentsCount: NonNegative,
  totalPanelistsCount: NonNegative.Or(Null),
});
export type QuotaSelectionDetailsPanel = Static<typeof QuotaSelectionDetailsPanel>;

export const QuotaSelectionDetailsCircular = Record({
  id: AboveZero,
  sendoutStatus: Union(Literal('Not sent'), Literal('Scheduled'), Literal('Sent')),
  circularType: Union(Literal('Initial'), Literal('Reminder')),
  dateToSend: Iso8601UtcString,
  respondentsCount: NonNegative,
  firstLiveUrl: HttpUrl.Or(EmptyString),
});
export type QuotaSelectionDetailsCircular = Static<typeof QuotaSelectionDetailsCircular>;

export const QuotaSelectionDetailsResponse = Record({
  id: Number, // seems to always be 0
  name: String.Or(Null), // seems to always be null
  panels: Array(QuotaSelectionDetailsPanel),
  circulars: Array(QuotaSelectionDetailsCircular),
  currentCompleted: NonNegative,
  totalInvited: NonNegative,
  responded: NonNegative,
  started: NonNegative,
  remainingUrlsCount: NonNegative.Or(Null),
  wantedCompletes: NonNegative,
});
export type QuotaSelectionDetailsResponse = Static<typeof QuotaSelectionDetailsResponse>;

export const BucketDetails = Record({
  id: AboveZero,
  name: NonEmptyString,
  currentCompletes: NonNegative,
  wantedQuantity: NonNegative.Or(Null),
});

export type BucketDetails = Static<typeof BucketDetails>;

export const BucketDetailsResponse = Array(BucketDetails);

export type BucketDetailsResponse = Static<typeof BucketDetailsResponse>;

export class ManageHttpService {
  getQuotas(
    projectId: number,
    targetGroupIds: number[],
    config?: IRequestShortcutConfig
  ): IHttpPromise<TargetGroupQuotasResponse> {
    return $http.validatingPost(TargetGroupQuotasResponse, urlService.getQuotas(projectId), targetGroupIds, config);
  }

  getAggregatedStatuses(
    projectId: number,
    targetGroupIds: number[],
    config: IRequestShortcutConfig
  ): IHttpPromise<TargetGroupsAggregatedStatusResponse> {
    return $http.validatingPost(
      TargetGroupsAggregatedStatusResponse,
      urlService.getAggregatedStatus(projectId),
      targetGroupIds,
      config
    );
  }

  updateQuotas(projectId: number, request: UpdateQuotasRequest): IHttpPromise<void> {
    return $http.post(urlService.updateQuotas(projectId), request);
  }

  updateOptimizer(projectId: number, updates: { targetGroupId: number; useOptimizer: boolean }[]) {
    return $http.post(urlService.updateOptimizer(projectId), { updates });
  }

  relaxBuckets(projectId: number, targetGroupId: number, request: RelaxBucketsRequest): IHttpPromise<void> {
    return $http.post(urlService.relaxBuckets(projectId, targetGroupId), request);
  }

  closeQuotas(projectId: number, request: UpdateQuotaOpenStateRequest): IHttpPromise<void> {
    return $http.post(urlService.closeQuotas(projectId), request);
  }

  reopenQuotas(projectId: number, request: UpdateQuotaOpenStateRequest): IHttpPromise<void> {
    return $http.post(urlService.reopenQuotas(projectId), request);
  }

  calculateSendouts(
    projectId: number,
    targetGroupId: number,
    quotaId: number | undefined,
    wantedQuantity: number
  ): IHttpPromise<CalculateSendoutsResponse> {
    const url =
      quotaId !== undefined
        ? urlService.calculateSendoutsForQuota(projectId, targetGroupId, quotaId)
        : urlService.calculateSendoutsForTargetGroup(projectId, targetGroupId);
    return $http.validatingPost(CalculateSendoutsResponse, url, wantedQuantity);
  }

  sample(
    projectId: number,
    targetGroupId: number,
    quotaId: number | undefined,
    wantedQuantity: number,
    samplingQuantity: number,
    sendoutTime: string,
    daysToWaitUntilReminderSendout: number | null
  ): IHttpPromise<EnqueueSampleResponse> {
    const url =
      quotaId !== undefined
        ? urlService.sampleQuota(projectId, targetGroupId, quotaId)
        : urlService.sampleTargetGroup(projectId, targetGroupId);

    return $http.validatingPost(EnqueueSampleResponse, url, {
      wantedQuantity,
      samplingQuantity,
      initialSendoutTime: sendoutTime,
      daysToWaitUntilReminderSendout,
    });
  }

  getAsapDateString(): IHttpPromise<string> {
    return $http.get(urlService.getAsapDateString());
  }

  getActiveSendouts(projectId: number): IHttpPromise<ActiveSendoutsResponse> {
    return $http.validatingGet(ActiveSendoutsResponse, urlService.getActiveSendouts(projectId));
  }

  removeAllSendouts(projectId: number, selectionIds: number[]): IHttpPromise<void> {
    return $http.post(urlService.removeAllSendouts(projectId), selectionIds);
  }

  getQuotaSelectionDetails(projectId: number, selectionIds: number[]): IHttpPromise<QuotaSelectionDetailsResponse> {
    return $http.validatingPost(
      QuotaSelectionDetailsResponse,
      urlService.quotaSelectionDetails(projectId),
      selectionIds
    );
  }

  getBucketDetails(
    projectId: number,
    targetGroupId: number,
    quotaIds: number[],
    bucketIds: number[]
  ): IHttpPromise<BucketDetailsResponse> {
    const data = {
      quotaIds,
      bucketIds,
    };
    return $http.validatingPost(BucketDetailsResponse, urlService.bucketDetails(projectId, targetGroupId), data);
  }

  quotaHasProcessingSamplingItems(projectId: number, targetGroupId: number, quotaId: number): IHttpPromise<boolean> {
    return $http.validatingGet(Boolean, urlService.quotaHasProcessingSamplingItems(projectId, targetGroupId, quotaId));
  }

  targetGroupHasProcessingSamplingItems(projectId: number, targetGroupId: number): IHttpPromise<boolean> {
    return $http.validatingGet(Boolean, urlService.targetGroupHasProcessingSamplingItems(projectId, targetGroupId));
  }

  rescheduleSendouts(
    projectId: number,
    selectionIds: number[],
    circularType: CircularType,
    dateToSend: string
  ): IHttpPromise<void | { message: string }> {
    return $http.post(urlService.rescheduleSendouts(projectId), {
      selectionIds,
      circularType,
      dateToSend,
    });
  }

  removeReminderSendouts(projectId: number, selectionIds: number[]): IHttpPromise<void> {
    return $http.post(urlService.removeReminderSendouts(projectId), selectionIds);
  }

  getSendoutHistory(projectId: number): IHttpPromise<SendoutHistoryResponse> {
    return $http.validatingGet(SendoutHistoryResponse, urlService.getSendoutHistory(projectId));
  }

  prepareRespondentStatusesUpdate(
    projectId: number,
    respondentStatuses: PrepareRespondentStatusesRequest
  ): IHttpPromise<PrepareRespondentStatusesResponse> {
    return $http.validatingPost(
      PrepareRespondentStatusesResponse,
      urlService.prepareRespondentStatusesUpdate(projectId),
      respondentStatuses
    );
  }

  cancelRespondentStatusesUpdate(projectId: number, workingDocumentId: string): IHttpPromise<void> {
    return $http.post(urlService.cancelRespondentStatusesUpdate(projectId), asJsonScalarString(workingDocumentId));
  }

  confirmRespondentStatusesUpdate(
    projectId: number,
    workingDocumentId: string,
    groups: RespondentStatusPair[]
  ): IHttpPromise<ConfirmRespondentStatusesResponse> {
    const data = { workingDocumentId, statuses: groups };
    return $http.validatingPost(
      ConfirmRespondentStatusesResponse,
      urlService.confirmRespondentStatusesUpdate(projectId),
      data
    );
  }

  closeProject(projectId: number): IHttpPromise<void> {
    return $http.post(urlService.closeProject(projectId), {});
  }
}

export const manageHttpService = new ManageHttpService();
