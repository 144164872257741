import { ui } from 'angular';
import { ListCacheDialogComponent } from './list-cache-dialog.component';
import { api } from '../common/api';

export class ListCacheDialogSettingsFactory {
  static create(): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'list-cache-dialog',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        cacheKeys: () => {
          return api.feature.getCacheKeys().then((res) => res.data);
        },
      },
      component: ListCacheDialogComponent.componentName,
    };
  }
}
