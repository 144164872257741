import { ui } from 'angular';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { react2angular } from 'react2angular';
import { ConfirmationDialogResolve } from './confirmation-dialog-settings.factory';
import { ModalCloseButton } from './ModalCloseButton';

type Props = {
  resolve: ConfirmationDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
};

export const ConfirmationDialog: React.FC<Props> = ({
  resolve: { header, message, callbackFactory, cancelCaption, okayCaption, useDangerColor },
  modalInstance,
}) => {
  const [callbackInvoked, setCallbackInvoked] = useState(false);
  const callback = useMemo(() => callbackFactory?.(modalInstance), [callbackFactory, modalInstance]);

  const invokeCallback = () => {
    setCallbackInvoked(true);
    callback();
  };

  return (
    <div className="confirmation-dialog-component">
      <ModalCloseButton onClose={modalInstance.dismiss} />
      <div className="modal-container">
        <h1 className="modal-header">{header}</h1>
        <p className="push-down">{message}</p>
        <div className="modal-controls">
          <button className="ui-btn default-btn" onClick={modalInstance.dismiss}>
            {cancelCaption || 'Cancel'}
          </button>
          <button
            className={classNames('ui-btn', { 'primary-btn': !useDangerColor, 'red-btn': useDangerColor })}
            disabled={callbackInvoked}
            onClick={callbackFactory ? invokeCallback : (_) => modalInstance.close(true)}
          >
            {okayCaption || 'OK'}
          </button>
        </div>
      </div>
    </div>
  );
};

export const ConfirmationDialogComponentName = 'confirmationDialogComponent';
export const ngConfirmationDialogComponent = {
  [ConfirmationDialogComponentName]: react2angular(ConfirmationDialog, ['resolve', 'modalInstance']),
};
