import './toggler.component.scss';
import { IOnChangesObject } from 'angular';
import { html } from '../../../helpers';

const selector = 'togglerComponent';

const template = html`
  <div class="ui-toggler">
    <input
      class="ui-toggler__control"
      ng-if="!$ctrl.useTwoWayBinding()"
      type="checkbox"
      id="{{ $ctrl.switchId }}"
      ng-model="$ctrl.checked"
      ng-change="$ctrl.switchToggled()"
      ng-disabled="$ctrl.disabled"
    /><input
      class="ui-toggler__control"
      ng-if="$ctrl.useTwoWayBinding()"
      type="checkbox"
      id="{{ $ctrl.switchId }}"
      ng-model="$ctrl.model"
      ng-change="$ctrl.switchToggled()"
      ng-disabled="$ctrl.disabled"
    /><label class="ui-toggler__label" for="{{ $ctrl.switchId }}"
      ><span
        ng-class="{'ui-toggler__label_onoff': $ctrl.isOnOffSwitch, 'ui-toggler__label_yesno': !$ctrl.isOnOffSwitch}"
      ></span
      ><span class="ui-toggler__switch"></span
    ></label>
  </div>
`;

interface Bindings {
  checked: '<' | boolean;
  model: '=' | boolean;
  switchId: '@' | string;
  switchType: '@' | 'onoff' | 'yesno';
  disabled: '<' | boolean;
  onToggle: '&' | (() => void);
}

const bindings: Bindings = {
  checked: '<',
  model: '=',
  switchId: '@',
  switchType: '@',
  disabled: '<',
  onToggle: '&',
};

export class TogglerComponent implements Bindings {
  static componentName = selector;

  checked: boolean;
  model: boolean;
  switchId: string;
  switchType: '@' | 'yesno' | 'onoff';
  disabled: boolean;
  isOnOffSwitch: boolean;
  onToggle: () => void;

  $onInit() {
    this.isOnOffSwitch = this.switchType === 'onoff';
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.checked) {
      this.checked = changes.checked.currentValue;
    }

    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }

  switchToggled(): void {
    this.onToggle();
  }

  useTwoWayBinding(): boolean {
    return this.model !== undefined;
  }
}

export const ngTogglerComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TogglerComponent,
  },
};
