import { IAttributes, IAugmentedJQuery, IDirective, IScope } from 'angular';
import { debounce } from 'lodash-es';

const directiveName = 'removeOverClassOnDragLeave';

export function removeOverClassOnDragLeave(): IDirective {
  return {
    restrict: 'A',
    link: (_scope: IScope, elem: IAugmentedJQuery, attrs: IAttributes) => {
      elem.on(
        'dragleave',
        debounce(() => {
          const overClass = 'overClass';
          const classes = attrs[overClass].split(' ');
          for (const c of classes) {
            elem.removeClass(c);
          }
        }, 500)
      );
    },
  };
}

export const ngRemoveOverClassOnDragLeaveDirective = {
  [directiveName]: removeOverClassOnDragLeave,
};
