import './templates-list-component.scss';
import { map, includes } from 'lodash-es';
import { ListenerComponent } from '../../common/channels/channel';
import { api } from '../../common/api';
import { countryService } from '../../target-groups/country.service';
import { userService } from '../../common/user.service';
import { TemplatesListType, TemplateChannel } from '../../common/enums';
import { targetGroupRepository } from '../../common/target-group/target-group.repository';
import { persistentSurveyLinksService } from '../../common/target-group/survey-links/persistent-survey-links.service';
import { projectSettingsService } from '../../common/project-settings.service';
import { templateChannel } from '../../common/channels/template-channel';
import { $window, $q } from '../../ngimport';
import { html, Iso8601Date } from '../../helpers';
import { TemplateHistoryItem } from '../../common/http-services/template.httpservice';

const selector = 'templatesListComponent';

const template = html`
  <div class="templates-list-component">
    <div class="filters-container">
      <template-filters-component
        filter-templates="$ctrl.filterTemplates(countryId, onlyMyTemplates, templateName)"
      ></template-filters-component>
    </div>
    <div ng-class="{'scrollable-list': $ctrl.isUseTemplateType}">
      <div class="list-header" stick-to-top scroll-down-position="0" scroll-up-position="74">
        <p class="name-column" ng-class="{ 'use-template': $ctrl.isUseTemplateType }">Name</p>
        <p class="country-column">Country</p>
        <p ng-if="$ctrl.isManageTemplateType" class="created-column">Created</p>
        <p ng-if="$ctrl.isManageTemplateType" class="edited-column">Edited</p>
        <p ng-if="$ctrl.isManageTemplateType" class="creator-column">Creator</p>
      </div>
      <p class="empty-text" ng-if="!$ctrl.templates.length && !$ctrl.isLoading">Currently, you have no templates.</p>

      <div
        class="template-item"
        ng-repeat="template in $ctrl.templates
                    | filter: $ctrl.filterOwnTemplates
                    | filter: { countryId: $ctrl.filters.countryId }:true
                    | filter: { name: $ctrl.filters.name }
                    | limitTo:$ctrl.visibleTemplates
                    track by template.id"
      >
        <template-list-item-component
          item="template"
          templates-list-type="$ctrl.templatesListType"
          on-use-template="$ctrl.onUseTemplate({id})"
        ></template-list-item-component>
      </div>
    </div>
    <div class="load-trigger" on-scroll-into-view="$ctrl.showNextTemplatesPage()" margin="{{$ctrl.scrollMargin}}">
      &nbsp;
    </div>
    <div class="spinner" ng-if="$ctrl.isLoading">
      <div class="spinner-container text-center">
        <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </div>
`;

export interface TemplatesListItem {
  id: number;
  name: string;
  countryId: number;
  countryName: string;
  owner: string;
  created: Iso8601Date;
  updated: Iso8601Date;
  updateHistory: TemplateHistoryItem[];
  channel: TemplateChannel;
}

export interface UpdateHistoryItem {
  user: { userId: number; userName: string };
  updated: Iso8601Date;
}

interface Bindings {
  templatesListType: '<' | TemplatesListType;
  onUseTemplate: '&' | ((_: { id: number }) => void);
}

const bindings: Bindings = {
  templatesListType: '<',
  onUseTemplate: '&',
};

export class TemplatesListComponent extends ListenerComponent implements Bindings {
  isLoading: boolean;
  templates: TemplatesListItem[] = [];
  visibleTemplates: number;
  userOwnsAllTemplates: boolean;
  templatesListType: TemplatesListType;
  pageSize: number;
  currentPage = 1;
  scrollMargin: number;
  onUseTemplate: (_: { id: number }) => void;

  filters: {
    countryId?: number;
    name?: string;
    onlyMyTemplates: boolean;
  };

  countries: {
    id: number;
    name: string;
  }[];

  get isUseTemplateType() {
    return this.templatesListType === TemplatesListType.UseTemplate;
  }
  get isManageTemplateType() {
    return this.templatesListType === TemplatesListType.ManageTemplates;
  }
  get currentUserName() {
    return userService.impersonatedAs || userService.name;
  }
  get filterOwnTemplates() {
    return this.filters.onlyMyTemplates ? (item: TemplatesListItem) => item.owner === this.currentUserName : () => true;
  }

  async $onInit(): Promise<void> {
    templateChannel.deleted.listen(this.whenTemplateDeleted, this);
    this.filters = { countryId: undefined, name: undefined, onlyMyTemplates: true };
    await this.getTemplatesCountries();
    await this.getTemplates();
    this.pageSize = this.getPageSize();
    this.visibleTemplates = this.pageSize;
    this.scrollMargin = this.getScrollMargin();
  }

  async getTemplates(): Promise<void> {
    this.isLoading = true;
    const res = await api.template.getAllTemplates();
    this.templates = res.data.map((el) => ({
      id: el.id,
      name: el.name,
      countryId: el.countryId,
      countryName: this.getCountryName(el.countryId),
      owner: el.owner,
      created: el.created,
      updated: el.updated,
      updateHistory: el.updateHistory,
      channel: el.channel,
    }));
    this.checkTemplatesOwnership();
    this.isLoading = false;
    return $q.resolve();
  }

  filterTemplates(filterCountryId: number, onlyMyTemplates: boolean, templateName?: string) {
    this.filters.name = !templateName ? undefined : templateName;
    this.filters.countryId = !filterCountryId ? undefined : filterCountryId;
    this.filters.onlyMyTemplates = onlyMyTemplates;
  }

  checkTemplatesOwnership() {
    this.userOwnsAllTemplates = this.templates.every((d) => d.owner === userService.name);
  }

  showNextTemplatesPage() {
    if (!this.templates || !this.templates.length) {
      this.currentPage = 1;
      this.visibleTemplates = 0;
    }
    this.currentPage = Math.min(this.currentPage + 1, Math.ceil(this.templates.length / this.pageSize));
    this.visibleTemplates = this.pageSize * this.currentPage;
  }

  private getCountryName(countryId: number): string {
    return this.countries.find((c) => c.id === countryId).name;
  }

  private getPageSize() {
    if (this.templatesListType === TemplatesListType.UseTemplate) return this.templates.length;
    return Math.ceil(($window.innerHeight * 1.25) / 70);
  }

  private getScrollMargin() {
    return Math.ceil($window.innerHeight / 4);
  }

  private async whenTemplateDeleted(wasInStorage: boolean): Promise<void> {
    if (wasInStorage) {
      await this.onDeletedLoadedTemplate();
    }
    this.getTemplates();
  }

  private async onDeletedLoadedTemplate(): Promise<void> {
    await targetGroupRepository.removeAll().then(() => {
      persistentSurveyLinksService.clear();
      projectSettingsService.clear();
    });
  }

  private async getTemplatesCountries(): Promise<void> {
    await api.template.getTemplateCountries().then((res) => {
      return (this.countries = map(countryService.countries, (c) => ({
        id: c.id,
        name: c.name,
      })).filter((c) => includes(res.data, c.id)));
    });
  }
}

export const ngTemplatesListComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TemplatesListComponent,
  },
};
