import { ui } from 'angular';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';
import { TargetGroupsStateIntent } from '../../target-groups-state-intent';
import { currentWorkService } from '../current-work.service';

export interface UnsavedChangesDialogResolve {
  canMerge: boolean;
  mergeExceedsMaxTgLimit?: boolean;
  currentWorkTitle?: string;
  currentWorkIntent?: TargetGroupsStateIntent;
}

export class UnsavedChangesDialogSettingsFactory {
  static create(resolve?: UnsavedChangesDialogResolve): ui.bootstrap.IModalSettings {
    return {
      windowClass: 'unsaved-changes-dialog',
      component: UnsavedChangesDialogComponent.componentName,
      resolve: {
        canMerge: resolve ? resolve.canMerge : false,
        mergeExceedsMaxTgLimit: resolve ? resolve.mergeExceedsMaxTgLimit : false,
        currentWorkTitle: currentWorkService.getCurrentWorkTitle(),
        currentWorkIntent: currentWorkService.getCurrentWorkIntent(),
      },
    };
  }
}
