import { find, remove, without } from 'lodash-es';
import { featureFlipper } from '../common/feature-flipper';
import { FeatureKey } from '../app-settings';
import { Listener } from '../common/channels/channel';
import { targetGroupChannel } from './channels/target-group-channel';

export type PaneName =
  | ''
  | 'basic-settings'
  | 'regions'
  | 'profiling'
  | 'quotas'
  | 'audience'
  | 'exclusions'
  | 'incentives'
  | 'supplier-metadata';

export interface Pane {
  name: PaneName;
  loaded: boolean;
  isDisabled: boolean;
  enabledByFeature?: FeatureKey;
  disabledByFeature?: FeatureKey;
}

export class TargetGroupAccordionService extends Listener {
  panes: Pane[] = [
    { name: 'basic-settings', loaded: false, isDisabled: false },
    { name: 'regions', loaded: false, isDisabled: true },
    { name: 'audience', loaded: false, isDisabled: true },
    { name: 'profiling', loaded: false, isDisabled: true },
    { name: 'quotas', loaded: false, isDisabled: true },
    { name: 'exclusions', loaded: false, isDisabled: true },
    { name: 'supplier-metadata', loaded: false, isDisabled: true, enabledByFeature: 'supplierMetadata' },
  ];

  init(): void {
    remove(
      this.panes,
      (p) =>
        (p.enabledByFeature !== undefined && !featureFlipper.isEnabled(p.enabledByFeature)) ||
        (p.disabledByFeature !== undefined && featureFlipper.isEnabled(p.disabledByFeature))
    );
    targetGroupChannel.model.restoring.listen(this.disableAllPanes, this);
  }

  resetPanes(): void {
    this.disableAllPanesExcept('basic-settings');
  }

  enableAllPanesExcept(...panesToDisable: PaneName[]): void {
    const panesToEnable = without(
      targetGroupAccordionService.panes.map((p) => p.name),
      ...panesToDisable
    );
    this.setPaneDisabledState(false, ...panesToEnable);
    this.setPaneDisabledState(true, ...panesToDisable);
  }

  disableAllPanesExcept(...panesToEnable: PaneName[]): void {
    const panesToDisable = without(
      targetGroupAccordionService.panes.map((p) => p.name),
      ...panesToEnable
    );
    this.setPaneDisabledState(false, ...panesToEnable);
    this.setPaneDisabledState(true, ...panesToDisable);
  }

  disableAllPanes() {
    this.setPaneDisabledState(true, ...targetGroupAccordionService.panes.map((p) => p.name));
  }

  private setPaneDisabledState(isDisabled: boolean, ...panes: PaneName[]): void {
    for (const pane of panes) {
      const match = find(this.panes, (p) => p.name === pane);
      if (match) {
        match.isDisabled = isDisabled;
      }
    }
  }
}

export const targetGroupAccordionService = new TargetGroupAccordionService();
