import './target-group-quotas-table.component.scss';
import { IOnChangesObject } from 'angular';
import { includes } from 'lodash-es';
import { ExistingTargetGroupQuotaSetup, ExistingTargetGroupQuota } from '../http-services/existing-project.models';
import { Product, QuotaGrouping } from '../enums';
import { html } from '../../helpers';
import { TargetGroupRespondentStatistics } from '../http-services/target-group.httpservice';
import { FeasibilityQuota } from '../http-services/feasibility.response';

const selector = 'targetGroupQuotasTableComponent';

const template = html`
  <div class="target-group-quotas-table-component">
    <div ng-show="!$ctrl.quotas.hasUserDefinedQuotas" class="irrelevant push-down">No quotas have been setup</div>
    <table class="details-table" hide-for-product="${Product.AccessPro}" ng-show="$ctrl.quotas.hasUserDefinedQuotas">
      <thead>
        <tr>
          <th>Quota(s) on completes</th>
          <th colspan="3">Completes</th>
        </tr>
        <tr>
          <td></td>
          <td>collected</td>
          <td>wanted</td>
          <td>feasible</td>
        </tr>
      </thead>
      <tbody>
        <tr ng-repeat="quota in $ctrl.nonSupplyMixQuotas">
          <td>{{quota.name}}</td>
          <td>{{quota.actualCompletes}}</td>
          <td>{{quota.wantedCompletes}}</td>
          <td ng-class="{'low-initial-feasibility': quota.initialFeasible < quota.wantedCompletes}">
            {{quota.initialFeasible}}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="quotas-stats" show-for-product="${Product.AccessPro}" ng-show="$ctrl.quotas.hasUserDefinedQuotas">
      <div class="stats-header" feature-off="quotasOnStarts">
        <div>Quotas</div>
        <div>Wanted</div>
        <div>Feasible</div>
        <div>Invited</div>
        <div>Remaining completes</div>
        <div>Current feasibility</div>
      </div>
      <div class="stats-header" feature-on="quotasOnStarts">
        <div>Quotas</div>
        <div ng-if="$ctrl.tgHasQuotasOnStart">Starts</div>
        <div>Completes</div>
        <div>Invited</div>
        <div>Initial feasibility</div>
        <div>Current feasibility</div>
      </div>
      <div class="quota-info" ng-repeat="quota in $ctrl.nonSupplyMixQuotas">
        <quota-stats-component
          project-id="$ctrl.projectId"
          target-group-id="$ctrl.targetGroupId"
          target-group-has-quotas-on-start="$ctrl.tgHasQuotasOnStart"
          quota="quota"
          stats="$ctrl.getStatsForQuota(quota.name)"
          infield-feasibility="$ctrl.getCurrentFeasibility(quota.name)"
        ></quota-stats-component>
      </div>
    </div>
  </div>
`;

interface Bindings {
  projectId: '<' | number;
  targetGroupId: '<' | number;
  quotas: '<' | ExistingTargetGroupQuotaSetup;
  stats: '<' | TargetGroupRespondentStatistics;
  infieldFeasibility: '<' | FeasibilityQuota[];
}

const bindings: Bindings = {
  projectId: '<',
  targetGroupId: '<',
  quotas: '<',
  stats: '<',
  infieldFeasibility: '<',
};

export class TargetGroupQuotasTableComponent implements Bindings {
  static componentName = selector;

  projectId: number;
  targetGroupId: number;
  quotas: ExistingTargetGroupQuotaSetup;
  stats: TargetGroupRespondentStatistics;
  infieldFeasibility: FeasibilityQuota[];
  nonSupplyMixQuotas: ExistingTargetGroupQuota[];

  get tgHasQuotasOnStart(): boolean {
    return this.nonSupplyMixQuotas?.some((quota) => quota.wantedStarts > 0) ?? false;
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.quotas) {
      this.quotas = changes.quotas.currentValue;
    }

    if (changes.infieldFeasibility) {
      this.infieldFeasibility = changes.infieldFeasibility.currentValue;
    }

    this.reload();
  }

  getStatsForQuota(name: string) {
    return this.stats?.quotaStats[name];
  }

  getCurrentFeasibility(name: string) {
    if (!this.infieldFeasibility) {
      return null;
    }
    return this.infieldFeasibility.find((q) => q.name === name).feasible;
  }

  private reload(): void {
    if (this.quotas !== undefined && this.quotas.hasUserDefinedQuotas) {
      this.nonSupplyMixQuotas = this.quotas.quotas.filter(
        (quota) => !includes(quota.quotaGrouping, QuotaGrouping.Supply)
      );
    }
  }
}

export const ngTargetGroupQuotasTableComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TargetGroupQuotasTableComponent,
  },
};
