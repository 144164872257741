import './effective-cpi-details.component.scss';
import { react2angular } from 'react2angular';
import { useState } from 'react';
import { flatMap } from 'lodash';
import { EffectiveCpiDetailResponse } from '../../common/http-services/project-price.response';
import { PriceModel } from '../../common/pricing/PriceDetails';
import { filters } from '../../common/filters';

interface Props {
  currentPrice: PriceModel;
  showRateCard: () => void;
}

export const EffectiveCpiDetails: React.FC<Props> = ({ currentPrice, showRateCard }) => {
  const isSupplyMixCcpi = useState(currentPrice.quotas?.length > 0);

  const hasSomeEffectiveCpiDetails = () => {
    if (!isSupplyMixCcpi) return currentPrice.effectiveCpiDetails?.length > 0;
    const allDetails = flatMap(currentPrice.quotas.map((q) => q.effectiveCpiDetails)).filter((el) => el !== null);
    if (allDetails.length > 0) return true;
    if (currentPrice.quotas.filter((q) => q.completes === 0).length === 0) return true;
    return false;
  };

  function cpiDetailsItem(e: EffectiveCpiDetailResponse, showCcpiLabel: boolean) {
    return (
      <li key={e.cpi.value}>
        <div className="row">
          <div className="col-xs-4">
            {showCcpiLabel && (
              <span className="ccpi-label" title="Custom CPI">
                CCPI
              </span>
            )}
            {filters.twoDecimalsFormat()(e.cpi.value)}
          </div>
          <div className="col-xs-4">{e.deliveredCompletes}</div>
          <div className="col-xs-4 cost">{filters.twoDecimalsFormat()(e.cost.value)}</div>
        </div>
      </li>
    );
  }

  return (
    <div className="effective-cpi-details-component">
      {!isSupplyMixCcpi && (
        <div className="row">
          <div className="col-xs-6">
            <span className="ccpi-label">Custom CPI</span>
          </div>
          <div className="col-xs-6 rate-card-link-cell">
            <a onClick={() => showRateCard()}>Show rate card</a>
          </div>
        </div>
      )}
      {currentPrice.effectiveCpi && (
        <div className="row current">
          <div className="col-xs-12">
            Current Custom CPI:
            <span className="value">{filters.formatPrice()(currentPrice.effectiveCpi)}</span>
          </div>
        </div>
      )}
      {hasSomeEffectiveCpiDetails() && (
        <ul className="values-table">
          <li>
            <div className="row">
              <div className="col-xs-4">
                <label>CPI ({currentPrice.cpi.currency}) </label>
              </div>
              <div className="col-xs-4">
                <label>Completes</label>
              </div>
              <div className="col-xs-4">
                <label> Subtotal ({currentPrice.cpi.currency}) </label>
              </div>
            </div>
          </li>
          {currentPrice.effectiveCpiDetails?.map((d) => cpiDetailsItem(d, false))}
          {isSupplyMixCcpi &&
            currentPrice.quotas.map((q) => (
              <>
                {q.isCustomCpi && q.effectiveCpiDetails?.map((d) => cpiDetailsItem(d, q.isCustomCpi))}
                {(!q.isCustomCpi || q.effectiveCpiDetails.length === 0) &&
                  q.completes > 0 &&
                  cpiDetailsItem({ deliveredCompletes: q.completes, cpi: q.cpi, cost: q.price }, q.isCustomCpi)}
              </>
            ))}
        </ul>
      )}
      <ul className="values-table">
        <li>
          <div className="row">
            <div className="col-xs-4">
              <label>
                {hasSomeEffectiveCpiDetails() && 'Avg.'} CPI ({currentPrice.cpi.currency}){' '}
              </label>
            </div>
            <div className="col-xs-4">
              <label>Total completes</label>
            </div>
            <div className="col-xs-4">
              <label> Total ({currentPrice.cpi.currency}) </label>
            </div>
          </div>
        </li>
        <li className="average" title="Average CPI">
          <div className="row">
            <div className="col-xs-4">{filters.formatPrice()(currentPrice.cpi, false)}</div>
            <div className="col-xs-4">{currentPrice.completes}</div>
            <div className="col-xs-4 cost">{filters.formatPrice()(currentPrice.price, false)}</div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export const ngEffectiveCpiDetailsComponent = {
  effectiveCpiDetailsComponent: react2angular(EffectiveCpiDetails, ['currentPrice', 'showRateCard']),
};
