import { ui, IPromise } from 'angular';
import { api } from '../common/api';
import { draftChannel } from '../common/channels/draft-channel';
import { html, silenceRejection } from '../helpers';
import { StorageDraft } from '../common/target-group/target-group.repository';

const selector = 'deleteDraftDialogComponent';

const template = html`
  <div class="delete-draft-dialog-component">
    <modal-close-button-component close-action="$ctrl.dismiss()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Delete draft?</h1>
      <p ng-show="$ctrl.isInStorage">Deleting the draft will clear the target groups you are currently working with.</p>
      <p ng-hide="$ctrl.isInStorage" class="push-down">
        Deleting <em>{{$ctrl.draftName}}</em> will remove it from the list.
      </p>
      <div class="modal-controls">
        <button class="ui-btn default-btn" ng-click="$ctrl.dismiss()">Cancel</button>
        <button class="ui-btn red-btn" ng-click="$ctrl.ok()">Delete</button>
      </div>
    </div>
  </div>
`;

interface Draft {
  id: number;
  name: string;
}

export interface DeleteDraftDialogResolve {
  draft: {
    id: number;
    name: string;
  };
  savedDraft: StorageDraft;
}

interface Bindings {
  resolve: '<' | DeleteDraftDialogResolve;
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  resolve: '<',
  modalInstance: '<',
};

export class DeleteDraftDialogComponent implements Bindings, DeleteDraftDialogResolve {
  static componentName = selector;

  resolve: DeleteDraftDialogResolve;
  modalInstance: ui.bootstrap.IModalInstanceService;
  draft: Draft;
  savedDraft: StorageDraft;
  isInStorage: boolean;
  draftName: string;

  $onInit(): void {
    this.draft = this.resolve.draft;
    this.savedDraft = this.resolve.savedDraft;
    this.isInStorage = this.draft && this.savedDraft && this.draft.id === this.savedDraft.id;
    this.draftName = this.draft.name;
  }

  dismiss(): void {
    this.modalInstance.dismiss();
  }

  ok(): IPromise<void> {
    return api.draft.deleteDraft(this.draft.id).then(() => {
      draftChannel.deleted.dispatch(this.isInStorage);
      this.modalInstance.close();
    }, silenceRejection);
  }
}

export const ngDeleteDraftDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: DeleteDraftDialogComponent,
  },
};
