import { SupplySource } from '../../../common/enums';
import { SupplyGroupCustomCpiSettings } from '../../active-target-group/models/active-supply.model';

export type ActiveSupplyMixSource = SupplySource.CintPanels | SupplySource.OwnPanels | SupplySource.PrivatePricing;

export interface SupplyGroupUpdate {
  readonly id: number;
  readonly source: ActiveSupplyMixSource;
  name: string;
  panelIds: number[];
  useCustomCpi?: boolean;
  customCpi?: number;
  ccpiSettings?: SupplyGroupCustomCpiSettings;
}

export class SupplyMixStateService {
  private supplySource: ActiveSupplyMixSource | undefined;

  get activeSupplySource(): ActiveSupplyMixSource | undefined {
    return this.supplySource;
  }

  setActiveSupplySource(source: ActiveSupplyMixSource): void {
    this.supplySource = source;
  }

  clearActiveSupplySource(): void {
    this.supplySource = undefined;
  }
}

export const supplyMixStateService = new SupplyMixStateService();
