export class CommonConstants {
  static get maximumFixedIncentiveAmount(): number {
    return 1_000_000_000;
  }

  static get uiBootstrapDialogEscapeKeyPress(): string {
    return 'escape key press';
  }

  static get maximumTargetGroupsCount(): number {
    return 50;
  }
}
