import './TrendIcon.scss';
import { TrendDirection } from '../pricing/price-trend.service';

interface Props {
  isPositive: boolean;
  direction: TrendDirection;
}

export const TrendIcon: React.FC<Props> = ({ isPositive, direction }) => {
  const trendClass = isPositive ? 'trend trend-positive' : 'trend trend-negative';
  return (
    <span className={trendClass}>
      {direction === TrendDirection.Up && <span className="no-side-padding fas fa-arrow-circle-up fa-rotate-45-cw" />}
      {direction === TrendDirection.Down && (
        <span className="no-side-padding fas fa-arrow-circle-down fa-rotate-45-ccw" />
      )}
    </span>
  );
};
