import { isEmpty } from 'lodash-es';
import { ActivePanelistPool } from './active-target-group/models/active-supply.model';
import { PanelistPoolType } from '../common/enums';
import { isEmptyGuid } from '../helpers';
import { AvailabilityResultResponse } from '../common/http-services/supply.httpservice';

export class PanelistPoolHelper {
  hasInclusivePanelistPool(panelistPool: ActivePanelistPool): boolean {
    return (
      panelistPool &&
      panelistPool.type === PanelistPoolType.Inclusive &&
      (!isEmpty(panelistPool.input) || !isEmptyGuid(panelistPool.selectedPoolId)) &&
      !isEmptyGuid(panelistPool.rootPoolId)
    );
  }

  hasExclusivePanelistPool(panelistPool: ActivePanelistPool): boolean {
    return (
      panelistPool &&
      !isEmpty(panelistPool.availabilityResult) &&
      panelistPool.type === PanelistPoolType.Exclusive &&
      isEmpty(panelistPool.sourceUrl) &&
      !isEmptyGuid(panelistPool.rootPoolId) &&
      !isEmpty(panelistPool.input)
    );
  }

  hasExclusiveFileUploaded(panelistPool: ActivePanelistPool): boolean {
    return (
      panelistPool &&
      !isEmpty(panelistPool.createPoolResult) &&
      panelistPool.type === PanelistPoolType.Exclusive &&
      !isEmptyGuid(panelistPool.rootPoolId) &&
      !isEmpty(panelistPool.input)
    );
  }

  hasExclusiveExternalPanelistPool(panelistPool: ActivePanelistPool): boolean {
    return (
      panelistPool &&
      !isEmpty(panelistPool.availabilityResult) &&
      panelistPool.type === PanelistPoolType.Exclusive &&
      !isEmpty(panelistPool.sourceUrl) &&
      isEmpty(panelistPool.input) &&
      !isEmptyGuid(panelistPool.rootPoolId)
    );
  }

  hasImportErrorInclusive(panelistPool: ActivePanelistPool): boolean {
    return (
      isEmptyGuid(panelistPool.rootPoolId) &&
      panelistPool.type === PanelistPoolType.Inclusive &&
      isEmpty(panelistPool.sourceUrl) &&
      !isEmpty(panelistPool.input)
    );
  }

  hasImportErrorExclusive(panelistPool: ActivePanelistPool): boolean {
    return (
      isEmptyGuid(panelistPool.rootPoolId) &&
      panelistPool.type === PanelistPoolType.Exclusive &&
      isEmpty(panelistPool.sourceUrl) &&
      !isEmpty(panelistPool.input) &&
      isEmpty(panelistPool.selectedGroup)
    );
  }

  getAvailablePanelistCount(availabilityResult: AvailabilityResultResponse): number {
    return (
      availabilityResult?.availablePanelists?.opinionHub.panelistCount ??
      0 + availabilityResult?.availablePanelists?.ownPanels.panelistCount ??
      0 + availabilityResult?.availablePanelists?.privatePricing.panelistCount ??
      0
    );
  }
}

export const panelistPoolHelper = new PanelistPoolHelper();
