import { IDirective } from 'angular';
import { RuntimeSettings } from '../../runtime-settings';

const directiveName = 'notInSandbox';

export function notInSandbox(): IDirective {
  return {
    link: (_scope, element) => {
      if (RuntimeSettings.runtime.environment === 'Sandbox') {
        element.remove();
      }
    },
  };
}

export const ngNotInSandboxDirective = {
  [directiveName]: notInSandbox,
};
