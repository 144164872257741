import './templates-list-item.component.scss';
import { MenuListItem, MenuOptionType } from '../../common/controls/options-menu/options-menu.component';
import { ListenerComponent } from '../../common/channels/channel';
import { projectListChannel } from '../../common/channels/project-list-channel';
import { analytics } from '../../common/analytics';
import { userService } from '../../common/user.service';
import { TemplatesListType, Product, TemplateChannel } from '../../common/enums';
import { modalService } from '../../common/modal.service';
import { TemplateHistoryDialogSettingsFactory } from './template-history-dialog.component';
import { html, silenceRejection } from '../../helpers';
import { DeleteTemplateDialogSettingsFactory } from './delete-template-dialog-settings.factory';
import { targetGroupRepository } from '../../common/target-group/target-group.repository';
import { filters } from '../../common/filters';
import { TemplatesListItem } from './templates-list.component';
import { session } from '../../common/session';

import accessIcon from '../../../images/access-icon.png';

const selector = 'templateListItemComponent';

const template = html`
  <div class="template-list-item-component font-paragraph" ng-class="{'loading-state': $ctrl.loadingFile}">
    <div class="template-list-row">
      <div class="name-column" title="{{$ctrl.item.name}}" ng-class="{ 'use-template': $ctrl.isUseTemplateType }">
        <img
          ng-if="$ctrl.isAccessChannelItemAndProMode"
          ng-src="${accessIcon}"
          class="template-list-item__option-icon"
          title="Created in Access"
        />
        <span>{{$ctrl.item.name}}</span>
      </div>
      <div class="country-column" title="{{$ctrl.item.countryName}}">{{$ctrl.item.countryName}}</div>
      <div ng-if="$ctrl.isManageTemplatesType" class="created-column">{{$ctrl.item.created | dateFormat }}</div>
      <div ng-if="$ctrl.isManageTemplatesType" class="edited-column hidden-tablet">
        {{$ctrl.item.updated | dateFormat }}
      </div>
      <div ng-if="$ctrl.isManageTemplatesType" title="{{$ctrl.item.owner}}" class="creator-column hidden-tablet">
        {{ $ctrl.item.owner }}
      </div>
      <div
        ng-if="$ctrl.isManageTemplatesType"
        class="project-list__column_control hidden visible-tablet"
        ng-click="$ctrl.toggleTemplateInfo()"
      >
        <div class="ui-btn icon-btn project-list-item__arrow rounded-arrow-hover-tracker">
          <span class="rounded-arrow" ng-class="{'rounded-arrow_up': $ctrl.isExpanded}"></span>
        </div>
      </div>
      <div class="control" ng-class="{ 'use-template': $ctrl.isUseTemplateType }">
        <div
          ng-if="!$ctrl.isManageTemplatesType"
          class="clear-btn ui-btn full-btn default-btn"
          ng-click="$ctrl.onUseTemplate({id: $ctrl.item.id})"
        >
          Use
        </div>
        <options-menu-component
          ng-if="$ctrl.isManageTemplatesType"
          options="$ctrl.menuOptions"
          on-option-click="$ctrl.onMenuOptionSelect(option)"
        >
        </options-menu-component>
      </div>
    </div>
    <div
      ng-hide="$ctrl.isUseTemplateType"
      class="project-list-item__info-pane"
      ng-class="{'project-list-item__info-pane_expanded': $ctrl.isExpanded}"
    >
      <p class="project-list-item__info">Created: {{$ctrl.item.created | dateFormat}}</p>
      <p class="project-list-item__info">Edited: {{$ctrl.item.updated | dateFormat}}</p>
      <p class="project-list-item__info">Creator: {{$ctrl.item.owner}}</p>
    </div>
  </div>
`;

interface Bindings {
  item: '<' | TemplatesListItem;
  templatesListType: '<' | TemplatesListType;
  onUseTemplate: '&' | ((_: { id: number }) => void);
}

const bindings: Bindings = {
  item: '<',
  templatesListType: '<',
  onUseTemplate: '&',
};

export class TemplateListItemComponent extends ListenerComponent implements Bindings {
  static componentName = selector;

  item: TemplatesListItem;
  isExpanded = false;
  onOptionClicked: (_: { option: MenuListItem; item: TemplatesListItem }) => void;
  isDraft: boolean;
  url: string;
  loadingFile: boolean;
  templatesListType: TemplatesListType;
  menuOptions: MenuListItem[];
  onUseTemplate: (_: { id: number }) => void;

  get canOpenTemplate() {
    return !(userService.hasOutstandingBills && this.isDraft);
  }

  get isUseTemplateType() {
    return this.templatesListType === TemplatesListType.UseTemplate;
  }

  get isManageTemplatesType() {
    return this.templatesListType === TemplatesListType.ManageTemplates;
  }

  get isAccessChannelItemAndProMode() {
    return this.item.channel === TemplateChannel.Access && session.product === Product.AccessPro;
  }

  $onInit(): void {
    projectListChannel.paneExpanded.listen(this.checkIfShouldCollapse, this);
    projectListChannel.loadedFile.listen((id) => {
      if (id !== this.item.id) return;
      this.loadingFile = false;
    }, this);

    if (this.templatesListType === TemplatesListType.ManageTemplates) {
      this.menuOptions = this.createMenuOptions(this.item);
    }
  }

  toggleTemplateInfo(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      projectListChannel.paneExpanded.dispatch(this.item.id);
    }
  }

  onMenuOptionSelect(option: MenuListItem): void {
    this.onMenuOptionClicked(option, this.item);
  }

  private onMenuOptionClicked(option: MenuListItem, item: TemplatesListItem) {
    switch (option.id) {
      case 0:
        analytics.template.createProjectBasedOnTemplate();
        break;
      case 1:
        analytics.template.edit();
        break;
      case 2:
        analytics.template.showHistory();
        this.showTemplateHistory();
        break;
      case 3:
        this.deleteTemplate(item);
        break;
      default:
        break;
    }
  }

  private showTemplateHistory(): void {
    modalService
      .openMedium(
        TemplateHistoryDialogSettingsFactory.create({
          owner: this.item.owner,
          created: this.item.created,
          history: this.item.updateHistory,
        })
      )
      .result.catch(silenceRejection);
  }

  private deleteTemplate(tpl: TemplatesListItem) {
    modalService
      .openSmall(
        DeleteTemplateDialogSettingsFactory.create({
          template: { id: tpl.id, name: tpl.name },
          savedTemplate: targetGroupRepository.getStorageTemplate(),
        })
      )
      .result.catch(silenceRejection);
  }

  private checkIfShouldCollapse(projectId: number | 'none') {
    if (this.item.id === projectId) return;
    this.isExpanded = false;
  }

  private sameUserRole(item: TemplatesListItem): boolean {
    const isAccessRoleDraft = item.channel === TemplateChannel.Access && session.product === Product.Access;
    const isAccessProDraft = item.channel === TemplateChannel.AccessPro && session.product === Product.AccessPro;
    const isMsDraft = item.channel === TemplateChannel.ManagedServices && session.product === Product.ManagedServices;

    return isAccessRoleDraft || isAccessProDraft || isMsDraft;
  }

  private createMenuOptions(item: TemplatesListItem) {
    return [
      {
        id: 0,
        name: 'Create project based on template',
        url: filters.loadTemplateUrl()(item.id),
        type: MenuOptionType.Create,
        hidden: !userService.companyAllowedToCreateProjects,
      },
      {
        id: 1,
        name: 'Edit template',
        url: filters.editTemplateUrl()(item.id),
        type: MenuOptionType.Edit,
        disabled: !this.sameUserRole(item),
      },
      {
        id: 2,
        name: 'Show update history',
        url: null,
        type: MenuOptionType.History,
        disabled: false,
      },
      {
        id: 3,
        name: 'Delete template',
        url: null,
        type: MenuOptionType.Delete,
        disabled: false,
      },
    ];
  }
}

export const ngTemplateListItemComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: TemplateListItemComponent,
  },
};
