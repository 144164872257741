import { ExistingTargetGroup, ExistingTargetGroupSummary } from '../http-services/existing-project.models';
import { isUndefinedOrNull } from '../../helpers';
import { ProjectPrice, TargetGroupPrice } from './price-models';

export interface PriceTrendThreshold {
  completes: number;
  responded: number;
}

export interface IrAndLoiTrend {
  ir: TrendDirection;
  loi: TrendDirection;
}

export enum TrendDirection {
  Flat = 'flat',
  Up = 'up',
  Down = 'down',
}

export class PriceTrendService {
  static threshold: PriceTrendThreshold = {
    completes: 15,
    responded: 75,
  };
  private irLoiThreshold = 0.25;

  irAndLoiTrend(tg: ExistingTargetGroupSummary, targetGroupPrice: TargetGroupPrice): IrAndLoiTrend {
    const ir = this.irTrendReal(tg, targetGroupPrice);
    const loi = this.loiTrendReal(tg, targetGroupPrice);
    if (
      (ir.trend !== TrendDirection.Flat && ir.diff > this.irLoiThreshold) ||
      (loi.trend !== TrendDirection.Flat && loi.diff > this.irLoiThreshold)
    ) {
      return {
        ir: ir.trend,
        loi: loi.trend,
      };
    }

    return {
      ir: TrendDirection.Flat,
      loi: TrendDirection.Flat,
    };
  }

  loiTrend(tg: ExistingTargetGroup, targetGroupPrice: TargetGroupPrice): TrendDirection {
    if (isUndefinedOrNull(targetGroupPrice)) return TrendDirection.Flat;

    if (tg.actualNumberOfCompletes < PriceTrendService.threshold.completes) return TrendDirection.Flat;

    if (targetGroupPrice.projected.price.value === targetGroupPrice.initial.price.value) return TrendDirection.Flat;

    return this.trendDirection(tg.estimatedLengthOfInterview, tg.actualLengthOfInterview);
  }

  irTrend(tg: ExistingTargetGroup, targetGroupPrice: TargetGroupPrice): TrendDirection {
    if (isUndefinedOrNull(targetGroupPrice)) return TrendDirection.Flat;

    if (tg.responded < PriceTrendService.threshold.responded) return TrendDirection.Flat;

    if (targetGroupPrice.projected.price.value === targetGroupPrice.initial.price.value) return TrendDirection.Flat;

    return this.trendDirection(tg.estimatedIncidenceRate, tg.actualIncidenceRate);
  }

  priceTrend(actual: number, responded: number, price: ProjectPrice | TargetGroupPrice): TrendDirection {
    if (isUndefinedOrNull(price)) return TrendDirection.Flat;
    if (isUndefinedOrNull(price.projected.price)) return TrendDirection.Flat;
    if (isUndefinedOrNull(price.initial.price)) return TrendDirection.Flat;

    if (actual < PriceTrendService.threshold.completes && responded < PriceTrendService.threshold.responded)
      return TrendDirection.Flat;

    if (price.projected.price.value === price.initial.price.value) return TrendDirection.Flat;

    return price.projected.price.value > price.initial.price.value ? TrendDirection.Up : TrendDirection.Down;
  }

  loiTrendReal(tg: ExistingTargetGroupSummary, targetGroupPrice: TargetGroupPrice) {
    const flat = {
      diff: 0,
      trend: TrendDirection.Flat,
    };
    if (isUndefinedOrNull(targetGroupPrice)) return flat;

    if (!tg.hasSignificantLoi) return flat;

    if (targetGroupPrice.projected.price.value === targetGroupPrice.initial.price.value) return flat;

    return this.trendDirectionReal(tg.estimatedLengthOfInterview, tg.actualLengthOfInterview);
  }

  irTrendReal(tg: ExistingTargetGroupSummary, targetGroupPrice: TargetGroupPrice) {
    const flat = {
      diff: 0,
      trend: TrendDirection.Flat,
    };
    if (isUndefinedOrNull(targetGroupPrice)) return flat;

    if (!tg.hasSignificantNumberOfRespondents) return flat;

    if (targetGroupPrice.projected.price.value === targetGroupPrice.initial.price.value) return flat;

    return this.trendDirectionReal(tg.estimatedIncidenceRate, tg.actualIncidenceRate);
  }

  private trendDirection(estimated: number, actual: number) {
    const diff = actual - estimated;
    if (diff !== 0) {
      return diff > 0 ? TrendDirection.Up : TrendDirection.Down;
    }
    return TrendDirection.Flat;
  }

  private trendDirectionReal(estimated: number, actual: number) {
    if (isUndefinedOrNull(actual)) {
      return {
        diff: 0,
        trend: TrendDirection.Flat,
      };
    }

    const diff = actual / estimated - 1;
    let trend: TrendDirection;
    if (diff === 0) {
      trend = TrendDirection.Flat;
    } else {
      trend = diff > 0 ? TrendDirection.Up : TrendDirection.Down;
    }

    return {
      diff: Math.abs(diff),
      trend,
    };
  }
}

export const priceTrendService = new PriceTrendService();
