import { ActiveExcludeProjectsModel } from '../../target-groups/active-target-group/models/active-exclude-projects.model';
import { ExcludedStatusWithTimeline } from '../http-services/existing-project.models';

export interface ProjectWithStatusExclude {
  readonly projectId: number;
  readonly respondentStatusTimelines: ExcludedStatusWithTimeline[];
}

export interface ExcludeProjectsModel {
  readonly projects: ProjectWithStatusExclude[];
  readonly respondentStatusTimelines: ExcludedStatusWithTimeline[];
}

export function createExcludeProjectsModel(): ExcludeProjectsModel {
  return {
    projects: [],
    respondentStatusTimelines: [],
  };
}

export function createExcludeProjectsModelFromActive(model: ActiveExcludeProjectsModel): ExcludeProjectsModel {
  return {
    projects: model.selectedProjectIds.map((id) => ({
      projectId: id,
      respondentStatusTimelines: model.projectStatusesToExclude[id]?.map(({ value, timeline }) => ({
        respondentStatus: value,
        timeline,
      })),
    })),
    respondentStatusTimelines: model.statusesToExclude
      ? model.statusesToExclude.map(({ value, timeline }) => ({
          respondentStatus: value,
          timeline,
        }))
      : [],
  };
}
