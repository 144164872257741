import { IDirective } from 'angular';
import './clickable-chevron.directive.scss';

const directiveName = 'clickableChevron';

export function clickableChevron(): IDirective {
  return {
    transclude: true,
    restrict: 'E',
    scope: {
      expanded: '=',
      onClick: '&',
    },
    template: `
            <button class="clickable-chevron" ng-click="onClick()" ng-class="{'expanded': expanded}">
              <i class="fas fa-chevron-right"></i>
            </button>
        `,
  };
}

export const ngClickableChevronDirective = {
  [directiveName]: clickableChevron,
};
