import './import-project-dialog.component.scss';
import { ui } from 'angular';
import { html } from '../helpers';
import { api } from '../common/api';
import { $state } from '../ngimport';

const selector = 'importProjectDialogComponent';

const template = html`
  <div class="import-project-dialog-component">
    <modal-close-button-component close-action="$ctrl.close()"></modal-close-button-component>
    <div class="modal-container">
      <h1 class="modal-header">Import Access project</h1>
      <div>
        <p>Paste your copied JSON here and click import.</p>
        <div class="push-down">
          <textarea name="testLinks" rows="30" ng-model="$ctrl.projectData" spellcheck="false"> </textarea>
        </div>
        <div class="well well-sm error-container">
          <span class="label label-danger">{{ $ctrl.error }}</span>
        </div>
        <div class="modal-controls">
          <button type="button" class="ui-btn default-btn" ng-click="$ctrl.close()">Cancel</button>
          <button type="button" class="ui-btn primary-btn" ng-click="$ctrl.import()">Import</button>
        </div>
      </div>
    </div>
  </div>
`;

interface Bindings {
  modalInstance: '<' | ui.bootstrap.IModalInstanceService;
}

const bindings: Bindings = {
  modalInstance: '<',
};

export class ImportProjectDialogComponent implements Bindings {
  static componentName = selector;

  modalInstance: ui.bootstrap.IModalInstanceService;
  projectData: string;
  error: string;

  close(): void {
    this.modalInstance.dismiss();
  }

  import(): void {
    api.feature
      .importProject(this.projectData)
      .then((res) => {
        const { projectId } = res.data;
        this.close();
        $state.go('manage.monitor', { projectId });
      })
      .catch((res) => {
        this.error = res.data.exception.message;
      });
  }
}

export const ngImportProjectDialogComponent = {
  [selector]: {
    template,
    bindings: bindings as {},
    controller: ImportProjectDialogComponent,
  },
};
