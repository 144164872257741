import { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { api } from '../../api';
import { LabeledInput } from '../../controls/react/LabeledInput';
import { HttpsStatusKey, LinkSource } from '../../enums';
import { TargetGroupRespondentStatistics } from '../../http-services/target-group.httpservice';
import './show-links-dialog.component.scss';

interface Props {
  projectId: number;
  targetGroupId: number;
  screenoutInformation: string;
  testLinks: string[];
  adHocSupplierLink?: string;
}

const getLiveLinkType = (source: LinkSource) => {
  switch (source) {
    case LinkSource.LinkTemplate:
      return '(link template)';
    case LinkSource.UniqueLinkPool:
      return '(unique links)';
    default:
      return '';
  }
};

const getTestLinkType = (testLinks: string[]) => {
  if (testLinks.length > 1) return '(unique links)';
  return '(link template)';
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const renderHttpsStatus = (status: HttpsStatusKey) => {
  switch (status) {
    case HttpsStatusKey.InvalidCertificate:
      return (
        <span
          className="validator-message text-left error"
          title="The service provider is using an invalid SSL Certficate. The survey could potentially be blocked by some browsers and devices."
        >
          <i className="fas fa-exclamation-triangle" /> Your link is using an invalid security certficate
        </span>
      );
    case HttpsStatusKey.ValidNotUsed:
      return (
        <span
          className="validator-message text-left warning"
          title="The service provider is providing a valid SSL Certficate but it´s not being used in your link. The survey could potentially be blocked by some browsers and devices."
        >
          <i className="fas fa-exclamation-triangle" /> Your link is not using the https protocol, however this is
          supported by the service provider. We recommend to use the https protocol if available.
        </span>
      );
    default:
      return <span>{status}</span>;
  }
};

export const TargetGroupLinks: React.FC<Props> = (props: Props) => {
  const [stats, setStats] = useState<TargetGroupRespondentStatistics>();
  const [httpsStatus, setHttpsStatus] = useState<HttpsStatusKey>();
  useEffect(() => {
    if (!stats) {
      api.targetGroup.getRespondentStatistics(props.projectId, props.targetGroupId, true).then((result) => {
        setStats(result);
        if (result.surveyLinkInfo.example) {
          api.validation.getLinkHttpsStatus(result.surveyLinkInfo.example).then((statusResult) => {
            setHttpsStatus(statusResult.data);
          });
        }
      });
    }
  });

  return (
    <div className="target-group-links-component">
      {!stats && <div className="wait-spinner" />}
      {stats && (
        <div>
          <div>
            <h2>Live link</h2>
            <span className="text-muted">{getLiveLinkType(stats.surveyLinkInfo.source)}</span>
            <LabeledInput
              verticalOrientation
              name="livelink"
              maxLength={255}
              label={stats.surveyLinkInfo.source !== LinkSource.LinkTemplate ? 'Example' : undefined}
              type="text"
              readonly
              value={stats.surveyLinkInfo.example}
              buttonVisible={navigator.clipboard !== undefined}
              buttonClick={() => copyToClipboard(stats.surveyLinkInfo.example)}
              buttonIcon="fa fa-clipboard"
              buttonTitle="Copy to clipboard"
              buttonFeedback={{
                iconClicked: 'fa fa-clipboard-check',
                iconClickedText: 'Copied',
              }}
            />
            {renderHttpsStatus(httpsStatus)}
          </div>
          <div>
            <h2>Test link</h2>
            <span className="text-muted">{getTestLinkType(props.testLinks)}</span>
            <div>
              {props.testLinks.map((testLink, index) => (
                <LabeledInput
                  verticalOrientation
                  key={index}
                  name="testLink"
                  maxLength={255}
                  type="text"
                  readonly
                  value={testLink}
                  buttonVisible={navigator.clipboard !== undefined}
                  buttonClick={() => copyToClipboard(testLink)}
                  buttonIcon="fa fa-clipboard"
                  buttonTitle="Copy to clipboard"
                  buttonFeedback={{
                    iconClicked: 'fa fa-clipboard-check',
                    iconClickedText: 'Copied',
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div>
        <h2>Screenout and testing instructions</h2>
        <div className="screenout-info">{props.screenoutInformation}</div>
      </div>
      {props.adHocSupplierLink && (
        <div>
          <h2>Ad Hoc Supplier Link</h2>
          <div>
            <LabeledInput
              verticalOrientation
              name="testLink"
              maxLength={255}
              type="text"
              readonly
              value={props.adHocSupplierLink}
              buttonVisible={navigator.clipboard !== undefined}
              buttonClick={() => copyToClipboard(props.adHocSupplierLink)}
              buttonIcon="fa fa-clipboard"
              buttonTitle="Copy to clipboard"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const ngTargetGroupLinksComponent = {
  targetGroupLinksComponent: react2angular(TargetGroupLinks, [
    'projectId',
    'targetGroupId',
    'screenoutInformation',
    'testLinks',
    'adHocSupplierLink',
  ]),
};
