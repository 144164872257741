import { module as ngModule, extend as ngExtend } from 'angular';
import uirouter from '@uirouter/angularjs';
import uiselect from 'ui-select';
import alert from 'angular-ui-bootstrap/src/alert';
import modal from 'angular-ui-bootstrap/src/modal';
import tabs from 'angular-ui-bootstrap/src/tabs';
import pagination from 'angular-ui-bootstrap/src/pagination';
import collapse from 'angular-ui-bootstrap/src/collapse';
import dropdown from 'angular-ui-bootstrap/src/dropdown';
import accordion from 'angular-ui-bootstrap/src/accordion';
import typeahead from 'angular-ui-bootstrap/src/typeahead';
import ngStrap from 'angular-strap';
import ngFileSaver from 'angular-file-saver';
import ngJsonFormatter from 'jsonformatter';
import { datePickerOptions, timePickerOptions } from './ng-strap-options';
import { registerDebouncePromise } from './debounce-promise';
import 'jsonformatter/dist/json-formatter.css';
import {
  registerUiSelectMatchTemplates,
  uiSelectMatchDirectiveDecorator,
} from './controls/ui-select/ui-select-match-directive.decorator';
import { hotkeyService } from './hotkey.service';
import { runtimeTypeValidatingHttpProviderDecorator } from './runtime-type-validating-http-provider.decorator';
import {
  moduleName as ngImportModuleName,
  $templateCache,
  $stateProvider,
  $provide,
  $datepickerProvider,
  $timepickerProvider,
  JSONFormatterConfigProvider,
} from '../ngimport';
import { filters } from './filters';
import { ngErrorMessageDialogComponent } from './dialogs/error-message-dialog.component';
import { ngOnOffSwitchComponent } from './controls/on-off-switch/on-off-switch.component';
import { ngInlineHelpToggleComponent } from './inline-help/inline-help-toggle.component';
import { ngProjectPriceReactComponent } from './pricing/ProjectPrice';
import { ngTargetGroupPriceReactComponent } from '../overview/target-group/TargetGroupPrice';
import { ngRateCardDialogComponent } from './pricing/rate-card/rate-card-dialog.component';
import { ngTargetGroupQuotasTableComponent } from './target-group/target-group-quotas-table.component';
import { ngTargetGroupSupplyComponent } from './target-group/target-group-supply.component';
import { ngTargetGroupProfilingComponent } from './target-group/target-group-profiling.component';
import { ngAvailableLinkTagsComponent } from './target-group/survey-links/available-link-tags.component';
import { ngSurveyLinksDialogComponent } from './target-group/survey-links/survey-links-dialog.component';
import { ngDeleteDraftDialogComponent } from '../project-list/delete-draft-dialog.component';
import { ngShowForRolesDirective } from './directives/show-for-roles.directive';
import { ngHideForRolesDirective } from './directives/hide-for-roles.directive';
import { ngShowForProductDirective } from './directives/show-for-product.directive';
import { ngHideForProductDirective } from './directives/hide-for-product.directive';
import { ngAdminOnlyInProductionDirective } from './directives/admin-only-in-production.directive';
import { ngOnlyIfImmerPatchesEnabledDirective } from './directives/only-if-immer-patches-enabled.directive';
import { ngFeatureOnAnyDirective } from './directives/feature-on-any.directive';
import { ngFeatureOnDirective } from './directives/feature-on.directive';
import { ngFeatureOffAllDirective } from './directives/feature-off-all.directive';
import { ngFeatureOffDirective } from './directives/feature-off.directive';
import { ngNotInSandboxDirective } from './directives/not-in-sandbox.directive';
import { ngFadeInOnChangeDirective } from './directives/fade-in-on-change.directive';
import { ngAutoSelectOnShowDirective } from './directives/auto-select-on-show.directive';
import { ngInitialValuePlaceholderDirective } from './directives/initial-value-placeholder.directive';
import { ngGreaterThanZeroIntegerDirective } from './directives/greater-than-zero-integer.directive';
import { ngGlyphLinkDirective } from './directives/glyph-link.directive';
import { ngFocusOnDirective } from './directives/focus-on.directive';
import { ngRunOnBlurIfValueClearedDirective } from './directives/run-on-blur-if-value-cleared.directive';
import { ngRemoveOverClassOnDragLeaveDirective } from './directives/remove-over-class-on-drag-leave.directive';
import { ngPositiveIntegerDirective } from './directives/positive-integer.directive';
import { ngPercentDirective } from './directives/percent.directive';
import { ngOnEnterPressedDirective } from './directives/on-enter-pressed.directive';
import { ngValidLinkTemplateDirective } from './directives/valid-link-template.directive';
import { ngTrackKbLinkDirective } from './directives/track-kb-link.directive';
import { ngSpinWhileLoadingFancyDirective } from './directives/spin-while-loading-fancy.directive';
import { ngSpinWhileLoadingDirective } from './directives/spin-while-loading.directive';
import { ngInlineHelpDirective } from './inline-help/inline-help.directive';
import { ngCustomDropdownComponent } from './controls/custom-dropdown/custom-dropdown.component';
import { ngShowLinksDialogComponent } from './target-group/survey-links/show-links-dialog.component';
import { ngDropFileDirective } from './directives/drop-file.directive';
import { ngSelectNgFiles } from './directives/select-ng-files.directive';
import { ngTogglerComponent } from './controls/toggler/toggler.component';
import { ngImmerPatchesDevtoolsComponent } from '../dev-tools/immer-patches-devtools.component';
import { ngOnClickOutsideDirective } from './directives/on-click-outside.directive';
import { ngOptionsMenuComponent } from './controls/options-menu/options-menu.component';
import { ngStickToTopDirective } from './directives/stick-to-top.directive';
import { ngOnScrollIntoViewDirective } from './directives/on-scroll-into-view.directive';
import { ngImmerPatchesItemComponent } from '../dev-tools/immer-patches-item.component';
import { ngTemplatesListComponent } from '../project-list/templates/templates-list.component';
import { ngTemplateListItemComponent } from '../project-list/templates/template-list-item.component';
import { ngTemplateFiltersComponent } from '../project-list/templates/template-filters.component';
import { ngModalCloseButtonComponent } from './dialogs/modal-close-button.component';
import { ngLoadTemplateDialogComponent } from '../target-groups/sidebar/dialogs/load-template-dialog.component';
import { ngUnsavedChangesDialogComponent } from './dialogs/unsaved-changes-dialog.component';
import { ngClickableChevronDirective } from './directives/clickable-chevron.directive';
import { ngTargetGroupFilterAndSearchComponent } from './TargetGroupFilterAndSearchComponent';
import { ngQuotaStatsComponent } from './target-group/quota-stats.component';
import { ngTargetGroupSelectionDialogComponent } from './dialogs/TargetGroupSelectionDialog';
import { ngExistingExclusionsComponent } from '../target-groups/exclude-projects/ExistingExclusions';
import { ngExistingRegionsComponent } from '../target-groups/regions/existing-regions.component';
import { ngTargetGroupLinksComponent } from './target-group/survey-links/TargetGroupLinks';
import { ngProjectRedirectLinks } from './controls/react/ProjectRedirectLinks';
import { ngInfoDialogComponent } from './dialogs/InfoDialog';
import { ngConfirmationDialogComponent } from './dialogs/ConfirmationDialog';
import { ngTargetGroupStatus } from './controls/react/TargetGroupStatus';
import { ngSaveQuotaPresetDialogComponent } from './dialogs/SaveQuotaPresetDialog';
import { ngBsPopoverComponent } from './controls/AngularBsPopover';
import { ngCollapsibleBillingInfo } from './pricing/CollapsibleBillingInfo';
import { ngProjectChangeLogDialogComponent } from './dialogs/ProjectChangeLogDialog';
import { ngAddProjectGroupDialogComponent } from '../overview/project-summary/AddProjectGroupDialog';
import { ngSelectProjectGroupsDialogComponent } from '../overview/project-summary/SelectProjectGroupsDialog';
import { ngExcludePanelistPoolComponent } from '../target-groups/exclude-projects/ExcludePanelistPool';
import { ngFeatureOnAllDirective } from './directives/feature-on-all.directive';

require('angular-file-upload');

export const common = ngModule('access.common', [
  uirouter,
  alert,
  modal,
  tabs,
  pagination,
  collapse,
  dropdown,
  accordion,
  typeahead,
  uiselect,
  `${ngStrap}.collapse`,
  `${ngStrap}.datepicker`,
  `${ngStrap}.timepicker`,
  `${ngStrap}.popover`,
  ngFileSaver,
  ngJsonFormatter,
  'angularFileUpload',
  ngImportModuleName,
]);

common.config(() => {
  $provide.decorator('uiSelectMatchDirective', uiSelectMatchDirectiveDecorator);
  $provide.decorator('$http', runtimeTypeValidatingHttpProviderDecorator);

  ngExtend($datepickerProvider.defaults, datePickerOptions);
  ngExtend($timepickerProvider.defaults, timePickerOptions);

  registerDebouncePromise($provide);

  $stateProvider.state('common', {
    params: { initiatingAction: null },
  });

  JSONFormatterConfigProvider.hoverPreviewEnabled = true;
});

common.run(() => {
  if (__PROCESS__.ENV === 'testing') return;

  registerUiSelectMatchTemplates($templateCache);
  hotkeyService.registerHotkeys();
});

common.filter(filters);

common.component(ngErrorMessageDialogComponent);
common.component(ngInlineHelpToggleComponent);
common.component(ngProjectPriceReactComponent);
common.component(ngTargetGroupPriceReactComponent);
common.component(ngCollapsibleBillingInfo);
common.component(ngRateCardDialogComponent);
common.component(ngTargetGroupQuotasTableComponent);
common.component(ngQuotaStatsComponent);
common.component(ngTargetGroupSupplyComponent);
common.component(ngExistingExclusionsComponent);
common.component(ngExistingRegionsComponent);
common.component(ngExcludePanelistPoolComponent);
common.component(ngTargetGroupProfilingComponent);
common.component(ngAvailableLinkTagsComponent);
common.component(ngSurveyLinksDialogComponent);
common.component(ngDeleteDraftDialogComponent);
common.component(ngCustomDropdownComponent);
common.component(ngShowLinksDialogComponent);
common.component(ngTargetGroupLinksComponent);
common.component(ngTogglerComponent);
common.component(ngOptionsMenuComponent);
common.component(ngOnOffSwitchComponent);
common.component(ngTemplatesListComponent);
common.component(ngTemplateListItemComponent);
common.component(ngTemplateFiltersComponent);
common.component(ngModalCloseButtonComponent);
common.component(ngImmerPatchesDevtoolsComponent);
common.component(ngImmerPatchesItemComponent);
common.component(ngLoadTemplateDialogComponent);
common.component(ngUnsavedChangesDialogComponent);
common.component(ngTargetGroupSelectionDialogComponent);
common.component(ngSaveQuotaPresetDialogComponent);
common.component(ngProjectRedirectLinks);
common.component(ngTargetGroupFilterAndSearchComponent);
common.component(ngInfoDialogComponent);
common.component(ngConfirmationDialogComponent);
common.component(ngTargetGroupStatus);
common.component(ngBsPopoverComponent);
common.component(ngProjectChangeLogDialogComponent);
common.component(ngAddProjectGroupDialogComponent);
common.component(ngSelectProjectGroupsDialogComponent);

common.directive(ngShowForRolesDirective);
common.directive(ngHideForRolesDirective);
common.directive(ngShowForProductDirective);
common.directive(ngHideForProductDirective);
common.directive(ngAdminOnlyInProductionDirective);
common.directive(ngFeatureOnAnyDirective);
common.directive(ngFeatureOnDirective);
common.directive(ngFeatureOnAllDirective);
common.directive(ngFeatureOffAllDirective);
common.directive(ngFeatureOffDirective);
common.directive(ngNotInSandboxDirective);
common.directive(ngFadeInOnChangeDirective);
common.directive(ngAutoSelectOnShowDirective);
common.directive(ngInitialValuePlaceholderDirective as any);
common.directive(ngGreaterThanZeroIntegerDirective as any);
common.directive(ngGlyphLinkDirective);
common.directive(ngFocusOnDirective);
common.directive(ngRunOnBlurIfValueClearedDirective as any);
common.directive(ngRemoveOverClassOnDragLeaveDirective);
common.directive(ngPositiveIntegerDirective as any);
common.directive(ngPercentDirective as any);
common.directive(ngOnEnterPressedDirective);
common.directive(ngValidLinkTemplateDirective as any);
common.directive(ngTrackKbLinkDirective);
common.directive(ngSpinWhileLoadingDirective);
common.directive(ngSpinWhileLoadingFancyDirective);
common.directive(ngInlineHelpDirective);
common.directive(ngDropFileDirective as any);
common.directive(ngSelectNgFiles as any);
common.directive(ngOnClickOutsideDirective);
common.directive(ngStickToTopDirective);
common.directive(ngOnScrollIntoViewDirective);
common.directive(ngOnlyIfImmerPatchesEnabledDirective);
common.directive(ngClickableChevronDirective);
