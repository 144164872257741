import { IAugmentedJQuery, IDirective, IScope } from 'angular';
import { $timeout } from '../../ngimport';

const directiveName = 'autoSelectOnShow';

export function autoSelectOnShow(): IDirective {
  return {
    restrict: 'A',
    link: (_scope: IScope, elem: IAugmentedJQuery) => {
      elem[0].focus();
      $timeout(() => {
        (elem as any)[0].select();
      }, 0);
    },
  };
}

export const ngAutoSelectOnShowDirective = {
  [directiveName]: autoSelectOnShow,
};
