import { Channel, DataChannel } from '../common/channels/channel';
import { ProjectListsFilterSettings } from './project-lists-filter-settings.repository';
import { ProjectUserFilter } from '../common/enums';
import { ProjectListModel } from './project-lists-service';
import { ProjectGroupListModel } from './project-group-list-service';
import { ProjectGroup } from './groups/ProjectGroups';

export const projectListsChannel = {
  filtersApplied: new DataChannel<ProjectListsFilterSettings>(),
  scopeChanged: new DataChannel<ProjectUserFilter>(),
  activeProjectGroupChanged: new DataChannel<ProjectGroup>(),
  projectGroupListUpdated: new DataChannel<ProjectGroupListModel>(),
  projectGroupSearchUpdated: new DataChannel<ProjectGroupListModel>(),
  activeProjectListUpdated: new DataChannel<ProjectListModel>(),
  deviatingProjectListUpdated: new DataChannel<ProjectListModel>(),
  unclosedProjectListUpdated: new DataChannel<ProjectListModel>(),
  recentProjectListUpdated: new DataChannel<ProjectListModel>(),
  groupProjectListUpdated: new DataChannel<ProjectListModel>(),
  newProjectListStateEntered: new Channel(),
};
