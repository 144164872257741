import { Boolean, Null, Record, Static, Array, Optional } from 'runtypes';
import { AboveZero, NonEmptyString, NonNegative, Iso8601UtcString } from '../../custom-runtypes';

export const MoneyResponse = Record({
  value: NonNegative,
  currency: NonEmptyString,
});
export type MoneyResponse = Static<typeof MoneyResponse>;

export const PriceWithCompletesResponse = Record({
  completes: AboveZero,
  price: MoneyResponse,
});
export type PriceWithCompletesResponse = Static<typeof PriceWithCompletesResponse>;

export const ProjectFeeResponse = Record({
  name: NonEmptyString,
  price: MoneyResponse,
});
export type ProjectFeeResponse = Static<typeof ProjectFeeResponse>;

export const PriceWithFeesResponse = Record({
  completes: NonNegative,
  price: MoneyResponse,
  volumeDiscountCompletes: NonNegative,
  fees: Array(ProjectFeeResponse),
  priceWithoutFees: MoneyResponse,
});
export type PriceWithFeesResponse = Static<typeof PriceWithFeesResponse>;

export const EffectiveCpiDetailResponse = Record({
  deliveredCompletes: NonNegative,
  cost: MoneyResponse,
  cpi: MoneyResponse,
});
export type EffectiveCpiDetailResponse = Static<typeof EffectiveCpiDetailResponse>;

export const QuotaPriceResponse = Record({
  id: NonNegative,
  cpi: MoneyResponse,
  price: MoneyResponse,
  isCustomCpi: Boolean,
  customCpiMin: NonNegative.Or(Null),
  customCpiMax: NonNegative.Or(Null),
  completes: NonNegative.Or(Null),
  effectiveCpiDetails: Array(EffectiveCpiDetailResponse).Or(Null),
  currentEffectiveCpi: MoneyResponse.Or(Null),
});
export type QuotaPriceResponse = Static<typeof QuotaPriceResponse>;

export const PriceWithCpiResponse = Record({
  completes: NonNegative,
  price: MoneyResponse,
  cpi: MoneyResponse,
  isCustomCpi: Boolean,
  quotas: Array(QuotaPriceResponse).Or(Null),
});
export type PriceWithCpiResponse = Static<typeof PriceWithCpiResponse>;

export const FixedIncentiveResponse = Record({
  amount: NonNegative,
  currency: NonEmptyString.Or(Null),
});
export type FixedIncentiveResponse = Static<typeof FixedIncentiveResponse>;

export const PriceWithCpiAndFixedIncentiveResponse = Record({
  completes: NonNegative,
  price: MoneyResponse,
  cpi: MoneyResponse,
  isCustomCpi: Boolean,
  fixedIncentive: FixedIncentiveResponse,
  effectiveCpi: MoneyResponse.Or(Null),
  effectiveCpiDetails: Array(EffectiveCpiDetailResponse),
  effectiveLengthOfInterview: NonNegative,
  effectiveIncidenceRate: NonNegative,
});
export type PriceWithCpiAndFixedIncentiveResponse = Static<typeof PriceWithCpiAndFixedIncentiveResponse>;

export const TargetGroupPriceResponse = Record({
  targetGroupId: AboveZero,
  hasRateCard: Boolean,
  hasAgreedPrice: Boolean,
  ownPanel: Boolean,
  initial: PriceWithCpiResponse,
  current: PriceWithCpiAndFixedIncentiveResponse,
  projected: PriceWithCpiAndFixedIncentiveResponse,
  completeFee: MoneyResponse.Or(Null),
  canUseCustomCpi: Boolean,
  customCpiMin: NonNegative,
  customCpiMax: NonNegative,
});
export type TargetGroupPriceResponse = Static<typeof TargetGroupPriceResponse>;

export const InternalPriceWithCpiResponse = Record({
  price: MoneyResponse,
  cpi: MoneyResponse,
  priceTotal: MoneyResponse,
  cpiTotal: MoneyResponse,
});
export type InternalPriceWithCpiResponse = Static<typeof InternalPriceWithCpiResponse>;

export const InternalTargetGroupPriceResponse = Record({
  targetGroupId: AboveZero,
  initial: InternalPriceWithCpiResponse,
  current: InternalPriceWithCpiResponse,
  projected: InternalPriceWithCpiResponse,
});
export type InternalTargetGroupPriceResponse = Static<typeof InternalTargetGroupPriceResponse>;

export const InternalPriceResponse = Record({
  initial: MoneyResponse,
  initialTotal: MoneyResponse,
  current: MoneyResponse,
  currentTotal: MoneyResponse,
  projected: MoneyResponse,
  projectedTotal: MoneyResponse,
  targetGroupPrices: Array(InternalTargetGroupPriceResponse),
});
export type InternalPriceResponse = Static<typeof InternalPriceResponse>;

export const ProjectPriceResponse = Record({
  initial: PriceWithCompletesResponse,
  current: PriceWithFeesResponse,
  projected: PriceWithFeesResponse,
  targetGroupPrices: Array(TargetGroupPriceResponse),
  internalPrice: Optional(InternalPriceResponse.Or(Null)),
  invoice: PriceWithFeesResponse.Or(Null),
});
export type ProjectPriceResponse = Static<typeof ProjectPriceResponse>;

export const CpiChangeHistoryItem = Record({
  cpi: MoneyResponse,
  timeStamp: Iso8601UtcString,
  changedBy: NonEmptyString.Or(Null),
});
export type CpiChangeHistoryItem = Static<typeof CpiChangeHistoryItem>;

export const CpiChangeHistoryForQuota = Record({
  quotaId: AboveZero,
  cpiChangeHistory: Array(CpiChangeHistoryItem).Or(Null),
});
export type CpiChangeHistoryForQuota = Static<typeof CpiChangeHistoryForQuota>;
