import classnames from 'classnames';
import { projectGroupListService } from '../../../dashboard/project-group-list-service';
import { isGuid } from '../../../helpers';
import { DeviationFilter, ProjectChannel } from '../../enums';
import './list-item-pill.scss';

export interface Props {
  isSelected: boolean;
  text: string;
  onClick?: (key: string) => void;
  removeIcon?: boolean;
  title?: string;
}

export const displayForEnumValue = (enumKey: string): string => {
  switch (enumKey) {
    case ProjectChannel.Api:
      return 'API';
    case DeviationFilter.OnHold:
      return 'On Hold';
    default:
      return enumKey;
  }
};

export const ListItemPill: React.FC<Props> = ({ isSelected, onClick, text, removeIcon, title }) => {
  const displayText = isGuid(text) ? mapProjectGroupIdToName(text) : displayForEnumValue(text);

  return (
    <li
      title={title || undefined}
      data-testid={`list-item-pill-${displayText}`}
      className={classnames('list-item-pill', { selected: isSelected })}
      onClick={onClick ? () => onClick(text) : undefined}
    >
      <h4>{displayText}</h4>
      {removeIcon && <i className="remove-icon fas fa-times" />}
    </li>
  );
};

function mapProjectGroupIdToName(id: string) {
  return projectGroupListService.companyProjectGroups.find((pg) => pg.id === id)?.name ?? id;
}
